import { useEffect, useState } from "react";
import { getEnv } from "../../../../../../helpers/procurex";
import axios from "axios";
import { Placeholder } from "rsuite";


const PaktaIntegritasAuctionTab = ({ auction, tabulasi }: any) => {
  const [isLoadingPaktaIntegritas, setIsLoadingPaktaIntegritas] = useState(false);
  const [urlPdf, setUrlPdf] = useState("");
  const getPaktaIntegritas = () => {
    setIsLoadingPaktaIntegritas(true);
    const newUrl = getEnv("auction") + `/auction/generate-pakta-integritas/${auction?.uuid}`;
    axios.get(newUrl, {
      responseType: "blob"
    })
      .then((response) => {
        setIsLoadingPaktaIntegritas(false);
        const url = URL.createObjectURL(response.data);
        setUrlPdf(url);
      })
      .catch((error) => {
        setIsLoadingPaktaIntegritas(false);
      })

  };

  useEffect(() => {
    getPaktaIntegritas();
  }, []);
  return (
    <>
    <div className={'row'}>
        <div className={'col-md-12'}>
        {(isLoadingPaktaIntegritas)
          ? <Placeholder.Graph height={700} active={isLoadingPaktaIntegritas} />
          : urlPdf
            ? <>
              <object width="100%" style={{
                height: "700px"
              }} data={urlPdf} type="application/pdf">
                {" "}
              </object>
            </>
            : <div className={"bg-secondary w-100"} style={{ "height": 700, "opacity": "0.6" }}></div>
        }
        </div>
      </div>
    </>
  );
};

export { PaktaIntegritasAuctionTab };
