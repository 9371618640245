import React, { useEffect, useRef, useState } from "react";
import { useGetJadwalTender, usePostJadwalTender } from "../../../hooks/proposalTenderHook";
import { toast } from "react-toastify";
import "rsuite/dist/rsuite-no-reset.min.css";
import {
  getEnv, getMomentFromTimestamp,
  parseDateTimeToDBFormat,
  parseDBDateToJSFormat,
} from '../../../../../../../helpers/procurex'
import DateRangePickerX from "../../../../../../components/shared/form/DateRangePickerX";
import { useIntl } from "react-intl";
import { useAxiosGet, useAxiosPost } from "../../../../../../../hooks/useAxios";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { ApprovalX } from "../../../../../../components/shared/ApprovalX";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { PageAlertX } from "../../../../../../components/shared/PageAlertX";
import moment from 'moment'

const FormExtendTender = ({ proposalTender, onRefresh, jadwalList, jadwalProsesTender }: any) => {
  const intl = useIntl();
  // @ts-ignore
  const [dateJadwal, setDateJadwal] = useState<[Date, Date]>([]);
  const [isLoadingSaveJadwal, setIsLoadingSaveJadwal] = useState(false);
  const [{
    doSubmit: doSubmitGet,
    isLoading: isLoadingGet
  }] = useAxiosGet(getEnv("tender") + `/tender-jadwal/routing-approval-code/${proposalTender?.uuid}`);
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosPost(getEnv("tender") + `/tender-jadwal/${proposalTender?.uuid}`);
  const formSchemaValidation = Yup.object().shape({
    proses: Yup.string().required(intl.formatMessage({ id: "Wajib diisi!" })),
    note: Yup.string().required(intl.formatMessage({ id: "Wajib diisi!" })),
  });
  const [approvalSchema, setApprovalSchema] = useState(null);
  const [isShowApprovalModal, setIsShowApprovalModal] = useState(false);
  const formik = useRef({} as any);

  const handleOnSave = async () => {
    if(formik.current.values.waktu.length === 0){
      formik.current.setFieldError("waktu", intl.formatMessage({id:"Wajib diisi!"}));
      return;
    }
    doSubmitGet({}, undefined, undefined).then((res: any) => {
      setApprovalSchema(res.data.details);
      setIsShowApprovalModal(true);
    });
  };

  const handleOnSubmitApproval = async (values: any) => {
    setIsShowApprovalModal(false);
    Swal.fire({
      title: intl.formatMessage({id:"Apakah Anda yakin"})+"?",
      text: intl.formatMessage({id:"Anda akan mengextend Tender ini"}),
      icon: "question",
      showCancelButton: true,
      confirmButtonText: intl.formatMessage({id:"Ya, Submit"}),
      cancelButtonText: intl.formatMessage({id:"Tidak"})
    }).then((result) => {
      const currentJadwal = jadwalProsesTender?.data?.filter((v: any) => v.description === formik.current.values.proses);
      if(!currentJadwal || currentJadwal.length === 0){
        toast.error(intl.formatMessage({id:"Jadwal tidak ditemukan"}));
        return;
      }
      const payload = {
        _method: "PUT",
        proposal_tender_id: proposalTender?.uuid,
        description: formik.current.values.proses,
        note: formik.current.values.note,
        start_at: parseDateTimeToDBFormat(formik.current.values.waktu[0]),
        end_at: parseDateTimeToDBFormat(formik.current.values.waktu[1]),
        approvers: values.approval.map((item: any) => {
          return {
            step: item.step,
            jabatan: item.jabatan,
            type: "APPROVAL",
            user_id: item.extra_info.user.user_id,
            extra_info: item.extra_info
          };
        })
      };
      if (result.isConfirmed) {
        const newUrl = getEnv("tender") + `/tender-jadwal/${currentJadwal[0]?.uuid}`;
        doSubmit(payload, newUrl, toast, () => {
          onRefresh && onRefresh();
        });
      }
    });
  }


  return (
    <>
      <div className={"form"}>
        <Formik
          innerRef={formik}
          key={`formik-extend-tender`}
          initialValues={{
            proses: jadwalList[jadwalList.length-1].type,
            note: "",
            waktu: []
          }}
          onSubmit={handleOnSave}
          validationSchema={formSchemaValidation}
          enableReinitialize
          validateOnChange={true}
        >
          {({ errors, touched, values, setFieldValue, resetForm, submitForm }: any) => (
            <Form id="form-hps">
              <>
                <div className={"form-group row mb-4"}>
                  <label className={"col-md-3 col-sm-12"}>{intl.formatMessage({ id: "Proses Tender" })}</label>
                  <div className={"col-md-9 col-sm-12"}>
                    <select className={"form-select form-select-sm"} name="proses" value={values?.proses} onChange={(e:any)=>{
                      setFieldValue("proses", e.target.value);
                    }}>
                      {jadwalList.map((v: any) => {
                        return (
                          <option key={v.name} value={v.type}>
                            {v.label}
                          </option>
                        );
                      })}
                    </select>
                    {(errors.proses && touched.proses) && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{errors.proses}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className={"form-group row mb-4"}>
                  <label className={"col-md-3 col-sm-12"}>{intl.formatMessage({ id: "Waktu" })}</label>
                  <div className={"col-md-9 col-sm-12"}>
                    <DateRangePickerX
                      defaultValue={dateJadwal}
                      onChange={(e: any) => {
                        setFieldValue("waktu",e);
                      }}
                    />
                    {(errors.waktu && touched.waktu) && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{errors.waktu}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className={"form-group row mb-4"}>
                  <label className={"col-md-3 col-sm-12"}>{intl.formatMessage({ id: "Note" })}</label>
                  <div className={"col-md-9 col-sm-12"}>
                    <Field
                      name='note'
                      className={'form-control form-control-sm'}
                      placeholder={'Note'}
                      type='note'
                    />
                    {(errors.note && touched.note) && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{errors.note}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className={"form-group d-flex justify-content-end"}>
                  <button className={"btn btn-primary btn-sm"} type={"submit"}
                          disabled={isLoadingSaveJadwal || isLoading || isLoadingGet}>
                    {(isLoadingSaveJadwal || isLoadingGet || isLoading) && <i className={"fa fa-spinner fa-spin"}></i>}
                    {(!isLoadingSaveJadwal && !isLoadingGet && !isLoading) && <i className={"fa fa-save"}></i>}
                    {intl.formatMessage({ id: "Submit" })}
                  </button>
                </div>
              </>
            </Form>
          )}
        </Formik>
        <ApprovalX
          title={intl.formatMessage({id:'Extend Tender'})}
          show={isShowApprovalModal}
          schema={approvalSchema}
          onHide={() => {
            setIsShowApprovalModal(false);
          }}
          onSubmit={handleOnSubmitApproval}
          isLoading={isLoadingGet}
          source={"TENDER"}
          object_id={proposalTender?.id}
          company_id={proposalTender?.company?.id}
          code={"tender_cancel"}
          readOnly={false}
        />
      </div>
    </>
  );
};
const RowJadwalTender = ({ label, type, name, selectedData, proposalTender, isDisabled, currentTimestamp, momentCurrentTimestamp, onChange, ...props }: any) => {
  const intl = useIntl();
  const [index, setIndex] = useState(0);
  const [key, setKey] = useState(0);
  const [currentId, setCurrentId] = useState<any>(null);
  // @ts-ignore
  const [dateJadwal, setDateJadwal] = useState<[Date, Date]>([]);
  const [statusJadwal, setStatusJadwal] = useState("");
  // @ts-ignore
  const [dateJadwalUpdated, setDateJadwalUpdated] = useState<[Date, Date]>([]);
  const [initialDateJadwal, setInitialDateJadwal] = useState<[Date, Date]>([new Date(), new Date()]);

  useEffect(() => {
    selectedData?.data?.map((item: any) => {
      // Initial value dari database, description pada API harus sama dengan type yang ada di jadwal tender
      if (item.description === type) {
        // @ts-ignore
        setCurrentId(item.uuid);
        setDateJadwal([parseDBDateToJSFormat(item.start_at), parseDBDateToJSFormat(item.end_at)]);
        setInitialDateJadwal([parseDBDateToJSFormat(item.start_at), parseDBDateToJSFormat(item.end_at)]);
        if(item.requested_start_at && item.requested_end_at) {
          setDateJadwalUpdated([parseDBDateToJSFormat(item.requested_start_at), parseDBDateToJSFormat(item.requested_end_at)]);
        }
        setIndex(index + 1);
      }
    });
  }, [selectedData]);

  useEffect(() => {
    if (dateJadwal && dateJadwal.length === 2) {
      // @ts-ignore

      onChange && onChange({
        current_id: currentId,
        proposal_tender_id: proposalTender.uuid,
        description: type,
        // @ts-ignore
        start_at: parseDateTimeToDBFormat(dateJadwal[0]),
        // @ts-ignore
        end_at: parseDateTimeToDBFormat(dateJadwal[1])
      });
    }
  }, [dateJadwal]);

  useEffect(() => {
    const updateStatus = () => {
      let status = "Not Scheduled";
      const start = moment(dateJadwal[0]);
      const end = moment(dateJadwal[1])
      if (dateJadwal) {
        const current = moment(momentCurrentTimestamp).format('YYYY-MM-DD HH:mm:ss');
        const start = moment(dateJadwal[0]).format('YYYY-MM-DD HH:mm:ss');
        const end = moment(dateJadwal[1]).format('YYYY-MM-DD HH:mm:ss');

        if (moment(current).isBefore(moment(start))) {
          status = "Not Started";
        } else if (moment(current).isSameOrAfter(moment(start)) && moment(current).isBefore(moment(end))) {
          status = "Ongoing";
        } else if (moment(current).isSameOrAfter(moment(end))) {
          status = "Done";
        }
      }

      setStatusJadwal(status);
    };
    updateStatus();
  }, [momentCurrentTimestamp]);

  // useEffect(() => {
  //   if (currentJadwal?.length === 0 ) return;

  //   const jadwal = currentJadwal.filter((v: any) => v.description === type);
  //   if(jadwal.length === 0) return ;
  //   setDateJadwal([parseDBDateToJSFormat(jadwal[0]?.start_at), parseDBDateToJSFormat(jadwal[0]?.end_at)])
  //   setKey(key + 1)
  // }, [currentJadwal]);

  return (
    <tr key={index}>
      <td>{label} *</td>
      <td>
        {statusJadwal === "Not Scheduled" && <span className={"badge badge-danger"}>{statusJadwal}</span>}
        {statusJadwal === "Not Started" && <span className={"badge badge-info"}>{statusJadwal}</span>}
        {statusJadwal === "Ongoing" && <span className={"badge badge-warning"}>{statusJadwal}</span>}
        {statusJadwal === "Done" && <span className={"badge badge-success"}>{statusJadwal}</span>}
      </td>
      <td>
        <div className={""}>
          <DateRangePickerX
            key={key}
            defaultValue={dateJadwal}
            onChange={(e: any) => {
              setDateJadwal(e);
            }}
            disabled={isDisabled}
          />
        </div>
        {(dateJadwalUpdated.length > 0 && proposalTender?.status_detail === "Submit for Approval Change Schedule") && (
          <div className={"bg-success p-2 mt-2"}>
            <span className={"text-dark"}>
              {intl.formatMessage({ id: "Pengajuan Jadwal Baru" })}
            </span>
            <DateRangePickerX
              defaultValue={dateJadwalUpdated}
              onChange={(e: any) => {
                setDateJadwalUpdated(e);
              }}
              disabled={true}
              className={"bg-success"}
              style={{backgroundColor: "green"}}
            />
          </div>
        )}

      </td>
    </tr>
  );
};
interface IProps {
  proposalTender?: any;
  onRefresh?: any;
  isOwner?: any;
}
const JadwalTenderPage = ({ proposalTender, onRefresh, isOwner }: IProps) => {
  const [{ doGetJadwal, isLoading, isError, data, isSuccess }] = useGetJadwalTender();
  const intl = useIntl();
  const PusherJS = require("pusher-js");
  const [{ doSubmit: doSaveJadwal, isLoading: isLoadingSaveJadwal }] = usePostJadwalTender();
  const [formJadwalList, setFormJadwalList] = React.useState<any>([]);
  const [isSaveDisabled, setIsSaveDisabled] = React.useState<boolean>(true);
  const [isShowExtendTender, setIsShowExtendTender] = React.useState<boolean>(false);
  const [isCanExtendTender, setIsCanExtendTender] = useState(false)
  const [currentProposalTender, setCurrentProposalTender] = useState( null );
  const [{ doSubmit }] = useAxiosGet(
    getEnv("tender")
  )
  const [jadwalProsesTender, setJadwalProsesTender] = React.useState<any>([
    { label: intl.formatMessage({ id: "Pengumuman" }), name: "pengumuman", type: "Pengumuman" },
    { label: intl.formatMessage({ id: "Registrasi" }), name: "registrasi", type: "Registrasi" },
    { label: intl.formatMessage({ id: "Aanwijzing" }), name: "aanwijzing", type: "Aanwijzing" },
    { label: intl.formatMessage({ id: "Penawaran Teknikal" }), name: "penawaran_teknikal", type: "Penawaran Teknikal" },
    { label: intl.formatMessage({ id: "Penawaran Komersil" }), name: "penawaran_komersil", type: "Penawaran Komersil" },
    {
      label: intl.formatMessage({ id: "Penawaran Teknikal & Komersil" }),
      name: "penawaran_teknikal_komersil",
      type: "Penawaran"
    }
  ]);
  const [currentTimestamp, setCurrentTimestamp] = useState(0);
  const [momentCurrentTimestamp, setMomentCurrentTimestamp] = useState(null as any);
  const [extendTenderKey,setExtendTenderKey] = useState(0)

  const [jadwalPQ, setJadwalPQ] = React.useState<any>([
    { label: "Pra Qualification", name: "pra_qualification", type: "Pra Qualification" }
  ]);

  const getProposalTender = () => {
    const newUrl = getEnv("tender") + `/tender/${proposalTender?.uuid}`
    doSubmit({}, newUrl, undefined, () => {}).then((res: any) => {
      const _proposalTender = res.data;
      setCurrentProposalTender(_proposalTender)
    }).catch((err:any)=>{
      toast.error("Get Evaluasi Teknis: "+err?.response?.data?.meta?.message)
    });
  }

  const isFormEnabled = (_proposalTender:any) => {
    if(!_proposalTender) return false;
    const allowedStatus: any[] = [
      "Draft",
      "DUR",
      "Pra Qualification",
      "Penawaran",
      "Published",
      "Registrasi",
      "Penawaran Teknikal",
      "Penawaran Komersil"
    ];
    const allowedStatusDetail: any[] = ["Draft", "Rejected", "Quotation", "On Going", "Published", "Registration"];
    return allowedStatus.includes(_proposalTender.status) &&
      allowedStatusDetail.includes(_proposalTender.status_detail);

  };

  const initSocket = () => {
    let client = new PusherJS(process.env.REACT_APP_WEBSOCKET_APP_KEY, {
      wsHost: process.env.REACT_APP_WEBSOCKET_URL,
      wsPort: process.env.REACT_APP_WEBSOCKET_PORT,
      cluster: process.env.REACT_APP_WEBSOCKET_CLUSTER,
      forceTLS: process.env.REACT_APP_WEBSOCKET_FORCETLS,
      encrypted: process.env.REACT_APP_WEBSOCKET_ENCRYPTED,
      disableStats: true,
      enabledTransports: ["ws", "wss"],
      logToConsole: true
    });
    const channel = client.subscribe("server-time");
    channel.bind("App\\Events\\ServerTimeEvent", function(data: any) {
      formatDateTime(data.message);
    });
  };

  const genereateJadwal = () => {
    let temp = jadwalProsesTender;
    // Kondisional untuk menampilkan jadwal tender sesuai metode aanwijzing
    if (proposalTender.metode_aanwijzing === "Tanpa Aanwijzing") {
      temp = temp.filter((v: any) => v.name !== "aanwijzing");
    }

    // Kondisional untuk menampilkan jadwal tender sesuai metode penyampaian
    if (proposalTender.metode_penyampaian === "1 Sampul" || proposalTender.metode_penyampaian === "2 Sampul") {
      temp = temp.filter((v: any) => v.name !== "penawaran_teknikal");
      temp = temp.filter((v: any) => v.name !== "penawaran_komersil");
    } else {
      temp = temp.filter((v: any) => v.name !== "penawaran_teknikal_komersil");
    }
    setJadwalProsesTender(temp);
  };

  const handleOnChange = (data: any) => {
    let temp = formJadwalList.filter((v: any) => v.description !== data.description);
    temp.push(data);
    // if (data.description === "Pengumuman") {
    //   const registrasi = {...data, description: "Registrasi"}
    //   const penawaran = {...data, description: "Penawaran"}
    //   const penawaranTeknikal = {...data, description: "Penawaran Teknikal"}
    //   const penawaranKomersil = {...data, description: "Penawaran Komersil"}

    //   temp.push(registrasi);
    //   if (proposalTender.metode_penyampaian === "1 Sampul" || proposalTender.metode_penyampaian === "2 Sampul") {
    //     temp.push(penawaran);
    //   } else {
    //     temp.push(penawaranTeknikal);
    //     temp.push(penawaranKomersil);
    //   }
    // }
    setFormJadwalList(temp);
  };

  const handleOnSave = async () => {
    console.log(formJadwalList);
    formJadwalList.map((v: any) => {
      doSaveJadwal(v, toast, () => {
        onRefresh && onRefresh();
      });
    });
  };

  const checkIsShowExtendTender = () => {
    return currentProposalTender?.status === "Bid Opening" &&
      (currentProposalTender?.status_detail === "Bid Opening" ||
        currentProposalTender?.status_detail === "Rejected");
  }

  const checkIsSaveEnabled = () => {
    let allowedStatus: any[] = [];
    let allowedStatusDetail: any[] = [];
    if (!proposalTender?.praqualification) {
      // allowedStatus = ["Draft", "DUR", "Pra Qualification"];
      // allowedStatusDetail = ["Draft"];
      return true;
    } else {
      // Jika PQ, harus lanjut PQ terlebih dahulu baru dapat mengupdate form
      allowedStatus = ["Pra Qualification", "DUR"];
      allowedStatusDetail = ["Draft", "Rejected"];
    }

    return allowedStatus.includes(proposalTender.status) &&
      allowedStatusDetail.includes(proposalTender.status_detail);
  };

  const isShowJadwalPQ = () => {
    return proposalTender?.praqualification && proposalTender?.status === "Pra Qualification";
  };

  const isShowRetenderReason = () => {
    const showRetenderReason = [
      "Submit for Approval Change Schedule"
    ]
    return showRetenderReason.includes(proposalTender.status_detail);
  }

  const formatDateTime = (timestamp: any) => {
    const time = getMomentFromTimestamp(timestamp);
    const _dateLabel =
      intl.formatMessage({ id: 'DAY.' + time.day() }) +
      ', ' +
      (time.date() < 10 ? '0' + time.date() : time.date()) +
      ' ' +
      intl.formatMessage({ id: 'MONTH.' + time.month() }) +
      ' ' +
      time.year();

    const _timeLabel =
      (time.hours() < 10 ? '0' + time.hours() : time.hours()) +
      ':' +
      (time.minutes() < 10 ? '0' + time.minutes() : time.minutes()) +
      ':' +
      (time.seconds() < 10 ? '0' + time.seconds() : time.seconds());
    // @ts-ignore
    setCurrentTimestamp(_dateLabel + " " + _timeLabel);
    setMomentCurrentTimestamp(time);

  };

  useEffect(() => {
    if (proposalTender?.id) {
      doGetJadwal(proposalTender?.id);
      genereateJadwal();
      setIsSaveDisabled(!checkIsSaveEnabled());
    }
  }, [proposalTender]);

  useEffect(() => {
    initSocket();
    getProposalTender()
  }, []);

  useEffect(() => {
    setIsCanExtendTender(checkIsShowExtendTender())
  }, [currentProposalTender])


  return (
    <>
      {isShowRetenderReason() &&
        <PageAlertX title={intl.formatMessage({id:'Alasan Extend Jadwal'})} variant={"warning"} html={
          <>
            <p className={"text-dark"}>{proposalTender?.jadwal_note}</p>
          </>
        } />
      }

      <div>
        <h3>{intl.formatMessage({ id: "Jadwal Tender" })}</h3>
        <hr />
        {proposalTender?.praqualification && (
          <div className={"alert alert-warning"}>
            <strong className={"text-dark"}>{intl.formatMessage({ id: "Informasi" })}</strong>
            <p className={"text-dark"}>
              Jika menggunakan metode Pra Qualification, maka jadwal tender yang bisa diatur <strong>hanya Pra
              Qualification</strong>.<br />
              Jadwal Pra Qualification <strong>muncul di vendor</strong> ketika statusnya <strong>sudah
              masuk</strong> sesuai dengan jadwal yang diatur.
            </p>
          </div>
        )}

        <table className={"table table-bordered"}>
          <thead>
          <tr>
            <th style={{
              width: '25%'
            }}><strong>{intl.formatMessage({ id: "Proses" })}</strong></th>
            <th style={{
              width: '10%'
            }}><strong>{intl.formatMessage({ id: "Status" })}</strong></th>
            <th style={{
              width: '65%'
            }}><strong>{intl.formatMessage({ id: "Jadwal" })} (WIB)</strong></th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>{intl.formatMessage({ id: "Waktu Server" })}</td>
            <td></td>
            <td>
              <input type={"text"} className={"form-control form-control-sm"} disabled={true}
                     value={currentTimestamp +" WIB"} />
            </td>
          </tr>
          {!isShowJadwalPQ() && jadwalProsesTender.map((v: any) => {
            return (
              <RowJadwalTender
                key={"proses-" + v.name}
                type={v.type}
                label={v.label}
                name={v.name}
                proposalTender={proposalTender}
                selectedData={data}
                currentTimestamp={currentTimestamp}
                momentCurrentTimestamp={momentCurrentTimestamp}
                onChange={(e: any) => handleOnChange(e)}
                isDisabled={!isFormEnabled(currentProposalTender)}
                // currentJadwal={formJadwalList}
              />
            );
          })}
          {isShowJadwalPQ() && jadwalPQ.map((v: any) => {
            return (
              <RowJadwalTender
                key={"proses-" + v.name}
                type={v.type}
                label={v.label}
                name={v.name}
                proposalTender={proposalTender}
                selectedData={data}
                currentTimestamp={currentTimestamp}
                onChange={(e: any) => handleOnChange(e)}
                isDisabled={!isFormEnabled(currentProposalTender)}
                // currentJadwal={formJadwalList}
              />
            );
          })}
          </tbody>
        </table>
        <div className={"form-group d-flex justify-content-end"} key={extendTenderKey}>
          {isOwner && 
            <>
              {isCanExtendTender && (
                <button
                  className={"btn btn-warning btn-sm me-4"}
                  onClick={() => setIsShowExtendTender(true)}
                  type={"button"}
                >
                  {isLoadingSaveJadwal && <i className={"fa fa-spinner fa-spin"}></i>}
                  {!isLoadingSaveJadwal && <i className={"fa fa-clock"}></i>}
                  {intl.formatMessage({ id: "Extend Tender" })}
                </button>
              )}
              <button className={"btn btn-primary btn-sm"} onClick={() => handleOnSave()} type={"button"}
                      disabled={!isFormEnabled(currentProposalTender) || isLoadingSaveJadwal}>
                {isLoadingSaveJadwal && <i className={"fa fa-spinner fa-spin"}></i>}
                {!isLoadingSaveJadwal && <i className={"fa fa-save"}></i>}
                {intl.formatMessage({ id: "Simpan" })}
              </button>
            </>
          }
        </div>
      <Modal show={isShowExtendTender} onHide={()=>setIsShowExtendTender(false)} style={{
        // zIndex: 1000
      }}>
        <Modal.Header closeButton>
          <Modal.Title>{intl.formatMessage({id:'Extend Tender'})}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormExtendTender
            proposalTender={proposalTender}
            onRefresh={()=> {
              setIsShowExtendTender(false);
              getProposalTender()
              onRefresh && onRefresh();
            }}
            jadwalList={jadwalProsesTender}
            jadwalProsesTender={data}
          />
        </Modal.Body>
      </Modal>
      </div>
    </>
  );
};

export default JadwalTenderPage;