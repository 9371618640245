import { useEffect, useRef, useState } from "react"
import CreateTabs from "../components/CreateTabs"
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom'
import { KTSVG, toastSuccess } from "../../../../../_metronic/helpers"
import AgreementDetailHeader from "./AgreementDetailHeader"
import { useAxiosGet } from "../../../../../hooks/useAxios"
import BottomToolbarWrapper from "../../../../components/shared/BottomToolbarWrapper"
import AgreementDetailHistory from "./AgreementDetailHistory"
import AgreementDetailAttachment from "./AgreementDetailAttachment"

const AgreementDetail = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const refHeaderForm = useRef<any>()

    const [tabs, setTabs] = useState([])
    const [agreement, setAgreement] = useState([])
    const [currentTab, setCurrentTab] = useState(0)
    const [searchParams, setSearchParams] = useSearchParams()

    const [{ doSubmit: doGetDataAgreement, isLoading }] = useAxiosGet("")

    const tabsOpt = [
        {
            value: 0,
            icon: 'fa-solid fa-sliders fa-fw',
            text: 'Header',
            disabled: false,
        },
        {
            value: 1,
            icon: 'fa-solid fa-file-circle-plus fa-fw',
            text: 'Attachment',
            disabled: false
        },
        {
            value: 2,
            icon: 'fa-solid fa-clock-rotate-left fa-fw',
            text: 'History',
            disabled: false,
        },
    ]

    const handleBeforeClick = async () => {
        if (currentTab === 0 && location.pathname !== '/contract-management/agreement/create') {
            // await refHeaderForm.current.submitHeaderForm()
        }
    }

    const handleAfterClick = () => {
        handleFetchAgreement("")
        setTimeout(() => {
            window.scrollTo(0, 0)
        }, 500)
    }

    const hanldeHideSidebar = () => {
        const elementToClick = document.getElementById('kt_aside_toggle');
        if (elementToClick && !elementToClick.classList.contains('active')) elementToClick.click();
    }

    const handleShowSidebar = () => {
        const elementToClick = document.getElementById('kt_aside_toggle');
        if (elementToClick && elementToClick.classList.contains('active')) elementToClick.click();
    }

    const handleOnGoBack = () => {
        handleShowSidebar()
        navigate(searchParams.get('back') || '/contract-management/agreement')
    }

    const handleFetchAgreement = (uuid: string) => {
        // fetch data
    }

    useEffect(() => {
        hanldeHideSidebar()
        setTabs(tabsOpt)
    }, [])

    return (
        <>
            <div className='d-flex justify-content-end gap-4 mb-8'>
                <div className='me-auto'>
                    <button type={"button"} onClick={handleOnGoBack} className='btn btn-danger btn-sm'>
                        <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon-muted' />
                        Kembali ke List
                    </button>
                </div>

                <div className={"d-flex justify-content-end me-3"}>
                    <div className={"badge badge-warning text-dark"}>
                        <i className={"fa fa-tag me-3 text-dark"} />
                        Status OA
                    </div>
                </div>
            </div>

            <CreateTabs
                afterClick={handleAfterClick}
                beforeClick={handleBeforeClick}
                tabs={tabs}
                setCurrentTab={setCurrentTab}
                currentTab={currentTab}
            />

            {currentTab === 0 && <AgreementDetailHeader agreement={agreement} ref={refHeaderForm} isLoading={isLoading} />}
            {currentTab === 1 && <AgreementDetailAttachment agreement={agreement} />}
            {currentTab === 2 && <AgreementDetailHistory />}

            <BottomToolbarWrapper children={
                <>
                    <button
                        onClick={() => {
                            if (currentTab === 0) {
                                // refHeaderForm.current.submitHeaderForm(true)
                            } else {
                                toastSuccess('200: Successfully update data.')
                            }
                        }}
                        className='btn btn-info btn-lg me-3'
                    >
                        <KTSVG path='/media/icons/duotune/files/fil008.svg' className='svg-icon-muted' />
                        Save Draft
                    </button>
                    <button className='btn btn-primary btn-lg me-3'>
                        <i className="fa-regular fa-thumbs-up" />
                        Submit Agreement
                    </button>
                    <button
                        type='button'
                        className='btn btn-secondary btn-lg me-3'
                        disabled={currentTab === 0}
                        onClick={() => {
                            setCurrentTab(currentTab - 1)
                        }}
                    >
                        <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon-muted' />
                        Previous
                    </button>
                    <button
                        type='button'
                        className='btn btn-primary btn-lg me-3'
                        disabled={false}
                        onClick={() => {
                            setCurrentTab(currentTab + 1)
                        }}
                    >
                        <KTSVG path='/media/icons/duotune/arrows/arr001.svg' className='svg-icon-muted' />
                        Next
                    </button>
                </>
            }
            />
        </>
    )
}

export default AgreementDetail