import React, { useState, useEffect } from 'react';
import ApexCharts from 'react-apexcharts';
import _, { map } from 'underscore';
const options2: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'long',
    year: 'numeric'
};

type IProps = {
    tabIndex: number,
    data: any,
    material: string
}
const ApexChart: React.FC<IProps> = (props) => {
    const colors = {
        konsumsi: "#7ca868",
        produksi: "#0000FF",
        procurement: "#00FF00",
        stock: "#F42C10",
        penjualan: "#A4A4A8",
        gagal_produksi: "#f3722c",
        gagal_jual: "#fffdd0",
        gridBorder: "rgba(77, 138, 240, .15)",
        cardBg: "#fff",
        bodyColor: "#000",
        secondary: "#7987a1",
        muted: "#7987a1",
    };

    const [data, setData] = useState<any>({
        series: [
        ],
        options: {
            chart: {
                height: 350,
                type: 'area'
            },
            colors:[],
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                categories: []
            },
        }
    });

    const getDates = (data: any) => {
        let dates = [];
        if(data){
            dates = _.pluck(data, 'date');
        }
        return dates;
    }

    const getValue = (data: any, name) => {
        let values = [];
        if(data){
            values = _.pluck(data, name);
            values = values.map(num => parseFloat(num));
        }
        return values;
    }

    useEffect(() => {
        console.log(props.data,"props data")
        console.log(getDates(props.data),"props get dates")
        if(props.tabIndex == 0){
            setData({
                series: [{
                    name: 'Stock',
                    type: "line",
                    data: getValue(props.data,'beginning_of_stock')
                }, 
                // {
                //     name: 'Procument',
                //     type: "line",
                //     data: getValue(props.data,'end_of_stock')
                // },
                {
                    name: 'Produksi',
                    type: "line",
                    data: getValue(props.data,'production')
                },
                {
                    name: 'Konsumsi',
                    type: "line",
                    data: getValue(props.data,'consumption')
                },
                {
                    name: 'Penjualan',
                    type: "line",
                    data: getValue(props.data,'sales')
                },
                {
                    name: 'Gagal Produksi',
                    type: "line",
                    data: getValue(props.data,'production_failed')
                },
                {
                    name: 'Gagal Konsumsi',
                    type: "line",
                    data: getValue(props.data,'consumption_failed')
                },
                {
                    name: 'Gagal Jual',
                    type: "line",
                    data: getValue(props.data,'sales_failed')
                }
                ],
                options: {
                    chart: {
                        type: "line",
                        height: "400",
                        parentHeightOffset: 0,
                        foreColor: colors.bodyColor,
                        background: colors.cardBg,
                        toolbar: {
                            show: true,
                        },
                        stacked: false,
                    },
                    theme: {
                        mode: "light",
                    },
                    tooltip: {
                        enabled: true,
                        style: {
                            opacity: 0.8
                        },
                        theme: "light",
                        x: {
                            show: true,
                        },
                    },
                    title: {
                        align: 'left'
                    },
                    subtitle: {
                        align: 'left'
                    },
                    colors:[
                        colors.stock,
                        // colors.procurement,
                        colors.produksi,
                        colors.konsumsi,
                        colors.penjualan,
                        colors.gagal_produksi,
                        colors.gagal_jual,
                    ],
                    fill: {
                        type: 'solid' //make color chart fill not gradient
                    },
                    grid: {
                        padding: {
                            bottom: -4,
                        },
                        borderColor: colors.gridBorder,
                        xaxis: {
                            lines: {
                                show: true,
                            },
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    xaxis: {
                        type: 'datetime',
                        labels: {
                            format: "dd MMM yyyy",
                        },
                        categories: getDates(props.data),
                        tooltip: {
                            enabled: false,
                        },
                        lines: {
                            show: true,
                        },
                        axisBorder: {
                            color: colors.gridBorder,
                        },
                        axisTicks: {
                            show: true,
                            borderType: 'solid',
                            color: colors.bodyColor,
                        },
                        crosshairs: {
                            stroke: {
                                color: colors.bodyColor,
                            },
                        },
                    },
                    yaxis: {
                        labels: {
                            formatter: function (val: any) {
                                if (typeof val === 'number') {
                                    return val.toLocaleString("id-ID");
                                } else {
                                    return '';
                                }
                            }
                        },
                        opposite: false,
                        title: {
                            text: "Stock (ton)",
                            style: {
                                size: 9,
                                color: colors.muted,
                            },
                        },
                        tickAmount: 10,
                        type: 'numeric',
                        min: undefined,
                        max: undefined,
                        // tooltip: {
                        //     enabled: true,
                        // },
                        crosshairs: {
                            stroke: {
                                color: colors.secondary,
                            },
                        },
                    },
                    markers: {
                        size: 0,
                    },
                    legend: {
                        horizontalAlign: 'center',
                        showForSingleSeries: true,
                        showForNullSeries: true,
                        showForZeroSeries: true,
                    },
                    annotations: {
                        xaxis: [
                            {
                                x: new Date().getTime(),
                                width: '250%',
                                borderColor: "#2A08B6",
                                label: {
                                    borderColor: "#2A08B6",
                                    style: {
                                        color: "#fff",
                                        background: "#2A08B6"
                                    },
                                    orientation: "horizontal",
                                    text: "Penunjuk Hari Ini"
                                }
                            }
                        ]
                    }
                }
            });
        }else if(props.tabIndex == 1){
            setData({
                series: [{
                    name: 'Stock',
                    type: "line",
                    data: getValue(props.data,'beginning_of_stock')
                }, 
                // {
                //     name: 'Procument',
                //     type: "line",
                //     data: getValue(props.data,'end_of_stock')
                // },
                {
                    name: 'Produksi',
                    type: "line",
                    data: getValue(props.data,'production')
                },
                {
                    name: 'Konsumsi',
                    type: "line",
                    data: getValue(props.data,'consumption')
                }
                ],
                options: {
                    chart: {
                        height: 350,
                        type: 'area'
                    },
                    colors:[
                        colors.stock,
                        // colors.procurement,
                        colors.produksi,
                        colors.konsumsi,
                    ],
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    xaxis: {
                        categories: getDates(props.data)
                    },
                }
            });
        }else if(props.tabIndex == 2){
            setData({
                series: [
                    {
                        name: 'Konsumsi',
                        type: "line",
                        data: getValue(props.data,'consumption')
                    }
                ],
                options: {
                    chart: {
                        height: 350,
                        type: 'area'
                    },
                    colors:[
                        colors.stock,
                        // colors.procurement,
                        colors.produksi,
                        colors.konsumsi,
                    ],
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    xaxis: {
                        categories: getDates(props.data)
                    },
                }
            });
        }
    }, [props.tabIndex, props.data]);
    return (
        <div>
            <div id="chart">
                <ApexCharts options={data.options} series={data.series} type="area" height={350} />
            </div>
            <div id="html-dist"></div>
        </div>
    );
}

export default ApexChart;
