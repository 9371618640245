import {AxiosError} from 'axios'
import {Form, Formik, FormikConfig} from 'formik'
import React, {createContext, useContext, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useQueryClient} from 'react-query'
import * as Yup from 'yup'
import {toastError, toastSuccess} from '../../../../../../_metronic/helpers'
import {getEnv} from '../../../../../../helpers/procurex'
import {ApprovalX} from '../../../../../components/shared/ApprovalX'
import FormikSelect2 from '../../../../../components/shared/form/FormikSelect2'
import {EmployeeModel, UserModel} from '../../../../auth'
import {ClarificationTenderItemModel} from '../../../core/_models'
import {
  useRoutingApprovalConfigForSubmitAlihkanQuery,
  useSubmitAlihkanMutation,
} from '../core/_queries'
import EvaluationTenderItemsModalInfo from './ClarificationTenderItemsModalInfo'

type EvaluationAlihkanModalContextType = {
  clarification_tender_item_ids: string[] // uuid
  alihkan_user: UserModel | undefined
}

const EvaluationAlihkanModalContext = createContext<EvaluationAlihkanModalContextType>({
  clarification_tender_item_ids: [],
  alihkan_user: undefined,
})

type ApprovalModalProps = {
  show: boolean
  onHide: () => void
  onSuccess: () => void
  isGetRoutingApprovalConfigEnabled?: boolean
}

const ApprovalModal = ({
  show,
  onHide,
  onSuccess,
  isGetRoutingApprovalConfigEnabled = false,
}: ApprovalModalProps) => {
  const queryClient = useQueryClient()
  const context = useContext(EvaluationAlihkanModalContext)

  const {mutate: submit, isLoading: isLoadingSubmitForApproval} = useSubmitAlihkanMutation()

  const {data: routingApprovalConfig} = useRoutingApprovalConfigForSubmitAlihkanQuery(
    context.clarification_tender_item_ids,
    {
      enabled:
        (show || isGetRoutingApprovalConfigEnabled) &&
        !isLoadingSubmitForApproval &&
        context.clarification_tender_item_ids.length > 0,
      retry: false,
      onError: (error: AxiosError) => {
        toastError(
          error.response.data?.meta?.message || error.response.data?.message || 'Terjadi kesalahan'
        )
        onHide()
      },
    }
  )

  return (
    <ApprovalX
      show={show && routingApprovalConfig !== undefined}
      onHide={onHide}
      readOnly={false}
      isLoading={isLoadingSubmitForApproval}
      schema={routingApprovalConfig?.details}
      onSubmit={(values: {approval: any[]}) => {
        const {alihkan_user, ...rest} = context
        const payload = {
          ...rest,
          user_id: alihkan_user.id,
          approvers: values.approval.map((item: any) => ({
            step: item.step,
            jabatan: item.jabatan,
            type: 'APPROVAL',
            user_id: item.extra_info.user.user_id,
            extra_info: {},
          })),
        }

        submit(payload, {
          onSuccess: async (response) => {
            await queryClient.invalidateQueries([
              'clarification-tender-items',
              'alihkan',
              'routing-approval-config',
            ])

            context.clarification_tender_item_ids.forEach((uuid) => {
              queryClient.invalidateQueries(['clarification-tender-items', uuid, 'results'])
            })

            payload.clarification_tender_item_ids.forEach((uuid) => {
              queryClient.invalidateQueries(['clarification-tender-item', uuid])
            })

            toastSuccess(response.data.meta.message)
            onSuccess()
          },
          onError: (error: AxiosError) => {
            toastError(
              error.response.data?.meta?.message ||
                error.response.data?.message ||
                'Terjadi kesalahan'
            )
          },
        })
      }}
    />
  )
}

interface MainModalFormValues {
  alihkan_user: UserModel
}

interface MainModalProps {
  show: boolean
  onHide: () => void
  clarificationTenderItems: ClarificationTenderItemModel[]
  onSubmit: FormikConfig<MainModalFormValues>['onSubmit']
}

const MainModal = ({show, onHide, clarificationTenderItems, onSubmit}: MainModalProps) => {
  const intl = useIntl()
  const context = useContext(EvaluationAlihkanModalContext)
  const labels = {
    alihkan_user: intl.formatMessage({id: 'Tujuan Pengalihan'}),
  }

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        alihkan_user: context.alihkan_user || '',
      }}
      validationSchema={Yup.object().shape({
        alihkan_user: Yup.mixed().required().label(labels.alihkan_user),
      })}
    >
      {() => (
        <Modal size='lg' show={show} onHide={onHide}>
          <Form>
            <Modal.Header closeButton>
              <h4>{intl?.formatMessage({id: 'Permintaan Pengalihan Evaluasi'})}</h4>
            </Modal.Header>
            <Modal.Body className='p-0'>
              <EvaluationTenderItemsModalInfo
                clarificationTenderItemsFetchParams={{
                  filters: {
                    uuid: context.clarification_tender_item_ids,
                  },
                }}
                queryOptions={{
                  enabled: show,
                }}
                initialData={clarificationTenderItems}
                showBuyerNote={false}
                showBuyerAttachment={false}
              >
                <section title='to_user'>
                  <label className='form-label required' htmlFor='alihkan_user'>
                    {labels.alihkan_user}
                  </label>

                  <FormikSelect2
                    name='alihkan_user'
                    optionsUrl={`${getEnv('sso')}/user?filter[not_vendor]=1`}
                    getOptionLabel={(opt: UserModel) => {
                      const employee: EmployeeModel | null | undefined = opt.employee

                      return `${employee?.dept_title || '-'} (${opt.username} - ${opt.name})`
                    }}
                    getOptionValue={(opt: any) => opt}
                    unique_property='id'
                    search_property={'dept_title_username_name'}
                  />
                </section>
              </EvaluationTenderItemsModalInfo>
            </Modal.Body>
            <Modal.Footer>
              <Button type='button' variant='danger' onClick={onHide}>
                {intl.formatMessage({id: 'Batal'})}
              </Button>
              <Button type='submit' variant='secondary'>
                {intl.formatMessage({id: 'Submit'})}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </Formik>
  )
}

interface EvaluationAlihkanModalProps {
  show: boolean
  onHide: () => void
  clarificationTenderItems: ClarificationTenderItemModel[]
  onSuccess?: () => void
}

export const EvaluationAlihkanModal = ({
  show,
  onHide,
  clarificationTenderItems,
  onSuccess,
}: EvaluationAlihkanModalProps) => {
  const [isShowMainModal, setIsShowMainModal] = useState(show)
  const [isShowApprovalModal, setIsShowApprovalModal] = useState(false)
  const [contextValue, setContextValue] = useState<EvaluationAlihkanModalContextType>({
    clarification_tender_item_ids: clarificationTenderItems.map((item) => item.uuid),
    alihkan_user: undefined,
  })

  useEffect(() => {
    setIsShowMainModal(show)
    setIsShowApprovalModal(false)
  }, [show])

  useEffect(() => {
    setContextValue((prevValue) => ({
      ...prevValue,
      clarification_tender_item_ids: clarificationTenderItems.map((item) => item.uuid),
    }))
  }, [clarificationTenderItems])

  const onHideFn = () => {
    setIsShowApprovalModal(false)
    setIsShowMainModal(false)
    onHide()
  }

  return (
    <EvaluationAlihkanModalContext.Provider value={contextValue}>
      <MainModal
        show={isShowMainModal}
        onHide={onHideFn}
        clarificationTenderItems={clarificationTenderItems}
        onSubmit={(values) => {
          setContextValue({...contextValue, ...values})
          setIsShowMainModal(false)
          setIsShowApprovalModal(true)
        }}
      />

      <ApprovalModal
        show={isShowApprovalModal}
        onHide={onHideFn}
        onSuccess={() => {
          onHideFn()
          onSuccess && onSuccess()
        }}
        isGetRoutingApprovalConfigEnabled={isShowMainModal}
      />
    </EvaluationAlihkanModalContext.Provider>
  )
}
