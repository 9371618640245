import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react'
import {Field, Form, Formik, useFormikContext} from 'formik'
import FormikSelect2 from '../../../../../../components/shared/form/FormikSelect2'
import {Col, Row, Spinner} from 'react-bootstrap'
import FormikSelect3 from '../../../../../../components/shared/form/FormikSelect3'
import * as Yup from 'yup'
import {useAuth} from '../../../../../auth'
import Swal from 'sweetalert2'
import {
  useDeleteDraftTender,
  useGetApprovalSchema,
  usePutDraftTender,
  useSubmitDraft,
  useSubmitTender,
} from '../../../hooks/proposalTenderHook'
import {toast} from 'react-toastify'
import {ApprovalX} from '../../../../../../components/shared/ApprovalX'
import {getEnv, parseStringToBool} from '../../../../../../../helpers/procurex'
import {useAxiosGet, useAxiosPost} from '../../../../../../../hooks/useAxios'
import BottomToolbarWrapper from '../../../../../../components/shared/BottomToolbarWrapper'
import RetenderForm from './RetenderForm'
import {useIntl} from 'react-intl'
import {darkModeClass} from '../../../../../../../helpers/utils'
import CurrencyInput from 'react-currency-input-field'
import axios from 'axios'
import { AlihkanModal } from '../AlihkanModal'
import {useProposalTender} from '../../core/_proposalTenderProvider'
import {useMutation, useQuery} from 'react-query'


const API_URL = process.env.REACT_APP_API_URL;

type IProps = {
  proposalTender: any
  afterSaveDraft?: Function
  show: boolean
  parent: any
  quotation?: any
  onUpdateLoading?: any
  onGoBack: any
  isOwner: boolean
}

export type Ref = HTMLButtonElement;

const ParameterPengadaanPage = forwardRef<Ref, IProps>((props, ref) => {
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = usePutDraftTender(props.proposalTender?.uuid);
  const intl = useIntl();
  const [{
    doGetDetail: doGetDetailApprovalSchema,
    isLoading: isLoadingSchema,
    data: dataSchema
  }] = useGetApprovalSchema();
  const [{
    doSubmit: doSubmitDelete,
    isLoading: isLoadingDelete,
    isError: isErrorDelete,
    data: dataDelete,
    isSuccess: isSuccessDelete
  }] = useDeleteDraftTender(props.proposalTender?.uuid);
  const [{ doSubmit: doSubmitDraft, isLoading: isLoadingDraft }] = useSubmitDraft(props.proposalTender?.uuid);
  const [{
    doSubmit: doSubmitTender,
    isLoading: isLoadingSubmitTender,
    isError: isErrorSubmitTender,
    data: dataSubmitTender,
    isSuccess: isSuccessSubmitTender
  }] = useSubmitTender(props.proposalTender?.uuid);
  const [{ doSubmit: doSubmitPQ, isLoading: isLoadingPQ }] = useAxiosPost(getEnv("tender") + "/pq/submit");
  const [{ doSubmit: doSubmitGet, isLoading: isLoadingGet }] = useAxiosGet(
    getEnv("tender") + "/negotiation"
  );
  const [isShowApprovalSchema, setIsShowApprovalSchema] = React.useState(false);
  const [approvalSchema, setApprovalSchema] = React.useState([]);
  const { currentUser } = useAuth();
  const [isDisabledForm, setIsDisabledForm] = React.useState(true);
  const [isShowApprovalSchmeaSubmitAwarding, setIsShowApprovalSchemaSubmitAwarding] = React.useState(false);
  const [isShowApprovalSchemaPengumumanPemenang, setIsShowApprovalSchemaPengumumanPemenang] = React.useState(false);
  const [isShowApprovalSchemaPenunjukkanPemenang, setIsShowApprovalSchemaPenunjukkanPemenang] = React.useState(false);
  const [isShowApprovalSchemaBatalTender, setIsShowApprovalSchemaBatalTender] = React.useState(false);
  const [approvalSchemaSubmitAwarding, setApprovalSchemaSubmitAwarding] = React.useState([]);
  const [approvalSchemaPengumumanPemenang, setApprovalSchemaPengumumanPemenang] = React.useState([]);
  const [approvalSchemaPenunjukkanPemenang, setApprovalSchemaPenunjukkanPemenang] = React.useState([]);
  const [isCanSubmitForApproval,setIsCanSubmitForApproval] = useState({
    state: false,
    message: ''
  })
  const [isShowRetender,setIsShowRetender] = useState(false);
  const [isShowAlihkan,setIsShowAlihkan] = useState(false);
  const [approvalSchemaBatalTender, setApprovalSchemaBatalTender] = React.useState([]);
  const initialValues = {
    ...props.proposalTender,
    visibilitas: "",
    masa_berlaku: [30,45,60,90].includes(props.proposalTender?.masa_berlaku) ? props.proposalTender?.masa_berlaku : "0",
    masa_berlaku_others: [30,45,60,90].includes(props.proposalTender?.masa_berlaku) ? "" : props.proposalTender?.masa_berlaku,
    delivery_time: [30,45,60,90].includes(props.proposalTender?.delivery_time) ? props.proposalTender?.delivery_time : "0",
    delivery_time_others: [30,45,60,90].includes(props.proposalTender?.delivery_time) ? "" : props.proposalTender?.delivery_time,
    buyer: props.proposalTender?.created_by?.username + " - " + props.proposalTender?.created_by?.name
  };
  const [purchasingGroupIdsForFiltering, setPurchasingGroupIdsForFiltering] = React.useState([]);
  const formSchemaValidation = Yup.object().shape({
    no: Yup.string().required("No. Proposal Tender harus diisi!"),
    reference: Yup.string().nullable(),
    buyer: Yup.string().required("Buyer harus diisi!"),
    title: Yup.string().required("Judul Proposal Tender harus diisi!"),
    metode_pengadaan: Yup.object().nullable().required("Metode Pengadaan harus diisi!"),
    praqualification: Yup.boolean().required("Pra Qualification harus diisi!"),
    required_pengumuman_pemenang: Yup.boolean().nullable().required("Pengumuman Pemenang harus diisi!"),
    required_penunjukkan_pemenang: Yup.boolean().nullable().required("Penunjukkan Pemenang harus diisi!"),
    metode_aanwijzing: Yup.string().nullable().required("Metode Aanwijzing harus diisi!"),
    metode_negosiasi: Yup.string().nullable().required("Metode Negosiasi harus diisi!"),
    metode_penyampaian: Yup.string().nullable().required("Metode Penyampaian harus diisi!"),
    metode_evaluasi: Yup.string().nullable().required("Metode Evaluasi harus diisi!"),
    order_placement: Yup.string().nullable().required("Order Placement harus diisi!"),
    multiwinner: Yup.boolean().required("Multiwinner harus diisi!"),
    incoterm: Yup.object().nullable().required("Incoterm harus diisi!"),
    bid_opening_validation: Yup.string().required("Visibilitas Bid Opening harus diisi!"),
    purchasing_group: Yup.object().nullable().required("Purchasing Group harus diisi!"),
    masa_berlaku: Yup.number().required("Masa Berlaku Penawaran harus diisi!"),
    bid_bond: Yup.boolean().required("Jaminan Penawaran harus diisi!"),
    tkdn: Yup.boolean().required("TKDN harus diisi!"),
    // tkdn_description: Yup.string().when("tkdn", {
    //   is: true,
    //   then: Yup.string().required("Description harus diisi."),
    //   otherwise: Yup.string().nullable()
    // }),
    lingkup_pekerjaan: Yup.string().nullable(),
    internal_note: Yup.string().nullable(),
    external_note: Yup.string().nullable(),
    lokasi_pengiriman: Yup.string().required("Lokasi Pengiriman harus diisi!")
  });
  const formik = useRef({} as any);

  const [formIndex, setFormIndex] = React.useState(0);
  const checkIsFormEnabled = () => {
    const allowedStatus = ["Draft", "DUR"];
    const allowedStatusDetail = ["Draft", "Rejected"];
    return allowedStatus.includes(props.proposalTender.status) &&
      allowedStatusDetail.includes(props.proposalTender.status_detail);
  };
  const getExistsPurchasingGroup = () => {
    // @ts-ignore
    const tmp = [];
    props.proposalTender?.items?.map((v: any) => {
      // @ts-ignore
      if (!tmp.includes(v?.purchasing_group_id)) {
        tmp.push(v?.purchasing_group_id);
      }
    });
    // @ts-ignore
    setPurchasingGroupIdsForFiltering(tmp);
  };
  const checkIsCanSubmitForApproval =  async() => {
    const shouldCheckOnStatus = [
      "DUR",
      "Draft"
    ];
    const shouldCheckOnStatusDetail = [
      "Draft",
      "Rejected"
    ];

    axios
      .get(getEnv("tender") + `/tender-attachment?filter[proposal_tender_id]=${props.proposalTender?.id}`)
      .then((res) => {
        let count = 0
        res.data.data.forEach((vendor:any) => {
          if(vendor?.is_skv && vendor?.media_type?.name !== "PR_TENDER_HPS") count++
        });
        console.log('count', count)
        if(count === 0) {
          setIsCanSubmitForApproval({
            state: false,
            message: "Centang SKV minimal 1 selain HPS"
          })
          return
        }
      })

    axios
      .get(getEnv("tender") + `/tender-attachment?filter[proposal_tender_id]=${props.proposalTender?.id}`)
      .then((res) => {
        let count = 0
        res.data.data.forEach((vendor:any) => {
          if(vendor?.status_tender === "INVITED") count++
        });
      })

    if(props.proposalTender?.praqualification && props.proposalTender?.status === "Draft") return;
    if(shouldCheckOnStatus.includes(props.proposalTender?.status) && shouldCheckOnStatusDetail.includes(props.proposalTender?.status_detail)){
      const newUrl = getEnv('tender') + `/dur/submission/${props.proposalTender?.uuid}`
      doSubmitGet({},newUrl,undefined).then((res:any)=>{
        setIsCanSubmitForApproval({
          state:true,
          message: ''
        })
      }).catch((err:any)=>{
        setIsCanSubmitForApproval({
          state:false,
          message: err?.response?.data?.meta?.message
        })
      })
    }
  }

  const checkIsCanPublishPq =  async() => {
    const shouldCheckOnStatus = [
      "Pra Qualification",
    ];
    const shouldCheckOnStatusDetail = [
      "Draft",
      "Rejected",
    ];

    if(shouldCheckOnStatus.includes(props.proposalTender?.status) && shouldCheckOnStatusDetail.includes(props.proposalTender?.status_detail)){
      const newUrl = getEnv('tender') + `/pq/check-publish-status/${props.proposalTender?.uuid}`
      doSubmitGet({},newUrl,undefined).then((res:any)=>{
        setIsCanSubmitForApproval({
          state:true,
          message: ''
        })
      }).catch((err:any)=>{
        setIsCanSubmitForApproval({
          state:false,
          message: err?.response?.data?.meta?.message
        })
      })
    }
  }

  useEffect(() => {
    if (props.proposalTender) {
      setIsDisabledForm(!checkIsFormEnabled());
      getExistsPurchasingGroup();
      checkIsCanSubmitForApproval();
      checkIsCanPublishPq();
    }
  }, [props.proposalTender]);


  // @ts-ignore
  useImperativeHandle(ref, () => ({
    refSaveDraft() {
      props.onUpdateLoading && props.onUpdateLoading(true);
      return new Promise((resolve, reject) => {

      });
    },
    
    refCancel(title: string, text: string, action?: any) {
      return new Promise((resolve, reject) => {
        props.onUpdateLoading && props.onUpdateLoading(true);
        // @ts-ignore
        Swal.fire({
          title: title,
          text: text,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Ya, Batalkan",
          cancelButtonText: "Tidak"
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            doSubmitDelete(toast, () => {
            }).then((res: any) => {
              resolve("operation successful");
              action && action();
              props.afterSaveDraft && props.afterSaveDraft();
            }).catch(() => {
              reject("Cancel operation failed");
            }).finally(() => {
              props.onUpdateLoading && props.onUpdateLoading(false);
            });
            // Swal.fire('Saved!', '', 'success')
          } else {
            reject("Cancel operation failed");
            props.onUpdateLoading && props.onUpdateLoading(false);
          }
        });
      });
    },

    refCancelPQ(title: string, text: string, action: any) {
      return new Promise((resolve, reject) => {
        props.onUpdateLoading && props.onUpdateLoading(true);
        Swal.fire({
          title: title,
          text: text,
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Ya, cancel",
          cancelButtonText: "Tidak"
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {

            // return new Promise((resolve, reject) => {
            //   doSubmitTender({}, toast, () => {}).then((data:any)=>{
            //     action && action();
            //     resolve('Cancel operation successful');
            //   }).catch(()=>{
            //     reject('Cancel operation failed');
            //   })
            // });
          } else {
            resolve("Cancel operation successful");
            props.onUpdateLoading && props.onUpdateLoading(false);
          }
        });
      });

    },

    refPublishPQ(title: string, text: string, action: any) {
      Swal.fire({
        title: title,
        text: text,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Ya, submit",
        cancelButtonText: "Tidak"
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          doSubmitPQ(
            {},
            getEnv("tender") + `/pq/submit/${props.proposalTender.uuid}`,
            toast
          ).then((res: any) => {
            action && action();
            props.afterSaveDraft && props.afterSaveDraft();
          });
        }
      });
    },
    
    refContinuePQ(title: string, text: string, action: any) {
      return new Promise((resolve, reject) => {
        props.onUpdateLoading && props.onUpdateLoading(true);

      });
    },

    refSubmitBidOpening(title: string, text: string, action: any) {
      Swal.fire({
        title: title,
        text: text,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Ya, publish",
        cancelButtonText: "Tidak"
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        props.onUpdateLoading && props.onUpdateLoading(true);
        if (result.isConfirmed) {
          doSubmitPQ(
            {},
            getEnv("tender") + `/bid/submit/${props.proposalTender.uuid}`,
            toast
          ).then((res: any) => {
            action && action();
            props.afterSaveDraft && props.afterSaveDraft();
          }).finally(() => {
            props.onUpdateLoading && props.onUpdateLoading(false);
          });
          // return new Promise((resolve, reject) => {
          //   doSubmitTender({}, toast, () => {}).then((data:any)=>{
          //     action && action();
          //     resolve('Cancel operation successful');
          //   }).catch(()=>{
          //     reject('Cancel operation failed');
          //   })
          // });
        } else {
          props.onUpdateLoading && props.onUpdateLoading(false);
        }

      });

    },
    
    refSubmit(): Promise<any> {
      return new Promise((resolve, reject) => {
        props.onUpdateLoading && props.onUpdateLoading(true);
        // Jika status DUR/Praqualification dan belum ada approval
        if ((props.proposalTender?.status === "DUR" || props.proposalTender?.status === "Praqualification") && !props.proposalTender.is_on_approval) {
          // doGetDetailApprovalSchema(props.proposalTender?.uuid, toast).then((res: any) => {
          //   setIsShowApprovalSchema(true);
          //   setApprovalSchema(res.details)
          // }).catch((error) => {
          //   toast.error(error)
          //   reject('Cancel operation failed');
          // });
        }
        if (!props.proposalTender?.is_on_approval) {
          doGetDetailApprovalSchema(props.proposalTender?.uuid, toast).then((res: any) => {
            setIsShowApprovalSchema(true);
            setApprovalSchema(res.details);
          }).catch((error) => {
            toast.error(error);
            reject("Cancel operation failed");
          }).finally(() => {
            props.onUpdateLoading && props.onUpdateLoading(false);
          });
          // Swal.fire({
          //   title: "Apakah Anda yakin?",
          //   text: "Anda akan mensubmit Proposal Tender ini",
          //   icon: "question",
          //   showCancelButton: true,
          //   confirmButtonText: "Ya, Submit",
          //   cancelButtonText: "Tidak"
          // }).then((result) => {
          //   /* Read more about isConfirmed, isDenied below */
          //   if (result.isConfirmed) {
          //     if (props.proposalTender?.status === "DUR") {
          //      doGetDetailApprovalSchema(props.proposalTender?.uuid, toast).then((res: any) => {
          //                   setIsShowApprovalSchema(true);
          //                   setApprovalSchema(res.details);
          //                 }).catch((error) => {
          //                   toast.error(error);
          //                   reject("Cancel operation failed");
          //                 }).finally(() => {
          //                   props.onUpdateLoading && props.onUpdateLoading(false);
          //                 })
          //     }
          //   }
          //   else {
          //     reject("Cancel operation failed");
          //     props.onUpdateLoading && props.onUpdateLoading(false);
          //   }
          // });
        }
        // Jika status draft, maka submit terlebih dahulu baru ambil detail approval
        if (props.proposalTender?.is_on_approval) {
          setIsShowApprovalSchema(true);
        }

      });
    },

    refSubmitEvaluasi(title: string, text: string, action: any) {
      return new Promise((resolve, reject) => {
        props.onUpdateLoading && props.onUpdateLoading(true);
        // alert('evaluasi')
        Swal.fire({
          title: title,
          text: text,
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Ya, publish",
          cancelButtonText: "Tidak"
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            // Submit evaluasi vendor
            doSubmitPQ(
              {},
              getEnv("tender") + `/evaluation/submit/${props.proposalTender.uuid}`,
              toast
            ).then((res: any) => {
              // submit evaluasi administrasi
              props.afterSaveDraft && props.afterSaveDraft();
              resolve("Cancel operation successful");
            }).finally(() => {
              resolve("Cancel operation successful");
              props.onUpdateLoading && props.onUpdateLoading(false);
            });
          } else {
            props.onUpdateLoading && props.onUpdateLoading(false);
            reject("Cancel operation failed");
          }
        });
      });

    },

    refSaveKonfigurasiNego(title: string, text: string, payload: any, type: string, action: any) {
      return new Promise((resolve, reject) => {
        props.onUpdateLoading && props.onUpdateLoading(true);
        if (type === "Draft") {
          doSubmitPQ(payload,
            getEnv("tender") + `/negotiation/${payload?.negotiation_id}`,
            toast
          ).then((res: any) => {
            // submit evaluasi administrasi
            props.afterSaveDraft && props.afterSaveDraft();
          }).finally(() => {
            resolve("Cancel operation successful");
          });
        } else {
          Swal.fire({
            title: title,
            text: text,
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Ya, submit",
            cancelButtonText: "Tidak"
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              // Submit evaluasi vendor
              doSubmitPQ({
                  ...payload,
                  process: "Proses Negosiasi"
                },
                getEnv("tender") + `/negotiation/${payload?.negotiation_id}`,
                toast
              ).then((res: any) => {
                // submit evaluasi administrasi
                props.afterSaveDraft && props.afterSaveDraft();
              }).finally(() => {
                resolve("Cancel operation successful");
                props.onUpdateLoading && props.onUpdateLoading(false);
              });
            } else {
              reject("Cancel operation failed");
              props.onUpdateLoading && props.onUpdateLoading(false);
            }
          });
        }

      });
    },

    refSubmitCloseNegosiasi(title: string, text: string, payload: any, action: any) {
      return new Promise((resolve, reject) => {
        Swal.fire({
          title: title,
          text: text,
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Ya, Tutup",
          cancelButtonText: "Batal"
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            // Submit evaluasi vendor
            doSubmitPQ({},
              getEnv("tender") + `/negotiation/close/${props?.proposalTender?.negotiation?.uuid}`,
              toast
            ).then((res: any) => {
              // submit evaluasi administrasi
              props.afterSaveDraft && props.afterSaveDraft();
            }).finally(() => {
              resolve("Cancel operation successful");
              props.onUpdateLoading && props.onUpdateLoading(false);
            });
          } else {
            reject("Cancel operation failed");
            props.onUpdateLoading && props.onUpdateLoading(false);
          }
        });

      });
    },

    refSubmitToAwarding(title: string, text: string, payload: any, action: any) {
      return new Promise((resolve, reject) => {
        Swal.fire({
          title: title,
          text: text,
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Ya, lanjutkan",
          cancelButtonText: "Batal"
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            // Submit evaluasi vendor
            doSubmitPQ({
                _method: "PUT",
                proposal_tender_id: props.proposalTender?.uuid,
                process: "Awarding",
                note: "-"
              },
              getEnv("tender") + `/negotiation/${props?.proposalTender?.negotiation?.uuid}`,
              toast
            ).then((res: any) => {
              // submit evaluasi administrasi
              props.afterSaveDraft && props.afterSaveDraft();
            }).finally(() => {
              resolve("Cancel operation successful");
              props.onUpdateLoading && props.onUpdateLoading(false);
            });
          } else {
            reject("Cancel operation failed");
            props.onUpdateLoading && props.onUpdateLoading(false);
          }
        });

      });
    },

    refSubmitReportAwarding(title: string, text: string, action: any) {
      return new Promise((resolve, reject) => {
        // if(props.proposalTender?.status === "Pengumuman Pemenang" && props.proposalTender?.status_detail === "Draft") {
          const newUrl = getEnv("tender") + `/awarding-reporting/routing-approval-code/${props?.proposalTender?.uuid}`;
          doSubmitGet({}, newUrl, undefined).then((res: any) => {
            setApprovalSchemaSubmitAwarding(res.data?.details);
            setIsShowApprovalSchemaSubmitAwarding(true);
          }).finally(() => {
            resolve("Cancel operation successful");
            props.onUpdateLoading && props.onUpdateLoading(false);
          });
        // } else {
          setIsShowApprovalSchemaSubmitAwarding(true);
        // }
      });
    },

    refSubmitPengumumanPemenang(title: string, text: string, action: any) {
      return new Promise((resolve, reject) => {
        const newUrl = getEnv("tender") + `/awarding-announcement/routing-approval-code/${props?.proposalTender?.uuid}`;
        doSubmitGet({},newUrl, undefined).then((res: any) => {
          setApprovalSchemaPengumumanPemenang(res.data?.details);
          setIsShowApprovalSchemaPengumumanPemenang(true);
        }).finally(() => {
          resolve("Cancel operation successful");
          props.onUpdateLoading && props.onUpdateLoading(false);
        });
      });
    },

    refSubmitPenunjukkanPemenang(title: string, text: string, action: any) {
      return new Promise((resolve, reject) => {
        const newUrl = getEnv("tender") + `/awarding-announcement/routing-approval-code/${props?.proposalTender?.uuid}`;
        doSubmitGet({},newUrl, undefined).then((res: any) => {
          setApprovalSchemaPenunjukkanPemenang(res.data?.details);
          setIsShowApprovalSchemaPenunjukkanPemenang(true);
        }).finally(() => {
          resolve("Cancel operation successful");
          props.onUpdateLoading && props.onUpdateLoading(false);
        });
      });
    },

    refBatalTender() {
      return new Promise((resolve, reject) => {
        const allowedStatusDirectCancel = [
          "Draft",
          "DUR",
        ];
        if(allowedStatusDirectCancel.includes(props.proposalTender?.status)) {
          Swal.fire({
            title: "Apakah Anda yakin?",
            text: "Anda akan membatalkan Proposal Tender ini",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Ya, batalkan",
            cancelButtonText: "Tidak"
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              doSubmitPQ(
                {},
                getEnv("tender") + `/tender/cancel/${props.proposalTender.uuid}`,
                toast
              ).then((res: any) => {
                resolve("Operation Successful");
                props.afterSaveDraft && props.afterSaveDraft();
              }).finally(() => {
                props.onUpdateLoading && props.onUpdateLoading(false);
              });
            } else {
              resolve("Cancel operation successful");
              props.onUpdateLoading && props.onUpdateLoading(false);
            }
          });
        } else {
          const newUrl = getEnv("tender") + `/dur/routing-approval-code-cancel/${props?.proposalTender?.uuid}`;
          doSubmitGet({},newUrl, undefined).then((res: any) => {
            setApprovalSchemaBatalTender(res.data?.details);
            setIsShowApprovalSchemaBatalTender(true);
          }).finally(() => {
            resolve("Cancel operation successful");
            props.onUpdateLoading && props.onUpdateLoading(false);
          });
        }

      });
    },

    refReTender() {
      setIsShowRetender(true)
    },

    refAlihkan() {
      setIsShowAlihkan(true)
    }

  }));

  const generatePayload = (values:any) => {
    return {
      "title": values.title,
      "reference": values.reference,
      "purchasing_group_id": values.purchasing_group?.purch_group_id,
      "praqualification": parseStringToBool(values.praqualification),
      "required_pengumuman_pemenang": parseStringToBool(values.required_pengumuman_pemenang),
      "required_penunjukkan_pemenang": parseStringToBool(values.required_penunjukkan_pemenang),
      "metode_pengadaan_id": values.metode_pengadaan?.id,
      "metode_aanwijzing": values.metode_aanwijzing,
      "metode_negosiasi": values.metode_negosiasi,
      "metode_penyampaian": values.metode_penyampaian,
      "metode_evaluasi": values.metode_evaluasi,
      "order_placement": values.order_placement,
      "bid_bond": parseStringToBool(values.bid_bond),
      "bid_bond_value": values.bid_bond_value ? parseFloat((values.bid_bond_value).toString().replace(/,/,'.')).toFixed(3) : "0.000",
      "bid_opening_validation": values.bid_opening_validation,
      "incoterm_id": values.incoterm?.id,
      "multiwinner": parseStringToBool(values.multiwinner),
      "tkdn": parseStringToBool(values.tkdn),
      // "tkdn_description": parseStringToBool(values.tkdn) ? values.tkdn_description : "-",
      "lokasi_pengiriman": values.lokasi_pengiriman,
      "masa_berlaku": values.masa_berlaku === "0" ? values?.masa_berlaku_others : values.masa_berlaku,
      "delivery_time": values.delivery_time === "0" ? values?.delivery_time_others: values.delivery_time,
      "lingkup_pekerjaan": values.lingkup_pekerjaan,
      "internal_note": values.internal_note,
      "external_note": values.external_note
    }
  }
  
  const handleSaveDraft = async (values: any, errors: any, source="button") => {
    const payload = generatePayload(values);
    await doSubmit(payload, toast, () => {
      // if(source !== "button") 
        props.afterSaveDraft && props.afterSaveDraft();
    });
  };

  const handleOnSubmitApproval = async (values: any) => {
    const payload = {
      approvers: values.approval.map((item: any) => {
        return {
          step: item.step,
          jabatan: item.jabatan,
          type: "APPROVAL",
          user_id: item?.extra_info?.user?.user_id,
          extra_info: item?.extra_info
        };
      })
    };
    doSubmitTender(payload, toast, () => {
    }).then((data: any) => {
      setIsShowApprovalSchema(false);
      props.afterSaveDraft && props.afterSaveDraft();
    }).catch(() => {
    });
  };

  const handleOnBatalTenderWithApproval = async (values: any) => {
    Swal.fire({
      title: intl.formatMessage({id:"Apakah Anda yakin"})+"?",
      text: intl.formatMessage({id:"Anda akan membatalkan Tender ini"}),
      icon: "question",
      showCancelButton: true,
      confirmButtonText: intl.formatMessage({id:"Ya, Submit"}),
      cancelButtonText: intl.formatMessage({id:"Tidak"})
    }).then((result) => {
      const payload = {
        approvers: values.approval.map((item: any) => {
          return {
            step: item.step,
            jabatan: item.jabatan,
            type: "APPROVAL",
            user_id: item.extra_info.user.user_id,
            extra_info: item.extra_info
          };
        }),
        cancel_reason: values?.rejection_note
      };
      const newUrl = getEnv("tender") + `/tender/cancel/${props?.proposalTender?.uuid}`;
      doSubmitPQ(payload, newUrl, toast, () => {
      }).then((data: any) => {
        setIsShowApprovalSchema(false);
        props.afterSaveDraft && props.afterSaveDraft();
      }).catch(() => {
      });
    });

  };

  const handleOnSubmitApprovalSubmitAwarding = async (values: any) => {
    const payload = {
      approvers: values.approval.map((item: any) => {
        return {
          step: item.step,
          jabatan: item.jabatan,
          type: "APPROVAL",
          user_id: item.extra_info.user.user_id,
          extra_info: item.extra_info
        };
      })
    };
    const newUrl = getEnv("tender") + `/awarding-reporting/submit/${props?.proposalTender?.uuid}`;
    doSubmitPQ(payload, newUrl, toast, () => {
    }).then((data: any) => {
      setIsShowApprovalSchema(false);
      props.afterSaveDraft && props.afterSaveDraft();
    }).catch(() => {
    });
  };

  const handleOnSubmitApprovalPengumumanPemenang = async (values: any) => {
    const payload = {
      approvers: values.approval.map((item: any) => {
        return {
          step: item.step,
          jabatan: item.jabatan,
          type: "APPROVAL",
          user_id: item.extra_info.user.user_id,
          extra_info: item.extra_info
        };
      }),
      masa_sanggah: values.masa_sanggah,
    };
    // console.log(payload);

    const newUrl = getEnv("tender") + `/awarding-announcement/submit/${props?.proposalTender?.uuid}`;
    doSubmitPQ(payload, newUrl, toast, () => {
    }).then((data: any) => {
      setIsShowApprovalSchemaPengumumanPemenang(false);
      props.afterSaveDraft && props.afterSaveDraft();
    }).catch(() => {
    });
  };

  const handleOnLanjutPQ = async() => {
    Swal.fire({
      title: `<div className='${darkModeClass()}'>Apakah Anda yakins?</div>`,
      text: "Anda akan melanjutkan ke sesi Pra Qualification",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Ya, lanjutkan",
      cancelButtonText: "Tidak"
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        doSubmitPQ(
          {},
          getEnv("tender") + `/dur/submit/${props.proposalTender.uuid}`,
          toast
        ).then((res: any) => {
          props.afterSaveDraft && props.afterSaveDraft();
        }).finally(() => {
          props.onUpdateLoading && props.onUpdateLoading(false);
        });
      } else {
        props.onUpdateLoading && props.onUpdateLoading(false);
      }
    });
  }

  const handleOnCancelPQ = async() => {
    Swal.fire({
      title: "Apakah Anda yakin?",
      text: "Anda akan membatalkan Pra Qualification",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Ya, lanjutkan",
      cancelButtonText: "Tidak"
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        doSubmitPQ(
          {},
          getEnv("tender") + `/pq/cancel/${props.proposalTender.uuid}`,
          toast
        ).then((res: any) => {
          props.afterSaveDraft && props.afterSaveDraft();
        }).finally(() => {
          props.onUpdateLoading && props.onUpdateLoading(false);
        });
      } else {
        props.onUpdateLoading && props.onUpdateLoading(false);
      }
    });
  }

  //region approval schema pq
  const [isShowApprovalPQ, setIsShowApprovalPQ] = React.useState(false);
  const [pqApprovalCode, setPQApprovalCode] = React.useState(false);
  const [pqApprovalSchema, setPQApprovalSchema] = React.useState([]);
  const [renderKey, setRenderKey] = React.useState(0);

  const {refetch: refetchGetApprovalCode, isLoading: isLoadingGetApprovalCodePq} = useQuery({
    queryKey: ["pra_qualification","approval-code",props?.proposalTender?.uuid],
    refetchOnWindowFocus: false,
    enabled: false,
    queryFn: () => axios.get(getEnv('tender')+`/pq/routing-approval-code/${props?.proposalTender?.uuid}`),
    onSuccess: (res)=> {
      setPQApprovalCode(res?.data?.data?.code)
      setIsShowApprovalPQ(true)
      setPQApprovalSchema(res?.data?.data?.details)
      setRenderKey(renderKey+1)
    },
    onError: (err) => {
    }
  })

  const {mutate: mutateSubmitPQ, isLoading: isLoadingSubmitPq} = useMutation({
    mutationKey: ["pra_qualification","approval",props?.proposalTender?.negotiation?.uuid],
    mutationFn: (payload) => axios.post(getEnv("tender")+`/pq/submit/${props?.proposalTender?.uuid}`,payload),
    onSuccess: (res)=> {
      setIsShowApprovalPQ(false);
      props.afterSaveDraft && props.afterSaveDraft();
      toast.success(res?.data?.meta?.message);
    }
  })

  const handleOnPublishPQ = async() => {
    refetchGetApprovalCode().then(r => {})
  }

  const handleOnPQSubmit = (data:any) => {
    let payload = {
      note: "",
      approvers: data.approval.map((item: any) => {
        return {
          step: item.step,
          jabatan: item.jabatan,
          type: item?.type,
          user_id: item.extra_info.user.user_id,
          extra_info: item.extra_info
          }
        })
      }
      // @ts-ignore
    mutateSubmitPQ(payload)
  }
  // endregion

  const handleOnCancelDUR = async() => {
    Swal.fire({
      title: "Apakah Anda yakin?",
      text: "Anda akan membatalkan sesi DUR ini",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, Batalkan",
      cancelButtonText: "Tidak"
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        doSubmitDelete(toast, () => {
        }).then((res: any) => {
          props.afterSaveDraft && props.afterSaveDraft();
        }).catch(() => {
        }).finally(() => {
          props.onUpdateLoading && props.onUpdateLoading(false);
        });
        // Swal.fire('Saved!', '', 'success')
      } else {
        props.onUpdateLoading && props.onUpdateLoading(false);
      }
    });
  }

  const handleOnSubmitTender = async () => {
    doGetDetailApprovalSchema(props.proposalTender?.uuid, toast).then((res: any) => {
      setIsShowApprovalSchema(true);
      setApprovalSchema(res.details);
    }).catch((error) => {
      toast.error(error);
    }).finally(() => {
      props.onUpdateLoading && props.onUpdateLoading(false);
    });
  }

  const isShowLanjutPQ = () => {
    return props?.proposalTender?.status === "Draft" &&
      props?.proposalTender?.status_detail === "Draft" &&
      props?.proposalTender?.praqualification;
  }

  const isShowSubmit = () => {
    if(
      props?.proposalTender?.praqualification &&
      props?.proposalTender?.status === "Draft" &&
      props?.proposalTender?.status_detail === "Draft"
    ) return false;
    const allowedStatus = ["Draft", "Rejected", "DUR"];
    const allowedStatusDetail = ["Draft","Rejected"]
    return allowedStatus.includes(props?.proposalTender?.status) &&
      allowedStatusDetail.includes(props?.proposalTender?.status_detail);
  }

  const isShowSubmitPQ = () => {
    return props?.proposalTender?.status === "Pra Qualification" &&
      (props?.proposalTender?.status_detail === "Draft" || props?.proposalTender?.status_detail === "Rejected") &&
      props?.proposalTender?.praqualification;
  }

  const isShowCancelDUR = () => {
    const allowedStatusDetail = ["Draft","Rejected"]
    return props?.proposalTender?.status === "DUR" &&
      allowedStatusDetail.includes(props?.proposalTender?.status_detail)
  }

  const debounce = (func, delay) => {
    let debounceTimer;
    return function() {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    }
  }

  const debounceAutoSave = debounce((values:any)=>{
    const payload = {
      ...generatePayload(formik.current.values),
      source: "autosave"
    }
    axios.put(getEnv('tender') + "/tender/"+props?.proposalTender?.uuid,payload)
  },1000)

  const handleAutoSave = (values:any) => {
    if(
      (props?.proposalTender?.status === "Draft" && props?.proposalTender?.status_detail === "Draft")
    ){
      // @ts-ignore
      debounceAutoSave(values)
    }
  };

  return (
    <div>

      <Formik
        innerRef={formik}
        key={`formik-tender-parameter-pengadaan-` + formIndex}
        initialValues={initialValues}
        onSubmit={handleSaveDraft}
        validationSchema={formSchemaValidation}
        enableReinitialize
        validateOnChange={true}

      >
        {({ errors, touched, values, setFieldValue, resetForm, submitForm }: any) => {
          handleAutoSave(values)
          return (
            <Form id="form-hps">
              <>
                {isCanSubmitForApproval?.message && !isShowLanjutPQ() && (
                  <div className={"alert alert-warning text-danger"}>
                    <i className={"fa fa-warning text-danger me-2"}></i> {isCanSubmitForApproval?.message}
                  </div>
                )}
                <div className={"d-flex justify-content-between"}>
                  <div><h3>{intl.formatMessage({id:'Parameter Pengadaan'})}</h3></div>
                </div>
                <hr />

                <div className={"row"}>
                  <div className={"col-md-6 col-sm-12 col-lg-6"}>
                    <div className={"form-group mb-3"}>
                      <label className="form-label">
                        {intl.formatMessage({id:'No. Proposal Tender'})}
                      </label>
                      <Field
                        name="no"
                        type="text"
                        className="form-control"
                        disabled={true}
                      />
                      {(errors.no && touched.no) && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.no}</div>
                        </div>
                      )}
                    </div>

                    <div className={"form-group mb-3"}>
                      <label className="form-label">
                        {intl.formatMessage({id:'Buyer'})}
                      </label>
                      <Field
                        name="buyer"
                        disabled={true}
                        type="text"
                        className="form-control"
                        required={true}
                      />
                      {(errors.buyer && touched.buyer) && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.buyer}</div>
                        </div>
                      )}
                    </div>

                    <div className={"form-group mb-3"}>
                      <label className="required form-label">
                        {intl.formatMessage({id:'Judul Proposal Tender'})}
                      </label>
                      <Field
                        name="title"
                        type="text"
                        className="form-control"
                        disabled={isDisabledForm}
                        onChange={(e: any) => {
                          setFieldValue('title', e.target.value)
                        }}
                      />
                      {(errors.title && touched.title) && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.title}</div>
                        </div>
                      )}
                    </div>
                    <div className={"form-group mb-3"}>
                      <label className="required form-label">
                        {intl.formatMessage({id:'Metode Pengadaan'})}
                      </label>
                      <FormikSelect2
                        name="metode_pengadaan"
                        optionsUrl={`${API_URL}/api/masterdata/metode-pengadaan?filter[company_id]=${props.proposalTender?.company_code}`}
                        unique_property={"id"}
                        search_property={"name"}
                        getOptionValue={(opt: any) => opt}
                        getOptionLabel={(opt: any) =>
                          `${opt.name}`
                        }
                        isDisabled={isDisabledForm}
                        onChangeValue={(opt: any) => {
                          if (props.proposalTender?.status === "Draft") {
                            setFieldValue("praqualification", opt.pra_qualification);
                          }
                        }}
                      />
                      {(errors.metode_pengadaan_id && touched.metode_pengadaan_id) && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.metode_pengadaan_id}</div>
                        </div>
                      )}
                    </div>
                    <div className={"form-group mb-3"}>
                      <label>
                        {intl.formatMessage({id:'Pra Qualification'})}
                      </label>
                      <Row className={"mt-2"}>
                        <Col>
                          <label>
                            <Field
                              type="radio"
                              // disabled={isDisabledForm || values?.metode_pengadaan?.pra_qualification}
                              disabled={isDisabledForm || (props.proposalTender?.status === "DUR")}
                              name="praqualification"
                              className={"me-2"}
                              value={true}
                              checked={ parseStringToBool(values.praqualification)}
                              onChange={(e: any) => {
                                setFieldValue('praqualification', parseStringToBool(e.target.value))
                              }}
                            />
                            {intl.formatMessage({id:'Ya'})}
                          </label>
                        </Col>
                        <Col>
                          <label>
                            <Field type="radio" name="praqualification" className={"me-2"} value={false}
                              // disabled={isDisabledForm || values?.metode_pengadaan?.pra_qualification}
                                   disabled={isDisabledForm}
                                   checked={!parseStringToBool(values.praqualification)}
                                   onChange={(e: any) => {
                                     setFieldValue('praqualification', parseStringToBool(e.target.value))
                                   }}
                            />
                            {intl.formatMessage({id:'Tidak'})}
                          </label>
                        </Col>
                      </Row>
                      {(errors.praqualification && touched.praqualification) && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.praqualification}</div>
                        </div>
                      )}
                    </div>

                    <div className={"form-group mb-3"}>
                      <label>
                        {intl.formatMessage({id:'Pengumuman Pemenang'})}
                      </label>
                      <Row className={"mt-2"}>
                        <Col>
                          <label>
                            <Field
                              type="radio"
                              disabled={isDisabledForm}
                              name="required_pengumuman_pemenang"
                              className={"me-2"}
                              value={true}
                              checked={values.required_pengumuman_pemenang === null ? false : parseStringToBool(values.required_pengumuman_pemenang)}
                              onChange={(e: any) => {
                                setFieldValue('required_pengumuman_pemenang', parseStringToBool(e.target.value))
                              }}
                            />
                            {intl.formatMessage({id:'Ya'})}
                          </label>
                        </Col>
                        <Col>
                          <label>
                            <Field type="radio" name="required_pengumuman_pemenang" className={"me-2"} value={false}
                                   disabled={isDisabledForm}
                                   checked={values.required_pengumuman_pemenang === null ? false : !parseStringToBool(values.required_pengumuman_pemenang)}
                                   onChange={(e: any) => {
                                     setFieldValue('required_pengumuman_pemenang', parseStringToBool(e.target.value))
                                   }}
                            />
                            {intl.formatMessage({id:'Tidak'})}
                          </label>
                        </Col>
                      </Row>
                      {(errors.required_pengumuman_pemenang && touched.required_pengumuman_pemenang) && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.required_pengumuman_pemenang}</div>
                        </div>
                      )}
                    </div>

                    <div className={"form-group mb-3"}>
                      <label>{intl.formatMessage({id:'Penunjukkan Pemenang'})}</label>
                      <Row className={"mt-2"}>
                        <Col>
                          <label>
                            <Field
                              type="radio"
                              disabled={isDisabledForm}
                              name="required_penunjukkan_pemenang"
                              className={"me-2"}
                              value={true}
                              checked={values.required_penunjukkan_pemenang === null ? false : parseStringToBool(values.required_penunjukkan_pemenang)}
                              onChange={(e: any) => {
                                setFieldValue('required_penunjukkan_pemenang', parseStringToBool(e.target.value))
                              }}
                            />
                            {intl.formatMessage({id:'Ya'})}
                          </label>
                        </Col>
                        <Col>
                          <label>
                            <Field type="radio"
                                   name="required_penunjukkan_pemenang"
                                   className={"me-2"}
                                   value={false}
                                   disabled={isDisabledForm}
                                   checked={values.required_penunjukkan_pemenang === null ? false : !parseStringToBool(values.required_penunjukkan_pemenang)}

                                   onChange={(e: any) => {
                                     setFieldValue('required_penunjukkan_pemenang', parseStringToBool(e.target.value))
                                   }}
                            />
                            {intl.formatMessage({id:'Tidak'})}
                          </label>
                        </Col>
                      </Row>
                      {(errors.required_penunjukkan_pemenang && touched.required_penunjukkan_pemenang) && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.required_penunjukkan_pemenang}</div>
                        </div>
                      )}
                    </div>

                    <div className={"form-group mb-3"}>
                      <FormikSelect3
                        label={intl.formatMessage({id:'Metode Aanwijzing'})}
                        name="metode_aanwijzing"
                        optionsUrl={`${API_URL}/api/masterdata/enumeration?filter[type]=TENDER_METODE_AANWIJZING`}
                        getOptionValue={(opt: any) => opt.value}
                        getOptionLabel={(opt: any) =>
                          `${opt.value}`
                        }
                        isDisabled={isDisabledForm}
                      />
                    </div>

                    <div className={"form-group mb-3"}>
                      <FormikSelect3
                        label={intl.formatMessage({id:'Metode Negosiasi'})}
                        name="metode_negosiasi"
                        optionsUrl={`${API_URL}/api/masterdata/enumeration?filter[type]=TENDER_METODE_NEGOSIASI`}
                        getOptionValue={(opt: any) => opt.value}
                        getOptionLabel={(opt: any) =>
                          `${opt.value}`
                        }
                        isDisabled={isDisabledForm}
                      />
                    </div>

                    <div className={"form-group mb-3"}>
                      <FormikSelect3
                        label={intl.formatMessage({id:'Metode Penyampaian'})}
                        name="metode_penyampaian"
                        optionsUrl={`${API_URL}/api/masterdata/enumeration?filter[type]=TENDER_METODE_PENYAMPAIAN`}
                        getOptionValue={(opt: any) => opt.value}
                        getOptionLabel={(opt: any) =>
                          `${opt.value}`
                        }
                        isDisabled={isDisabledForm}
                      />
                    </div>

                    <div className={"form-group mb-3"}>
                      <FormikSelect3
                        label={intl.formatMessage({id:'Metode Evaluasi'})}
                        name="metode_evaluasi"
                        optionsUrl={`${API_URL}/api/masterdata/enumeration?filter[type]=TENDER_METODE_EVALUASI`}
                        getOptionValue={(opt: any) => opt.value}
                        getOptionLabel={(opt: any) =>
                          `${opt.value}`
                        }
                        isDisabled={isDisabledForm}
                      />
                    </div>

                    <div className={"form-group mb-3"}>
                      <FormikSelect3
                        label={intl.formatMessage({id:'Order Placement'})}
                        name="order_placement"
                        optionsUrl={`${API_URL}/api/masterdata/enumeration?filter[type]=TENDER_ORDER_PLACEMENT`}
                        getOptionValue={(opt: any) => opt.value}
                        getOptionLabel={(opt: any) =>
                          `${opt.value}`
                        }
                        isDisabled={isDisabledForm}
                        onChangeValue={(opt: any) => {
                          if (opt?.value === "Itemize") {
                            setFieldValue("multiwinner", true);
                          } else {
                            setFieldValue("multiwinner", false);
                          }
                        }}
                      />
                    </div>

                    <div className={"form-group mb-3"}>
                      <label>{intl.formatMessage({id:'Multiwinner'})}</label>
                      <Row className={"mt-2"}>
                        <Col>
                          <Field type={"radio"}
                                 disabled={isDisabledForm || values.order_placement !== "Itemize"}
                                 name={"multiwinner"}
                                 value={true}
                                 checked={parseStringToBool(values.multiwinner)}
                                 onChange={(e: any) => {
                                   setFieldValue('multiwinner', parseStringToBool(e.target.value))
                                 }}
                          />
                          {intl.formatMessage({id:'Ya'})}
                        </Col>
                        <Col>
                          <Field type={"radio"} disabled={isDisabledForm} name={"multiwinner"} value={false}
                                 checked={!parseStringToBool(values.multiwinner)}
                                 onChange={(e: any) => {
                                   setFieldValue('multiwinner', parseStringToBool(e.target.value))
                                 }}
                          /> {intl.formatMessage({id:'Tidak'})}
                        </Col>
                      </Row>
                      {(errors.multiwinner && touched.multiwinner) && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.multiwinner}</div>
                        </div>
                      )}
                    </div>

                    <div className={"form-group mb-3"}>
                      <div className={"row"}>
                        <div className={"col-4"}>
                          <label className="required form-label">{intl.formatMessage({id:'Incoterm'})}</label>
                          <FormikSelect2
                            name="incoterm"
                            optionsUrl={`${API_URL}/api/masterdata/incoterm`}
                            unique_property={"id"}
                            search_property={"code_name"}
                            getOptionValue={(opt: any) => opt}
                            getOptionLabel={(opt: any) =>
                              `${opt.code}`
                            }
                            isDisabled={isDisabledForm}
                          />
                          {
                            (errors.incoterm_id && touched.incoterm_id) && (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{errors.incoterm_id}</div>
                              </div>
                            )
                          }
                        </div>
                        <Col>
                          <label className={"mb-2 required"} style={{
                            fontWeight: "450"
                          }}>{intl.formatMessage({id:'Lokasi Pengiriman / Pekerjaan'})}</label>
                          <Field
                            name="lokasi_pengiriman"
                            type="text"
                            className="form-control"
                            disabled={isDisabledForm}
                            label={"Incoterm"}
                            onChange={(e: any) => {
                              setFieldValue('lokasi_pengiriman', e.target.value)
                            }} />
                          {
                            (errors.lokasi_pengiriman && touched.lokasi_pengiriman) && (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{errors.lokasi_pengiriman}</div>
                              </div>
                            )
                          }
                        </Col>
                      </div>
                    </div>

                    <div className={"form-group mb-3"}>
                      <FormikSelect3
                        label={intl.formatMessage({id:'Visibilitas Bid Opening'})}
                        name="bid_opening_validation"
                        optionsUrl={`${API_URL}/api/masterdata/enumeration?filter[type]=TENDER_VISIBILITAS`}
                        getOptionValue={(opt: any) => opt.value}
                        getOptionLabel={(opt: any) =>
                          `${opt.value}`
                        }
                        isDisabled={isDisabledForm}
                        menuPlacement={"top"}
                      />
                    </div>

                  </div>
                  <div className={'col-md-6 col-sm-12 col-lg-6'}>
                    <div className={'form-group mb-3'}>
                      <label className="form-label">
                        {intl.formatMessage({id: 'Referensi'})}
                      </label>
                      <Field
                        name="reference"
                        type="text"
                        className="form-control"
                        label={'Incoterm'}
                        disabled={isDisabledForm}
                        onChange={(e: any) => {
                          setFieldValue('reference', e.target.value)
                        }}
                      />
                      {
                        (errors.reference && touched.reference) && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{errors.reference}</div>
                          </div>
                        )
                      }
                    </div>
                    <div className={'form-group mb-3'}>
                      <FormikSelect2
                        label={intl.formatMessage({id: 'Purchasing Group'})}
                        name="purchasing_group"
                        optionsUrl={`${API_URL}/api/masterdata/purchasing-group?filter[comp_code]=${currentUser?.company_code}&filter[purch_group_id]=${purchasingGroupIdsForFiltering.join(',')}`}
                        getOptionValue={(opt: any) => opt}
                        getOptionLabel={(opt: any) =>
                          `${opt.purch_group_id} - ${opt.purch_group_name}`
                        }
                        unique_property={'id'}
                        search_property="purch_group_id_category_name"
                        isDisabled={isDisabledForm}
                        onChangeValue={(opt: any) => {
                          if (opt?.comp_code === 'A000') {
                            setFieldValue('required_penunjukkan_pemenang', true)
                          }
                        }}
                      />
                      {(errors.purchasing_group_id && touched.purchasing_group_id) && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.purchasing_group_id}</div>
                        </div>
                      )}
                    </div>

                    <div className={'form-group mb-9'}>
                      <label className={'form-label'}>{intl.formatMessage({id: 'Delivery Time'})}</label>
                      <Row className={'mt-2'}>
                        <Col><Field type={'radio'} disabled={isDisabledForm} name={'delivery_time'} value={30}
                                    checked={parseInt(values.delivery_time) === 30}
                                    onChange={(e: any) => {
                                      setFieldValue('delivery_time', 30)
                                    }}
                        /> 30 {intl.formatMessage({id: 'Hari'})}</Col>
                        <Col><Field type={'radio'} disabled={isDisabledForm} name={'delivery_time'} value={45}
                                    checked={parseInt(values.delivery_time) === 45}
                                    onChange={(e: any) => {
                                      setFieldValue('delivery_time', 45)
                                    }}
                        /> 45 {intl.formatMessage({id: 'Hari'})}</Col>
                        <Col><Field type={'radio'} disabled={isDisabledForm} name={'delivery_time'} value={60}
                                    checked={parseInt(values.delivery_time) === 60}
                                    onChange={(e: any) => {
                                      setFieldValue('delivery_time', 60)
                                    }}
                        /> 60 {intl.formatMessage({id: 'Hari'})}</Col>
                        <Col><Field type={'radio'} disabled={isDisabledForm} name={'delivery_time'} value={90}
                                    checked={parseInt(values.delivery_time) === 90}
                                    onChange={(e: any) => {
                                      setFieldValue('delivery_time', 90)
                                    }}
                        /> 90 {intl.formatMessage({id: 'Hari'})}</Col>
                        <Col>
                          <Field type={'radio'} disabled={isDisabledForm} name={'delivery_time'} value={0}
                                 checked={parseInt(values.delivery_time) === 0}
                                 onChange={(e: any) => {
                                   setFieldValue('delivery_time', 0)
                                 }}
                          />
                          <Field
                            type={'text'}
                            className={'ms-2'}
                            name={'delivery_time_others'} style={{width: '50px'}}
                            // disabled={true}
                            // disabled={values.masa_berlaku == '90' }
                            disabled={[30, 45, 60, 90].includes(parseInt(values.delivery_time)) || isDisabledForm}
                            // const invalidValue = [30, 45, 60, 90].includes(parseInt(values.masa_berlaku));
                            // return !invalidValue;
                            onChange={(e: any) => {
                              const value = parseInt(e.target.value)
                              setFieldValue('delivery_time_others', isNaN(value) ? '' : value)
                            }}
                          />
                        </Col>
                      </Row>
                      {(errors.delivery_time && touched.delivery_time) && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.delivery_time}</div>
                        </div>
                      )}
                    </div>

                    <div className={'form-group mb-5'}>
                      <label
                        className={'required form-label'}>{intl.formatMessage({id: 'Masa Berlaku Penawaran'})}</label>
                      <Row className={'mt-2'}>
                        <Col><Field type={'radio'} disabled={isDisabledForm} name={'masa_berlaku'} value={30}
                                    checked={parseInt(values.masa_berlaku) === 30}
                                    onChange={(e: any) => {
                                      setFieldValue('masa_berlaku', 30)
                                    }}
                        /> 30 {intl.formatMessage({id: 'Hari'})}</Col>
                        <Col><Field type={'radio'} disabled={isDisabledForm} name={'masa_berlaku'} value={45}
                                    checked={parseInt(values.masa_berlaku) === 45}
                                    onChange={(e: any) => {
                                      setFieldValue('masa_berlaku', 45)
                                    }}
                        /> 45 {intl.formatMessage({id: 'Hari'})}</Col>
                        <Col><Field type={'radio'} disabled={isDisabledForm} name={'masa_berlaku'} value={60}
                                    checked={parseInt(values.masa_berlaku) === 60}
                                    onChange={(e: any) => {
                                      setFieldValue('masa_berlaku', 60)
                                    }}
                        /> 60 {intl.formatMessage({id: 'Hari'})}</Col>
                        <Col><Field type={'radio'} disabled={isDisabledForm} name={'masa_berlaku'} value={90}
                                    checked={parseInt(values.masa_berlaku) === 90}
                                    onChange={(e: any) => {
                                      setFieldValue('masa_berlaku', 90)
                                    }}
                        /> 90 {intl.formatMessage({id: 'Hari'})}</Col>
                        <Col>
                          <Field type={'radio'} disabled={isDisabledForm} name={'masa_berlaku'} value={0}
                                 checked={parseInt(values.masa_berlaku) === 0}
                                 onChange={(e: any) => {
                                   setFieldValue('masa_berlaku', 0)
                                 }}
                          />
                          <Field
                            type={'text'}
                            className={'ms-2'}
                            name={'masa_berlaku_others'} style={{width: '50px'}}
                            // disabled={true}
                            // disabled={values.masa_berlaku == '90' }
                            disabled={[30, 45, 60, 90].includes(parseInt(values.masa_berlaku)) || isDisabledForm}
                            // const invalidValue = [30, 45, 60, 90].includes(parseInt(values.masa_berlaku));
                            // return !invalidValue;
                            onChange={(e: any) => {
                              const value = parseInt(e.target.value)
                              setFieldValue('masa_berlaku_others', isNaN(value) ? '' : value)
                            }}
                          />
                        </Col>
                      </Row>
                      {(errors.masa_berlaku && touched.masa_berlaku) && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.masa_berlaku}</div>
                        </div>
                      )}
                    </div>
                    
                    <div className={'form-group mb-5'}>
                      <label
                        className={'form-label'}>{intl.formatMessage({id: 'Nilai Jaminan Penawaran (Bid Bond)'})}</label>
                      <Row className={'mt-2'}>
                        <Col>
                            <Field type={'radio'} disabled={isDisabledForm} name={'bid_bond'} value={true}
                                   checked={parseStringToBool(values.bid_bond)}
                                   onChange={(e: any) => {
                                     setFieldValue('bid_bond', parseStringToBool(e.target.value))
                                   }} /> {intl.formatMessage({id: 'Ya'})}
                        </Col>
                        <Col>
                          <Field
                            type={'radio'}
                            disabled={isDisabledForm}
                            name={'bid_bond'}
                            value={false}
                            checked={!parseStringToBool(values.bid_bond)}
                            style={{
                              marginTop: '3px',
                            }}
                            onChange={(e: any) => {
                              setFieldValue('bid_bond', parseStringToBool(e.target.value))
                            }}
                          />&nbsp;
                          {intl.formatMessage({id: 'Tidak'})}
                        </Col>
                      </Row>

                      {(errors.bid_bond && touched.bid_bond) && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.bid_bond}</div>
                        </div>
                      )}
                    </div>
                    <div className={'form-group mb-5'}>
                      <label className={'form-label'}>{intl.formatMessage({id: 'TKDN'})}</label>
                      <Row className={'mt-2'}>
                        <Col>
                          <Field type={'radio'} disabled={isDisabledForm} name={'tkdn'} value={true}
                                 checked={parseStringToBool(values.tkdn)}
                                 onChange={(e: any) => {
                                   setFieldValue('tkdn', parseStringToBool(e.target.value))
                                 }} /> {intl.formatMessage({id: 'Ya'})} &nbsp;
                          {/*<Field type={"text"} name={"tkdn_description"} style={{*/}
                          {/*  maxWidth: "50px"*/}
                          {/*}}*/}
                          {/*       disabled={!parseStringToBool(values.tkdn) || isDisabledForm} />*/}
                          {(errors.tkdn_description && touched.tkdn_description) && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">{errors.tkdn_description}</div>
                            </div>
                          )}
                        </Col>
                        <Col><Field type={'radio'} disabled={isDisabledForm} name={'tkdn'} value={false}
                                    checked={!parseStringToBool(values.tkdn)}
                                    onChange={(e: any) => {
                                      setFieldValue('tkdn', parseStringToBool(e.target.value))
                                    }} /> {intl.formatMessage({id: 'Tidak'})}</Col>
                      </Row>
                      {(errors.tkdn && touched.tkdn) && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.tkdn}</div>
                        </div>
                      )}
                    </div>
                    <div className={'form-group mb-3'}>
                      <label className={'form-label'}>{intl.formatMessage({id: 'Lingkup Pekerjaan'})}</label>
                      <Field as={'textarea'} disabled={isDisabledForm} className={'form-control'}
                             name={'lingkup_pekerjaan'}
                             onChange={(e: any) => {
                               setFieldValue('lingkup_pekerjaan', e.target.value)
                             }}></Field>
                    </div>
                    <div className={'form-group mb-3'}>
                      <label className={'form-label'}>{intl.formatMessage({id: 'Catatan Internal'})}</label>
                      <Field as={'textarea'} disabled={isDisabledForm} className={'form-control'}
                             name={'internal_note'}
                             onChange={(e: any) => {
                               setFieldValue('internal_note', e.target.value)
                             }}></Field>
                    </div>
                    <div className={'form-group'}>
                      <label className={'form-label'}>{intl.formatMessage({id: 'Catatan External'})}</label>
                      <Field as={'textarea'} disabled={isDisabledForm} className={'form-control'}
                             name={'external_note'}
                             onChange={(e: any) => {
                               setFieldValue('external_note', e.target.value)
                             }}></Field>
                    </div>

                  </div>
                  {/*  End Col-6 */}
                </div>
                <BottomToolbarWrapper isOwner={props?.isOwner} onGoBack={props?.onGoBack} children={
                  <>
                    {props?.proposalTender?.status !== 'Pra Qualification' &&
                      <button
                        className={'btn btn-info'}
                        type={'button'}
                        onClick={submitForm}
                        disabled={isDisabledForm || isLoadingSubmitTender}
                      >
                        {isLoading && <Spinner className={'spinner-grow-sm'} animation="border" size="sm" />}
                        {!isLoading && <i className={'fa fa-save me-2'}></i>}
                        {intl.formatMessage({id: 'Save as Draft'})}
                      </button>
                    }
                    {isShowCancelDUR() &&
                      <button
                        className={'btn btn-danger ms-3'}
                        type={'button'}
                        onClick={handleOnCancelDUR}
                        disabled={isDisabledForm || isLoadingSubmitTender}
                      >
                        {isLoading && <Spinner className={'spinner-grow-sm me-2'} animation="border" size="sm" />}
                        {!isLoading && <i className={'fa fa-undo me-2'}></i>}
                        {intl.formatMessage({id: 'Batalkan DUR'})}
                      </button>
                    }
                    {isShowSubmit() &&
                      <button
                        className={'btn btn-primary ms-3'}
                        type={'button'}
                        onClick={handleOnSubmitTender}
                        disabled={isDisabledForm || isLoadingSubmitTender || !isCanSubmitForApproval?.state}

                      >
                        {isLoading && <Spinner className={'spinner-grow-sm me-2'} animation="border" size="sm" />}
                        {!isLoading && <i className={'fa fa-paper-plane me-2'}></i>}
                        {intl.formatMessage({id: 'Submit for Approval'})}
                      </button>
                    }
                    {isShowLanjutPQ() &&
                      <button
                        className={'btn btn-primary ms-3'}
                        type={'button'}
                        onClick={handleOnLanjutPQ}
                        disabled={isDisabledForm || isLoadingSubmitTender}
                      >
                        {isLoading && <Spinner className={'spinner-grow-sm me-2'} animation="border" size="sm" />}
                        {!isLoading && <i className={'fa fa-arrow-right me-2'}></i>}
                        {intl.formatMessage({id: 'Lanjutkan ke PQ'})}
                      </button>
                    }

                    {isShowSubmitPQ() && (
                      <>
                        <button
                          className={'btn btn-danger me-3'}
                          type={'button'}
                          onClick={handleOnCancelPQ}
                          disabled={isLoadingSubmitTender}
                        >
                          {isLoading && <Spinner className={'spinner-grow-sm me-2'} animation="border" size="sm" />}
                          {!isLoading && <i className={'fa fa-undo me-2'}></i>}
                          {intl.formatMessage({id: 'Batalkan PQ'})}
                        </button>
                        <button
                          className={'btn btn-primary'}
                          type={'button'}
                          onClick={handleOnPublishPQ}
                          disabled={isLoadingSubmitTender || !isCanSubmitForApproval?.state}
                        >
                          {isLoading && <Spinner className={"spinner-grow-sm me-2"} animation="border" size="sm" />}
                          {!isLoading && <i className={"fa fa-globe me-2"}></i> }
                          {intl.formatMessage({id:'Submit PQ'})}
                        </button>
                      </>
                    )}

                    {/*{isShowClosePQ() && (*/}
                    {/*  <>*/}
                    {/*    <button*/}
                    {/*      className={"btn btn-primary"}*/}
                    {/*      type={"button"}*/}
                    {/*      onClick={handleOnClosePQ}*/}
                    {/*      disabled={isLoadingSubmitTender}*/}
                    {/*    >*/}
                    {/*      {isLoading && <Spinner className={"spinner-grow-sm me-2"} animation="border" size="sm" />}*/}
                    {/*      {!isLoading && <i className={"fa fa-flag me-2"}></i> }*/}
                    {/*      Tutup PQ*/}
                    {/*    </button>*/}
                    {/*  </>*/}
                    {/*)}*/}

                  </>
                } />


              </>
              {/*  End Row */}
            </Form>
          )
        }}
      </Formik>

      <ApprovalX
        show={isShowApprovalSchema}
        schema={approvalSchema}
        onHide={() => {
          setIsShowApprovalSchema(false);
        }}
        onSubmit={(data: any) => {
          handleOnSubmitApproval(data);
        }}
        isLoading={isLoadingSubmitTender}
        source={"TENDER"}
        object_id={props.proposalTender?.id}
        company_id={props.proposalTender?.company?.id}
        code={props.proposalTender?.routing_approval_config_code}
        readOnly={props.proposalTender?.is_on_approval}
      />

      <ApprovalX
        title={"Laporan Pengadaan"}
        show={isShowApprovalSchmeaSubmitAwarding}
        schema={approvalSchemaSubmitAwarding}
        onHide={() => {
          setIsShowApprovalSchemaSubmitAwarding(false);
        }}
        onSubmit={(data: any) => {
          handleOnSubmitApprovalSubmitAwarding(data);
        }}
        isLoading={isLoadingSubmitTender}
        source={"TENDER"}
        object_id={props.proposalTender?.id}
        company_id={props.proposalTender?.company?.id}
        code={"tender_procurement_reporting"}
        readOnly={isLoadingPQ}
        // readOnly={props.proposalTender?.is_on_approval}
      />

      {isShowApprovalSchemaPenunjukkanPemenang && (
        <ApprovalX
          title={"Penunjukkan Pemenang"}
          show={isShowApprovalSchemaPenunjukkanPemenang}
          schema={approvalSchemaPenunjukkanPemenang}
          onHide={() => {
            setIsShowApprovalSchemaPenunjukkanPemenang(false);
          }}
          onSubmit={(data: any) => {
            // handleOnSubmitApprovalSubmitAwarding(data);
          }}
          isLoading={isLoadingSubmitTender}
          source={"TENDER"}
          object_id={props.proposalTender?.id}
          company_id={props.proposalTender?.company?.id}
          code={props?.proposalTender?.routing_approval_config_code}
          readOnly={props?.proposalTender?.is_on_approval}
          // readOnly={props.proposalTender?.is_on_approval}
        />
      )}

      {isShowApprovalSchemaPengumumanPemenang && (
        <ApprovalX
          title={"Pengumuman Pemenang"}
          show={isShowApprovalSchemaPengumumanPemenang}
          schema={approvalSchemaPengumumanPemenang}
          onHide={() => {
            setIsShowApprovalSchemaPengumumanPemenang(false);
          }}
          onSubmit={(data: any) => {
            handleOnSubmitApprovalPengumumanPemenang(data);
          }}
          isLoading={isLoadingSubmitTender}
          source={"TENDER"}
          object_id={props.proposalTender?.id}
          company_id={props.proposalTender?.company?.id}
          code={props?.proposalTender?.routing_approval_config_code}
          AdditionalForm={(arg:any) => {
            return (
              <>
                <div className={"form-group mb-3"}>
                  <label className="form-label">
                    Masa Sanggah (hari)
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e:any)=>arg.onChange(e.target.value,"masa_sanggah")}
                  />
                </div>
                <hr />
              </>
            )
          }
          }
          readOnly={props.proposalTender?.is_on_approval}
        />
      )}

      {isShowApprovalPQ && (
        <ApprovalX
          key={renderKey}
          show={isShowApprovalPQ}
          schema={pqApprovalSchema}
          onHide={() => {
            setIsShowApprovalPQ(false);
          }}
          onSubmit={(data: any) => {
            handleOnPQSubmit(data)
          }}
          isLoading={isLoadingSubmitPq}
          source={"TENDER"}
          object_id={props?.proposalTender?.id}
          company_id={props?.proposalTender?.company?.id}
          code={pqApprovalCode}
          // readOnly={}
          readOnly={props?.proposalTender?.is_on_approval}
          />
      )}

      {/* Batal Tender */}
      <ApprovalX
        title={intl.formatMessage({id:'Batal Tender'})}
        show={isShowApprovalSchemaBatalTender}
        schema={approvalSchemaBatalTender}
        onHide={() => {
          setIsShowApprovalSchemaBatalTender(false);
        }}
        onSubmit={(data: any) => {
          setIsShowApprovalSchemaBatalTender(false);
          handleOnBatalTenderWithApproval(data);
        }}
        isLoading={isLoadingPQ}
        source={"TENDER"}
        object_id={props.proposalTender?.id}
        company_id={props.proposalTender?.company?.id}
        code={"tender_cancel"}
        readOnly={isLoadingPQ}
        AdditionalForm={(arg:any) => {
          return (
            <>
              <div className={"form-group mb-3"}>
                <label className="form-label">
                  Alasan batal tender
                </label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e:any)=>arg.onChange(e.target.value,"rejection_note")}
                />
              </div>
              <hr />
            </>
          )
        }
        }
      />

      <RetenderForm
        proposalTender={props?.proposalTender}
        show={isShowRetender}
        onHide={()=>setIsShowRetender(false)}
      />

      <AlihkanModal
        proposalTender={props?.proposalTender}
        show={isShowAlihkan}
        onHide={()=>setIsShowAlihkan(false)}
      />

    </div>
  );
});
export default ParameterPengadaanPage;