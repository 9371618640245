import React, { useEffect, useState } from "react";
import { useAxiosGet } from "../../../../../../../hooks/useAxios";
import {getEnv, parseNumberToFloat} from '../../../../../../../helpers/procurex'
import { Placeholder } from "rsuite";

const TrBidTabulationPaket = ({ item, isKomersil = false }: { item: any, isKomersil?: boolean }) => {
  return (
    <>
      <tr>
        <td className={"border border-bottom-4 p-2"} rowSpan={item?.vendors?.length+1} style={{
          verticalAlign: 'top',
          border: '4px #dee2e6 !important',
        }}>
          <div className={"d-flex flex-column"}>
            <div className={"p-0 m-0 d-flex flex-column mb-2"}>
              <small className={"text-muted"}>Description</small>
              {item?.description}
            </div>
            <div className={"p-0 m-0 d-flex flex-column mb-2"}>
              <small className={"text-muted"}>No PR</small>
              {item?.no_pr}
            </div>
            <div className={"p-0 m-0 d-flex flex-column mb-2"}>
              <small className={"text-muted"}>Line No</small>
              {item?.line_no || '-'}
            </div>
            <div className={"p-0 m-0 d-flex flex-column mb-2"}>
              <small className={"text-muted"}>Material</small>
              {item?.material || '-'}
            </div>
          </div>
        </td>
      </tr>
      {item?.vendors?.map((v: any, index: number) => {
        return (
          <tr key={"bid-tab-vendor-" + index}>
            <td className={"border border-bottom-4 p-2 text-end"}>{parseNumberToFloat(v?.quantity)}</td>
            <td className={"border border-bottom-4 p-2 text-center"}>{v?.uom?.uom}</td>
            <td className={"border border-bottom-4 p-2"}>
              <div className={"p-0 m-0"}><strong>{v?.vendor_id}</strong></div>
              <div className={"p-0 m-0"}>{v?.vendor_name}</div>
            </td>
            <td className={"border border-bottom-4 p-2"}>{v?.quote}</td>
            {isKomersil ? (
              <td className={`text-center border border-bottom-4 p-2 
                ${v?.status === "PASSED" ? 'bg-success text-white' : 
                  v?.status === "FAILED" ? 'bg-danger text-white' : 
                  v?.status === "NOT-EVALUATE" ? 'bg-warning' : ''}`}>
                <strong>{v?.status}</strong>
              </td>
            ) : (
              <td className={`text-center border border-bottom-4 p-2 
                ${v?.technical_status === "PASSED" ? 'bg-success text-white' : 
                  v?.technical_status === "FAILED" ? 'bg-danger text-white' : 
                  v?.technical_status === "NOT-EVALUATE" ? 'bg-warning' : ''}`}>
                <strong>{v?.technical_status}</strong>
              </td>
            )}
          </tr>
        );
      })}
    </>
  );
};

const BidTabulationComponent = ({ proposalTender, isKomersil = false }: { proposalTender: any, isKomersil?: boolean }) => {
  const [dataItems, setDataItems] = useState([]);
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosGet(
    getEnv("tender") + `/evaluation/bid/${proposalTender?.uuid}`
  );

  const checkIsPaket = () => {
    return proposalTender?.order_placement === "Paket";
  }

  const checkIs1Sampul = () => {
    return proposalTender?.metode_penyampaian === "1 Sampul";
  }

  const checkIs2Sampul = () => {
    return proposalTender?.metode_penyampaian === "2 Sampul";
  }

  const checkIs2Tahap = () => {
    return proposalTender?.metode_penyampaian === "2 Tahap";
  }

  const getItems = () => {
    doSubmit({}, undefined, undefined).then((res: any) => {
      if(isKomersil && (checkIs2Sampul() || checkIs2Tahap())) {
        setDataItems(res.data.filter((i: any) => {
          i.vendors = i?.vendors?.filter((v: any) => v?.technical_status === "PASSED");
          return i;
        }));
      } else {
        setDataItems(res.data);
      }
    });
  };

  useEffect(() => {
    getItems();
  }, [isKomersil]);

  return (
    <>
      <h3>{isKomersil ? 'Bid Tabulation' : 'Bid Tabulation Teknis'}</h3>
      {isLoading && (
        <Placeholder.Grid rows={5} columns={5} active={isLoading} />
      )}
      {!isLoading && (
        <>
          <div className={"table-responsive"}>
            <table className={"table table-header-gray"}>
              <thead>
              <tr>
                <th className={"border px-2"} style={{
                  verticalAlign: "top", position: "sticky", minWidth: "200px", left: 0, zIndex: 1
                }}><strong>Deskripsi</strong></th>
                <th className={"border text-end"}><strong>QTY</strong></th>
                <th className={"border text-center"}><strong>Satuan</strong></th>
                <th className={"border"}><strong>Rekanan</strong></th>
                <th className={"border text-nowrap"}><strong>Quote</strong></th>
                <th className={"border text-nowrap px-2"}><strong>Hasil Evaluasi</strong></th>
              </tr>
              </thead>
              {dataItems.map((item: any, index: number) => {
                return (
                  <TrBidTabulationPaket
                    item={item}
                    key={"bid-tab-" + index}
                    isKomersil={isKomersil}
                  />
                );
              })}
            </table>
          </div>
        </>
      )}
    </>
  );
};


export default BidTabulationComponent;