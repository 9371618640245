import { Field, Form, Formik } from 'formik'
import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { Link, useNavigate, useParams } from 'react-router-dom'
import SelectX from '../../../../../components/shared/form/SelectX'
import { ApiUrl } from '../../../../../config/Api'
import { getEnv } from "../../../../../../helpers/procurex";
import { InputNumber } from "rsuite";
import axios from 'axios'
import { useIntl } from "react-intl";
import Swal from "sweetalert2";
import DatepickerX2 from "../../../../../components/shared/form/DatepickerX2";
import DateRangePickerX from '../../../../../components/shared/form/DateRangePickerX'
interface CreateProps {
    backUrl?: string
}
const CreateTransportation = ({ backUrl }: CreateProps) => {

    const initialValues = {
        name: '',
        category: '',
        capacity: '0',
        imo: '',
        currency: 'IDR',
    }

    const formSchemaValidation = Yup.object().shape({
        name: Yup.string().required('Nama is required'),
        category: Yup.string().required('Category is required'),
        capacity: Yup.number().required('Capacity is required').positive('Capacity must be positive'),
    });

    const intl = useIntl();
    const refFormik = React.useRef<any>();

    const [formData, setFormData] = React.useState([]);

    const [loading, setLoading] = useState(true)
    const [isLoading, setIsLoading] = useState(false);
    const [key, setKey] = useState(0)

    const [totalCapacity, setTotalCapacity] = useState(0);
    const [hargaPerolehan, sethargaPerolehan] = useState(0);

    const handleOnTotalCapacity = (score: number) => {
        setTotalCapacity(score)
    }

    const handleOnHarga = (score: number) => {
        sethargaPerolehan(score)
    }

    const [selectedCategory, setSelectedCategory] = useState(null);

    const handleCategory = (selectedValue: any) => {
        setSelectedCategory(selectedValue);
    };

    const TableRow = ({ no, name, capacity, uom, imo, category, harga_perolehan, currency, komoditas, vendor, awarding, eta_estimasi, destination_estimasi, laycan_date, port_load, port_destination, onDelete, ...props }: any) => {
        return (
            <tr>
                <td className={"border text-center"}>{no}</td>
                <td className={"border"}>{name}</td>
                <td className={"border"}>{capacity}</td>
                <td className={"border"}>{uom}</td>
                <td className={"border"}>{imo}</td>
                <td className={"border"}>{category}</td>
                <td className={"border"}>{harga_perolehan}</td>
                <td className={"border"}>{currency}</td>
                <td className={"border"}>{komoditas}</td>
                <td className={"border"}>{vendor}</td>
                <td className={"border"}>{awarding}</td>
                <td className={"border"}>{eta_estimasi}</td>
                <td className={"border"}>{destination_estimasi}</td>
                <td className={"border"}>{laycan_date}</td>
                <td className={"border"}>{port_load}</td>
                <td className={"border"}>{port_destination}</td>
                <td className={"border text-center"}>
                    <button className={"btn btn-sm btn-secondary"} onClick={() => onDelete()}>
                        <i className={"fa fa-trash text-danger"}></i>
                    </button>
                </td>
            </tr>
        );
    };

    const [formindex, setFormIndex] = useState(0)

    const navigate = useNavigate();

    const handleBack = async () => {
        Swal.fire({
            title: `Data Belum Tersimpan`,
            html: `Apakah Anda Yakin Ingin Keluar ?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: `Ya, Keluar!`,
            confirmButtonColor: "#999999",
            cancelButtonColor: "#258141",
            cancelButtonText: `Cancel`,
        }).then((result) => {
            if (result.isConfirmed) {
                navigate('/stockview/masterdata/transportation')
            }
        })
    }

    const handleBackClick = () => {
        if (formData.length !== 0) {
            handleBack();
        } else {
            navigate('/stockview/masterdata/transportation');
        }
    };

    const handleAddRow = async (values: any) => {
        const newData = {
            name: values.name,
            category: values.category,
            imo: values.imo,
            capacity: values.capacity,
            uom: values.capacity ? 'TON' : '',
            harga_perolehan: values.harga_perolehan || initialValues.currency,
            currency: values.currency,
            komoditas: values.komoditas,
            vendor: values.vendor,
            awarding: values.awarding,
            eta_estimasi: values.eta_estimasi,
            destination_estimasi: values.destination_estimasi,
            laycan_date: values.laycan_date,
            port_load: values.port_load,
            port_destination: values.port_destination
        };
        setFormData([...formData, newData]);
        setFormIndex(formindex + 1)
        // @ts-ignore

    };

    const handleDeleteRow = async (index: number) => {
        const newData = formData.filter((item: any, i: number) => i !== index);
        // @ts-ignore
        setFormData(newData);
    };

    const handleSubmit = async () => {
        setLoading(true)
        try {
            const data = {
                ...formData
            };

            const response = await axios.post(getEnv("stockview") + `/transportation`, data)

            if (response.status === 200 || response.status === 201) {
                toast.success(response?.status + ': ' + response?.data.meta.message, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                })
            }

            const fleetData = Object.values(data);

            for (const item of fleetData) {
                let responseAddFleet;
                let responseAddFleetToVessel;

                try {
                    responseAddFleet = await axios.post(getEnv("vessel_add_fleet") + `?imo=${item.imo}&active=1&protocol=jsono`);
                } catch (error: any) {
                    console.error("Add fleet request failed", error);
                }

                const apiUrl = process.env.REACT_APP_API_VESSEL;
                const dataVessel = await fetch(apiUrl).then((response) => response.json());

                const vessel = dataVessel.DATA.find((v) => v.IMO === item.imo);
                if (!vessel) {
                    throw new Error(`Vessel with IMO ${item.imo} not found`);
                }

                const vesselData = [
                    {
                        mmsi: vessel.MMSI,
                        imo: vessel.IMO,
                        ship_id: vessel.SHIP_ID,
                        lat: vessel.LAT,
                        lon: vessel.LON,
                        eta: vessel.ETA,
                        destination: vessel.DESTINATION,
                        flag: vessel.FLAG,
                        draught: vessel.DRAUGHT,
                        type_name: vessel.TYPE_NAME,
                        ship_country: vessel.SHIP_COUNTRY,
                        ship_class: vessel.SHIP_CLASS,
                        last_port: vessel.LAST_PORT,
                        last_port_id: vessel.LAST_PORT_ID,
                        last_port_unlocode: vessel.LAST_PORT_UNLOCODE,
                        last_port_country: vessel.LAST_PORT_COUNTRY,
                        next_port_id: vessel.NEXT_PORT_ID,
                        next_port_unlocode: vessel.NEXT_PORT_UNLOCODE,
                        next_port_name: vessel.NEXT_PORT_NAME,
                        next_port_country: vessel.NEXT_PORT_COUNTRY,
                        speed: vessel.SPEED,
                        heading: vessel.HEADING,
                        course: vessel.COURSE,
                        status: vessel.STATUS,
                        timestamp: vessel.TIMESTAMP,
                        dsrc: vessel.DSRC,
                        utc_seconds: vessel.UTC_SECONDS,
                        market: vessel.MARKET,
                        shipname: vessel.SHIPNAME,
                        shiptype: vessel.SHIPTYPE,
                        callsign: vessel.CALLSIGN,
                        length: vessel.LENGTH,
                        width: vessel.WIDTH,
                        grt: vessel.GRT,
                        dwt: vessel.DWT,
                        year_built: vessel.YEAR_BUILT,
                        rot: vessel.ROT,
                        ais_type_summary: vessel.AIS_TYPE_SUMMARY,
                        l_fore: vessel.L_FORE,
                        w_left: vessel.W_LEFT,
                        last_port_time: vessel.LAST_PORT_TIME,
                        current_port: vessel.CURRENT_PORT,
                        current_port_id: vessel.CURRENT_PORT_ID,
                        current_port_unlocode: vessel.CURRENT_PORT_UNLOCODE,
                        current_port_country: vessel.CURRENT_PORT_COUNTRY,
                        eta_calc: vessel.ETA_CALC,
                        eta_updated: vessel.ETA_UPDATED,
                        distance_to_go: vessel.DISTANCE_TO_GO,
                        distance_travelled: vessel.DISTANCE_TRAVELLED,
                        avg_speed: vessel.AVG_SPEED,
                        max_speed: vessel.MAX_SPEED,
                    }
                ];

                try {
                    responseAddFleetToVessel = await axios.post(getEnv("stockview") + `/vessel-repository`, vesselData);
                } catch (error) {
                    console.error("Add fleet to vessel request failed", error);
                    continue;
                }

                if (
                    (responseAddFleet?.status === 200 || responseAddFleet?.status === 201) &&
                    (responseAddFleetToVessel.status === 200 || responseAddFleetToVessel.status === 201)
                ) {
                    if (responseAddFleet?.data?.success?.[0]?.code === "s7") {
                        toast.success(responseAddFleet.data.success[0].detail || "Fleet item updated successfully");
                    } else {
                        toast.success(responseAddFleetToVessel.data.meta.message);
                    }
                }
            }

            setFormIndex(formindex + 1)
        } catch (error: any) {
            console.error(error)
            if (error.response?.status === 400 && error.response?.data?.errors?.[0]?.code === "e8") {
                toast.error(error.response.data.errors[0].detail);
            }
        } finally {
            setLoading(false)
        }
    }

    return (
        <Formik
            key={formindex}
            innerRef={refFormik}
            initialValues={initialValues}
            onSubmit={handleAddRow}
            validationSchema={formSchemaValidation}
            enableReinitialize
            validateOnChange={true}
        >
            {({ errors, touched, values, setFieldValue }: any) => (
                <Form>
                    <div className='d-flex justify-content-between mb-6'>
                        <div>
                            <button className='btn btn-secondary btn-sm fw-bold' onClick={handleBackClick}>
                                Back To list
                            </button>
                        </div>
                        <div className='d-flex gap-4'>
                            <button className={"btn btn-primary btn-sm"} disabled={isLoading}
                                onClick={() => handleSubmit()}>
                                {isLoading && <i className={"fa fa-spinner fa-spin"}></i>}
                                {!isLoading && <i className={"fa fa-save"}></i>}
                                {intl.formatMessage({ id: "Simpan" })}
                            </button>
                        </div>
                    </div>

                    <div className='card my-5'>
                        <div className='card-header d-flex align-items-center'>
                            <b>FORM ADD TRANSPORTATION</b>
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <div className='mb-6'>
                                        <label htmlFor='' className='form-label'>
                                            Nomor IMO
                                        </label>
                                        <Field className='form-control' name="imo" placeholder="input imo.." />
                                        {(errors.imo && touched.imo) && (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{errors.imo}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='mb-6'>
                                        <label htmlFor='' className='required form-label'>
                                            Name
                                        </label>
                                        <Field className='form-control' name="name" placeholder="input name.." />
                                        {(errors.name && touched.name) && (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{errors.name}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='mb-6'>
                                        <label htmlFor='' className='required form-label'>
                                            Category
                                        </label>
                                        <SelectX
                                            onChange={(e: any) => setFieldValue('category', e)}
                                            options={[
                                                { label: 'Shipping', value: 'Shipping' },
                                                { label: 'Truck', value: 'Truck' },
                                            ]}
                                        />
                                        {(errors.category && touched.category) && (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{errors.category}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='mb-6'>
                                        <label htmlFor='' className='required form-label'>
                                            Capacity
                                        </label>
                                        <InputNumber
                                            defaultValue={totalCapacity}
                                            min={0}
                                            name="capacity"
                                            className={"w-100 text-end"}
                                            onChange={(e: any) => setFieldValue('capacity', e)}
                                        />
                                        {(errors.capacity && touched.capacity) && (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{errors.capacity}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='mb-6'>
                                        <label htmlFor='' className='form-label'>
                                            Uom
                                        </label>
                                        <Field className='form-control' name="uom" value={values.capacity ? 'TON' : ''} disabled />
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='mb-6'>
                                        <label htmlFor='' className='form-label'>
                                            Harga Perolehan
                                        </label>
                                        <div className="input-group">
                                            <InputNumber
                                                defaultValue={hargaPerolehan}
                                                min={0}
                                                name="harga_perolehan"
                                                className={"w-50 text-end"}
                                                placeholder={'Harga input in USD'}
                                                onChange={(e: any) => setFieldValue('harga_perolehan', e)}
                                            />
                                            <Field as="select" name="currency" className="form-select" style={{ maxWidth: '80px' }}>
                                                <option value="IDR">IDR</option>
                                                <option value="USD">USD</option>
                                            </Field>
                                        </div>
                                        {(errors.harga_perolehan && touched.harga_perolehan) && (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{errors.harga_perolehan}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='mb-6'>
                                        <label htmlFor='' className='form-label'>
                                            ETA Estimasi
                                        </label>
                                        <DatepickerX2
                                            onChange={(date: any) => setFieldValue("eta_estimasi", date)}
                                            size={"lg"}
                                            value={values.eta_estimasi}
                                            format={"dd-MM-yyyy"}
                                        />
                                        {(errors.eta_estimasi && touched.eta_estimasi) && (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{errors.eta_estimasi}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='mb-6'>
                                        <label htmlFor='' className='form-label'>
                                            Laycan Estimasi
                                        </label>
                                        <DateRangePickerX
                                            defaultValue={values?.laycan_date}
                                            onChange={(e: any) => {
                                                if (e && e.length === 2) {
                                                    const formattedRange = [
                                                        e[0].toISOString().split('T')[0],
                                                        e[1].toISOString().split('T')[0]
                                                    ];
                                                    setFieldValue("laycan_date", JSON.stringify(formattedRange));
                                                } else {
                                                    setFieldValue("laycan_date", "");
                                                }
                                            }}
                                            format={"dd-MM-yyyy"}
                                        />
                                        {(errors.laycan_date && touched.laycan_date) && (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{errors.laycan_date}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='mb-6'>
                                        <label htmlFor='' className='form-label'>
                                            Komoditas
                                        </label>
                                        <Field className='form-control' name="komoditas" placeholder="input komoditas.." />
                                        {(errors.komoditas && touched.komoditas) && (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{errors.komoditas}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='mb-6'>
                                        <label htmlFor='' className='form-label'>
                                            Vendor
                                        </label>
                                        <Field className='form-control' name="vendor" placeholder="input vendor.." />
                                        {(errors.vendor && touched.vendor) && (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{errors.vendor}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='mb-6'>
                                        <label htmlFor='' className='form-label'>
                                            Awarding
                                        </label>
                                        <Field className='form-control' name="awarding" placeholder="input awarding.." onChange={(e: any) => {
                                            setFieldValue('awarding', e.target.value.toUpperCase());
                                        }} />
                                        {(errors.awarding && touched.awarding) && (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{errors.awarding}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='mb-6'>
                                        <label htmlFor='' className='form-label'>
                                            Port Load
                                        </label>
                                        <Field className='form-control' name="port_load" placeholder="input port load.." />
                                        {(errors.port_load && touched.port_load) && (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{errors.port_load}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='mb-6'>
                                        <label htmlFor='' className='form-label'>
                                            Port Destination
                                        </label>
                                        <Field className='form-control' name="port_destination" placeholder="input port destination.." />
                                        {(errors.port_destination && touched.port_destination) && (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{errors.port_destination}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='d-flex justify-content-end gap-4'>
                                    <button type={"submit"} className={"btn btn-primary btn-sm fw-bold"}>
                                        {isLoading && <i className={"fa fa-spinner fa-spin"}></i>}
                                        {!isLoading && <i className={"fa fa-plus"}></i>}
                                        {intl.formatMessage({ id: "Tambah" })}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"col-md-12 mt-5"}>
                        <table className={"table table-bordered"}>
                            <thead>
                                <tr>
                                    <th className={"border text-center"}><strong>{intl.formatMessage({ id: "No" })}</strong></th>
                                    <th className={"border text-center"}><strong>{intl.formatMessage({ id: "Name" })}</strong></th>
                                    <th className={"border text-center"}><strong>{intl.formatMessage({ id: "Capacity" })}</strong></th>
                                    <th className={"border text-center"}><strong>{intl.formatMessage({ id: "UOM" })}</strong></th>
                                    <th className={"border text-center"}><strong>{intl.formatMessage({ id: "Nomor IMO" })}</strong></th>
                                    <th className={"border text-center"}><strong>{intl.formatMessage({ id: "Category" })}</strong></th>
                                    <th className={"border text-center"}><strong>{intl.formatMessage({ id: "Harga Perolehan" })}</strong></th>
                                    <th className={"border text-center"}><strong>{intl.formatMessage({ id: "Currency" })}</strong></th>
                                    <th className={"border text-center"}><strong>{intl.formatMessage({ id: "Komoditas" })}</strong></th>
                                    <th className={"border text-center"}><strong>{intl.formatMessage({ id: "Vendor" })}</strong></th>
                                    <th className={"border text-center"}><strong>{intl.formatMessage({ id: "Awarding" })}</strong></th>
                                    <th className={"border text-center"}><strong>{intl.formatMessage({ id: "Eta Estimasi" })}</strong></th>
                                    <th className={"border text-center"}><strong>{intl.formatMessage({ id: "Destination Estimasi" })}</strong></th>
                                    <th className={"border text-center"}><strong>{intl.formatMessage({ id: "laycan date" })}</strong></th>
                                    <th className={"border text-center"}><strong>{intl.formatMessage({ id: "Port Load" })}</strong></th>
                                    <th className={"border text-center"}><strong>{intl.formatMessage({ id: "Port Destination" })}</strong></th>
                                    <th className={"border text-center"}><strong>{intl.formatMessage({ id: "Aksi" })}</strong></th>
                                </tr>
                            </thead>
                            <tbody>
                                {formData.length === 0 && (
                                    <tr>
                                        <td colSpan={16} className={"text-center"}>
                                            <i>{intl.formatMessage({ id: "Tidak ada data" })}</i>
                                        </td>
                                    </tr>
                                )}
                                {formData.map((item: any, index: number) => {
                                    const formatDateLaycan = (dateString) => {
                                        const [year, month, day] = dateString.split('-');
                                        return `${day}/${month}/${year}`;
                                    };

                                    const laycanDates = item?.laycan_date ? JSON.parse(item.laycan_date) : [];
                                    const formattedStartDate = laycanDates[0] ? formatDateLaycan(laycanDates[0]) : '';
                                    const formattedEndDate = laycanDates[1] ? formatDateLaycan(laycanDates[1]) : '';
                                    const formatmerge = `${formattedStartDate} - ${formattedEndDate}`;

                                    return (
                                        <TableRow
                                            key={"adm-" + index}
                                            no={index + 1}
                                            name={item.name}
                                            capacity={item.capacity}
                                            imo={item.imo}
                                            category={item.category}
                                            uom={item.uom}
                                            harga_perolehan={item.harga_perolehan}
                                            currency={item.currency}
                                            komoditas={item.komoditas}
                                            vendor={item.vendor}
                                            awarding={item.awarding}
                                            eta_estimasi={item.eta_estimasi}
                                            destination_estimasi={item.destination_estimasi}
                                            laycan_date={formatmerge}
                                            port_load={item.port_load}
                                            port_destination={item.port_destination}
                                            onDelete={() => handleDeleteRow(index)}
                                        />
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export { CreateTransportation }