import React, { useEffect, useState } from "react";
import TableXWrapper from "../../../../../../components/shared/TableXWrapper";
import { getEnv } from "../../../../../../../helpers/procurex";
import { ButtonGroup, Col, Row } from "react-bootstrap";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { usePostPanitiaPengadaan } from "../../../hooks/proposalTenderHook";
import { toast } from "react-toastify";
import SwalX from "../../../../../../components/shared/SwalX";
import { useIntl } from "react-intl";
import axios from "axios";
import { toastError } from "../../../../../../../_metronic/helpers";
interface IProps {
  proposalTender?: any;
  afterSubmit?: Function;
  disabled?: boolean;
  isOwner?: boolean;
}
const FormPanitiaTender = ({proposalTender,afterSubmit,disabled,isOwner}:IProps) => {
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = usePostPanitiaPengadaan();
  const intl = useIntl();
  const [initialValues, setInitialValues] = useState({
    name: "",
    jabatan: "",
    department: "",
    type: ""
  });
  const [formIndex, setFormIndex] = useState(0);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Nama harus diisi"),
    jabatan: Yup.string().required("Jabatan harus diisi"),
    department: Yup.string().required("Department harus diisi")
  });
  const formik = React.useRef(null);
  const handleSubmit = (values:any) => {
    const payload = {
      ...values,
      proposal_tender_id: proposalTender.uuid
    }
    doSubmit(payload,toast,()=>{
      setFormIndex(formIndex+1)
      afterSubmit && afterSubmit()
    })
  };

  return (
    <>

          <Formik
            innerRef={formik}
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
            key={formIndex}
          >
            {({ errors, touched, values, setFieldValue }) => (
              <>
                <Form>
                  <Row>
                    <Col sm="6">
                      <div className="form-group mb-4">
                        <label className="form-label">{intl.formatMessage({id:"Nama"})}*</label>
                        <Field
                          name="name"
                          type="text"
                          className="form-control"
                          disabled={disabled}
                        />
                        {errors.name && touched.name && (
                          <div className="fv-plugins-message-container">
                            {/* @ts-ignore*/}
                            <div className="fv-help-block">{errors.name}</div>
                          </div>
                        )}
                      </div>
                      <div className="form-group mb-10">
                        <label className="form-label">{intl.formatMessage({id:"Jabatan"})}*</label>
                        <Field
                          name="jabatan"
                          type="text"
                          className="form-control"
                          disabled={disabled}
                        />
                        {errors.jabatan && touched.jabatan && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {/* @ts-ignore*/}
                              {errors.jabatan}
                            </div>
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col sm="6">
                      <div className="form-group mb-10">
                        <label className="form-label">{intl.formatMessage({id:"Departemen"})}*</label>
                        <Field
                          name="department"
                          type="text"
                          className="form-control"
                          disabled={disabled}
                        />
                        {errors.department && touched.department && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {/* @ts-ignore*/}
                              {errors.department}
                            </div>
                          </div>
                        )}
                      </div>
                      {isOwner &&
                        <div className="form-group mb-10 text-end pt-4">
                          <label className="form-label">&nbsp;</label>
                          <button
                            type="submit"
                            disabled={isLoading || disabled}
                            className={"btn btn-primary btn-sm"}
                          >
                            {isLoading && (
                              <span className="spinner-border spinner-border-sm mr-2"></span>
                            )}
                            {!isLoading && (
                              <i className={"fa fa-save"}></i>
                            )}
                            {intl.formatMessage({id:"Simpan"})}
                          </button>
                        </div>
                      }
                    </Col>
                  </Row>
                </Form>
              </>
            )}
          </Formik>
    </>
  );
};



const PanitiaTenderForm = ({ proposalTender, isOwner }: IProps) => {
  const urlIndex = getEnv("tender") + "/tender-panitia";
  const intl = useIntl();
  const [selectedRows, setSelectedRows] = useState([]);
  const [formIndex, setFormIndex] = useState(0);
  const [itemId, setItemId] = useState(null);
  const [isDisabledForm, setIsDisabledForm] = useState(true);
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false)
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const tableColumns = [
    {
      Header: intl.formatMessage({ id: "Aksi" }),
      accessor: "uuid",
      width: 50,
      className: "text-center",
      Cell: (props: any) => (
        <>
          {isOwner &&
            <ButtonGroup size={'sm'} aria-label='Action'>
              <button
                type='button'
                className='btn btn-sm btn-secondary'
                disabled={isDisabledForm}
                onClick={() => {
                  setItemId(props.cell.row.original.uuid)
                  setIsConfirmDeleteOpen(true)
                }}
              >
                <i className={'fa fa-trash'} />
              </button>
            </ButtonGroup>
          }
        </>
      ),
    },
    { Header: intl.formatMessage({id:"Nama"}), accessor: "name", width: 200, className: "text-center" },
    { Header: intl.formatMessage({id:"Jabatan"}), accessor: "jabatan", width: 200, className: "text-center" },
    { Header: intl.formatMessage({id:"Departemen"}), accessor: "department", width: 200, className: "text-center" }
  ];

  useEffect(() => {
    if(proposalTender?.status_detail === 'Draft' || proposalTender?.status_detail === 'Rejected'){
      setIsDisabledForm(false)
    } else {
      setIsDisabledForm(true)
    }
  }, [proposalTender]);
  const deleteItem = async () => {
    try {
      setIsLoadingDelete(true)
      const response: any = await axios.delete(`${urlIndex}/${itemId}`)

      if (response.status === 200) {
        setFormIndex(formIndex + 1)
        setIsConfirmDeleteOpen(false)
        toast.success(response?.data?.meta?.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      }
    } catch (error: any) {
      console.error(error)

      toastError(error.response?.data?.meta?.message || 'Terjadi kesalahan')
    } finally {
      setIsLoadingDelete(false)
    }
  }

  return (
    <>
      <div className={""}>
          <div className={"d-flex justify-content-between"}>
            <div><h3>{intl.formatMessage({id:"Panitia Pengadaan"})}</h3></div>

          </div>
          <hr />
          <FormPanitiaTender proposalTender={proposalTender} afterSubmit={()=>{
            setFormIndex(formIndex+1)
          }} disabled={isDisabledForm} isOwner={isOwner}/>
          <TableXWrapper
            key={formIndex}
            readonly={true}
            title={"Panitia"}
            urlIndex={urlIndex}
            defaultFilter={`filter[proposal_tender_id]=${proposalTender?.id}`}
            columns={tableColumns}
            isForceCreateButton={true}
            onSelectedRows={(e: any) => {
              setSelectedRows(e);
            }}
            disableUrlChanged={true}
            disablePaddingCard={true}
          />
          <SwalX
            title={intl.formatMessage({id: 'DATATABLE.CONFIRM_DELETE'})}
            message={intl.formatMessage({id: 'DATATABLE.MESSAGE_DELETE'})}
            show={isConfirmDeleteOpen}
            loading={isLoadingDelete}
            onHide={() => setIsConfirmDeleteOpen(false)}
            onSubmit={() => deleteItem()}
          />
      </div>
    </>
  );
};

export default PanitiaTenderForm;