import { Field, Form, Formik } from "formik"
import { forwardRef, useRef, useState } from "react"
import { Accordion, Col, FormGroup, FormLabel, OverlayTrigger, Row, Tooltip } from "react-bootstrap"
import { Placeholder } from "rsuite"
import * as Yup from 'yup'
import { getEnv, parseNumberToCurrency, parseNumberToFloat } from "../../../../../helpers/procurex"
import FormikSelect2 from "../../../../components/shared/form/FormikSelect2"
import moment from "moment"
import DetailFormItemModal from "../../po/create/components/DetailFormItemModal"

interface Props {
    ref: any
    agreement: any
    isLoading: boolean
}

const AgreementDetailHeader = forwardRef(({ agreement, isLoading }: Props, refHeader) => {
    const formik = useRef({} as any)

    const [modalData, setModalData] = useState('')
    const [modalFormDetailItem, setModalFormDetailItem] = useState(false)

    const validationSchema = Yup.object().shape({})
    const [initialValues, setInitialValues] = useState({})
    const handleSubmit = (values) => { }
    const items = [
        {
            "id": 3309,
            "uuid": "9d5051f8-2cd9-4b43-ad4b-8ecaa82c6b16",
            "no_pr": "2100001231",
            "line_no": "10",
            "line_no_pr": "00020",
            "plant_id": "A801",
            "plant": {
                "id": 2,
                "uuid": null,
                "kode_plant": "A801",
                "nama_plant": "PLANT GENERAL",
                "company_code": "A000",
                "deleted_at": null,
                "created_by": null,
                "created_at": "2023-05-26T21:39:41.000000Z",
                "updated_by": null,
                "updated_at": "2024-03-10T18:10:05.000000Z",
                "address": "Taman Anggrek \u2013 Kemanggisan Ja",
                "postal_code": "11480",
                "city": "KOTA ADM. JAKARTA BARAT"
            },
            "item_text": "Jasa Multi Account",
            "item_text_pr": "Jasa Multi Account",
            "specification": "",
            "quantity": "1.000",
            "uom_id": "AU",
            "uom": {
                "id": 21,
                "uom": "AU",
                "uom_name": "Activ.unit",
                "is_active": true,
                "created_at": null,
                "updated_at": "2024-02-01T18:20:07.000000Z",
                "uom_sap": "LE",
                "measurement_unit_text": null
            },
            "valuation_price": "760000.000",
            "total_value": "760000.000",
            "additional_cost": "0.000",
            "additional_cost_description": "",
            "currency_id": "IDR",
            "currency": {
                "id": 82,
                "code": "IDR",
                "short_text": "Rupiah",
                "long_text": "Indonesian Rupiah",
                "created_at": "2023-05-11T20:52:25.000000Z",
                "updated_at": "2023-05-11T20:52:25.000000Z"
            },
            "delivery_date": "2024-04-24",
            "tax_code_id": "",
            "tax_code": null,
            "note": "",
            "serviceLines": [
                {
                    "id": 1863,
                    "uuid": "9d5051f8-496a-4265-9229-22b4c0bf41c9",
                    "line_no": "10",
                    "line_no_pr": "0000000020",
                    "service_no": "",
                    "short_text": "Jasa 2",
                    "currency_id": "IDR",
                    "currency": {
                        "id": 82,
                        "code": "IDR",
                        "short_text": "Rupiah",
                        "long_text": "Indonesian Rupiah",
                        "created_at": "2023-05-11T20:52:25.000000Z",
                        "updated_at": "2023-05-11T20:52:25.000000Z"
                    },
                    "uom_id": "MON",
                    "uom": {
                        "id": 261,
                        "uom": "MON",
                        "uom_name": "Months",
                        "is_active": true,
                        "created_at": null,
                        "updated_at": null,
                        "uom_sap": "MON",
                        "measurement_unit_text": null
                    },
                    "per": "1.000",
                    "quantity": "12.000",
                    "net_price": "400000.000",
                    "gross_price": "4800000.000",
                    "created_at": "2024-10-23 18:29:41",
                    "updated_at": "2024-10-23 18:29:41",
                    "action": {
                        "edit": true,
                        "delete": true
                    }
                },
                {
                    "id": 1864,
                    "uuid": "9d5051f8-4e74-4106-9dd2-aed421a17ab8",
                    "line_no": "20",
                    "line_no_pr": "0000000030",
                    "service_no": "",
                    "short_text": "Jasa 3",
                    "currency_id": "IDR",
                    "currency": {
                        "id": 82,
                        "code": "IDR",
                        "short_text": "Rupiah",
                        "long_text": "Indonesian Rupiah",
                        "created_at": "2023-05-11T20:52:25.000000Z",
                        "updated_at": "2023-05-11T20:52:25.000000Z"
                    },
                    "uom_id": "MON",
                    "uom": {
                        "id": 261,
                        "uom": "MON",
                        "uom_name": "Months",
                        "is_active": true,
                        "created_at": null,
                        "updated_at": null,
                        "uom_sap": "MON",
                        "measurement_unit_text": null
                    },
                    "per": "1.000",
                    "quantity": "12.000",
                    "net_price": "300000.000",
                    "gross_price": "3600000.000",
                    "created_at": "2024-10-23 18:29:41",
                    "updated_at": "2024-10-23 18:29:41",
                    "action": {
                        "edit": true,
                        "delete": true
                    }
                },
                {
                    "id": 1865,
                    "uuid": "9d5051f8-5175-4a94-ada9-947542a26668",
                    "line_no": "30",
                    "line_no_pr": "0000000010",
                    "service_no": "",
                    "short_text": "Jasa 1",
                    "currency_id": "IDR",
                    "currency": {
                        "id": 82,
                        "code": "IDR",
                        "short_text": "Rupiah",
                        "long_text": "Indonesian Rupiah",
                        "created_at": "2023-05-11T20:52:25.000000Z",
                        "updated_at": "2023-05-11T20:52:25.000000Z"
                    },
                    "uom_id": "MON",
                    "uom": {
                        "id": 261,
                        "uom": "MON",
                        "uom_name": "Months",
                        "is_active": true,
                        "created_at": null,
                        "updated_at": null,
                        "uom_sap": "MON",
                        "measurement_unit_text": null
                    },
                    "per": "1.000",
                    "quantity": "12.000",
                    "net_price": "60000.000",
                    "gross_price": "720000.000",
                    "created_at": "2024-10-23 18:29:41",
                    "updated_at": "2024-10-23 18:29:41",
                    "action": {
                        "edit": true,
                        "delete": true
                    }
                }
            ],
            "created_at": "2024-10-23 18:29:41",
            "updated_at": "2024-10-23 18:29:41",
            "action": {
                "edit": true,
                "delete": true
            }
        }
    ]
    console.log('items', items)
    return (
        <>
            {isLoading && (
                <Placeholder.Grid rows={3} active columns={2} style={{ marginTop: 20 }} />
            )}
            {!isLoading && (
                <>
                    {modalFormDetailItem && (
                        <DetailFormItemModal
                            show={modalFormDetailItem}
                            handleClose={() => setModalFormDetailItem(false)}
                            header={agreement}
                            data={modalData}
                        />
                    )}

                    <Formik
                        enableReinitialize
                        validationSchema={validationSchema}
                        initialValues={initialValues}
                        innerRef={formik}
                        onSubmit={handleSubmit}
                    >
                        {({ errors, touched, values, setFieldValue }) => (
                            <Form>
                                <Accordion defaultActiveKey={['0']} className='mb-5'>
                                    <Accordion.Item eventKey='0'>
                                        <Accordion.Header><h3>Header</h3></Accordion.Header>
                                        <Accordion.Body>
                                            <div className={'card card-body'}>
                                                <Row className="mt-5">
                                                    <Col xs="12" sm="6" md="6" className="mb-5">
                                                        <FormGroup>
                                                            <FormLabel>No Tender ProcureX</FormLabel>
                                                            <Field
                                                                type='text'
                                                                disabled={true}
                                                                className='form-control'
                                                                name='no_tender_procurex'
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs="12" sm="6" md="6" className="mb-5">
                                                        <FormGroup>
                                                            <FormLabel>Vendor</FormLabel>
                                                            <Field
                                                                type='text'
                                                                disabled={true}
                                                                className='form-control'
                                                                name='vendor'
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs="12" sm="6" md="6" className="mb-5">
                                                        <FormGroup>
                                                            <FormLabel>No PO ProcureX</FormLabel>
                                                            <Field
                                                                type='text'
                                                                disabled={true}
                                                                className='form-control'
                                                                name='no_po_procurex'
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs="12" sm="6" md="6" className="mb-5">
                                                        <FormGroup>
                                                            <FormLabel>No PO SAP</FormLabel>
                                                            <Field
                                                                type='text'
                                                                disabled={true}
                                                                className='form-control'
                                                                name='no_po_sap'
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs="12" sm="6" md="6" className="mb-5">
                                                        <FormGroup>
                                                            <FormLabel>Status</FormLabel>
                                                            <Field
                                                                type='text'
                                                                disabled={true}
                                                                className='form-control'
                                                                name='status'
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>

                                <Accordion defaultActiveKey={['0']} className='mb-5'>
                                    <Accordion.Item eventKey='0'>
                                        <Accordion.Header><h3>Outline Agreement (Header)</h3></Accordion.Header>
                                        <Accordion.Body>
                                            <div className={'card card-body'}>
                                                <Row className="mt-5">
                                                    <Col xs="12" sm="6" md="6" className="mb-5">
                                                        <FormGroup>
                                                            <FormLabel>Purch. Org</FormLabel>
                                                            <Field
                                                                type='text'
                                                                disabled={true}
                                                                className='form-control'
                                                                name='purch_org'
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs="12" sm="6" md="6" className="mb-5">
                                                        <FormikSelect2
                                                            label='Agreement Type'
                                                            name={`document_type`}
                                                            optionsUrl={getEnv("masterdata") + `/document-types?filter[cat]=F`}
                                                            getOptionValue={(opt: any) => opt}
                                                            getOptionLabel={(opt: any) =>
                                                                `${opt.doc_type_id} - ${opt.doc_type_desc}`
                                                            }
                                                            unique_property='id'
                                                            search_property={'doc_type_id_desc'}
                                                            isDisabled={false}
                                                        />
                                                    </Col>
                                                    <Col xs="12" sm="6" md="6" className="mb-5">
                                                        <FormGroup>
                                                            <FormLabel>Purch. Group</FormLabel>
                                                            <Field
                                                                type='text'
                                                                disabled={true}
                                                                className='form-control'
                                                                name='purch_group'
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs="12" sm="6" md="6" className="mb-5">
                                                        <FormGroup>
                                                            <FormLabel>Agreement Date</FormLabel>
                                                            <Field
                                                                disabled={false}
                                                                type='date'
                                                                className='form-control'
                                                                value={''}
                                                                name='agreement_date'
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs="12" sm="6" md="6" className="mb-5">
                                                        <FormGroup>
                                                            <FormLabel>Company</FormLabel>
                                                            <Field
                                                                type='text'
                                                                disabled={true}
                                                                className='form-control'
                                                                name='company'
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs="12" sm="6" md="6" className="mb-5">
                                                        <FormGroup>
                                                            <FormLabel>Validity Start</FormLabel>
                                                            <Field
                                                                disabled={false}
                                                                type='date'
                                                                className='form-control'
                                                                value={''}
                                                                name='validity_start'
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs="12" sm="6" md="6" className="mb-5">
                                                    </Col>
                                                    <Col xs="12" sm="6" md="6" className="mb-5">
                                                        <FormGroup>
                                                            <FormLabel>Validity End</FormLabel>
                                                            <Field
                                                                disabled={false}
                                                                type='date'
                                                                className='form-control'
                                                                value={''}
                                                                name='validity_end'
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <hr />
                                                <Row className="mt-5">
                                                    <Col xs="12" sm="6" md="6" className="mb-5">
                                                        <FormikSelect2
                                                            label='Incoterm'
                                                            name={`incoterm`}
                                                            optionsUrl={getEnv("masterdata") + `/incoterm`}
                                                            getOptionValue={(opt: any) => opt}
                                                            getOptionLabel={(opt: any) => `${opt.code} - ${opt.name}`}
                                                            search_property={'code_name'}
                                                            isDisabled={false}
                                                        />
                                                    </Col>
                                                    <Col xs="12" sm="6" md="6" className="mb-5">
                                                        <FormGroup>
                                                            <FormLabel>Target Value</FormLabel>
                                                            <Field
                                                                type='text'
                                                                disabled={true}
                                                                className='form-control'
                                                                name='no_tender_procurex'
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs="12" sm="6" md="6" className="mb-5">
                                                        <FormGroup>
                                                            <FormLabel>Incoterm Detail</FormLabel>
                                                            <Field
                                                                type='text'
                                                                disabled={true}
                                                                className='form-control'
                                                                name='purch_group'
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs="12" sm="6" md="6" className="mb-5">
                                                        <FormGroup>
                                                            <FormLabel>Actual Value</FormLabel>
                                                            <Field
                                                                type='text'
                                                                disabled={true}
                                                                className='form-control'
                                                                name='agreement_date'
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs="12" sm="6" md="6" className="mb-5">
                                                    </Col>
                                                    <Col xs="12" sm="6" md="6" className="mb-5">
                                                        <FormGroup>
                                                            <FormLabel>Remaining Value</FormLabel>
                                                            <Field
                                                                type='text'
                                                                disabled={true}
                                                                className='form-control'
                                                                name='validity_start'
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>

                                <Accordion defaultActiveKey={['0']} className='mb-5'>
                                    <Accordion.Item eventKey='0'>
                                        <Accordion.Header><h3>Purchase Order (Item)</h3></Accordion.Header>
                                        <Accordion.Body>
                                            <div className={'card card-body'}>
                                                <div className="mt-10 table-responsive" style={{ minWidth: "100%" }}>
                                                    <table className={"table table-bordered table-rounded table-header-gray gs-5"}>
                                                        <thead>
                                                            <tr>
                                                                <th
                                                                    className={"text-center border px-2"}
                                                                    style={{ minWidth: "75px", position: "sticky", left: 0, zIndex: 1 }}
                                                                >Action</th>
                                                                <th
                                                                    className={"text-left border"}
                                                                    style={{ minWidth: "200px", position: "sticky", left: "75px", zIndex: 1 }}
                                                                >Resume</th>
                                                                <th className={"text-left border"} style={{ minWidth: "200px" }}>Description</th>
                                                                <th className={"text-left border"}>Qty</th>
                                                                <th className={"text-left border"} style={{ minWidth: "100px" }}>Satuan</th>
                                                                <th className={"text-left border"} style={{ minWidth: "150px" }}>Harga Satuan</th>
                                                                <th className={"text-left border"} style={{ minWidth: "150px" }}>Sub Total</th>
                                                                <th className={"text-left border"}>Currency</th>
                                                                <th className={"text-left border"} style={{ minWidth: "150px" }}>Add Cost</th>
                                                                <th className={"text-left border"} style={{ minWidth: "150px" }}>Total</th>
                                                                <th className={"text-left border"}>Delivery</th>
                                                                <th className={"text-left border"} style={{ minWidth: "100px" }}>Tax Code</th>
                                                                <th className={"text-left border"} style={{ minWidth: "200px" }}>Notes</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {items && items?.map((item: any, index: any) => (
                                                                <>
                                                                    <tr key={index}>
                                                                        <td className={"border text-center"} rowSpan={item?.serviceLines?.length + 1} style={{
                                                                            position: "sticky", left: 0, zIndex: 1, backgroundColor: "#fff"
                                                                        }}>
                                                                            <div>
                                                                                <OverlayTrigger
                                                                                    placement='right'
                                                                                    overlay={<Tooltip id='button-tooltip-2'>Item PO</Tooltip>}
                                                                                >
                                                                                    <a href={"#"} title={"Lihat Detail"} onClick={() => {
                                                                                        setModalFormDetailItem(true)
                                                                                        setModalData(item)
                                                                                    }}>
                                                                                        <i className={"fa fs-3 fa-pencil text-dark"}></i>
                                                                                    </a>
                                                                                </OverlayTrigger>
                                                                            </div>
                                                                        </td>
                                                                        <td className={"border text-start"} rowSpan={item?.serviceLines?.length + 1} style={{
                                                                            verticalAlign: 'top',
                                                                            borderBottom: '4px #dee2e6 !important',
                                                                            position: "sticky", left: "75px", zIndex: 1, backgroundColor: "#fff"
                                                                        }}>
                                                                            <div className={"d-flex flex-column"}>
                                                                                <div className={"p-0 m-0 d-flex flex-column border-bottom mb-2"}>
                                                                                    <small className={"text-muted"}>Nomor PR</small>
                                                                                    {item?.no_pr || "-"}
                                                                                </div>
                                                                                <div className={"p-0 m-0 d-flex flex-column border-bottom mb-2"}>
                                                                                    <small className={"text-muted"}>Item PR</small>
                                                                                    {item?.line_no || "-"}
                                                                                </div>
                                                                                <div className={"p-0 m-0 d-flex flex-column border-bottom mb-2"}>
                                                                                    <small className={"text-muted"}>Nomor Material</small>
                                                                                    {item?.material_id || "-"}
                                                                                </div>
                                                                                <div className={"p-0 m-0 d-flex flex-column mb-2"}>
                                                                                    <small className={"text-muted"}>Plant</small>
                                                                                    {(item?.plant?.kode_plant + ' - ' + item?.plant?.nama_plant) || "-"}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className={"border text-start"} colSpan={4}>{`Jasa Item ${item?.line_no}`}</td>
                                                                        <td className={"border text-start"}>{parseNumberToCurrency(item?.serviceLines?.reduce((a: any, b: any) => parseFloat(a) + parseFloat(b.net_price), 0))}</td>
                                                                        <td className={"border text-start"} rowSpan={item?.serviceLines?.length + 1}>{item?.currency?.code}</td>
                                                                        <td className={"border text-start"} rowSpan={item?.serviceLines?.length + 1}>{parseNumberToCurrency(item?.additional_cost)}</td>
                                                                        <td className={"border text-start"} rowSpan={item?.serviceLines?.length + 1}>{parseNumberToCurrency(parseInt(item?.total_value) + parseInt(item?.additional_cost || '0'))}</td>
                                                                        <td className={"border text-start"} rowSpan={item?.serviceLines?.length + 1}>{item?.delivery_date ? moment(item?.delivery_date).format('DD/MM/yyyy') : ''}</td>
                                                                        <td className={"border text-start"} rowSpan={item?.serviceLines?.length + 1}>{item?.tax_code_id}</td>
                                                                        <td className={"border text-start"} rowSpan={item?.serviceLines?.length + 1}>{item?.note}</td>
                                                                    </tr>
                                                                    {item?.serviceLines?.map((item: any, index: any) => (
                                                                        <tr>
                                                                            <td className={"border text-start"}>{item?.short_text}</td>
                                                                            <td className={"border text-start"}>{item?.quantity}</td>
                                                                            <td className={"border text-start"}>{item?.uom_id}</td>
                                                                            <td className={"border text-start"}>{parseNumberToCurrency(item?.gross_price)}</td>
                                                                            <td className={"border text-start"}>{parseNumberToCurrency(item?.net_price)}</td>
                                                                        </tr>
                                                                    ))}
                                                                </>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Form>
                        )}
                    </Formik>
                </>
            )}
        </>
    )
})

export default AgreementDetailHeader