import {formatNumberToDecimal, getEnv, parseNumberToCurrency, parseNumberToFloat} from '../../../../../../../../helpers/procurex'
import React, { useEffect, useState } from "react";
import TableXWrapper from "../../../../../../../components/shared/TableXWrapper";
import { useAxiosGet, useAxiosPost } from "../../../../../../../../hooks/useAxios";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";
import Swal from "sweetalert2";
import { Badge, Panel, Stack } from "rsuite";
import NoteAttachmentEvaluation from "../../NoteAttachmentEvaluation";
import { Spinner } from "react-bootstrap";
import EvaluasiTeknis from "../../EvaluasiTeknis";
import EvaluasiAdministrasi from "../../EvaluasiAdministrasi";
import CurrencyInput from "react-currency-input-field";
import axios  from "axios";
import PreviewAttachment from "../../../../../../../components/PreviewAttachment";
import EvaluasiKomersil from '../../EvaluasiKomersil'

const StatusBadgePassedFailed = ({ status }: any) => {
  return (
    <span
      className={`badge ${
        status === "PASSED" ? "bg-success" : "bg-danger"
      } text-white`}
    >
      {status}
    </span>
  );
};

const ProsesEvaluasiKomersil = ({ proposalTender, quotation, onChange, onChangeEvaluationData, isOwner }: any) => {
  const [syarats, setSyarats] = useState([]);
  const [dataItems, setDataItems] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isUncheckAll, setIsUncheckAll] = useState(false);
  const [renderKey, setRenderKey] = useState(0);
  const [durVendor, setDurVendor] = useState(null as any);
  const [note, setNote] = useState("");
  const [attachment, setAttachment] = useState([]);
  const [deletedAttachment, setDeletedAttachment] = useState([]);

  const intl = useIntl();

  const [previewAttachment, setPreviewAttachment] = useState( null);
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosGet(
    getEnv("tender") + `/evaluation/item/${proposalTender?.uuid}/${quotation?.vendor?.uuid}`
  );

  const [{ doSubmit: doSubmitSave, isLoading: isLoadingSave }] = useAxiosPost(
    getEnv("tender") + `/evaluation/administration/syarat`
  );

  const getSyarats = () => {
    doSubmit({},
      getEnv("tender") + `/evaluation/commercial/${proposalTender?.uuid}/${quotation?.vendor?.uuid}`,
      undefined, () => {
      }
    ).then((res: any) => {
      setSyarats(res.data);
    }).catch((err: any) => {
    });
  };

  const getItems = () => {
    doSubmit({}, undefined, undefined, () => {
    }).then((res: any) => {
      const _items = res.data;
      setDataItems(_items);
    }).catch((err: any) => {
      toast.error("Get Evaluasi Teknis: " + err?.response?.data?.meta?.message);
    });
  };

  const handleSaveEvaluateVendor = () => {
    const evaluateVendorURL = getEnv("tender") + `/evaluation/vendor/v2/${proposalTender?.uuid}/${quotation?.vendor?.uuid}`;
    doSubmitSave({}, evaluateVendorURL, toast, () => {}).then((res: any) => {
    }).catch((err: any) => {});
  }

  const updateSyarat = (index: number, state: string) => {
    const newSyarats = syarats.map((syarat: any, i: number) => {
      if (i === index) {
        syarat.status = state;
      }
      return syarat;
    });
    // @ts-ignore
    setSyarats(newSyarats);
  };

  const saveSyarat = () => {
    if (proposalTender?.order_placement === "Paket") {
      submitSyaratIfPaket();
    } else if (proposalTender?.order_placement === "Itemize") {
      submitSyaratIfItemize();
    }
  };

  const submitSyaratIfPaket = () => {
    // @ts-ignore
    const requirements = [];
    let isAllPassed = true;
    syarats.forEach((syarat: any) => {
      if(syarat?.status !== 'NOT-EVALUATE') {
        requirements.push({
          id: syarat.uuid,
          evaluation: syarat.status === "PASSED",
          description: ""
        });
        if (syarat.status !== "PASSED") isAllPassed = false;
      }
    });

    // @ts-ignore
    const payload = {
      proposal_tender_vendor_id: quotation?.vendor?.uuid,
      // @ts-ignore
      requirements: requirements
    };

    const isNeedEvaluateCommercial = proposalTender?.metode_evaluasi === "Sistem Gugur";

    if(requirements.length !== 0) {
      doSubmitSave(payload, getEnv("tender") + `/evaluation/commercial/syarat/${proposalTender?.uuid}`, !isNeedEvaluateCommercial ? toast : undefined, () => {
      }).then((res: any) => {
          const evaluateVendorURL = getEnv("tender") + `/evaluation/vendor/v2/${proposalTender?.uuid}/${quotation?.vendor?.uuid}`;
          doSubmitSave({}, evaluateVendorURL, toast, () => {}).then((res: any) => {
            onChange && onChange();
            getDurVendor();
          }).catch((err: any) => {});
        // }
      });
    }
  };

  const submitSyaratIfItemize = () => {
    // @ts-ignore
    const requirements = [];
    syarats.forEach((syarat: any) => {
      requirements.push({
        id: syarat.uuid,
        evaluation: syarat.status === "PASSED",
        description: ""
      });
    });

    // @ts-ignore
    const payload = {
      proposal_tender_vendor_id: quotation?.vendor?.uuid,
      // @ts-ignore
      requirements: requirements
    };

    doSubmitSave(payload, getEnv("tender") + `/evaluation/commercial/syarat/${proposalTender?.uuid}`, toast, () => {
    }).then((res: any) => {
      const evaluateVendorURL = getEnv("tender") + `/evaluation/vendor/v2/${proposalTender?.uuid}/${quotation?.vendor?.uuid}`;
      doSubmitSave({}, evaluateVendorURL, toast, () => {}).then((res: any) => {
        onChange && onChange();
        getDurVendor();
      }).catch((err: any) => {});
    });
  };

  const submitEvaluationItemize = (item: any, state: boolean) => {
    const payload = {
      proposal_tender_vendor_id: quotation?.vendor?.uuid,
      quotation_item_id: item?.uuid,
      evaluation: state,
      note: "-"
    };
    doSubmitSave(payload, getEnv("tender") + `/evaluation/commercial/item/${proposalTender?.uuid}`, toast, () => {
      dataItems.forEach((item2: any) => {
        if (item2?.uuid === item?.uuid) {
          item.commercial_status = state ? "PASSED" : "FAILED";
        }
      });
    }).then((res: any) => {
      onChange && onChange();
    });
  };

  const isItemize = () => {
    return proposalTender?.order_placement === "Itemize";
  };

  const isItemizeSistemGugur = () => {
    return proposalTender?.order_placement === "Itemize" &&
      proposalTender?.metode_evaluasi === "Sistem Gugur";
  };

  const getDurVendor = async () => {
    const url = getEnv("tender") + `/dur-vendor?filter[proposal_tender_id]=${proposalTender?.id}&filter[vendor_id]=${quotation?.vendor_id}`;
    doSubmit({}, url, undefined, () => {}).then((res: any) => {
      setDurVendor(res.data[0]);
    }).catch((err: any) => {});
  }

  const isFormEnabled = () => {
    const allowedStatus = [
      "Evaluasi Komersil"
    ];
    return allowedStatus.includes(proposalTender?.status) && !isLoadingSave;
  };

  useEffect(() => {
    getItems();
    getSyarats();
    getDurVendor();
  }, []);

  // useEffect(() => {
  //   if (syarats?.length > 0 && renderKey > 0) saveSyarat();
  //   if (syarats?.length > 0) setRenderKey(renderKey + 1);
  // }, [syarats]);

  const handleOnSubmitEvaluasiKomersil = async() => {
    Swal.fire({
      title: `${intl.formatMessage({id:"Apakah Anda yakin"})}?`,
      html: `${intl.formatMessage({id:"Anda akan mensubmit Evaluasi Komersil"})}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Ya, Submit`,
      cancelButtonText: intl.formatMessage({id:"Cancel"}),
    }).then((result) => {
      if (result.isConfirmed) {
        //region save syarat
        saveSyarat();


        //save note
        if (note) {
          doSubmitSave({
            note: note || "-",
            type: "Evaluasi Komersil"
          }, getEnv("tender") + `/evaluation/notes/${quotation?.vendor?.uuid}`, undefined);

        }

        //save attachment
        // @ts-ignore
        if (attachment && attachment.length > 0) {
          const payloadAttachment = {
            // @ts-ignore
            description: attachment[0]?.media_type?.file_name,
            type: "Evaluasi Komersil",
            // @ts-ignore
            media_type: attachment[0]?.media_type,
            // @ts-ignore
            media_url: attachment[0]?.media_url
          }
          doSubmitSave(payloadAttachment, getEnv("tender") + `/evaluation/attachment/${quotation?.vendor?.uuid}`, undefined);
        }

        //delete attachment
        // @ts-ignore
        if (deletedAttachment && deletedAttachment.length > 0) {
          deletedAttachment.forEach((item: any) => {
            doSubmitSave({
              _method: "DELETE"
            }, getEnv("tender") + `/evaluation/attachment/${quotation?.vendor?.uuid}/${item}`, undefined);
          })
        }
      }
    })
  }

  const TableItemJasa = ({proposalTender, service, quotation, item, index,}: any) => {
    const [shortText, setShortText] = useState<any>(service?.short_text);
    const [remark, setRemark] = useState<any>(service?.remark);
    const [quantity, setQuantity] = useState<any>(service?.quantity);
    const [valuationPrice, setValuationPrice] = useState<any>(service?.net_price);
    const [totalPenawaran, setTotalPenawaran] = useState<any>(service?.gross_price);
    const [renderIndex, setRenderIndex] = useState<any>(0);
    const [debounceTimer, setDebounceTimer] = useState<any>(null);

    return (
      <>
        <tr key={index + "-" + service?.id} style={{
          position: "relative",
          height: "auto"
        }}>
          <td className={"text-center border align-middle"}>
            {isLoading && (
              <div className={""} style={{
                position: "absolute",
                width: "100%",
                textAlign: "center",
                backgroundColor: "rgba(255,255,255,0.5)",
                zIndex: 9999,
                justifyContent: "center",
                alignItems: "center",
                height: "100%"
              }}>
                <i className={"fa fa-spinner fa-spin fa-2x"}></i>
              </div>
            )}
          </td>
          {/* Start Short Text */}
          <td className={"text-start border align-start bg-white"} style={{
            verticalAlign: "middle", position: 'sticky', left: 0, zIndex: 1
          }}>
            <textarea
              className={"form-control form-control-sm"}
              defaultValue={shortText}
              rows={1}
              disabled={true}
              maxLength={50}>
            </textarea>
          </td>
          {/* End Short Text */}
          <td></td>
          {/* Start UoM */}
          <td className={"text-center border align-start"}>
            <input type={"text"}
                  className={"form-control form-control-sm"}
                  disabled={true}
                  value={service?.uom_id}
                  style={{
                    minWidth: "50px"
                  }}
            />
          </td>
          {/* End UoM */}

          {/* Start Qty PR */}
          <td className={"text-center border align-start"}>
            <CurrencyInput
              className="form-control form-control-sm text-end"
              groupSeparator="."
              decimalSeparator=","
              decimalsLimit={2}
              value={quantity}
              style={{
                maxWidth: "200px"
              }}
              disabled={true}
            />
          </td>
          {/* End Qty PR */}

          {/* Start HPS/OE/PR Price */}
          <td className={"text-center border align-start"}>
            <input type={"text"}
                  className={"form-control form-control-sm text-end"}
                  disabled={true}
                  value={parseNumberToCurrency(service?.gross_value_pr, service?.currency_id_pr)}
                  style={{ minWidth: "200px" }}
            />
          </td>
          {/* End HPS/OE/PR Price */}

          {/* Start Qty Vendor */}
          <td className={"text-center border align-start"}>
            <CurrencyInput
              className="form-control form-control-sm text-end"
              groupSeparator="."
              decimalSeparator=","
              decimalsLimit={2}
              value={quantity}
              onValueChange={(value, name) => {
                setQuantity(value);
              }}
              style={{
                maxWidth: "200px"
              }}
              disabled={true}
            />
          </td>
          {/* End Qty Vendor */}

          {/* Start Penawaran Vendor Price */}
          <td className={"text-center border align-start"}>
            <input type={"text"}
                  className={"form-control form-control-sm text-end"}
                  disabled={true}
                  value={parseNumberToCurrency(service?.gross_price, service?.currency_id)}
                  style={{ minWidth: "200px" }}
            />
          </td>
          {/* End Penawaran Vendor Price */}
        </tr>
      </>
    )
  }

  const TableItemService = ({proposalTender, service, quotation, item, index,}: any) => {
    let serviceLinesCount         = item?.service_lines_quotation?.length === 0 ? 0 : item?.service_lines_quotation?.length;
    let totalRowSpanPerItem       = serviceLinesCount + 1;

    let totalServiceLinesAllItem  = dataItems.reduce((total: any, item: { service_lines_quotation: string | any[]; }) => {
      return total + ((item?.service_lines_quotation?.length === 0) ? 0 : item?.service_lines_quotation?.length);
    }, 0);

    let totalRowSpan              = totalServiceLinesAllItem + dataItems.length;


    return (
      <>
        {/* Start No */}
        <td className={"border px-2 text-center"} rowSpan={totalRowSpanPerItem} 
          style={{ position: "sticky", left: 0, zIndex: 1, verticalAlign: "middle", backgroundColor: "#fff" }}
        >{index + 1}</td>
        {/* End No */}

        {/* Start Short Text */}
        <td className={"border"}
            style={{ position: "sticky",left: 30,zIndex: 1,backgroundColor: "#fff" }}>{item?.short_text}
        </td>
        {/* End Short Text */}

        {/* Start Specification */}
        <td className={"border"} style={{ maxHeight: `${40 * item.serviceLinesCount}px`}} rowSpan={totalRowSpanPerItem}>
          <div style={{
            maxHeight: `${40 * item.serviceLinesCount}px`,
            width: "250px",
            overflow: "auto"
          }}>
            <textarea
              className={"form-control form-control-sm"}
              defaultValue={item?.spesification}
              rows={(3 * serviceLinesCount) + 1}
              disabled={true}
              maxLength={250}>
            </textarea>
          </div>
        </td>
        {/* End Specification */}

        {/* Start Comply or Deviate */}
        <td className={"border text-center text-nowrap"} rowSpan={totalRowSpanPerItem} style={{
          verticalAlign: "middle",
        }}>
          <strong>{item?.quote}</strong>
        </td>
        {/* End Comply or Deviate */}

        {/* Start Curr */}
        <td className={"border"}>{item?.currency?.code}</td>
        {/* End Curr */}

        {/* Start Uom */}
        <td className={"border"}>{item?.uom?.uom}</td>
        {/* End Uom */}

        {/* Start Per */}
        <td className={"border text-center text-nowrap"} rowSpan={totalRowSpanPerItem} style={{
          verticalAlign: "middle",
        }}>{item?.per}</td>
        {/* End Per */}

        {/* Start Deskripsi Addcost */}
        <td className={"text-center border align-start"} style={{ maxHeight: `${40 * item.serviceLinesCount}px`, verticalAlign: "middle"}} rowSpan={totalRowSpanPerItem}>
            <textarea
              className={"form-control form-control-sm"}
              defaultValue={item?.additional_cost_description}
              rows={(2 * serviceLinesCount)}
              disabled={true}
              maxLength={250}>
            </textarea>
        </td>
        {/* End Deskripsi Addcost */}

        {/* Start Addcost */}
        <td className={"text-center border align-start"} style={{ maxHeight: `${40 * item.serviceLinesCount}px`, verticalAlign: "middle"}} rowSpan={totalRowSpanPerItem}>
          <input type={"text"}
                className={"form-control form-control-sm text-end"}
                disabled={true}
                value={parseNumberToCurrency(item?.additional_cost, item?.currency?.code)}
                style={{ minWidth: "150px" }}
          />
        </td>
        {/* End Addcost */}

        {/* Start Quantity */}
        <td className={"border text-end"}>{parseNumberToFloat(item?.quantity)}</td>
        {/* End Quantity */}

        {/* Start Buyer Price */}
        <td className={"border text-end"}>{parseNumberToCurrency(item?.buyer_valuation_price, item?.currency?.code)}</td>
        {/* End Buyer Price */}

        {/* Start Quantity */}
        <td className={"border text-end"}>{parseNumberToFloat(item?.quantity)}</td>
        {/* End Quantity */}

        {/* Start Quote Price */}
        <td className={`${parseFloat(item?.valuation_price) > parseFloat(item?.buyer_valuation_price) ? (
          'text-end border text-danger'
        ) : (
          'text-end border'
        )}`}>{parseNumberToCurrency(item?.valuation_price, item?.currency?.code)}</td>
        {/* End Quote Price */}
        
        {/* Start Quote Price */}
        <td className={`${parseFloat(item?.valuation_price) > parseFloat(item?.buyer_valuation_price) ? (
          'text-end border text-danger px-2'
        ) : (
          'text-end border px-2'
        )}`}>{parseNumberToCurrency(item?.total_value, item?.currency?.code)}</td>
        {/* End Quote Price */}
      </>
    )
  }

  const TableItemServiceLines = ({proposalTender, service, quotation, item, index,}: any) => {
    const [shortTextPR, setShortTextPR] = useState<any>(service?.short_text_pr);
    const [quantity, setQuantity] = useState<any>(service?.quantity);
    const [valuationPrice, setValuationPrice] = useState<any>(service?.net_price);
    const [totalPenawaran, setTotalPenawaran] = useState<any>(service?.gross_price);

    return (
      <>
        <tr key={index + "-" + service?.id} style={{
          position: "relative",
          height: "auto",
        }}>
          {/* Start Short Text */}
          <td className={"text-start border align-start bg-white px-2"} style={{
            verticalAlign: "middle", position: 'sticky', left: 30, zIndex: 1
          }}>
            <textarea
              className={"form-control form-control-sm"}
              defaultValue={shortTextPR}
              rows={1}
              disabled={true}
              maxLength={50}>
            </textarea>
          </td>
          {/* End Short Text */}

          {/* Start Currency */}
          <td className={"text-center border align-start"}>
            <input type={"text"}
              className={"form-control form-control-sm"}
              disabled={true}
              value={service?.currency_id}
              style={{
                minWidth: "50px"
              }}
            />
          </td>
          {/* End Currency */}

          {/* Start UoM */}
          <td className={"text-center border align-start"}>
            <input type={"text"}
              className={"form-control form-control-sm"}
              disabled={true}
              value={service?.uom_id}
              style={{
                minWidth: "50px"
              }}
            />
          </td>
          {/* End UoM */}

          {/* Start Qty */}
          <td className={"text-center border align-start"}>
            <CurrencyInput
              className="form-control form-control-sm text-end"
              groupSeparator="."
              decimalSeparator=","
              decimalsLimit={2}
              value={quantity}
              onValueChange={(value, name) => {
                setQuantity(value);
              }}
              style={{
                maxWidth: "150px"
              }}
              disabled={true}
            />
          </td>
          {/* End Qty */}

          {/* Start Buyer Price */}
          <td className={"text-center border align-start"}>
            <input type={"text"}
              className={"form-control form-control-sm text-end"}
              disabled={true}
              value={parseNumberToCurrency(service?.gross_value_pr, service?.currency_id)}
              style={{ minWidth: "200px" }}
            />
          </td>
          {/* End Buyer Price */}

          {/* Start Qty */}
          <td className={"text-center border align-start"}>
            <CurrencyInput
              className="form-control form-control-sm text-end"
              groupSeparator="."
              decimalSeparator=","
              decimalsLimit={2}
              value={quantity}
              onValueChange={(value, name) => {
                setQuantity(value);
              }}
              style={{
                minWidth: "100px"
              }}
              disabled={true}
            />
          </td>
          {/* End Qty */}

          {/* Start Quote Price */}
          <td className={"text-center border align-start"}>
            <input type={"text"}
              className={"form-control form-control-sm text-end"}
              disabled={true}
              value={parseNumberToCurrency(valuationPrice, service?.currency_id)}
              style={{ minWidth: "200px" }}
            />
          </td>
          {/* End Quote Price */}

          {/* Start Quote totalPenawaran */}
          <td className={"text-center border align-start px-2"}>
            <input type={"text"}
                  className={"form-control form-control-sm text-end"}
                  disabled={true}
                  value={parseNumberToCurrency(totalPenawaran, service?.currency_id)}
                  style={{ minWidth: "200px" }}
            />
          </td>
          {/* End Quote totalPenawaran */}
        </tr>
      </>
    )
  }

  const TableItemMaterial = ({proposalTender, service, quotation, item, index,}: any) => {
    return (
      <>
        {/* Start No */}
        <td className={"border px-2 text-center"}
          style={{ position: "sticky", left: 0, zIndex: 1, backgroundColor: "#fff" }}
        >{index + 1}</td>
        {/* End No */}

        {/* Start Short Text */}
        <td className={"border"}
            style={{ position: "sticky",left: 30,zIndex: 1,backgroundColor: "#fff" }}>{item?.buyer_short_text}
        </td>
        {/* End Short Text */}

        {/* Start Buyer Specification */}
        <td className={"border"} style={{ maxHeight: `${40 * item.serviceLinesCount}px`}}>
          <div style={{
            maxHeight: `${40 * item.serviceLinesCount}px`,
            width: "250px",
            overflow: "auto"
          }}>
            <textarea
              className={"form-control form-control-sm"}
              defaultValue={item?.buyer_spesification}
              rows={1}
              disabled={true}
              maxLength={250}>
            </textarea>
          </div>
        </td>
        {/* End Buyer Specification */}

        {/* Start Comply or Deviate */}
        <td className={"border text-center text-nowrap"}>
          <strong>{item?.quote}</strong>
        </td>
        {/* End Comply or Deviate */}

        {/* Start Currency */}
        <td className={"border text-center text-nowrap"}>{item?.currency?.code}</td>
        {/* End Currency */}

        {/* Start UoM */}
        <td className={"border text-center text-nowrap"}>{item?.uom?.uom}</td>
        {/* End UoM */}

        {/* Start Per */}
        <td className={"border text-center text-nowrap"}>{item?.per}</td>
        {/* End Per */}

        {/* Start Deskripsi Addcost */}
        <td className={"text-center border align-start"} style={{ maxHeight: `40px`, verticalAlign: "middle"}}>
            <textarea
              className={"form-control form-control-sm"}
              defaultValue={item?.additional_cost_description}
              rows={2}
              disabled={true}
              maxLength={250}>
            </textarea>
        </td>
        {/* End Deskripsi Addcost */}

        {/* Start Addcost */}
        <td className={"text-center border align-start"} style={{ maxHeight: `40px`, verticalAlign: "middle"}}>
          <input type={"text"}
                className={"form-control form-control-sm text-end"}
                disabled={true}
                value={parseNumberToCurrency(item?.additional_cost, item?.currency?.code)}
                style={{ minWidth: "150px" }}
          />
        </td>
        {/* End Addcost */}

        {/* Start Quantity */}
        <td className={"border text-end"}>{parseNumberToFloat(item?.quantity)}</td>
        {/* End Quantity */}

        {/* Start Buyer Price */}
        <td className={"border text-end"}>{parseNumberToCurrency(item?.buyer_valuation_price, item?.currency?.code)}</td>
        {/* End Buyer Price */}

        {/* Start Quantity */}
        <td className={"border text-end"}>{parseNumberToFloat(item?.quantity)}</td>
        {/* End Quantity */}

        {/* Start Quote Price */}
        <td className={`${parseFloat(item?.valuation_price) > parseFloat(item?.buyer_valuation_price) ? (
          'text-end border text-danger'
        ) : (
          'text-end border'
        )}`}>{parseNumberToCurrency(item?.valuation_price, item?.currency?.code)}</td>
        {/* End Quote Price */}
        
        {/* Start Quote Price */}
        <td className={`${parseFloat(item?.valuation_price) > parseFloat(item?.buyer_valuation_price) ? (
          'text-end border text-danger px-2'
        ) : (
          'text-end border px-2'
        )}`}>{parseNumberToCurrency(item?.total_value, item?.currency?.code)}</td>
        {/* End Quote Price */}
      </>
    )
  }

  const getTotalValueFromVendorQuotation = () => {
    let total = '0';
    let _total = 0;

    // backlog: currency not found
    let curr = dataItems[0]?.currency?.code ?? 'IDR';

    dataItems.forEach((item: any) => {
      _total += parseFloat(item.total_value);
    });

    total = _total.toLocaleString('id-ID', {
              style: 'currency',
              currency: curr
            });

    return total;
  }

  return (
    <>
      <Panel
        header={
          <Stack justifyContent={"space-between"}>
            <h3>{intl.formatMessage({ id: "Proses Evaluasi Komersil" })}</h3>

            {(durVendor?.status_evaluation_commercial === "PASSED" || durVendor?.status_evaluation_commercial === "FAILED") && (
              <div>
                <Badge className={"px-2 py-1"} content={
                  <>
                    <i className={"fa fa-check-circle text-white me-2"}></i>
                    {intl.formatMessage({ id: "Dievaluasi" })}
                  </>
                } color="green" />
              </div>
            )}

            {(durVendor?.status_evaluation_commercial === "NO-DATA" || durVendor?.status_evaluation_commercial === "NOT-EVALUATE") && (
              <div>
                <Badge className={"px-2 py-1"} content={
                  <>
                    <i className={"fa fa-times-circle text-white me-2"}></i>
                    {intl.formatMessage({ id: "Belum Dievaluasi" })}
                  </>
                } color="red" />
              </div>
            )}
          </Stack>
        }
        collapsible
        bordered
        defaultExpanded={true}
      >

      <div className={"table-responsive"}>
        <table className={"table table-bordered table-striped table-header-gray"}>
        <thead>
          <tr>
            <th
              className={"text-center border text-nowrap px-2"}
              style={{ verticalAlign: "middle", position: "sticky", left: 0, zIndex: 1 }}
              rowSpan={2}
            >
              <strong>&nbsp;{intl.formatMessage({ id: "No" })}</strong>
            </th>
            <th className={"border align-items-start"}
                style={{ verticalAlign: "middle", minWidth: "250px", position: "sticky", left: 30, zIndex: 1 }}
                rowSpan={2}>
              <strong>{intl.formatMessage({ id: "Deskripsi" })}</strong>
            </th>
            <th className={"text-center border"} rowSpan={2} style={{ verticalAlign: "middle", minWidth: "200px" }}>
              <strong>{intl.formatMessage({ id: "Spesifikasi" })}</strong>
            </th>
            <th className={"text-center border text-nowrap"} rowSpan={2} style={{ verticalAlign: "middle" }}>
              <strong>{intl.formatMessage({ id: "Quote" })}</strong>
            </th>
            <th className={"text-center border text-nowrap"} rowSpan={2} style={{ verticalAlign: "middle" }}>
              <strong>{intl.formatMessage({ id: "Mata Uang" })}</strong>
            </th>
            <th className={"text-center border"} rowSpan={2} style={{ verticalAlign: "middle" }}>
              <strong>{intl.formatMessage({ id: "Satuan" })}</strong>
            </th>
            <th className={"text-end border"} rowSpan={2} style={{ verticalAlign: "middle" }}>
              <strong>Per</strong>
            </th>
            <th className={"text-center border text-nowrap"} rowSpan={2} style={{ verticalAlign: "middle" }}>
              <strong>{intl.formatMessage({ id: "Deskripsi Add Cost" })}</strong>
            </th>
            <th className={"text-center border text-nowrap"} rowSpan={2} style={{ verticalAlign: "middle" }}>
              <strong>{intl.formatMessage({ id: "Add Cost" })}</strong>
            </th>
            <th className={"text-center border text-nowrap"} colSpan={2}>
              <strong>{intl.formatMessage({ id: "Permintaan Buyer" })}</strong>
            </th>
            <th className={"text-center border text-nowrap"} colSpan={3}>
              <strong>{intl.formatMessage({ id: "Penawaran Vendor" })}</strong>
            </th>
          </tr>
          <tr>
            <th className={"text-end border"} style={{
              minWidth: "100px"
            }}>{intl.formatMessage({ id: "Kuantitas" })}</th>
            <th className={"text-end border text-nowrap"}>{intl.formatMessage({ id: "Harga Satuan" })}</th>
            <th className={"text-end border"} style={{
              minWidth: "100px"
            }}>{intl.formatMessage({ id: "Kuantitas" })}</th>
            <th className={"text-end border text-nowrap"}>{intl.formatMessage({ id: "Harga Satuan" })}</th>
            <th className={"text-end border text-nowrap px-2"}>{intl.formatMessage({ id: "Total Harga" })}</th>
          </tr>
        </thead>
        <tbody>
          {dataItems.map((item: any, index: number) => {
            let isBarang    = (item?.service_lines_quotation?.length === 0)
            let isJasa      = (item?.service_lines_quotation?.length > 0);

            let serviceLinesCount         = (item?.service_lines_quotation?.length === 0) ? 0 : item?.service_lines_quotation?.length;
            let totalRowSpanPerItem       = serviceLinesCount + 1;

            let totalServiceLinesAllItem  = dataItems.reduce((total: any, item: { service_lines_quotation: string | any[]; }) => {
              return total + ((item?.service_lines_quotation?.length === 0) ? 0 : item?.service_lines_quotation?.length);
            }, 0);

            let totalRowSpan              = totalServiceLinesAllItem + dataItems.length;

            return (
              <>
                <tr key={"item-evaluasi-komersil" + index}>
                  {(isBarang) && (
                    <TableItemMaterial proposalTender={proposalTender} service={item} quotation={quotation} item={item} index={index} />
                  )}
                  {(isJasa) && (
                    <TableItemService proposalTender={proposalTender} service={item} quotation={quotation} item={item} index={index} />
                  )}
                </tr>
                {(item?.service_lines_quotation && item?.service_lines_quotation?.length > 0)  && item?.service_lines_quotation?.map((service: any, index2: number) => {
                  return (
                    <TableItemServiceLines
                      service={service}
                      index={index2}
                      indexItem={index}
                      key={index2 + "-" + service?.id}
                      quotation={quotation}
                      item={item}
                      proposalTender={proposalTender}
                    />
                  );
                })}
              </>
            );
          })}
        </tbody>
            <tfoot>
              <td 
                className={"text-center border align-end"} 
                style={{backgroundColor: '#0e365b', fontWeight: 'bold', fontSize: '20px', color: "white", verticalAlign: "middle", position: "sticky", left: 0, zIndex: 1}} 
                colSpan={3}
              > Total Penawaran Vendor</td>
              <td 
                className={"text-end border align-start px-20"} 
                style={{backgroundColor: '#0e365b', fontWeight: 'bold', fontSize: '20px', color: 'white', verticalAlign: "middle", position: "sticky", right: 0, zIndex: 0}} 
                colSpan={11}
              ><strong></strong>{getTotalValueFromVendorQuotation()}</td>
            </tfoot>
        </table>
      </div>

      <div className={"table-responsive mt-10"}>
        <table className={"table table-bordered table-header-gray"}>
          <thead>
          <tr>
            <th className={"text-center border"}><strong>No</strong></th>
            <th className={"text-center border"}><strong>Deskripsi</strong></th>
            <th className={"text-center border"}><strong>Mandatory</strong></th>
            <th className={"text-center border"}><strong>Dokumen</strong></th>
            <th className={"text-center border"} colSpan={2}>
              <strong>{intl.formatMessage({ id: "Hasil Evaluasi" })}</strong>
            </th>
          </tr>
          </thead>
          <tbody>
          {syarats.length === 0 && (
            <tr>
              <td className={"text-center border"} colSpan={5}>Tidak ada data.</td>
            </tr>
          )}


          {syarats.map((syarat: any, index: number) => {
            return (
              <tr key={"evaluasi-kommersial" + index}>
                <td className={"text-center border"}>{index + 1}</td>
                <td className={"text-left border"}>{syarat.description}</td>
                <td className={"text-center border"}>{syarat.required_attachment ? "Ya" : "Tidak"}</td>
                <td className={"text-left border"}>
                  {syarat?.media_url && (
                    <div className={"input-group input-group-sm"}>
                      <input type={"text"} className={"form-control form-control-sm"} value={syarat?.description}
                             disabled={true} />
                      <button type={"button"} className={"btn btn-primary btn-sm"} disabled={isLoading}
                              onClick={() => setPreviewAttachment(
                                syarat?.media_url
                              )}>
                        <i className={"fa fa-eye"}></i>
                      </button>
                    </div>
                  )}
                  {!syarat?.media_url &&
                    <div className={"text-gray"} style={{
                      fontStyle: "italic",
                      color: "#a1a5b7"
                    }}><i className={"fa fa-file"}></i> {intl.formatMessage({ id: "Tidak ada data" })}</div>
                  }
                </td>
                <td className={"border text-center"}>
                  <div className={"d-flex align-items-center justify-content-center gap-2"}>
                    {isLoadingSave && (
                      <i className={"fa fa-spinner fa-spin"}></i>
                    )}
                    <input type={"checkbox"}
                           className={"form-check-input"}
                           checked={syarat?.status === "PASSED"}
                           disabled={!isFormEnabled()}
                           onChange={(e: any) => {
                            updateSyarat(index, "PASSED");
                            }}
                    /> Diterima
                  </div>
                </td>
                <td className={"border text-center"}>

                  <div className={"d-flex align-items-center justify-content-center gap-2"}>
                    {isLoadingSave && (
                      <i className={"fa fa-spinner fa-spin"}></i>
                    )}
                    <input type={"checkbox"} className={"form-check-input"} checked={syarat?.status === "FAILED"}
                           disabled={!isFormEnabled()} onChange={(e: any) => {
                      updateSyarat(index, "FAILED");
                    }} /> Tidak Diterima
                  </div>
                </td>
              </tr>
            );
          })}
          </tbody>
        </table>
      </div>

      <NoteAttachmentEvaluation
        proposalTender={proposalTender}
        quotation={quotation}
        onChange={(type:string,value:any)=>{
          setNote(value?.note);
          setAttachment(value?.attachments);
        }}
        onDeleted={(deletedAttachment: any) => {
          // console.log(deletedAttachment);
          setDeletedAttachment(deletedAttachment);
        }}
        fieldKey={"evaluation_commercial"}
      />

      {isOwner && 
        <div className={"mt-4 d-flex justify-content-end"}>
          <button
            className={"btn btn-primary"}
            type={"button"}
            onClick={handleOnSubmitEvaluasiKomersil}
            disabled={isLoadingSave}
          >
            {isLoadingSave && (
              <Spinner animation="border" role="status" size={"sm"} className={"me-2"} />
            )}
            {!isLoadingSave && <i className={"fa fa-save"}></i>}
            {intl.formatMessage({ id: "Simpan Evaluasi Komersil" })}
          </button>
        </div>
      }
      </Panel>
      <PreviewAttachment previewExt={previewAttachment} onHide={setPreviewAttachment} />
    </>
  );
};

const InformasiRekanan = ({ quotation, proposalTender }: any) => {
  const [bidBondAttachment, setBidBondAttachment] = useState(null);
  // console.log("quotation", quotation)
  return (
    <>
      <div className={"row"}>
        <div className={"col-sm-12 col-md-6"}>
          <div className={"form-group mb-3"}>
            <label htmlFor="">Nama Rekanan</label>
            <input type="text" className={"form-control form-control-sm"} value={quotation?.vendor?.vendor?.name}
                   disabled={true} />
          </div>
          <div className={"form-group mb-3"}>
            <label htmlFor="">Nomor Penawaran</label>
            <input type="text" className={"form-control form-control-sm"} value={quotation?.quotation_number}
                   disabled={true} />
          </div>
          <div className={"form-group mb-3"}>
            <label htmlFor="">Nomor Penawaran Komersil</label>
            <input type="text" className={"form-control form-control-sm"} value={quotation?.quotation_commercial_number}
                   disabled={true} />
          </div>
          <div className={"form-group mb-3"}>
            <label htmlFor="">Tanggal Penawaran</label>
            <input type="text" className={"form-control form-control-sm"} value={quotation?.date} disabled={true} />
          </div>
          <div className={"form-group mb-3"}>
            <label htmlFor="">Berlaku Hingga Tanggal</label>
            <input type="text" className={"form-control form-control-sm"} value={quotation?.expired_at}
                   disabled={true} />
          </div>
          <div className={"form-group mb-3"}>
            <label htmlFor="">Masa Berlaku Penawaran</label>
            <div className={"input-group"}>
              <input type="text" className={"form-control form-control-sm"} value={quotation?.validity}
                     disabled={true} />
              <div className="input-group-append">
                <span className="input-group-text">Hari</span>
              </div>
            </div>
          </div>
        </div>
        <div className={"col-sm-12 col-md-6"}>
          <div className={"row mb-3"}>
            <div className={"col-md-4"}>
              <div className={"form-group mb-3"}>
                <label htmlFor="">Incoterm</label>
                <input type="text" className={"form-control form-control-sm"} value={quotation?.incoterm?.code}
                       disabled={true} />
              </div>
            </div>
            <div className={"col-md-8"}>
              <div className={"form-group mb-3"}>
                <label htmlFor="">Deskripsi Incoterm</label>
                <input type="text" className={"form-control form-control-sm"} value={quotation?.incoterm?.name}
                       disabled={true} />
              </div>
            </div>
          </div>
          <div className={"form-group mb-3"}>
            <label htmlFor="">Nilai Jaminan Penawaran</label>

            <div className={"input-group"}>
              <input type="text" className={"form-control  form-control-sm"}
                     value={parseNumberToCurrency(quotation?.bid_bond_value)} disabled={true} />
              <div className="input-group-append">
                <button
                  className={"btn btn-primary btn-sm"}
                  type={"button"}
                  onClick={() => {
                    setBidBondAttachment(quotation?.bid_bond_media_url);
                  }}
                >
                  <i className={"fa fa-eye"}></i>
                </button>
              </div>
              <PreviewAttachment previewExt={bidBondAttachment} onHide={() => {
                setBidBondAttachment(null);
              }} />
            </div>
          </div>
          <div className={"form-group mb-3"}>
            <label htmlFor="">Jaminan Penawaran Berlaku Hingga</label>
            <input type="text" className={"form-control form-control-sm"} value={quotation?.bid_bond_masa_berlaku}
                   disabled={true} />
          </div>
          <div className={"form-group mb-3"}>
            <label htmlFor="">Additional Cost</label>
            <input type="text" className={"form-control form-control-sm"} value={""} disabled={true} />
          </div>
        </div>
      </div>
    </>
  );
};

const HasilAkhirEvaluasi = ({ formIndex, proposalTender, quotation }: any) => {
  const [dataItems, setDataItems] = useState([]);
  const [currentProposalTender, setCurrentProposalTender] = useState(null);
  const [selectedVendor, setSelectedVendor] = useState(proposalTender);
  const [statusEvaluation, setStatusEvaluation] = useState("NOT-EVALUATE");
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosGet(
    getEnv("tender") + `/evaluation/item/${proposalTender?.uuid}/${quotation?.vendor?.uuid}`
  );
  const [summary, setSummary] = useState(null);
  const [nilaiTeknis, setNilaiTeknis] = useState(0);
  const [nilaiKomersil, setNilaiKomersil] = useState(0);
  const [nilaiAmbangBatas, setNilaiAmbangBatas] = useState(0);
  const [nilaiAkhir, setNilaiAkhir] = useState(0);

  const getItems = () => {
    doSubmit({}, undefined, undefined, () => {
    }).then((res: any) => {
      const _items = res.data;
      setDataItems(_items);
    }).catch((err: any) => {
      toast.error("Get Evaluasi Teknis: " + err?.response?.data?.meta?.message);
    });
  };

  const getSelectedVendor = () => {
    // @ts-ignore
    axios
      .get(getEnv("tender")+ `/dur-vendor?filter[proposal_tender_id]=${proposalTender?.id}&filter[vendor_id]=${quotation?.vendor_id}`)
      .then((res: any) => {
        const _selectedVendor = res.data.data?.find((vendor: any) => {
          return (vendor?.vendor_id).toString() === (quotation?.vendor_id).toString();
        });
        setStatusEvaluation(_selectedVendor?.status_evaluation_commercial);
        setSelectedVendor(_selectedVendor);
        setNilaiAkhir(_selectedVendor?.score);
      })
  };

  const getNilaiTeknisKomersil = () => {
    // @ts-ignore
    const configuration = currentProposalTender?.syarats?.find((syarat: any) => syarat?.type === "configuration");
    if (configuration) {
      setNilaiTeknis(configuration?.bobot_teknis);
      setNilaiKomersil(configuration?.bobot_komersial);
      setNilaiAmbangBatas(configuration?.ambang_batas);
    }
  };

  /**
   * paket, ambil dari proposalTender?.vendors?.evaluation_technical
   */
  const checkIsPaketSistemGugur = () => {
    return proposalTender?.order_placement === "Paket" &&
      proposalTender?.metode_evaluasi === "Sistem Gugur";
  };

  const checkIsPaketSistemNilai = () => {
    return proposalTender?.order_placement === "Paket" &&
      proposalTender?.metode_evaluasi === "Sistem Nilai";
  };

  const checkIsItemizeSistemGugur = () => {
    return proposalTender?.order_placement === "Itemize" &&
      proposalTender?.metode_evaluasi === "Sistem Gugur";
  };

  const checkIsItemizeSistemNilai = () => {
    return proposalTender?.order_placement === "Itemize" &&
      proposalTender?.metode_evaluasi === "Sistem Nilai";
  };

  const checkIsPaket = () => {
    return proposalTender?.order_placement === "Paket";
  };

  const checkIsItemize = () => {
    return proposalTender?.order_placement === "Itemize";
  };

  const getProposalTender = () => {
    const newUrl = getEnv("tender") + `/tender/${proposalTender?.uuid}`;
    doSubmit({}, newUrl, undefined, () => {
    }).then((res: any) => {
      const _proposalTender = res.data;
      setCurrentProposalTender(_proposalTender);
    }).catch((err: any) => {
      toast.error("Get Evaluasi Teknis: " + err?.response?.data?.meta?.message);
    });
  };

  useEffect(() => {
    if (formIndex > 0) getProposalTender();
  }, [formIndex]);

  useEffect(() => {
    if (currentProposalTender) {
      getItems();
      getSelectedVendor();
      getNilaiTeknisKomersil();
    }
  }, [currentProposalTender]);

  useEffect(() => {
    setCurrentProposalTender(proposalTender);
  }, []);

  return (
    <>
      <h3>Hasil Akhir Evaluasi Komersil</h3>
      <div className={"table-responsive"}>
        <table className={"table table-bordered table-header-gray"}>
          <thead>
          <tr>
            <th className={"text-center border px-2"} style={{
              verticalAlign: "top", position: "sticky", minWidth: "20px", left: 0, zIndex: 1
            }}>No
            </th>
            <th className={"text-start border"} style={{
              verticalAlign: "top", position: "sticky", minWidth: "200px", left: 30, zIndex: 1
            }}>Nama Rekanan
            </th>
            <th className={"text-start border"} style={{
              minWidth: "300px"
            }}>Deskripsi
            </th>
            {(checkIsPaketSistemGugur() || checkIsItemizeSistemGugur()) && (
              <th className={"text-center border"} colSpan={2}>Kesimpulan</th>
            )}
            {checkIsPaketSistemNilai() && (
              <>
                <th className={"text-center border text-nowrap"}>
                  Nilai Teknis, Proporsi Bobot
                </th>
                <th className={"text-center border text-nowrap"}>
                  Nilai Komersil, Proporsi Bobot
                </th>
                <th className={"text-center border text-nowrap"}>
                  Ambang Batas Teknis
                </th>
                <th className={"text-center border text-nowrap"}>
                  Nilai Total
                </th>
                <th className={"text-center border text-nowrap px-2"}>
                  Kesimpulan
                </th>
              </>
            )}
            {checkIsItemizeSistemNilai() && (
              <>
                <th className={"text-center border text-nowrap"}>
                  Nilai Teknis, Proporsi Bobot
                </th>
                <th className={"text-center border text-nowrap"}>
                  Nilai Komersil, Proporsi Bobot
                </th>

                <th className={"text-center border text-nowrap"}>
                  Nilai Total
                </th>
                <th className={"text-center border text-nowrap"}>
                  Kesimpulan
                </th>
              </>
            )}
          </tr>
          </thead>
          <tbody>
          {dataItems.map((item: any, index: number) => {
            return (
              <tr key={"hasil-akhir-evaluasi" + index}>
                {index === 0 && (
                  <td className={"border text-center px-2"} style={{
                    verticalAlign: "top",
                    position: "sticky",
                    minWidth: "20px",
                    left: 0,
                    zIndex: 1,
                    backgroundColor: "#fff"
                  }} rowSpan={dataItems?.length}>
                    1
                  </td>
                )}
                {index === 0 && (
                  <td className={"border px-2"} style={{
                    verticalAlign: "top",
                    position: "sticky",
                    minWidth: "200px",
                    left: 30,
                    zIndex: 1,
                    backgroundColor: "#fff"
                  }} rowSpan={dataItems?.length}>
                    {/* @ts-ignore */}
                    {selectedVendor?.vendor?.name}
                    {/* {checkIsPaketSistemGugur() ? "t" : "n"} */}
                  </td>
                )}
                <td className={"border px-2"}>{item?.short_text}</td>
                {(checkIsPaketSistemGugur() && index === 0) && (
                  <>
                    <td className={"border text-center"} rowSpan={dataItems?.length}>
                      <input type={"checkbox"} className={"form-check-input"} disabled={true}
                             checked={statusEvaluation === "PASSED"} /> Lolos
                    </td>
                    <td className={"border text-center"} rowSpan={dataItems?.length}>
                      <input type={"checkbox"} className={"form-check-input"} disabled={true}
                             checked={statusEvaluation === "FAILED"} /> Tidak Lolos
                    </td>
                  </>
                )}

                {(checkIsPaketSistemNilai() && index === 0) && (
                  <>
                    <td className={"border text-center px-2"} rowSpan={dataItems?.length}>
                      {nilaiTeknis}
                    </td>
                    <td className={"border text-center px-2"} rowSpan={dataItems?.length}>
                      {nilaiKomersil}
                    </td>
                    <td className={"border text-center px-2"} rowSpan={dataItems?.length}>
                      {nilaiAmbangBatas}
                    </td>
                    <td className={"border text-center px-2"} rowSpan={dataItems?.length}>
                      {nilaiAkhir}
                    </td>
                    <td className={"border text-center px-2 text-nowrap"} rowSpan={dataItems?.length}>
                      <StatusBadgePassedFailed status={statusEvaluation} />
                    </td>
                  </>
                )}

                {(checkIsItemizeSistemGugur()) && (
                  <>
                    <td className={"border"}>
                      <input type={"checkbox"} className={"form-check-input"}
                             checked={item?.status === "PASSED"} /> Lolos
                    </td>
                    <td className={"border"}>
                      <input type={"checkbox"} className={"form-check-input"}
                             checked={item?.status !== "PASSED"} /> Tidak Lolos
                    </td>
                  </>
                )}

                {(checkIsItemizeSistemNilai()) && (
                  <>
                    <td className={"border text-center"}>
                      {item?.score_technical}
                    </td>
                    <td className={"border text-center"}>
                      {item?.score_commercial}
                    </td>
                    <td className={"border text-center"}>
                      <strong>{item?.score_technical + item?.score_commercial}</strong>
                    </td>
                    <td className={"border text-center"}>
                      <StatusBadgePassedFailed status={item?.status} />
                    </td>
                  </>
                )}

              </tr>
            );
          })}
          </tbody>
        </table>
      </div>
    </>
  );
};

const DetailPenawaran = ({ proposalTender, quotation, onBack, isOwner }: any) => {
  const [formIndex, setFormIndex] = useState(0);
  const [{ doSubmit: doSubmitPost, isLoading: isLoadingPost }] = useAxiosPost("");
  const [evaluationData, setEvaluationData] = useState(null as any);
  const [isDisabled, setIsDisabled] = useState(false);
  const intl = useIntl();
  const handleOnChangeEvaluasiKomersil = () => {
    // setFormIndex(formIndex + 1);
    const evaluateVendorURL = getEnv('tender') + `/evaluation/vendor/v2/${proposalTender?.uuid}/${quotation?.vendor?.uuid}`
    doSubmitPost({}, evaluateVendorURL, toast, () => {
    }).then((res: any) => {
      setFormIndex(formIndex + 1);
    }).catch((err: any) => {
      setFormIndex(formIndex + 1);
    })
  };
  const checkIsDisabled =  () => {
    const allowedStatus = ["Evaluasi Administrasi", "Evaluasi Teknikal", "Evaluasi Komersil"];
    setIsDisabled(!allowedStatus.includes(proposalTender?.status));
  };
  const onChangeEvaluationData = async (field: string, value: any) => {
    setEvaluationData({ ...evaluationData, [field]: value });
  };

  const handleOnSaveEvaluation = async () => {
    // region save evaluation administrasi
    if (evaluationData?.evaluation_administration) {
      // region note
      await doSubmitPost({
        note: evaluationData?.evaluation_administration?.note || "-",
        type: "Evaluasi Administrasi"
      }, getEnv("tender") + `/evaluation/notes/${quotation?.vendor?.uuid}`, undefined);
      // endregion

      //region attachment
      evaluationData?.evaluation_administration?.attachments?.forEach(async (attachment: any) => {
        if (attachment.current_id) return;
        const payload = {
          description: attachment?.media_type?.file_name,
          type: "Evaluasi Administrasi",
          media_type: attachment?.media_type,
          media_url: attachment?.media_url
        };
        await doSubmitPost(payload, getEnv("tender") + `/evaluation/attachment/${quotation?.vendor?.uuid}`, undefined);
      });
      //endregion
    }
    // endregion

    //region save evaluation teknis
    if (evaluationData?.evaluation_technical) {
      await doSubmitPost({
        note: evaluationData?.evaluation_technical?.note || "-",
        type: "Evaluasi Teknikal"
      }, getEnv("tender") + `/evaluation/notes/${quotation?.vendor?.uuid}`, undefined);
      //region attachment
      evaluationData?.evaluation_technical?.attachments?.forEach(async (attachment: any) => {
        if (attachment.current_id) return;
        const payload = {
          description: attachment?.media_type?.file_name,
          type: "Evaluasi Teknikal",
          media_type: attachment?.media_type,
          media_url: attachment?.media_url
        };
        await doSubmitPost(payload, getEnv("tender") + `/evaluation/attachment/${quotation?.vendor?.uuid}`, undefined);
      });
      //endregion
    }
    //endregion

    //region save evaluation komersil
    if (evaluationData?.evaluation_commercial) {
      await doSubmitPost({
        note: evaluationData?.evaluation_commercial?.note || "-",
        type: "Evaluasi Komersil"
      }, getEnv("tender") + `/evaluation/notes/${quotation?.vendor?.uuid}`, undefined);
      //region attachment
      evaluationData?.evaluation_commercial?.attachments?.forEach(async (attachment: any) => {
        if(attachment.current_id) return;
        const payload = {
          description: attachment?.media_type?.file_name,
          type: "Evaluasi Komersil",
          media_type: attachment?.media_type,
          media_url: attachment?.media_url
        }
        await doSubmitPost(payload, getEnv("tender") + `/evaluation/attachment/${quotation?.vendor?.uuid}`, undefined);
      });
      //endregion
    }
    //endregion

    //region delete attachment
    if (evaluationData?.evaluation_administration_deleted) {
      evaluationData?.evaluation_administration_deleted?.forEach(async (attachment: any) => {
        await doSubmitPost({
          _method: "DELETE"
        }, getEnv("tender") + `/evaluation/attachment/${quotation?.vendor?.uuid}/${attachment}`, undefined);
      });
    }
    if (evaluationData?.evaluation_technical_deleted) {
      evaluationData?.evaluation_technical_deleted?.forEach(async (attachment: any) => {
        await doSubmitPost({
          _method: "DELETE"
        }, getEnv("tender") + `/evaluation/attachment/${quotation?.vendor?.uuid}/${attachment}`, undefined);
      });
    }
    if (evaluationData?.evaluation_commercial_deleted) {
      evaluationData?.evaluation_commercial_deleted?.forEach(async (attachment: any) => {
        await doSubmitPost({
          _method: "DELETE"
        }, getEnv("tender") + `/evaluation/attachment/${quotation?.vendor?.uuid}/${attachment}`, undefined).then((res: any) => {});
      });
    }
    //endregion

    toast.success(intl.formatMessage({ id: "Berhasil menyimpan data" }));
  };

  useEffect(() => {
    checkIsDisabled();
  }, [proposalTender]);

  return (
    <>
      <div className={"row"}>
        <div className={"col-md-6 mb-5"}>
          <button className={"btn btn-sm btn-danger"} type={"button"} onClick={() => onBack()}>
            <i className={"fa fa-arrow-left"}></i> Kembali ke list
          </button>
        </div>
        <div className={"col-md-6"}>
        </div>
        <div className={"col-md-12"}>
          <InformasiRekanan proposalTender={proposalTender} quotation={quotation} />
          <hr />
          <EvaluasiAdministrasi
            proposalTender={proposalTender}
            quotation={quotation}
            onChangeEvaluationData={onChangeEvaluationData}
            isOwner={isOwner}
          />
          <hr />
          <EvaluasiTeknis
            proposalTender={proposalTender}
            quotation={quotation}
            onChange={() => handleOnChangeEvaluasiKomersil()}
            onChangeEvaluationData={onChangeEvaluationData}
            isOwner={isOwner}
          />

          <hr />
          {/*<ProsesEvaluasiKomersil*/}
          {/*  proposalTender={proposalTender}*/}
          {/*  quotation={quotation}*/}
          {/*  onChange={() => handleOnChangeEvaluasiKomersil()}*/}
          {/*  onChangeEvaluationData={onChangeEvaluationData}*/}
          {/*  isOwner={isOwner}*/}
          {/*/>*/}
          <EvaluasiKomersil
            proposalTender={proposalTender}
            quotation={quotation}
            onChange={() => handleOnChangeEvaluasiKomersil()}
            onChangeEvaluationData={onChangeEvaluationData}
            isOwner={isOwner}
          />


          <hr />
          <HasilAkhirEvaluasi formIndex={formIndex} proposalTender={proposalTender} quotation={quotation} />

          <hr />

          {/* <div className={"d-flex justify-content-end"}>
            <button
              type={"button"}
              disabled={isLoadingPost || isDisabled}
              className={"btn btn-info me-3"}
              onClick={handleOnSaveEvaluation}
            >
              {(isLoadingPost) ? (
                <Spinner animation="border" role="status" size={"sm"} />
              ) : (
                <i className={"fa fa-save"}></i>
              )}
              {intl.formatMessage({ id: "Simpan Evaluasi" })}
            </button>
          </div> */}
        </div>
      </div>
    </>
  );
};

const AdministrasiKomersilDuaTahapTab = ({ proposalTender, onQuotationChanged,isOwner }: any) => {
  const urlIndex = getEnv("tender") + `/evaluation/quotation/${proposalTender.uuid}?commercial=true`;
  const [selectedRows, setSelectedRows] = useState(null);
  const tableColumns = [
    {
      Header: "#",
      accessor: "line_no",
      width: 100,
      className: "text-center",
      Cell: (props: any) => {
        return (
          <>
            <a href={"#"} title={"Lihat Penawaran"} onClick={(e: any) => {
              e.preventDefault();
              setSelectedRows(props.row.original);
            }} className={"btn btn-primary btn-sm"}>
              <i className={"fa fa-eye"}></i>
            </a>
          </>
        );
      }
    },
    {
      Header: "Vendor ID", accessor: "vendor_id", width: 100, className: "text-start", Cell: (props: any) => {
        return (
          <>
            {props.row.original.vendor_id}
          </>
        );
      }
    },
    { Header: "Nama Vendor", accessor: "vendor.vendor.name", width: 300, className: "text-start" },
    {
      Header: "Status", accessor: "status", width: 150, className: "text-center", Cell: (props: any) => {
        return (
          <>
            <StatusBadgePassedFailed status={props.row.original.vendor.status_evaluation_commercial} />
          </>
        );

      }
    },
    { Header: "Quotation Terakhir", accessor: "updated_at", className: "text-center", width: 300 }

  ];

  useEffect(() => {
    onQuotationChanged(selectedRows);
  }, [selectedRows]);
  return (
    <>
      {selectedRows && (
        <DetailPenawaran
          proposalTender={proposalTender}
          quotation={selectedRows}
          onBack={() => {
            setSelectedRows(null);
          }}
          isOwner={isOwner}
        />
      )}
      {!selectedRows && (
        <TableXWrapper
          readonly={true}
          title={"Proposal Tender"}
          urlIndex={urlIndex}
          columns={tableColumns}
          isForceCreateButton={true}
          disableUrlChanged={true}
        />
      )}
      {/*{JSON.stringify(proposalTender)}*/}
    </>
  );
};

export default AdministrasiKomersilDuaTahapTab;