import {ButtonGroup, Col, Modal, Row} from 'react-bootstrap'
import TableXWrapper from '../../../../../../components/shared/TableXWrapper'
import React, {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import axios from 'axios'
import SwalX from '../../../../../../components/shared/SwalX'
import {useIntl} from 'react-intl'
import {pdfjs} from 'react-pdf'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import {toastError} from '../../../../../../../_metronic/helpers'
import {getEnv} from '../../../../../../../helpers/procurex'
import FormAttachment from '../../../../../../components/shared/form/FormAttachment'
import {useProposalTender} from '../../core/_proposalTenderProvider'
import {Placeholder} from 'rsuite'

const API_URL = process.env.REACT_APP_API_URL

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
  standardFontDataUrl: 'standard_fonts/',
}

interface AttachmentProps {
  onAdded?: () => void
  onDeleted?: () => void
  headerData?: any
  onRefresh?: any
  hideForm?: boolean
  hideTitle?: boolean
  isOwner?: boolean
}

const DokumenPengadaanPage = ({
  onAdded,
  onDeleted,
  headerData,
  onRefresh,
  hideForm,
  hideTitle,
  isOwner
}: AttachmentProps) => {
  const intl = useIntl()
  const [itemId, setItemId] = useState(0)
  const [mediaTypeName, setMediaTypeName] = useState('')
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false)
  const [isDisabledForm, setIsDisabledForm] = useState(true)
  let [key, setKey] = useState(0)
  const [numPages, setNumPages] = useState<any>(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [previewModal, setPreviewModal] = useState(false)
  const [previewExt, setPreviewExt] = useState({value: '', ext: ''})
  const [initialWidth, setInitialWidth] = useState<any>(null)
  const [indexForm, setFormIndex] = useState(1)
  const urlIndex = getEnv('tender') + '/tender-attachment'
  const {proposalTender,refetchProposalTender} = useProposalTender();
  const tableColumns = [
    {
      Header: intl.formatMessage({id: 'Aksi'}),
      accessor: 'media_url',
      className: 'text-center',
      width: 150,
      Cell: (props: any) => (
        <>
            <ButtonGroup size={'sm'} aria-label='Action'>
              {isOwner && (
                <button
                  type='button'
                  className='btn btn-sm btn-secondary'
                  disabled={isDisabledForm}
                  onClick={() => {
                    setItemId(props.cell.row.original.uuid)
                    setIsConfirmDeleteOpen(true)
                  }}
                >
                  <i className={'fa fa-trash'} />
                </button>
                )
              }
              <button
                type='button'
                className='btn btn-sm btn-secondary'
                onClick={() => {
                  preview(props.cell.row.values.media_url)
                }}
              >
                <i className={'fa fa-eye'} />
              </button>
          </ButtonGroup>
        </>
      ),
    },
    {
      Header: 'Share Vendor',
      accessor: 'is_skv',
      width: 50,
      orderable: false,
      searchable: false,
      className: 'text-center',
      Cell: (props: any) => {
        const [isLoading, setIsLoading] = useState(false)
        const [isChecked, setIsChecked] = useState(props.row.original.is_skv)
        const handleCheckSKP = async (row: any, state: boolean) => {
          let data = row
          data.skv = state
          data.proposal_tender_id = proposalTender?.uuid
          setIsLoading(true)
          try {
            await axios.put(
              getEnv('tender') + '/tender-attachment/' + props.row.original.uuid,
              data
            )
            setIsChecked(!isChecked)
            toast.success('Berhasil menyimpan data')
            refetchProposalTender()
          } catch (e) {
            console.error(e)
            toast.error('Gagal menyimpan data')
          } finally {
            setIsLoading(false)
            onRefresh && onRefresh()
          }
        }

        const isSKPEnabled = () => {
          if(!isOwner) return false
          if(props?.row?.original?.media_type.name === 'PR_TENDER_HPS') return false;

          const allowedStatus = [
            'Draft',
            'Pra Qualification',
            'DUR',
            'Published',
            'Aanwijzing',
            'Registrasi',
            'Penawaran',
            'Penawaran Teknikal',
            'Penawaran Komersil'
          ];
          const allowedStatusDetail = [
            'Draft',
            'Rejected',
            'Submit for Approval DUR',
            'Published',
            'Quotation',
            'Evaluation',
            'Registration',
            'Submit for Approval Cancel'
          ]

          // return allowedStatus.includes(proposalTender.status);
          return allowedStatus.includes(proposalTender?.status) && allowedStatusDetail.includes(proposalTender?.status_detail);
        }

        const onClicked = (e: any) => {
          if (props?.row?.original?.media_type.name === 'PR_TENDER_HPS') {
            toast.error('Tidak dapat Share HPS')
            e.preventDefault()
          }
        }

        return (
          <div className={'d-flex justify-content-center'}>
            <div className='form-check form-check-custom'>
              {isLoading && <span className='spinner-border spinner-border-sm me-1' />}
              {!isLoading && (
                <input
                  type='checkbox'
                  className='form-check-input'
                  id=''
                  defaultChecked={props.row.original.is_skp}
                  checked={isChecked}
                  disabled={!isSKPEnabled()}
                  onClick={(e: any) => onClicked(e)}
                  onChange={(e) => handleCheckSKP(props.row.original, e.target.checked)}
                />
              )}
            </div>
          </div>
        )
      },
    },
    {
      Header: intl.formatMessage({id: 'Nomor PR'}),
      accessor: 'pr_number',
      name: 'pr_number',
      width: 150,
      className: 'text-center',
    },
    {
      Header: intl.formatMessage({id: 'Jenis Lampiran'}),
      accessor: 'media_type.name',
      name: 'media_type',
      width: 250,
    },
    {Header: intl.formatMessage({id: 'Deskripsi'}), accessor: 'description', width: 400},

  ]

  useEffect(() => {
    if(!proposalTender) return ;
    setKey(key+1)
    const allowedStatus = [
      'Draft',
      'Pra Qualification',
      'DUR',
      'Published',
      'Aanwijzing',
      'Registrasi',
      'Penawaran',
      'Penawaran Teknikal',
      'Penawaran Komersil',
    ]
    const allowedStatusDetail = [
      'Draft',
      'Rejected',
      'Submit for Approval DUR',
      'Published',
      'Quotation',
      'Evaluation',
      'Registration',
      'Submit for Approval Cancel',
    ]
    if (
      allowedStatus.includes(proposalTender?.status) &&
      allowedStatusDetail.includes(proposalTender?.status_detail)
    ) {
      setIsDisabledForm(false)
    } else {
      setIsDisabledForm(true)
    }
  }, [proposalTender])
  const deleteItem = async () => {
    try {
      setIsLoadingDelete(true)
      const response: any = await axios.delete(`${urlIndex}/${itemId}`)

      if (response.status === 200) {
        setKey(key + 1)
        setIsConfirmDeleteOpen(false)
        toast.success(response?.data?.meta?.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
        onDeleted !== undefined && onDeleted()
        onRefresh && onRefresh()
      }
    } catch (error: any) {
      console.error(error)

      toastError(error.response?.data?.meta?.message || 'Terjadi kesalahan')
    } finally {
      setIsLoadingDelete(false)
    }
  }

  const preview = (value: string) => {
    window.open(value, '_blank')
  }

  const onAfterUpload = async (res: any) => {
    const payload = {
      proposal_tender_id: proposalTender?.uuid,
      type: proposalTender?.source,
      description: res?.description,
      media_type: res?.type,
      media_url: res?.url,
      skv: false,
    }

    let response = await axios.post(`${getEnv('tender')}/tender-attachment`, payload)

    if (response.status === 201 || response.status === 200) {
      toast.success(response?.status + ': ' + response?.data.meta.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
      setKey(key + 1)
      setFormIndex(indexForm + 1)
      onAdded !== undefined && onAdded()
      onRefresh && onRefresh()
      return true
    }
  }

  if(!proposalTender){
    return <Placeholder.Grid rows={10} columns={8} />
  }

  return (
    <>
      <SwalX
        title={intl.formatMessage({id: 'DATATABLE.CONFIRM_DELETE'})}
        message={intl.formatMessage({id: 'DATATABLE.MESSAGE_DELETE'})}
        show={isConfirmDeleteOpen}
        loading={isLoadingDelete}
        onHide={() => setIsConfirmDeleteOpen(false)}
        onSubmit={() => deleteItem()}
      />
      <Modal
        key={'modal-' + key}
        show={previewModal}
        onHide={() => {
          setPreviewModal(false)
          setPageNumber(1)
          setNumPages(null)
        }}
        size={'lg'}
      >
        <Modal.Header closeButton={true}>
          <h4>Preview Attachment</h4>
        </Modal.Header>
        <Modal.Body className='overflow-visible'>
          <object width='100%' height='700' data={previewExt.value} type='application/pdf'>
            {' '}
          </object>
          {(previewExt.ext === 'jpg' || previewExt.ext === 'png' || previewExt.ext === 'jpeg') && (
            <>
              <img className='w-100' src={previewExt.value} alt='image' />
            </>
          )}
        </Modal.Body>
      </Modal>

      <div key={indexForm}>
        {hideTitle !== true && (
          <div className={"d-flex justify-content-between"}>
            <div>
              <h3>{intl.formatMessage({ id: "Dokumen Pengadaan" })}</h3>
            </div>
          </div>
        )}
        {hideForm !== true && (
          <>
            <hr />
            <FormAttachment
              media_type_filter={"TENDER_ATTACHMENT"}
              isDisabled={isDisabledForm}
              afterSubmit={onAfterUpload}
              isOwner={isOwner}
            />
          </>
        )}


        <div className="card border">
          <div className="card-body">
            <Row>
              <Col>
                <TableXWrapper
                  readonly
                  key={'table-' + key}
                  title={'Test'}
                  urlIndex={urlIndex}
                  columns={tableColumns}
                  defaultFilter={`filter[proposal_tender_id]=${proposalTender?.id}`}
                  disableUrlChanged={true}
                  disablePaddingCard={true}
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  )
}

export default DokumenPengadaanPage
