import { useRef } from 'react'
import { Formik, Form, FormikProps } from 'formik'
import { Col, Row } from 'react-bootstrap'
import * as Yup from 'yup'
import { toastError, toastSuccess } from '../../../../../_metronic/helpers'
import { useMutation } from 'react-query'
import { runProductionPlan, storeImport } from './_requests'

interface Props {
  handleSuccess: () => void
}

const ImportExcel = ({
  handleSuccess
}: Props) => {

  const { mutate: submitImport, isLoading: isLoadingSubmitImport } = useMutation({
    mutationFn: (values: any) => storeImport(values),
    onSuccess: (response: any) => {
      submitRunProductionPlan()
    },
    onError: (error: any) => {
      console.error(error)
      toastError(error.response.data?.meta?.message || 'Terjadi kesalahan')
    },
  })


  const { mutate: submitRunProductionPlan, isLoading: isLoadingRunProductionPlan } = useMutation({
    mutationFn: () => runProductionPlan(),
    onSuccess: (response: any) => {
      handleSuccess && handleSuccess()
      toastSuccess(response.data.meta.message)
    },
    onError: (error: any) => {
      console.error(error)
      toastError(error.response.data?.meta?.message || 'Terjadi kesalahan')
    },
  })

  const fileRef = useRef<HTMLInputElement | null>()
  const formikRef = useRef<FormikProps<any> | null>()

  const resetInputFile = () => {
    if (fileRef.current) {
      fileRef.current.value = ''
    }
  }
  return (
    <>
          <Formik
            innerRef={formikRef}
            initialValues={{
              file: ''
            }}
            validationSchema={Yup.object().shape({
              file: Yup.mixed().required('File is required')
            })}
            onSubmit={values => submitImport(values)}
            onReset={() => resetInputFile()}
            enableReinitialize
          >
            {({ setFieldValue, errors, touched }: any) => (
              <>
                <Form>
                  <Row>
                    <Col lg={12}>
                      <div className='mb-7'>
                        <label className='required form-label'>
                          <span>File</span>
                        </label>
                        <input
                          type="file"
                          name="file"
                          className="form-control"
                          ref={fileRef}
                          onChange={event => setFieldValue('file', event.target.files[0])}
                        />
                        {(errors.file && touched.file) && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{errors.file}</div>
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <div className='d-flex justify-content-end mt-5'>
                    <button disabled={isLoadingSubmitImport || isLoadingRunProductionPlan} type='submit' className='btn btn-sm btn-primary'>
                      {(isLoadingSubmitImport || isLoadingRunProductionPlan) && <span className='spinner-border spinner-border-sm me-1' />}
                      Import Data
                    </button>
                  </div>
                </Form>
              </>
            )}
          </Formik>
    </>
  )
}

export default ImportExcel
