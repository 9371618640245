import axios, {AxiosResponse} from 'axios'
import {
  create,
  createOptions,
  DocumentFormat,
  DocumentFormattedEventArgs,
  RichEdit,
} from 'devexpress-richedit'
import {Component, createContext, ReactNode, useCallback, useMemo, useState} from 'react'
import {createPortal} from 'react-dom'
import {useQueries} from 'react-query'
import {UseQueryResult} from 'react-query/types/react/types'
import {toastError} from '../../../_metronic/helpers'

type Coordinate = {
  lowerLeftX: number
  lowerLeftY: number
  upperRightX: number
  upperRightY: number
  page: number
}

export type SignCoordinateResult = {
  isLoading: boolean
  coordinateInPixels: Coordinate | null
  coordinateInPoints: Coordinate | null
  error: string | undefined
}

type SignCoordinatesContextValue = {
  isLoading: boolean
  results: Record<string, SignCoordinateResult>
}

export const SignCoordinatesContext =
  createContext<SignCoordinatesContextValue>({
    isLoading: false,
    results: {},
  })

interface DocumentInfo {
  filename: string
  base64: string
}

export const getDocument = async (sourceUrl: string): Promise<DocumentInfo | undefined> => {
  const response = await axios.get(sourceUrl, {responseType: 'blob'})
  const type = response.headers['content-type']

  if (type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
    toastError('Invalid file type')
    return undefined
  }

  const filename = getFilenameFromResponse(response)
  const file = new Blob([response.data], {type})

  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = () => {
      resolve({
        filename: filename,
        base64: reader.result as string,
      })
    }
  })
}

const getFilenameFromResponse = (response: AxiosResponse) => {
  let headerLine = response.headers['content-disposition']

  if (!headerLine) {
    return null
  }

  let startFileNameIndex = headerLine?.indexOf('"') + 1
  let endFileNameIndex = headerLine?.lastIndexOf('"')

  return headerLine?.substring(startFileNameIndex, endFileNameIndex)
}

class CustomDevExpressRichEdit extends Component<{
  id: string
  base64Document: string
  onDocumentFormatted?: (richEdit: RichEdit, event: DocumentFormattedEventArgs) => void
  visible?: boolean
}> {
  rich: RichEdit | null = null

  componentDidMount() {
    if (this.rich || !this.props.base64Document) return

    // the createOptions() method creates an object that contains RichEdit options initialized with default values
    const options = createOptions()

    options.readOnly = true
    options.events.documentFormatted = this.props.onDocumentFormatted

    this.rich = create(document.getElementById(this.props.id), options)

    this.rich.openDocument(this.props.base64Document, 'DocumentName', DocumentFormat.OpenXml)
  }

  render() {
    // hide element without display none or visibility hidden, but make element is 0 height
    const style = this.props.visible
      ? {}
      : {
          height: 0,
          overflow: 'hidden',
        }

    return (
      <div style={style}>
        <div id={this.props.id}></div>
      </div>
    )
  }
}

type Result = {
  error: string | undefined
  coordinateInPixels: Coordinate | undefined
  coordinateInPoints: Coordinate | undefined
}

const findCoordinateByBookmarkName = (
  elementId: string,
  bookmarkName: string,
  richEdit: RichEdit
): Result => {
  if (!richEdit) {
    return {error: undefined, coordinateInPixels: undefined, coordinateInPoints: undefined}
  }

  const bookmark = richEdit.document.bookmarks.find(bookmarkName)[0]

  if (!bookmark) {
    return {
      error: 'Bookmark not found',
      coordinateInPixels: undefined,
      coordinateInPoints: undefined,
    }
  }

  bookmark.goTo()

  const selection = document
    .getElementById(elementId)
    .querySelector('.dxreResBox.dxreResBoxAnchored')

  if (!selection) {
    return {
      error: 'Bookmark selection not found',
      coordinateInPixels: undefined,
      coordinateInPoints: undefined,
    }
  }

  const pages = document.getElementById(elementId).querySelectorAll('.dxrePage')
  const coordinateInPixels = {} as Coordinate
  let page = null

  for (let i = 0; i < pages.length; i++) {
    page = pages[i]

    if (page.contains(selection)) {
      coordinateInPixels.page = i + 1
      break
    }
  }

  if (!page) {
    return {error: 'Image not found', coordinateInPixels: undefined, coordinateInPoints: undefined}
  }

  const pageRect = page.getBoundingClientRect()
  const selectionRect = selection.getBoundingClientRect()

  coordinateInPixels.lowerLeftX = selectionRect.left - pageRect.left
  coordinateInPixels.lowerLeftY =
    pageRect.height - (selectionRect.top + selectionRect.height - pageRect.top)
  coordinateInPixels.upperRightX = selectionRect.left + selectionRect.width - pageRect.left
  coordinateInPixels.upperRightY = pageRect.height - (selectionRect.top - pageRect.top)

  const coordinateInPoints: Coordinate = {
    page: coordinateInPixels.page,
    lowerLeftX: richEdit.unitConverter.pixelsToPoints(selectionRect.left - pageRect.left),
    lowerLeftY: richEdit.unitConverter.pixelsToPoints(
      pageRect.height - (selectionRect.top + selectionRect.height - pageRect.top)
    ),
    upperRightX: richEdit.unitConverter.pixelsToPoints(
      selectionRect.left + selectionRect.width - pageRect.left
    ),
    upperRightY: richEdit.unitConverter.pixelsToPoints(
      pageRect.height - (selectionRect.top - pageRect.top)
    ),
  }

  return {error: undefined, coordinateInPixels, coordinateInPoints}
}

export interface SignCoordinateDocumentsArg {
  docxDocumentUrl: string | (() => Promise<string>)
  bookmarkName: string
}

export type SignCoordinateDocumentsArgs = Record<string, SignCoordinateDocumentsArg>

export interface SignCoordinatesProviderProps {
  documents: SignCoordinateDocumentsArgs
  children: ReactNode | ((value: SignCoordinatesContextValue) => ReactNode)
  isEditorVisible?: boolean
}

export const SignCoordinatesProvider = ({
  documents,
  children,
  isEditorVisible = false
}: SignCoordinatesProviderProps) => {
  const [results, setResults] = useState<SignCoordinatesContextValue['results']>({})

  const documentKeys = useMemo(() => Object.keys(documents ?? {}), [documents])
  const getDocumentQueries: UseQueryResult<DocumentInfo>[] = useQueries(
    documentKeys.map((key) => ({
      queryKey: ['dev-express-sign-coordinate', key, documents[key].docxDocumentUrl],
      queryFn: async () => {
        const docxDocumentUrl = documents[key].docxDocumentUrl
        const sourceUrl =
          typeof docxDocumentUrl === 'function' ? await docxDocumentUrl() : docxDocumentUrl

        return sourceUrl ? await getDocument(sourceUrl) : undefined
      },
      staleTime: 0,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }))
  )

  const updateCoordinate = useCallback(
    (elementId: string, key: string, richEdit: RichEdit) => {
      const result = findCoordinateByBookmarkName(elementId, documents[key].bookmarkName, richEdit)
      console.log('=>(SignCoordinatesProvider.tsx:246) result\n', result)

      setResults((prevResults) => ({
        ...prevResults,
        [key]: {
          isLoading: false,
          ...result,
        },
      }))
    },
    [documents]
  )

  const isGetDocumentQueriesLoading = getDocumentQueries.some((query) => query.isLoading)
  const isLoading =
    isGetDocumentQueriesLoading ||
    Object.keys(results).length !== documentKeys.length ||
    Object.keys(results).some((key) => results[key].isLoading)

  const validBase64Documents = Object.fromEntries(
    documentKeys
      .map((key, index) => [key, getDocumentQueries[index].data?.base64])
      .filter(([_, base64]) => !!base64)
  )

  const contextValue = useMemo(
    () => ({
      isLoading: isLoading,
      results: results,
    }),
    [isLoading, results]
  )

  return (
    <SignCoordinatesContext.Provider value={contextValue}>
      {typeof children === 'function' ? children(contextValue) : children}
      {!isGetDocumentQueriesLoading &&
        documentKeys.map((key) =>
          createPortal(
            <CustomDevExpressRichEdit
              key={key}
              id={`richEdit-${key}`}
              base64Document={validBase64Documents[key]}
              onDocumentFormatted={(richEdit) => updateCoordinate(`richEdit-${key}`, key, richEdit)}
              visible={isEditorVisible}
            />,
            document.body
          )
        )}
    </SignCoordinatesContext.Provider>
  )
}
