import { Field, Form, Formik } from 'formik'
import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { Link, useNavigate, useParams } from 'react-router-dom'
import SelectX from '../../../../../components/shared/form/SelectX'
import { ApiUrl } from '../../../../../config/Api'
import { getEnv } from "../../../../../../helpers/procurex";
import DatepickerX2 from "../../../../../components/shared/form/DatepickerX2";
import SwalX from '../../../../../components/shared/SwalX'
import Swal from "sweetalert2";
import { useAuth } from "../../../../../../app/modules/auth";
import { InputNumber } from "rsuite";
import axios from 'axios'
import { useIntl } from "react-intl";

const CreateProduction = () => {
    const { currentUser } = useAuth();

    const initialValues = {
        material_number: '',
        kode_plant: '',
        company_code: '',
        production: '0',
        uom: '',
        start_validity_date: '',
        end_validity_date: ''
    }

    const formSchemaValidation = Yup.object().shape({
        material_number: Yup.string().required('Material Number is required'),
        company_code: Yup.string().required('Company Code is required'),
        production: Yup.number().required('Total Production is required').min(0, 'Total Production must be greater than or equal to 0'),
        kode_plant: Yup.string().required('Plant is required'),
        start_validity_date: Yup.date().required('Start validity date is required'),
        end_validity_date: Yup.date().required('End validity date is required'),
    });

    const intl = useIntl();
    const refFormik = React.useRef<any>();
    const [itemsMasterMaterial, setItemsMasterMaterial] = useState<any>([])
    const [itemsMasterCompany, setItemsCompanyStock] = useState<any>([])
    const [ItemsPlant, setItemsPlant] = useState<any>([])
    const [itemLoading, setItemLoading] = useState(false)

    const loadItem = () => {
        axios
            .get(getEnv("stockview") + `/master-material`)
            .then((res) => {
                setItemsMasterMaterial(res.data.data)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setItemLoading(false)
            })
        axios
            .get(getEnv("stockview") + `/company-stock?perPage=50&page=1`)
            .then((res) => {
                setItemsCompanyStock(res.data.data)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setItemLoading(false)
            })
        axios
            .get(getEnv("stockview") + `/material-plant-all?filter[active_app]=X&company_code=${company_code_user_first}`)
            .then((res) => {
                setItemsPlant(res.data.data)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setItemLoading(false)
            })
    }

    useEffect(() => {
        loadItem();
    }, [])

    // const [formData, setFormData] = React.useState([]);
    const [formData, setFormData] = useState({
        master: { code: "STOCKVIEW_PRODUCTION" },
        details: []
    });

    // const [initialValues, setInitialvalues] = useState<any>(defaultValue)
    const [loading, setLoading] = useState(true)
    const [isLoading, setIsLoading] = useState(false);
    const [key, setKey] = useState(0)

    const [totalProduction, setTotalProduction] = useState(0);

    const handleOnTotalChange = (score: number) => {
        setTotalProduction(score)
    }

    const [selectedMaterial, setSelectedMaterial] = useState(null);
    const [selectedCompanyCode, setSelectedCompanyCode] = useState(null);
    const [selectedPlant, setSelectedPlant] = useState(null);

    const handleMaterialSelect = (selectedValue: any) => {
        setSelectedMaterial(selectedValue);
    };

    const handleCompanySelect = (selectedValue: any) => {
        setSelectedCompanyCode(selectedValue);
    };

    const handlePlantSelect = (selectedValue: any) => {
        setSelectedPlant(selectedValue);
    };

    const TableRow = ({ no, material_number, company_code, uom, onCheck, start_date, plant, end_date, production, onDelete, ...props }: any) => {
        return (
            <tr>
                <td className={"border text-center"}>{no}</td>
                <td className={"border"}>{material_number}</td>
                <td className={"border"}>{company_code}</td>
                <td className={"border"}>{plant}</td>
                <td className={"border"}>{production}</td>
                <td className={"border"}>{uom}</td>
                <td className={"border"}>{start_date}</td>
                <td className={"border"}>{end_date}</td>
                <td className={"border text-center"}>
                    <button className={"btn btn-sm btn-secondary"} onClick={() => onDelete()}>
                        <i className={"fa fa-trash text-danger"}></i>
                    </button>
                </td>
            </tr>
        );
    };

    const handleBackClick = () => {
        if (formData.details.length !== 0) {
            handleBack();
        } else {
            navigate('/stockview/transaction/production');
        }
    };

    // const [dataUpdate, dataUpdateForm] = useState<Date | null>(null);

    let [isLoadingDelete, setIsLoadingDelete] = useState(false)
    let [isConfirmSubmitOpen, setIsConfirmSaveOpen] = useState(false)
    const [formindex, setFormIndex] = useState(0)

    const navigate = useNavigate();

    const handleAddRow = async (values: any) => {
        const newData = {
            data: {
                material_number: values.material_number,
                company_code: values.company_code,
                plant: values.kode_plant,
                production: values.production,
                uom: values.material_number ? 'TON' : '',
                start_date: values.start_validity_date,
                end_date: values.end_validity_date
            }
        };
        // setFormData([...formData, newData]);
        setFormData(prevFormData => ({
            ...prevFormData,
            details: [...prevFormData.details, newData]
        }));
        setFormIndex(formindex + 1);
        // @ts-ignore

    };

    const handleDeleteRow = async (index: number) => {
        // const newData = formData.filter((item: any, i: number) => i !== index);
        // @ts-ignore
        // setFormData(newData);
        const newDetails = formData.details.filter((item: any, i: number) => i !== index);

        setFormData(prevFormData => ({
            ...prevFormData,
            details: newDetails
        }));
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            console.log('a', formData);

            // const dataListResponse = await axios.get(`${getEnv("stockview")}/production-list?company_code=${company_code_user}&jenis_api=${jenis_api}`);
            // if (dataListResponse.status !== 200) {
            //     throw new Error('Failed to fetch data list');
            // }
            // const dataList = dataListResponse.data.data;

            // const requests = [];

            // for (const data of formData) {
            //     const trimmedMaterialNumber = data.material_number.trim();
            //     const trimmedData = { ...data, material_number: trimmedMaterialNumber };
            //     const existingRecords = dataList.filter(item =>
            //         item.material_number === trimmedData.material_number &&
            //         item.company_code === trimmedData.company_code &&
            //         item.plant === trimmedData.plant &&
            //         item.start_validity_date === trimmedData.start_validity_date
            //     );

            //     if (existingRecords.length > 0) {
            //         const [firstRecord, ...remainingRecords] = existingRecords;

            //         // Update the first record
            //         const updateData = {
            //             ...trimmedData,
            //             id: firstRecord.id
            //         };

            //         requests.push(axios.put(`${getEnv("stockview")}/production-list/${firstRecord.id}`, updateData));

            //         // Delete the remaining records
            //         for (const record of remainingRecords) {
            //             requests.push(axios.delete(`${getEnv("stockview")}/production-list/${record.id}`));
            //         }
            //     } else {
            //         requests.push(axios.post(`${getEnv("stockview")}/production-list`, formData));
            //     }
            // }

            // const responses = await Promise.all(requests);

            // responses.forEach(response => {
            //     if (response.status === 200 || response.status === 201) {
            //         toast.success(response?.status + ': ' + response?.data.meta.message, {
            //             position: 'top-right',
            //             autoClose: 5000,
            //             hideProgressBar: false,
            //             closeOnClick: true,
            //             pauseOnHover: true,
            //             draggable: true,
            //             progress: undefined,
            //             theme: 'light',
            //         });
            //     }
            // });

            const response = await axios.post(getEnv("stockview") + `/production-list`, formData)

            if (response.status === 200 || response.status === 201) {
                toast.success(response?.status + ': ' + response?.data.meta.message, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                })
            }

        } catch (error) {
            console.error('Error processing records:', error);
            toast.error(error?.data?.meta?.message || 'Terjadi kesalahan', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        } finally {
            setLoading(false);
        }
    };

    // const handleSubmit = async () => {
    //     setLoading(true)
    //     try {
    //         const data = {
    //             ...formData
    //         };

    //         const response = await axios.post(getEnv("stockview") + `/production-list`, data)

    //         if (response.status === 200 || response.status === 201) {
    //             toast.success(response?.status + ': ' + response?.data.meta.message, {
    //                 position: 'top-right',
    //                 autoClose: 5000,
    //                 hideProgressBar: false,
    //                 closeOnClick: true,
    //                 pauseOnHover: true,
    //                 draggable: true,
    //                 progress: undefined,
    //                 theme: 'light',
    //             })
    //         }
    //         setFormIndex(formindex + 1);
    //     } catch (error: any) {
    //         console.error(error)
    //         toast.error(error?.data?.meta?.message || 'Terjadi kesalahan', {
    //             position: 'top-right',
    //             autoClose: 5000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //             progress: undefined,
    //             theme: 'light',
    //         })
    //     } finally {
    //         setLoading(false)
    //     }
    // }

    const handleBack = async () => {
        Swal.fire({
            title: `Data Belum Tersimpan`,
            html: `Apakah Anda Yakin Ingin Keluar ?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: `Ya, Keluar!`,
            confirmButtonColor: "#999999",
            cancelButtonColor: "#258141",
            cancelButtonText: `Cancel`,
        }).then((result) => {
            if (result.isConfirmed) {
                navigate('/stockview/transaction/production')
            }
        })
    }

    let company_code_user = currentUser.company_code;
    let company_code_user_first = currentUser.company_code[0];
    let jenis_api = "list";
    let role_user = currentUser.has_roles;

    return (
        <>
            <Formik
                key={formindex}
                innerRef={refFormik}
                initialValues={initialValues}
                onSubmit={handleAddRow}
                validationSchema={formSchemaValidation}
                enableReinitialize
                validateOnChange={true}
            >
                {({ errors, touched, values, setFieldValue }: any) => (
                    <Form>
                        <div className='d-flex justify-content-between mb-6'>
                            <div>
                                <button className='btn btn-secondary btn-sm fw-bold' onClick={handleBackClick}>
                                    Back To list
                                </button>
                            </div>
                            <div className='d-flex gap-4'>
                                <button className={"btn btn-primary btn-sm"} disabled={isLoading}
                                    onClick={() => handleSubmit()}>
                                    {isLoading && <i className={"fa fa-spinner fa-spin"}></i>}
                                    {!isLoading && <i className={"fa fa-save"}></i>}
                                    {intl.formatMessage({ id: "Simpan" })}
                                </button>
                            </div>
                        </div>

                        <div className='card my-5'>
                            <div className='card-header d-flex align-items-center'>
                                <b>FORM ADD PRODUCTION</b>
                            </div>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='required form-label'>
                                                Material Number
                                            </label>
                                            <SelectX
                                                onChange={(e: any) => setFieldValue('material_number', e)}
                                                options={itemsMasterMaterial.map((item: any) => ({
                                                    label: `${item.material_number} - ${item.material_desc}`,
                                                    value: item.material_number
                                                }))}
                                            />
                                            {(errors.material_number && touched.material_number) && (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">{errors.material_number}</div>
                                                </div>
                                            )}
                                        </div>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label required'>
                                                Plant
                                            </label>
                                            <SelectX
                                                onChange={(e: any) => setFieldValue('kode_plant', e)}
                                                options={ItemsPlant.map((item: any) => ({
                                                    label: `${item.kode_plant} - ${item.nama_plant}`,
                                                    value: item.kode_plant
                                                }))}
                                            />
                                            {(errors.kode_plant && touched.kode_plant) && (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">{errors.kode_plant}</div>
                                                </div>
                                            )}
                                        </div>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='required form-label'>
                                                Company Code
                                            </label>
                                            <SelectX
                                                onChange={(e: any) => setFieldValue('company_code', e)}
                                                options={
                                                    itemsMasterCompany
                                                        .filter((item: any) => {
                                                            if (company_code_user === "A000") {
                                                                return item.active_app === "X" && item.company_code !== "A000";
                                                            } else {
                                                                return item.active_app === "X" && item.company_code !== "A000" && item.company_code === company_code_user;
                                                            }
                                                        })
                                                        .map((item: any) => ({
                                                            label: item.company_code,
                                                            value: item.company_code
                                                        }))
                                                }
                                            />
                                            {(errors.company_code && touched.company_code) && (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">{errors.company_code}</div>
                                                </div>
                                            )}
                                        </div>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='required form-label'>
                                                Production
                                            </label>
                                            <InputNumber
                                                defaultValue={0}
                                                min={0}
                                                name="production"
                                                className={"w-100 text-end"}
                                                onChange={(e: any) => setFieldValue('production', e)}
                                            />
                                            {(errors.production && touched.production) && (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">{errors.production}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label'>
                                                Uom
                                            </label>
                                            <Field className='form-control' name="uom" value={values.material_number ? 'TON' : ''} disabled />
                                            {/* <MetronicErrorMessage name='sap_no' /> */}
                                        </div>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='required form-label'>
                                                Start Validity Date
                                            </label>
                                            <DatepickerX2
                                                onChange={(date: any) => setFieldValue("start_validity_date", date)}
                                                size={"lg"}
                                                value={values.start_validity_date}
                                                format={"dd-MM-yyyy"}
                                            />
                                            {(errors.start_validity_date && touched.start_validity_date) && (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">{errors.start_validity_date}</div>
                                                </div>
                                            )}
                                        </div>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='required form-label'>
                                                End Validity Date
                                            </label>
                                            <DatepickerX2
                                                onChange={(date: any) => setFieldValue("end_validity_date", date)}
                                                size={"lg"}
                                                value={values.end_validity_date}
                                                format={"dd-MM-yyyy"}
                                            />
                                            {(errors.end_validity_date && touched.end_validity_date) && (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">{errors.end_validity_date}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-end gap-4'>
                                        <button type={"submit"} className={"btn btn-primary btn-sm fw-bold"}>
                                            {isLoading && <i className={"fa fa-spinner fa-spin"}></i>}
                                            {!isLoading && <i className={"fa fa-plus"}></i>}
                                            {intl.formatMessage({ id: "Tambah" })}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"col-md-12 mt-5"}>
                            <table className={"table table-bordered"}>
                                <thead>
                                    <tr>
                                        <th className={"border text-center"}><strong>No</strong></th>
                                        <th className={"border text-center"}><strong>Material Number</strong>
                                        </th>
                                        <th className={"border text-center"}><strong>Company Code</strong>
                                        </th>
                                        <th className={"border text-center"}><strong>Plant</strong>
                                        </th>
                                        <th className={"border text-center"}><strong>Production</strong></th>
                                        <th className={"border text-center"}><strong>Uom</strong></th>
                                        <th className={"border text-center"}><strong>Start Validity Date</strong></th>
                                        <th className={"border text-center"}><strong>End Validity Date</strong></th>
                                        <th className={"border text-center"}><strong>Aksi</strong></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {formData.details.length === 0 && (
                                        <tr>
                                            <td colSpan={8} className={"text-center"}>
                                                <i>Tidak ada data</i>
                                            </td>
                                        </tr>
                                    )}
                                    {formData.details.map((item: any, index: number) => {
                                        return (
                                            <TableRow
                                                key={"adm-" + index}
                                                no={index + 1}
                                                material_number={item.data.material_number}
                                                company_code={item.data.company_code}
                                                plant={item.data.plant}
                                                production={item.data.production}
                                                uom={item.data.uom}
                                                start_date={item.data.start_date}
                                                end_date={item.data.end_date}
                                                onDelete={() => handleDeleteRow(index)}
                                            />
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export { CreateProduction }
