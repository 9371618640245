import React, { useEffect, useState } from "react";
import GambaranUmumDuaSampulTab from "./GambaranUmumDuaSampulTab";
import {AdministrasiTeknisDuaSampulTab} from "./AdministrasiTeknisDuaSampulTab";
import { Nav } from "rsuite";
import AdministrasiKomersilDuaSampulTab from "./AdministrasiKomersilDuaSampulTab";
import {BeritaAcaraTab} from "../BeritaAcaraTab";
import { ResumeEvaluasiTab } from "../ResumeEvaluasiTab";
import { useIntl } from "react-intl";
import BottomToolbarWrapper from "../../../../../../../components/shared/BottomToolbarWrapper";
import { Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import { getEnv } from "../../../../../../../../helpers/procurex";
import { toast } from "react-toastify";
import axios from "axios";
import {useAxiosGet, useAxiosPost} from '../../../../../../../../hooks/useAxios'
import {useParams} from 'react-router-dom'
import {useGetDetailProposalTender} from '../../../../hooks/proposalTenderHook'
import {ToolbarInterface, useProposalTender} from '../../../core/_proposalTenderProvider'
import {EvaluasiUserTab} from '../evaluasi_satu_sampul/EvaluasiUserTab'
import KlarifikasiVendorTab from '../evaluasi_satu_sampul/KlarifikasiVendorTab'
import {ApprovalX} from '../../../../../../../components/shared/ApprovalX'


const EvaluasiTeknisDuaSampulTab = ({proposalTender,onQuotationChanged,isOwner}:any) => {
  const [selectedTab, setSelectedTab] = useState("");
  const [menuTab, setMenuTab] = useState([] as any);
  const [renderKey,setRenderKey] = useState(0)
  const intl = useIntl();
  const  [{ doSubmit, isLoading }] = useAxiosPost(
    getEnv("tender") + `/evaluation/administration/syarat`
  )
  const [isLoadingCheck, setIsLoadingCheck] = useState(false);
  const [approvalSchemaSubmitEvaluation, setApprovalSchemaSubmitEvaluation] = React.useState([])
  const [isShowApprovalSchmeaSubmitEvaluation, setIsShowApprovalSchemaSubmitEvaluation] = React.useState(false)
  const checkIsResumeEnabled = (_proposalTender:any) => {
    const allowedStatus = [
      "Evaluasi Teknis",
      "Evaluasi Administrasi",
      "Evaluasi Komersil",
      "Negotiation",
      "Awarding",
      "Laporan Pengadaan",
      "Pengumuman Pemenang",
      "Penunjukan Pemenang",
      "Purchase Order"
    ];

    if (_proposalTender?.metode_penyampaian !== "1 Sampul" && _proposalTender?.status === "Bid Opening") {
      return _proposalTender?.metode_penyampaian_step === 1
    } else {
      return allowedStatus.includes(_proposalTender?.status);
    }

  }

  const checkIsAdministrasiTeknisEnabled = (_proposalTender:any) => {
    const allowedStatus = [
      "Bid Opening Komersil",
      "Evaluasi Teknis",
      "Evaluasi Administrasi",
      "Evaluasi Komersil",
      "Negotiation",
      "Awarding",
      "Laporan Pengadaan",
      "Pengumuman Pemenang",
      "Penunjukan Pemenang",
      "Purchase Order"
    ];
    if (proposalTender?.metode_penyampaian_step !== 0) allowedStatus.push('Bid Opening')
    return allowedStatus.includes(_proposalTender?.status);
  }

  const checkIsAdministrasiKomersilEnabled = (_proposalTender:any) => {
    const allowedStatus = [
      "Evaluasi Komersil",
      "Negotiation",
      "Awarding",
      "Laporan Pengadaan",
      "Pengumuman Pemenang",
      "Penunjukan Pemenang",
      "Purchase Order"
    ];
    return allowedStatus.includes(_proposalTender?.status);
  }

  const initiateTab = (_proposalTender:any) => {
    const tmp = [
      { eventKey: "gambaran-umum", label: intl.formatMessage({id:"Gambaran Umum"}), disabled: false },
      { eventKey: "administrasi-teknis", label: intl.formatMessage({id:"Administrasi Teknis"}), disabled: !checkIsAdministrasiTeknisEnabled(_proposalTender) },
      { eventKey: "administrasi-komersil", label: intl.formatMessage({id:"Komersil"}), disabled: !checkIsAdministrasiKomersilEnabled(_proposalTender) },
      { eventKey: "evaluasi-teknis", label: intl.formatMessage({id:"Resume Admin-Teknis"}), disabled: !checkIsResumeEnabled(_proposalTender) },
      { eventKey: "evaluasi", label: intl.formatMessage({id:"Resume Komersil"}), disabled: !checkIsAdministrasiKomersilEnabled(_proposalTender) },
      { eventKey: "berita-acara", label: intl.formatMessage({id:"Berita Acara"}) },
    ]
    let tmpSelectedTab = "";
    tmp.forEach((item:any) => {
      if(!item?.disabled && tmpSelectedTab === ""){
        tmpSelectedTab = item.eventKey;
        setSelectedTab(item.eventKey);
        return false;
      }
    })
    setMenuTab(tmp);
  }

  const showTab = () => {
    switch (selectedTab) {
      case "gambaran-umum":
        return <GambaranUmumDuaSampulTab proposalTender={proposalTender} />;
      case "administrasi-teknis":
        return <AdministrasiTeknisDuaSampulTab proposalTender={proposalTender} onQuotationChanged={onQuotationChanged} isOwner={isOwner} />;
      case "administrasi-komersil":
        return <AdministrasiKomersilDuaSampulTab proposalTender={proposalTender} onQuotationChanged={onQuotationChanged} isOwner={isOwner}/>;
      case "evaluasi-teknis":
        return <ResumeEvaluasiTab proposalTender={proposalTender} isKomersil = {false}/>;
      case "evaluasi":
        return <ResumeEvaluasiTab proposalTender={proposalTender} isKomersil = {true} />;
      case "berita-acara":
        return <BeritaAcaraTab proposalTender={proposalTender}/>;
      default:
        return <></>;
    }
  }

  const isShowBidOpening = () => {
    return proposalTender?.status === "Bid Opening" &&
      (proposalTender?.status_detail === "Bid Opening" ||
       proposalTender?.status_detail === "Rejected");
  }

  const isShowTutupEvaluasi = () => {
    const statusAllowed = ["Evaluasi Administrasi", "Evaluasi Komersil"];
    return statusAllowed.includes(proposalTender?.status) &&
      (proposalTender?.status_detail === "On Going" || proposalTender?.status_detail === "Rejected");
  }

  const { uuid } = useParams();
  const [{ doGetDetail, data: currentPropoposalTender }] = useGetDetailProposalTender();
  const [{doSubmit: doSubmitGet, isLoading: isLoadingGet}] = useAxiosGet(
    getEnv('tender') + '/negotiation',
  )
  const onBidOpening = () => {
    Swal.fire({
      title: intl.formatMessage({id:"Apakah Anda yakin"})+"?",
      text: intl.formatMessage({id:"Anda akan membuka penawaran"}),
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Ya, submit",
      cancelButtonText: "Tidak"
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        doSubmit(
          {},
          getEnv("tender") + `/bid/submit/${proposalTender.uuid}`,
          toast
        ).then((res: any) => {
          onQuotationChanged && onQuotationChanged();
        }).finally(() => {
          fetchProposalTender()
        });
      } else {
      }
    });
  }

  const checkIsAllEvaluate = async() => {
    setIsLoadingCheck(true);
    let state = true;
    const {data} = await axios.get(getEnv("tender") + `/evaluation/quotation/${proposalTender.uuid}?perPage=1000`)
    data?.data?.forEach((item:any) => {
      if (proposalTender?.status === 'Evaluasi Administrasi') {
        if(item?.vendor?.status_evaluation_administration === "NOT-EVALUATE"){
          state = false;
        }
      }
    })
    setIsLoadingCheck(false);
    return state;
  }

  const handleSubmitEvaluation = async (values:any) => {
    // Submit evaluasi vendor
    setIsShowApprovalSchemaSubmitEvaluation(false)
    let payload = {};
    if(proposalTender?.status === 'Evaluasi Komersil') {
      payload = {
        approvers: values.approval.map((item: any) => {
          return {
            step: item.step,
            jabatan: item.jabatan,
            type: 'APPROVAL',
            user_id: item.extra_info.user.user_id,
            extra_info: item.extra_info,
          }
        }),
      }
    }

    Swal.fire({
      title: intl.formatMessage({id:"Apakah Anda yakin"})+"?",
      text: intl.formatMessage({id:"Anda akan menutup"+" "+proposalTender?.status}),
      icon: "question",
      showCancelButton: true,
      confirmButtonText: intl.formatMessage({id:"Ya, Submit"}),
      cancelButtonText: intl.formatMessage({id:"Tidak"})
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        doSubmit(
          payload,
          getEnv("tender") + `/evaluation/submit/${proposalTender.uuid}`,
          toast
        ).then((res: any) => {
          // submit evaluasi administrasi
          onQuotationChanged && onQuotationChanged();
        }).finally(() => {
          fetchProposalTender()
        });
      } else {
        onQuotationChanged && onQuotationChanged();
      }
    });

  }

  const onTutupEvaluasiAdministrasi = async() => {
    const isValid = await checkIsAllEvaluate();
    if(!isValid){
      toast.error(intl.formatMessage({id:"Evaluasi vendor belum lengkap."}))
      return false;
    }
    if(proposalTender?.status === 'Evaluasi Administrasi'){
      handleSubmitEvaluation({})
    } else {
      const newUrl = getEnv('tender') + `/evaluation/routing-approval-code/${proposalTender?.uuid}`
      doSubmitGet({}, newUrl, undefined).then((res: any) => {
        setApprovalSchemaSubmitEvaluation(res.data?.details)
        setIsShowApprovalSchemaSubmitEvaluation(true)
      })
    }

  }

  const fetchProposalTender = () => {
    doGetDetail(uuid,undefined).then((resProposalTender:any)=>{})
  }

  useEffect(() => {
    fetchProposalTender()
  }, []);

  useEffect(() => {
    initiateTab(currentPropoposalTender)
  }, [currentPropoposalTender])

  return (
    <>
      <div className={""} key={renderKey}>
          <div className={"d-flex justify-content-between"}>
            <div><h3>Evaluasi Tender</h3></div>
          </div>
          <hr />

          <Nav appearance={"subtle"} activeKey={selectedTab} onSelect={(e:any)=>{setSelectedTab(e)}} style={{ marginBottom: 20 }}>
            {menuTab.map((item:any, index:number) => {
              return (
                <Nav.Item eventKey={item.eventKey} key={index} disabled={item?.disabled}>
                  {item.label}
                </Nav.Item>
              );
            })}
          </Nav>

          {showTab()}
        <ApprovalX
          title={'Evaluation'}
          show={isShowApprovalSchmeaSubmitEvaluation}
          schema={approvalSchemaSubmitEvaluation}
          onHide={() => {
            setIsShowApprovalSchemaSubmitEvaluation(false)
          }}
          onSubmit={(data: any) => {
            // handleOnSubmitApprovalSubmitAwarding(data)
            handleSubmitEvaluation(data)
          }}
          isLoading={false}
          source={'TENDER'}
          object_id={proposalTender?.id}
          company_id={proposalTender?.company?.id}
          code={'tender_evaluation'}
          readOnly={isLoading}
        />
        <BottomToolbarWrapper isOwner={isOwner} onGoBack={()=>{}} children={
          <>
            {isShowBidOpening() && (
              <button
                type={"button"}
                disabled={isLoading}
                className={"btn btn-primary me-3"}
                onClick={() => onBidOpening()}
              >
                {isLoading ? (
                  <Spinner animation="border" role="status" size={"sm"} />
                ) : (
                  <i className={"fa fa-envelope-open"}></i>
                )}
                {proposalTender?.metode_penyampaian_step === 0
                  ? intl.formatMessage({id:"Pembukaan Penawaran Teknis"})
                  : intl.formatMessage({id:"Pembukaan Penawaran Komersil"})
                }
              </button>
            )}

            {isShowTutupEvaluasi() && (
              <button
                type={"button"}
                disabled={isLoading}
                className={"btn btn-primary me-3"}
                onClick={onTutupEvaluasiAdministrasi}
              >
                {(isLoading || isLoadingCheck) ? (
                  <Spinner animation="border" role="status" size={"sm"} />
                ) : (
                  <i className={"fa fa-envelope"}></i>
                )}
                {intl.formatMessage({id:"Tutup"+" "+proposalTender?.status})}
              </button>
            )}
          </>
        } />
      </div>
    </>
  );
};

const EvaluasiTeknisDuaSampulPage = ({proposalTender, onQuotationChanged, isOwner}:any) => {
  const {toolbarEvaluasi, setToolbarEvaluasi} = useProposalTender()

  useEffect(() => {
    setToolbarEvaluasi((prevValue: ToolbarInterface) => ({
      ...prevValue,
      show: true
    }));
  }, [])

  return (
    <>
      <>
        {toolbarEvaluasi?.currentTab === 0 && (
          <EvaluasiTeknisDuaSampulTab
            proposalTender={proposalTender}
            onQuotationChanged={onQuotationChanged}
            isOwner={isOwner}
          />
        )}

        {toolbarEvaluasi?.currentTab === 1 && (
          <EvaluasiUserTab
            proposalTender={proposalTender}
          />
        )}

        {toolbarEvaluasi?.currentTab === 2 && (
          <KlarifikasiVendorTab
            proposalTender={proposalTender}
          />
        )}
      </>
    </>
  )
}


export default EvaluasiTeknisDuaSampulPage
