import axios from 'axios'
import {useCallback, useEffect, useState} from 'react'
import {useLang} from '../_metronic/i18n/Metronici18n'

function revokeObjectUrl(objectUrl: string) {
  if (objectUrl !== '') {
    URL.revokeObjectURL(objectUrl)
  }
}

const useRemoteFileAsObjectUrl = (sourceUrlParam: string | null = null) => {
  const [sourceUrl, setSourceUrl] = useState<string | null>(sourceUrlParam)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [objectUrl, setObjectUrl] = useState<string>('')
  const [key,setKey] = useState(0)
  const lang = useLang()

  const resetObjectUrl = useCallback(() => {
    revokeObjectUrl(objectUrl)
    setObjectUrl('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const refresh = useCallback(() => {
    setKey((prevKey) => prevKey + 1)
    setObjectUrl('')
  },[])

  useEffect(() => {
    resetObjectUrl()
    setSourceUrl(sourceUrlParam)
  }, [resetObjectUrl, sourceUrlParam])

  useEffect(() => {
    const controller = new AbortController()

    ;(async () => {
      if (sourceUrl === null || sourceUrl === undefined || sourceUrl === '') {
        setObjectUrl('')
        return
      }

      if (objectUrl !== '') {
        return
      }

      try {
        setIsLoading(true)

        const response = await axios.get(sourceUrl, {
          responseType: 'blob',
          signal: controller.signal,
          headers: {
            'Accept-Language': lang === 'id' ? 'id-ID' : 'en-US',
          },
        })

        if (response.status === 200) {
          setObjectUrl(URL.createObjectURL(response.data))
        }

        setIsLoading(false)
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error)

          setObjectUrl('')
          setIsLoading(false)
        }
      }
    })()

    return () => {
      // Cancel the request when the component unmounts
      controller.abort()

      revokeObjectUrl(objectUrl)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectUrl, sourceUrl, key])

  return {objectUrl, sourceUrl, isLoading, setSourceUrl, resetObjectUrl, refresh}
}

export default useRemoteFileAsObjectUrl
