import { useEffect, useState } from "react";
import { Modal, Tabs, Tab } from "react-bootstrap";
import ReactJson from "react-json-view";
interface IProps {
  show: boolean
  onClose: Function
  errorData: any
}
const ModalError = ({ show, onClose, errorData} : IProps) => {
  const [respon, setRespon] = useState<any>(null)
  useEffect(() => {
    if (errorData?.data !== undefined) {
      setRespon(errorData?.data)
    } else {
      if (errorData?.data?.data?.response?.ET_RETURN !== undefined) {
        setRespon(errorData?.response?.data.data?.response?.ET_RETURN?.item)
      } else {
        setRespon(errorData?.response?.data.data?.response)
      }
    }
  }, [errorData])

  return (
    <Modal show={show} size={"xl"}>
      <Modal.Header closeButton={true} onHide={()=>onClose(true)}>
        <Modal.Title>
          Error {respon?.data?.code}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs defaultActiveKey={0}>
          <Tab eventKey={0} title="Summary" className={"p-2"}>
            <div className={"table-responsive"}>
              <table className={"table table-bordered border mt-3"}>
                <tr>
                  <th className={"p-2"}>
                    <strong>Error Message</strong>
                  </th>
                  <td className={"p-2"}>{respon?.data?.response?.EV_MESSAGE}</td>
                </tr>
                <tr>
                  <th className={"p-2"}>
                    <strong>Error Code</strong>
                  </th>
                  <td className={"p-2"}>{errorData?.status}</td>
                </tr>
                <tr>
                  <th className={"p-2"}>
                    <strong>Error Type</strong>
                  </th>
                  <td className={"p-2"}>SAP</td>
                </tr>
                <tr>
                  <th className={"p-2"}>
                    <strong>Error Detail</strong>
                  </th>
                  <td className={"p-2"}>
                    <div className={"table-responsive"}>
                      {/*{JSON.stringify()}*/}
                      <table className={"table table-bordered border mt-3"}>
                        <tr>
                          <th className={"p-2"}>Type</th>
                          <th className={"p-2"}>Message</th>
                          <th className={"p-2"}>Message V1</th>
                          <th className={"p-2"}>Message V2</th>
                          <th className={"p-2"}>Message V3</th>
                          <th className={"p-2"}>Message V4</th>
                        </tr>
                        {Array.isArray(respon) ? (
                          respon?.map((item: any, index: number) => {
                            return (
                              <tr className={"border"}>
                                <th className={"p-2"}>{item?.TYPE}</th>
                                <th className={"p-2"}>{item?.MESSAGE}</th>
                                <th className={"p-2"}>{item?.MESSAGE_V1}</th>
                                <th className={"p-2"}>{item?.MESSAGE_V2}</th>
                                <th className={"p-2"}>{item?.MESSAGE_V3}</th>
                                <th className={"p-2"}>{item?.MESSAGE_V4}</th>
                              </tr>
                            )
                          })
                        ) : (
                            <tr className={"border"}>
                              <th className={"p-2"}>{respon?.TYPE}</th>
                              <th className={"p-2"}>{respon?.MESSAGE}</th>
                              <th className={"p-2"}>{respon?.MESSAGE_V1}</th>
                              <th className={"p-2"}>{respon?.MESSAGE_V2}</th>
                              <th className={"p-2"}>{respon?.MESSAGE_V3}</th>
                              <th className={"p-2"}>{respon?.MESSAGE_V4}</th>
                            </tr>
                        )}
                      </table>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </Tab>
          {/*<Tab eventKey={1} title="Payload" className={"p-2"}>*/}
          {/*  <ReactJson src={errorData?.data?.data?.payload} theme={"monokai"} />*/}
          {/*</Tab>*/}
          {/*<Tab eventKey={2} title="Response" className={"p-2"}>*/}
          {/*  <ReactJson src={errorData?.data?.data?.response} theme={"monokai"} />*/}
          {/*</Tab>*/}
        </Tabs>
      </Modal.Body>
    </Modal>
  )
}

export default ModalError;