import {Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../../_metronic/layout/core'
import React from 'react'
import {
  AnnouncementList,
} from './components/AnnouncementList'

const masterData = {
  title: 'News',
  path: 'news/admin',
  isSeparator: false,
  isActive: false,
}
const separator = {
  title: '',
  path: '',
  isSeparator: true,
  isActive: false,
}
const breadcrumbs: Array<PageLink> = [masterData, separator]
const childBreadcrumbs: Array<PageLink> = [
  masterData,
  separator,
  {
    title: 'News',
    path: 'news/admin',
    isSeparator: false,
    isActive: false,
  },
  separator,
]

const AnnouncementTenderPage = () => {

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          index
          element={
            <>
              <PageTitle breadcrumbs={breadcrumbs}>News</PageTitle>
              <AnnouncementList />
            </>
          }
        ></Route>
      </Route>
    </Routes>
  )
}

export default AnnouncementTenderPage
