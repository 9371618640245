import React, { useEffect, useState } from "react";
import { useAxiosGet } from "../../../../../../../hooks/useAxios";
import { getEnv } from "../../../../../../../helpers/procurex";
import { Modal } from "react-bootstrap";
import PreviewAttachment from "../../../../../../components/PreviewAttachment";
import previewAttachment from "../../../../../../components/PreviewAttachment";
import { Placeholder } from "rsuite";
import BidTabulationComponent from "./BidTabulationComponent";
import { useIntl } from "react-intl";

const StatusBadgePassedFailed = ({ status }: any) => {
  const getBadgeClass = (status: string) => {
    switch (status) {
      case "PASSED":
        return "bg-success text-white";
      case "FAILED":
        return "bg-danger text-white";
      case "NOT-EVALUATE":
        return "bg-warning text-black";
      default:
        return "bg-default";
    }
  };

  return (
    <span className={`badge ${getBadgeClass(status)}`}>
      {status}
    </span>
  );
};

const TrRekapEvaluasiItem = ({ item }: any) => {
  return (
    <>
      <tr>
        <td className={"px-2 border"}>
          <ul>
            <li>{item?.description}</li>
          </ul>
        </td>
        <td colSpan={2} className={"px-2 border text-center align-middle"}>
          {/*{JSON.stringify(item)}*/}
        </td>
        <td className={"px-2 border text-center align-middle"}>
          <StatusBadgePassedFailed status={item?.technical_evaluation} />
        </td>
        <td className={"px-2 border text-center align-middle"}>
          <StatusBadgePassedFailed status={item?.commercial_evaluation} />
        </td>
        <td className={"px-2 border text-center align-middle"}>
          <StatusBadgePassedFailed status={item?.final_evaluation} />
        </td>
      </tr>
    </>
  );
};

const TrRekapEvaluasi = ({ proposalTender, item, handleOnDetailQuotation, isKomersil }: any) => {
  const intl = useIntl();

  const checkIsPaketSistemGugur = () => {
    return proposalTender?.order_placement === "Paket" &&
      proposalTender?.metode_evaluasi === "Sistem Gugur";
  };

  const checkIsPaketSistemNilai = () => {
    return proposalTender?.order_placement === "Paket" &&
      proposalTender?.metode_evaluasi === "Sistem Nilai";
  };

  const checkIsItemizeSistemGugur = () => {
    return proposalTender?.order_placement === "Itemize" &&
      proposalTender?.metode_evaluasi === "Sistem Gugur";
  };

  const checkIs1Sampul = () => {
    return proposalTender?.metode_penyampaian === "1 Sampul";
  }

  const checkIsItemize = () => {
    return (proposalTender?.order_placement === "Itemize" &&
      proposalTender?.metode_evaluasi === "Sistem Gugur") ||
      (proposalTender?.order_placement === "Itemize" &&
      proposalTender?.metode_evaluasi === "Sistem Nilai");
  };

  const checkIsItemizeSistemNilai = () => {
    return proposalTender?.order_placement === "Itemize" &&
      proposalTender?.metode_evaluasi === "Sistem Nilai";
  };

  const checkPassedTechnicalAdministration = (_tech_eval = null, _tech_score = null) => {
    let ambangBatas = proposalTender?.syarats?.find(syarat => syarat.type === 'configuration')?.ambang_batas ?? 0;
    if(proposalTender?.order_placement === "Paket") {
      console.log(item?.technical_evaluation);
      if(item?.technical_evaluation === "NOT-EVALUATE") {
        return "NOT-EVALUATE";
      } else if (item?.technical_evaluation === "PASSED" && item?.score_technical_evaluation >= ambangBatas) {
        return "PASSED";
      } 
      return "FAILED";
    } else {
      if (_tech_eval === "NOT-EVALUATE") {
        return "NOT-EVALUATE";
      } else if(_tech_eval === "PASSED" && _tech_score >= ambangBatas)
      {
        return "PASSED";
      }
      return "FAILED";
    }
  };  

  return (
    <>
      {!checkIsItemize() &&
        <>
          <tr>
            <td className={"border px-2"}><strong>{item?.name}</strong></td>
            <td className={"text-start border"}>
              <button className={"btn btn-info btn-sm p-2"} type={"button"} onClick={() => {
                handleOnDetailQuotation(item?.name, item?.quotation_no, item?.proposal_tender_vendor_id);
              }}>
                {item?.quotation_no}
              </button>
            </td>

            {(checkIsPaketSistemGugur()) && (
              <>
                <td className={"text-center border align-middle"}><StatusBadgePassedFailed status={item?.administration_evaluation} /></td>
                <td className={"text-center border align-middle"}><StatusBadgePassedFailed status={item?.technical_evaluation} /></td>
                {(isKomersil || checkIs1Sampul()) && (
                  <td className={"text-center border align-middle"}><StatusBadgePassedFailed status={item?.commercial_evaluation} /></td>
                )}
                {(isKomersil || checkIs1Sampul()) ? (
                  <td className="text-center border align-middle">
                    <StatusBadgePassedFailed status={item?.final_evaluation || "-"} />
                  </td>
                ) : (
                  <td className="text-center border align-middle">
                    <StatusBadgePassedFailed status={item?.final_evaluation_tech || "-"} />
                  </td>
                )}

              </>
            )}

            {checkIsPaketSistemNilai() && (
              <>
                <td className={"text-center border align-middle"}>
                  <StatusBadgePassedFailed status={item?.administration_evaluation} />
                </td>
                <td className={"text-center border align-middle"}>
                  <strong>{item?.score_technical_evaluation}</strong>
                  {" | "}
                  <StatusBadgePassedFailed status={checkPassedTechnicalAdministration()} />
                </td>
                {(isKomersil || checkIs1Sampul()) && (
                  <td className={"text-center border align-middle"}>{item?.commercial_value}</td>
                )}
                {(isKomersil || checkIs1Sampul()) && (
                  <td className={"text-center border align-middle"}>
                    <strong>{(Number(item?.technical_value) + Number(item?.commercial_value)).toFixed(2)}</strong>
                  </td>
                )}
                <td className={"text-center border align-middle"}>
                  <StatusBadgePassedFailed status={item?.final_evaluation} />
                </td>
              </>
            )}
          </tr>

          {item?.items?.map((v: any, index: number) => {
            return <TrRekapEvaluasiItem item={v} key={"rekap-item-" + index} />;
          })}
        </>
      }

      {checkIsItemize() &&
        <>
          {item.items.length > 0 ? (
            <>
              <tr>
                <td className={"border px-2 text-start"} rowSpan={item.items.length + 1}><strong>{item?.name}</strong></td>
                <td className={"border text-start"} rowSpan={item.items.length + 1}>
                  <button className={"btn btn-info btn-sm p-2"} type={"button"} onClick={() => {
                    handleOnDetailQuotation(item?.name, item?.quotation_no, item?.proposal_tender_vendor_id);
                  }}>
                    {item?.quotation_no}
                  </button>
                </td>
              </tr>

              {(checkIsItemizeSistemGugur()) && (
                <>
                  {item?.items?.map((v: any, index: number) => {
                    return (
                      <>
                        <tr>
                          <td className={"px-2 border"}>
                            <ul>
                              <li>{v?.description}</li>
                            </ul>
                          </td>
                          <td className={"px-2 border text-center align-middle"}>
                            <StatusBadgePassedFailed status={checkIsItemizeSistemNilai() ? v?.administration_value : v?.administration_evaluation} />
                          </td>
                          <td className={"px-2 border text-center align-middle"}>
                            <StatusBadgePassedFailed status={checkIsItemizeSistemNilai() ? v?.technical_value : v?.technical_evaluation} />
                          </td>
                          {(isKomersil || checkIs1Sampul()) && (
                            <td className={"px-2 border text-center align-middle"}>
                              <StatusBadgePassedFailed status={checkIsItemizeSistemNilai() ? v?.commercial_value : v?.commercial_evaluation} />
                            </td>
                          )}
                          {checkIsItemizeSistemNilai() &&
                            <td className={"px-2 border text-center align-middle"}>
                              <StatusBadgePassedFailed status={v?.technical_value + v?.commercial_value} />
                            </td>
                          }
                          {(isKomersil || checkIs1Sampul()) ? (
                            <td className={"px-2 border text-center align-middle"}>
                              <StatusBadgePassedFailed status={v?.final_evaluation} />
                            </td>
                          ) : (
                            <td className={"px-2 border text-center align-middle"}>
                              <StatusBadgePassedFailed status={v?.final_evaluation_tech} />
                            </td>
                          )}
                        </tr>
                      </>
                    )
                  })}
                </>
              )}

              {(checkIsItemizeSistemNilai()) && (
                <>
                  {item?.items?.map((v: any, index: number) => {
                    return (
                      <>
                        <tr>
                          <td className={"px-2 border align-middle"}>
                            <ul>
                              <li>{v?.description}</li>
                            </ul>
                          </td>
                          <td className={"px-2 border text-center align-middle"}>
                            <StatusBadgePassedFailed status={checkIsItemizeSistemNilai() ? v?.administration_value : v?.administration_evaluation} />
                          </td>
                          <td className={"text-center border align-middle"}>
                            <strong>{v?.score_technical_evaluation}</strong>
                            {" | "}
                            <StatusBadgePassedFailed status={checkPassedTechnicalAdministration(v?.technical_evaluation, v?.score_technical_evaluation)} />
                          </td>
                          {(isKomersil || checkIs1Sampul()) ? (
                          <td className={"px-2 border text-center align-middle"}>
                            <StatusBadgePassedFailed status={v?.final_evaluation} />
                          </td>
                          ) : (
                            <td className={"px-2 border text-center align-middle"}>
                            <StatusBadgePassedFailed status={v?.final_evaluation_tech} />
                          </td>
                          )}
                        </tr>
                      </>
                    )
                  })}
                </>
              )}  
            </>
          ) : (
            <>
              <tr>
                <td className={"border px-2 text-start"}><strong>{item?.name}</strong></td>
                <td className={"text-start border text-start"}>
                  <button className={"btn btn-info btn-sm p-2"} type={"button"} onClick={() => {
                    handleOnDetailQuotation(item?.name, item?.quotation_no, item?.proposal_tender_vendor_id);
                  }}>
                    {item?.quotation_no}
                  </button>
                </td>
                <td   
                  colSpan={ checkIsItemizeSistemNilai() 
                      ? ((isKomersil || checkIs1Sampul) ? 7 : 7) 
                      : 7
                  }
                  className={"border text-center align-middle"}>{intl.formatMessage({id:"Belum dilakukan evaluasi"})}</td>
              </tr>
            </>
          )}
        </>
      }
    </>
  );
};

export const RekapEvaluasi = ({ proposalTender,isKomersil }: any)  => {
  const [dataItems, setDataItems] = useState([]);
  const [bobotTeknis, setBobotTeknis] = useState(0);
  const [bobotKomersil, setBobotKomersil] = useState(0);
  const [ambangBatas, setAmbangBatas] = useState(0);
  const [vendorName, setVendorName] = useState("");
  const [quotationNo, setQuotationNo] = useState("");
  const [syarats, setSyarats] = useState<any>([]);
  const [syaratAdministrasi, setSyaratAdministrasi] = useState<any>([]);
  const [syaratTechnical, setSyaratTechnical] = useState<any>([]);
  const [syaratCommercial, setSyaratCommercial] = useState<any>([]);
  const [isShowModalDetailQuotation, setIsShowModalDetailQuotation] = useState(false);
  const [previewAttachment, setPreviewAttachment] = useState<any>(null);
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosGet(
    getEnv("tender") + `/evaluation/summary/${proposalTender?.uuid}`
  );
  const intl = useIntl();

  const checkIsPaketSistemGugur = () => {
    return proposalTender?.order_placement === "Paket" &&
      proposalTender?.metode_evaluasi === "Sistem Gugur";
  };

  const checkIsPaketSistemNilai = () => {
    return proposalTender?.order_placement === "Paket" &&
      proposalTender?.metode_evaluasi === "Sistem Nilai";
  };

  const checkIsItemizeSistemGugur = () => {
    return proposalTender?.order_placement === "Itemize" &&
      proposalTender?.metode_evaluasi === "Sistem Gugur";
  };

  const checkIs1Sampul = () => {
    return proposalTender?.metode_penyampaian === "1 Sampul";
  }

  const checkIs2Sampul = () => {
    return proposalTender?.metode_penyampaian === "2 Sampul";
  }

  const checkIs2Tahap = () => {
    return proposalTender?.metode_penyampaian === "2 Tahap";
  }

  const checkIsItemizeSistemNilai = () => {
    return proposalTender?.order_placement === "Itemize" &&
      proposalTender?.metode_evaluasi === "Sistem Nilai";
  };

  const getItems = () => {
    doSubmit({}, undefined, undefined).then((res: any) => {
      if(( checkIs2Sampul() || checkIs2Tahap() ) && isKomersil){
        if( proposalTender?.metode_evaluasi === "Sistem Nilai"){
          res.data.filter((v: any) => v.final_evaluation_tech === 'PASSED').sort((a: any, b: any) => (a.technical_value + a.commercial_value) - (b.technical_value + b.commercial_value));
          setDataItems(res.data.reverse());
        } else{
          setDataItems(res.data.filter((v: any) => v.final_evaluation_tech === 'PASSED'));
        }
      } else {
        if( proposalTender?.metode_evaluasi === "Sistem Nilai"){
          res.data.sort((a: any, b: any) => (a.technical_value + a.commercial_value) - (b.technical_value + b.commercial_value));
          setDataItems(res.data.reverse());
        } else{
          setDataItems(res.data);
        }
      }
    });
  };

  const getSyarat = () => {
    if (checkIsPaketSistemNilai() || checkIsItemizeSistemNilai()) {
      const _syarat = proposalTender?.syarats?.filter((v: any) => v.type === "configuration");
      if (_syarat.length > 0) {
        setBobotTeknis(_syarat[0].bobot_teknis);
        setBobotKomersil(_syarat[0].bobot_komersial);
        setAmbangBatas(_syarat[0].ambang_batas);
      }
    }
  };

  const handleOnDetailQuotation = (_vendorName: string, _quotationNo: string, vendorId: any) => {
    setIsShowModalDetailQuotation(true);
    setVendorName(_vendorName);
    setQuotationNo(_quotationNo);
    getEvaluasisAdministrasi(vendorId);
    getEvaluasiTeknis(vendorId);
    getEvaluasiCommercial(vendorId);
  };

  const getEvaluasisAdministrasi = (vendorId: any) => {
    doSubmit(
      {},
      getEnv("tender") + `/evaluation/administration/${proposalTender?.uuid}/${vendorId}`
    ).then((res: any) => {
      setSyaratAdministrasi(res.data);
    });
  };

  const getEvaluasiTeknis = (vendorId: any) => {
    doSubmit(
      {},
      getEnv("tender") + `/evaluation/technical/${proposalTender?.uuid}/${vendorId}`
    ).then((res: any) => {
      setSyaratTechnical(res.data);
    });
  };

  const getEvaluasiCommercial = (vendorId: any) => {
    doSubmit(
      {},
      getEnv("tender") + `/evaluation/commercial/${proposalTender?.uuid}/${vendorId}`
    ).then((res: any) => {
      setSyaratCommercial(res.data);
    });
  };

  useEffect(() => {
    getItems();
    getSyarat();
  }, [isKomersil]);

  useEffect(() => {
    setSyarats([...syaratAdministrasi, ...syaratTechnical, ...syaratCommercial]);
  }, [syaratAdministrasi, syaratTechnical, syaratCommercial]);

  return (
    <div className={"mb-4"}>
      {(checkIsPaketSistemNilai() || checkIsItemizeSistemNilai()) && (
        <div className={"mb-10"}>
          <h3>{intl.formatMessage({id:"Parameter Evaluasi"})}</h3>
          <hr />
          <div className={"row"}>
            <div className={"col-md-3"}>
              <div className={"form-group"}>
                <label><strong>{intl.formatMessage({id:"Bobot Teknis"})}</strong></label>
                <input className={"form-control form-control-sm"} disabled={true} value={bobotTeknis} />
              </div>
            </div>
            <div className={"col-md-3"}>
              <div className={"form-group"}>
                <label><strong>{intl.formatMessage({id:"Bobot Komersil"})}</strong></label>
                <input className={"form-control form-control-sm"} disabled={true} value={bobotKomersil} />
              </div>
            </div>
            <div className={"col-md-3"}>
              <div className={"form-group"}>
                <label><strong>{intl.formatMessage({id:"Ambang Batas"})}</strong></label>
                <input className={"form-control form-control-sm"} disabled={true} value={ambangBatas} />
              </div>
            </div>
          </div>
        </div>
      )}

      <h3>{intl.formatMessage({id:"Rekap Evaluasi"})}</h3>
      <hr />
      <div className={"table-responsive"}>
        {isLoading && (
          <>
            <Placeholder.Grid rows={5} columns={5} active />
          </>
        )}
        {!isLoading && (
          <table className={"table table-header-gray"}>
            <thead>
            <tr>
              <th className={"border px-2"}><strong>{intl.formatMessage({id:"Nama Rekanan"})}</strong></th>
              <th className={"border text-center align-middle"}><strong>{intl.formatMessage({id:"Nomor Penawaran"})}</strong></th>
              
              {(checkIsPaketSistemGugur()) && (
                <>
                  <th className={"border text-center text-nowrap align-middle"}><strong>{intl.formatMessage({id:"Evaluasi Administrasi"})}</strong></th>
                  <th className={"border text-center text-nowrap align-middle"}><strong>{intl.formatMessage({id:"Evaluasi Teknis"})}</strong></th>
                  {(isKomersil || checkIs1Sampul()) && (
                    <th className={"border text-center text-nowrap align-middle"}><strong>{intl.formatMessage({id:"Evaluasi Komersil"})}</strong></th>
                  )}
                  <th className={"border text-center text-nowrap px-2 align-middle"}><strong>{intl.formatMessage({id:"Hasil Akhir"})}</strong></th>
                </>
              )}
              {(checkIsItemizeSistemGugur()) && (
                <>
                  <th className={"border text-center text-nowrap align-middle"}><strong>{intl.formatMessage({id:"Deskripsi"})}</strong></th>
                  <th className={"border text-center text-nowrap align-middle"}><strong>{intl.formatMessage({id:"Evaluasi Administrasi"})}</strong></th>
                  <th className={"border text-center text-nowrap align-middle"}><strong>{intl.formatMessage({id:"Evaluasi Teknis"})}</strong></th>
                  {(isKomersil || checkIs1Sampul()) && (
                    <th className={"border text-center text-nowrap align-middle"}><strong>{intl.formatMessage({id:"Evaluasi Komersil"})}</strong></th>
                  )}
                  <th className={"border text-center text-nowrap px-2 align-middle"}><strong>{intl.formatMessage({id:"Hasil Akhir"})}</strong></th>
                </>
              )}
              {checkIsPaketSistemNilai() && (
                <>
                  <th className={"border text-center text-nowrap align-middle"}><strong>{intl.formatMessage({id:"Evaluasi Administrasi"})}</strong></th>
                  <th className={"border text-center text-nowrap align-middle"}><strong>{intl.formatMessage({id:"Nilai Teknis"})}</strong></th>
                  {(isKomersil || checkIs1Sampul()) && (
                    <th className={"border text-center text-nowrap align-middle"}><strong>{intl.formatMessage({id:"Nilai Komersil"})}</strong></th>
                  )}
                  {(isKomersil || checkIs1Sampul()) && (
                    <th className={"border text-center text-nowrap align-middle"}><strong>{intl.formatMessage({id:"Nilai Akhir"})}</strong></th>
                  )}
                  <th className={"border text-center text-nowrap px-2 align-middle"}><strong>{intl.formatMessage({id:"Hasil Akhir"})}</strong></th>
                </>
              )}
              {checkIsItemizeSistemNilai() && (
                <>
                  <th className={"border align-middle text-center text-nowrap align-middle"}><strong>{intl.formatMessage({id:"Item Penawaran"})}</strong></th>
                  <th className={"border align-middle text-center text-nowrap align-middle"}><strong>{intl.formatMessage({id:"Evaluasi Administrasi"})}</strong></th>
                  <th className={"border align-middle text-center text-nowrap align-middle"}><strong>{intl.formatMessage({id:"Nilai Teknis"})}</strong></th>
                  {(isKomersil || checkIs1Sampul()) && (
                    <th className={"border align-middle text-center text-nowrap align-middle"}><strong>{intl.formatMessage({id:"Nilai Komersil"})}</strong></th>
                  )}
                  {(isKomersil || checkIs1Sampul()) && (
                    <th className={"border align-middle text-center text-nowrap px-2 align-middle"}><strong>{intl.formatMessage({id:"Nilai Total"})}</strong></th>
                  )}
                  <th className={"border align-middle text-center text-nowrap px-2 align-middle"}><strong>{intl.formatMessage({id:"Hasil Akhir"})}</strong></th>
                </>
              )}

            </tr>
            </thead>
            <tbody>
            {dataItems.length === 0 && (
              <tr>
                <td colSpan={5} className={"border text-center align-middle"}>{intl.formatMessage({id:"Tidak ada data"})}</td>
              </tr>
            )}
            {dataItems.map((item: any, index: number) => {
              return (
                <TrRekapEvaluasi
                  proposalTender={proposalTender}
                  item={item}
                  key={"rekap-" + index}
                  handleOnDetailQuotation={handleOnDetailQuotation}
                  isKomersil = {isKomersil}
                />
              );
            })}
            </tbody>
          </table>
        )}

      </div>

      <Modal size={"lg"} show={isShowModalDetailQuotation}>
        <Modal.Header closeButton onHide={() => setIsShowModalDetailQuotation(false)}>
          <h4>{intl.formatMessage({id:"Detail Quotation"})}</h4>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className={"row"}>
              <div className={"col-6"}>
                <div className={"form-group"}>
                  <label><strong>{intl.formatMessage({id:"Nama Vendor"})}</strong></label>
                  <input className={"form-control form-control-sm"} disabled={true} value={vendorName} />
                </div>
              </div>
              <div className={"col-6"}>
                <div className={"form-group"}>
                  <label><strong>{intl.formatMessage({id:"Nomor Penawaran"})}</strong></label>
                  <input className={"form-control form-control-sm"} disabled={true} value={quotationNo} />
                </div>
              </div>
              <div className={"col-12 mt-5"}>
                {isLoading && <Placeholder.Grid rows={5} columns={4} active />}
                {!isLoading && (
                  <table className={"table table-header-gray"}>
                    <thead>
                    <tr>
                      <th className={"border px-2 text-center align-middle"}>{intl.formatMessage({id:"No"})}</th>
                      <th className={"border px-2 text-nowrap"}>{intl.formatMessage({id:"Kategori"})}</th>
                      <th className={"border px-2"}>{intl.formatMessage({id:"Deskripsi"})}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {syarats.map((item: any, index: number) => {
                      return (
                        <tr key={"syarat-" + index}>
                          <td className={"border px-2 text-center align-middle"}>{index + 1}</td>
                          <td className={"border px-2"}>
                            {item?.type}
                          </td>
                          <td className={"border px-2"}>
                            <div className={"input-group"}>
                              <input className={"form-control form-control-sm"} disabled={true}
                                     value={item?.description} />
                              <button className={"btn btn-primary btn-sm"} type={"button"} onClick={() => {
                                setPreviewAttachment(item?.media_url);
                              }}>
                                <i className={"fa fa-eye"}></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                    </tbody>
                  </table>
                )}

              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button className={"btn btn-danger btn-sm"} type={"button"}
                  onClick={() => setIsShowModalDetailQuotation(false)}>
            <i className={"fa fa-times"}></i>
            {intl.formatMessage({id:"Tutup"})}
          </button>
        </Modal.Footer>
      </Modal>
      <PreviewAttachment previewExt={previewAttachment} onHide={() => setPreviewAttachment(null)} />
    </div>
  );
};

export const ResumeEvaluasiTab = ({ proposalTender, isKomersil = false }: any) => {
  return (
    <div>
      <RekapEvaluasi proposalTender={proposalTender} isKomersil={isKomersil}  />

      <BidTabulationComponent proposalTender={proposalTender} isKomersil={isKomersil} />
    </div>
  );
};
