import {ID, KTCard, KTCardBody} from '../../../../../../_metronic/helpers'
import React, {useState} from 'react'
import TableXWrpper from "../../../../../components/shared/TableXWrapper";

const AnnouncementList = () => {
  const [urlIndex,setUrlIndex] = useState(process.env.REACT_APP_API_URL+"/api/masterdata/news");
  const tableColumns = [
    {Header: 'ID', accessor: 'id', width: 50, disableCreate: true, disableEdit: true, disableIndex: true},
    {Header: 'Title', accessor: 'title', width: 800},
    {Header: 'Type', accessor: 'type.name', accessor_form: 'type_id', width: 200, type: 'select2',
      options: {
        url: process.env.REACT_APP_API_URL+"/api/masterdata/news-type",
        key: 'id',
        label: 'name',
        return_only_id: true
      }
    },
    {Header: 'Group', accessor: 'group.name', accessor_form: 'group_id', width: 200, type: 'select2',
      options: {
        url: process.env.REACT_APP_API_URL+"/api/masterdata/news-group",
        key: 'id',
        label: 'name',
        return_only_id: true
      }
    },
    {Header: 'Status', accessor: 'status.name', accessor_form: 'status', width: 200, type: 'select2',
      options: {
        url: process.env.REACT_APP_API_URL+"/api/masterdata/enumeration&filter[type]=NEWS_STATUS",
        key: 'value',
        label: 'description',
        return_only_id: false
      }
    },
  ]
  return (
    <>
      <KTCard>
        <KTCardBody className='py-4'>
          <TableXWrpper
              title={"News"}
              urlIndex={urlIndex}
              columns={tableColumns}
          />
        </KTCardBody>
      </KTCard>
    </>
  )
}

export {AnnouncementList}
