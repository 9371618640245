import {useEffect, useState} from "react";
import axios from "axios";

interface IMeta {
    message: string,
    code: number
}
interface IResponse {
    data: any,
    meta: IMeta
}

export const useGetModuleUser = ()=>{
    const url = process.env.REACT_APP_API_URL+"/api/auth/module?filter[status]=ACTIVE&only_me=1&only_module=true"
    const [isLoadingModule,setIsLoading] = useState<boolean>(false);
    const [responseDataModule, setResponseData] = useState<any>({})
    const [responseErrorModule,setResponseError] = useState<any>({})

    useEffect(()=>{
        getDataModule();
    },[])
    const getDataModule = async():Promise<void> => {
        try {
            setIsLoading(true)
            const response =  await axios.get<IResponse>(url)
            setResponseData(response.data);
        } catch(err:any) {
            setResponseError(err);
        } finally {
            setIsLoading(false)
        }
    }
    return [ isLoadingModule, responseDataModule, responseErrorModule, getDataModule] as const;
}
