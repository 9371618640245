import { Nav, Placeholder } from "rsuite";
import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import {formatDateTime, formatDateTimeToDBFormat, getEnv} from '../../../../../helpers/procurex'
import axios from "axios";
import { useMutation, useQuery } from "react-query";
import GambaranUmumTab from "./GambaranUmumTab";
import ProsesTab from "./ProsesTab";
import SummaryTab from "./SummaryTab";
import {useNavigate, useParams} from 'react-router-dom'
import {ApprovalX, ButtonProsesApproval} from '../../../../components/shared/ApprovalX'
import {toast} from 'react-toastify'
import Swal from 'sweetalert2'
import {Spinner} from 'react-bootstrap'
import {StatusBadgeAanwijzing} from './AanwijzingList'
import {BeritaAcaraAanwijzing} from './BeritaAcaraAanwijzing'
import {AanwijzingProvider, useAanwijzing} from './core/_aanwijzingProvider'
import TableTabs from '../../../mr-sr/request/list/components/header/TableTabs'
import {BeritaAcaraEditorAanwijzing} from './BeritaAcaraEditorAanwijzing'

const Aanwijzing = ({aanwijzingUuid, isShowBackButton=true}:any) =>{
  const [selectedTab, setSelectedTab] = useState("gambaran-umum")
  const {
    aanwijzing,
    isLoading,
    setAanwizingUuid,
    mutateSubmitForApproval,
    isLoadingSubmitForApproval,
    refetchAanwijzing,
  } = useAanwijzing();
  const intl =useIntl()
  const navigate = useNavigate();
  const [routingApprovalSchema,setRoutingApprovalSchema] = useState([] as any)
  const [isShowApprovalSchema, setIsShowApprovalSchema] = useState(false)
  const isTabEnabled = () => {
    const allowedStatus = [
      'LIVE',
      'CLOSED',
      'DONE'
    ];
    return allowedStatus.includes(aanwijzing?.status)
  }

  const isSummaryEnabled = () => {
    const allowedStatus = [
      'LIVE',
      'CLOSED',
      'DONE'
    ];
    return allowedStatus.includes(aanwijzing?.status)
  }

  const isBAEnabled = () => {
    const allowedStatus = [
      'CLOSED',
      'DONE'
    ];
    return allowedStatus.includes(aanwijzing?.status)
  }

  const isTabBAEnabled = () => {
    const allowedStatus = [
      'CLOSED',
      'DONE'
    ];
    return allowedStatus.includes(aanwijzing?.status)
  }

  const [currentTabNumber, setCurrentTabNumber] = useState(0)
  const [tabs,setTabs] = useState([])


  const generateTab = () => {
    return  [
      {
        value: 0,
        text: intl?.formatMessage({id: 'Gambaran Umum'}),
        preventDefault: false,
        disabled: false,
      },
      {
        value: 1,
        text: intl?.formatMessage({id: 'Proses'}),
        preventDefault: false,
        disabled:!isTabEnabled(),
      },
      {
        value: 2,
        text: intl?.formatMessage({id: 'Summary'}),
        preventDefault: false,
        disabled:!isSummaryEnabled(),
      },
      {
        value: 3,
        text: intl?.formatMessage({id: 'Berita Acara'}),
        preventDefault: false,
        disabled:!isBAEnabled(),
      },
      // {
      //   value: 4,
      //   text: intl?.formatMessage({id: 'Berita Acara (dev)'}),
      //   preventDefault: false,
      //   disabled:!isTabEnabled(),
      // },
    ]
  }

  // @ts-ignore
  const {isLoading: isLoadingGetApprovalCode, refetch:refetchGetApprovalCode} = useQuery({
      queryFn: () => axios.get(`${getEnv('tender')}/aanwijzing/routing-approval-code/${aanwijzing?.uuid}`),
      enabled:false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onSuccess: (res:any) => {
        console.log("routing_approval",res?.data?.data?.details)
        setRoutingApprovalSchema(res?.data?.data?.details)
        setIsShowApprovalSchema(true)
      },
      //@ts-ignore
      onError: (err:any) => {
        alert(err)
      }
  })


  //endregion

  const showTab = () => {
    // if(selectedTab === 'gambaran-umum'){
    if(currentTabNumber===0){
      return <GambaranUmumTab
        isLoading={isLoading}
        onClickSubmitForApproval={()=> {
          refetchGetApprovalCode()
        }}
      />
    } else if(currentTabNumber===1){
      return <ProsesTab aanwijzing={aanwijzing} />
    } else if(currentTabNumber===2){
      return <SummaryTab aanwijzing={aanwijzing} />
    } else if(currentTabNumber===3){
      return <BeritaAcaraAanwijzing aanwijzing={aanwijzing} />
    } else if(currentTabNumber===4){
      return <BeritaAcaraEditorAanwijzing aanwijzing={aanwijzing} />
    }
  }

  const isShowSubmitForApproval = () => {
    return aanwijzing?.status === "OPEN" ||
      aanwijzing?.status === "REJECTED"
      // checkStatus()
  }

  const isStopButtonShow = () => {
    return aanwijzing?.status === "LIVE"
  }

  const isClosedButton = () => {
    return aanwijzing?.status === "DONE"
  }

  const isOnApproval = () => {
    return aanwijzing?.status === "SUBMITTED"
  }


  const handleOnSubmitApproval = (values:any) => {
    const payload = {
      uuid: aanwijzing?.uuid,
      approvers: values.approval.map((item: any) => {
        return {
          step: item.step,
          jabatan: item.jabatan,
          type: "APPROVAL",
          user_id: item.extra_info.user.user_id,
          extra_info: item.extra_info
        };
      })
    };
    mutateSubmitForApproval({
      payload: payload
    }, {
      onSuccess: (res:any) => {
        toast.success("Berhasil melakukan approval")
        refetchAanwijzing()
        setIsShowApprovalSchema(false)
      },
      onError: (err:any) => {
        toast.error(err)
      }
    })
  }
  //endregion

  //region close aanwijzing
  const {mutate: mutateCloseAanwijzing, isLoading:isLoadingCloseAanwijzing} = useMutation({
    mutationKey: ["aanwijzing","close"],
    mutationFn: ({uuid, payload}:any) => axios.post(getEnv('tender')+`/aanwijzing/close/${uuid}`,payload),
    onSuccess: (res:any) => {
      toast.success("Successfully close aanwijzing")
      refetchAanwijzing()
    },
    onError: (err:any) => {
      toast.error(err)
    }
  })

  const handleOnDone = () => {
    Swal.fire({
      title: "Apakah anda yakin?",
      text: "Anda akan menuntup sesi Aanwijzing ini",
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Ya, tutup',
      cancelButtonText: 'Tidak',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        mutateDoneAanwijzing({
          uuid: aanwijzing?.uuid
        })
      }
    })
  }
  //endregion

  //region done aanwijzing
  const {mutate: mutateDoneAanwijzing, isLoading:isLoadingDoneAanwijzing} = useMutation({
    mutationKey: ["aanwijzing","close"],
    mutationFn: ({uuid, payload}:any) => axios.post(getEnv('tender')+`/aanwijzing/done/${uuid}`,payload),
    onSuccess: (res:any) => {
      toast.success("Successfully done aanwijzing")
      refetchAanwijzing();
    },
    onError: (err:any) => {
      toast.error(err)
    }
  })

  const handleOnClose = () => {
    Swal.fire({
      title: "Apakah anda yakin?",
      text: "Anda akan menyelesaikan sesi Aanwijzing ini",
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Ya, selesaikan',
      cancelButtonText: 'Tidak',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        mutateCloseAanwijzing({
          uuid: aanwijzing?.uuid
        })
      }
    })
  }
  //endregion

  useEffect(() => {
    setTabs(generateTab())

  }, [aanwijzing])

  useEffect(() => {
    if(!aanwijzingUuid) return ;
    setAanwizingUuid(aanwijzingUuid)
  }, [aanwijzingUuid])

  if(!aanwijzing) {
    return (
      <div>
        <Placeholder.Paragraph rows={10} />
      </div>
    )
  }

  return (
    <>
      <div className={"d-flex justify-content-between mb-3 gap-2"}>
        <div className={'d-flex flex-column gap-2'}>
          <h2 className={'py-0 my-0'}>{aanwijzing?.title || "[UNTITLED]"}</h2>
          <div>
            <span className={"me-2"}>No: <strong>{aanwijzing?.reference}</strong></span>
            <span>| <strong>({aanwijzing?.company?.comp_code} - {aanwijzing?.company?.name})</strong></span>

          </div>
          <div>
            <span className={'badge badge-primary'}>#{aanwijzing?.source}</span>
          </div>
          <div className={'col-md-12 d-flex gap-3'}>
            {isShowBackButton && (
              <button className={'btn btn-danger btn-sm'} type={'button'} onClick={() => {
                navigate('/tender/admin/aanwijzing')
              }}>
                <i className={'fa fa-arrow-left'}></i> Kembali Ke List
              </button>
            )}

          </div>
        </div>

        <div className={'d-flex flex-column gap-2 text-end'}>
          <div>
            <StatusBadgeAanwijzing status={aanwijzing?.status} />
          </div>
          <div>
          <strong>{formatDateTime(aanwijzing?.start_at)} - {formatDateTime(aanwijzing?.end_at)}</strong>
          </div>
          {isOnApproval() && (
            <div>
              <div>
                &nbsp;
              </div>
              <ButtonProsesApproval
                onShowDetailApproval={() => {
                  setIsShowApprovalSchema(true)
                }}
                source={'TENDER'}
                object_id={aanwijzing?.id}
                company_id={aanwijzing?.company?.id}
                code={aanwijzing?.routing_approval_config_code}
                readOnly={true}
                onSubmit={() => {
                  // onApprove && onApprove();
                  refetchAanwijzing();
                }}
              />
            </div>
          )}
          {isStopButtonShow() && (
            <div>
              <div>
                &nbsp;
              </div>
              <button
                type={'button'}
                onClick={handleOnDone}
                disabled={isLoadingDoneAanwijzing}
                className={'btn btn-primary btn-sm'}
              >
                {(isLoadingDoneAanwijzing) && (
                  <Spinner animation="border" role="status" size={"sm"} className={"me-2"} />
                )}
                {!isLoadingDoneAanwijzing && (
                  <i className={'fa fa-stop me-2'}></i>
                )}
                Stop
              </button>
            </div>
          )}
          {isClosedButton() && (
            <div>
              <div>
                &nbsp;
              </div>
              <button
                type={'button'}
                onClick={handleOnClose}
                disabled={isLoadingCloseAanwijzing}
                className={'btn btn-primary btn-sm'}
              >
                {isLoadingCloseAanwijzing && (
                  <Spinner animation="border" role="status" size={"sm"} className={"me-2"} />
                )}
                {!isLoadingCloseAanwijzing && (
                  <i className={'fa fa-flag me-2'}></i>
                )}
                Close
              </button>
            </div>
          )}

          <div>

            <ApprovalX
              show={isShowApprovalSchema}
              schema={routingApprovalSchema}
              onHide={() => {
                setIsShowApprovalSchema(false)
              }}
              onSubmit={(data: any) => {
                handleOnSubmitApproval(data)
              }}

              isLoading={isLoadingSubmitForApproval}
              source={'TENDER'}
              object_id={aanwijzing?.id}
              company_id={aanwijzing?.company?.id}
              code={aanwijzing?.routing_approval_config_code}
              readOnly={aanwijzing?.status === 'SUBMITTED'}
            />
          </div>
        </div>
      </div>
      <div className={''}>
        <div className={"bg-white mb-2"}>
          <TableTabs
            tabs={tabs}
            currentTab={currentTabNumber}
            hideBadge={true}
            setCurrentTab={async (tabNumber: any) => {
              setCurrentTabNumber(tabNumber)
            }}
          />
        </div>

        {isLoading && <Placeholder.Paragraph rows={10} />}
        {(!isLoading && aanwijzing) && (
          <>
          {showTab()}
          </>
        )}

      </div>
    </>
  )
}

const AanwijzingDetail = ({aanwijzingUuid,isShowBackButton=true}:any) => {
  return (
    <>
      <AanwijzingProvider>
        <Aanwijzing aanwijzingUuid={aanwijzingUuid} isShowBackButton={isShowBackButton} />
      </AanwijzingProvider>
    </>
  )
}
export default AanwijzingDetail;