import { formatCurrency, KTCard, KTCardBody } from "../../../../../../_metronic/helpers";
import React, { useEffect, useState } from "react";
import TableXWrpper from "../../../../../components/shared/TableXWrapper";
import ButtonToolbarPRIncoming from "./ButtonToolbarPRIncoming";
import { getEnv, stringPadZero } from "../../../../../../helpers/procurex";
import axios from "axios";

interface IProps {
}

const BadgeUserNIP = ({nip}: any) => {
  const [user, setUser] = useState<any>({})
  const getUserDetail = () => {
    if(!nip){
      setUser({})
    } else {
      const url = getEnv('sso') + '/user?filter[username]=' + nip;
      axios.get(url)
        .then((res: any) => {
          // console.log(res?.data?.data[0])
          setUser(res?.data?.data[0])
        })
    }

  }

  useEffect(() => {
    getUserDetail()
  },[nip]);

  return (
    <div className={"badge badge-primary"}>
      {user?.username} - {user?.name}
    </div>
  )
}

const PurchasingRequisitionIncomingList = ({}:IProps) => {
  const [tableIndex,setTableIndex] = useState(0)
  const urlIndex = getEnv('tender') +"/purchase-requisition-item-tender";
  const [selectedRows, setSelectedRows] = useState([]);

  const BadgeUser = ({data}: any) => {
    const [usersNIP, setUserNIP] = useState<any>([])
    const [render, setRender] = useState<any>(0)
    const getUserDetail = () => {
      if (data?.current_approval_user) {
        if (!Array.isArray(data?.current_approval_user)) {
          setUserNIP([data?.current_approval_user])
          setRender(render + 1)
        } else {
          data.current_approval_user.forEach((v: any, i: number) => {
            setUserNIP((prevState: any) => [...prevState, v])
          })
          setRender(render + 1)
        }
      } else if (data?.current_user) {
        if (data?.current_user) {
          if (!Array.isArray(data?.current_user)) {
            setUserNIP([data?.current_user])
            setRender(render + 1)
          } else {
            data.current_user.forEach((v: any, i: number) => {
              setUserNIP((prevState: any) => [...prevState, v])
            })
            setRender(render + 1)
          }
        }
      }
    }

    useEffect(() => {
      if(data.current_approval_user || data.current_user) {
        getUserDetail()
      }
    },[data]);

    return (
      <div key={"render-user-"+render}>
        {usersNIP.map((v: any, i: number) => {
          return (
            <div key={'user-nip-'+v?.uuid +'-'+i}>
              <BadgeUserNIP nip={v} />
            </div>
          )
        })}

      </div>
    )
  }


  const tableColumns = [
    // {
    //   Header: 'Action',
    //   className: 'text-center',
    //   Cell: ({ row }: any) => (
    //     <>
    //       <Button size={"sm"} type={"button"} variant={"secondary"} className={"text-center"}>
    //         <i className={"fa fa-trash"}></i>
    //       </Button>
    //     </>
    //   )
    // },
    { Header: "Nomor PR", accessor: "no", width: 150, className: "text-center" },  
    { Header: 'Short Text', accessor: 'short_text', width: 250 },
    { Header: "Doc Type", accessor: "document_type_id", width: 100, className: "text-center" },
    { Header: 'Source PR', accessor: 'source_pr', className: 'text-center', width: 100, Cell: (props: any) => {
      const data = props.cell.row.original
        return data?.source
      }
    },
    { Header: "Status", accessor: "status", width: 100, className: "text-center", Cell: (props: any) => {
      const data = props.cell.row.original
        let badge = "badge-light-danger"
        if(data.status === 'SUBMITTED')
          badge = "badge-light-primary"
        else if(data.status === 'OPEN')
          badge = "badge-light-success"

        return (<span className={`badge ${badge}`}>{data.status}</span>)
      }
    },    
    { Header: "Line Item", accessor: "line_no", width: 100, className: "text-center" },
    { Header: "A", accessor: "account_assignment_category", width: 50, className: "text-center" },
    {Header: 'I', accessor: 'item_category', width: 100, Cell: (props: any) => {
      const data = props.cell.row.original
        return data?.purchaseRequisitionItem?.item_category
      }
    },
    {Header: 'Material Number', accessor: 'material_id', width: 100},
    {
      Header: 'Srv. Line Item',
      accessor: 'service_line.service_no',
      width: 250,
      Cell: (props: any) => {
        const data = props.cell.row.original
        return (
          <>
            {data.service_line?.map((v: any, i: number) => {
              return (
                <div key={i}>
                  {stringPadZero(v.line_no)} - {v.short_text}
                </div>
              )
            })}
          </>
        )
      }
    },  
    {Header: 'Qty', accessor: 'quantity_text', className: 'text-end', width: 200, Cell: (props: any) => {
      const data = props.cell.row.original
        return formatCurrency(data.quantity)
      }
    },
    {Header: 'Unit', accessor: 'uom_id', width: 50, className: 'text-center'},
    {Header: 'Harga Satuan', accessor: 'valuation_price_text', className: 'text-end', width: 200, Cell: (props: any) => {
      const data = props.cell.row.original
        return formatCurrency(data.valuation_price)
      }},
    {Header: 'Currency', accessor: 'currency_id', width: 150, className: 'text-center'},
    {Header: 'Per', accessor: 'per', className: 'text-end', width: 200, Cell: (props: any) => {
      const data = props.cell.row.original
        return formatCurrency(data.per)
      }
    },    
    {Header: 'Total Harga', accessor: 'total_value_text', width: 200, className: 'text-end', Cell: (props: any) => {
      const data = props.cell.row.original
        return formatCurrency(data.total_value ?? '0')
      }},
    {Header: 'Delivery Date', accessor: 'delivery_date', width: 150, className: 'text-center'},
    {Header: 'Material Group', accessor: 'material_group_id', width: 150, className: 'text-center'},
    {Header: 'Plant', accessor: 'plant_id', width: 100, className: 'text-center'},
    {Header: 'Sloc', accessor: 'sloc', width: 100, className: 'text-center', Cell: (props: any) => {
      const data = props.cell.row.original
        return data?.purchaseRequisitionItem?.storage_location
      }
    },
    {Header: 'Purchasing Group', accessor: 'purchasing_group_id', width: 250},
    {Header: 'Requisitioner', accessor: 'requisitioner', width: 150, className: 'text-center'},
    {Header: 'Track. No', accessor: 'tracking_number', width: 150, className: 'text-center'},
    {Header: 'No. Contract', accessor: 'agreement_id', width: 150, className: 'text-center'},
    {Header: 'Item Contract', accessor: 'agreement_item_id', width: 150, className: 'text-center'},
    {Header: 'Current User', accessor: 'current_user', width: 300, className: 'text-center', Cell: (props: any) => {
        return <BadgeUser data={props.cell.row.original} />
      }},
  ];
  return (
    <>
      <KTCard>
        <KTCardBody className="py-4">

              <TableXWrpper
                key={tableIndex}
                readonly={true}
                title={"Proposal Tender"}
                urlIndex={urlIndex}
                columns={tableColumns}
                isForceCreateButton={true}
                defaultFilter={"filter[status]=SUBMITTED&filter[source]=buyer_disposisi"}
                urlPageCreate={"/tender/tahapan_proposal-tender/create"}
                disableUrlChanged={true}
                onSelectedRows={(e: any) => {
                  setSelectedRows(e);
                }}
                headerButtons={
                  <ButtonToolbarPRIncoming
                    selectedRows={selectedRows}
                    afterSubmit={()=>{
                      setTableIndex(tableIndex+1)
                    }}
                    afterSync={()=>{setTableIndex(tableIndex+1)}}
                  />
                }
              />
        </KTCardBody>
      </KTCard>
    </>
  );
};

export { PurchasingRequisitionIncomingList };
