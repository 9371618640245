import {getEnv} from '../../../../../../helpers/procurex'
import React, {createContext, useContext, useEffect, useState} from 'react'
import {useMutation, useQuery} from 'react-query'
import axios from 'axios'
import {toast} from 'react-toastify'
import {useNavigate, useParams} from 'react-router-dom'
import {useLocation} from 'react-router'
import {useAuth} from '../../../../auth'

const API_URL = getEnv('tender')

export interface ToolbarInterface {
  show: boolean;
  currentTab: number;
}

//region context
const ProposalTenderContext = createContext({
  proposalTender: null as any,
  proposalTenderUuid: null as string,
  isLoadingProposalTender: false,
  toolbarEvaluasi: {
    show: false,
    currentTab: 0,
  } as ToolbarInterface,
  refetchProposalTender: () => {
  },
  setToolbarEvaluasi: () => {
  },
  tabActive: 'proposal',
  setTabActive: () => {
  },
} as any)

const ProposalTenderProvider = ({children}: any) => {
  const [proposalTender, setProposalTender] = useState(null)
  const [isLoadingProposalTender, setIsLoadingProposalTender] = useState(false)
  const [proposalTenderUuid, setProposalTenderUuid] = useState('')
  const [tabActive, setTabActive] = useState('proposal')
  const {refetchMenu} = useAuth()
  const [toolbarEvaluasi, setToolbarEvaluasi] = useState({
    show: false,
    currentTab: 0,
  } as ToolbarInterface)
  const {uuid} = useParams()
  const location = useLocation();

  const {refetch: refetchProposalTender, isLoading} = useQuery({
    enabled: false,
    queryKey: 'proposalTenderDetail',
    queryFn: () => axios.get(API_URL + `/tender/${uuid}`),
    onSuccess: (res) => {
      setProposalTender(res?.data?.data)
      refetchMenu()
    },
  })

  useEffect(() => {
    if (!uuid) return
    refetchProposalTender().then(r => {
    })
    setProposalTenderUuid(uuid)
  }, [uuid])


  useEffect(() => {
    if(!uuid) return
    const allowedShowEvaluasiOn = [
      `/tender/admin/proposal-tender/detail/${uuid}/proses-tender/evaluasi`,
      `/tender/admin/proposal-tender/detail/${uuid}/proses-tender/evaluasi-komersil`,
    ];
    if(allowedShowEvaluasiOn.includes(location.pathname)){
      setToolbarEvaluasi({
        show: true,
        currentTab: 0,
      })
    } else {
      setToolbarEvaluasi({
        show: false,
        currentTab: 0,
      })
    }
  }, [uuid,location])


  return (
    <ProposalTenderContext.Provider value={{
      proposalTender,
      proposalTenderUuid,
      refetchProposalTender,
      isLoadingProposalTender,
      toolbarEvaluasi,
      setToolbarEvaluasi,
      tabActive,
      setTabActive,
    }}>
      {children}
    </ProposalTenderContext.Provider>
  )
}

const useProposalTender = () => {
  return useContext(ProposalTenderContext)
}
//endregion

export {
  ProposalTenderProvider,
  useProposalTender,

}

