import { Field, Form, Formik } from 'formik'
import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { Link, useNavigate, useParams } from 'react-router-dom'
import SelectX from '../../../../../components/shared/form/SelectX'
import { ApiUrl } from '../../../../../config/Api'
import { getEnv } from "../../../../../../helpers/procurex";
import { InputNumber } from "rsuite";
import DatepickerX2 from "../../../../../components/shared/form/DatepickerX2";
import { useAuth } from "../../../../../../app/modules/auth";
import Swal from "sweetalert2";
import axios from 'axios'
import { useIntl } from "react-intl";

interface CreateProps {
    backUrl?: string
}
const CreateSafetyStock = ({ backUrl }: CreateProps) => {

    const { currentUser } = useAuth();

    const initialValues = {
        material_number: '',
        plant: '',
        company_code: '',
        uom: '',
        safety_stock: '0',
        end_validity_date: '',
        start_validity_date: '',
    }

    const intl = useIntl();
    const refFormik = React.useRef<any>();
    const [itemsMasterMaterial, setItemsMasterMaterial] = useState<any>([])
    const [itemsMasterCompany, setItemsCompanyStock] = useState<any>([])
    const [ItemsPlant, setItemsPlant] = useState<any>([])
    const [itemLoading, setItemLoading] = useState(false)

    const formSchemaValidation = Yup.object().shape({
        material_number: Yup.string().required('Material Number is required'),
        company_code: Yup.string().required('Company Code is required'),
        safety_stock: Yup.number()
            .required('Safety Stock is required')
            .min(0, 'Safety Stock must be greater than or equal to 0'),
        start_validity_date: Yup.date().required('Start Validity Date is required'),
        end_validity_date: Yup.date().required('End Validity Date is required')
    });

    const loadItem = () => {
        axios
            .get(getEnv("stockview") + `/master-material`)
            .then((res) => {
                setItemsMasterMaterial(res.data.data)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setItemLoading(false)
            })
        axios
            .get(getEnv("stockview") + `/company-stock`)
            .then((res) => {
                setItemsCompanyStock(res.data.data)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setItemLoading(false)
            })
        axios
            .get(getEnv("stockview") + `/material-plant-all?filter[active_app]=X&company_code=${company_code_user_first}`)
            .then((res) => {
                setItemsPlant(res.data.data)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setItemLoading(false)
            })
    }

    useEffect(() => {
        loadItem();
    }, [])

    const [formData, setFormData] = React.useState([]);

    const [loading, setLoading] = useState(true)
    const [isLoading, setIsLoading] = useState(false);
    const [key, setKey] = useState(0)

    const [totalPR, setTotalPR] = useState(0);
    const [SafetyStock, setSafetyStock] = useState(0);
    const [totalContract, setTotalContract] = useState(0);

    const handleOnTotalPRChange = (score: number) => {
        setTotalPR(score)
    }
    const handleOnTotalContractChange = (score: number) => {
        setTotalContract(score)
    }
    const handleOnSafetyStockChange = (score: number) => {
        setSafetyStock(score)
    }

    const [selectedMaterial, setSelectedMaterial] = useState(null);
    const [selectedPlant, setSelectedPlant] = useState(null);
    const [selectedCompany, setSelectedCompany] = useState(null);

    const handleMaterialSelect = (selectedValue: any) => {
        setSelectedMaterial(selectedValue);
    };

    const handlePlantSelect = (selectedValue: any) => {
        setSelectedPlant(selectedValue);
    };

    const handleCompanySelect = (selectedValue: any) => {
        setSelectedCompany(selectedValue);
    };

    const TableRow = ({ no,
        material_number,
        plant,
        company_code,
        safety_stock,
        uom,
        start_validity_date,
        end_validity_date,
        onDelete, ...props }: any) => {
        return (
            <tr>
                <td className={"border text-center"}>{no}</td>
                <td className={"border"}>{material_number}</td>
                <td className={"border"}>{plant}</td>
                <td className={"border"}>{company_code}</td>
                <td className={"border"}>{safety_stock}</td>
                <td className={"border"}>{uom}</td>
                <td className={"border"}>{start_validity_date}</td>
                <td className={"border"}>{end_validity_date}</td>
                <td className={"border text-center"}>
                    <button className={"btn btn-sm btn-secondary"} onClick={() => onDelete()}>
                        <i className={"fa fa-trash text-danger"}></i>
                    </button>
                </td>
            </tr>
        );
    };

    const [formindex, setFormIndex] = useState(0)

    const navigate = useNavigate();

    const handleBack = async () => {
        Swal.fire({
            title: `Data Belum Tersimpan`,
            html: `Apakah Anda Yakin Ingin Keluar ?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: `Ya, Keluar!`,
            confirmButtonColor: "#999999",
            cancelButtonColor: "#258141",
            cancelButtonText: `Cancel`,
        }).then((result) => {
            if (result.isConfirmed) {
                navigate('/stockview/transaction/safety-stock')
            }
        })
    }

    const handleBackClick = () => {
        if (formData.length !== 0) {
            handleBack();
        } else {
            navigate('/stockview/transaction/safety-stock');
        }
    };

    const handleAddRow = async (values: any) => {
        const newData = {
            material_number: values.material_number,
            company_code: values.company_code,
            plant: values.plant,
            safety_stock: values.safety_stock,
            uom: values.material_number ? 'TON' : '',
            start_validity_date: values.start_validity_date,
            end_validity_date: values.end_validity_date,
        };
        setFormData([...formData, newData]);

        setFormIndex(formindex + 1)
        // @ts-ignore

    };

    const handleDeleteRow = async (index: number) => {
        const newData = formData.filter((item: any, i: number) => i !== index);
        // @ts-ignore
        setFormData(newData);
    };

    const handleSubmit = async () => {
        setLoading(true)
        try {
            const dataListResponse = await axios.get(`${getEnv("stockview")}/safety-stock?company_code=${company_code_user}&jenis_api=${jenis_api}`);

            if (dataListResponse.status !== 200) {
                throw new Error('Failed to fetch data list');
            }

            const dataList = dataListResponse.data.data;

            const requests = [];

            for (const data of formData) {
                const trimmedMaterialNumber = data.material_number.trim();
                const trimmedData = { ...data, material_number: trimmedMaterialNumber };

                const existingRecords = dataList.filter(item =>
                    item.material_number === trimmedData.material_number &&
                    item.company_code === trimmedData.company_code &&
                    item.plant === trimmedData.plant &&
                    item.start_validity_date === trimmedData.start_validity_date
                );

                if (existingRecords.length > 0) {
                    const [firstRecord, ...remainingRecords] = existingRecords;

                    // Update the first record
                    const updateData = {
                        ...trimmedData,
                        id: firstRecord.id
                    };

                    requests.push(axios.put(`${getEnv("stockview")}/safety-stock/${firstRecord.id}`, updateData));

                    // Delete the remaining records
                    for (const record of remainingRecords) {
                        requests.push(axios.delete(`${getEnv("stockview")}/safety-stock/${record.id}`));
                    }
                } else {
                    requests.push(axios.post(`${getEnv("stockview")}/safety-stock`, formData));
                }
            }

            const responses = await Promise.all(requests);

            responses.forEach(response => {
                if (response.status === 200 || response.status === 201) {
                    toast.success(response?.status + ': ' + response?.data.meta.message, {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'light',
                    });
                }
            });
            // const response = await axios.post(getEnv("stockview") + `/safety-stock`, data)

            // if (response.status === 200 || response.status === 201) {
            //     toast.success(response?.status + ': ' + response?.data.meta.message, {
            //         position: 'top-right',
            //         autoClose: 5000,
            //         hideProgressBar: false,
            //         closeOnClick: true,
            //         pauseOnHover: true,
            //         draggable: true,
            //         progress: undefined,
            //         theme: 'light',
            //     })
            // }
            // setKey(key + 1)
        } catch (error: any) {
            console.error(error)
            toast.error(error?.data?.meta?.message || 'Terjadi kesalahan', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            })
        } finally {
            setLoading(false)
        }
    }

    let company_code_user = currentUser.company_code;
    let company_code_user_first = currentUser.company_code[0];
    let jenis_api = "list";

    return (
        <Formik
            key={formindex}
            innerRef={refFormik}
            initialValues={initialValues}
            onSubmit={handleAddRow}
            validationSchema={formSchemaValidation}
            enableReinitialize
            validateOnChange={true}
        >
            {({ errors, touched, values, setFieldValue }: any) => (
                <Form>
                    <div className='d-flex justify-content-between mb-6'>
                        <div>
                            <button className='btn btn-secondary btn-sm fw-bold' onClick={handleBackClick}>
                                Back To list
                            </button>
                        </div>
                        <div className='d-flex gap-4'>
                            <button className={"btn btn-primary btn-sm"} disabled={isLoading}
                                onClick={() => handleSubmit()}>
                                {isLoading && <i className={"fa fa-spinner fa-spin"}></i>}
                                {!isLoading && <i className={"fa fa-save"}></i>}
                                {intl.formatMessage({ id: "Simpan" })}
                            </button>
                        </div>
                    </div>

                    <div className='card my-5'>
                        <div className='card-header d-flex align-items-center'>
                            <b>FORM ADD SAFETY STOCK</b>
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <div className='mb-6'>
                                        <label htmlFor='' className='required form-label'>
                                            Material Number
                                        </label>
                                        <SelectX
                                            onChange={(e: any) => setFieldValue('material_number', e)}
                                            options={itemsMasterMaterial.map((item: any) => ({
                                                label: `${item.material_number} - ${item.material_desc}`,
                                                value: item.material_number
                                            }))}
                                        />
                                        {(errors.material_number && touched.material_number) && (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{errors.material_number}</div>
                                            </div>
                                        )}
                                    </div>
                                    <div className='mb-6'>
                                        <label htmlFor='' className='form-label'>
                                            Plant
                                        </label>
                                        <SelectX
                                            onChange={(e: any) => setFieldValue('plant', e)}
                                            options={ItemsPlant.map((item: any) => ({
                                                label: `${item.kode_plant} - ${item.nama_plant}`,
                                                value: item.kode_plant
                                            }))}
                                        />
                                        {(errors.plant && touched.plant) && (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{errors.plant}</div>
                                            </div>
                                        )}
                                    </div>
                                    <div className='mb-6'>
                                        <label htmlFor='' className='required form-label'>
                                            Company Code
                                        </label>
                                        <SelectX
                                            onChange={(e: any) => setFieldValue('company_code', e)}
                                            options={itemsMasterCompany
                                                .filter((item: any) => {
                                                    if (company_code_user === "A000") {
                                                        return item.company_code !== "A000";
                                                    } else {
                                                        return item.company_code !== "A000" && item.company_code === company_code_user;
                                                    }
                                                })
                                                .map((item: any) => ({
                                                    label: item.company_code,
                                                    value: item.company_code
                                                }))
                                            }
                                        />
                                        {(errors.company_code && touched.company_code) && (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{errors.company_code}</div>
                                            </div>
                                        )}
                                    </div>
                                    <div className='mb-6'>
                                        <label htmlFor='' className='required form-label'>
                                            Safety Stock
                                        </label>
                                        <InputNumber
                                            defaultValue={0}
                                            min={0}
                                            name="safety_stock"
                                            className={"w-100 text-end"}
                                            onChange={(e: any) => setFieldValue('safety_stock', e)}
                                        />
                                        {(errors.safety_stock && touched.safety_stock) && (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{errors.safety_stock}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='mb-6'>
                                        <label htmlFor='' className='form-label'>
                                            Uom
                                        </label>
                                        <Field className='form-control' name="uom" value={values.material_number ? 'TON' : ''} disabled />
                                    </div>
                                    <div className='mb-6'>
                                        <label htmlFor='' className='required form-label'>
                                            Start Validity Date
                                        </label>
                                        <DatepickerX2
                                            onChange={(date: any) => setFieldValue("start_validity_date", date)}
                                            size={"lg"}
                                            value={values.start_validity_date}
                                            format={"dd-MM-yyyy"}
                                        />
                                        {(errors.start_validity_date && touched.start_validity_date) && (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{errors.start_validity_date}</div>
                                            </div>
                                        )}
                                    </div>
                                    <div className='mb-6'>
                                        <label htmlFor='' className='required form-label'>
                                            End Validity Date
                                        </label>
                                        <DatepickerX2
                                            onChange={(date: any) => setFieldValue("end_validity_date", date)}
                                            size={"lg"}
                                            value={values.end_validity_date}
                                            format={"dd-MM-yyyy"}
                                        />
                                        {(errors.end_validity_date && touched.end_validity_date) && (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{errors.end_validity_date}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='d-flex justify-content-end gap-4'>
                                    <button type={"submit"} className={"btn btn-primary btn-sm fw-bold"}>
                                        {isLoading && <i className={"fa fa-spinner fa-spin"}></i>}
                                        {!isLoading && <i className={"fa fa-plus"}></i>}
                                        {intl.formatMessage({ id: "Tambah" })}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"col-md-12 mt-5"}>
                        <table className={"table table-bordered"}>
                            <thead>
                                <tr>
                                    <th className={"border text-center"}><strong>{intl.formatMessage({ id: "No" })}</strong></th>
                                    <th className={"border text-center"}><strong>{intl.formatMessage({ id: "Material Number" })}</strong></th>
                                    <th className={"border text-center"}><strong>{intl.formatMessage({ id: "Plant" })}</strong></th>
                                    <th className={"border text-center"}><strong>{intl.formatMessage({ id: "Company Code" })}</strong></th>
                                    <th className={"border text-center"}><strong>{intl.formatMessage({ id: "Safety Stock" })}</strong></th>
                                    <th className={"border text-center"}><strong>{intl.formatMessage({ id: "uom" })}</strong></th>
                                    <th className={"border text-center"}><strong>{intl.formatMessage({ id: "Start Validity Date" })}</strong></th>
                                    <th className={"border text-center"}><strong>{intl.formatMessage({ id: "End Validity Date" })}</strong></th>
                                    <th className={"border text-center"}><strong>{intl.formatMessage({ id: "Aksi" })}</strong></th>
                                </tr>
                            </thead>
                            <tbody>
                                {formData.length === 0 && (
                                    <tr>
                                        <td colSpan={9} className={"text-center"}>
                                            <i>{intl.formatMessage({ id: "Tidak ada data" })}</i>
                                        </td>
                                    </tr>
                                )}
                                {formData.map((item: any, index: number) => {
                                    return (
                                        <TableRow
                                            key={"adm-" + index}
                                            no={index + 1}
                                            material_number={item.material_number}
                                            plant={item.plant}
                                            company_code={item.company_code}
                                            safety_stock={item.safety_stock}
                                            uom={item.uom}
                                            start_validity_date={item.start_validity_date}
                                            end_validity_date={item.end_validity_date}
                                            onDelete={() => handleDeleteRow(index)}
                                        />
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export { CreateSafetyStock }