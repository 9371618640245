import React, {FC, useEffect, useMemo, useRef, useState} from 'react'
import {KTCardBody} from '../../../_metronic/helpers'
import {Button, ButtonGroup, Dropdown, Modal} from 'react-bootstrap'
import * as XLSX from 'xlsx'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import copy from 'clipboard-copy'
import {toast} from 'react-toastify'

import {
  useBlockLayout,
  useFlexLayout,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import {useIntl} from 'react-intl'
import FormX from './form/FormX'
import {useNavigate} from 'react-router-dom'
import { darkModeClass } from "../../../helpers/utils";

export interface IColumns {
  Header: any
  accessor?: any
  name?: string
  Cell?: any
  width?: number | string
  searchable?: boolean
  orderable?: boolean
  disableCreate?: boolean
  disableEdit?: boolean
  disableSortBy?: boolean
  value?: any
  type?: string
  fixed?: string
  options?: any
  className?: any
}

export interface IMeta {
  perPage: number
  per_page?: number
  currentPage: number
  total: number
}

type Props = {
  title?: string
  data: any
  meta: IMeta
  columns: Array<any>
  isLoading: boolean
  onRefresh?: any
  onRowDoubleClick?: any
  showCreateButton?: boolean
  onCreate?: any
  onSelectedRows?: any
  disableSearchInputAutoFocus?: boolean
  defaultPerPage?: number
  disableUrlChanged?: boolean
  hideSearch?: boolean
  hideShowPagination?: boolean
  hideExportAs?: boolean
  tableButton?: any
  hideSelection?: boolean
  headerButtons?: any
  subHeaderButtons?: any
  showMigrationVendor?: boolean
  disablePaddingCard?: boolean
  onSort?: Function
  columnSort?: string
  columnSortIsDesc?: boolean
  maxHeight?: String


  //checkbox condition
  checkboxConditionDisabled?: Function
  errorMessageConditionDisabled?: string,
  hasAnySearch?: boolean,
  checkboxAllDisabled?: boolean,

}

const TableX: FC<Props> = (props) => {
  const {
    columns,
    data,
    meta,
    title,
    isLoading,
    onRefresh,
    showCreateButton,
    onCreate,
    onSelectedRows,
    defaultPerPage,
    disableUrlChanged,
    headerButtons,
    disablePaddingCard,
    maxHeight
  } = props
  const disableSearchInputAutoFocus = props.disableSearchInputAutoFocus ?? false
  const intl = useIntl()
  const navigate = useNavigate()
  const [searchDropdownList, setSearchDropdownList] = useState([] as any)
  const searchRef = useRef(null)
  const [perPage, setPerPage] = useState(defaultPerPage || (meta ? meta.perPage : 25))
  const [searchSelectField, setSearchSelectField] = useState('')
  const [inputSearch, setInputSearch] = useState('')
  const [searchTags, setSearchTags] = useState([])
  const [formAdvanceSearch, setFormAdvanceSearch] = useState([])
  const [isModalSelectFieldOpen, setIsModalSelectFieldOpen] = useState(false)
  const [isModalAdvanceSearchOpen, setIsModalAdvanceSearchOpen] = useState(false)
  const [paginationData, setPaginationData] = useState([])
  const [currentPage, setCurrentPage] = useState(meta.currentPage)
  const [cursor, setCursor] = useState(0)
  const [isMounted, setIsMounted] = useState(false)
  const [copied, setCopied] = useState(false)
  const defaultColumn = useMemo(
    () => ({
      // When using the useFlexLayout:
      minWidth: 80, // minWidth is only used as a limit for resizing
      width: 180, // width is used for both the flex-basis and flex-grow
      maxWidth: 1024, // maxWidth is only used as a limit for resizing
    }),
    []
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
    getToggleHideAllColumnsProps,
    selectedFlatRows,
    toggleAllRowsSelected,
    toggleRowSelected,
    resetResizing,
    state: {selectedRowIds},
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useSortBy,
    usePagination,
    useBlockLayout,
    useResizeColumns,
    useFlexLayout,
    useRowSelect,
    (hooks) => {
      if(props.hideSelection !== true) {
        hooks.allColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: 'selection',
            disableResizing: true,
            minWidth: 50,
            width: 50,
            maxWidth: 1024,
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div className={'px-0'} style={{
                left: '0',
              }}>
                <input
                  className={'w-20px h-20px'}
                  style={{ borderColor: `#fff`, borderRadius: '4px'}}
                  type={'checkbox'}
                  checked={getToggleAllRowsSelectedProps().checked ? true : false}
                  // onChange={getToggleAllRowsSelectedProps().onChange}
                  // disabled={props.checkboxConditionDisabled ? true : false}
                  onChange={(e) => {
                    const { checked } = e.target;
                    const allRows = rows;
                    if(props.checkboxConditionDisabled) {
                      //@ts-ignore
                      const openRows = allRows.filter(row => {
                        const isEnable = props.checkboxConditionDisabled(row.original);
                        if(isEnable) return row;
                        return ;
                      });
                      openRows.forEach(row => row.toggleRowSelected(checked));
                    } else {
                      // allRows.forEach(row => row.toggleRowSelected(checked));
                      toggleAllRowsSelected(checked);
                    }
                  }}
                  disabled={props.checkboxAllDisabled === true ? true : false}
                />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({row, selectedFlatRows}) => {
              const isEnabled = props.checkboxConditionDisabled && props.checkboxConditionDisabled(
                row.original,
                selectedFlatRows.map((row: any) => row.original)
              )

              return (
                <div className={'form-check-sm text-center'}>
                  {props.checkboxConditionDisabled && (
                    <>
                      {!isEnabled && (
                        <input
                          className={'form-check-input border-primary bg-secondary'}
                          type={'checkbox'}
                          checked={row.getToggleRowSelectedProps().checked}
                          disabled={true}
                          onChange={(e: any) => {
                            toast.error(props.errorMessageConditionDisabled)
                            return false
                          }}
                        />
                      )}
                      {isEnabled && (
                        <input
                          className={'form-check-input border-primary'}
                          type={'checkbox'}
                          checked={row.getToggleRowSelectedProps().checked}
                          onChange={row.getToggleRowSelectedProps().onChange}
                        />
                      )}
                    </>
                  )}
                  {!props.checkboxConditionDisabled && (
                    <input
                      className={'form-check-input border-primary'}
                      type={'checkbox'}
                      checked={row.getToggleRowSelectedProps().checked}
                      // defaultChecked={true}
                      onChange={row.getToggleRowSelectedProps().onChange}
                      // disabled={props.checkboxConditionDisabled ? !props.checkboxConditionDisabled(row.original) : false}
                    />
                  )}
                </div>
              )
            },
          },
          ...columns,
        ])
        hooks.useInstanceBeforeDimensions.push(({ headerGroups }) => {
          // fix the parent group of the selection button to not be resizable
          const selectionGroupHeader = headerGroups[0].headers[0];
          selectionGroupHeader.canResize = false;
        });
      }

    }
  )

  useEffect(() => {
    if (onSelectedRows) {
      onSelectedRows(selectedFlatRows);
    }
  }, [selectedRowIds]);

  const exportToExcel = async () => {
    let arrKey = []
    let objs: any[] = []
    if (selectedFlatRows.length) {
      for (let key = 0; key < headerGroups[0].headers.length; key++) {
        if (key != 0 && headerGroups[0].headers[key].Header != 'Action') {
          arrKey.push(headerGroups[0].headers[key].Header)
        }
      }
      for (let idx = 0; idx < selectedFlatRows.length; idx++) {
        let arrVal: string[] = []
        let obj: any = {}
        let finalResult: any
        for (let index = 0; index < selectedFlatRows[idx].cells.length; index++) {
          if (index != 0 && selectedFlatRows[idx].cells[index].column.Header != 'Action') {
            if (
              typeof selectedFlatRows[idx].cells[index].value === 'object' &&
              selectedFlatRows[idx].cells[index].value != null
            ) {
              if (selectedFlatRows[idx].cells[index].value.employee) {
                finalResult = selectedFlatRows[idx].cells[index].value.employee.pos_kategori
              } else {
                finalResult = '-'
                if (selectedFlatRows[idx].cells[index].column.Header === 'Current User') {
                  if (Array.isArray(selectedFlatRows[idx].cells[index].value)) {
                    finalResult = ''
                    selectedFlatRows[idx].cells[index].value.forEach((val: any) => {
                      finalResult += val?.emp_no + ' - ' + val?.nama + ', '
                    })
                  }
                }
              }
            } else {
              finalResult = selectedFlatRows[idx].cells[index].value
            }
            arrVal.push(finalResult)
            arrKey.forEach((val, index) => {
              obj[String(val)] = arrVal[index]
            })
          }
        }
        objs.push(obj)
      }
    }
    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(objs)
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
    XLSX.writeFile(workbook, 'selected-data.xlsx')
  }

  const exportToPdf = async () => {
    let arrKey = []
    let objs: any[] = []
    if (selectedFlatRows.length) {
      for (let key = 0; key < headerGroups[0].headers.length; key++) {
        if (key != 0 && headerGroups[0].headers[key].Header != 'Action') {
          arrKey.push(headerGroups[0].headers[key].Header)
        }
      }

      for (let idx = 0; idx < selectedFlatRows.length; idx++) {
        let arrVal: string[] = []
        let obj: any = {}
        let finalResult: any
        for (let index = 0; index < selectedFlatRows[idx].cells.length; index++) {
          if (index != 0 && selectedFlatRows[idx].cells[index].column.Header != 'Action') {
            if (
              typeof selectedFlatRows[idx].cells[index].value === 'object' &&
              selectedFlatRows[idx].cells[index].value != null
            ) {
              if (selectedFlatRows[idx].cells[index].value.employee) {
                finalResult = selectedFlatRows[idx].cells[index].value.employee.pos_kategori
              } else {
                finalResult = '-'
              }
            } else {
              finalResult = selectedFlatRows[idx].cells[index].value
            }
            arrVal.push(finalResult)
            arrKey.forEach((val, index) => {
              obj[String(val)] = arrVal[index]
            })
          }
        }
        objs.push(obj)
      }

      let objKeys: any[] = []
      arrKey.forEach((val, index) => {
        let objKey: any = {}
        objKey[String('title')] = val
        objKey[String('field')] = val
        objKeys.push(objKey)
      })

      const doc = new jsPDF('l', 'mm', [297, 210])
      autoTable(doc, {
        columns: objKeys.map((col) => ({...col, dataKey: col.field})),
        body: objs,
      })

      doc.save('selected-data.pdf')
    }
  }

  const handleCopy = () => {
    const headerCopy = headerGroups
      .map((row) =>
        row.headers
          .map((val) =>
            typeof val.Header === 'string' && val.Header != 'Action' ? val.Header : ''
          )
          .join('\t')
      )
      .join('\n')

    const bodyCopy = selectedFlatRows
      .map((row) =>
        row.cells
          .map((cell) =>
            typeof cell.value === 'object' && cell.value != null
              ? cell.value.employee
                ? cell.value.employee.pos_kategori
                : '-'
              : cell.column.Header != 'Action'
              ? cell.value
              : ''
          )
          .join('\t')
      )
      .join('\n')

    copy(`${headerCopy} \t\n ${bodyCopy}`)

    setCopied(true)

    toast.success('Successfully copied to clipboard', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    })

    setTimeout(() => {
      setCopied(false)
    }, 1000)
  }

  const onSelectSearch = (row: any, query: string) => {
    setCurrentPage(1)
    setSearchTag(row.accessor, query)
  }

  const initFormAdvanceSearch = () => {
    // @ts-ignore
    let datas: any = []
    const optionCompany = {
      url: process.env.REACT_APP_API_URL + '/api/masterdata/companies',
      key: 'name',
      label: 'comp_code_name',
      return_only_id: true,
    }
    const optionStatus = {
      url:
        process.env.REACT_APP_API_URL +
        '/api/masterdata/enumeration?filter[type]=MR_SR_REQUEST_STATUS',
      key: 'value',
      label: 'value',
      return_only_id: true,
    }
    columns.map((v: any) => {
      if (window.location.href.includes('mr-sr/request')) {
        if (v.Header !== 'Action' && v.Header !== 'ID') {
          if (v.Header === 'company name') {
            datas.push({
              Header: v.Header,
              accessor: v.accessor,
              value: '',
              options: optionCompany,
              type: 'select2',
            })
          } else if (v.Header === 'status') {
            datas.push({
              Header: v.Header,
              accessor: v.accessor,
              value: '',
              options: optionStatus,
              type: 'select2',
            })
          } else {
            datas.push({
              Header: v.Header,
              accessor: v.accessor,
              value: '',
              placeholder:
                v.Header == 'Total' ? `Search Total / Total A - Total B` : `Search for ${v.Header}`,
            })
          }
        }
      } else if (v.searchable !== false) {
        datas.push({
          Header: v.Header,
          accessor: v.accessor,
          value: '',
          placeholder: `Search for ${v.Header}`,
        })
      }
    })
    // @ts-ignore
    setFormAdvanceSearch(datas)
  }
  const generateSearchQuery = () => {
    // @ts-ignore
    let queries = []
    searchTags.map((v: any) => {
      if (v.query) {
        let query = v.query.replace(/\s+$/, '')
        let querySplit = query.split('_or_')
        querySplit = querySplit.filter((v: any) => v !== '')
        querySplit = querySplit.map((item: string) => item.trim())
        let searchRow = {
          field: v.name || v.accessor,
          type: v.type || 'text',
          query: querySplit,
        }
        queries.push(searchRow)
      }
    })

    let serialize = ''
    // @ts-ignore
    queries.map((v: any, index) => {
      if(props.hasAnySearch) {
        if(v.field === "action" || v.field === "aksi"){
          serialize += `&filter[any]=`
        } else {
          serialize += `&filter[${v.field}]=`
        }
      } else {
        serialize += `&filter[${v.field}]=`
      }

      v.query.map((x: any) => {
        serialize += `${x},`
      })
      serialize = serialize.slice(0, -1)
    })
    // console.log(serialize)
    return serialize
  }
  const handleKeySearch = (e: any) => {
    const col = columns.filter((v) => v.searchable !== false)
    if (e.keyCode === 38 && cursor > 0) {
      // ketika keydown
      if (cursor > 0) {
        setTimeout(() => {
          setCursor(cursor - 1)
        }, 150)
      }
    } else if (e.keyCode === 40 && cursor < col.length - 1) {
      // ketika keyup
      setTimeout(() => {
        setCursor(cursor + 1)
      }, 150)
    } else if (e.keyCode === 13) {
      // ketika enter
      let accessor = col[cursor].accessor
      setTimeout(() => {
        setSearchTag(accessor, inputSearch)
      }, 150)
    } else if (e.keyCode === 27) {
      setSearchTags([])
      setInputSearch('')
    }
  }
  const onResetSearch = () => {
    initFormAdvanceSearch()
    setSearchTags([])
  }
  const setSearchTag = (accessor: string, query: string) => {
    const columnByAncessor = columns.filter((v: any) => v.accessor === accessor)[0]
    const tagsAvailable = searchTags.filter((v: any) => v.accessor === accessor)
    // console.log(tagsAvailable)
    const _record = {
      Header: columnByAncessor.Header,
      accessor: accessor,
      name: columnByAncessor.name || columnByAncessor.accessor,
      // @ts-ignore
      query: tagsAvailable.length <= 0 ? query : `${tagsAvailable[0].query} _or_ ${query}`,
      // @ts-ignore
      queryLabel: tagsAvailable.length <= 0 ? query : `${tagsAvailable[0].queryLabel} or ${query}`,
    }
    let currentTags = searchTags.filter((v: any) => v.accessor !== accessor)
    let currentFormAdvanceSearch = formAdvanceSearch
    currentFormAdvanceSearch.map((v: any, index: number) => {
      if (v.accessor === accessor) {
        // @ts-ignore
        currentFormAdvanceSearch[index].value = _record.query
      }
    })
    setFormAdvanceSearch(currentFormAdvanceSearch)
    // @ts-ignore
    currentTags.push(_record)
    setSearchTags(currentTags)
    setInputSearch('')
    setCursor(0)
  }
  const onDeleteSearch = async (row: any) => {
    const filteredItems = searchTags.filter((v) => v !== row)
    await setSearchTags(filteredItems)
  }
  const onFormAdvanceSearchChanged = (value: any) => {
    // setFormAdvanceSearch(value)
    // console.log(value)
  }
  const onSubmitAdvanceSearch = () => {
    // @ts-ignore
    let currentTags = []
    formAdvanceSearch.map((v: any) => {
      const columnByAncessor = columns.filter((x: any) => x.accessor === v.accessor)
      // @ts-ignore
      const tagsAvailable = currentTags.filter((x: any) => x.accessor === v.accessor)
      const valueReplaceble = v.value ? v.value.replace(/_or_/g, 'or') : null
      let _record = {
        Header: columnByAncessor[0].Header,
        accessor: v.accessor,
        name: v.name || v.accessor,
        // @ts-ignore
        query:
          tagsAvailable.length <= 0
            ? valueReplaceble
            : `${tagsAvailable[0].query} _or_ ${valueReplaceble}`,
        // @ts-ignore
        queryLabel:
          tagsAvailable.length <= 0
            ? valueReplaceble
            : `${tagsAvailable[0].queryLabel} or ${valueReplaceble}`,
      }
      // @ts-ignore
      currentTags.push(_record)
    })
    // @ts-ignore
    setSearchTags(currentTags)
    setIsModalAdvanceSearchOpen(false)
    setInputSearch('')
  }
  const handleRefresh = () => {
    const serializeSearch = generateSearchQuery()
    if (disableUrlChanged !== true) {
      navigate({
        search: `?perPage=${perPage}&currentPage=${currentPage ?? 1}${serializeSearch}`,
      })
    }
    onRefresh(true, perPage, currentPage, serializeSearch)
  }

  const generateListSearchDropdown = () => {
    setSearchDropdownList(columns.filter((v: any) => v.searchable !== false && v.Header !== 'Action'))
  }

  const handleOnSort = (columnId: string, isDesc: boolean) => {
    const serializeSearch = generateSearchQuery()
    if (disableUrlChanged !== true) {
      navigate({
        search: `?perPage=${perPage}&currentPage=${currentPage ?? 1}${serializeSearch}`,
      })
    }
    props.onSort && props.onSort(true, perPage, currentPage, serializeSearch, columnId, isDesc)
  }

  const getSearchHeaderName = (name:string) => {
    if(props.hasAnySearch) {
      if(name === "Action" || name === "Aksi") {
        return "Any"
      }
    }
    return name;
  }

  useEffect(() => {
    let shiftPressed = false;
    initFormAdvanceSearch()
    generateListSearchDropdown()

    setTimeout(() => {
      setIsMounted(true)
    }, 1000)
    const handleKeyDown = (event: any) => {
      if (event.ctrlKey && event.key === 'l') {
        event.preventDefault()
        // @ts-ignore
        searchRef.current.focus()
      } else if (event.shiftKey && event.key === 'Shift') {
        // if (!shiftPressed) {
        //   shiftPressed = true;
        // } else {
        //   setInputSearch((prevInputSearch) => (prevInputSearch === ' ' ? '' : ' '));
        //   // @ts-ignore
        //   searchRef.current.focus()
        //   shiftPressed = false;
        // }
      } else if (event.key === 'Escape') {
        setInputSearch('')
      }
    }

    const handleKeyUp = (event:any) => {
      if (event.shiftKey && event.key === 'Shift') {
        shiftPressed = false;
      }
    };

    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('keyup', handleKeyUp);


    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('keyup', handleKeyUp);
    }
  }, [])

  useEffect(() => {
    if (isMounted) handleRefresh()
  }, [perPage, currentPage])

  useEffect(() => {
    initFormAdvanceSearch()
  }, [data])

  useEffect(() => {
    if (isMounted) handleRefresh()
  }, [searchTags])

  useEffect(() => {
    if (meta.total) {
      let rows = []
      let maxPage = Math.ceil(meta.total / meta.perPage)
      for (let i = 1; i <= maxPage; i++) {
        rows.push({
          key: i,
        })
      }
      // @ts-ignore
      setPaginationData(rows)
    }
  }, [meta])

  useEffect(() => {
    if(inputSearch.charAt(1) === '>' || inputSearch.charAt(0) === '>'){
      const field = inputSearch;
    } else {
      setSearchDropdownList(columns.filter((v: any) => v.searchable !== false && v.Header !== 'Action'))
    }
  }, [inputSearch])

  return (
    <KTCardBody className={'py-4 w-100' + (disablePaddingCard ? ' p-0':null)}>
      <div className={'w-100'}>
        <div className={'row mb-2'}>
          <div className={'col-md-6'}>
            {title && <h2>{title}</h2>}
            {headerButtons}
            {/*{JSON.stringify(*/}
            {/*    {*/}
            {/*        selectedRowIds: selectedRowIds,*/}
            {/*        'selectedFlatRows[].original': selectedFlatRows.map(*/}
            {/*            d => d.original*/}
            {/*        ),*/}
            {/*    },*/}
            {/*    null,*/}
            {/*    2*/}
            {/*)}*/}
          </div>
          <div className={'col-md-6'}>
            <div className={'d-flex flex-column justify-content-between'}>
              {props.hideSearch !== true && (
                <div className={'d-flex flex-row'}>
                  <div className={'flex-grow-1'}>
                    <div className={'d-flex flex-row flex-grow-1 flex-wrap border-bottom'}>
                      {searchTags.length > 0 && (
                        <div className={'d-flex flex-row flex-wrap'}>
                          {searchTags.map((v: any, index: number) => {

                            return v.query ? (
                              <div
                                key={`${v}-${index}`}
                                className={'d-flex align-items-center border pe-1 w-auto mt-1 me-1'}
                                style={{
                                  height: '30px',
                                }}
                              >
                                <div
                                  className={'bg-dark-search h-100 d-flex align-items-center px-2'}
                                >
                                  {getSearchHeaderName(v.Header)}
                                </div>
                                <div className={'px-2'}>{v.queryLabel}</div>
                                <a
                                  href="javascript:;"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    onDeleteSearch(v)
                                  }}
                                  className={'text-muted'}
                                >
                                  <i className={'fa fa-times text-muted'} />
                                </a>
                              </div>
                            ) : null
                          })}
                        </div>
                      )}
                      <div className={'flex-grow-1'}>
                        <input
                          placeholder={`${intl.formatMessage({id:'Pencarian'})}... (ctrl+l)`}
                          autoFocus={!disableSearchInputAutoFocus}
                          className={'form-control form-control-sm form-control-solid w-100'}
                          value={inputSearch}
                          onChange={(e) => {
                            setInputSearch(e.currentTarget.value)
                          }}
                          onKeyDown={handleKeySearch}
                          onKeyUp={handleKeySearch}
                          ref={searchRef}
                        />
                      </div>
                    </div>
                  </div>

                  <div className={'flex-grow-2 flex-nowrap'}>
                    <div className={'d-flex flex-row flex-nowrap'}>
                      <div className={'d-flex flex-row'}>
                        <div>
                          <Button
                            size={'sm'}
                            variant={'outline'}
                            type={'button'}
                            onClick={() => setIsModalAdvanceSearchOpen(true)}
                          >
                            <i className={`fa fa-search ${darkModeClass()}`} />
                          </Button>

                          <Modal
                            style={{zIndex: '9999'}}
                            size={'lg'}
                            show={isModalAdvanceSearchOpen}
                            onHide={() => setIsModalAdvanceSearchOpen(false)}
                          >
                            <Modal.Header closeButton>
                              <Modal.Title>
                                {intl.formatMessage({id: 'Pencarian Lanjutan'})}
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              {/*{JSON.stringify(formAdvanceSearch)}*/}
                              {formAdvanceSearch.length > 0 && (
                                <FormX
                                  title={''}
                                  fields={formAdvanceSearch}
                                  onChange={onFormAdvanceSearchChanged}
                                />
                              )}
                            </Modal.Body>
                            <Modal.Footer>
                              <Button
                                variant='secondary'
                                size={'sm'}
                                onClick={() => setIsModalAdvanceSearchOpen(false)}
                              >
                                <i className={'fa fa-times'}></i>
                                {intl.formatMessage({id: 'DATATABLE.CLOSE'})}
                              </Button>
                              <Button
                                variant='primary'
                                type={'button'}
                                size={'sm'}
                                onClick={() => onSubmitAdvanceSearch()}
                              >
                                <i className={'fa fa-search'}></i>
                                {intl.formatMessage({id: 'DATATABLE.SUBMIT'})}
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {inputSearch.length > 0 && (
                <div
                  className={'w-100'}
                  key={JSON.stringify(searchTags)}
                  style={{
                    zIndex: 9000,
                    width: '100%',
                  }}
                >
                  <div
                    className={'position-absolute bg-white p-3'}
                    style={{
                      width: '46%',
                    }}
                  >
                    <div className='list-group'>
                      {searchDropdownList.filter((is:any)=>is.Header !== 'Action').map((v: any, index: number) => {
                        return (
                          v.searchable !== false && (
                            <a
                              href="javascript:;"
                              key={`${inputSearch}-${index}`}
                              onClick={(e) => {
                                e.preventDefault()
                                onSelectSearch(v, inputSearch)
                              }}
                              className={
                                'text-dark px-2 py-2 w-100 ' +
                                (cursor === index ? 'search-active' : '')
                              }
                            >
                              {intl.formatMessage({id:"Pencarian"})} <strong>{getSearchHeaderName(v.Header)}</strong> for: {inputSearch}
                            </a>
                          )
                        )
                      })}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className='d-flex justify-content-end mt-2 gap-4'></div>
          </div>
        </div>
        <div className={'mt-4 d-flex justify-content-between gap-4'}>
          <div className={'d-flex'}>
            {props?.subHeaderButtons}
            {props.hideShowPagination !== true && (
              <>
                <Dropdown className={'me-2'}>
                  <Dropdown.Toggle
                    className='border'
                    disabled={isLoading}
                    variant='outline-secondary'
                    id='dropdown-basic'
                    size={'sm'}
                  >
                    {intl.formatMessage({id:'Menampilkan'})} {perPage}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {defaultPerPage && defaultPerPage !== 25 && (
                      <Dropdown.Item href="javascript:;" onClick={() => setPerPage(defaultPerPage ?? 25)}>
                        {props.defaultPerPage}
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item href="javascript:;" onClick={() => setPerPage(25)}>
                      25 {intl.formatMessage({id:'Baris'})}
                    </Dropdown.Item>
                    <Dropdown.Item href="javascript:;" onClick={() => setPerPage(50)}>
                      50 {intl.formatMessage({id:'Baris'})}
                    </Dropdown.Item>
                    <Dropdown.Item href="javascript:;" onClick={() => setPerPage(100)}>
                      100 {intl.formatMessage({id:'Baris'})}
                    </Dropdown.Item>
                    <Dropdown.Item href="javascript:;" onClick={() => setPerPage(250)}>
                      250 {intl.formatMessage({id:'Baris'})}
                    </Dropdown.Item>
                    <Dropdown.Item href="javascript:;" onClick={() => setPerPage(500)}>
                      500 {intl.formatMessage({id:'Baris'})}
                    </Dropdown.Item>
                    <Dropdown.Item href="javascript:;" onClick={() => setPerPage(meta?.total)}>
                      {intl.formatMessage({id:'Semua'})}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <ButtonGroup>
                  {selectedFlatRows.length > 0 && (
                    <Button size={'sm'} variant={'secondary'} disabled={true}>
                      {selectedFlatRows.length} {intl.formatMessage({id:'Dipilih'})}
                    </Button>
                  )}
                </ButtonGroup>
              </>
            )}
          </div>
          <div className='d-flex justify-content-between gap-4'>
            {props.hideSearch !== true && (
              <>
                <div className={'d-flex gap-4'}>
                  {props?.tableButton}
                  {props.hideExportAs !== true && (
                    <Dropdown>
                      <Dropdown.Toggle
                        className={'border'}
                        disabled={(selectedFlatRows.length === 0) || isLoading}
                        title={intl.formatMessage({id: 'Silahkan pilih data yang akan di export'})}
                        variant='light-info fw-bold'
                        id='dropdown-basic'
                        size={'sm'}

                      >
                        {intl.formatMessage({id: 'Export As'})}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item onClick={exportToExcel}>Excel</Dropdown.Item>
                        <Dropdown.Item onClick={exportToPdf}>PDF</Dropdown.Item>
                        <Dropdown.Item onClick={handleCopy}>Copy Clipboard</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                  <Button
                    size={'sm'}
                    variant={'light-danger fw-bold'}
                    type={'button'}
                    onClick={() => onResetSearch()}
                  >
                    <span className='me-2'>
                      {intl.formatMessage({id: 'Reset Filter'})}
                    </span>
                    <i className={'fa fa-remove'} />
                  </Button>
                  <button
                    type='button'
                    className='btn btn-light-warning btn-sm fw-bold'
                    onClick={() => {
                      handleRefresh()
                    }}
                    disabled={isLoading}
                  >
                    <span className='me-2'>
                      {intl.formatMessage({id: 'Perbaharui'})}
                    </span>
                    <i className={'fa fa-refresh'} />
                  </button>
                </div>
                <button
                  className={'btn btn-sm btn-outline btn-outline-secondary'}
                  disabled={isLoading}
                  type={'button'}
                  onClick={() => setIsModalSelectFieldOpen(true)}
                >
                  <i className={`fa fa-eye ${darkModeClass()}`} />
                </button>
              </>
            )}

            <Modal show={isModalSelectFieldOpen} onHide={() => setIsModalSelectFieldOpen(false)}>
              <Modal.Header closeButton>
                <Modal.Title>
                  {intl.formatMessage({id: 'Pilih Field Yang Ditampilkan'})}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <table className={'table table-striped'}>
                  <tbody>
                    <tr>
                      <th style={{width: '30px'}}>
                        <div
                          className={'form-check form-check-custom form-check-solid form-check-sm'}
                        >
                          <input
                            className={'form-check-input'}
                            type={'checkbox'}
                            checked={getToggleHideAllColumnsProps().checked ? true : false}
                            onChange={getToggleHideAllColumnsProps().onChange}
                          />
                        </div>
                      </th>
                      <th>{intl.formatMessage({id: 'Semua'})}</th>
                    </tr>
                    {allColumns
                      .filter((v: any) => v.id !== 'selection')
                      .map((v: any, index: number) => {
                        return (
                          <tr key={`${v.id}-${index}`}>
                            <td>
                              <div
                                className={
                                  'form-check form-check-custom form-check-solid form-check-sm'
                                }
                              >
                                <input
                                  className={'form-check-input'}
                                  type={'checkbox'}
                                  checked={v.isVisible}
                                  onChange={v.getToggleHiddenProps().onChange}
                                  disabled={!v.Header}
                                />
                              </div>
                            </td>
                            <td>{v.Header}</td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              </Modal.Body>
              <Modal.Footer>
                <Button variant='secondary' onClick={() => setIsModalSelectFieldOpen(false)}>
                  {intl.formatMessage({id: 'Tutup'})}
                </Button>
              </Modal.Footer>
            </Modal>
            <div>
              {showCreateButton && (
                <Button size={'sm'} type={'button'} onClick={() => onCreate()}>
                  <i className={'fa fa-plus'} />
                  {intl.formatMessage({id: 'Tambah'})}
                </Button>
              )}
            </div>
          </div>
        </div>
        <div style={{
          position: 'relative',
        }}>
        <div className={'table-responsive w-100 mt-4'} style={{
          maxHeight: `${maxHeight ? maxHeight : 'calc(100vh - 300px)'}`,
          height: (rows?.length === 0 || isLoading) ? '220px': 'unset',
          border: (rows?.length === 0 || isLoading) ? '1px solid #eff2f5': 'unset',

        }}
        >

            <table
              className="table table-hover table-sm table-striped table-fixed table-rounded border gs-7" {...getTableProps()}>
              <thead style={{}} className={'bg-primary'}>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    // @ts-ignore
                    const isOrderable = column?.orderable ? column?.orderable : true
                    // @ts-ignore
                    // console.log(column?.Header,column?.orderable,isOrderable)
                    // @ts-ignore
                    let className = column?.className
                    // remove class fixed-column
                    // @ts-ignore
                    if (className?.includes('fixed-column')) {
                      // @ts-ignore
                      className = className.replace('fixed-column', 'fixed-column-header')
                    }
                    if (props.columnSort === column.id) {
                      column.isSortedDesc = props.columnSortIsDesc
                      column.isSorted = true
                    }
                    let showSort = true
                    if (column.disableSortBy || column.render('Header') === 'Action' || column.render('Header') === 'Aksi' || typeof column.render('Header') !== 'string') {
                      showSort = false
                    }

                    return (
                      // Add the sorting props to control sorting. For this example
                      // we can add them into the header props
                      <th
                        className={`text-white fw-bolder border fs-7 text-uppercase gs-0 ${className}`}
                        {...column.getHeaderProps((column.getSortByToggleProps()))}
                        onClick={() => {
                          // if(column.disableSortBy === true) return
                          // column.toggleSortBy(!column.isSortedDesc)
                          // // @ts-ignore
                          // let isDesc = column.isSortedDesc === true ? true : false;
                          // if(column.isSorted) {
                          //   isDesc = !props.columnSortIsDesc
                          // }
                          // handleOnSort(column.id, isDesc)
                        }}
                      >

                        <a href={'#'} onClick={(e) => {
                          e.preventDefault()
                          if(column.id === "selection") return ;
                          if (column.disableSortBy === true) return
                          // column?.toggleSortBy(!column.isSortedDesc)
                          // @ts-ignore
                          let isDesc = column?.isSortedDesc === true ? true : false
                          if (column?.isSorted) {
                            isDesc = !props.columnSortIsDesc
                          }
                          handleOnSort(column.id, isDesc)
                          console.log(column)
                        }}>
                          <span className={'text-white'}>{column.render('Header')}</span>
                          {isOrderable && (
                            <>
                              {(column.isSorted) ? (
                                column.isSortedDesc ? (
                                  <span><i className={'ms-2 fa fa-sort-down'} /></span>
                                ) : (
                                  <span><i className={'ms-2 fa fa-sort-up'} /></span>
                                )
                              ) : (
                                <>{showSort && (<i className={'ms-2 fa fa-sort'} />)}</>
                              )}
                            </>
                          )}

                        </a>
                        {column.canResize && (
                          <div
                            {...column.getResizerProps()}
                            className={`resizer ${column.isResizing ? 'isResizing' : ''}`}
                          />
                        )}
                        {/* Add a sort direction indicator */}


                      </th>
                    )
                  })}
                </tr>
              ))}
              </thead>
              {/* Apply the table body props */}
              <tbody {...getTableBodyProps()}>
              {!isLoading &&
                rows.map((row) => {
                  // Prepare the row for display
                  prepareRow(row)

                  return (
                    // Apply the row props
                    <tr
                      {...row.getRowProps()}
                      onClick={() => {
                      }}
                      onDoubleClick={(e: any) => {
                        return props.onRowDoubleClick ? props.onRowDoubleClick(row?.original) : null
                      }}
                    >
                      {
                        // Loop over the rows cells
                        row.cells.map((cell) => {
                          // Apply the cell props
                          return (
                            <td
                              {...cell.getCellProps({
                                //@ts-ignore
                                className: cell?.column?.className + ' border px-2',
                              })}
                            >
                              {
                                // Render the cell contents
                                cell.render('Cell')
                              }
                            </td>
                          )
                        })
                      }
                    </tr>
                  )
                })}
              </tbody>
            </table>

        </div>
          {isLoading && (
            <div
              className={
                'd-flex flex-column text-muted justify-content-start align-items-center'
              }
              style={{
                position: 'absolute',
                top: '65%',
                left: '50%',
                textAlign: 'center',
                transform: 'translate(-50%,-50%)',
                zIndex: 100
              }}
            >
              <img src={'/pi2.gif'} width={100} />
              Loading...
            </div>
          )}
          {!isLoading && rows.length === 0 && (
            <div
              className={
                'd-flex flex-column text-muted justify-content-start align-items-center'
              }
              style={{
                position: 'absolute',
                top: '65%',
                left: '50%',
                textAlign: 'center',
                transform: 'translate(-50%,-50%)',
                zIndex: 100
              }}
            >
              <img src={'/no-data-6.svg'} width={150} />
              {intl.formatMessage({id:"No Data Available"})}

            </div>
          )}
        </div>
        <hr />
        <div className={'d-flex justify-content-between mt-4'}>
          <div>
            {intl.formatMessage({id: 'Menampilkan'})}{' '}
            {meta.total <= meta.perPage ? meta.total : meta.perPage}{' '}
            {intl.formatMessage({id: 'Dari'})}{' '}
            {(meta?.total ? meta.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0')}{' '}
            {intl.formatMessage({id: 'Baris'})}
          </div>
          <ul className="pagination" key={'pagination-' + currentPage}>
            <li className={'page-item previous ' + (meta.currentPage === 1 ? 'disabled' : '')}>
              <a
                href="javascript:;"
                className='page-link'
                onClick={(e) => {
                  e.preventDefault()
                  setCurrentPage(meta.currentPage - 1)
                }}
              >
                <i className={'previous'} />
              </a>
            </li>
            {paginationData.length > 5 && (
              <>
                <li className={'page-item ' + (1 === meta.currentPage ? 'active' : '')} key={1}>
                  <a
                    href="javascript:;"
                    className='page-link'
                    onClick={(e) => {
                      e.preventDefault()
                      setCurrentPage(1)
                    }}
                  >
                    {1}
                  </a>
                </li>
                {meta.currentPage < 5 && (
                  <>
                    <li className={'page-item ' + (2 === meta.currentPage ? 'active' : '')} key={1}>
                      <a
                        href="javascript:;"
                        className='page-link'
                        onClick={(e) => {
                          e.preventDefault()
                          setCurrentPage(2)
                        }}
                      >
                        {2}
                      </a>
                    </li>
                    <li className={'page-item ' + (3 === meta.currentPage ? 'active' : '')} key={1}>
                      <a
                        href="javascript:;"
                        className='page-link'
                        onClick={(e) => {
                          e.preventDefault()
                          setCurrentPage(3)
                        }}
                      >
                        {3}
                      </a>
                    </li>
                    <li className={'page-item ' + (4 === meta.currentPage ? 'active' : '')} key={1}>
                      <a
                        href="javascript:;"
                        className='page-link'
                        onClick={(e) => {
                          e.preventDefault()
                          setCurrentPage(4)
                        }}
                      >
                        {4}
                      </a>
                    </li>
                  </>
                )}

                {/* {meta.currentPage >= 5 && currentPage !== paginationData.length &&  (
                  <li className={'page-item ' + (currentPage === meta.currentPage ? 'active' : '')} key={1}>
                    <a href="javascript:;" className='page-link' onClick={() => setCurrentPage(currentPage)}>
                      ... {currentPage-1}
                    </a>
                  </li>
                )} */}
                {meta.currentPage >= 5 && meta.currentPage !== paginationData.length && (
                  <>
                    <li
                      className={
                        'page-item ' + (currentPage - 1 === meta.currentPage ? 'active' : '')
                      }
                    >
                      <a
                        href="javascript:;"
                        className='page-link'
                        onClick={() => setCurrentPage(currentPage - 1)}
                      >
                        ...{currentPage - 1}
                      </a>
                    </li>
                    <li
                      className={'page-item ' + (currentPage === meta.currentPage ? 'active' : '')}
                    >
                      <a href="javascript:;" className='page-link' onClick={() => setCurrentPage(currentPage)}>
                        {currentPage}
                      </a>
                    </li>
                    {currentPage + 1 !== paginationData.length && (
                      <li
                        className={
                          'page-item ' + (currentPage + 1 === meta.currentPage ? 'active' : '')
                        }
                      >
                        <a
                          href="javascript:;"
                          className='page-link'
                          onClick={(e) => {
                            e.preventDefault()
                            setCurrentPage(currentPage + 1)
                          }}
                        >
                          {currentPage + 1}
                        </a>
                      </li>
                    )}
                  </>
                )}

                {meta.currentPage >= 5 && meta.currentPage === paginationData.length && (
                  <>
                    <li
                      className={
                        'page-item ' + (currentPage - 3 === meta.currentPage ? 'active' : '')
                      }
                    >
                      <a
                        href="javascript:;"
                        className='page-link'
                        onClick={() => setCurrentPage(currentPage - 3)}
                      >
                        ...{currentPage - 3}
                      </a>
                    </li>
                    <li
                      className={
                        'page-item ' + (currentPage - 2 === meta.currentPage ? 'active' : '')
                      }
                    >
                      <a
                        href="javascript:;"
                        className='page-link'
                        onClick={() => setCurrentPage(currentPage - 2)}
                      >
                        {currentPage - 2}
                      </a>
                    </li>
                    <li
                      className={
                        'page-item ' + (currentPage - 1 === meta.currentPage ? 'active' : '')
                      }
                    >
                      <a
                        href="javascript:;"
                        className='page-link'
                        onClick={() => setCurrentPage(currentPage - 1)}
                      >
                        {currentPage - 1}
                      </a>
                    </li>
                  </>
                )}

                {meta.currentPage !== 1 && (
                  <li
                    className={
                      'page-item ' + (paginationData.length === meta.currentPage ? 'active' : '')
                    }
                    key={1}
                  >
                    <a
                      href="javascript:;"
                      className='page-link'
                      onClick={() => setCurrentPage(paginationData.length)}
                    >
                      {paginationData.length}
                    </a>
                  </li>
                )}
                {meta.currentPage === 1 && (
                  <li
                    className={
                      'page-item ' + (paginationData.length === meta.currentPage ? 'active' : '')
                    }
                    key={1}
                  >
                    <a
                      href="javascript:;"
                      className='page-link'
                      onClick={() => setCurrentPage(paginationData.length)}
                    >
                      ...{paginationData.length}
                    </a>
                  </li>
                )}
              </>
            )}
            {paginationData.length <= 5 && (
              <>
                {paginationData.map((v: any) => {
                  return (
                    <li
                      className={
                        'page-item ' + (parseInt(v.key) === meta.currentPage ? 'active' : '')
                      }
                      key={v.key}
                    >
                      <a href="javascript:;" className='page-link' onClick={() => setCurrentPage(v.key)}>
                        {v.key}
                      </a>
                    </li>
                  )
                })}
              </>
            )}

            <li
              className={
                'page-item next ' + (meta.currentPage === paginationData.length ? 'disabled' : '')
              }
            >
              <a
                href="javascript:;"
                className='page-link'
                onClick={() => setCurrentPage(meta.currentPage + 1)}
              >
                <i className={'next'} />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </KTCardBody>
  )
}

export default TableX
