import React, { useCallback, useEffect, useState } from "react";
import { useAxiosGet, useAxiosPost } from "../../../../../../hooks/useAxios";
import { getEnv } from "../../../../../../helpers/procurex";
import { useIntl } from "react-intl";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Badge, Panel, Stack } from "rsuite";
import NoteAttachmentEvaluation from "./NoteAttachmentEvaluation";
import PreviewAttachment from "../../../../../components/PreviewAttachment";
import { Spinner } from "react-bootstrap";
import {EvaluasiKlarifikasiAdministrasiTeknisList} from './EvaluasiKlarifikasiAdministrasiTeknisList'

const EvaluasiAdministrasi = ({ proposalTender, quotation, onChange, onChangeEvaluationData, isOwner }: any) => {
  const [syarats, setSyarats] = useState([]);
  const [note, setNote] = useState("");
  const [attachment, setAttachment] = useState([]);
  const [deletedAttachment, setDeletedAttachment] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isUncheckAll, setIsUncheckAll] = useState(false);
  const [durVendor, setDurVendor] = useState(null as any);
  const [previewAttachment, setPreviewAttachment] = useState(null);
  const [isExpand, setIsExpand] = useState(true)
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosGet(
    getEnv("tender") + `/evaluation/administration/${proposalTender?.uuid}/${quotation?.vendor?.uuid}`
  );
  const [{ doSubmit: doSubmitSave, isLoading: isLoadingSave }] = useAxiosPost(
    getEnv("tender") + `/evaluation/administration/syarat`
  );
  const [renderKey, setRenderKey] = useState(0);
  const intl = useIntl();

  const isCanSubmit = useCallback(() => {
    return true;
    // const allowedStatus = [
    //   'NO-DATA',
    //   'NOT-EVALUATE'
    // ]
    // return allowedStatus.includes(durVendor?.status_evaluation_administration);
  }, [durVendor]);

  const getDurVendor = async () => {
    const url = getEnv("tender") + `/dur-vendor?filter[proposal_tender_id]=${proposalTender?.id}&filter[vendor_id]=${quotation?.vendor_id}`;
    doSubmit({}, url, undefined, () => {}).then((res: any) => {
      setDurVendor(res.data[0]);
    }).catch((err: any) => {});
  }

  const updateSyarat = (index: number, state: string) => {
    const newSyarats = syarats.map((syarat: any, i: number) => {
      if (i === index) {
        syarat.status = state;
      }
      return syarat;
    });
    // @ts-ignore
    setSyarats(newSyarats);
  };

  const handleOnSaveSyarat = async() => {
    Swal.fire({
      title: `${intl.formatMessage({id:"Apakah Anda yakin mensubmit Evaluasi Administrasi"})}?`,
      html: `${intl.formatMessage({id:"Tindakan ini tidak dapat dikembalikan"})}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Ya, Submit`,
      cancelButtonText: intl.formatMessage({id:"Cancel"}),
    }).then((result) => {
      if (result.isConfirmed) {
        // @ts-ignore
        const requirements = [];
        const isAdmin = syarats.some((syarat: any) => syarat.type === "administration" && syarat.status !== "NOT-EVALUATE");
        const isTechnical = syarats.some((syarat: any) => syarat.type === "technical" && syarat.status !== "NOT-EVALUATE");
        

        syarats.forEach((syarat: any) => {
          if(syarat?.status !== 'NOT-EVALUATE') {
            requirements.push({
              id: syarat.uuid,
              evaluation: syarat.status === "PASSED",
              description: ""
            });
          }
        });

        const payload = {
          proposal_tender_vendor_id: quotation?.vendor?.uuid,
          // @ts-ignore
          requirements: requirements
        };

        //region store syarat administration
        if(isAdmin){
          doSubmitSave(payload, getEnv("tender") + `/evaluation/administration/syarat/${proposalTender?.uuid}`, toast, () => {
          }).then((res: any) => {
            const evaluateVendorURL = getEnv("tender") + `/evaluation/vendor/v2/${proposalTender?.uuid}/${quotation?.vendor?.uuid}`;
            doSubmitSave({}, evaluateVendorURL, undefined, () => {}).then((res: any) => {
              onChange && onChange();
              getDurVendor();
            }).catch((err: any) => {});
          }) 
        }
        //endregion

        //region store syarat technical
        if(isTechnical){
          doSubmitSave(payload, getEnv("tender") + `/evaluation/technical/syarat/${proposalTender?.uuid}`, toast, () => {
          }).then((res: any) => {
              const evaluateVendorURL = getEnv("tender") + `/evaluation/vendor/v2/${proposalTender?.uuid}/${quotation?.vendor?.uuid}`;
              doSubmitSave({}, evaluateVendorURL, undefined, () => {}).then((res: any) => {
                onChange && onChange();
                getDurVendor();
              }).catch((err: any) => {});
          }) 
        }
        //endregion

        //save note
        if(note) {
          doSubmitSave({
            note: note || "-",
            type: "Evaluasi Administrasi"
          }, getEnv("tender") + `/evaluation/notes/${quotation?.vendor?.uuid}`, undefined);

        }

        //save attachment
        // @ts-ignore
        if(attachment && attachment.length > 0){
          const payloadAttachment = {
            // @ts-ignore
            description: attachment[0]?.media_type?.file_name,
            type: "Evaluasi Administrasi",
            // @ts-ignore
            media_type: attachment[0]?.media_type,
            // @ts-ignore
            media_url: attachment[0]?.media_url
          }
          doSubmitSave(payloadAttachment, getEnv("tender") + `/evaluation/attachment/${quotation?.vendor?.uuid}`, undefined);
        }

        //delete attachment
        // @ts-ignore
        if(deletedAttachment && deletedAttachment.length > 0){
          deletedAttachment.forEach((item: any)=>{
            doSubmitSave({
              _method: "DELETE"
            }, getEnv("tender") + `/evaluation/attachment/${quotation?.vendor?.uuid}/${item}`, undefined);
          })
        }

        // //region evaluate vendor v2
        // doSubmitSave(
        //   {},
        //   getEnv("tender")+`/evaluation/vendor/v2/${proposalTender?.uuid}/${quotation?.vendor?.uuid}`
        // )
        // //endregion

      }
    })

  };

  const handleCheckAll = (e: any) => {
    if (e.target.checked) {
      setIsCheckAll(true);
      setIsUncheckAll(false);
      const newSyarats = syarats.map((syarat: any) => {
        syarat.status = "PASSED";
        return syarat;
      });
      // @ts-ignore
      setSyarats(newSyarats);
    }
  };

  const handleUncheckAll = (e: any) => {
    if (e.target.checked) {
      setIsCheckAll(false);
      setIsUncheckAll(true);
      const newSyarats = syarats.map((syarat: any) => {
        syarat.status = "FAILED";
        return syarat;
      });
      // @ts-ignore
      setSyarats(newSyarats);
    }
  };

  const isFormEnabled = () => {
    const allowedStatus = [
      "Bid Opening",
      "Evaluasi Administrasi",
      "Evaluasi Teknikal"
    ];
    return allowedStatus.includes(proposalTender?.status) 
      && !isLoadingSave 
      && isCanSubmit();
  };
  
  useEffect(() => {
    getDurVendor();
    doSubmit({}, undefined, undefined, () => {
    }).then((res: any) => {
      let _syarat = res.data;

      //get technical evaluation
      doSubmit({}, getEnv("tender") + `/evaluation/technical/${proposalTender?.uuid}/${quotation?.vendor?.uuid}`, undefined, () => {}).then((res2: any) => {
        _syarat.push(...res2.data);
      }).finally(()=>{
        setSyarats(_syarat);
      })
    }).catch((err: any) => {
      toast.error("Get Syarat: " + err?.response?.data?.meta?.message);
    });
  }, []);

  useEffect(() => {
    // if (syarats?.length > 0 && renderKey > 0) saveSyarat();
    // if (syarats?.length > 0) setRenderKey(renderKey + 1);
  }, [syarats]);

  useEffect(() => {
    //region expanded condition
    if((durVendor?.status_evaluation_administration === "PASSED" || durVendor?.status_evaluation_administration === "FAILED")){
      // setIsExpand(false)
    } else {
      // setIsExpand(true)
    }
    setRenderKey(renderKey+1)
    //endregion
  }, [durVendor])

  return (
    <Panel
      key={renderKey}
      header={
        <Stack justifyContent={"space-between"}>
            <h3>{intl.formatMessage({ id: "Proses Evaluasi Administrasi" })}</h3>
            {(durVendor?.status_evaluation_administration === "PASSED" || durVendor?.status_evaluation_administration === "FAILED") && (
              <div>
                <Badge className={"px-2 py-1"} content={
                  <>
                    <i className={"fa fa-check-circle text-white me-2"}></i>
                    {intl.formatMessage({ id: "Telah Dievaluasi" })}
                  </>
                } color="blue" />
              </div>
            )}
          {(durVendor?.status_evaluation_administration === "NO-DATA" || durVendor?.status_evaluation_administration === "NOT-EVALUATE") && (
            <div>
              <Badge className={"px-2 py-1"} content={
                <>
                  <i className={"fa fa-times-circle text-white me-2"}></i>
                  {intl.formatMessage({ id: "Belum Dievaluasi" })}
                </>
              } color="red" />
            </div>
          )}
        </Stack>
      }
      collapsible
      bordered
      defaultExpanded={isExpand}
    >
      <div className={""}>
        <table className={"table table-bordered table-striped table-header-gray"}>
          <thead>
          <tr>
            <th className={"text-center border p-2"} rowSpan={2}><strong>{intl.formatMessage({ id: "No" })}</strong></th>
            <th className={"text-left border p-2"} rowSpan={2}><strong>{intl.formatMessage({ id: "Deskripsi" })}</strong>
            </th>
            <th className={"text-left border p-2"} rowSpan={2}><strong>{intl.formatMessage({ id: "Jenis" })}</strong>
            </th>
            <th className={"text-center border"} rowSpan={2}>
              <strong>{intl.formatMessage({ id: "Mandatory" })}</strong>
            </th>
            <th className={"text-left border"} rowSpan={2}><strong>{intl.formatMessage({ id: "Dokumen" })}</strong>
            </th>
            <th className={"text-center border"} colSpan={2}>
              <strong>{intl.formatMessage({ id: "Hasil Evaluasi" })}</strong>
            </th>
          </tr>
          <tr>
            <th className={"text-center"}>
              <input type={"checkbox"} className={"form-check-input"} checked={isCheckAll} disabled={!isFormEnabled()}
                     onChange={handleCheckAll} /> {intl.formatMessage({ id: "Setujui Semua" })}
            </th>
            <th className={"text-center"}>
              <input type={"checkbox"} className={"form-check-input"} checked={isUncheckAll}
                     disabled={!isFormEnabled()}
                     onChange={handleUncheckAll} /> {intl.formatMessage({ id: "Tolak Semua" })}
            </th>
          </tr>
          </thead>
          <tbody>
          {syarats.map((syarat: any, index: number) => {
            return (
              <tr key={"evaluasi-administrasi" + index}>
                <td className={"text-center border p-2"}>{index + 1}</td>
                <td className={"text-left border"}>{syarat.description}</td>
                <td className={"text-left border"}>{syarat.type}</td>
                <td
                  className={"text-center border"}>{syarat?.required_attachment ? intl.formatMessage({ id: "Ya" }) : intl.formatMessage({ id: "Tidak" })}</td>
                <td className={"text-left border"}>
                  {syarat?.media_url && (
                    <div className={"input-group input-group-sm"}>
                      <input type={"text"} className={"form-control form-control-sm"} value={syarat?.description}
                             disabled={true} />
                      <button type={"button"} className={"btn btn-primary btn-sm"} disabled={isLoading}
                              onClick={() => setPreviewAttachment(
                                syarat?.media_url
                              )}>
                        <i className={"fa fa-eye"}></i>
                      </button>
                    </div>
                  )}
                  {!syarat?.media_url &&
                    <div className={"text-gray"} style={{
                      fontStyle: "italic",
                      color: "#a1a5b7"
                    }}><i className={"fa fa-file"}></i> {intl.formatMessage({ id: "Tidak ada data" })}</div>
                  }
                </td>
                <td className={"border text-center"}>
                  <div className={"d-flex align-items-center justify-content-center gap-2"}>
                    {isLoadingSave && (
                      <i className={"fa fa-spinner fa-spin"}></i>
                    )}
                    <input type={"checkbox"} className={"form-check-input"} checked={syarat?.status === "PASSED"}
                           disabled={!isFormEnabled()} onChange={(e: any) => {
                      updateSyarat(index, "PASSED");
                    }} /> {intl.formatMessage({ id: "Diterima" })}
                  </div>
                </td>
                <td className={"border text-center"}>
                  <div className={"d-flex align-items-center justify-content-center gap-2"}>
                    {isLoadingSave && (
                      <i className={"fa fa-spinner fa-spin"}></i>
                    )}
                    <input type={"checkbox"} className={"form-check-input"} checked={syarat?.status === "FAILED"}
                           disabled={!isFormEnabled()} onChange={(e: any) => {
                      updateSyarat(index, "FAILED");
                    }} /> {intl.formatMessage({ id: "Ditolak" })}
                  </div>
                </td>
              </tr>
            );
          })}
          </tbody>
        </table>

        <NoteAttachmentEvaluation
          proposalTender={proposalTender}
          quotation={quotation}
          onChange={(type:string,value:any)=>{
            setNote(value?.note);
            setAttachment(value?.attachments);
          }}
          fieldKey={"evaluation_administration"}
          onDeleted={(deletedAttachment: any) => {
            // console.log(deletedAttachment);
            setDeletedAttachment(deletedAttachment);
          }}
          disabled={!isFormEnabled()}
        />


        {/*evaluation*/}
        <EvaluasiKlarifikasiAdministrasiTeknisList
          vendor_id={quotation?.vendor_id}
          clarificationType={"administration"}
        />


        <PreviewAttachment previewExt={previewAttachment} onHide={setPreviewAttachment} />
        {isOwner &&
          <div className={'mt-4 d-flex justify-content-end'}>
            <button
              className={'btn btn-primary'}
              type={'button'}
              onClick={handleOnSaveSyarat}
              disabled={isLoadingSave || !isFormEnabled()}
            >
              {isLoadingSave && (
                <Spinner animation="border" role="status" size={"sm"} className={"me-2"} />
              )}
              {!isLoadingSave && <i className={"fa fa-save"}></i>}
              {intl.formatMessage({id: "Simpan Evaluasi Administrasi"})}
            </button>
          </div>
        }
      </div>
    </Panel>
  );
};


export default EvaluasiAdministrasi;