import axios from 'axios'
import {getEnv} from '../../../../../helpers/procurex'


const storeImport = (values: any) => {
  const formData = new FormData()

  formData.append('snop-data', values.file)

  return axios.post(`${getEnv('stockview')}/snop/import-data`, formData)
}


const runProductionPlan = () => {
  return axios.post(`${getEnv('stockview')}/snop/run-production-plan`)
}

export {storeImport, runProductionPlan}