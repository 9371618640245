import axios, {AxiosResponse} from 'axios'
import {Form, Formik, FormikErrors} from 'formik'
import {FormikHelpers} from 'formik/dist/types'
import React, {RefAttributes, useCallback, useEffect, useState} from 'react'
import Dropzone, {DropzoneProps, DropzoneRef, FileRejection} from 'react-dropzone'
import {useIntl} from 'react-intl'
import {Link, useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import {JSX} from 'react/jsx-runtime'
import {MetronicErrorMessage, toastError, toastSuccess} from '../../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {Response} from '../../../../_models'
import {getEnv} from '../../../../helpers/procurex'
import PreviewAttachment from '../../../components/PreviewAttachment'
import SwalX from '../../../components/shared/SwalX'
import TableXWrapper from '../../../components/shared/TableXWrapper'
import {useAuth} from '../../auth'
import {
  PO_ARCHIVE_SOURCE,
  TEMP_MEDIA_TYPE_ID,
  useUploadPurchaseOrderArchiveMutation,
} from '../core/_queries'

type DropzoneXProps = JSX.IntrinsicAttributes &
  DropzoneProps &
  RefAttributes<DropzoneRef> & {
    helpText?: string
  }

const DropzoneX = (props: DropzoneXProps) => {
  return (
    <Dropzone {...props}>
      {props.children === undefined &&
        (({getRootProps, getInputProps}) => (
          <div {...getRootProps({className: 'dropzone'})}>
            <input {...getInputProps()} />
            <div className='dz-message needsclick align-items-center'>
              <i className='fas fa-file-upload fs-3x text-primary'></i>

              <div className='ms-4'>
                <h3 className='fs-5 fw-bold text-gray-900 mb-1'>
                  Drop files here or click to add file.
                </h3>
                {props.helpText && (
                  <span className='fs-7 fw-semibold text-gray-500'>{props.helpText}</span>
                )}
              </div>
            </div>
          </div>
        ))}
    </Dropzone>
  )
}

const ToastErrorContent = ({
  fileName,
  errorMessages,
}: {
  fileName: string
  errorMessages: string[]
}) => {
  return (
    <>
      <h5>{`Error`}</h5>
      <strong>{fileName}</strong>
      <div>
        {errorMessages.map((message, index) => (
          <div key={index} className='d-flex align-items-top py-2'>
            {message}
          </div>
        ))}
      </div>
    </>
  )
}

const categoryCodeAndValues = {
  PO: 'PO',
  T: 'TENDER',
  L: 'LAINNYA',
}
const categoryPattern = Object.keys(categoryCodeAndValues).join('|')
const fileNamePattern = new RegExp(`^(\\d{10})\\s*-(.+)-\\s*(${categoryPattern})\\.pdf`, 'i')

const validatorFn = (sizeInMb: number) => (file: File) => {
  // Validate file name
  if (!fileNamePattern.test(file.name)) {
    return {
      code: 'invalid-filename',
      message: `Name must match this pattern:\n[PO No]-[Description]-[Category: ${categoryPattern.replace('|', '/')}].pdf`,
    }
  }

  // Validate file size
  if (file.size > sizeInMb * 1024 * 1024) {
    return {
      code: 'invalid-size',
      message: `File size must be less than ${sizeInMb} MB`,
    }
  }

  return null
}

const PoArchiveTable = ({errors}: {errors?: FormikErrors<any>}) => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const [tableKey, setTableKey] = useState(0)
  const [itemUuid, setItemUuid] = useState(undefined)
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false)
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)
  const [previewAttachment, setPreviewAttachment] = useState(null)

  const onDeleteItem = useCallback(async () => {
    setIsLoadingDelete(true)
    await axios.delete(`${getEnv('media')}/media/${itemUuid}`)
    setIsLoadingDelete(false)
    setIsConfirmDeleteOpen(false)
    setTableKey((key) => key + 1)
  }, [itemUuid])

  const CellWithErrors = useCallback(
    ({value, row, column}) => {
      const columnErrors = errors?.[`archive.${row.original.id}.${column.id}`] as
        | string[]
        | undefined

      return (
        <>
          {value}
          {columnErrors &&
            columnErrors.map((msg) => (
              <>
                <br />
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{msg}</span>
                  </div>
                </div>
              </>
            ))}
        </>
      )
    },
    [errors]
  )

  return (
    <>
      <TableXWrapper
        key={tableKey}
        title='List Files'
        readonly
        hideSelection
        urlIndex={`${getEnv('media')}/media`}
        defaultFilter={`filter[media_type_id]=${TEMP_MEDIA_TYPE_ID}&filter[created_by_id]=${currentUser.id}`}
        headerButtons={<h3>List Files</h3>}
        columns={[
          {
            Header: intl.formatMessage({id: 'Aksi'}),
            accessor: 'uuid',
            searchable: false,
            orderable: false,
            disableSortBy: true,
            width: 100,
            Cell: ({value, row}) => {
              // noinspection RequiredAttributes
              return (
                <div className='d-flex w-100 justify-content-center gap-2'>
                  <button
                    type='button'
                    className='btn btn-flush btn-active-icon-primary btn-active-text-primary'
                    onClick={() => {
                      setPreviewAttachment(row.original.url)
                    }}
                  >
                    <i className='fa fs-3 fa-eye'></i>
                  </button>

                  <button
                    type='button'
                    className='btn btn-flush btn-active-icon-danger btn-active-text-danger'
                    onClick={() => {
                      setItemUuid(value)
                      setIsConfirmDeleteOpen(true)
                    }}
                  >
                    <i className='fa fs-3 fa-trash'></i>
                  </button>
                </div>
              )
            },
          },
          {
            Header: '#',
            accessor: 'id',
            searchable: false,
            orderable: false,
            disableSortBy: true,
            width: 50,
            Cell: ({row}) => row.index + 1,
          },
          {
            Header: 'No PO SAP',
            accessor: 'properties.po_sap_no',
            orderable: false,
            disableSortBy: true,
            width: 250,
            Cell: CellWithErrors,
          },
          {
            Header: 'Category',
            accessor: 'properties.category',
            orderable: false,
            disableSortBy: true,
            width: 350,
            Cell: CellWithErrors,
          },
          {
            Header: 'Description',
            accessor: 'properties.description',
            orderable: false,
            disableSortBy: true,
            width: 600,
            Cell: CellWithErrors,
          },
        ]}
      />

      <SwalX
        title={intl.formatMessage({id: 'DATATABLE.CONFIRM_DELETE'})}
        message={intl.formatMessage({id: 'DATATABLE.MESSAGE_DELETE'})}
        show={isConfirmDeleteOpen}
        loading={isLoadingDelete}
        onHide={() => setIsConfirmDeleteOpen(false)}
        onSubmit={() => onDeleteItem()}
      />

      <PreviewAttachment previewExt={previewAttachment} onHide={setPreviewAttachment} />
    </>
  )
}

const PoArchiveForm = () => {
  const navigate = useNavigate()
  const [tableKey, setTableKey] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const {mutate, isLoading: isUploadLoading} = useUploadPurchaseOrderArchiveMutation()

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    setIsLoading(true)
    await Promise.allSettled(
      acceptedFiles.map(async (file) => {
        const execResult = fileNamePattern.exec(file.name) ?? []
        const po_sap_no = execResult[1].trim()
        const description = execResult[2].trim()
        const categoryCode = execResult[3].trim().toUpperCase()
        const category = categoryCodeAndValues[categoryCode]

        if (!po_sap_no || !description || !category) {
          toastError('Invalid file name')
          return
        }

        try {
          const poResponse = await axios.get(
            `${getEnv('tender')}/po?filter[status]=Done&filter[sap_no]=${po_sap_no}`
          )

          if (poResponse.data.meta.total < 1 || !poResponse.data.data?.[0]?.id) {
            toast.error(
              <ToastErrorContent
                fileName={file.name}
                errorMessages={[`PO SAP ${po_sap_no} not found`]}
              />,
              {bodyClassName: 'align-items-start'}
            )

            return
          }

          const formData = new FormData()

          formData.append('object_id', poResponse.data.data[0].id)
          formData.append('file', file)
          formData.append('media_type_id', TEMP_MEDIA_TYPE_ID)
          formData.append('properties[po_sap_no]', po_sap_no)
          formData.append('properties[description]', description)
          formData.append('properties[category]', category)
          formData.append('properties[source]', PO_ARCHIVE_SOURCE)
          formData.append('properties[object_id]', poResponse.data.data[0].id)

          await axios.post(`${getEnv('media')}/media`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
        } catch (error) {
          const messages =
            'Failed to upload PO archive: ' +
            (axios.isAxiosError(error)
              ? error.response.data?.meta?.message ||
                error.response.data?.message ||
                'Terjadi kesalahan'
              : error.message)

          toast.error(<ToastErrorContent fileName={file.name} errorMessages={[messages]} />, {
            bodyClassName: 'align-items-start',
          })
        }
      })
    )

    setIsLoading(false)
    setTableKey((key) => key + 1)
  }, [])

  const onDropRejected = useCallback((fileRejections: FileRejection[]) => {
    fileRejections.forEach((rejection) =>
      toast.error(
        <ToastErrorContent
          fileName={rejection.file.name}
          errorMessages={rejection.errors.map((e) => e.message)}
        />,
        {bodyClassName: 'align-items-start'}
      )
    )
  }, [])

  const onSubmit = useCallback(
    (_: any, formikHelpers: FormikHelpers<{}>) => {
      setIsLoading(true)
      mutate(null, {
        onSuccess: (response: AxiosResponse<Response<any>>) => {
          setIsLoading(false)

          navigate('..')
          toastSuccess(response.data.meta.message)
        },
        onError: (error) => {
          setIsLoading(false)

          if (error.response?.status === 422 && error.response.data.errors) {
            const errors = error.response.data.errors as Array<any>

            Object.keys(errors).forEach((e: string) => {
              formikHelpers.setFieldTouched(e, true)
            })

            setTimeout(() => formikHelpers.setErrors(errors), 100)
          }
        },
      })
    },
    [mutate, navigate]
  )

  useEffect(() => {
    setIsLoading((prev) => prev || isUploadLoading)
  }, [isUploadLoading])

  return (
    <div className='card'>
      <div className='card-body'>
        <div>
          <h3>Add Files</h3>
          <DropzoneX
            accept={{'application/pdf': ['.pdf']}}
            onDrop={onDrop}
            onDropRejected={onDropRejected}
            helpText='Maximum size of file is 15 mb'
            validator={validatorFn(15)}
            disabled={isLoading}
          />
        </div>
      </div>

      <Formik initialValues={{}} onSubmit={onSubmit}>
        {({errors}) => (
          <>
            <PoArchiveTable key={tableKey} errors={errors} />

            <div className='px-9'>
              <MetronicErrorMessage name='archive' />
            </div>

            <Form>
              <div className='card-body mb-3'>
                <div className='d-flex justify-content-end'>
                  <button type='submit' className='btn btn-primary' disabled={isLoading}>
                    Upload Files
                  </button>
                </div>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  )
}

export const PoArchiveUploadPage = ({breadcrumbs}: {breadcrumbs?: Array<PageLink>}) => {
  const intl = useIntl()

  breadcrumbs = breadcrumbs.concat([
    {
      title: 'Arsip PO',
      path: '/contract-management/po-archive',
      isSeparator: false,
      isActive: false,
    },
  ])

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>Mass Upload</PageTitle>

      <div className={'mb-4 d-flex'}>
        <div>
          <Link to={'../'} className={'btn btn-danger btn-sm'}>
            <i className={'fa fa-arrow-left'}></i>
            {intl.formatMessage({id: 'Kembali ke list'})}
          </Link>
        </div>
      </div>

      <PoArchiveForm />
    </>
  )
}
