import React, { useEffect, useState } from "react";
import GambaranUmumDuaTahapTab from "./GambaranUmumDuaTahapTab";
import {AdministrasiTeknisDuaTahapTab} from "./AdministrasiTeknisDuaTahapTab";
import { Nav } from "rsuite";
import {BeritaAcaraTab} from "../BeritaAcaraTab";
import { ResumeEvaluasiTab } from "../ResumeEvaluasiTab";
import { Spinner } from "react-bootstrap";
import BottomToolbarWrapper from "../../../../../../../components/shared/BottomToolbarWrapper";
import Swal from "sweetalert2";
import { getEnv } from "../../../../../../../../helpers/procurex";
import { toast } from "react-toastify";
import axios from "axios";
import { useAxiosPost } from "../../../../../../../../hooks/useAxios";
import { useIntl } from "react-intl";
import {ToolbarInterface, useProposalTender} from '../../../core/_proposalTenderProvider'
import {EvaluasiUserTab} from '../evaluasi_satu_sampul/EvaluasiUserTab'
import KlarifikasiVendorTab from '../evaluasi_satu_sampul/KlarifikasiVendorTab'


const EvaluasiTeknisDuaTahapPageTab = ({proposalTender,onQuotationChanged,isOwner}:any) => {
  const [selectedTab, setSelectedTab] = useState("");
  const [menuTab, setMenuTab] = useState([] as any);
  const intl = useIntl();
  const  [{ doSubmit, isLoading }] = useAxiosPost(
    getEnv("tender") + `/evaluation/administration/syarat`
  )
  const [isLoadingCheck, setIsLoadingCheck] = useState(false);

  const initiateTab = () => {
    const tmp = [
      { eventKey: "gambaran-umum", label: "Gambaran Umum" },
      { eventKey: "administrasi-teknis", label: "Administrasi Teknis", disabled: isShowBidOpening() },
      { eventKey: "evaluasi", label: "Resume Evaluasi", disabled: isShowBidOpening() },
      { eventKey: "berita-acara", label: "Berita Acara" },
    ];

    let tmpSelectedTab = "";
    tmp.forEach((item:any) => {
      if(!item?.disabled && tmpSelectedTab === ""){
        tmpSelectedTab = item.eventKey;
        setSelectedTab(item.eventKey);
        return false;
      }
    })
    setMenuTab(tmp);
  }

  const showTab = () => {
    switch (selectedTab) {
      case "gambaran-umum":
        return <GambaranUmumDuaTahapTab proposalTender={proposalTender} jadwalDescription={"Penawaran Teknikal"} isOnMenuKomersil={false} />;

      case "administrasi-teknis":
        return <AdministrasiTeknisDuaTahapTab
          proposalTender={proposalTender}
          onQuotationChanged={()=>{}}
          isOwner={isOwner}
        />;
      case "evaluasi":
        return <ResumeEvaluasiTab proposalTender={proposalTender} />;
      case "berita-acara":
        return <BeritaAcaraTab proposalTender={proposalTender} />;
      default:
        return <></>;
    }
  }

  const isShowBidOpening = () => {
    return proposalTender?.status === "Bid Opening" &&
      (proposalTender?.status_detail === "Bid Opening" ||
       proposalTender?.status_detail === "Rejected");
  }

  const isShowTutupEvaluasiAdministrasi = () => {
    return proposalTender?.status === "Evaluasi Administrasi" &&
      proposalTender?.status_detail === "On Going";
  }

  const onBidOpening = () => {
    Swal.fire({
      title: intl.formatMessage({id:"Apakah Anda yakin"})+"?",
      text: intl.formatMessage({id:"Anda akan membuka penawaran"}),
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Ya, submit",
      cancelButtonText: "Tidak"
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        doSubmit(
          {},
          getEnv("tender") + `/bid/submit/${proposalTender.uuid}`,
          toast
        ).then((res: any) => {
          onQuotationChanged && onQuotationChanged();
        }).finally(() => {
        });
      } else {
      }
    });
  }

  const checkIsAllEvaluate = async() => {
    setIsLoadingCheck(true);
    let state = true;
    let metode_penyampaian_step = proposalTender?.metode_penyampaian_step;
    const {data} = await axios.get(getEnv("tender") + `/evaluation/quotation/${proposalTender.uuid}?perPage=1000`)

    if(metode_penyampaian_step === 1) {
      data?.data?.forEach((item:any) => {
        if(item?.vendor?.status_evaluation_administration === "NOT-EVALUATE" || item?.vendor?.status_evaluation_technical === "NOT-EVALUATE" ){
          state = false;
        }
      });
    } else {
      data?.data?.forEach((item:any) => {
        if(item?.vendor?.status_evaluation === "NOT-EVALUATE"){
          state = false;
        }
      });
    }

    setIsLoadingCheck(false);
    return state;
  }

  const onTutupEvaluasiAdministrasi = async() => {
    const isValid = await checkIsAllEvaluate();
    if(!isValid){
      toast.error(intl.formatMessage({id:"Evaluasi vendor belum lengkap."}))
      return false;
    }
    Swal.fire({
      title: intl.formatMessage({id:"Apakah Anda yakin"})+"?",
      text: intl.formatMessage({id:"Anda akan menutup"+" "+proposalTender?.status}),
      icon: "question",
      showCancelButton: true,
      confirmButtonText: intl.formatMessage({id:"Ya, Submit"}),
      cancelButtonText: intl.formatMessage({id:"Tidak"})
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        // Submit evaluasi vendor
        doSubmit(
          {},
          getEnv("tender") + `/evaluation/submit/${proposalTender.uuid}`,
          toast
        ).then((res: any) => {
          // submit evaluasi administrasi
        }).finally(() => {
          onQuotationChanged && onQuotationChanged();
        });
      } else {
        onQuotationChanged && onQuotationChanged();
      }
    });
  }


  useEffect(() => {
    initiateTab();
  }, [proposalTender]);

  return (
    <>
      <div className={""}>
          <div className={"d-flex justify-content-between"}>
            <div><h3>Evaluasi Teknis</h3></div>
          </div>
          <hr />

          <Nav appearance={"subtle"} activeKey={selectedTab} onSelect={(e:any)=>{setSelectedTab(e)}} style={{ marginBottom: 20 }}>
            {menuTab.map((item:any, index:number) => {
              return (
                <Nav.Item eventKey={item.eventKey} key={index} disabled={item?.disabled}>
                  {item.label}
                </Nav.Item>
              );
            })}
          </Nav>

          {showTab()}

        <BottomToolbarWrapper isOwner={isOwner} onGoBack={()=>{}} children={
          <>
            {isShowBidOpening() && (
              <button
                type={"button"}
                disabled={isLoading}
                className={"btn btn-primary me-3"}
                onClick={() => onBidOpening()}
              >
                {isLoading ? (
                  <Spinner animation="border" role="status" size={"sm"} />
                ) : (
                  <i className={"fa fa-envelope-open"}></i>
                )}
                {proposalTender?.metode_penyampaian_step === 0
                  ? intl.formatMessage({id:"Pembukaan Penawaran Teknis"})
                  : intl.formatMessage({id:"Pembukaan Penawaran Komersil"})
                }
              </button>
            )}

            {isShowTutupEvaluasiAdministrasi() && (
              <button
                type={"button"}
                disabled={isLoading}
                className={"btn btn-primary me-3"}
                onClick={onTutupEvaluasiAdministrasi}
              >
                {(isLoading && isLoadingCheck) ? (
                  <Spinner animation="border" role="status" size={"sm"} />
                ) : (
                  <i className={"fa fa-envelope"}></i>
                )}
                {intl.formatMessage({id:"Tutup"+" "+proposalTender?.status})}
              </button>
            )}
          </>
        } />
      </div>
    </>
  );
};

const EvaluasiTeknisDuaTahapPage = ({proposalTender, onQuotationChanged, isOwner}:any) => {
  const {toolbarEvaluasi, setToolbarEvaluasi} = useProposalTender()

  useEffect(() => {
    setToolbarEvaluasi((prevValue: ToolbarInterface) => ({
      ...prevValue,
      show: true
    }));
  }, [])

  return (
    <>
      <>
        {toolbarEvaluasi?.currentTab === 0 && (
          <EvaluasiTeknisDuaTahapPageTab
            proposalTender={proposalTender}
            onQuotationChanged={onQuotationChanged}
            isOwner={isOwner}
          />
        )}

        {toolbarEvaluasi?.currentTab === 1 && (
          <EvaluasiUserTab
            proposalTender={proposalTender}
          />
        )}

        {toolbarEvaluasi?.currentTab === 2 && (
          <KlarifikasiVendorTab
            proposalTender={proposalTender}
          />
        )}
      </>
    </>
  )
}

export default EvaluasiTeknisDuaTahapPage
