import { ID, KTCard, KTCardBody } from '../../../../../../_metronic/helpers'
import React, { useState, useRef } from 'react'
import { Link } from 'react-router-dom';
import TableXWrpper from "../../../../../components/shared/TableXWrapper";
import { ApiUrl } from '../../../../../config/Api'
import { getEnv } from "../../../../../../helpers/procurex";
import { Field, Form, Formik } from 'formik'
import { useIntl } from "react-intl";
import SelectX from '../../../../../components/shared/form/SelectX'
import FormikPublicSelect from '../../../../home/vendor-register/components/FormikPublicSelect'
import { toast } from 'react-toastify'
import axios from 'axios'
import { MetronicErrorMessage, toastError } from '../../../../../../_metronic/helpers'
import { Button, ButtonGroup, Col, Modal, Row } from "react-bootstrap";
import { useAuth } from "../../../../../../app/modules/auth";
import SwalX from '../../../../../components/shared/SwalX'

const JenisPublikasiStockViewList = () => {
    const { currentUser } = useAuth();
    const intl = useIntl();
    const tablecolumns = [
        {
            Header: "Nama Jenis",
            accessor: "nama_jenis",
            width: 250,
        },
        {
            Header: "Action",
            accessor: "uuid",
            width: 180,
            className: "text-center fixed-column",
            Cell: (props: any) => {
                const row = props.row.original;
                return (
                    <ButtonGroup size='sm' aria-label='Action'>
                        {!excludedRoles.some(r => role.includes(r)) && (
                            <>
                                <button
                                    type='button'
                                    className='btn btn-sm btn-success'
                                    onClick={(e: any) => {
                                        e.preventDefault();
                                        setId(row.id);
                                        openModalWithItem(row);
                                    }}
                                >
                                    Edit
                                </button>
                                <button
                                    type='button'
                                    className='btn btn-sm btn-danger'
                                    onClick={() => {
                                        setItemId(props.row.original.id)
                                        setIsConfirmDeleteOpen(true)
                                    }}
                                >
                                    <i className={'fa fa-trash'} />
                                </button>
                            </>
                        )}
                    </ButtonGroup>
                );
            },
        },
    ];

    const [formIndex, setFormIndex] = useState(0);
    const [isShowModal, setIsShowModal] = useState(false)
    const [isShowDetail, setIsShowDetail] = useState(false)
    const [key, setKey] = useState(0)
    const [id, setId] = useState('')
    const [item, setItem] = useState<any>(null)
    const formRef = useRef({} as any)
    const [isConfirmDialog, setIsConfirmDialog] = useState(false)
    const excludedRoles = ['admin_anper'];
    let [itemId, setItemId] = useState(0)
    let [isLoadingDelete, setIsLoadingDelete] = useState(false)
    let [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false)

    const submit = async (values: any) => {
        try {
            const response = await axios.put(getEnv("stockview") + `jenis-publikasi/${id}`, values)

            if (response.status === 200 || response.status === 201) {
                toast.success(response.data.meta.message)
                setId('')
                setIsShowModal(false)
                setFormIndex(formIndex + 1)
                setKey(key + 1)
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.meta?.message || 'Terjadi kesalahan')
        } finally {
            setIsConfirmDialog(false)
            setIsShowDetail(false)
        }
    }

    const deleteItem = async () => {
        try {
            setIsLoadingDelete(true)
            const response: any = await axios.delete(getEnv("stockview") + `/jenis-publikasi/${itemId}`)

            if (response.status === 200) {
                setFormIndex(formIndex + 1)
                setIsConfirmDeleteOpen(false)
                toast.success(response?.data?.meta?.message, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                })
            }
        } catch (error: any) {
            console.error(error)
            toastError(error.response?.data?.meta?.message || 'Terjadi kesalahan')
        } finally {
            setIsLoadingDelete(false)
        }
    }

    const [initialValues, setInitialValues] = useState<any>({
        active_app: item?.active_app || '',
    })

    const openModalWithItem = (item: any) => {
        setInitialValues({
            nama_jenis: item?.nama_jenis || ''
        });
        setItem(item);
        setIsShowModal(true);
    };

    let role = currentUser.has_roles;

    return (
        <>
            <SwalX
                title={intl.formatMessage({ id: 'DATATABLE.CONFIRM_DELETE' })}
                message={intl.formatMessage({ id: 'DATATABLE.MESSAGE_DELETE' })}
                show={isConfirmDeleteOpen}
                loading={isLoadingDelete}
                onHide={() => setIsConfirmDeleteOpen(false)}
                onSubmit={() => deleteItem()}
            />

            <Modal show={isShowModal} onHide={() => setIsShowModal(false)}>
                <Modal.Header>Edit Jenis Publikasi</Modal.Header>
                <Modal.Body>
                    <Formik initialValues={initialValues} onSubmit={submit} enableReinitialize>
                        {({ values, setFieldValue }) => (
                            <Form>
                                <label htmlFor='' className='form-label'>
                                    Nama Jenis Publikasi
                                </label>
                                <Field className='form-control' name="nama_jenis" placeholder="input nama jenis.." value={values.nama_jenis} />

                                <div className='d-flex gap-4 mt-5'>
                                    <button type='submit' className='btn btn-primary'>
                                        Save
                                    </button>
                                    <button
                                        type='button'
                                        className='btn btn-secondary'
                                        onClick={() => {
                                            setId('')
                                            setIsShowModal(false)
                                        }}
                                    >
                                        Batal
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>

            <KTCard className='card border mt-5'>
                <KTCardBody >
                    <TableXWrpper
                        title={"Material Master Data"}
                        key={formIndex}
                        readonly
                        urlIndex={getEnv("stockview") + `/jenis-publikasi`}
                        columns={tablecolumns}
                        headerButtons={
                            !excludedRoles.some(r => role.includes(r)) && (
                                <>
                                    <Link
                                        to="/stockview/masterdata/jenis-publikasi/create"
                                        className="btn btn-primary"
                                        title="Add Data"
                                    >
                                        Add Data
                                    </Link>
                                </>
                            )
                        }
                    />
                </KTCardBody>
            </KTCard>
        </>
    )
}

export { JenisPublikasiStockViewList }
