import {useIntl} from 'react-intl'
import React, {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react'
import TableXWrapper from '../../../../../../../components/shared/TableXWrapper'
import {formatDateTimeToDBFormat, getEnv, parseNumberToCurrency} from '../../../../../../../../helpers/procurex'
import {Animation, Nav, Placeholder} from 'rsuite'
import FormAttachment from '../../../../../../../components/shared/form/FormAttachment'
import {useMutation, useQuery} from 'react-query'
import axios from 'axios'
import PreviewAttachment from '../../../../../../../components/PreviewAttachment'
import {useFormik} from 'formik'
import Swal from 'sweetalert2'
import {Spinner} from 'react-bootstrap'
import {toast} from 'react-toastify'
import {useProposalTender} from '../../../core/_proposalTenderProvider'

const API_URL = getEnv('tender')


//region context
const KlarifikasiPenawaranContext = createContext({
  vendors: [],
  klarifikasi: null,
  proposalTender: null,
  isLoading: false,
  isCanSubmit: false,
  syaratAdministrations: [],
  syaratTechnicals: [],
  syaratCommercials: [],
  syaratItems: [],
  vendorKlarifikasi: null,
  isLoadingSubmitKlarifikasi: false,
  history: [],
  isLoadingGetHistory: false
} as any)

const KlarifikasiProvider = ({children, proposalTender, vendorKlarifikasi}: any) => {
  const [klarifikasi, setKlarifikasi] = useState({} as any)
  const [isCanSubmit,setIscanSubmit] = useState(false)
  const [syaratAdministrations, setSyaratAdministrations] = useState([] as any)
  const [syaratCommercials, setSyaratCommercials] = useState([] as any)
  const [isLoading, setIsLoading] = useState(false)
  const [syaratItems, setSyaratItems] = useState([] as any)
  const [history, setHistory] = useState([] as any)
  const {refetchProposalTender} = useProposalTender()


  //region submit klarifikasi
  const {mutate, isLoading: isLoadingSubmitKlarifikasi} = useMutation({
    mutationKey: ['submit-klarifikasi'],
    mutationFn: ({payload}:any) => axios.post(
      `${API_URL}/clarification/vendor`,
      payload,
    ),
    onSuccess: (res) => {
      toast.success(res?.data?.meta?.message)
      refetchProposalTender()
      setIscanSubmit(false)
    },
    onError: (error) => {
      // @ts-ignore
      toast.error(error?.response?.data?.message || "failed evalute to vendor")
    },
  })
  const handleOnSubmitKlarifikasi = (payload: any) => {
    mutate({payload})
  }
  //endregion

  //region get syarat commercials
  const {mutate: mutateGetSyaratAdministration, isLoading: isLoadingSyaratAdministration} = useMutation({
    mutationKey: ['quotation-syarat-administration', proposalTender?.uuid, vendorKlarifikasi?.uuid],
    mutationFn: () => axios.get(
      API_URL + `/evaluation/administration/${proposalTender?.uuid}/${vendorKlarifikasi?.uuid}`,
    ),
    onSuccess: (res) => {
      setSyaratAdministrations(res?.data?.data)
    },
    onError: (error:any) => {

    },
  })
  //endregion

  //region get syarat commercials
  const {data: syaratTechnicals, isLoading: isLoadingSyaratTechnical} = useQuery({
    queryKey: ['quotation-syarat-technical', proposalTender?.uuid, vendorKlarifikasi?.uuid],
    queryFn: () => axios
      .get(API_URL + `/evaluation/technical/${proposalTender?.uuid}/${vendorKlarifikasi?.uuid}`)
      .then((res) => res?.data?.data)
      .catch((error) => {
        if (axios.isAxiosError(error) && error.response?.status === 404 && error.response.data.meta?.message === 'Syarats is empty') {
          return
        }

        throw error
      }),
    enabled: proposalTender?.uuid !== undefined && vendorKlarifikasi?.uuid !== undefined,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  })
  //endregion

  //region get syarat commercials
  const {mutate: mutateGetSyaratCommercial, isLoading: isLoadingSyaratCommercial} = useMutation({
    mutationKey: ['quotation-syarat-commercial', proposalTender?.uuid, vendorKlarifikasi?.uuid],
    mutationFn: () => axios.get(
      API_URL + `/evaluation/commercial/${proposalTender?.uuid}/${vendorKlarifikasi?.uuid}`,
    ),
    onSuccess: (res) => {
      setSyaratCommercials(res?.data?.data)
    },
    onError: (error:any) => {

    },
  })
  //endregion

  //region get syarat items
  const {mutate: mutateGetSyaratItem, isLoading: isLoadingSyaratItem} = useMutation({
    mutationKey: ['quotation-syarat-item', proposalTender?.uuid, vendorKlarifikasi?.uuid],
    mutationFn: () => axios.get(
      API_URL + `/evaluation/item/${proposalTender?.uuid}/${vendorKlarifikasi?.uuid}`,
    ),
    onSuccess: (res) => {
      setSyaratItems(res?.data?.data)
    },
    onError: (error:any) => {

    },
  })
  //endregion

  //region get history
  const {mutate: mutateGetHistory, isLoading: isLoadingGetHistory} = useMutation({
    mutationKey: ['get-history', proposalTender?.uuid, vendorKlarifikasi?.uuid],
    mutationFn: () => axios.get(
      API_URL+ `/clarification/vendor/history/${proposalTender?.uuid}/${vendorKlarifikasi?.uuid}?include=items,items.syaratEvaluation.syarat,items.quotationItem.serviceLines,items.quotationItem.quotation,proposalTenderVendor,items.attachment`,
    ),
    onSuccess: (res) => {
      setHistory(res?.data?.data)
    },
    onError: (error:any) => {

    },
  })
  //endregion


  useEffect(() => {
    // if(proposalTender?.status_detail !== "Klarifikasi Vendor") {
    // alert(vendorKlarifikasi?.latest_clarification?.status)
      setIscanSubmit(vendorKlarifikasi?.latest_clarification?.status === "On Progress" ? false : true)
    // } else {
    //   setIscanSubmit(false)
    // }
  }, [vendorKlarifikasi])

  useEffect(() => {
    if(isLoadingSubmitKlarifikasi || isLoadingSyaratAdministration || isLoadingSyaratTechnical || isLoadingSyaratCommercial || isLoadingSyaratItem) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }, [isLoadingSubmitKlarifikasi, isLoadingSyaratAdministration, isLoadingSyaratTechnical, isLoadingSyaratCommercial, isLoadingSyaratItem])


  useEffect(() => {
    if (!proposalTender?.uuid || !vendorKlarifikasi?.uuid) return
    mutateGetSyaratAdministration()
    mutateGetSyaratCommercial()
    mutateGetSyaratItem()
    mutateGetHistory()
  }, [proposalTender, vendorKlarifikasi])


  return (
    <KlarifikasiPenawaranContext.Provider value={{
      klarifikasi,
      isLoading,
      isCanSubmit,
      handleOnSubmitKlarifikasi,
      proposalTender,
      syaratAdministrations,
      syaratTechnicals: syaratTechnicals ?? [],
      syaratCommercials,
      syaratItems,
      vendorKlarifikasi,
      isLoadingSubmitKlarifikasi,
      history,
      isLoadingGetHistory
    }}>
      {children}
    </KlarifikasiPenawaranContext.Provider>
  )
}

const useKlarifikasiPenawaran = () => {
  return useContext(KlarifikasiPenawaranContext)
}
//endregion

const KlarifikasiSyaratTable = ({title, type, isDisabled, defaultValue, onChange}) => {
  const intl = useIntl()
  const [previewAttachment, setPreviewAttachment] = useState('')
  const [selectedList,setSelectedList] = useState(defaultValue ?? [])
  const {syaratAdministrations, syaratTechnicals, syaratCommercials, isLoading} = useKlarifikasiPenawaran()
  const [syarats, setSyarats] = useState([])

  useEffect(() => {
    if(type === 'administration') {
      setSyarats(syaratAdministrations.concat(syaratTechnicals))
    } else {
      setSyarats(syaratCommercials)
    }
  }, [syaratAdministrations, syaratTechnicals, syaratCommercials])

  const handleOnCheck = (e, row) => {
    const formatted = {
      uuid: row?.uuid,
      buyer_note: '',
    }
    if(e.target.checked) {
      setSelectedList([...selectedList, formatted])
    } else {
      setSelectedList(selectedList.filter((v) => v?.uuid !== row?.uuid))
    }
  }

  const handleOnTextChange = (e, row) => {
    const formatted = {
      uuid: row?.uuid,
      buyer_note: e.target.value,
    }
    setSelectedList(selectedList.map((v) => {
      if(v?.uuid === row?.uuid) {
        return formatted
      }
      return v
    }))
  }

  useEffect(() => {
    onChange(selectedList)
  },[selectedList])

  useEffect(() => {
    if(!defaultValue) return ;
    setSelectedList(defaultValue)
  }, [defaultValue])


  if(isLoading) {
    return <Placeholder.Grid columns={5} rows={3} active />
  }


  return (
    <div className={'form-group mb-8 mt-8'}>
      <label className={'mb-2'}>
        <h4>
          <strong>{title}</strong>
        </h4>
      </label>
      <div>
        <table className={'table table-bordered table-striped table-header-gray'}>
          <thead>
          <tr>
            <th className={'px-2 border text-center'}
              //@ts-ignore
                width={50}>{intl?.formatMessage({id: '#'})}</th>
            <th
              className={'px-2 border'}
              //@ts-ignore
              width={30}
            >{intl?.formatMessage({id: 'No'})}</th>
            <th
              className={'px-2 border'}
              //@ts-ignore
              width={150}
            >{intl?.formatMessage({id: 'Tipe Penawaran'})}</th>
            <th className={'px-2 border'}
                //@ts-ignore
                width={300}>{intl?.formatMessage({id: 'Deskripsi'})}</th>
            <th className={'px-2 border'}>{intl?.formatMessage({id: 'Notes Klarifikasi Buyer'})}</th>
          </tr>
          </thead>
          <tbody>
          {syarats?.map((v: any, i: number) => {
            return (
              <tr key={'syarat-' + v?.id}>
                <td className={'px-2 text-center border'}
                  //@ts-ignore
                    width={50}>
                  <input
                    type={'checkbox'}
                    className={'form-check-input'}
                    disabled={isDisabled}
                    onChange={(e)=>handleOnCheck(e,v)}
                    checked={selectedList.find((item:any) => item?.uuid === v?.uuid)}
                  />
                </td>
                <td className={'px-2 text-center border'}>{i + 1}</td>
                <td className={'px-2 text-capitalize border'}>{v?.type}</td>
                <td className={'px-2 border'}>
                  {v?.description}
                  <hr />
                  <a
                    href={'javascript:;'}
                    onClick={() => setPreviewAttachment(v?.media_url)}
                    style={{fontWeight: 'bold'}}
                    className={'text-primary'}
                  >
                    <i className={'fa fa-chevron-right me-2'}></i>
                    Lihat File
                  </a>
                </td>
                <td className={"border px-2"}>
                  <textarea
                    className={'form-control'}
                    onChange={(e)=>handleOnTextChange(e,v)}
                    defaultValue={selectedList.find((item) => item?.uuid === v?.uuid)?.buyer_note}
                    disabled={!selectedList.find((item) => item?.uuid === v?.uuid) || isDisabled}
                  ></textarea>
                </td>
              </tr>
            )
          })}
          </tbody>
        </table>
        <PreviewAttachment
          previewExt={previewAttachment}
          onHide={() => setPreviewAttachment('')}
        />
      </div>
    </div>
  )
}

const ColumnBarangJasa = ({item}: any) => {
  const intl = useIntl()
  const [isShow,setIsShow] = useState(false)
  return (
    <>
      <a href={"javascript:;"} className={'text-primary'} onClick={()=>setIsShow(!isShow)}>
        {isShow && <i className={'fa fa-chevron-down me-2'}></i>}
        {!isShow && <i className={'fa fa-chevron-right me-2'}></i>}
        {item?.short_text}
      </a>
      <Animation.Collapse in={isShow}>
        {(props, ref) => (
          <div {...props} ref={ref}>
          <div className={'d-flex flex-column gap-2 mt-4'}>
            {(item && item?.service_lines_quotation?.length > 0) && (
              <>
                {item?.service_lines_quotation?.map((service: any, serviceIndex: number) => {
                  return (
                    <div key={`service-${serviceIndex}`} className={'d-flex flex-column gap-2 border-bottom'}>
                      <div className={'d-flex gap-2'}>
                        <div>{parseInt(service?.line_no)}</div>
                        <div>/</div>
                        <div>{(service?.short_text)}</div>
                      </div>

                    </div>
                  )
                })}
              </>
            )}

            <div className={'d-flex gap-2'}>
              <div><strong>{intl?.formatMessage({id: 'Status Quote'})}</strong> :</div>
              <div>{item?.quote}</div>
            </div>
            <div className={'d-flex gap-2'}>
              <div><strong>{intl?.formatMessage({id: 'Status Evaluation'})}</strong> :</div>
              <div>{item?.status_evaluation}</div>
            </div>
            <div className={'d-flex gap-2'}>
              <div><strong>{intl?.formatMessage({id: 'Sub Total'})}</strong> :</div>
              <div>{parseNumberToCurrency(item?.sub_total, item?.currency?.code)}</div>
            </div>
            <div className={'d-flex gap-2'}>
              <div><strong>{intl?.formatMessage({id: 'Total'})}</strong> :</div>
              <div>{parseNumberToCurrency(item?.total_value, item?.currency?.code)}</div>
            </div>

          </div>
          </div>
        )}

      </Animation.Collapse>
    </>
  )
}

const KlarifikasiItemTeknisTable = ({proposalTender, isDisabled,vendorKlarifikasi, defaultValue, onChange}) => {
  const intl = useIntl()

  const [selectedList,setSelectedList] = useState(defaultValue ?? [])
  const {syaratItems, isLoading} = useKlarifikasiPenawaran()


  const handleOnCheck = (e, row) => {
    const formatted = {
      uuid: row?.uuid,
      buyer_note: '',
    }
    if(e.target.checked) {
      setSelectedList([...selectedList, formatted])
    } else {
      setSelectedList(selectedList.filter((v) => v?.uuid !== row?.uuid))
    }
  }

  const handleOnTextChange = (e, row) => {
    const formatted = {
      uuid: row?.uuid,
      buyer_note: e.target.value,
    }
    setSelectedList(selectedList.map((v) => {
      if(v?.uuid === row?.uuid) {
        return formatted
      }
      return v
    }))
  }

  useEffect(() => {
    onChange(selectedList)
  },[selectedList])

  useEffect(() => {
    if(!defaultValue) return ;
    setSelectedList(defaultValue)
  }, [defaultValue])

  if(isLoading) {
    return <Placeholder.Grid columns={5} rows={3} active />
  }

  return (
    <div className={'form-group mb-8 mt-8'}>
      <h4>
        <strong>{intl.formatMessage({id:"Klarifikasi Item Teknis"})}</strong>
      </h4>
      <div>
        <table className={'table table-bordered table-striped table-header-gray'}>
          <thead>
          <tr>
            <th className={'px-2 border text-center'}
              //@ts-ignore
                width={50}>
              {intl?.formatMessage({id: '#'})}
            </th>
            <th className={'px-2 border text-center'}
              //@ts-ignore
                width={30}
            >
              {intl?.formatMessage({id: 'No'})}
            </th>
            <th className={'px-2 border'}
                //@ts-ignore
                width={450}>{intl?.formatMessage({id: 'Nama Barang/Jasa'})}</th>
            <th className={'px-2 border'}>{intl?.formatMessage({id: 'Notes Klarifikasi'})}</th>
          </tr>
          </thead>
          <tbody>
          {syaratItems?.map((item: any, itemIndex: number) => {
            return (
              <tr key={`klarifikasi-items-${proposalTender?.uuid}-${vendorKlarifikasi?.uuid}-${itemIndex}`}>
                <td className={'px-2 text-center border'}
                  //@ts-ignore
                    width={50}>
                  <input
                    type={'checkbox'}
                    className={'form-check-input'}
                    disabled={isDisabled}
                    onChange={(e) => handleOnCheck(e, item)}
                    checked={selectedList.find((sl:any) => item?.uuid === sl?.uuid)}
                  />
                </td>
                <td className={'text-center border px-2'}>{itemIndex + 1}</td>
                <td className={''}>
                  <ColumnBarangJasa item={item} />
                </td>
                <td className={'border px-2'}>
                  <textarea
                    className={'form-control'}
                    onChange={(e) => handleOnTextChange(e, item)}
                    defaultValue={selectedList.find((sl) => item?.uuid === sl?.uuid)?.buyer_note}
                    disabled={!selectedList.find((sl) => item?.uuid === sl?.uuid) || isDisabled}
                  ></textarea>
                </td>
              </tr>
            )
          })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

const ProsesKlarifikasiTab = () => {
  const intl = useIntl()
  const {
    handleOnSubmitKlarifikasi,
    isLoading,
    isCanSubmit,
    proposalTender,
    vendorKlarifikasi,
    isLoadingSubmitKlarifikasi
  } = useKlarifikasiPenawaran()

  const initialValues = useMemo(() => ({
    buyer_note: vendorKlarifikasi?.latest_clarification === "On Going" ? (vendorKlarifikasi?.latest_clarification?.buyer_note || '') : '',
    vendor_note: vendorKlarifikasi?.latest_clarification === "On Going" ? (vendorKlarifikasi?.latest_clarification?.vendor_note || '') : '',
    attachment_url: '',
    proposal_tender_id: proposalTender?.uuid || '',
    proposal_tender_vendor_id: vendorKlarifikasi?.uuid || '',
    syarat_evaluations_administration: [],
    syarat_evaluations_commercial: [],
    quotation_items: [],
  }), [vendorKlarifikasi, proposalTender])

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: useCallback((values) => {
      Swal.fire({
        title: intl.formatMessage({id: 'Apakah anda yakin?'}),
        text: intl.formatMessage({id: 'Anda akan mengirim klarifikasi ke vendor'}),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: intl.formatMessage({id: 'Ya, Kirim'}),
        cancelButtonText: intl.formatMessage({id: 'Batal'}),
      }).then((result) => {
        if (result.isConfirmed) {
          const syarats = [
            ...values?.syarat_evaluations_administration,
            ...values?.syarat_evaluations_commercial,
          ].map((v:any) => ({
            ...v,
            id: v?.uuid,
            uuid: undefined
          }));
          const quotation_items = values?.quotation_items?.map((v:any)=>({
              ...v,
              id: v?.uuid,
              uuid: undefined
          }))

          const payload = {
            //@ts-ignore
            ...values,
            quotation_items,
            syarats,
          }
          delete payload.syarat_evaluations_administration
          delete payload.syarat_evaluations_commercial
          handleOnSubmitKlarifikasi(payload)
        }
      })
    }, [intl, handleOnSubmitKlarifikasi])
  })
  useEffect(() => {
    if(!vendorKlarifikasi) return ;
    if(vendorKlarifikasi?.latest_clarification?.status === "On Progress") {
      formik.setFieldValue('buyer_note', vendorKlarifikasi?.latest_clarification?.buyer_note)
      formik.setFieldValue('vendor_note', vendorKlarifikasi?.latest_clarification?.vendor_note)
      formik.setFieldValue('attachment_url', vendorKlarifikasi?.latest_clarification?.attachment?.media_url)
      let _syaratAdministrationDefault  = []
      let _syaratCommercialDefault = []
      let _quotationItem = []
      vendorKlarifikasi?.latest_clarification?.items?.map((v:any)=>{
        if(v?.syarat_evaluation?.type === "administration"){
          _syaratAdministrationDefault.push({
            uuid: v?.syarat_evaluation?.syarat?.uuid,
            buyer_note: v?.buyer_note
          })
        }
        if(v?.syarat_evaluation?.type === "commercial"){
          _syaratCommercialDefault.push({
            uuid: v?.syarat_evaluation?.syarat?.uuid,
            buyer_note: v?.buyer_note
          })
        }
        if(v?.quotation_item){
          _quotationItem.push({
            uuid: v?.quotation_item?.uuid,
            buyer_note: v?.buyer_note
          })
        }
      })
      formik.setFieldValue("syarat_evaluations_administration",_syaratAdministrationDefault)
      formik.setFieldValue("syarat_evaluations_commercial",_syaratCommercialDefault)
      formik.setFieldValue("quotation_items",_quotationItem)
    }
  }, [vendorKlarifikasi])

  const showAdministrationAndTechnical = [0, 1].includes(proposalTender?.metode_penyampaian_step)
  const showCommercial = [0, 2].includes(proposalTender?.metode_penyampaian_step)

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className={'row'}>
          <div className={'col-md-12'}>
            <div className={'form-group mb-8'}>
              <label className={'mb-2'}><strong>{intl?.formatMessage({id: 'Note Klarifikasi Buyer'})}</strong></label>
              <textarea
                className={'form-control'}
                rows={5}
                onChange={formik.handleChange}
                name={'buyer_note'}
                value={formik.values.buyer_note}
                disabled={isLoading || !isCanSubmit || isLoadingSubmitKlarifikasi}
              ></textarea>
            </div>
            <div className={'form-group mb-8'}>
              <label className={'mb-2'}><strong>{intl?.formatMessage({id: 'Attachment'})}</strong></label>
              <FormAttachment
                media_type_filter={'TENDER_ATTACHMENT'}
                afterSubmit={(e: any) => {
                  formik.setFieldValue('attachment_url', e.url)
                }}
                onlyFile={true}
                isRequired={false}
                isDisabled={!isCanSubmit}
                isHideLabel={true}
                isSmall={true}
                defaultValue={{
                  url: formik.values.attachment_url
                }}
              />
            </div>
          </div>
        </div>
        <hr />
        {showAdministrationAndTechnical && <KlarifikasiSyaratTable
          title={intl.formatMessage({id: 'Klarifikasi Penawaran Administrasi & Teknis'})}
          type={'administration'}
          isDisabled={!isCanSubmit}
          defaultValue={formik.values.syarat_evaluations_administration}
          onChange={(e) => {
            formik.setFieldValue('syarat_evaluations_administration', e)
          }}
        />}

        {showCommercial && <KlarifikasiSyaratTable
          title={intl.formatMessage({id: 'Klarifikasi Penawaran Komersil'})}
          type={'commercial'}
          isDisabled={!isCanSubmit}
          defaultValue={formik.values.syarat_evaluations_commercial}
          onChange={(e) => {
            formik.setFieldValue('syarat_evaluations_commercial', e)
          }}
        />}

        {showAdministrationAndTechnical && <KlarifikasiItemTeknisTable
          proposalTender={proposalTender}
          vendorKlarifikasi={vendorKlarifikasi}
          isDisabled={!isCanSubmit}
          defaultValue={formik.values.quotation_items}
          onChange={(e) => {
            formik.setFieldValue('quotation_items', e)
          }}
        />}

        <div className={'form-group text-end'}>
          <button
            className={'btn btn-primary'}
            type={'submit'}
            disabled={isLoading || !isCanSubmit || isLoadingSubmitKlarifikasi}
          >
            {!isLoadingSubmitKlarifikasi && <i className={'fa fa-paper-plane me-2'}></i>}
            {isLoadingSubmitKlarifikasi && <Spinner animation="border" size="sm" />}
            {intl.formatMessage({id: 'Klarifikasi ke Vendor'})}
          </button>
        </div>
      </form>

    </div>
  )
}

const RiwayatKlarifikasiTab = () => {
  const intl = useIntl();
  const {
    isLoadingGetHistory,
    history
  } = useKlarifikasiPenawaran()
  const [currentPreview,setCurrentPreview] = useState(null as string)

  const getBackground = (rowHistory:any) => {
    if(rowHistory.session % 2 === 0){
      return 'bg-light-info'
    }
    return '';
  }

  if(isLoadingGetHistory) {
    return <Placeholder.Grid
      rows={10}
      columns={4}
      active={true}
    />
  }

  return (
    <div className={"table-responsive"}>
      <table className={"table"}>
        <thead>
        <tr>
          <th className={'border p-2'}>
            <strong>{intl?.formatMessage({id: '#'})}</strong>
          </th>

          <th className={'border p-2'}
          //@ts-ignore
              width={200}
          >
            <strong>{intl?.formatMessage({id: 'Item Klarifikasi'})}</strong>
          </th>
          <th className={'border p-2 text-nowrap'}
            //@ts-ignore
              width={200}
          >
            <strong>{intl?.formatMessage({id: 'Notes dari Buyer'})}</strong>
          </th>
          <th className={'border p-2'}
            //@ts-ignore
            width={200}
          >
            <strong>{intl?.formatMessage({id: 'Jawaban Klarifikasi'})}</strong>
          </th>
        </tr>
        </thead>
        <tbody>

        {history?.map((rowHistory:any,i:number)=>{
          return (
            <>
              <tr className={`border ${getBackground(rowHistory)}`} style={{
                border:"5px solid"
              }}>
                <td className={'border p-2'} rowSpan={rowHistory?.items?.length}>
                  <div className={'d-flex flex-column'}>
                    <div className={'border-bottom py-1'}>
                      <div><small><strong>Sesi</strong></small></div>
                      <div>{rowHistory?.session}</div>
                    </div>
                    <div className={'border-bottom py-1'}>
                      <div><small><strong>Vendor</strong></small></div>
                      <div>{rowHistory?.vendor?.vendor?.name}</div>
                    </div>
                    <div className={'border-bottom py-1'}>
                      <div className={"d-flex justify-content-between"}>
                        <div><small><strong>Buyer</strong></small></div>
                        <div className={"text-muted"}><small>{formatDateTimeToDBFormat(rowHistory?.created_at)} WIB</small></div>
                      </div>
                      <div>{rowHistory?.buyer_note}</div>

                    </div>
                    <div className={' py-1'}>
                      <div className={'d-flex justify-content-between'}>
                        <div><small><strong>vendor</strong></small></div>
                        <div className={'text-muted'}><small>{formatDateTimeToDBFormat(rowHistory?.updated_at)} WIB</small></div>
                      </div>
                      <div>{rowHistory?.vendor_note}</div>

                    </div>
                  </div>
                </td>

                <td className={'border p-2'}>
                  <small className={'text-muted'}>
                    {rowHistory?.items[0]?.syarat_evaluation ? rowHistory?.items[0]?.syarat_evaluation?.type : 'Item'}
                  </small> <br />
                  {
                    rowHistory?.items[0]?.syarat_evaluation?.syarat?.description ??
                    rowHistory?.items[0]?.quotation_item?.short_text
                  }
                </td>
                <td className={'border p-2'}>
                  <div>
                    {rowHistory?.items[0]?.buyer_note}
                  </div>

                  {rowHistory?.items[0]?.syarat_evaluation?.media_url && (
                    <div className={"border-top mt-2 pt-2"}>
                      <a href={rowHistory?.items[0]?.syarat_evaluation?.media_url} onClick={(e)=>{
                        e.preventDefault();
                        setCurrentPreview(rowHistory?.items[0]?.syarat_evaluation?.media_url)
                      }}>
                        <small>
                          <strong>
                            <i className={"fa fa-chevron-right me-2"}></i>
                            Lihat File
                          </strong>
                        </small>
                      </a>
                    </div>
                  )}

                </td>
                <td className={'border p-2'}>
                  <div>{rowHistory?.items[0]?.vendor_note}</div>

                  {rowHistory?.items[0]?.attachment?.media_url && (
                    <div className={"border-top mt-2 pt-2"}>
                      <a href={rowHistory?.items[0]?.attachment?.media_url} onClick={(e)=>{
                        e.preventDefault();
                        setCurrentPreview(rowHistory?.items[0]?.attachment?.media_url)
                      }}>
                        <small>
                          <strong>
                            <i className={"fa fa-chevron-right me-2"}></i>
                            Lihat File
                          </strong>
                        </small>
                      </a>
                    </div>
                  )}
                </td>
              </tr>
              {rowHistory?.items?.map((rowHistoryItem:any,j:number) => {
                if(j===0) return;
                return (
                  <tr key={'rowHistoryItem' + j} className={`${getBackground(rowHistory)}`}>
                    <td className={'border p-2'}>
                      <small className={"text-muted"}>
                        {rowHistoryItem?.syarat_evaluation ? rowHistoryItem?.syarat_evaluation?.type : 'Item' }
                      </small> <br />
                      {rowHistoryItem?.syarat_evaluation?.syarat?.description ?? rowHistoryItem?.quotation_item?.short_text}
                    </td>
                    <td className={'border p-2'}>
                      <div>{rowHistoryItem?.buyer_note}</div>

                      {rowHistory?.items[0]?.syarat_evaluation?.media_url && (
                        <div className={"border-top mt-2 pt-2"}>
                          <a href={rowHistory?.items[0]?.syarat_evaluation?.media_url} onClick={(e)=>{
                            e.preventDefault();
                            setCurrentPreview(rowHistory?.items[0]?.syarat_evaluation?.media_url)
                          }}>
                            <small>
                              <strong>
                                <i className={"fa fa-chevron-right me-2"}></i>
                                Lihat File
                              </strong>
                            </small>
                          </a>
                        </div>
                      )}
                    </td>
                    <td className={'border p-2'}>
                      <div>{rowHistoryItem?.vendor_note}</div>

                      {rowHistoryItem?.attachment?.media_url && (
                        <div className={'border-top mt-2 pt-2'}>
                          <a
                            href={rowHistoryItem?.attachment?.media_url}
                            onClick={(e) => {
                              e.preventDefault()
                              setCurrentPreview(rowHistoryItem?.attachment?.media_url)
                            }}
                          >
                            <small>
                              <strong>
                                <i className={'fa fa-chevron-right me-2'}></i>
                                Lihat File
                              </strong>
                            </small>
                          </a>
                        </div>
                      )}
                    </td>
                  </tr>
                )
              })}
            </>
          )
        })}
        </tbody>
      </table>
      <PreviewAttachment previewExt={currentPreview} onHide={()=>setCurrentPreview(null)} />
    </div>
  )
}

const KlarifikasiDetail = () => {
  const intl = useIntl()
  const [selectedTab, setSelectedTab] = useState('1')
  const {vendorKlarifikasi} = useKlarifikasiPenawaran()

  return (
    <div>
      <table className={"table table-striped mb-4"}>
        <thead>
        <tr>
          <th
            // @ts-ignore
            width={150}
          >
            <strong>{intl?.formatMessage({id: 'Vendor'})}</strong>
          </th>
          <td>
            : {vendorKlarifikasi?.vendor?.vendor_no} - {vendorKlarifikasi?.vendor?.name}
          </td>
        </tr>
        <tr>
          <th>
            <strong>{intl?.formatMessage({id: 'Status Terakhir'})}</strong>
          </th>
          <td>
            : {vendorKlarifikasi?.latest_clarification?.status}
          </td>
        </tr>
        </thead>
      </table>
      <hr />
      <Nav
        justified
        appearance={"tabs"}
        activeKey={selectedTab}
        onSelect={(e: any) => {
          setSelectedTab(e)
        }}
        style={{marginBottom: 20}}
      >
        <Nav.Item eventKey={'1'} className={'d-flex justify-content-center'}>
          <div>{intl?.formatMessage({id: 'Proses Klarifikasi'})}</div>
        </Nav.Item>
        <Nav.Item eventKey={'2'} className={'d-flex justify-content-center'}>
          <div>{intl?.formatMessage({id: 'Riwayat Klarifikasi'})}</div>
        </Nav.Item>
      </Nav>

      {selectedTab === '1' &&
        <ProsesKlarifikasiTab />
      }

      {selectedTab === '2' &&
        <RiwayatKlarifikasiTab />
      }

    </div>
  )
}

const KlarifikasiVendorTab = ({proposalTender}) => {
  const intl = useIntl()
  const urlIndex = `${API_URL}/clarification/vendor/latest-all-vendors/${proposalTender?.uuid}?include=items.syaratEvaluation.syarat,items.quotationItem.serviceLines,items.quotationItem.quotation,items.attachment`
  const [currentKlarifikasi, setCurrentKlarifikasi] = useState(null)
  const tableColumns = useMemo(() => [
    {
      Header: intl.formatMessage({id: 'Aksi'}),
      accessor: 'uuid',
      width: 200,
      className: 'text-center',
      Cell: (props: any) => {
        return (
          <>
            <a href={'#'} title={'Lihat Penawaran'} onClick={(e: any) => {
              e.preventDefault()
              setCurrentKlarifikasi(props.row.original)
            }} className={'btn btn-info btn-sm'}>
              <i className={'fa fa-eye'}></i> Lihat Klarifikasi
            </a>
          </>
        )
      },
    },
    {
      Header: intl.formatMessage({id: 'ID Vendor'}),
      accessor: 'vendor_id',
      className: 'text-start',
      width: 120,
    },
    {
      Header: intl.formatMessage({id: 'Nama Vendor'}),
      accessor: 'vendor.name',
      className: 'text-start',
      width: 250,
    },
    {
      Header: intl.formatMessage({id: 'Status'}),
      accessor: 'latest_clarification.status',
      className: 'text-start',
      width: 150,
    },
    {
      Header: intl.formatMessage({id: 'Waktu Aksi Terakhir'}),
      accessor: 'latest_clarification.updated_at',
      width: 250,
      className: 'text-start',
      Cell: (props: any) => {
        if (!props?.row?.original?.latest_clarification?.updated_at)
          return intl.formatMessage({id: 'Belum ada klarifikasi'})
        return formatDateTimeToDBFormat(props?.row?.original?.latest_clarification?.updated_at)
      },
    },

  ], [intl])

  return (
    <div>
      <KlarifikasiProvider
        proposalTender={proposalTender}
        vendorKlarifikasi={currentKlarifikasi}
      >
        <div>
          <h3>{intl.formatMessage({id: 'Klarifikasi Vendor'})}</h3>
          <hr />
          {currentKlarifikasi && (
            <div className={'d-flex flex-column'}>
              <div className={'mb-8'}>
                <button
                  className={'btn btn-danger btn-sm'}
                  onClick={() => setCurrentKlarifikasi(null)}
                  type={'button'}
                >
                  <i className={'fa fa-arrow-left'} />
                  {intl.formatMessage({id: 'Kembali ke list'})}
                </button>
              </div>

              <KlarifikasiDetail />
            </div>
          )}

          {!currentKlarifikasi && (
            <TableXWrapper
              urlIndex={urlIndex}
              columns={tableColumns}
              disableUrlChanged={true}
              hideSearch={false}
              hideExportAs={false}
              disablePaddingCard={true}
              readonly={true}
            />
          )}

        </div>
      </KlarifikasiProvider>
    </div>
  )
}
export default KlarifikasiVendorTab