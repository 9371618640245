import {useState} from "react";
import {Field, Form, Formik} from "formik";
import {Modal, Spinner} from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { MetronicErrorMessage, toastError, toastSuccess } from '../../../../../_metronic/helpers'
import { getEnv } from "../../../../../helpers/procurex";
import Swal from 'sweetalert2'
import axios from "axios";

type IProps = {
  action: any
  po: any
  show: boolean
  onHide: Function
}

export const NoteConfirmationModal = ({ action, po, show, onHide }: IProps) => {
  const navigate = useNavigate()

  const initialValues = {notes: ''}

  const validationSchema = Yup.object({notes: Yup.string().nullable().required('The field is required')})

  const onSubmit = (values:any) => {
    Swal.fire({
      title: "Apakah Anda Yakin?",
      text: `Anda ${action} dengan PO ini?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Ya, ${action}`,
      cancelButtonText: "Tidak"
    }).then(async (result) => {
        if (!result.isConfirmed) return;
        try {
            let payload = {
              confirmation : (action === "Approve") ? true : false,
              notes : values?.notes
            };

            let response: any
            if (po?.status === 'Release' || po?.status === 'Waiting Vendor Approval') response = await axios.post(getEnv("tender") + `/vendor/po/${po?.uuid}`, payload)
            if (po?.status === 'Final Check') response = await axios.post(getEnv("tender") + `/po/submit/${po?.uuid}`, payload)
            toastSuccess(response?.data?.meta?.message)
            navigate('/contract-management/po')
        } catch (error: any) {
            toastError(error?.response?.data?.meta?.message || 'Terjadi kesalahan')
        }
    })
  }

  return (
    <>
      <Modal show={show}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {() => (
            <Form>
              <>
                <Modal.Header onHide={() => onHide(false)}>
                  <h4>Confirmation</h4>
                </Modal.Header>

                <Modal.Body>
                  <div>
                    <label className='form-label mb-3' htmlFor='destination'>
                      Notes Confirmation
                    </label>
                    <Field
                      as={"textarea"}
                      name={"notes"}
                      className={"form-control"}
                    />
                    <MetronicErrorMessage name='notes' />
                  </div>
                </Modal.Body>

                <Modal.Footer>
                  <button className={"btn btn-sm btn-danger"} type={"button"} onClick={() => onHide()}>
                    <i className={"fa fa-times"}></i>
                    Batal
                  </button>
                  <button className={"btn btn-sm btn-primary"} type={"submit"}>
                    <i className={"fa fa-save"}/>
                    Submit
                  </button>
                </Modal.Footer>
              </>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};