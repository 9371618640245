import { Field, Form, Formik } from "formik"
import { ButtonGroup, Col, FormGroup, FormLabel, Row } from "react-bootstrap"
import TableXWrapper from "../../../../components/shared/TableXWrapper"
import { getEnv } from "../../../../../helpers/procurex"
import FormikSelect2 from "../../../../components/shared/form/FormikSelect2"
import FormikUploadFile from "../../../../components/shared/form/FormikUploadFile"
import { useState } from "react"
import * as Yup from 'yup'

interface Props {
    agreement: any
}

const AgreementDetailAttachment = ({ agreement }: Props) => {
    const [key, setKey] = useState(0);

    const tableColumns = [
        {
            Header: 'Action',
            accessor: 'media_url',
            width: 150,
            Cell: (props: any) => (
                <>
                    <ButtonGroup size={'sm'} aria-label='Action'>
                        <button
                            type='button'
                            className='btn btn-sm btn-secondary'
                        >
                            <i className={'fa fa-trash'} />
                        </button>
                        <button
                            type='button'
                            className='btn btn-sm btn-secondary'
                        >
                            <i className={'fa fa-eye'} />
                        </button>
                    </ButtonGroup>
                </>
            ),
        },
        { Header: 'ID', accessor: 'id', width: 50, disableCreate: true, disableEdit: true },
        { Header: 'Tipe Lampiran', accessor: 'attachment_type', width: 500 },
        { Header: 'Deskripsi Lampiran', accessor: 'deskripsi', width: 500 },
    ]

    const validationSchema = Yup.object().shape({})
    const [initialValues, setInitialValues] = useState({})
    const handleSubmit = (values) => { }

    return (
        <div className={'card'}>
            <div className={'card-body'}>
                <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                >
                    {({ errors, touched, values, setFieldValue }) => (
                        <>
                            <Form>
                                <Row>
                                    <Col sm='6'>
                                        <div className='mb-10 mt-5'>
                                            <FormikSelect2
                                                label='Tipe Lampiran'
                                                name={`media_type`}
                                                optionsUrl={getEnv("media") + `/media-type`}
                                                getOptionValue={(opt: any) => opt}
                                                getOptionLabel={(opt: any) =>
                                                    `${opt?.properties?.description} - ${opt?.name}`
                                                }
                                                isDisabled={false}
                                            />
                                        </div>
                                        <div className='mb-10'>
                                            <FormGroup>
                                                <FormLabel>Deskripsi Lampiran</FormLabel>
                                                <Field
                                                    type='text'
                                                    disabled={false}
                                                    className='form-control'
                                                    name='deskripsi'
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className='mb-10'>
                                            <FormikUploadFile
                                                label='File Lampiran'
                                                name='file_buyer_url'
                                                disabled={false}
                                            />
                                        </div>
                                        <div className='d-flex gap-4 justify-content-end mb-5'>
                                            <button type='submit' className='btn btn-primary'>
                                                Save
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </>
                    )}
                </Formik>

                <div className='card border mt-10'>
                    <div className="card-body">
                        <Row>
                            <Col>
                                <TableXWrapper
                                    readonly
                                    key={key}
                                    title={'Test'}
                                    urlIndex={`http://localhost:3011/mock-api/listPo.json`}
                                    columns={tableColumns}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AgreementDetailAttachment