import { Outlet, Route, Routes } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../../_metronic/layout/core'
import React, { useState, useEffect } from 'react'
import { ApiUrl } from '../../../../config/Api'
import { getEnv } from "../../../../../helpers/procurex";
import TableXWrapper from '../../../../components/shared/TableXWrapper'
import axios, { AxiosResponse } from 'axios'
import {
    TransportationVesselHistoryLogList,
} from './components/TransportationVesselHistoryLogList'

const masterData = {
    title: 'Transportation Vessel History Log',
    path: '/stockview/vessel-management/vessel-history-log',
    isSeparator: false,
    isActive: false,
}
const separator = {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
}
const breadcrumbs: Array<PageLink> = [masterData, separator]
const childBreadcrumbs: Array<PageLink> = [
    masterData,
    separator,
    {
        title: 'Transportation Vessel History Log',
        path: '/stockview/vessel-management/vessel-history-log',
        isSeparator: false,
        isActive: false,
    },
    separator,
]

const TransportationVesselHistoryLog = () => {

    // @ts-ignore
    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    index
                    element={
                        <>
                            <PageTitle breadcrumbs={breadcrumbs}>Transportation Vessel History Log</PageTitle>
                            <TransportationVesselHistoryLogList />
                        </>
                    }
                ></Route>
            </Route>
        </Routes>
    )
}

export default TransportationVesselHistoryLog
