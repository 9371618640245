import React, {FC, useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {NavLink, useNavigate} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {darkModeClass} from '../../../../../../helpers/utils'
import ActivityLogDrawer from '../ActivityLogDrawer'
import Swal from 'sweetalert2'
import {getEnv} from '../../../../../../helpers/procurex'
import {useMutation} from 'react-query'
import axios from 'axios'
import {toastError, toastSuccess} from '../../../../../../_metronic/helpers'
import {Placeholder} from 'rsuite'
import {useProposalTender} from '../core/_proposalTenderProvider'
import {useAxiosGet, useAxiosPost} from '../../../../../../hooks/useAxios'
import {ApprovalX} from '../../../../../components/shared/ApprovalX'
import RetenderForm from './tahapan_proposal/RetenderForm'


enum source {
  'PQ' = 'PQ',
  'Proposal Tender' = 'Proposal Tender',
  'Quotation' = 'Quotation',
}

const HEADER_MENU_INDEX_PROPOSAL_TENDER = 1
const HEADER_MENU_INDEX_PROCESS_TENDER = 2
const HEADER_MENU_INDEX_AWARDING_TENDER = 3

type Props = {
  proposalTender?: any,
  onIndexChange?: Function
  onMenuHeaderChange?: Function
  source?: source
  onQuotationChanged?: Function
  onSetKonfigurasiNego?: Function
  onRefresh?: Function
  handleOnBatalTender?: Function
  handleOnRetender?: Function
  handleOnAlihkan?: Function
  defaultMenu?: 0
  active?: boolean
  onUpdateLoading?: any
  afterSaveDraft?: Function
  to?: string
  isOwner?: boolean
}

const RowMenu = ({...props}: any) => {

  const getTextColor = () => {
    if (props?.disabled) return 'text-muted'
    if (props?.warning?.type === 'danger') return 'text-danger'
    return 'text-dark'
  }
  return (
    <div className={'py-3 w-100'} style={{
      fontWeight: 'normal',
      borderLeft: props?.active ? '5px solid #5d78ff' : '',
      paddingLeft: '10px',
    }}>


      <div className={'d-flex justify-content-between w-100'}>
        <div>
          <NavLink to={props?.to}>
            {props?.subMenu && (<strong>{props?.title}</strong>)}
            {!props?.subMenu && (props.title)}
          </NavLink>
          {/*<a href={"#"} onClick={(e) => {*/}
          {/*  e.preventDefault()*/}
          {/*  if(props?.subMenu || props?.disabled) return ;*/}
          {/*  props.onClick(props?.menu);*/}
          {/*}} className={`text-decoration-none ${getTextColor()}`}>*/}
          {/*  {props?.subMenu && (<strong>{props?.title}</strong>)}*/}
          {/*  {!props?.subMenu && (props.title)}*/}
          {/*</a>*/}
        </div>
        {props?.warning?.type === 'danger' && (
          <i className={'fa fa-warning text-danger me-2 blink_me'} title={props?.warning?.message}></i>)}
        {props?.warning?.type === 'info' && (
          <i className={'fa fa-info-circle text-info me-2'} title={props?.warning?.message}></i>)}
      </div>

      {props?.subMenu && (
        props.subMenu.map((menu: any, index: number) => {
          return (
            <RowMenu
              title={menu.title}
              currentMenuTitle={props?.currentMenuTitle}
              key={'proses' + index}
              active={false}
              disabled={menu.disabled}
              onClick={() => props.onClick(
                menu,
              )}
            />
          )
        })
      )}
    </div>
  )
}

const RowMenuLink = ({...props}: any) => {
  const getTextColor = () => {
    if (props?.disabled) return 'text-muted'
    if (props?.warning?.type === 'danger') return 'text-danger'
    return 'text-dark'
  }
  return (
    <NavLink to={props?.to} end onClick={(e) => {
      if (props?.subMenu?.length > 0 || props?.disabled) e.preventDefault()
    }}>
      {({isActive, isPending, isTransitioning}) => (
        <div className={`py-3 w-100 ${getTextColor()}`} style={{
          fontWeight: 'normal',
          borderLeft: isActive ? '5px solid #5d78ff' : '',
          paddingLeft: '10px',
        }}>
          <div className={'d-flex justify-content-between w-100'}>
            <div>

              {props?.subMenu && (<strong>{props?.title}</strong>)}
              {!props?.subMenu && (props.title)}

              {/*<a href={"#"} onClick={(e) => {*/}
              {/*  e.preventDefault()*/}
              {/*  if(props?.subMenu || props?.disabled) return ;*/}
              {/*  props.onClick(props?.menu);*/}
              {/*}} className={`text-decoration-none ${getTextColor()}`}>*/}
              {/*  {props?.subMenu && (<strong>{props?.title}</strong>)}*/}
              {/*  {!props?.subMenu && (props.title)}*/}
              {/*</a>*/}
            </div>
            {props?.warning?.type === 'danger' && (
              <i className={'fa fa-warning text-danger me-2 blink_me'} title={props?.warning?.message}></i>)}
            {props?.warning?.type === 'info' && (
              <i className={'fa fa-info-circle text-info me-2'} title={props?.warning?.message}></i>)}
          </div>
          {props?.subMenu && (
            props.subMenu.map((menu: any, index: number) => {
              return (
                <RowMenuLink
                  title={menu.title}
                  currentMenuTitle={props?.currentMenuTitle}
                  key={'proses' + index}
                  active={false}
                  disabled={menu.disabled}
                  to={menu.to}
                />
              )
            })
          )}
        </div>
      )}
    </NavLink>
  )
}

const SideLeftLayout: FC<Props> = (props) => {
  const {
    setToolbarEvaluasi,
  } = useProposalTender()
  const [selectedMenu, setSelectedMenu] = useState(
    {
      index: 0,
      headerIndex: HEADER_MENU_INDEX_PROPOSAL_TENDER,
      component: null,
    },
  )
  const navigate = useNavigate()
  const [menuHeaderNumber, setMenuHeaderNumber] = useState(1)
  const [currentMenuTitle, setCurrentMenuTitle] = useState('Parameter Pengadaan')
  const [proposalMenu, setProposalMenu] = useState<any>([])
  const [processMenu, setProcessMenu] = useState<any>([])
  const [awardingMenu, setAwardingMenu] = useState<any>([])
  const intl = useIntl()
  const [proposalTenderIdForLog, setProposalTenderIdForLog] = useState<any>(null)
  const {tabActive, setTabActive, refetchProposalTender} = useProposalTender()
  const [isShowRetender,setIsShowRetender] = useState(false);
  const checkIsNegosiasiEnabled = () => {
    const allowedStatus = [
      'Negotiation',
      'Laporan Pengadaan',
      'Pengumuman Pemenang',
      'Penunjukan Pemenang',
      'Purchase Order',
    ]
    return allowedStatus.includes(props.proposalTender.status)
  }

  const checkIsPQEnabled = () => {
    return props.proposalTender.praqualification
  }

  const checkIsProsesTenderEnabled = () => {
    if (props?.proposalTender?.status === 'Pra Qualification') return false
    return true
  }

  const isProcessTabEnabled = () => {
    if (props?.proposalTender?.praqualification) return true
    const allowedStatus = [
      'Penawaran',
      'Pra Qualification',
      'Evaluasi Administrasi',
      'Negotiation',
      'Laporan Pengadaan',
      'Bid Opening',
      'Penawaran Komersil',
      'Evaluasi Komersil',
      'Pengumuman Pemenang',
      'Penunjukan Pemenang',
      'Penawaran Teknikal',
      'DUR',
      'Purchase Order',
      'Published',
      'Registrasi'
    ]
    return allowedStatus.includes(props.proposalTender.status)
  }

  const isAwardingTabEnabled = () => {
    const allowedStatus = [
      'Evaluation Administration',
      'Negotiation',
      'Laporan Pengadaan',
      'Pengumuman Pemenang',
      'Penunjukan Pemenang',
      'Purchase Order',
    ]
    return allowedStatus.includes(props.proposalTender.status)
  }

  const isJadwalEnable = () => {
    if (props?.proposalTender?.status === 'Cancel') return true
    const allowedStatus = [
      'Draft',
      'DUR',
      'Cancel',
      'Pra Qualification',
      'Evaluasi Administrasi',
      'Negotiation',
      'Laporan Pengadaan',
      'Penawaran',
      'Published',
      'Bid Opening',
      'Penawaran Komersil',
      'Evaluasi Komersil',
      'Negotiation',
      'Registrasi',
      'Pengumuman Pemenang',
      'Penawaran Teknikal',
      'Penunjukan Pemenang',
      'Purchase Order',
    ]
    const allowedDetailStatus = [
      'Draft',
      'Quotation',
      'Submit for Approval DUR',
      'Rejected',
      'On Going',
      'Published',
      'Bid Opening',
      'Quotation',
      'Proses Negosiasi',
      'Awarding',
      'Registration',
      'Evaluation',
      'Evaluasi Evaluator',
      'Submit for Approval',
      'Masa Sanggah',
      'Submit for Approval Change Schedule',
      'Submit for Approval Retender',
    ]
    if (props?.proposalTender?.praqualification && props?.proposalTender?.status === 'Draft') {
      return false
    }
    return allowedStatus.includes(props.proposalTender.status)
      && allowedDetailStatus.includes(props.proposalTender.status_detail)

  }

  const isPersyaratanEnable = () => {
    // akan mendisable semua menu jika status draft dan PQ
    const allowedStatus = ['Draft']
    const allowedDetailStatus = ['Draft', 'Rejected']
    return allowedStatus.includes(props.proposalTender.status)
      && allowedDetailStatus.includes(props.proposalTender.status_detail)
      && props.proposalTender.praqualification
  }

  const getWarningDokumenPengadaan = () => {
    if (props?.proposalTender?.status === 'Cancel') return null
    if (props?.proposalTender?.praqualification && props?.proposalTender?.status === 'Draft') return null
    const isThereAnyAttachment = props?.proposalTender?.attachments?.length > 0
    const isThereAnySKV = props?.proposalTender?.attachments?.filter((item: any) => item?.is_skv === true).length > 0

    if (!isThereAnyAttachment || !isThereAnySKV)
      return {type: 'danger', message: 'Dokumen Pengadaan belum diupload atau Belum ada SKV (Share ke Vendor)'}
    return
  }

  const getWarningJadwalTender = () => {
    if (props?.proposalTender?.status === 'Cancel') return null
    if (!props?.proposalTender?.praqualification && props?.proposalTender?.status === 'Draft') {
      let isJadwalValid = props?.proposalTender?.jadwals?.length < 3
      return !isJadwalValid ? null : {type: 'danger', message: 'Jadwal Tender belum diatur'}
    }
    if (props?.proposalTender?.status === 'Pra Qualification') {
      let isJadwalValid = props?.proposalTender?.jadwals?.filter((item: any) => item?.description === 'Pra Qualification').length === 1
      return isJadwalValid ? null : {type: 'danger', message: 'Jadwal Tender belum diatur'}
    }

    return null
  }

  const getWarningPersyaratan = () => {
    if (props?.proposalTender?.status === 'Cancel') return null
    if (!props?.proposalTender?.praqualification && 
      (props?.proposalTender?.status === 'Draft' ||
      (props?.proposalTender?.status === 'DUR' &&
      props?.proposalTender?.status_detail === 'Rejected'))
    ) {
      if (props?.proposalTender?.metode_evaluasi === 'Sistem Nilai') {
        const isTheresConfigurationType = props?.proposalTender?.syarats?.filter((item: any) => item?.type === 'configuration').length > 0
        const isTheresAdministrationType = props?.proposalTender?.syarats?.filter((item: any) => item?.type === 'administration').length > 0
        const isTheresCommercialType = props?.proposalTender?.syarats?.filter((item: any) => item?.type === 'commercial').length > 0
        const isThereMandatoryAdminTechnical = props?.proposalTender?.syarats?.filter((item: any) => item?.type === 'commercial' && item?.required_attachment == true).length > 0
        const isThereMandatoryCommercial = props?.proposalTender?.syarats?.filter((item: any) => item?.type === 'administration' && item?.required_attachment == true).length > 0

        if (isTheresConfigurationType && isTheresAdministrationType && isTheresCommercialType && isThereMandatoryAdminTechnical && isThereMandatoryCommercial) return null

        return {type: 'danger', message: 'Persyaratan belum diatur'}
      } else {
        const isTheresAdministrationType = props?.proposalTender?.syarats?.filter((item: any) => item?.type === 'administration').length > 0
        const isTheresTechnicalType = props?.proposalTender?.syarats?.filter((item:any) => item?.type === "technical").length > 0;
        const isTheresCommercialType = props?.proposalTender?.syarats?.filter((item: any) => item?.type === 'commercial').length > 0
        const isThereMandatoryAdminTechnical = props?.proposalTender?.syarats?.filter((item: any) => item?.type === 'commercial' && item?.required_attachment == true).length > 0;
        const isThereMandatoryTechnical  = props?.proposalTender?.syarats?.filter((item:any) => item?.type === "technical" && item?.required_attachment == true).length > 0
        const isThereMandatoryCommercial = props?.proposalTender?.syarats?.filter((item: any) => item?.type === 'administration' && item?.required_attachment == true).length > 0

        if (isTheresAdministrationType && isTheresTechnicalType && isTheresCommercialType && isThereMandatoryAdminTechnical && isThereMandatoryCommercial && isThereMandatoryTechnical) return null
        return {type: 'danger', message: 'Persyaratan belum diatur'}
      }
    }
    if (props?.proposalTender?.status === 'Pra Qualification') {
      const isTheresConfigurationType = props?.proposalTender?.syarats?.filter((item: any) => (item?.source === 'pra_qualification') && (item?.required_attachment == true)).length > 0
      if (isTheresConfigurationType) return null
      return {type: 'danger', message: 'Persyaratan belum diatur'}
    }
    return null
  }

  const getWarningPanitia = () => {
    // return {type:"danger", message: "Persyaratan belum diatur"}
    return
  }

  const getWarningDUR = () => {
    if (props?.proposalTender?.status === 'Cancel') return null
    if (props?.proposalTender?.praqualification && props?.proposalTender?.status === 'Draft') return null
    if (props?.proposalTender?.status === 'Pra Qualification') return null
    if (props?.proposalTender?.total_dur != 0) return null
    return {type: 'danger', message: 'Daftar Usulan Rekanan belum diatur'}
  }

  interface AttachmentProps {
    proposalTender: any;
    onRefresh?: Function;
  }

  const initProposalMenus = () => {
    // @ts-ignore
    setProposalMenu([
      {
        title: intl.formatMessage({id: 'Parameter Pengadaan'}),
        disabled: false,
        to: `/tender/admin/proposal-tender/detail/${props.proposalTender.uuid}`,
        key: '/',
      },
      {
        title: intl.formatMessage({id: 'Item PR'}),
        disabled: false,
        to: `/tender/admin/proposal-tender/detail/${props.proposalTender.uuid}/item`,
        key: 'item',
      },
      {
        title: intl.formatMessage({id: 'Dokumen Pengadaan'}),
        disabled: !isDokumenPengadaanActive(),
        warning: getWarningDokumenPengadaan(),
        to: `/tender/admin/proposal-tender/detail/${props.proposalTender.uuid}/dokumen`,
        key: 'dokumen',
      },
      {
        title: intl.formatMessage({id: 'Jadwal Tender'}),
        disabled: !isJadwalEnable(),
        // component: <JadwalTenderPage proposalTender={props.proposalTender} onRefresh={props.onRefresh} />,
        to: `/tender/admin/proposal-tender/detail/${props.proposalTender.uuid}/jadwal`,
        warning: getWarningJadwalTender(),
        key: 'jadwal',
      },
      {
        title: intl.formatMessage({id: 'Persyaratan'}),
        disabled: isPersyaratanEnable(),
        // component: <PerysaratanTenderPageV2 proposalTender={props.proposalTender} onRefresh={props?.onRefresh} />,
        to: `/tender/admin/proposal-tender/detail/${props.proposalTender.uuid}/persyaratan`,
        warning: getWarningPersyaratan(),
        key: 'persyaratan',
      },
      {
        title: intl.formatMessage({id: 'Panitia'}),
        disabled: !isPanitiaActive(),
        // component: <PanitiaTenderForm proposalTender={props.proposalTender} />,
        to: `/tender/admin/proposal-tender/detail/${props.proposalTender.uuid}/panitia`,
        warning: getWarningPanitia(),
        key: 'panitia',
      },
      {
        title: intl.formatMessage({id: 'Daftar Usulan Rekanan'}),
        disabled: !isDURActive(),
        // component: <DURPage proposalTender={props.proposalTender} afterSubmit={props?.onRefresh} onUpdateLoading={props.onUpdateLoading} afterSaveDraft={props.afterSaveDraft} />,
        to: `/tender/admin/proposal-tender/detail/${props.proposalTender.uuid}/dur`,
        warning: getWarningDUR(),
        key: 'dur',
      },
    ])
  }

  const initProcessMenus = () => {
    // @ts-ignore
    setProcessMenu([
      {
        title: intl.formatMessage({id: 'Pra Qualification'}),
        disabled: !checkIsPQEnabled(),
        // component: <PraQualificationEvaluatePage proposalTender={props.proposalTender} onRefresh={props?.onRefresh} />,
        to: `/tender/admin/proposal-tender/detail/${props.proposalTender.uuid}/pq`,
      },
      {
        title: intl.formatMessage({id: 'Aanwijzing'}),
        disabled: !checkIsAanwijzingEnabled(),
        // component: <AanwijzingDetail
        //   proposalTender={props.proposalTender}
        // />,
        to: `/tender/admin/proposal-tender/detail/${props.proposalTender.uuid}/aanwijzing`,
      },
      {
        title: intl.formatMessage({id: 'Proses Tender'}),
        disabled: !checkIsProsesTenderEnabled(),
        component: null,
        subMenu: initGetProsesTenderSubMenu(),
        to: `/tender/admin/proposal-tender/detail/${props.proposalTender.uuid}/proses-tender`,
      },
      {
        title: intl.formatMessage({id: 'Negosiasi'}),
        disabled: !checkIsNegosiasiEnabled(),
        // component: <NegosiasiPage proposalTender={props.proposalTender} onSetKonfigurasiNego={props.onRefresh} />,
        to: `/tender/admin/proposal-tender/detail/${props.proposalTender.uuid}/negosiasi`,
      },
      // {title: 'Peserta Tender', disabled: false, component: <PesertaTenderPage proposalTender={props.proposalTender} />},
      // {title: 'Tender', disabled: false, component: <EvaluasiTeknisPage proposalTender={props.proposalTender} onQuotationChanged={props.onQuotationChanged} />},
      // {
      //   title: "Pemilihan Pemenang",
      //   disabled: true
      // }
    ])
  }

  const checkIsEvaluasiKomersilEnabled = () => {
    return props?.proposalTender?.metode_penyampaian_step > 1
  }

  const checkIsAanwijzingEnabled = () => {
    const allowedStatus = [
      'E-Aanwijzing',
    ]
    return allowedStatus.includes(props?.proposalTender?.metode_aanwijzing)
  }

  const checkIsEvaluasiTeknisEnabled = () => {
    // if(props?.proposalTender?.status === "Pra Qualification") return false;
    // const allowedStatus = [

    // ]
    // return true;
    return props?.proposalTender?.metode_penyampaian_step > 0
  }

  const initGetProsesTenderSubMenu = () => {
    const prosesTender2Tahap = [
      {
        title: intl.formatMessage({id: 'Peserta Tender'}),
        disabled: !checkIsProsesTenderEnabled(),
        // component: <PesertaTenderPage proposalTender={props.proposalTender} onQuotationChanged={props.onQuotationChanged} />,
        to: `/tender/admin/proposal-tender/detail/${props.proposalTender.uuid}/proses-tender/peserta`,
      },
      {
        title: intl.formatMessage({id: 'Evaluasi Teknis'}),
        disabled: !checkIsEvaluasiTeknisEnabled(),
        // component: <EvaluasiTeknisDuaTahapPage proposalTender={props.proposalTender} onQuotationChanged={props.onQuotationChanged} />,
        to: `/tender/admin/proposal-tender/detail/${props.proposalTender.uuid}/proses-tender/evaluasi`,
      },
      {
        title: intl.formatMessage({id: 'Evaluasi Komersil'}),
        disabled: !checkIsEvaluasiKomersilEnabled(),
        // component: <EvaluasiKomersilDuaTahapPage proposalTender={props.proposalTender} onQuotationChanged={props.onQuotationChanged} />,
        to: `/tender/admin/proposal-tender/detail/${props.proposalTender.uuid}/proses-tender/evaluasi-komersil`,
      },
    ]
    const prosesTender1Sampul = [
      {
        title: intl.formatMessage({id: 'Peserta Tender'}),
        disabled: !checkIsProsesTenderEnabled(),
        // component: <PesertaTenderPage proposalTender={props.proposalTender} onQuotationChanged={props.onQuotationChanged} />,
        to: `/tender/admin/proposal-tender/detail/${props.proposalTender.uuid}/proses-tender/peserta`,
      },
      {
        title: intl.formatMessage({id: 'Tender'}),
        disabled: !checkIsProsesTenderEnabled(),
        // component: <EvaluasiTeknisSatuSampulPage proposalTender={props.proposalTender} onQuotationChanged={props.onQuotationChanged} />,
        to: `/tender/admin/proposal-tender/detail/${props.proposalTender.uuid}/proses-tender/evaluasi`,
      },
    ]
    const prosesTender2Sampul = [
      {
        title: intl.formatMessage({id: 'Peserta Tender'}),
        disabled: !checkIsProsesTenderEnabled(),
        // component: <PesertaTenderPage proposalTender={props.proposalTender} onQuotationChanged={props.onQuotationChanged} />,
        to: `/tender/admin/proposal-tender/detail/${props.proposalTender.uuid}/proses-tender/peserta`,
      },
      {
        title: intl.formatMessage({id: 'Tender'}),
        disabled: !checkIsProsesTenderEnabled(),
        // component: <EvaluasiTeknisDuaSampulPage proposalTender={props.proposalTender} onQuotationChanged={props.onQuotationChanged} />,
        to: `/tender/admin/proposal-tender/detail/${props.proposalTender.uuid}/proses-tender/evaluasi`,
      },
    ]


    if (props.proposalTender.metode_penyampaian === '2 Tahap') {
      return prosesTender2Tahap
    }
    if (props.proposalTender.metode_penyampaian === '2 Sampul') {
      return prosesTender2Sampul
    }
    return prosesTender1Sampul
  }

  const isAwardingEnabled = () => {
    const allowedStatus = [
      'Laporan Pengadaan',
      'Pengumuman Pemenang',
      'Penunjukan Pemenang',
      'Purchase Order',
    ]
    return allowedStatus.includes(props.proposalTender.status)
  }

  const isPengumumanPemenangEnabled = () => {
    const allowedStatus = [
      'Laporan Pengadaan',
      'Pengumuman Pemenang',
      'Penunjukan Pemenang',
      'Purchase Order',
    ]
    return allowedStatus.includes(props.proposalTender.status) && props.proposalTender.required_pengumuman_pemenang
  }

  const isPenunjukkanPemenangEnabled = () => {
    const allowedStatus = [
      'Penunjukan Pemenang',
      "Laporan Pengadaan",
      'Purchase Order',
    ]
    return allowedStatus.includes(props.proposalTender.status) && props.proposalTender.required_penunjukkan_pemenang
  }
  const initAwardingMenus = () => {
    // @ts-ignore
    setAwardingMenu([
      {
        title: 'Awarding',
        disabled: !isAwardingEnabled(),
        // component: <AwardingPage proposalTender={props.proposalTender} afterSaveDraft={props.afterSaveDraft} onRefresh={props?.onRefresh} />,
        to: '/tender/admin/proposal-tender/detail/' + props.proposalTender.uuid +'/awarding',
      },
      {
        title: 'Pengumuman Pemenang',
        // disabled: !props?.proposalTender?.use_pengumuman_pemenang,
        disabled: !isPengumumanPemenangEnabled(),
        // component: <PengumumanPemenangPage proposalTender={props.proposalTender} onRefresh={() => props?.onRefresh && props.onRefresh()} />,
        to: '/tender/admin/proposal-tender/detail/' + props.proposalTender.uuid +'/pengumuman-pemenang',
      },
      {
        title: 'Penunjukan Pemenang',
        // disabled: !props?.proposalTender?.use_pemilihan_pemenang,
        disabled: !isPenunjukkanPemenangEnabled(),
        // component: <PenunjukkanPemenangPage proposalTender={props.proposalTender} />,
        to: '/tender/admin/proposal-tender/detail/' + props.proposalTender.uuid +'/penunjukkan-pemenang',
      },
      // {
      //   title: "Pemilihan Pemenang",
      //   disabled: true
      // }
    ])
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(props?.proposalTender?.no).then(function() {
      toast.success('Berhasil Copy Nomor Tender')
    }, function(err) {
      toast.error('Gagal Copy Nomor Tender')
    })
  }

  const navigateToPO = () => {
    if (props.proposalTender?.status == 'Purchase Order') {
      navigate(`/tender/admin/purchase-order/detail/${props.proposalTender.purchase_order_no}`)
    } else {
      toast.error('Purchase Order belum tersedia')
    }
  }

  const handleNavigateMenuHeader = (e: number) => {
    if (props.proposalTender.status === 'Draft') {
      toast.error('Tidak dapat mengubah menu header pada status draft')
      return
    }

    setMenuHeaderNumber(e)
  }

  const handleChangeSideMenu = (header: number, menu: any) => {
    setCurrentMenuTitle(menu?.title)
    const payload = {
      headerIndex: header,
      index: header === HEADER_MENU_INDEX_PROPOSAL_TENDER ? proposalMenu.indexOf(menu) : processMenu.indexOf(menu),
      component: menu?.component,
    }
    // @ts-ignore
    setSelectedMenu(payload)

    props.onIndexChange && props.onIndexChange(payload)
  }

  const handleNavigateMenu = (header: number, menu: any) => {
    setCurrentMenuTitle(menu?.title)
    navigate({
      hash: `#menu=${header}-${
        getSidenavMenuIndex(header, menu)
      }`,
    }, {replace: true})
    handleChangeSideMenu(header, menu)


    //region handle toolbar right navigation
    // setToolbarEvaluasi((prevValue: ToolbarInterface) => ({
    //   ...prevValue,
    //   show: header === 2 && menu.title === 'Tender',
    // }))

    //endregion
  }

  const getSidenavMenuIndex = (header: number, menu: any) => {
    if (header === HEADER_MENU_INDEX_PROPOSAL_TENDER) {
      return proposalMenu.indexOf(menu)
    } else if (header === HEADER_MENU_INDEX_PROCESS_TENDER) {
      return processMenu.indexOf(menu)
    } else if (header === HEADER_MENU_INDEX_AWARDING_TENDER) {
      return awardingMenu.indexOf(menu)
    }
  }

  const isDokumenPengadaanActive = () => {
    // if(props?.proposalTender?.status === "Pra Qualification") return false
    if (props.proposalTender.status === 'Draft' && (props.proposalTender.status_detail === 'Draft' || props.proposalTender.status_detail === 'Rejected')  && props?.proposalTender?.praqualification) {
      return false
    }
    return true
  }

  const isPanitiaActive = () => {
    if (props?.proposalTender?.status === 'Pra Qualification') return false
    if (props.proposalTender.status === 'Draft' && props.proposalTender.status_detail === 'Draft' && props?.proposalTender?.praqualification) {
      return false
    }
    return true
  }

  const isDURActive = () => {
    if (props?.proposalTender?.status === 'Pra Qualification') return false
    if (props.proposalTender.status === 'Draft' && props.proposalTender.status_detail === 'Draft' && props?.proposalTender?.praqualification) {
      return false
    }
    return true
  }

  const hideSidebar = () => {
    // hide sidebar
    const elementToClick = document.getElementById('kt_aside_toggle')

    if (elementToClick && !elementToClick.classList.contains('active')) {
      // Memicu klik pada elemen
      elementToClick.click()
    }
  }

  const isRetenderEnabled = () => {
    const statusTender = props.proposalTender.status
    const statusTenderDetail = props.proposalTender.status_detail
    const isStatusPenawaran = statusTender.indexOf('Penawaran') !== -1
    const isStatusSubmitForApproval = statusTenderDetail.indexOf('Submit for Approval') !== -1

    const invalidStatus = [
      'Draft',
      'Cancel',
    ]
    return !invalidStatus.includes(statusTender) && !isStatusPenawaran && !isStatusSubmitForApproval
  }

  const isBatalTenderEnabled = () => {
    const invalidStatus = [
      'Cancel',
    ]
    return !invalidStatus.includes(props.proposalTender.status)
  }

  const isCetakEnabled = () => {
    return false;
    // const invalidStatus = [
    //   'Cancel',
    // ]
    // return !invalidStatus.includes(props.proposalTender.status)
  }

  const [{ doSubmit: doSubmit, isLoading: isLoadingPQ }] = useAxiosPost(getEnv("tender") + "/pq/submit");
  const [{ doSubmit: doSubmitGet, isLoading: isLoadingGet }] = useAxiosGet(
    getEnv("tender") + "/negotiation"
  );
  const [approvalSchemaBatalTender, setApprovalSchemaBatalTender] = React.useState([]);
  const [isShowApprovalSchemaBatalTender, setIsShowApprovalSchemaBatalTender] = React.useState(false);
  const [isShowApprovalSchema, setIsShowApprovalSchema] = React.useState(false);
  const handleOnBatalTenderWithApproval = async (values: any) => {
    Swal.fire({
      title: intl.formatMessage({id:"Apakah Anda yakin"})+"?",
      text: intl.formatMessage({id:"Anda akan membatalkan Tender ini"}),
      icon: "question",
      showCancelButton: true,
      confirmButtonText: intl.formatMessage({id:"Ya, Submit"}),
      cancelButtonText: intl.formatMessage({id:"Tidak"})
    }).then((result) => {
      const payload = {
        approvers: values.approval.map((item: any) => {
          return {
            step: item.step,
            jabatan: item.jabatan,
            type: "APPROVAL",
            user_id: item.extra_info.user.user_id,
            extra_info: item.extra_info
          };
        }),
        cancel_reason: values?.rejection_note
      };
      const newUrl = getEnv("tender") + `/tender/cancel/${props?.proposalTender?.uuid}`;
      doSubmit(payload, newUrl, toast, () => {
      }).then((data: any) => {
        setIsShowApprovalSchema(false);
        refetchProposalTender();
        props.afterSaveDraft && props.afterSaveDraft();
      }).catch(() => {
      });
    });

  };

  const handleOnBatalTender = async () => {
    // props.handleOnBatalTender && await props.handleOnBatalTender()
    const allowedStatusDirectCancel = [
      "Draft",
      "DUR",
    ];
    if(allowedStatusDirectCancel.includes(props.proposalTender?.status)) {
      Swal.fire({
        title: "Apakah Anda yakin?",
        text: "Anda akan membatalkan Proposal Tender ini",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Ya, batalkan",
        cancelButtonText: "Tidak"
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          doSubmit(
            {},
            getEnv("tender") + `/tender/cancel/${props.proposalTender.uuid}`,
            toast
          ).then((res: any) => {
            refetchProposalTender();
          }).finally(() => {
          });
        }
      });
    } else {
      const newUrl = getEnv("tender") + `/dur/routing-approval-code-cancel/${props?.proposalTender?.uuid}`;
      doSubmitGet({},newUrl, undefined).then((res: any) => {
        setApprovalSchemaBatalTender(res.data?.details);
        setIsShowApprovalSchemaBatalTender(true);
      }).catch((e:any)=> {
        toast.error(e?.response?.data?.meta?.message);
      })
    }
  }

  const handleOnRetender = async () => {
    setIsShowRetender(true)
  }

  const handleOnAlihkan = async () => {
    props.handleOnAlihkan && await props?.handleOnAlihkan()
  }

  // region OE
  const isEvaluationOEEnabled = () => {
    return props?.proposalTender?.status === 'Negotiation' && props?.proposalTender?.status_detail === 'Draft'
  }

  const mutation = useMutation({
    mutationFn: () => axios.put(`${getEnv('tender')}/negotiation/${props?.proposalTender?.negotiation?.uuid}`, {
      proposal_tender_id: props.proposalTender.uuid,
      process: 'Evaluasi OE',
      note: '-',
    }),
    onSuccess: (response: any) => {
      toastSuccess(response.data.meta.message)
      setTimeout(() => {
        window.location.reload()
      }, 1500)
    },
    onError: (error: any) => {
      toastError(error.response.data.meta.message)
    },
  })

  const handleOnEvalOE = async () => {
    Swal.fire({
      title: intl.formatMessage({id: 'Apakah Anda yakin'}) + '?',
      html: intl.formatMessage({id: 'Anda akan melakukan evaluasi OE'}),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: intl.formatMessage({id: 'Ya, Submit'}),
      cancelButtonText: intl.formatMessage({id: 'Tidak'}),
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        mutation.mutate()
      }
    })
  }
  // endregion

  /**
   * Override redirect navigation by status
   */
  const overrideNavigationByStatus = () => {
    // if (!isRendered) {
    //
    //   if (props?.defaultMenu) {
    //     const _overrideMenu = parseInt(props?.defaultMenu) < 0 ? 0 : props?.defaultMenu
    //     handleChangeSideMenu(2, proposalMenu[_overrideMenu])
    //     return
    //   }
    //
    //   const allowedStatusDraft = ['Draft']
    //   const allowedStatusPQ = ['Pra Qualification']
    //   const allowedStatusDUR = ['DUR']
    //   const allowedStatusPenawaran = ['Published', 'Aanwijzing', 'Registrasi', 'Penawaran', 'Penawaran Teknikal', 'Penawaran Komersil']
    //   const allowedStatusBid = ['Bid Opening', 'Evaluasi Administrasi', 'Evaluasi Teknikal', 'Evaluasi Komersil']
    //   const allowedStatusNego = ['Negotiation']
    //   const allowedStatusAwarding = ['Awarding', 'Laporan Pengadaan', 'Pengumuman Pemenang', 'Penunjukkan Pemenang']
    //
    //   const allowedDetailStatusRetender = ['Submit for Approval Retender', 'Retender']
    //
    //   if (allowedDetailStatusRetender.includes(props?.proposalTender?.status_detail)) {
    //     handleNavigateMenu(1, proposalMenu[1])
    //   } else if (allowedStatusDUR.includes(props?.proposalTender?.status)) {
    //     if (props?.proposalTender?.status_detail === 'Submit for Approval DUR') {
    //       handleNavigateMenuHeader(2)
    //       handleChangeSideMenu(2, processMenu[3]['subMenu'][0])
    //     } else {
    //       handleNavigateMenu(1, proposalMenu[6])
    //     }
    //   } else if (allowedStatusPQ.includes(props?.proposalTender?.status)) {
    //     if (props?.proposalTender?.status_detail !== 'Draft') {
    //       handleNavigateMenu(2, processMenu[1])
    //       handleNavigateMenuHeader(2)
    //     }
    //   } else if (allowedStatusBid.includes(props?.proposalTender?.status)) {
    //     if (props?.proposalTender?.status_detail === 'Submit for Approval Change Schedule') {
    //       handleNavigateMenu(1, proposalMenu[3])
    //     } else if (props?.proposalTender?.metode_penyampaian_step === 2
    //       && props?.proposalTender?.metode_penyampaian === '2 Tahap'
    //     ) {
    //       handleNavigateMenuHeader(2)
    //       handleChangeSideMenu(2, processMenu[3]['subMenu'][2])
    //     } else {
    //       handleNavigateMenuHeader(2)
    //       handleChangeSideMenu(2, processMenu[3]['subMenu'][1])
    //     }
    //   } else if (allowedStatusNego.includes(props?.proposalTender?.status)) {
    //     handleNavigateMenu(2, processMenu[4])
    //     handleNavigateMenuHeader(2)
    //   } else if (allowedStatusAwarding.includes(props?.proposalTender?.status)) {
    //     handleNavigateMenuHeader(3)
    //     handleChangeSideMenu(3, awardingMenu[0])
    //   } else if (allowedStatusPenawaran.includes(props?.proposalTender?.status)) {
    //     handleNavigateMenuHeader(2)
    //     handleChangeSideMenu(2, processMenu[3]['subMenu'][0])
    //   }
    //   setIsRendered(true)
    // }

  }

  useEffect(() => {
    if (props?.proposalTender) {
      initProposalMenus()
      initProcessMenus()
      initAwardingMenus()
    }

  }, [props?.proposalTender])



  useEffect(() => {
    if (processMenu.length > 0 && proposalMenu.length > 0 && awardingMenu.length > 0) {
      const currentUrl = window.location.pathname;
      if(proposalMenu?.find((menu) => menu.to === currentUrl)) {
        setTabActive('proposal')
      } else if(processMenu?.find((menu) => {
        if(menu?.subMenu) {
          return menu.subMenu.find((subMenu) => subMenu.to === currentUrl)
        } else {
          return menu.to === currentUrl;
        }
      })) {
        setTabActive('process')
      } else if(awardingMenu?.find((menu) => {
        if(menu?.subMenu) {
          return menu.subMenu.find((subMenu) => subMenu.to === currentUrl)
        } else {
          return menu.to === currentUrl;
        }
      })) {
        setTabActive('awarding')
      }
    }
  }, [proposalMenu, processMenu, awardingMenu])

  useEffect(() => {
    hideSidebar()
  }, [])

  // useEffect(() => {
  //   props.onIndexChange && props.onIndexChange(selectedMenu)
  // }, [selectedMenu])

  // useEffect(() => {
  //   // @ts-ignore
  //   props.onMenuHeaderChange && props.onMenuHeaderChange(menuHeaderNumber)
  // }, [menuHeaderNumber])


  if (!props?.proposalTender) return (
    <>
      <Placeholder.Paragraph rows={30} />
    </>
  )

  return (
    <div>
      <div className={'d-flex justify-content-between mb-1'}>
        <a
          href={'#'}
          onClick={(e) => setTabActive('proposal')} className={'text-center'}>
          <div>
            <i className={'fa fa-file fa-2x' + (tabActive === 'proposal' ? ' text-info' : ` ${darkModeClass()}`)}></i>
          </div>
          <div>
            <strong className={(tabActive === 'proposal' ? ' text-info' : ` ${darkModeClass()}`)} style={{
              borderBottom: (tabActive === 'proposal' ? '5px solid #5d78ff' : ''),
              padding: '0px 10px 0px 10px',
            }}>Proposal</strong>
          </div>
        </a>
        <a href={'#'} onClick={(e: any) => {
          e.preventDefault()
          if (isProcessTabEnabled()) {
            // handleNavigateMenuHeader(2)}
            setTabActive('process')
          }
        }} className={'text-center'}>
          <div>
            <i className={'fa fa-sync fa-2x ' + (isProcessTabEnabled() ? (tabActive === 'process' ? ' text-info' : ` ${darkModeClass()}`) : `${darkModeClass()} text-muted`)}></i>
          </div>
          <div>
            <strong
              className={isProcessTabEnabled() ? ((tabActive === 'process' ? ' text-info' : ` ${darkModeClass()}`)) : ` ${darkModeClass()} text-muted`}
              style={{
                borderBottom: (tabActive === 'process' ? '5px solid #5d78ff' : ''),
                padding: '0px 10px 0px 10px',
              }}>Process</strong>
          </div>
        </a>
        <a href={'#'} onClick={(e: any) => {
          e.preventDefault()
          if (isAwardingTabEnabled()) setTabActive('awarding')
        }}
           className={'text-center'}>
          <div>
            <i className={'fa fa-trophy fa-2x ' + (isAwardingTabEnabled() ? (tabActive === 'awarding' ? ' text-info' : ` ${darkModeClass()}`): `${darkModeClass()} text-muted`)}></i>
          </div>
          <div>
            <strong
              className={isAwardingTabEnabled() ? ((tabActive === 'awarding' ? ' text-info' : ` ${darkModeClass()}`)) : ` ${darkModeClass()} text-muted`}
              style={{
                borderBottom: (tabActive === 'awarding' ? '5px solid #5d78ff' : ``),
                padding: '0px 10px 0px 10px',
              }}>Awarding</strong>
          </div>
        </a>
      </div>
      <br />
      <hr className={'mt-1'} />
      <div className={'text-center'}>
        <h1>
          {(props?.proposalTender?.metode_penyampaian === '1 Sampul')
            ? props?.proposalTender?.status
            : (props?.proposalTender?.status === 'Bid Opening')
              ? props?.proposalTender?.metode_penyampaian_step === 0
                ? 'Bid Opening Teknis'
                : props?.proposalTender?.metode_penyampaian_step === 1
                && 'Bid Opening Komersil'
              : props?.proposalTender?.status
          }
        </h1>
        <small className={`${darkModeClass()}`}>
          {props?.proposalTender?.is_waiting ? 'Waiting for ' : ''}
          {props?.proposalTender?.status_detail}
        </small>
      </div>

      <hr />
      <div className={'mb-1'}>
        <div className={'form-group mb-3'}>
          <label htmlFor=""
                 className={`font-weight ${darkModeClass()}`}><strong>{intl.formatMessage({id: 'No. Proposal Tender'})}</strong></label>
          <div className={'input-group'}>
            <input type="text" className={'form-control form-control-sm'} disabled={true}
                   value={props?.proposalTender?.no} />
            <div className={'input-group-append'}>
              <div className={'btn-group'} style={{ display: 'flex', margin: 0 }}>
                <button className={'btn btn-sm btn-info'} onClick={() => copyToClipboard()}>
                  <i className={'fa fa-copy'}></i>
                </button>
                <button
                  className="btn btn-primary"
                  onClick={() => setProposalTenderIdForLog(props?.proposalTender?.id)}
                >
                  <i className="fa fa-history" />
                </button>
                {proposalTenderIdForLog && (
                  <ActivityLogDrawer
                    subject_id={proposalTenderIdForLog}
                    subject_type="App\Models\ProposalTender"
                    onHide={() => setProposalTenderIdForLog(null)}
                  />
                )}
                {/* <button className={'btn btn-sm btn-info'} onClick={() => navigateToPO()}>
                  <i className="fa fa-file-alt"></i>PO
                </button> */}
              </div>
            </div>
          </div>
        </div>
        {props?.proposalTender?.title &&
          <div className={'form-group mb-3'}>
            <label htmlFor=""
                   className={`font-weight ${darkModeClass()}`}><strong>{intl.formatMessage({id: 'Judul Tender'})}</strong></label>
            <div className={'input-group'}>
              <textarea
                className="form-control form-control-sm"
                rows={3}
                disabled={true}
              >{props?.proposalTender?.title}</textarea>
            </div>
          </div>
        }
        <div className={'text-end gap-2 d-flex align-items-end justify-content-end table-responsive mb-4'}>
          <div className={'badge badge-primary text-white'}
               style={{fontStyle: 'italic'}}>#{props?.proposalTender?.order_placement}</div>
          <div className={'badge badge-primary text-white'}
               style={{fontStyle: 'italic'}}>#{props?.proposalTender?.metode_evaluasi}</div>
          <div className={'badge badge-primary text-white'}
               style={{fontStyle: 'italic'}}>#{props?.proposalTender?.metode_penyampaian}</div>
        </div>
        <div className={'d-flex justify-content-between table-responsive'}>
          <div className={'btn-group w-100'}>
            <button
              className={'btn btn-sm btn-danger'}
              onClick={handleOnBatalTender}
              disabled={!isBatalTenderEnabled() || !props?.isOwner}
            >
              {intl.formatMessage({id: 'Batal'})}
            </button>
            <button
              className={'btn btn-sm btn-warning'}
              disabled={!isRetenderEnabled() || !props?.isOwner}
              onClick={handleOnRetender}
            >
              {intl.formatMessage({id: 'Retender'})}
            </button>
            {/* <button
              className={"btn btn-sm btn-info"}
              disabled={false}
              onClick={handleOnAlihkan}
            >
              {intl.formatMessage({ id: "Alihkan" })}
            </button> */}
            {/*<button*/}
            {/*  className={"btn btn-sm btn-primary"}*/}
            {/*  disabled={!isEvaluationOEEnabled()}*/}
            {/*  onClick={handleOnEvalOE}*/}
            {/*  title={"Only open when Negotiation - Draft"}*/}
            {/*>*/}
            {/*  {intl.formatMessage({ id: "Eval OE" })}*/}
            {/*</button>*/}

            <button
              className={'btn btn-sm btn-primary'}
              disabled={!isCetakEnabled()}
            >
              {intl.formatMessage({id: 'Cetak'})}
            </button>
          </div>
        </div>
      </div>

      <hr className={'mt-1'} />
      <div className={'mb-1 d-flex flex-column'}>
        {/* Region Proposal Menu*/}
        {/*{menuHeaderNumber === HEADER_MENU_INDEX_PROPOSAL_TENDER && (*/}
        {tabActive === 'proposal' && (
          <>
            {proposalMenu.map((menu: any, index: number) => {
              return (
                <RowMenuLink
                  title={menu.title}
                  key={'menu' + index}
                  to={menu?.to}
                  menu={menu}
                  currentMenuTitle={currentMenuTitle}
                  disabled={menu.disabled}
                  warning={menu.warning}
                />
              )
            })}
          </>
        )}
        {/* End Region Proposal Menu*/}

        {/* Region Process Menu*/}
        {/*{menuHeaderNumber === HEADER_MENU_INDEX_PROCESS_TENDER && (*/}
        {tabActive === 'process' && (
          <>
            {processMenu.map((menu: any, index: number) => {
              return (
                <RowMenuLink
                  title={menu.title}
                  key={'menu' + index}
                  to={menu?.to}
                  menu={menu}
                  currentMenuTitle={currentMenuTitle}
                  disabled={menu.disabled}
                  warning={menu.warning}
                  subMenu={menu?.subMenu}
                />
              )
            })}
            {/*{processMenu.map((menu: any, index: number) => {*/}
            {/*  return (*/}
            {/*    <RowMenu*/}
            {/*      title={menu.title}*/}
            {/*      key={'proses' + index}*/}
            {/*      active={selectedMenu.index === index}*/}
            {/*      currentMenuTitle={currentMenuTitle}*/}
            {/*      onClick={(e: any) => handleNavigateMenu(*/}
            {/*        HEADER_MENU_INDEX_PROCESS_TENDER,*/}
            {/*        e,*/}
            {/*      )}*/}
            {/*      menu={menu}*/}
            {/*      subMenu={menu.subMenu}*/}
            {/*      disabled={menu.disabled}*/}
            {/*    />*/}
            {/*  )*/}
            {/*})}*/}
          </>
        )}
        {/*  End Region Process Menu*/}

        {/*  Region Awarding Menu*/}
        {/*  {menuHeaderNumber === HEADER_MENU_INDEX_AWARDING_TENDER && (*/}
        {tabActive === 'awarding' && (
          <>
            {awardingMenu.map((menu: any, index: number) => {
              return (
                <RowMenuLink
                  title={menu.title}
                  key={'menu' + index}
                  to={menu?.to}
                  menu={menu}
                  currentMenuTitle={currentMenuTitle}
                  disabled={menu.disabled}
                  warning={menu.warning}
                  subMenu={menu?.subMenu}
                />
              )
            })}
          </>
        )}
        {/*  End Region Awarding Menu*/}
        <ApprovalX
          title={intl.formatMessage({id:'Batal Tender'})}
          show={isShowApprovalSchemaBatalTender}
          schema={approvalSchemaBatalTender}
          onHide={() => {
            setIsShowApprovalSchemaBatalTender(false);
          }}
          onSubmit={(data: any) => {
            setIsShowApprovalSchemaBatalTender(false);
            handleOnBatalTenderWithApproval(data);
          }}
          isLoading={isLoadingPQ}
          source={"TENDER"}
          object_id={props.proposalTender?.id}
          company_id={props.proposalTender?.company?.id}
          code={"tender_cancel"}
          readOnly={isLoadingPQ}
          AdditionalForm={(arg:any) => {
            return (
              <>
                <div className={"form-group mb-3"}>
                  <label className="form-label">
                    Alasan batal tender
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e:any)=>arg.onChange(e.target.value,"rejection_note")}
                  />
                </div>
                <hr />
              </>
            )
          }
          }
          // readOnly={props.proposalTender?.is_on_approval}
        />

        <RetenderForm
          proposalTender={props?.proposalTender}
          show={isShowRetender}
          onHide={()=>setIsShowRetender(false)}
        />

      </div>
    </div>
  )
}

// @ts-ignore
export default SideLeftLayout