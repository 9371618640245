import { FC, useState } from 'react'
import { Col, Container, Modal, Row } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { NavLink } from 'react-router-dom'
import NavigationBar from './components/NavigationBar'
import TenderingList from './components/TenderingList'

const Contact: FC = () => {
  const intl = useIntl()
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)

  return (
    <>
      <NavigationBar />
      <div
        style={{
          height: '500px',
          backgroundColor: '#777',
          backgroundImage:
            'linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0)), url(/background_login.jpg)',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <Container>
          <Row>
            <Col>
                <p style={{ color: 'white', fontSize: '28px', fontWeight: '550', textAlign: 'left', paddingTop: '160px' }}>
                  Procurement System <br /> Pupuk Indonesia (Persero)
                </p>
            </Col>
          </Row>
        </Container>
      </div>
      <main>
        <Container>
          <Row>
            <Col>
              <div className='card' style={{ marginTop: '-80px' }}>
                <div className='card-body'>
                <Row>
                    <Col sm="6" className='border-end'>

                        <table width="100%">
                            <thead>
                                <tr>
                                    <td colSpan={3} className="text-center">
                                        <h4>PT. Pupuk Indonesia</h4>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{verticalAlign: 'top'}} width="25%"><b>Alamat</b></td>
                                    <td style={{verticalAlign: 'top'}}>:</td>
                                    <td>
                                    Kantor Pengadaan PT Pupuk Indonesia (Persero), Jl. Menteng Raya No 27, Kebon Sirih, Jakarta Pusat
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Telp</b></td>
                                    <td>:</td>
                                    <td>
                                        021 53654900 ext. 6911/6921 
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Email</b></td>
                                    <td>:</td>
                                    <td>
                                        vm.eproc@pupuk-indonesia.com
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Nomor WA</b></td>
                                    <td>:</td>
                                    <td>
                                        0812-6636-6797
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <hr/>
                        
                        <table width="100%">
                            <thead>
                                <tr>
                                    <td colSpan={3} className="text-center">
                                        <h4>PT. Petrokimia Gresik</h4>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td width="25%"><b>Alamat</b></td>
                                    <td>:</td>
                                    <td>
                                        PT. Petrokimia Gresik Jl. Ahmad Yani Gresik - 61119 INDONESIA
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Telp</b></td>
                                    <td>:</td>
                                    <td>
                                        031-3982100, 3982200 
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Fax</b></td>
                                    <td>:</td>
                                    <td>
                                        031-3981722,3982272
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Vendor Management</b></td>
                                    <td>:</td>
                                    <td>
                                        ext. 2699
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Nomor WA</b></td>
                                    <td>:</td>
                                    <td>
                                        0811-3276-002
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Email</b></td>
                                    <td>:</td>
                                    <td>
                                        epro@petrokimia-gresik.com
                                    </td>
                                </tr>

                            </tbody>
                        </table>

                        <hr/>

                        <table width="100%">
                            <thead>
                                <tr>
                                    <td colSpan={3} className="text-center">
                                        <h4>PT. Pupuk Kalimantan Timur</h4>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td width="25%"><b>Alamat</b></td>
                                    <td>:</td>
                                    <td>
                                        Jln. Ir. James Simanjuntak No.1 Bontang Utara – Kaltim
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Telp</b></td>
                                    <td>:</td>
                                    <td>
                                        0548-41202 
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Fax</b></td>
                                    <td>:</td>
                                    <td>
                                        0548-41595
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Pengadaan jasa</b></td>
                                    <td>:</td>
                                    <td>
                                        ext. 5214 (Abdul Kadir Kaola)
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Pengadaan barang</b></td>
                                    <td>:</td>
                                    <td>
                                        ext. 5248 (Jaka Satria)
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Jasa distribusi</b></td>
                                    <td>:</td>
                                    <td>
                                        ext. 5833 (Suryo Wildan)    
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Vendor Management</b></td>
                                    <td>:</td>
                                    <td>
                                        ext. 5218 (Rizki Ardita)
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Email</b></td>
                                    <td>:</td>
                                    <td>
                                        vendormgm@pupukkaltim.com
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </Col>
                    <Col sm="6">
                        <table width="100%">
                            <thead>
                                <tr>
                                    <td colSpan={3} className="text-center">
                                        <h4>PT Pupuk Sriwijaya Palembang</h4>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td width="25%"><b>Alamat</b></td>
                                    <td>:</td>
                                    <td>
                                        Jl Mayor Zen, Palembang- Indonesia 30118
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Telp</b></td>
                                    <td>:</td>
                                    <td>
                                        (0711) - 712222 ext 3439 
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Email</b></td>
                                    <td>:</td>
                                    <td>
                                        vendor_management@pusri.co.id
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                        <br></br>
                        <br></br>
                        <hr/>

                        <table width="100%">
                            <thead>
                                <tr>
                                    <td colSpan={3} className="text-center">
                                        <h4>PT. Pupuk Kujang</h4>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td width="25%"><b>Alamat</b></td>
                                    <td>:</td>
                                    <td>
                                        Jl. Jend. A. Yani No. 39 Cikampek 41373 Kabupaten Karawang – Jawa Barat
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Telp</b></td>
                                    <td>:</td>
                                    <td>
                                        (0264) 316141, 317007
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Fax</b></td>
                                    <td>:</td>
                                    <td>
                                        (0264) 314235, 314335
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Email</b></td>
                                    <td>:</td>
                                    <td>
                                        procurement@pupuk-kujang.co.id
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                        <br></br>
                        <hr/>

                        <table width="100%">
                            <thead>
                                <tr>
                                    <td colSpan={3} className="text-center">
                                        <h4>PT. Pupuk Iskandar Muda</h4>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td width="25%"><b>Alamat</b></td>
                                    <td>:</td>
                                    <td>
                                        Jl. Medan - Banda Aceh, Krueng Geukueh, Aceh Utara
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Telp</b></td>
                                    <td>:</td>
                                    <td>
                                        (0645) 56222
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Pengadaan Barang</b></td>
                                    <td>:</td>
                                    <td>
                                        +628116709885
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Pengadaan Jasa</b></td>
                                    <td>:</td>
                                    <td>
                                        +628116725747
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Email</b></td>
                                    <td>:</td>
                                    <td>
                                        eproc@pim.co.id
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </Col>
                </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </main>
      <footer className='mt-10 text-center py-5'>
        © 2023 PT Pupuk Indonesia (Persero) | All Rights Reserved
      </footer>
    </>
  )
}

export { Contact }