import {AxiosResponse} from 'axios'
import React from 'react'
import {Col, OverlayTrigger, Row, Tooltip} from 'react-bootstrap'
import {IntlShape, useIntl} from 'react-intl'
import {useQueryClient} from 'react-query'
import {Link} from 'react-router-dom'
import {Column} from 'react-table'
import {KTCard, KTCardBody} from '../../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {getEnv} from '../../../../helpers/procurex'
import {IColumns} from '../../../components/shared/TableX'
import TableXWrapper from '../../../components/shared/TableXWrapper'
import {PurchaseOrderModel} from '../core/_models'
import {PurchaseOrderKeys} from '../core/_queries'

const getTableColumns = (intl: IntlShape): IColumns[] | Column<any>[] => [
  {
    Header: intl.formatMessage({id: 'Aksi'}),
    accessor: 'created_by.id',
    searchable: false,
    orderable: false,
    disableSortBy: true,
    width: 100,
    Cell: ({row}) => {
      // noinspection RequiredAttributes
      return (
        <div className='d-flex w-100 justify-content-center'>
          <OverlayTrigger
            placement='right'
            overlay={<Tooltip id='button-tooltip-2'>Detail Arsip PO</Tooltip>}
          >
            <Link to={row.original.uuid} className='p-3'>
              <i className={'fa fs-3 fa-pencil text-dark'} />
            </Link>
          </OverlayTrigger>
        </div>
      )
    },
  },
  {Header: 'No PO SAP', accessor: 'sap_no', width: 150},
  {Header: 'No PO ProcureX', accessor: 'no', width: 170},
  {Header: 'No tender', accessor: 'proposal_tender_no', width: 180},
  {Header: 'Judul Tender', accessor: 'title', width: 150},
  {Header: 'Vendor', accessor: 'vendor_no_name', width: 200},
  {
    Header: 'Purch. Org',
    accessor: 'purchasing_organization',
    width: 200,
    Cell: ({value}) => (value ? `${value.comp_code} - ${value.comp_id}` : ''),
  },
  {Header: 'Purch. Grp', accessor: 'purchasing_group_id_name', width: 200},
  {Header: 'Tanggal PO', accessor: 'po_date_format', width: 200},
]

const PoArchiveList = () => {
  const intl = useIntl()
  const queryClient = useQueryClient()
  const tableColumns = getTableColumns(intl) as IColumns[]

  // noinspection HtmlUnknownTarget
  return (
    <>
      <Row>
        <Col>
          <div className='d-flex mb-3'>
            <Link to='upload' className='btn btn-sm btn-primary shadow-sm fw-bold'>
              <i className='fas fa-plus' />
              Mass Upload
            </Link>
          </div>
        </Col>
      </Row>

      <KTCard>
        <KTCardBody className='py-4'>
          <TableXWrapper
            readonly
            urlIndex={`${getEnv('tender')}/po`}
            defaultFilter='filter[status]=Done'
            columns={tableColumns}
            onAfterLoadData={(responseData: AxiosResponse<PurchaseOrderModel[]>) => {
              queryClient.setQueryData(PurchaseOrderKeys.lists(), responseData.data)
            }}
          />
        </KTCardBody>
      </KTCard>
    </>
  )
}

export const PoArchiveListPage = ({breadcrumbs}: {breadcrumbs?: Array<PageLink>}) => {
  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>Arsip PO</PageTitle>
      <PoArchiveList />
    </>
  )
}
