import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import { ListToolbar } from './components/header/ListToolbar'
import { darkModeClass } from '../../../../../helpers/utils'
import { ApiUrl } from '../../../../config/Api'
import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useAuth } from '../../../auth'

import TableTabs from './components/header/TableTabs'
import TableXWrapper from '../../../../components/shared/TableXWrapper'
import axios from 'axios'
import ModalNotes from './components/ModalNotes'

const BadgeUserContractManagementList = ({data}: any) => {
  const [userDetail, setUserDetail] = useState<any>({})
  const [userNIP, setUserNIP] = useState<any>("")
  const [userName, setUserName] = useState<any>("")

  const getUserDetail = () => {
    let _userNIP = data.current_approval_user;

    if(data?.status === "Submit for Approval Release") {
      _userNIP = data?.current_approval_user[0];
    }
    setUserNIP(_userNIP)
    const url = ApiUrl + '/api/auth/user?filter[username]=' + _userNIP;
    axios.get(url)
      .then((res:any) => {
        setUserDetail(res.data.data[0])
      })
  }

  useEffect(() => {
    if(data.current_approval_user || data.current_approval_user) {
      getUserDetail()
    }
  },[data]);

  return (
    <>
      {userNIP && (
        <span className={"badge badge-primary"}>
          {userNIP} - {userDetail.name}
        </span>
      )}
    </>
  )
}

const List = () => {
  const {currentUser} = useAuth()
  const [key, setKey] = useState(0)
  const [getDraft, setGetDraft] = useState(0)
  const [getOnGoing, setGetOnGoing] = useState(0)
  const [currentTab, setCurrentTab] = useState(0)
  const [getComplete, setGetComplete] = useState(0)
  const [getOnOutstanding, setGetOnOutstanding] = useState(0)
  const [detailPo, setDetailPO] = useState<any>({})
  const [showModal, setShowModal] = useState(false)

  const tabs = [
    {
      value: 0,
      text: 'Draft',
      total: getDraft,
    },
    {
      value: 1,
      text: 'Ongoing',
      total: getOnGoing,
    },
    {
      value: 2,
      text: 'Outstanding',
      total: getOnOutstanding,
    },
    {
      value: 3,
      text: 'Complete',
      total: getComplete,
    },
  ]

  const tableColumns = [
    {
      Header: 'Action ',
      accessor: 'created_by.id',
      width: 150,
      Cell: (props: any) =>
        <div className='d-flex w-100 justify-content-center'>
          <OverlayTrigger
            placement='right'
            overlay={<Tooltip id='button-tooltip-2'>Detail PO</Tooltip>}
          >
            <NavLink to={'/contract-management/po/' + props?.cell?.row?.original?.uuid} className='p-3'>
              <i className={'fa fs-3 fa-pencil text-dark'} />
            </NavLink>
          </OverlayTrigger>
          <OverlayTrigger
            placement='right'
            overlay={<Tooltip id='button-tooltip-2'>Notes</Tooltip>}
          >
            <a href={"javascript:;"} onClick={(e:any) => {
              setDetailPO(props?.cell?.row?.original)
              setShowModal(true)
              e.preventDefault()
            }} className={`p-3`}>
              <i className={`fa fs-3 fa-note-sticky ${darkModeClass()}`}></i>
            </a>
          </OverlayTrigger>
        </div>
    },
    {Header: 'Catatan', accessor: 'notes', width: 170},
    {
      Header: 'Status PO',
      accessor: 'status',
      width: 200,
      Cell: (props: any) => {
        const original = props?.cell?.row?.original
        const badgeColor = 'badge badge-primary fw-bolder'

        return (
          <>
            <div className='d-flex gap-2'>
              <span className={`${badgeColor}`}>{original.status}</span>
            </div>
          </>
        )
      },
    },
    {Header: 'No PO ProcureX', accessor: 'no', width: 170},
    {Header: 'No PO SAP', accessor: 'sap_no', width: 150},
    {Header: 'No tender', accessor: 'proposal_tender_no', width: 180},
    {Header: 'Judul Tender', accessor: 'title', width: 150},
    {
      Header: 'Vendor',
      accessor: 'vendor_no_name',
      width: 200
    },
    {
      Header: 'Purch. Org',
      accessor: 'purchasing_organization',
      width: 200,
      Cell: (props: any) => (
        ''
          // props?.cell?.row?.original?.purchasing_organization?.comp_code + ' - ' + props?.cell?.row?.original?.purchasing_organization?.name
      )
    },
    {
      Header: 'Purch. Grp',
      accessor: 'purchasing_group_id_name',
      width: 200
    },
    {
      Header: 'Tanggal PO',
      accessor: 'po_date_format',
      width: 200
    },
    {
      Header: 'Tanggal Release PO',
      accessor: 'release_date_format',
      width: 180
    },
    {
      Header: 'PR Number',
      accessor: 'pr_no',
      className: "text-center",
      width: 150
    },
    {Header: 'Buyer', accessor: 'created_by', width: 300, className: 'text-center', Cell: (props: any) => {
        return (
          <>
            {props?.cell?.row?.original?.created_by?.username} - {props?.cell?.row?.original?.created_by?.name}
          </>
        )
      }},
    {Header: 'Current User', accessor: 'current_user', width: 300, className: 'text-center', Cell: (props: any) => {
      return <BadgeUserContractManagementList data={props.cell.row.original} />
    }},

  ]

  const tableVendorColumns = [
    {
      Header: 'Action ',
      accessor: 'created_by.id',
      width: 150,
      Cell: (props: any) =>
        <div className='d-flex w-100 justify-content-center'>
          <OverlayTrigger
            placement='right'
            overlay={<Tooltip id='button-tooltip-2'>Detail PO</Tooltip>}
          >
            <NavLink to={'/contract-management/po/' + props?.cell?.row?.original?.uuid} className='p-3'>
              <i className={'fa fs-3 fa-pencil text-dark'} />
            </NavLink>
          </OverlayTrigger>
        </div>
    },
    {
      Header: 'Status PO',
      accessor: 'status',
      width: 200,
      Cell: (props: any) => {
        const original = props?.cell?.row?.original
        const badgeColor = 'badge badge-primary fw-bolder'

        return (
          <>
            <div className='d-flex gap-2'>
              <span className={`${badgeColor}`}>{original.status}</span>
            </div>
          </>
        )
      },
    },
    {Header: 'No PO ProcureX', accessor: 'no', width: 170},
    {Header: 'No PO SAP', accessor: 'sap_no', width: 150},
    {Header: 'No tender', accessor: 'proposal_tender_no', width: 180},
    {Header: 'Judul Tender', accessor: 'title', width: 150},
    {
      Header: 'Vendor',
      accessor: 'vendor_no_name',
      width: 200
    },
    {
      Header: 'Purch. Org',
      accessor: 'purchasing_organization',
      width: 200,
      Cell: (props: any) => (
        ''
          // props?.cell?.row?.original?.purchasing_organization?.comp_code + ' - ' + props?.cell?.row?.original?.purchasing_organization?.name
      )
    },
    {
      Header: 'Purch. Grp',
      accessor: 'purchasing_group_id_name',
      width: 200
    },
    {
      Header: 'Tanggal PO',
      accessor: 'po_date_format',
      width: 200
    },
    {
      Header: 'Tanggal Release PO',
      accessor: 'release_date_format',
      width: 180
    },
    {
      Header: 'PR Number',
      accessor: 'pr_no',
      className: "text-center",
      width: 150
    },
    {Header: 'Buyer', accessor: 'created_by', width: 300, className: 'text-center', Cell: (props: any) => {
        return (
          <>
            {props?.cell?.row?.original?.created_by?.username} - {props?.cell?.row?.original?.created_by?.name}
          </>
        )
      }},
    {Header: 'Current User', accessor: 'current_user', width: 300, className: 'text-center', Cell: (props: any) => {
      return <BadgeUserContractManagementList data={props.cell.row.original} />
    }},

  ]

  useEffect(() => {
    axios
      .get(`${ApiUrl}/api/tender/po?filter[status]=Draft,PO Rejected,Vendor Approval Rejection&only_me=true`)
      .then(function (response) {
        setGetDraft(response.data.meta.total)
      })
      .catch(function (error) {
        console.log(error)
      })
      .finally(function () {})
    axios
      .get(`${ApiUrl}/api/tender/po?filter[status]=Submit for Approval Release,Waiting Vendor Approval&only_me=true`)
      .then(function (response) {
        setGetOnGoing(response.data.meta.total)
      })
      .catch(function (error) {
        console.log(error)
      })
      .finally(function () {})
    axios
      .get(`${ApiUrl}/api/tender/po?filter[status]=PO Approved,Release,Rejected Release,Rejected Confirmation,Final Check&filter[exclude_submit_release]=1&only_me=true`)
      .then(function (response) {
        setGetOnOutstanding(response.data.meta.total)
      })
      .catch(function (error) {
        console.log(error)
      })
      .finally(function () {})
    axios
      .get(`${ApiUrl}/api/tender/po?filter[status]=Done&only_me=true`)
      .then(function (response) {
        setGetComplete(response.data.meta.total)
      })
      .catch(function (error) {
        console.log(error)
      })
      .finally(function () {})
  }, [key])

  return (
    <>
      <ModalNotes
        key={key}
        setKey={setKey}
        detailPo={detailPo}
        showModal={showModal}
        onHide={() => setShowModal(false)}
      />

      {!currentUser?.has_roles?.includes('vendor') &&
        <>
          <ListToolbar />

          <TableTabs
            tabs={tabs}
            currentTab={currentTab}
            setCurrentTab={(tabNumber: any) => {
              setCurrentTab(tabNumber)
            }}
          />
        </>
      }

      <div className='card mt-3'>
        <div className='card-body'>
          <Row>
            {currentUser?.has_roles?.includes('vendor') ? (
              <Col>
                <TableXWrapper
                  readonly
                  key={key}
                  urlIndex={`${ApiUrl}/api/tender/vendor/po`}
                  columns={tableVendorColumns}
                  disableUrlChanged={true}
                />
              </Col>
            ) : (
              <Col key={currentTab}>
                {currentTab === 0 &&
                  <TableXWrapper
                    readonly
                    key={key}
                    urlIndex={`${ApiUrl}/api/tender/po?filter[status]=Draft,PO Rejected,Vendor Approval Rejection&only_me=true`}
                    columns={tableColumns}
                    disableUrlChanged={true}
                  />
                }
                {currentTab === 1 &&
                  <TableXWrapper
                    readonly
                    key={key}
                    urlIndex={`${ApiUrl}/api/tender/po?filter[status]=Submit for Approval Release,Waiting Vendor Approval&only_me=true`}
                    columns={tableColumns}
                    disableUrlChanged={true}
                  />
                }
                {currentTab === 2 &&
                  <TableXWrapper
                    readonly
                    key={key}
                    urlIndex={`${ApiUrl}/api/tender/po?filter[status]=PO Approved,Release,Rejected Release,Rejected Confirmation,Final Check&filter[exclude_submit_release]=1&only_me=true`}
                    columns={tableColumns}
                    disableUrlChanged={true}
                  />
                }
                {currentTab === 3 &&
                  <TableXWrapper
                    readonly
                    key={key}
                    urlIndex={`${ApiUrl}/api/tender/po?filter[status]=Done&only_me=true`}
                    columns={tableColumns}
                    disableUrlChanged={true}
                  />
                }
              </Col>
            )}
          </Row>
        </div>
      </div>
    </>
  )
}

export {
  List,
  BadgeUserContractManagementList
}