import { Formik, Form } from 'formik'
import { Col, Row } from 'react-bootstrap'
import DatepickerX2 from '../../../../components/shared/form/DatepickerX2'
import ApexChart from './ApexChart'
import { useEffect, useState } from 'react'
import FormikSelect2 from '../../../../components/shared/form/FormikSelect2'
import { getEnv } from '../../../../../helpers/procurex'
import { useAxiosPost } from '../../../../../hooks/useAxios'
import { Placeholder } from 'rsuite';
import TableXWrapper from '../../../../components/shared/TableXWrapper'

const ShowData = () => {
    const [data, setData] = useState(null);
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [material, setMaterial] = useState(null);
    const [keyTable, setKeyTable] = useState(0);
    const [items, setItems] = useState<any>([
        {
            id: 1,
            name: 'Balansitas Produk',
        },
        {
            id: 2,
            name: 'Balansitas Bahan Baku',
        },
        // {
        //   id: 3,
        //   name: 'Konsumsi Mat  NonStock',
        // },
        // {
        //   id: 4,
        //   name: 'COGM Per Plan',
        // },
        // {
        //   id: 5,
        //   name: 'Revenue & Marign',
        // },
    ])
    const [activeTab, setActiveTab] = useState(0) // State to track the active tab

    const [{ doSubmit: doSubmitRun, isLoading }] = useAxiosPost(
        `${getEnv("stockview")}/snop/run`
    );

    const handleTabClick = (index: any) => {
        setData(null);
        setMaterial(null);
        setActiveTab(index);
    }

    const onChange = () => {
        if (dateFrom == null || dateTo == null || material == null) {
            return
        }
        let update = {
            start_date: dateFrom,
            end_date: dateTo,
            material: material,
        }
        doSubmitRun(update).then((res: any) => {
            setData(res.data);
        });
    }

    const tableColumnsBalansitasProduk = [
        { Header: 'Company', accessor: 'company', width: 180 },
        { Header: 'Produk', accessor: 'material', width: 180 },
        { Header: 'Date', accessor: 'date', width: 180 },
        { Header: 'Produksi', accessor: 'production', width: 180 },
        // { Header: 'Procurement', accessor: 'procurement', width: 180 },
        { Header: 'Konsumsi', accessor: 'consumption', width: 180 },
        { Header: 'Sales', accessor: 'sales', width: 180 },
        { Header: 'Stock Akhir', accessor: 'end_of_stock', width: 180 },
    ]

    const tableColumnsBalansitasBahanBaku = [
        { Header: 'Company', accessor: 'company', width: 180 },
        { Header: 'Material', accessor: 'material', width: 180 },
        { Header: 'Date', accessor: 'date', width: 180 },
        { Header: 'Produksi', accessor: 'production', width: 180 },
        // { Header: 'Procurement', accessor: 'procurement', width: 180 },
        { Header: 'Konsumsi', accessor: 'consumption', width: 180 },
        { Header: 'Sales', accessor: 'sales', width: 180 },
        { Header: 'Stock Akhir', accessor: 'end_of_stock', width: 180 },
    ]

    useEffect(() => {
        onChange();
    }, [dateFrom, dateTo, material])
    return (
        <>
            <div className='row'>
                <div className='col-12'>
                    <Formik
                        initialValues={{
                            date_from: '',
                            date_to: '',
                        }}
                        onSubmit={() => { }}
                        enableReinitialize
                    >
                        {({ errors, touched, values, setFieldValue }: any) => (
                            <>
                                <Form>
                                    <Row>
                                        <Col md={6}>
                                            <div className='mb-7'>
                                                <label className='required form-label'>
                                                    <span>Date from</span>
                                                </label>
                                                <DatepickerX2
                                                    onChange={(val: any) => {
                                                        setFieldValue('date_from', val);
                                                        setDateFrom(val);
                                                    }}
                                                    value={(values.date_from && new Date(values.date_from)) || null}
                                                    size={"lg"}
                                                    format={"dd MMMM yyyy"}
                                                />
                                                {(errors.date_from && touched.date_from) && (
                                                    <div className="fv-plugins-message-container">
                                                        <div className="fv-help-block">{errors.date_from}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className='mb-7'>
                                                <label className='required form-label'>
                                                    <span>Date to</span>
                                                </label>
                                                <DatepickerX2
                                                    onChange={(val: any) => {
                                                        setFieldValue('date_to', val);
                                                        setDateTo(val);
                                                    }}
                                                    value={(values.date_to && new Date(values.date_to)) || null}
                                                    size={"lg"}
                                                    format={"dd MMMM yyyy"}
                                                />
                                                {(errors.date_to && touched.date_to) && (
                                                    <div className="fv-plugins-message-container">
                                                        <div className="fv-help-block">{errors.date_to}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </>
                        )}
                    </Formik>
                </div>
            </div>
            <div className='row'>
                <div className='col-12'>
                    <form>
                        <div>
                            <ul className='nav nav-tabs nav-tabs-line' id='myTab' role='tablist'>
                                {items.map((item: any, index: number) => (
                                    <li className='nav-item' style={{ minWidth: '70px', cursor: 'pointer' }} key={index}>
                                        <a
                                            className={`nav-link p-5 text-center ${index === activeTab ? 'active' : ''} nav-group`}
                                            id={`tab-${index}`}
                                            data-toggle='tab'
                                            data-name={item.name}
                                            data-id={item.id}
                                            role='tab'
                                            aria-controls={`group-${index}`}
                                            aria-selected={index === activeTab}
                                            onClick={() => handleTabClick(index)}
                                        >
                                            {item.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </form>
                </div>
            </div>
            {activeTab === 0 && (
                <>
                    <div className='row mt-5'>
                        <div className='col-3'>
                            <Formik
                                initialValues={{
                                    material: ''
                                }}
                                onSubmit={() => { }}
                                enableReinitialize
                            >
                                {({ }: any) => (
                                    <>
                                        <Form>
                                            <div className='mb-7'>
                                                <FormikSelect2
                                                    onChangeValue={(e: any) => {
                                                        setMaterial(e.material);
                                                    }}
                                                    label='Material'
                                                    name='material'
                                                    unique_property={'id'}
                                                    optionsUrl={`${getEnv("stockview")}/snop/materials/2`}
                                                    getOptionValue={(opt: any) => opt}
                                                    getOptionLabel={(opt: any) => opt.material}
                                                    search_property={"material"}
                                                />
                                            </div>
                                        </Form>
                                    </>
                                )}
                            </Formik>
                        </div>
                    </div>
                    {isLoading &&
                        <Placeholder.Grid rows={3} active columns={2} style={{ marginTop: 20 }} />
                    }
                    {!isLoading && data != null && data.length > 0 &&
                        <>
                            <div className='row mt-5'>
                                <div className='col-12'>
                                    <ApexChart tabIndex={activeTab} material={material} data={data} />
                                </div>
                            </div>
                            <div className='row mt-5'>
                                <div className='col-12'>
                                    <TableXWrapper
                                        key={keyTable}
                                        readonly
                                        urlIndex={`${getEnv("stockview")}/snop`}
                                        columns={tableColumnsBalansitasProduk}
                                        defaultFilter={`sort=id&start_date=${dateFrom}&end_date=${dateTo}&material=${material}`}
                                    />
                                </div>
                            </div>
                        </>
                    }
                </>
            )}

            {activeTab === 1 && (
                <>
                    <div className='row mt-5'>
                        <div className='col-3'>
                            <Formik
                                initialValues={{
                                    material: ''
                                }}
                                onSubmit={() => { }}
                                enableReinitialize
                            >
                                {({ }: any) => (
                                    <>
                                        <Form>
                                            <div className='mb-7'>
                                                <FormikSelect2
                                                    onChangeValue={(e: any) => {
                                                        setMaterial(e.material);
                                                    }}
                                                    label='Material'
                                                    name='material'
                                                    unique_property={'id'}
                                                    optionsUrl={`${getEnv("stockview")}/snop/materials/0`}
                                                    getOptionValue={(opt: any) => opt}
                                                    getOptionLabel={(opt: any) => opt.material}
                                                    search_property={"material"}
                                                />
                                            </div>
                                        </Form>
                                    </>
                                )}
                            </Formik>
                        </div>
                    </div>
                    {isLoading &&
                        <Placeholder.Grid rows={3} active columns={2} style={{ marginTop: 20 }} />
                    }
                    {!isLoading && data != null && data.length > 0 &&
                        <>
                            <div className='row mt-5'>
                                <div className='col-12'>
                                    <ApexChart tabIndex={activeTab} data={data} material={material} />
                                </div>
                            </div>
                            <div className='row mt-5'>
                                <div className='col-12'>
                                    <TableXWrapper
                                        key={keyTable}
                                        readonly
                                        urlIndex={`${getEnv("stockview")}/snop`}
                                        columns={tableColumnsBalansitasBahanBaku}
                                        defaultFilter={`sort=id&start_date=${dateFrom}&end_date=${dateTo}&material=${material}`}
                                    />
                                </div>
                            </div>
                        </>
                    }
                </>
            )}
        </>
    );
}

export default ShowData