import { Field, Form, Formik } from 'formik'
import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom'
import SelectX from '../../../../../components/shared/form/SelectX'
import { ApiUrl } from '../../../../../config/Api'
import { getEnv } from "../../../../../../helpers/procurex";
import { InputNumber } from "rsuite";
import axios from 'axios'
import { useIntl } from "react-intl";
import SwalX from '../../../../../components/shared/SwalX'
import Swal from "sweetalert2";
import DatepickerX2 from "../../../../../components/shared/form/DatepickerX2";
import { useAuth } from "../../../../../../app/modules/auth";

const CreateConsumption = () => {
    const { currentUser } = useAuth();

    const initialValues = {
        material_number: '',
        company_code: '',
        total_consumption: '0',
        uom: '',
        start_validity_date: '',
        end_validity_date: ''
    }

    const intl = useIntl();
    const refFormik = React.useRef<any>();
    const [itemsMasterMaterial, setItemsMasterMaterial] = useState<any>([])
    const [itemsMasterCompany, setItemsCompanyStock] = useState<any>([])
    const [itemLoading, setItemLoading] = useState(false)

    // const validationSchema = Yup.object({});
    const formSchemaValidation = Yup.object().shape({
        material_number: Yup.string().required('Material Number is required'),
        company_code: Yup.string().required('Company Code is required'),
        total_consumption: Yup.number().required('Total Consumption is required').min(0, 'Total Consumption must be greater than or equal to 0'),
        start_validity_date: Yup.date().required('Start validity date is required'),
        end_validity_date: Yup.date().required('End validity date is required'),
    });

    const loadItem = () => {
        axios
            .get(getEnv("stockview") + `/master-material`)
            .then((res) => {
                setItemsMasterMaterial(res.data.data)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setItemLoading(false)
            })
        axios
            .get(getEnv("stockview") + `/company-stock?perPage=50&page=1`)
            .then((res) => {
                setItemsCompanyStock(res.data.data)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setItemLoading(false)
            })
    }

    useEffect(() => {
        loadItem();
    }, [])

    const [formData, setFormData] = useState({
        master: { code: "STOCKVIEW_CONSUMPTION" },
        details: []
    });

    // const [initialValues, setInitialvalues] = useState<any>(defaultValue)
    const [loading, setLoading] = useState(true)
    const [isLoading, setIsLoading] = useState(false);
    const [key, setKey] = useState(0)

    const [totalConsumption, setTotalConsumption] = useState(0);

    const handleOnTotalChange = (score: number) => {
        setTotalConsumption(score)
    }

    const [selectedMaterial, setSelectedMaterial] = useState(null);
    const [selectedCompanyCode, setSelectedCompanyCode] = useState(null);

    const handleMaterialSelect = (selectedValue: any) => {
        setSelectedMaterial(selectedValue);
    };

    const handleCompanySelect = (selectedValue: any) => {
        setSelectedCompanyCode(selectedValue);
    };

    const TableRow = ({ no, material_number, company_code, uom, onCheck, start_date, end_date, total_consumption, onDelete, ...props }: any) => {
        return (
            <tr>
                <td className={"border text-center"}>{no}</td>
                <td className={"border"}>{material_number}</td>
                <td className={"border"}>{company_code}</td>
                <td className={"border"}>{total_consumption}</td>
                <td className={"border"}>{uom}</td>
                <td className={"border"}>{start_date}</td>
                <td className={"border"}>{end_date}</td>
                <td className={"border text-center"}>
                    <button className={"btn btn-sm btn-secondary"} onClick={() => onDelete()}>
                        <i className={"fa fa-trash text-danger"}></i>
                    </button>
                </td>
            </tr>
        );
    };

    let [isLoadingDelete, setIsLoadingDelete] = useState(false)
    let [isConfirmSubmitOpen, setIsConfirmSaveOpen] = useState(false)

    const [formindex, setFormIndex] = useState(0)
    const navigate = useNavigate();

    const handleBackClick = () => {
        if (formData.details.length !== 0) {
            handleBack();
        } else {
            navigate('/stockview/transaction/consumption');
        }
    };

    const handleAddRow = async (values: any) => {
        const newData = {
            data: {
                material_number: values.material_number,
                company_code: values.company_code,
                total_consumption: values.total_consumption,
                uom: values.material_number ? 'TON' : '',
                start_date: values.start_validity_date,
                end_date: values.end_validity_date
            }
        };

        // Add newData to the details array inside formData
        setFormData(prevFormData => ({
            ...prevFormData,
            details: [...prevFormData.details, newData]
        }));

        setFormIndex(formindex + 1);
        // refFormik.current?.resetForm(); // Uncomment if using Formik for form management
    };


    const handleDeleteRow = async (index: number) => {
        const newDetails = formData.details.filter((item: any, i: number) => i !== index);

        setFormData(prevFormData => ({
            ...prevFormData,
            details: newDetails
        }));
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const response = await axios.post(getEnv("stockview") + `/consumption-list`, formData)

            if (response.status === 200 || response.status === 201) {
                toast.success(response?.status + ': ' + response?.data.meta.message, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                })
            }
        } catch (error) {
            console.error('Error processing records:', error);
            toast.error(error?.data?.meta?.message || 'Terjadi kesalahan', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        } finally {
            setLoading(false);
        }
    };

    const handleBack = async () => {
        Swal.fire({
            title: `Data Belum Tersimpan`,
            html: `Apakah Anda Yakin Ingin Keluar ?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: `Ya, Keluar!`,
            confirmButtonColor: "#999999",
            cancelButtonColor: "#258141",
            cancelButtonText: `Cancel`,
        }).then((result) => {
            if (result.isConfirmed) {
                navigate('/stockview/transaction/consumption')
            }
        })
    }

    let company_code_user = currentUser.company_code;
    let jenis_api = "list";

    return (
        <>
            <Formik
                key={formindex}
                innerRef={refFormik}
                initialValues={initialValues}
                onSubmit={handleAddRow}
                validationSchema={formSchemaValidation}
                enableReinitialize
                validateOnChange={true}
            >
                {({ errors, touched, values, setFieldValue }: any) => (
                    <Form>
                        <div className='d-flex justify-content-between mb-6'>
                            <div>
                                <button className='btn btn-secondary btn-sm fw-bold' onClick={handleBackClick}>
                                    Back To list
                                </button>
                            </div>
                            <div className='d-flex gap-4'>
                                <button className={"btn btn-primary btn-sm"} disabled={isLoading}
                                    onClick={() => handleSubmit()}>
                                    {isLoading && <i className={"fa fa-spinner fa-spin"}></i>}
                                    {!isLoading && <i className={"fa fa-save"}></i>}
                                    {intl.formatMessage({ id: "Simpan" })}
                                </button>
                            </div>
                        </div>

                        <div className='card my-5'>
                            <div className='card-header d-flex align-items-center'>
                                <b>FORM ADD CONSUMPTION</b>
                            </div>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label required'>
                                                Material Number
                                            </label>
                                            <SelectX
                                                onChange={(e: any) => setFieldValue('material_number', e)}
                                                options={itemsMasterMaterial.map((item: any) => ({
                                                    label: `${item.material_number} - ${item.material_desc}`,
                                                    value: item.material_number.trim()
                                                }))}
                                            />
                                            {(errors.material_number && touched.material_number) && (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">{errors.material_number}</div>
                                                </div>
                                            )}
                                        </div>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label required'>
                                                Company Code
                                            </label>
                                            <SelectX
                                                onChange={(e: any) => setFieldValue('company_code', e)}
                                                options={
                                                    itemsMasterCompany
                                                        .filter((item: any) => {
                                                            if (company_code_user === "A000") {
                                                                return item.active_app === "X" && item.company_code !== "A000";
                                                            } else {
                                                                return item.active_app === "X" && item.company_code !== "A000" && item.company_code === company_code_user;
                                                            }
                                                        })
                                                        .map((item: any) => ({
                                                            label: item.company_code,
                                                            value: item.company_code
                                                        }))
                                                }
                                            />
                                            {(errors.company_code && touched.company_code) && (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">{errors.company_code}</div>
                                                </div>
                                            )}
                                        </div>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label required'>
                                                Total Consumption
                                            </label>
                                            <InputNumber
                                                defaultValue={0}
                                                min={0}
                                                name="total_consumption"
                                                className={"w-100 text-end"}
                                                onChange={(e: any) => setFieldValue('total_consumption', e)}
                                            />
                                            {(errors.total_consumption && touched.total_consumption) && (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">{errors.total_consumption}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label'>
                                                Uom
                                            </label>
                                            <Field className='form-control' name="uom" value={values.material_number ? 'TON' : ''} disabled />
                                        </div>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label required'>
                                                Start Validity Date
                                            </label>
                                            <DatepickerX2
                                                onChange={(date: any) => setFieldValue("start_validity_date", date)}
                                                size={"lg"}
                                                value={values.start_validity_date}
                                                format={"dd-MM-yyyy"}
                                            />
                                            {(errors.start_validity_date && touched.start_validity_date) && (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">{errors.start_validity_date}</div>
                                                </div>
                                            )}
                                        </div>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label required'>
                                                End Validity Date
                                            </label>
                                            <DatepickerX2
                                                onChange={(date: any) => setFieldValue("end_validity_date", date)}
                                                size={"lg"}
                                                value={values.end_validity_date}
                                                format={"dd-MM-yyyy"}
                                            />
                                            {(errors.end_validity_date && touched.end_validity_date) && (
                                                <div className="fv-plugins-message-container">
                                                    <div className="fv-help-block">{errors.end_validity_date}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-end gap-4'>
                                        <button type={"submit"} className={"btn btn-primary btn-sm fw-bold"}>
                                            {isLoading && <i className={"fa fa-spinner fa-spin"}></i>}
                                            {!isLoading && <i className={"fa fa-plus"}></i>}
                                            {intl.formatMessage({ id: "Tambah" })}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"col-md-12 mt-5"}>
                            <table className={"table table-bordered"}>
                                <thead>
                                    <tr>
                                        <th className={"border text-center"}><strong>No</strong></th>
                                        <th className={"border text-center"}><strong>Material Number</strong>
                                        </th>
                                        <th className={"border text-center"}><strong>Company Code</strong>
                                        </th>
                                        <th className={"border text-center"}><strong>Total Consumption</strong></th>
                                        <th className={"border text-center"}><strong>Uom</strong></th>
                                        <th className={"border text-center"}><strong>Start Validity Date</strong></th>
                                        <th className={"border text-center"}><strong>End Validity Date</strong></th>
                                        <th className={"border text-center"}><strong>Aksi</strong></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {formData.details.length === 0 && (
                                        <tr>
                                            <td colSpan={8} className={"text-center"}>
                                                <i>Tidak ada data</i>
                                            </td>
                                        </tr>
                                    )}
                                    {formData.details.map((item: any, index: number) => {
                                        return (
                                            <TableRow
                                                key={"adm-" + index}
                                                no={index + 1}
                                                material_number={item.data.material_number}
                                                company_code={item.data.company_code}
                                                total_consumption={item.data.total_consumption}
                                                uom={item.data.uom}
                                                start_date={item.data.start_date}
                                                end_date={item.data.end_date}
                                                onDelete={() => handleDeleteRow(index)}
                                            />
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export { CreateConsumption }
