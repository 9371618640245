/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, useEffect, useState} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {DevExpressRichCoordinateTestPage} from '../pages/DevExpressRichCoordinateTestPage'
import {DevExpressRichEditTestPage} from '../pages/DevExpressRichEditTestPage'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {HomePage} from '../modules/home/HomePage'
import {Logout, AuthPage, useAuth, setupAxios} from '../modules/auth'

import {App} from '../App'
import AccountPage from '../modules/accounts/AccountPage'
import axios from 'axios'
import ModalError from '../components/shared/ModalError'
import {toast} from 'react-toastify'
import {VendorRegister} from '../modules/home/vendor-register/VendorRegister'
import SoketiTestPage from "../modules/auction/SoketiTestPage";
import { ForgotPassword } from "../modules/auth/components/ForgotPassword";
import { Contact } from '../modules/home/Contact'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env
const {REACT_APP_ENVIRONMENT} = process.env

const AppRoutes: FC = () => {
  const [showModalError, setShowModalError] = useState(false)
  const [errorData, setErrorData] = useState<any>()
  const {currentUser, logout} = useAuth()
  const [disableToastForError, setDisableToastForError] = useState([
    '/tender/tender/',
    '/tender/dur/submission/',
    '/tender/vendor/quotation/quotation-status',
    '/tender/po/submit/',
    '/dur-vendor/get-vendor-sap',
    '/signing/sign',
    /\/archive$/
  ]);
  useEffect(() => {
    setupAxios(axios, handleAxiosError)
  }, [])



  useEffect(() => {
    if(process.env.REACT_APP_ENVIRONMENT)
      document.title = '['+process.env.REACT_APP_ENVIRONMENT + '] Procure X - Pupuk Indonesia';
    else
      document.title = 'Procure X - Pupuk Indonesia';
  }, []);

  const handleAxiosError = (error: any) => {
    if (error?.data?.data?.show_modal === true || error?.response?.data?.data?.show_modal === true) {
      setShowModalError(true)
      setErrorData(error)
      return ;
    }

    if (error?.response) {
      if (error?.toJSON()?.status === 401) {
        toast.error('Sesi Anda telah berakhir, Anda akan diarahkan', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 10000,
        })
        setTimeout(() => {
          logout()
        }, 2000)

      } else if (error?.toJSON()?.status === 422) {
        const errors = error?.response?.data?.errors;

        if (typeof errors === 'object') {
          let isExcept = false;
          const currentUrl = error?.response?.request?.responseURL;
          Object.keys(errors).forEach(field => {
            disableToastForError?.some((i:any)=>{
              isExcept = i instanceof RegExp
                ? i.test(currentUrl)
                : currentUrl?.includes(i)

              return isExcept;
            })
            if(!isExcept){
              toast.error(`${field}: ${errors[field][0]}`, {
                // Optional parameters for toast styling
              });
              console.log("=>(AppRoutes.tsx:96) `${field}: ${errors[field][0]}`\n", `${field}: ${errors[field][0]}`);
            }
          });
        }
      } else if(error?.toJSON().status === 403) {
        console.log(error?.response?.data)
      }  else if (error?.toJSON()?.status !== 404) {
        if (error.toJSON().message === "Network Error") {
          toast.error("Network Error, Please refresh your page or check connection.", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 10000,
            bodyClassName: "toast-body",
            className: "toast-container",
          })
        } else {
          let isExcept = false;
          const currentUrl = error?.response?.request?.responseURL;
          disableToastForError?.some((i:any)=>{
            isExcept = i instanceof RegExp
              ? i.test(currentUrl)
              : currentUrl?.includes(i)

            return isExcept;
          })
          if(!isExcept){
            toast.error(error?.response?.data?.meta?.message || error?.data?.message || error?.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 10000,
            })
          }
          return;
        }
      }
    }
  }

  return (
    <>
      <BrowserRouter basename={PUBLIC_URL}>
        <Routes>
          <Route element={<App />}>
            <Route path='/' element={<HomePage />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/vendor-register' element={<VendorRegister />} />
            <Route path='/reset-password' element={<ForgotPassword />} />
            <Route path='error/*' element={<ErrorsPage />} />
            <Route path='logout' element={<Logout />} />
            <Route path='crafted/account/overview' element={<AccountPage />} />
            <Route path='soketi' element={<SoketiTestPage />}/>
            {currentUser ? (
              <>
                <Route path='/*' element={<PrivateRoutes />} />
                <Route path='devexpress-richedit' element={<DevExpressRichEditTestPage />} />
                <Route path='devexpress-richedit-coordinate' element={<DevExpressRichCoordinateTestPage />} />
                <Route index element={<Navigate to='/dashboard' />} />
              </>
            ) : (
              <>
                <Route path='auth/*' element={<AuthPage />} />
                <Route path='*' element={<Navigate to='/auth' />} />
                <Route path='devexpress-richedit-coordinate' element={<DevExpressRichCoordinateTestPage />} />
              </>
            )}
          </Route>
        </Routes>
      </BrowserRouter>
      <ModalError
        show={showModalError}
        onClose={() => setShowModalError(false)}
        errorData={errorData}
      />
    </>
  )
}

export {AppRoutes}
