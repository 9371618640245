import axios from 'axios'
import {getEnv} from '../../../../helpers/procurex'

export const extractUUID = (url: string): string | null => {
  // Define a regular expression pattern to match the UUID
  const uuidPattern = /([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})/

  // Test the URL against the pattern
  const match = url.match(uuidPattern)

  // If a match is found, return the UUID, otherwise return null
  return match ? match[0] : null
}

export const deleteMedias = async (fileUrls: string[]) => {
  await Promise.allSettled(
    fileUrls.map(async (fileUrl: string) => {
      const uuid = extractUUID(fileUrl)

      if (uuid) {
        return await axios.delete(getEnv('media') + `/media/${uuid}`)
      }
    })
  )
}

export const uploadAppendices = async (
  files: Array<File | string>,
  mediaTypeId: string,
  uploadFn: (mediaTypeId: string, file: File) => Promise<any>
): Promise<{status: true; data: string[]} | {status: false}> => {
  try {
    const uploadResults = await Promise.allSettled(
      files.map(async (file) => {
        if (typeof file === 'string') {
          return file
        }

        const responseFile = await uploadFn(mediaTypeId, file)

        if (responseFile instanceof Error) {
          throw responseFile
        }

        return responseFile?.url
      })
    )

    const hasError = uploadResults.some((result) => result.status === 'rejected')

    if (hasError) {
      await deleteMedias(
        uploadResults
          .filter((result) => result.status === 'fulfilled')
          .map((result: PromiseFulfilledResult<string>) => result.value)
      )

      return {status: false}
    }

    return {
      status: true,
      data: uploadResults.map((result) => (result as PromiseFulfilledResult<string>).value),
    }
  } catch (error) {
    console.error(error)
    return {status: false}
  }
}

