import React, { useEffect, useState } from "react";
import { useAxiosGet, useAxiosPost } from "../../../../../../../../hooks/useAxios";
import {
  getEnv,
  getSizeInBytes,
  parseNumberToCurrency, parseNumberToFloat,
  parseStringToBool,
} from '../../../../../../../../helpers/procurex'
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import DatepickerX2 from "../../../../../../../components/shared/form/DatepickerX2";
import { toast } from "react-toastify";
import { Placeholder, Toggle } from "rsuite";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { PageAlertX } from "../../../../../../../components/shared/PageAlertX";
import { useIntl } from "react-intl";
import BottomToolbarWrapper from "../../../../../../../components/shared/BottomToolbarWrapper";
import PreviewAttachment from "../../../../../../../components/PreviewAttachment";
import CurrencyInput from "react-currency-input-field";
import FreeAuctionModal from "./FreeAuctionModal";
import { toastError } from "../../../../../../../../_metronic/helpers";
import EvaluasiOEModal from "./EvaluasiOEModal";



const AttachmentNegosiasi = ({ proposalTender, isOwner }: any) => {
  const [dataItems, setDataItems] = useState([] as any);

  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosGet(
    getEnv("tender") + `/negotiation-attachment?filter[negotiation_id]=${proposalTender?.negotiation?.id}`
  );
  const [{ doSubmit: doSubmitPost, isLoading: isLoadingPost }] = useAxiosPost(
    getEnv("tender") + `/negotiation-attachment`
  );
  const [previewFile, setPreviewFile] = useState(null as any);
  const [attachments, setAttachments] = useState([] as any);
  const [formData, setFormData] = useState({
    description: "",
    required_attachment: false,
    uuid: null
  } as any);
  const intl = useIntl();

  const getAttachment = () => {
    doSubmit({},getEnv('tender')+`/negotiation/${proposalTender?.negotiation?.uuid}`,undefined,undefined).then((res:any)=>{
      setAttachments(res?.data?.attachments)
    })
  }
  const isFormEnabled = () => {
    const allowedNegotiationStatus = ["Open"];
    const allowedNegotiationStatusDetail = ["Draft"];
    const allowedStatus = ["Negotiation"];
    return allowedNegotiationStatus.includes(proposalTender?.negotiation?.status) &&
      allowedNegotiationStatusDetail.includes(proposalTender?.negotiation?.status_detail)
      && allowedStatus.includes(proposalTender?.status);
  };

  const handleOnSubmit = async () => {
    if(!formData?.description){
      toast.error(intl.formatMessage({id:"Deskripsi harus diisi"}));
      return;
    }
    const payload = {
      negotiation_id: proposalTender?.negotiation?.uuid,
      _method: formData?.uuid ? "PUT" : "POST",
      description: formData?.description,
      required_attachment: formData?.required_attachment,
    };
    let newUrl = getEnv("tender") + `/negotiation-attachment`;
    if (formData?.uuid) {
      newUrl = newUrl + `/${formData?.uuid}`;
    }

    await doSubmitPost(payload, newUrl, toast).then((res: any) => {
      toast.success(res?.message);
      setFormData({
        description: "",
        required_attachment: false,
        uuid: null
      })
      // @ts-ignore
      getItems();
    });
  };

  const handleOnDelete = async (id: any) => {
    Swal.fire({
      title: intl.formatMessage({id:"Apakah Anda yakin"})+"?",
      text: intl.formatMessage({id:"Anda akan menghapus attachment ini"}),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: intl.formatMessage({id:"Ya, Submit"}),
      cancelButtonText: intl.formatMessage({id:"Tidak"})
    }).then(async (result) => {
      if (result.isConfirmed) {
        const newUrl = getEnv("tender") + `/negotiation-attachment/${id}`;
        await doSubmitPost({ _method: "DELETE" }, newUrl, toast).then((res: any) => {
          getItems();
        });
      }
    });
  };

  const handleOnEdit = async (row: any) => {
    setFormData({
      description: row?.description,
      required_attachment: row?.required,
      uuid: row?.uuid
    })
  };

  const getItems = () => {
    doSubmit(null, undefined, undefined).then((res: any) => {
      setDataItems(res?.data);
    });
  };

  useEffect(() => {
    getItems();
    getAttachment();
  }, []);

  return (
      <>
        <div className={"d-flex justify-content-between"}>
          <div><h3>{intl.formatMessage({id:"Lampiran"})}</h3></div>
        </div>
        <hr />
        <div className={"row"}>

          <div className={"col-md-5 col-sm-12"}>
            <div className={"form-group mb-4"}>
              <label className="required form-label">
                {intl.formatMessage({ id: "Deskripsi" })}
              </label>
              <input
                type="text"
                value={formData?.description}
                disabled={isLoading || !isFormEnabled()}
                name="description"
                onChange={(e: any) => setFormData({...formData,description:e.target.value})}
                className={"form-control form-control-sm me-2"}
              />

            </div>
          </div>
          <div className={"col-md-2 col-sm-12"}>
            <div className={"form-group mb-4"}>
              <label className="required form-label">
                {intl.formatMessage({ id: "Mandatory" })}
              </label> <br />
              <Toggle size="md" defaultValue={formData?.required_attachment} checked={formData?.required_attachment} className={"mt-2"} checkedChildren="Ya" unCheckedChildren="Tidak"
                      onChange={(e: any) => setFormData({...formData,required_attachment:e})} />
            </div>
          </div>
          {isOwner &&
            <div className={"col-md-4 col-sm-12"}>
              <br />
              <button
                type={"button"}
                className={"btn btn-primary btn-sm mt-2"}
                disabled={!isFormEnabled() || isLoading}
                onClick={handleOnSubmit}>
                {isLoading && <i className={"fa fa-spinner fa-spin"}></i>}{" "}
                {!isLoading && <i className={"fa fa-save"}></i>}{" "}
                {intl.formatMessage({ id: "Simpan" })}
              </button>
            </div>
          }

          <div className={"col-md-12 col-sm-12"}>
            <hr />
            <div className={"table-responsive"} style={{
              maxHeight: 200
            }}>
              <table className={"table table-header-gray"}>
                <thead>
                <tr>
                  <th className={"border px-2 text-start"} style={{ width: "60%" }}>
                    {intl.formatMessage({ id: "Deskripsi" })}
                  </th>
                  <th className={"border px-2 text-center"} style={{ width: "20%" }}>
                    {intl.formatMessage({ id: "Mandatory" })}
                  </th>
                  <th className={"border px-2 text-center"} style={{ width: "20%" }}>
                    {intl.formatMessage({ id: "Aksi" })}
                  </th>
                </tr>
                </thead>
                <tbody>
                {dataItems?.length === 0 && (
                  <tr>
                    <td className={"border text-center"} colSpan={3}>
                      {intl.formatMessage({ id: "Tidak ada data" })}
                    </td>
                  </tr>
                )}
                {dataItems?.map((item: any, index: number) => {
                    return (
                      <tr key={"sesi-negosiasi-attachment"+index}>
                        <td className={"border px-2 text-start"}>
                          {item?.description}
                        </td>
                        <td className={"border text-center px-2"}>
                          {parseStringToBool(item?.required) ? intl.formatMessage({id:"Ya"}) : intl.formatMessage({id:"Tidak"})}
                        </td>
                        <td className={"border text-center px-2"}>
                          <div className={"btn-group btn-group-sm"}>
                            <button
                              type={"button"}
                              className={"btn btn-info btn-sm"}
                              disabled={!isFormEnabled() || isLoadingPost}
                              onClick={() => handleOnEdit(item)}>
                              {isLoadingPost && <i className={"fa fa-spinner fa-spin"}></i>}
                              {!isLoadingPost && <i className={"fa fa-edit"}></i>}
                            </button>
                            <button
                              type={"button"}
                              className={"btn btn-danger btn-sm"}
                              disabled={!isFormEnabled() || isLoadingPost}
                              onClick={() => handleOnDelete(item?.uuid)}>
                              {isLoadingPost && <i className={"fa fa-spinner fa-spin"}></i>}
                              {!isLoadingPost && <i className={"fa fa-trash"}></i>}
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  }
                )}
                </tbody>
              </table>
              {attachments?.map((item:any, index:number)=>{
                return (
                  <div key={index}
                       className={"mt-2 mb-1 alert alert-info bg-light-info px-2 py-2 w-100 d-flex align-items-center justify-content-between"}
                       style={{
                         borderRadius: 0,
                         border: "none"
                       }}>
                    <div>
                      <a href={"#"} className={""} onClick={(e: any) => {
                        e.preventDefault();
                        setPreviewFile(item?.uploaded?.media_url);
                      }}>
                        <strong className={"text-info"}>{item?.description}&nbsp;</strong>
                      </a>
                    </div>
                    <div>
                    </div>
                  </div>
                );
              })}
              <PreviewAttachment previewExt={previewFile} onHide={() => setPreviewFile(null)} />
            </div>
          </div>

        </div>
      </>
  );
};



const TrItemNegosiasiPerVendor = ({ proposalTender, negotiation, index, negotiationPayload, onVendorSelected, onVendorItemSelected, onClickHistory }: any) => {


  const isCheckboxEnabled = () => {
    if(proposalTender?.order_placement === "Paket") return false;

    return true;
    // return proposalTender?.order_placement === "Itemize";
  };

  const isFormEnabled = () => {
    const allowedStatus = ["Negotiation"];
    const allowedStatusDetail = ["Draft"];
    return allowedStatus.includes(proposalTender?.status) &&
      allowedStatusDetail.includes(proposalTender?.status_detail);
  };

  const checkIsDefaultChecked = () => {
    let state = false;
    negotiation?.items?.forEach((item: any) => {
      if(item?.process){
        state = true;
      }
    });
    return state;
  }

  const handleOnShowHistory = async () => {
    onClickHistory && onClickHistory(negotiation);
  }

  return (
    <>
      <tr>
        <td className={"border px-2 text-center"} rowSpan={negotiation?.items?.length + 1}>
          <input
            type={"checkbox"}
            checked={negotiationPayload?.vendors?.includes(negotiation?.vendor?.uuid) || checkIsDefaultChecked()}
            className={"form-check-input"}
            disabled={!isFormEnabled()}
            defaultChecked={checkIsDefaultChecked()}
            onChange={(e: any) => onVendorSelected(negotiation?.vendor, e.target.checked)}
          />
        </td>
        <td className={"border px-2"} rowSpan={negotiation?.items?.length + 1}>
          <strong><small>{negotiation?.vendor_id}</small></strong><br />
          <small>{negotiation?.vendor?.name}</small>
        </td>
      </tr>
      {negotiation?.items?.map((negotiationItem: any, index2: number) => {
        return (
          <tr key={"quotation-items-" + index2}>
            <td className={"border px-2 text-center"}>

              {parseNumberToCurrency(negotiationItem?.hps,negotiationItem?.currency?.code)}
            </td>
            <td className={"border px-2 text-end"}>{parseNumberToCurrency(negotiationItem?.total_offering,negotiationItem?.currency?.code)}</td>
            <td className={"border px-2 text-end"}>{parseNumberToCurrency(negotiationItem?.total_negotiation,negotiationItem?.currency?.code)}</td>
            <td className={"border px-2 text-center"}>
              <input
                type={"checkbox"}
                className={"form-check-input"}
                disabled={!isCheckboxEnabled() || !isFormEnabled()}
                checked={negotiation?.negotiation_items_id?.includes(negotiationItem?.uuid)}
                defaultChecked={negotiationItem?.process}
                onChange={(e: any) => onVendorItemSelected(negotiation, negotiationItem, e.target.checked)}
              />
            </td>
            <td className={"border px-2 text-start"}>{negotiationItem?.no_pr}</td>
            <td className={"border px-2 text-start"}>{negotiationItem?.line_no}</td>
            <td className={"border px-2 text-start"}>{negotiationItem?.material}</td>
            <td className={"border px-2 text-start"}>{negotiationItem?.short_text}</td>
            <td className={"border px-2 text-center"}>{negotiationItem?.currency?.code}</td>
            <td className={"border px-2 text-center"}>{negotiationItem?.uom?.uom}</td>
            <td className={"border px-2 text-center"}>{parseNumberToFloat(negotiationItem?.per)}</td>
            <td className={"border px-2 text-end"}>{(parseNumberToFloat(negotiationItem?.quantity))}</td>
            <td className={"border px-2 text-end"}>{parseNumberToCurrency(negotiationItem?.total_negotiation,negotiationItem?.currency?.code)}</td>
            <td className={"border px-2 text-center"}>
              <button
                type={"button"}
                className={"btn btn-sm btn-info"}
                onClick={handleOnShowHistory}
              >
                <i className={"fa fa-history"}></i>
              </button>
            </td>
          </tr>
        );
      })}
    </>
  );
};

const ItemNegosiasi = ({ proposalTender, onChange }: any) => {
  const [negotiations, setNegotiations] = useState([] as any);
  const [history, setHistory] = useState([] as any);
  const [selectedVendor, setSelectedVendor] = useState(null as any); // [uuid, name
  const [isShowModalHistory, setIsShowModalHistory] = useState(false);
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosGet(
    getEnv("tender") + `/negotiation/${proposalTender?.negotiation?.uuid}`
  );
  const [{ doSubmit: doSubmitPost, isLoading: isLoadingPost }] = useAxiosPost(
    getEnv("tender") + `/negotiation/${proposalTender?.negotiation?.uuid}`
  );
  const [negotiationPayload, setNegotiationPayload] = useState({
    negotiations: [] as any,
    vendors: [] as any,
    items: [] as any
  });

  const getItems = () => {
    doSubmit(null, undefined, undefined).then((res: any) => {
      setNegotiations(res?.data?.vendors || []);
    });
  };

  useEffect(() => {
    getItems();
  }, []);

  const handleOnVendorSelected = async (vendor: any, state: boolean) => {
    let _negotiationPayload = { ...negotiationPayload };
    if (state) {
      // @ts-ignore
      _negotiationPayload.vendors.push(vendor?.uuid);
    } else {
      _negotiationPayload.vendors = _negotiationPayload.vendors.filter((item: any) => item !== vendor?.uuid);
    }

    if(proposalTender?.order_placement === "Paket"){
      // Jika paket, maka semua item terpilih
      const currentNegotiation = negotiations.find((item: any) => item?.vendor?.uuid === vendor?.uuid);
      currentNegotiation?.items?.forEach((item: any) => {
        handleOnVendorItemSelected(currentNegotiation, item, state);
      });
    }

    setNegotiationPayload(_negotiationPayload);
  };

  // const handleOnVendorItemSelected = async (negotiation: any, negotiationItem: any, state: boolean) => {
  //   let _negotiation = negotiations.find((item: any) => item?.uuid === negotiation?.uuid);
  //   let negotiationItems = _negotiation?.negotiation_items_id || [];
  //   if (state) {
  //     negotiationItems.push(negotiationItem?.uuid);
  //   } else {
  //     // @ts-ignore
  //     negotiationItems = negotiationItems.filter((item: any) => item !== negotiationItem?.uuid);
  //   }
  //   _negotiation.negotiation_items_id = negotiationItems;
  //   setNegotiations([...negotiations.filter((item: any) => item?.uuid !== negotiation?.uuid), negotiation]);
  // };

  const handleOnVendorItemSelected = async (negotiation: any, negotiationItem: any, state: boolean) => {
    setNegotiations(prevNegotiations =>
      prevNegotiations.map(item => {
        if (item.uuid === negotiation.uuid) {
          let negotiationItems = item.negotiation_items_id || [];
          let fullNegotiationItems = item.negotiation_items || [];
          if (state) {
            negotiationItems = [...negotiationItems, negotiationItem.uuid];
            fullNegotiationItems = [...fullNegotiationItems, negotiationItem];
          } else {
            negotiationItems = negotiationItems.filter(id => id !== negotiationItem.uuid);
            fullNegotiationItems = fullNegotiationItems.filter(item => item !== negotiationItem);
          }
          return { ...item, negotiation_items_id: negotiationItems, negotiation_items: fullNegotiationItems };
        }
        return item;
      })
    );
  };

  const remappingNegotiationPayload = () => {
    // bagian ini melakukan remapping payload item negosiasi
    let payload = [] as any;
    negotiations.forEach((negotiation: any) => {
      let negotiationItems = negotiation?.negotiation_items_id || [];
      let fullNegotiationItems = negotiation?.negotiation_items || [];
      if (negotiationItems?.length > 0) {
        payload.push({
          proposal_tender_vendor_id: negotiation?.uuid,
          vendor: negotiation?.vendor,
          items: negotiationItems,
          fullItems: fullNegotiationItems
        });
      }
    });
    onChange && onChange(payload);
  }

  const getHistory = async (vendor: any) => {
    setSelectedVendor(vendor)
    setIsShowModalHistory(true);
    const url = getEnv("tender") + `/negotiation/summary-vendor/${proposalTender?.negotiation?.uuid}/${vendor?.uuid}`;
    doSubmit({}, url, undefined).then((res: any) => {
      setHistory(res?.data);
    })
  }

  useEffect(() => {
    remappingNegotiationPayload();
  },[negotiations])

  if(isLoading){
    return (
      <Placeholder.Grid columns={5} rows={10} active={true} />
    )
  }

  const hideNegoIfProcessInBersama = () => {
    if(proposalTender?.negotiation?.type === "BERSAMA" && 
        proposalTender?.negotiation?.status === "Process"
      ) {
      return true;
    }
  }

  return (
    <>
      <hr />
      <h3>Item Negosiasi</h3>
      <div className={"table-responsive"}>
        <table className={"table table-header-gray"}>
          <thead>
          <tr className={"bg-primary"}>
            <th className={"border px-2 text-center"} style={{ width: "50px" }}>#</th>
            <th className={"border px-2 text-center text-nowrap"}>Rekanan</th>
            <th className={"border px-2 text-center text-nowrap"}>HPS</th>
            <th className={"border px-2 text-center text-nowrap"}>Penawaran Awal</th>
            <th className={"border px-2 text-center  text-nowrap"}>Total Nego</th>
            <th className={"border px-2 text-center"}>Aksi</th>
            <th className={"border px-2 text-center text-nowrap"}>No PR</th>
            <th className={"border px-2 text-center text-nowrap"}>Line Item</th>
            <th className={"border px-2 text-center text-nowrap"}>Kode Material</th>
            <th className={"border px-2 text-center"} style={{ minWidth: 200 }}>Deskripsi</th>
            <th className={"border px-2 text-center text-nowrap"}>Currency</th>
            <th className={"border px-2 text-center text-nowrap"}>UoM</th>
            <th className={"border px-2 text-center text-nowrap"}>Per</th>
            <th className={"border px-2 text-center text-nowrap"}>Qty</th>
            <th className={"border px-2 text-center text-nowrap"}>Total</th>
            <th className={"border px-2 text-center text-nowrap"}>Action</th>
          </tr>
          </thead>
          <tbody>
          {negotiations?.length === 0 && (
            <tr>

              <td className={"border text-center"} colSpan={16}>Tidak ada data</td>

            </tr>
          )}
          {negotiations?.map((item: any, index: number) => {
            return (
              <TrItemNegosiasiPerVendor
                key={"quotation-vendor-" + index}
                proposalTender={proposalTender}
                negotiation={item}
                index={index}
                negotiationPayload={negotiationPayload}
                onVendorSelected={handleOnVendorSelected}
                onVendorItemSelected={handleOnVendorItemSelected}
                onClickHistory={getHistory}
              />
            );
          })}
          </tbody>
        </table>
      </div>

      <Modal show={isShowModalHistory} size={"xl"} onHide={()=>setIsShowModalHistory(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Riwayat Negosiasi Vendor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <h4 className={"mb-5"}>
              <label><strong>Vendor: </strong></label> {selectedVendor?.vendor?.vendor_no} / {selectedVendor?.vendor?.name}
            </h4>
            {isLoading && <Placeholder.Grid active={true} columns={5} rows={5} />}
            {!isLoading && (
              <div className={"table-responsive"} style={{
                overflowY: "scroll",
              }}>
                <table className={"table table-header-gray"}>
                  <thead>
                  <tr>
                    <th className={"border px-2"} style={{width:50}}>No</th>
                    <th className={"border px-2"} style={{minWidth: 200}}>Item Tender</th>
                    <th className={"border px-2"} style={{minWidth: 250}}>Nego yang Berlaku</th>
                    <th className={"border px-2"}  colSpan={history[0]?.histories?.length+1}>Riwayat Negosiasi</th>
                  </tr>
                  </thead>
                  <tbody>
                  {history?.length === 0 && (
                    <tr>
                      <td className={"border text-center"} colSpan={4}>Tidak ada data</td>
                    </tr>
                  )}
                  {history?.map((item: any, index: number) => {
                    return (
                      <tr key={"history-negosiasi-"+index}>
                        <td className={"border px-2 text-center"}>{index+1}</td>
                        <td className={"border px-2 text-start"}>{item?.short_text}</td>
                        <td className={"border px-2 text-start"}>
                          <div>
                            <div className={"border-bottom mb-2 d-flex flex-column"}>
                              <div><strong>Waktu Submit :</strong></div>
                              <div>-</div>
                            </div>
                            <div className={"border-bottom mb-2 d-flex flex-column"}>
                              <div><strong>Harga Penawaran Awal / Total: </strong></div>
                              <div>{parseNumberToCurrency(item?.previous_negotiation?.price, item?.currency?.code)} / {parseNumberToCurrency(item?.previous_negotiation?.sub_total, item?.currency?.code)}</div>
                            </div>
                            <div className={"border-bottom mb-2 d-flex flex-column"}>
                              <div><strong>Harga Nego / Total: </strong></div>
                                <div className={"bg-warning"}>{parseNumberToCurrency(item?.current_negotiation?.price, item?.currency?.code)} / {parseNumberToCurrency(item?.current_negotiation?.sub_total, item?.currency?.code)}</div>
                            </div>
                            <div className={"border-bottom mb-2 d-flex flex-column"}>
                              <div><strong>Quantity : </strong></div>
                              <div>{(item?.previous_negotiation?.quantity)}</div>
                            </div>
                            <div className={"text-start"}>
                              {!item?.process && (
                                <span className={"badge badge-success"}>Status Approved</span>
                              )}
                              {item?.process && (
                                <span className={"badge badge-info"}>Pending</span>
                              )}
                            </div>
                          </div>
                        </td>
                        {item?.histories?.reverse()?.map((historyItem: any, index2: number) => {
                          return (
                            <td className={"border px-2 text-start"} key={"history-negosiasi-item-"+index2} style={{minWidth: 250}}>
                              <div>
                                <div className={"border-bottom mb-2 d-flex flex-column"}>
                                  <div><strong>Sesi / Waktu Submit :</strong></div>
                                  <div>
                                    {historyItem?.session + 1} /
                                  </div>
                                </div>
                                <div className={"border-bottom mb-2 d-flex flex-column"}>
                                  <div><strong>Harga Sebelum Nego / Total: </strong></div>
                                  <div>{parseNumberToCurrency(historyItem?.previous_price, item?.currency?.code)} / {parseNumberToCurrency(historyItem?.previous_sub_total, item?.currency?.code)}</div>
                                </div>
                                {item?.process && hideNegoIfProcessInBersama() ? (
                                <div className={"border-bottom mb-2 d-flex flex-column"}>
                                  <div><strong>Harga Nego / Total: </strong></div>
                                  <div className={"bg-warning"}>********</div>
                                </div>
                                ) :(
                                <div className={"border-bottom mb-2 d-flex flex-column"}>
                                  <div><strong>Harga Nego / Total: </strong></div>
                                  <div className={"bg-warning"}>{parseNumberToCurrency(historyItem?.price, item?.currency?.code)} / {parseNumberToCurrency(historyItem?.sub_total, item?.currency?.code)}</div>
                                </div>
                                )}
                                <div className={"border-bottom mb-2 d-flex flex-column"}>
                                  <div><strong>Quantity : </strong></div>
                                  <div>{(parseNumberToFloat(historyItem?.quantity))}</div>
                                </div>
                                <div className={"border-bottom mb-2 d-flex flex-column"}>
                                  <div><strong>Delivery Time : </strong></div>
                                  <div>{(historyItem?.delivery_time)}</div>
                                </div>
                                <div className={"text-start"}>
                                  {(!historyItem?.process && !(historyItem?.status === 'REJECT')) && (
                                    <span className={"badge badge-success"}>Status Approved</span>
                                  )}
                                  {(!historyItem?.process && historyItem?.status === 'REJECT') && (
                                    <span className={"badge badge-danger"}>Status Rejected</span>
                                  )}
                                  {historyItem?.process && (
                                    <span className={"badge badge-info"}>Pending</span>
                                  )}
                                </div>
                              </div>
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className={"btn btn-danger"} type={"button"} onClick={()=>setIsShowModalHistory(false)}>
            <i className={"fa fa-times"}></i>
            Tutup
          </button>
        </Modal.Footer>
      </Modal>

    </>
  );
};

const SesiNegosiasiTab = ({ proposalTender, onChange, isOwner }: any) => {
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosPost(
    getEnv("tender") + `/negotiation/${proposalTender?.negotiation?.uuid}`,
  );
  const [{ doSubmit: doSubmitGet, isLoading: isLoadingGet }] = useAxiosGet(
    getEnv("tender") + `/negotiation/validate-not-yet-auction/${proposalTender?.negotiation?.uuid}`
  )
  const [isShowButtonFreeAuction, setIsShowButtonFreeAuction] = useState(false)
  const [isShowModalFreeAuction, setIsShowModalFreeAuction] = useState(false);
  const [isShowModalEvaluasiOE, setIsShowModalEvaluasiOE] = useState(false);
  const [valuesSesiNego, setValuesSesiNego] = useState<any>([]);
  const intl = useIntl();
  
  const [alertMessage, setAlertMessage] = React.useState<any>(null);
  const [alertMessage2, setAlertMessage2] = React.useState<any>(null);
  const navigate = useNavigate();

  const formSchemaValidation = Yup.object().shape({
    "start_at": Yup.string().when('metode_negosiasi', {
      is: (value:any) => value && value === 'Proses Negosiasi',
      then: Yup.string().required("Tanggal mulai harus diisi")
    }),
    "end_at": Yup.string().when('metode_negosiasi', {
      is: (value:any) => value && value === 'Proses Negosiasi',
      then: Yup.string().required("Tanggal selesai harus diisi")
    }),
    "metode_negosiasi": Yup.string().required("Metode negosiasi harus diisi"),
  });
  const [initialValues, setInitialValues] = useState({
    start_at: proposalTender?.negotiation?.start_at || "",
    end_at: proposalTender?.negotiation?.end_at || "",
    metode_negosiasi: "",
    note: proposalTender?.negotiation?.note || "",
    button: "",
    negotiations: []
  } as any);
  const formik = React.useRef(null);

  const isFormEnabled = () => {
    const allowedStatus = ["Negotiation"];
    const allowedStatusDetail = ["Draft"];
    return allowedStatus.includes(proposalTender?.status) &&
      allowedStatusDetail.includes(proposalTender?.status_detail);
  };

  const handleOnSaveDraft = async (values: any) => {
    const payload = {
      ...values,
      _method: "PUT",
      proposal_tender_id: proposalTender?.uuid,
      process: "Draft"
    };
    await onSubmit(payload);
  };

  const handleOnSubmit = async (values: any) => {
    // if(!values?.start_at){
    //   // @ts-ignore
    //   formik?.current?.setErrors({start_at: 'Tanggal mulai harus diisi'});
    //   return;
    // }
    if(values?.negotiations?.length === 0){
      toast.error("Anda belum memilih item negosiasi");
      return;
    }

    Swal.fire({
      title: intl.formatMessage({id:"Apakah Anda yakin"})+"? ",
      text: intl.formatMessage({id:"Anda akan membuat sesi negosiasi"}),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: intl.formatMessage({id:"Ya, Submit"}),
      cancelButtonText: intl.formatMessage({id:"Tidak"})
    }).then(async (result) => {
      if (result.isConfirmed) {
        const payload = {
          ...values,
          _method: "PUT",
          proposal_tender_id: proposalTender?.uuid,
          process: values?.metode_negosiasi,
        };
        await onSubmit(payload);
      }
    });

  };

  const onSubmit = async (payload: any) => {
    await doSubmit(payload, undefined, toast).then((res: any) => {
      onChange && onChange();
    });
  }

  useEffect(() => {
    setAlertMessage(`
      1. Masukkan tanggal mulai negosiasi dan tanggal akhir negosiasi, beserta attachment (jika diperlukan)
      2. Jika itemize, pilih vendor-item yang akan dinegosiasi. Jika paket, pilih vendor yang akan dinego.
      3. Jika sudah selesai, klik tombol "Buat Sesi Negosiasi"
    `)

    setAlertMessage2(`
      1. Vendor hanya bisa mengirimkan satu kali penawaran di sesi nego tersebut. Selanjutnya, buyer HARUS   approve/reject terhadap negosiasi vendor. Selama buyer BELUM menindaklanjuti, maka vendor TIDAK BISA mengirimkan negosiasi lagi.
      2. Jika masih terdapat waktu dan buyer mereject negosiasi, maka vendor bisa mengirimkan negosiasi lagi. Jika buyer approve, maka negosiasi tersebut akan menjadi harga penawaran terakhir.
      3. Untuk mendownload BA negosiasi, klik tab Sesi Negosiasi, Scroll ke Item, Pilih Item -> Riwayat Negosiasi Vendor, lalu klik download BA Nego di sesi yang diinginkan."
    `)
    
  })

  const isShowCloseNegotiation = () => {
    const allowedStatus = [
      "Negotiation"
    ];
    const allowedStatusDetail = [
      "Proses Negosiasi"
    ];
    return allowedStatus.includes(proposalTender?.status) &&
      allowedStatusDetail.includes(proposalTender?.status_detail) &&
      proposalTender?.negotiation?.type === "SATUAN";
  };

  useEffect(() => {
    isShowFreeAuction()
  }, []);

  const isShowFreeAuction = () => {
    doSubmitGet({},undefined,undefined).then((res:any)=>{
      setIsShowButtonFreeAuction(true)
    }).catch((err:any)=>{
      setIsShowButtonFreeAuction(false)
    })
  };

  const onCloseNegosiasi = async() => {
    Swal.fire({
      title: intl.formatMessage({id:'Apakah Anda yakin?'}),
      text: intl.formatMessage({id:'Anda akan menutup sesi negosiasi ini'}),
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Ya, Tutup",
      cancelButtonText: "Batal"
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        // Submit evaluasi vendor
        doSubmit({},
          getEnv("tender") + `/negotiation/close/${proposalTender?.negotiation?.uuid}`,
          toast
        ).then((res: any) => {
          // submit evaluasi administrasi
        }).finally(() => {
          onChange && onChange();
        });
      } else {
      }
    });
  }

  const showSidebar = () => {
    // hide sidebar
    const elementToClick = document.getElementById('kt_aside_toggle');

    if (elementToClick && elementToClick.classList.contains('active')) {
      // Memicu klik pada elemen
      elementToClick.click();
    }
  }

  const goBack = () => {
    showSidebar();
    const path = localStorage.getItem("backTo");
    // alert(path)
    navigate(path ? path : "/tender/admin/proposal-tender/draft");
    // navigate('/tender/admin/proposal-tender')
  };

  const openModalFreeAuction = (values) => {
    if (values?.negotiations?.length == 0) {
      return toastError('Vendor & Item is required!')
    }

    setValuesSesiNego(values?.negotiations)
    setIsShowModalFreeAuction(true)
  }
  
  return (
    <>
      <EvaluasiOEModal
        showModalEvaluasiOE={isShowModalEvaluasiOE}
        hideModalEvaluasiOE={()=>setIsShowModalEvaluasiOE(false)}
        proposalTender={proposalTender}
      />
      <FreeAuctionModal
        showModalFreeAuction={isShowModalFreeAuction}
        hideModalFreeAuction={()=>setIsShowModalFreeAuction(false)}
        valuesSesiNego={valuesSesiNego}
        setValuesSesiNego={setValuesSesiNego}
        proposalTender={proposalTender}
      />
      <PageAlertX
        title={"Petunjuk Negosiasi"}
        variant={"info"}
        message={alertMessage}
      />
      <PageAlertX
        title={"Peraturan Negosiasi"}
        variant={"danger"}
        message={alertMessage2}
      />
      <Formik
        innerRef={formik}
        initialValues={initialValues}
        onSubmit={handleOnSubmit}
        validationSchema={formSchemaValidation}
        enableReinitialize
        validateOnChange={true}

      >
        {({ errors, touched, values, setFieldValue, resetForm, submitForm }: any) => (
          <Form id="form-hps">
            <div className={"row"}>
              <div className={"col-md-4 col-sm-12"}>
                <div className={"d-flex justify-content-between"}>
                  <div><h3>{intl.formatMessage({ id: "Jadwal Negosiasi" })}</h3></div>
                </div>
                <hr />
                <div className={"form-group mb-4"}>
                  <label className="required form-label">
                    {intl.formatMessage({ id: "Metode Negosiasi" })}
                  </label>
                  <select
                    className={"form-control form-control-sm"}
                    disabled={!isFormEnabled()}
                    name={"metode_negosiasi"}
                    onChange={(e: any) => setFieldValue("metode_negosiasi", e.target.value)}
                  >
                    <option value={""}>-- {intl.formatMessage({ id: "Pilih Metode Negosiasi" })} --</option>
                    <option value={"Proses Negosiasi"}>{intl.formatMessage({ id: "Negosiasi Manual" })}</option>
                    <option value={"Auction"}>Auction</option>
                  </select>
                  {(errors.metode_negosiasi && touched.metode_negosiasi) && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{errors.metode_negosiasi}</div>
                    </div>
                  )}
                </div>
                <div className={"form-group mb-4"}>
                  <label className="required form-label">
                    {intl.formatMessage({ id: "Tanggal Mulai" })}
                  </label>
                  <DatepickerX2
                    onChange={(date: any) => setFieldValue("start_at", date)}
                    size={"md"}
                    readonly={!isFormEnabled()}
                    value={values.start_at}
                    format={"dd-MM-yyyy HH:mm:ss"}
                  />
                  {(errors.start_at && touched.start_at) && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{errors.start_at}</div>
                    </div>
                  )}
                </div>
                <div className={"form-group mb-4"}>
                  <label className="required form-label">
                    {intl.formatMessage({ id: "Tanggal Selesai" })}
                  </label>
                  <DatepickerX2
                    onChange={(date: any) => setFieldValue("end_at", date)}
                    size={"md"}
                    readonly={!isFormEnabled()}
                    value={values.end_at}
                    format={"dd-MM-yyyy HH:mm:ss"}
                  />
                  {(errors.end_at && touched.end_at) && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{errors.end_at}</div>
                    </div>
                  )}
                </div>

                <div className={"form-group mb-4"}>
                  <label className="form-label">
                    {intl.formatMessage({ id: "Catatan" })}
                  </label>
                  <textarea
                    className={"form-control form-control-sm"}
                    rows={3}
                    disabled={!isFormEnabled()}
                    value={values.note}
                    onChange={(e: any) => setFieldValue("note", e.target.value)}
                  />
                </div>
              </div>
              <div className={"col-md-8 col-sm-12"}>
                <AttachmentNegosiasi
                  proposalTender={proposalTender}
                  negotiation={data}
                  isOwner={isOwner}
                />
              </div>

              <div className={"col-md-12 col-sm-12"}>
                <ItemNegosiasi
                  proposalTender={proposalTender}
                  onChange={(negotiationPayload: any) => setFieldValue("negotiations", negotiationPayload)}
                />
              </div>
              <BottomToolbarWrapper
                onGoBack={()=>{}}
                isOwner={isOwner}
                children={
                  <>
                    {isFormEnabled() && (
                      <>
                        <button
                          type={"button"}
                          disabled={isLoading}
                          className={"btn btn-secondary me-3"}
                          onClick={() => setIsShowModalEvaluasiOE(true)}
                        >
                          {isLoading && (<i className={"fa fa-spinner fa-spin me-2"}></i>)}
                          {!isLoading && (<i className={"fa-solid fa-hand-holding-dollar"}></i>)}
                          {intl.formatMessage({ id: "Evaluasi OE" })}
                        </button>
                      </>
                    )}

                    {isShowButtonFreeAuction && (
                      <>
                        <button
                          type={"button"}
                          disabled={isLoading || !isFormEnabled()}
                          className={"btn btn-warning me-3"}
                          onClick={() => openModalFreeAuction(values)}
                        >
                          {isLoading && (<i className={"fa fa-spinner fa-spin me-2"}></i>)}
                          {!isLoading && (<i className={"fa fa-circle-dollar-to-slot me-2"}></i>)}
                          {intl.formatMessage({ id: "Free Auction" })}
                        </button>
                      </>
                    )}

                    {!isShowCloseNegotiation() && (
                      <>
                        <button
                          className={"btn btn-info me-3"}
                          type={"button"}
                          onClick={() => handleOnSaveDraft(values)}
                          disabled={isLoading || !isFormEnabled()}
                        >
                          {isLoading && (<i className={"fa fa-spinner fa-spin me-2"}></i>)}
                          {!isLoading && (<i className={"fa fa-save me-2"}></i>)}
                          {intl.formatMessage({ id: "Simpan Draft" })}
                        </button>
                        <button
                          className={"btn btn-primary"}
                          type={"button"}
                          onClick={submitForm}
                          disabled={isLoading || values?.negotiations?.length === 0 || !isFormEnabled()}
                        >
                          {isLoading && (<i className={"fa fa-spinner fa-spin me-2"}></i>)}
                          {(!isLoading) && (<i className={"fa fa-paper-plane me-2"}></i>)}
                          {intl.formatMessage({ id: "Buat Sesi Negosiasi" })}
                        </button>
                      </>
                    )}

                    {isShowCloseNegotiation() && (
                      <>
                        <button
                          type={"button"}
                          disabled={isLoading}
                          className={"btn btn-danger ms-2"}
                          onClick={onCloseNegosiasi}
                        >
                          {isLoading ? (
                            <i className="fa fa-spinner fa-spin"></i>
                          ) : (
                            <i className={"fa fa-close"}></i>
                          )}
                          Tutup Negosiasi
                        </button>
                      </>
                    )}
                  </>
                } />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default SesiNegosiasiTab;