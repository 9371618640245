import axios, {AxiosResponse} from 'axios'
import {useAuth} from '../../../../auth'
import {
  ClarificationTenderItemModel,
  ClarificationTenderItemParameterObject,
  ClarificationTenderItemStatus,
} from '../../../core/_models'
import {FetchListParams, FetchListResponse} from '../../../../../../_models'
import {UseMutationOptions, UseQueryOptions} from 'react-query/types/react/types'
import {getEnv} from '../../../../../../helpers/procurex'
import {listParamsTransformer, listParamsTransformerSorted} from '../../../../../../helpers/utils'
import {QueryKey, useMutation, useQuery} from 'react-query'

const TENDER_API_URL = getEnv('tender')

export interface QueryOptions<T = unknown>
  extends Omit<UseQueryOptions<T>, 'queryKey' | 'queryFn'> {}

export interface ClarificationTenderItemFilters {
  status?: string[]
  uuid?: string[]
  current_user?: string
  evaluator_or_end_user?: string
  latest_item_of_proposal_tender_id?: string | number
  proposal_tender_uuid?: string
  with_vendor_id?: string
  is_category_administration?: boolean
  is_category_technical?: boolean
  is_category_commercial?: boolean
}

export interface ClarificationEvaluatorResultFilters {
  proposal_tender_uuid?: string
  tender_item_status?: ClarificationTenderItemStatus
  vendor_id?: string
  is_category_administration?: boolean
  is_category_technical?: boolean
  is_category_commercial?: boolean
}

//region Get Clarification Tender Items Query
export const fetchClarificationTenderItems = (
  params?: FetchListParams<ClarificationTenderItemFilters>
): FetchListResponse<ClarificationTenderItemModel> =>
  axios
    .get(`${TENDER_API_URL}/clarification/tender-item`, {
      params: listParamsTransformer(params),
    })
    .then((response) => response.data)

export const useClarificationTenderItemsQuery = (
  queryOptions: QueryOptions<readonly ClarificationTenderItemModel[]>,
  fetchParams?: FetchListParams<ClarificationTenderItemFilters>
) =>
  useQuery<readonly ClarificationTenderItemModel[]>(
    ['clarification-tender-items', listParamsTransformerSorted(fetchParams)],
    () => fetchClarificationTenderItems(fetchParams).then((response) => response.data),
    queryOptions
  )
//endregion

//region Get Clarification Tender Item Query
interface FetchParams {
  include?: string[]
}

export const useClarificationTenderItemQuery = (
  uuid: string,
  fetchParams?: FetchParams,
  options?: QueryOptions<ClarificationTenderItemModel>
) =>
  useQuery<ClarificationTenderItemModel>(
    ['clarification-tender-items', uuid, 'results'],
    () => {
      const config = {
        params: {
          include: fetchParams?.include?.join(','),
        },
      }
      return axios
        .get(`${TENDER_API_URL}/clarification/tender-item/${uuid}`, config)
        .then((response) => response.data)
        .then((response) => response.data)
    },
    options
  )
//endregion

//region Submit Evaluator Mutation
export interface SubmitEvaluatorPayload {
  clarification_tender_item_ids: string[]
  to_end_user_note: string
  to_end_user_id: string | number
  attachment_url?: string | null
}

type SubmitEvaluatorMutationOptions = Omit<
  UseMutationOptions<any, any, SubmitEvaluatorPayload>,
  'mutationFn'
>

export const useSubmitEvaluatorMutation = (options: SubmitEvaluatorMutationOptions) =>
  useMutation(
    (data: SubmitEvaluatorPayload) => axios.post(`${TENDER_API_URL}/clarification/end-user`, data),
    options
  )
//endregion

//region Get Routing Approval Config for Submit Evaluator Query
export const useGetRoutingApprovalConfigForSubmitEvaluatorQuery = (
  uuids: string[],
  options?: QueryOptions<any>
) =>
  useQuery<any>(
    ['clarification-tender-items', 'end-user', 'routing-approval-config'],
    () =>
      axios
        .post(`${TENDER_API_URL}/clarification/end-user/routing-approval-config`, {ids: uuids})
        .then((response) => response.data)
        .then((response) => response.data),
    options
  )
//endregion

//region Get Routing Approval Config for Submit Return Query
export const useGetRoutingApprovalConfigForSubmitReturnQuery = (
  uuids: string[],
  options?: QueryOptions<any>
) =>
  useQuery<any>({
    queryKey: ['clarification-tender-items', 'submit-return', 'routing-approval-config'],
    queryFn: async () => {
      const response = await axios.post(
        `${TENDER_API_URL}/clarification/tender-item/routing-approval-config`,
        {clarification_tender_item_ids: uuids}
      )

      return response.data.data
    },
    ...options,
  })

//endregion

//region Submit Return Mutation
export const useSubmitReturnMutation = (
  options: Omit<UseMutationOptions<AxiosResponse>, 'mutationFn'>
) =>
  useMutation(
    (data: any) => axios.post(`${TENDER_API_URL}/clarification/tender-item/submit`, data),
    options
  )

//endregion

//region Submit Buyer Mutation
export interface SubmitBuyerPayload {
  proposal_tender_id: string
  proposal_tender_item_ids: string[]
  parameters: Array<ClarificationTenderItemParameterObject>
  buyer_note: string
  evaluator_id: string | number
  attachment_urls: string[]
  approvers: Array<{
    step: number
    jabatan: string
    type: string
    user_id: number
    extra_info: any
  }>
}

type SubmitBuyerMutationOptions = Omit<
  UseMutationOptions<any, any, SubmitBuyerPayload>,
  'mutationFn'
>

export const useSubmitBuyerMutation = (options?: SubmitBuyerMutationOptions) =>
  useMutation(
    (data: SubmitBuyerPayload) => axios.post(`${TENDER_API_URL}/clarification/evaluator`, data),
    options
  )
//endregion

//region Get Routing Approval Config for Submit Buyer Query
export const useGetRoutingApprovalConfigForSubmitBuyerQuery = (
  proposal_tender_uuid: string,
  proposal_tender_item_uuids: string[],
  options?: QueryOptions<any>
) =>
  useQuery<any>(
    ['clarification-tender-items', 'evaluator', 'routing-approval-config'],
    () =>
      axios
        .post(`${TENDER_API_URL}/clarification/evaluator/routing-approval-config`, {
          proposal_tender_id: proposal_tender_uuid,
          proposal_tender_item_ids: proposal_tender_item_uuids,
        })
        .then((response) => response.data)
        .then((response) => response.data),
    options
  )
//endregion

//region Get Proposal Tender Items of Proposal Tender Query
export const useProposalTenderItemsQuery = (proposalTenderId: string | number, enabled = true) =>
  useQuery(
    ['proposal-tender-items', {proposal_tender_id: proposalTenderId}],
    () =>
      axios
        .get(`${TENDER_API_URL}/tender-item`, {
          params: {
            'filter[proposal_tender_id]': proposalTenderId,
            'filter[on_progress_evaluator_clarification]': undefined ? 1 : 0,
          },
        })
        .then((response) => response.data),
    {enabled: enabled}
  )
//endregion

//region Sync PR Mutation
interface SyncPrPayload {
  clarification_tender_item_id: string
  attachment_url: string
  note: string
}

export const useSyncPrMutation = (options?: UseMutationOptions<any, any, SyncPrPayload>) =>
  useMutation(
    (data: SyncPrPayload) =>
      axios.post(`${TENDER_API_URL}/clarification/tender-item/pr-syncs`, data),
    options
  )
//endregion

//region Alihkan Clarification Tender Item Mutation
interface AlihkanPayload {
  clarification_tender_item_ids: string[]
  user_id: number
}

export const useClarificationTenderItemAlihkanMutation = (
  options?: UseMutationOptions<any, any, AlihkanPayload>
) => {
  return useMutation(
    (data: AlihkanPayload) =>
      axios.post(`${TENDER_API_URL}/clarification/tender-item/alihkan`, data),
    options
  )
}
//endregion

//region Get Routing Approval Config for Submit Alihkan Query
export const useRoutingApprovalConfigForSubmitAlihkanQuery = (
  uuids: string[],
  options?: QueryOptions<any>
) =>
  useQuery<any>(
    ['clarification-tender-items', 'alihkan', 'routing-approval-config'],
    () =>
      axios
        .post(`${TENDER_API_URL}/clarification/tender-item/alihkan/routing-approval-config`, {
          clarification_tender_item_ids: uuids,
        })
        .then((response) => response.data.data),
    options
  )
//endregion

//region Submit Alihkan Mutation
export interface SubmitAlihkanPayload {
  clarification_tender_item_ids: string[]
  user_id: string | number
}

type SubmitAlihkanMutationOptions = Omit<
  UseMutationOptions<any, any, SubmitAlihkanPayload>,
  'mutationFn'
>

export const useSubmitAlihkanMutation = (options?: SubmitAlihkanMutationOptions) =>
  useMutation(
    (data: SubmitAlihkanPayload) =>
      axios.post(`${TENDER_API_URL}/clarification/tender-item/alihkan/submit`, data),
    options
  )
//endregion

//region Get Clarification Tender Item Counts Query
interface CountsPayload {
  groups: {
    [key: string]: ClarificationTenderItemFilters
  }
}

type CountsData = {
  [key in keyof CountsPayload['groups']]: number
}

export const useClarificationTenderItemCountsQuery = (
  currentUsername: string,
  payload: CountsPayload,
  options?: QueryOptions<CountsData>,
  key?: QueryKey,
) =>
  useQuery<CountsData>(
    key ?? ['clarification-tender-items', 'counts', currentUsername],
    () =>
      axios
        .post(`${TENDER_API_URL}/clarification/tender-item/counts`, payload)
        .then((res) => res.data.data),
    options
  )
//endregion
