import { ID, KTCard, KTCardBody } from '../../../../../../_metronic/helpers'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import TableXWrpper from "../../../../../components/shared/TableXWrapper";
import { ApiUrl } from '../../../../../config/Api'
import { Button, ButtonGroup, Col, Modal, Row } from "react-bootstrap";
import SelectX from '../../../../../components/shared/form/SelectX'
import { getEnv } from "../../../../../../helpers/procurex";
import { useIntl } from "react-intl";
import { InputNumber } from "rsuite";
import { Field, Form, Formik } from 'formik'
import { MetronicErrorMessage, toastError } from '../../../../../../_metronic/helpers'
import { useAuth } from "../../../../../../app/modules/auth";
import { toast } from 'react-toastify'
import axios from 'axios'

const SlocMasterDataStockviewList = () => {
    const { currentUser } = useAuth();
    const intl = useIntl();
    const tablecolumns = [
        {
            Header: "PLANT",
            accessor: "kode_plant",
            width: 250,
        },
        {
            Header: "SLOC",
            accessor: "kode_sloc",
            width: 250,
        },
        {
            Header: "SLOC DESC",
            accessor: "desc_sloc",
            width: 180,
        },
        {
            Header: "CAPACITY",
            accessor: "capacity",
            width: 150,
        },
        {
            Header: "Action",
            accessor: "uuid",
            width: 150,
            className: "text-center fixed-column",
            Cell: (props: any) => {
                const row = props.row.original;
                return (
                    <ButtonGroup size='sm' aria-label='Action'>
                        {!excludedRoles.some(r => role.includes(r)) && (
                            <>
                                <button
                                    type='button'
                                    className='btn btn-sm btn-success'
                                    onClick={(e: any) => {
                                        e.preventDefault();
                                        setId(row.id);
                                        openModalWithItem(row); // Pass the row item to the function
                                    }}
                                >
                                    Edit
                                </button>
                            </>
                        )}
                    </ButtonGroup>
                );
            },
        },
    ];
    const [formIndex, setFormIndex] = useState(0);
    const [isShowModal, setIsShowModal] = useState(false)
    const [isShowDetail, setIsShowDetail] = useState(false)
    let [itemId, setItemId] = useState(0)
    let [isLoadingDelete, setIsLoadingDelete] = useState(false)
    let [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false)
    const [key, setKey] = useState(0)
    const [id, setId] = useState('')
    const [item, setItem] = useState<any>(null)
    const [isConfirmDialog, setIsConfirmDialog] = useState(false)
    const [itemLoading, setItemLoading] = useState(false)
    const excludedRoles = ['admin_anper'];

    const [totalCapacity, setTotalCapacity] = useState(0);

    const handleOnTotalCapacityChange = (score: number) => {
        setTotalCapacity(score)
    }

    const submit = async (values: any) => {
        try {
            const newData = {
                kode_sloc: item?.kode_sloc,
                kode_plant: item?.kode_plant,
                desc_sloc: item?.desc_sloc,
                capacity: totalCapacity ? totalCapacity : item?.capacity || '0',
            };

            const response = await axios.put(getEnv("stockview") + `/sloc/${id}`, newData)

            if (response.status === 200 || response.status === 201) {
                toast.success(response.data.meta.message)
                setId('')
                setIsShowModal(false)
                setFormIndex(formIndex + 1)
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.meta?.message || 'Terjadi kesalahan')
        } finally {
            setIsConfirmDialog(false)
            setIsShowDetail(false)
        }
    }


    const [initialValues, setInitialValues] = useState<any>({
        sloc: item?.kode_sloc,
        plant: item?.kode_plant,
        plant_desc: item?.plant_description,
        company_code: item?.company_code,
        capacity: item?.capacity ? item?.capacity : '0',
    })

    const openModalWithItem = (item: any) => {
        setInitialValues({
            sloc: item?.kode_sloc,
            plant: item?.kode_plant,
            sloc_desc: item?.desc_sloc,
            capacity: item?.capacity || '0',
        });
        setItem(item);
        setIsShowModal(true);
    };

    let role = currentUser.has_roles;
    let company_code_user = currentUser.company_code[0];

    return (
        <>
            <Modal size="xl" show={isShowModal} onHide={() => setIsShowModal(false)}>
                <Modal.Header>Edit Production Stockview</Modal.Header>
                <Modal.Body>
                    <Formik initialValues={initialValues} onSubmit={submit} enableReinitialize>
                        {({ values, setFieldValue }) => (
                            <Form>
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label'>
                                                Plant
                                            </label>
                                            <Field
                                                className='form-control'
                                                name='plant'
                                                type='text'
                                                value={values.plant}
                                                disabled
                                            />
                                            {/* <MetronicErrorMessage name='sap_no' /> */}
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label'>
                                                Sloc
                                            </label>
                                            <Field
                                                className='form-control'
                                                name='sloc'
                                                type='text'
                                                value={`${values.sloc} - ${values.sloc_desc}`}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className='mb-6'>
                                        <label htmlFor='' className='form-label'>
                                            Capacity
                                        </label>
                                        <InputNumber
                                            defaultValue={initialValues.capacity}
                                            min={0}
                                            name="capacity"
                                            className={"w-100 text-end"}
                                            onChange={(value: any) => {
                                                handleOnTotalCapacityChange(value)
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className='d-flex gap-4 mt-5'>
                                    <button type='submit' className='btn btn-primary'>
                                        Save
                                    </button>
                                    <button
                                        type='button'
                                        className='btn btn-secondary'
                                        onClick={() => {
                                            setId('')
                                            setIsShowModal(false)
                                        }}
                                    >
                                        Batal
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>

            <KTCard className='card border mt-5'>
                <KTCardBody >
                    <TableXWrpper
                        title={"Sloc Master Data"}
                        key={formIndex}
                        readonly
                        urlIndex={getEnv("stockview") + `/sloc?company_code=${company_code_user}`}
                        columns={tablecolumns}
                    />
                </KTCardBody>
            </KTCard>
        </>
    )
}

export { SlocMasterDataStockviewList }
