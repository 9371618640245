import axios, {AxiosError} from 'axios'
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query'
import {getEnv} from '../../../../helpers/procurex'
import {PurchaseOrderModel} from './_models'

const TENDER_API_URL = getEnv('tender')
const MEDIA_API_URL = getEnv('media')

/**
 * @see https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories
 */
export const PurchaseOrderKeys = {
  all: ['purchase-orders'] as const,
  lists: () => [...PurchaseOrderKeys.all, 'list'] as const,
  details: () => [...PurchaseOrderKeys.all, 'detail'] as const,
  detail: (uuid: string) => [...PurchaseOrderKeys.details(), uuid] as const,
}

export const PurchaseOrderArchiveKeys = {
  all: ['purchase-order-archives'] as const,
  lists: () => [...PurchaseOrderArchiveKeys.all, 'list'] as const,
  details: () => [...PurchaseOrderArchiveKeys.all, 'detail'] as const,
  detail: (uuid: string) => [...PurchaseOrderArchiveKeys.details(), uuid] as const,
}

export const usePurchaseOrderQuery = (
  uuid: string,
  options?: UseQueryOptions<PurchaseOrderModel, unknown, PurchaseOrderModel>
) => {
  const queryClient = useQueryClient()
  return useQuery<PurchaseOrderModel>({
    queryKey: PurchaseOrderKeys.detail(uuid),
    queryFn: () => axios.get(`${TENDER_API_URL}/po/${uuid}`).then((response) => response.data.data),

    /**
     * @see https://tkdodo.eu/blog/seeding-the-query-cache#seeding-details-from-lists
     */
    initialData: () => {
      const list = queryClient.getQueryData(PurchaseOrderKeys.lists()) as PurchaseOrderModel[]

      return list?.find((po) => po.uuid === uuid)
    },
    initialDataUpdatedAt: () => queryClient.getQueryState(['todos', 'list'])?.dataUpdatedAt,

    ...options,
  })
}


export const PO_ARCHIVE_SOURCE = 'TENDER-PO'
export const TEMP_MEDIA_TYPE_ID = '51'

export const useUploadPurchaseOrderArchiveMutation = (
  options?: Omit<UseMutationOptions, 'mutationFn'>
) =>
  useMutation<any, AxiosError>({
    ...options,

    mutationFn: () =>
      axios.post(`${MEDIA_API_URL}/archive`, {
        source: PO_ARCHIVE_SOURCE,
        from_media_type_id: TEMP_MEDIA_TYPE_ID,
        to_media_type_id: '52',
      }),
  })
