import { ID, KTCard, KTCardBody } from '../../../../../../_metronic/helpers'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import TableXWrpper from "../../../../../components/shared/TableXWrapper";
import SelectX from '../../../../../components/shared/form/SelectX'
import { ApiUrl } from '../../../../../config/Api'
import { getEnv } from "../../../../../../helpers/procurex";
import { useIntl } from 'react-intl'
import axios from 'axios'
import { Button, ButtonGroup, Col, Modal, Row } from "react-bootstrap";
import { useAuth } from "../../../../../../app/modules/auth";

const VesselListStockviewList = () => {
    const intl = useIntl()
    const { currentUser } = useAuth();
    const tablecolumns = [
        {
            Header: "Action",
            accessor: "uuid",
            width: 100,
            className: "text-center fixed-column",
            Cell: (props: any) => {
                const row = props.row.original;
                return (
                    <ButtonGroup size='sm' aria-label='Action'>
                        {!excludedRoles.some(r => role.includes(r)) && (
                            <>
                                <button
                                    type='button'
                                    className='btn btn-sm btn-info'
                                    onClick={(e: any) => {
                                        e.preventDefault();
                                        setId(row.id);
                                        openModalWithItem(row);
                                    }}
                                >
                                    <i className='fa fa-eye' aria-hidden="true"></i>
                                </button>
                            </>
                        )}
                    </ButtonGroup>
                );
            }
        },
        {
            Header: "ETA",
            accessor: "eta",
            width: 120,
            Cell: ({ value }) => {
                if (!value) return '';
                const date = new Date(value);
                const day = date.getDate().toString().padStart(2, '0');
                const month = (date.getMonth() + 1).toString().padStart(2, '0');
                const year = date.getFullYear();
                return `${day}-${month}-${year}`;
            }
        },
        {
            Header: "IMO",
            accessor: "imo",
            width: 80,
        },
        {
            Header: "SHIPNAME",
            accessor: "shipname",
            width: 120,
        },
        {
            Header: "LAT",
            accessor: "lat",
            width: 100,
        },
        {
            Header: "LON",
            accessor: "lon",
            width: 100,
        },
        {
            Header: "DESTINATION",
            accessor: "destination",
            width: 100,
        },
        {
            Header: "FLAG",
            accessor: "flag",
            width: 60,
        },
        {
            Header: "DRAUGHT (m)",
            accessor: "draught",
            width: 100,
            Cell: ({ value }) => {
                return <span>{(value / 10).toFixed(1)} m</span>;
            }
        },
        {
            Header: "TYPE NAME",
            accessor: "type_name",
            width: 100,
        },
        {
            Header: "SHIP COUNTRY",
            accessor: "ship_country",
            width: 100,
        },
        {
            Header: "Create Time",
            accessor: "created_at",
            width: 100,
            Cell: ({ value }) => {
                if (!value) return '';
                const date = new Date(value);
                const day = date.getDate().toString().padStart(2, '0');
                const month = (date.getMonth() + 1).toString().padStart(2, '0');
                const year = date.getFullYear();
                const hours = date.getHours().toString().padStart(2, '0');
                const minutes = date.getMinutes().toString().padStart(2, '0');
                return `${day}-${month}-${year} ${hours}:${minutes}`;
            }
        }
    ];

    const excludedRoles = ['admin_anper', 'DaanExp_Anper', 'Gudang_Bahan_Baku_Anper', 'Perencanaan_Pengadaan_Anper', 'Managerial_PI'];
    const [formindex, setFormIndex] = useState(0);

    const [isShowModal, setIsShowModal] = useState(false)
    const [id, setId] = useState('')
    const [item, setItem] = useState<any>(null)
    const [itemsMasterMaterial, setItemsMasterMaterial] = useState<any>([])
    const [itemsMasterCompany, setItemsCompanyStock] = useState<any>([])
    const [itemLoading, setItemLoading] = useState(false)

    const loadItem = () => {
        axios
            .get(getEnv("masterdata") + `/master-material`)
            .then((res) => {
                setItemsMasterMaterial(res.data.data)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setItemLoading(false)
            })
        axios
            .get(getEnv("masterdata") + `/company-stock`)
            .then((res) => {
                setItemsCompanyStock(res.data.data)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setItemLoading(false)
            })
    }

    useEffect(() => {
        loadItem();
    }, [])

    const openModalWithItem = async (item: any) => {
        setItem(item);
        setIsShowModal(true);
    };

    let company_code_user = currentUser.company_code;
    let role = currentUser.has_roles;
    const poItem = item?.po_item ? JSON.parse(item.po_item) : null;

    return (
        <>
            <Modal size="xl" show={isShowModal} onHide={() => setIsShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Detail Fleet <strong className='font-bold'>{item?.shipname}</strong></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className='col-6'>
                            <ul className='list-group'>
                                <li className='list-group-item'><p><strong>IMO:</strong> {item?.imo}</p></li>
                                <li className='list-group-item'><p><strong>MMSI:</strong> {item?.mmsi}</p></li>
                                <li className='list-group-item'><p><strong>Ship ID:</strong> {item?.ship_id}</p></li>
                                <li className='list-group-item'><p><strong>Latitude:</strong> {item?.lat}</p></li>
                                <li className='list-group-item'><p><strong>Longitude:</strong> {item?.lon}</p></li>
                                <li className='list-group-item'><p><strong>ETA:</strong> {item?.eta}</p></li>
                                <li className='list-group-item'><p><strong>Destination:</strong> {item?.destination}</p></li>
                                <li className='list-group-item'><p><strong>Flag:</strong> {item?.flag}</p></li>
                                <li className='list-group-item'><p><strong>Draught:</strong> {item?.draught}</p></li>
                                <li className='list-group-item'><p><strong>Status:</strong> {item?.status}</p></li>
                                <li className='list-group-item'><p><strong>Heading:</strong> {item?.heading}</p></li>
                                <li className='list-group-item'><p><strong>Speed:</strong> {item?.speed}</p></li>
                                <li className='list-group-item'><p><strong>Width:</strong> {item?.width}</p></li>
                            </ul>
                        </div>
                        <div className='col-6'>
                            <ul className='list-group'>
                                <li className='list-group-item'><p><strong>Type Name:</strong> {item?.type_name}</p></li>
                                <li className='list-group-item'><p><strong>Ship Country:</strong> {item?.ship_country}</p></li>
                                <li className='list-group-item'><p><strong>Ship Class:</strong> {item?.ship_class}</p></li>
                                <li className='list-group-item'><p><strong>Last Port:</strong> {item?.last_port}</p></li>
                                <li className='list-group-item'><p><strong>Next Port:</strong> {item?.next_port_name}</p></li>
                                <li className='list-group-item'><p><strong>ETA:</strong> {item?.eta}</p></li>
                                <li className='list-group-item'><p><strong>Destination:</strong> {item?.destination}</p></li>
                                <li className='list-group-item'><p><strong>Flag:</strong> {item?.flag}</p></li>
                                <li className='list-group-item'><p><strong>Length:</strong> {item?.length}</p></li>
                                <li className='list-group-item'><p><strong>Timestamp:</strong> {item?.timestamp}</p></li>
                                <li className='list-group-item'><p><strong>DWT:</strong> {item?.dwt}</p></li>
                                <li className='list-group-item'><p><strong>Year Built:</strong> {item?.year_built}</p></li>
                                <li className='list-group-item'><p><strong>GRT:</strong> {item?.grt}</p></li>
                            </ul>
                        </div>
                    </div>
                    <div className='mt-3 mb-2'>
                        {item?.po_item ? (
                            (() => {
                                let poItemData;
                                try {
                                    poItemData = JSON.parse(item?.po_item);
                                } catch (error) {
                                    return <div className="alert alert-danger">Invalid JSON data</div>;
                                }

                                const allKeys = Array.isArray(poItemData) ? poItemData.reduce((keys, item) => {
                                    Object.keys(item).forEach(key => {
                                        if (typeof item[key] === 'object' && item[key] !== null) {
                                            Object.keys(item[key]).forEach(nestedKey => {
                                                const combinedKey = `${key}.${nestedKey}`;
                                                if (!keys.includes(combinedKey)) keys.push(combinedKey);
                                            });
                                        } else {
                                            if (!keys.includes(key)) keys.push(key);
                                        }
                                    });
                                    return keys;
                                }, []) : [];

                                return (
                                    <>
                                        <h4>PO NUMBER ({poItem?.[0]?.ITEM?.PO_NUMBER})</h4>
                                        <div className="table-responsive mt-3">
                                            <table className='table table-striped table-bordered'>
                                                <thead>
                                                    <tr>
                                                        {allKeys.map((key, index) => (
                                                            <th key={index}>{key}</th>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {Array.isArray(poItemData) && poItemData.length > 0 ? (
                                                        poItemData.map((item, index) => (
                                                            <tr key={index}>
                                                                {allKeys.map((key) => {
                                                                    const keys = key.split('.');
                                                                    let value = item;

                                                                    keys.forEach(k => {
                                                                        value = value ? value[k] : null;
                                                                    });

                                                                    return (
                                                                        <td key={key}>
                                                                            {value !== undefined && value !== null
                                                                                ? String(value)
                                                                                : 'N/A'}
                                                                        </td>
                                                                    );
                                                                })}
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan={allKeys.length}>NO PO Item data available.</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </>
                                );
                            })()
                        ) : (
                            <div>No PO Item data available.</div>
                        )}
                    </div>
                </Modal.Body>
            </Modal>

            <KTCard className='card border mt-5'>
                <KTCardBody >
                    <TableXWrpper
                        title={"Vessel List"}
                        key={formindex}
                        readonly
                        urlIndex={getEnv("stockview") + `/vessel-repository`}
                        columns={tablecolumns}
                    />
                </KTCardBody>
            </KTCard>
        </>
    )
}

export { VesselListStockviewList }
