/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from "react-intl";
import { AsideMenuItemWithSub } from "./AsideMenuItemWithSub";
import { AsideMenuItem } from "./AsideMenuItem";
import { useGetSideMenu } from "../core/hooks";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Spinner } from "react-bootstrap";
import { Search } from "../../../partials";
import { useAuth } from "../../../../app/modules/auth";
import { useAxiosGet } from "../../../../hooks/useAxios";
import { getEnv } from "../../../../helpers/procurex";
import ModalDocument from "./ModalDocument";

// daftar uri yang akan di ignore untuk mengambil main menu
const ShouldLoadMainMenu = ["account", "dashboard"];

export function AsideMenuMainVendor() {
  const intl = useIntl();
  const { pathname } = useLocation();
  const [{doSubmit, isLoading}] = useAxiosGet(getEnv('sso')+'/module?include=menus,menus.submenus&filter[status]=ACTIVE&sort=order_no&only_me=1')
  const [currentUri, setCurrentUri] = useState("");
  const [isLoadingSideMenu, responseDataSideMenu, responseErrorSideMenu, getDataSideMenu] = useGetSideMenu();
  const [searchMenu, setSearchMenu] = useState("");
  const [modules,setModules] = useState<any>([])


  const getModule = async () => {
    doSubmit({},undefined,undefined).then((res:any)=> {
      setModules(res.data)
    })
  }

  useEffect(() => {
    const uriSlide = pathname.substring(1).split("/");
    if (currentUri !== uriSlide[0]) {
      let uri = ShouldLoadMainMenu.includes(uriSlide[0]) ? "dashboard" : uriSlide[0];
      setCurrentUri(uri);
    }
  }, [pathname]);

  useEffect(() => {
    if (!currentUri) return;
    getDataSideMenu(currentUri);
  }, [currentUri]);

  useEffect(() => {
    getModule();
  }, []);


  return (
    <>
      {isLoadingSideMenu && (
        <div className={"text-center"}>
          <Spinner animation={"border"} style={{ color: "white" }} />
        </div>
      )}
      <div className="aside-search p-1 pb-3">
        {/*<Search />*/}
        <div className="form-group has-search">
          <span className="fa fa-search form-control-feedback"></span>
          <input
            type="text"
            className="form-control form-control-sm"
            placeholder={intl.formatMessage({ id: "Cari Menu" })}
            onChange={(e: any) => setSearchMenu(e.target.value)}
          />
        </div>
      </div>

      <>
        {modules.map((module: any, index: number) => {
          if(module?.menus?.length === 0) return ;
          return (
            <div key={'module-'+index}>
              <span className="menu-section text-muted fs-8 ls-1 px-2 py-2" style={{
                borderTop: index!== 0 ? "1px solid #a9a" : "unset",
              }}>
                {module.name}
              </span>
              {
                module?.menus
                  .filter((v: any) => {
                    return JSON.stringify(v).toLowerCase().includes(searchMenu.toLowerCase());
                  })
                  .map((v: any) => {
                    return (
                      <div key={"sm-" + v.id}>
                        {v.submenus && v.submenus.length > 0 && (
                          <AsideMenuItemWithSub
                            key={"menu" + v.id}
                            to={v.path}
                            // title={intl.formatMessage({id: v.name})}
                            title={v?.name}
                            icon="/media/icons/duotune/general/gen025.svg"
                          >
                            {v.submenus.map((x: any) => {
                              return (
                                <AsideMenuItem
                                  key={"submenu" + v.id + "x" + x.id}
                                  to={x.path}
                                  icon={x.icon}
                                  title={x.name}
                                  countingUrl={x.badge_count_url}
                                />
                              );
                            })}
                          </AsideMenuItemWithSub>
                        )}
                        {(!v.submenus || v.submenus.length === 0) && (
                          <AsideMenuItem 
                            key={"menu" + v.id} 
                            to={v.path} 
                            icon={v.icon} 
                            title={v.name}
                            countingUrl={v.badge_count_url}
                          />
                        )}
                      </div>
                    );
                  })
                
              }
              <br />
            </div>
          )
        })}
        <ModalDocument
          documentType='vendor'
        />
      </>
    </>
  );
}
