import { ButtonGroup, Col, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import { toastError, toastSuccess } from '../../../../../../_metronic/helpers'
import { useEffect, useState } from 'react'
import { useUploadMedia } from '../../../../../../hooks/MediaUploadHook'
import { Form, Formik } from 'formik'
import { useParams } from 'react-router-dom'
import { ApiUrl } from '../../../../../config/Api'

import axios from 'axios'
import * as Yup from 'yup'
import SelectX from '../../../../../components/shared/form/SelectX'
import FormikUploadFile from '../../../../../components/shared/form/FormikUploadFile'
import SelectXTemplateDocument from './SelectXTemplateDocument'

interface Props {
  data: any
}

const Memo = ({ data }: Props) => {
  const { id } = useParams()
  const [key, setKey] = useState(0)
  const { upload } = useUploadMedia()
  const [po, setPo] = useState<any>(null)
  const [items, setItems] = useState<any>([])
  const [documentBase64, setDocumentBase64] = useState('')
  const [isShowForm, setIsShowForm] = useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [loadingDocument, setLoadingDocument] = useState(false)
  const [showModalReplace, setShowModalReplace] = useState(false)
  const [selectedJenisDocument, setSelectedJenisDocument] = useState(1)
  const [jenisDocumentGenerate, setJenisDocumentGenerate] = useState<any>({})

  const validationSchema = Yup.object({
    file: Yup.string().required('The field is required')
  })

  const defaultValue = {
    file: '',
    item: ''
  }

  const [initialValues, setInitialValues] = useState<any>(defaultValue)

  const loadPo = () => {
    axios
      .get(`${ApiUrl}/api/tender/po/${id}`)
      .then((res) => {
        setPo(res.data.data)
      })
      .catch((error) => {
          console.error(error)
      })
      .finally(() => {
      })
  }

  useEffect(() => {
    loadPo()
  }, [id])

  const loadItem = async () => {
    try {
      const res = await axios.get(`${ApiUrl}/api/tender/po-attachment?filter[type]=Purchase Order,SSUK,SSKK,Legal Statement&filter[purchase_order_id]=${po?.id}`)

      setItems(res?.data?.data)

      const cekGeneratePO = res?.data?.data?.filter((item: any) => (item?.type === "Purchase Order"))
      if (cekGeneratePO?.length === 0) submitFormGenerate({"label": "Purchase Order"})

      if (res?.data?.meta?.total > 0) {
        setSelectedJenisDocument(res?.data?.meta?.total)
        loadDocument(res?.data?.data[res?.data?.meta?.total - 1]?.media_url?.replace('/file2', '/convert'))
      }
    } catch (error: any) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (po?.id) {
      loadItem()
      setIsShowForm((po?.status === 'Draft') || (po?.status === 'PO Rejected') || (po?.status === 'Vendor Approval Rejection'))
    }
  }, [po])

  const loadDocument = async (url: string | null) => {
    setDocumentBase64('')
    setLoadingDocument(false)

    if (url === null || loadingDocument) {
      return
    }

    try {
      setLoadingDocument(true)
      const response = await axios.get(url, { responseType: 'blob' })
      setDocumentBase64(URL.createObjectURL(response.data))
    } catch (error: any) {
      console.error(error)
    } finally {
      setLoadingDocument(false)
    }
  }

  const submitFormGenerate = async (values: any) => {
    setLoadingSubmit(true)
    try {
      let response: any

      if (values?.label?.includes("Purchase Order")) {
        response = await axios.get(`${ApiUrl}/api/tender/po/generate-po/${id}`)
      } else if (values?.label?.includes("SSUK")) {
        const $type = (values?.value).replace(" SSUK", "").trim();
        response = await axios.get(`${ApiUrl}/api/tender/po/generate-ssuk/${$type}/${id}`)
      } else if (values?.label?.includes("SSKK")) {
        response = await axios.get(`${ApiUrl}/api/tender/po/generate-sskk/${values?.value}/${id}`)
      }

      toastSuccess(response?.data?.meta?.message)
      setKey(key + 1)
      loadItem()
    } catch (error: any) {
      toastError(error?.response?.data?.meta?.message || 'Terjadi kesalahan')
    }
    setLoadingSubmit(false)
  }

  const setContinuedAction = (values: any, item: any) => {
    if (values === 1) {
      deleteFile(item)
    } else if (values === 2) {
      downloadFile(item)
    } else {
      setInitialValues({
        file: '',
        item: item
      })
      setShowModalReplace(true)
    }
  }

  const deleteFile = async (item: any) => {
    setLoadingSubmit(true)
    try {
      const responses = await Promise.all([
        axios.delete(item?.media_url.replace('/file2', '')),
        axios.delete(`${ApiUrl}/api/tender/po-attachment/${item?.uuid}`)
      ])

      const response = responses[1]

      toastSuccess(response?.data?.meta?.message)
      setDocumentBase64('')
      setSelectedJenisDocument(0)
      loadItem()
      setKey(key + 1)
    } catch (error: any) {
      toastError(error?.response?.data?.meta?.message || 'Terjadi kesalahan')
    }
    setLoadingSubmit(false)
  }

  const downloadFile = async (item: any) => {
    fetch(item?.media_url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'file.docx')
        if (document.body) {
          document.body.appendChild(link)
          link.click()
          link.parentNode?.removeChild(link)
          toastSuccess('Berhasil mengunduh file')
        }
        setKey(key + 1)
      })
      .catch((error) => {
        console.error('Gagal mengunduh file:', error)
        toastError('Gagal mengunduh file')
      })
  }

  const replaceFile = async (values: any) => {
    setLoadingSubmit(true)
    try {
      axios.delete(initialValues?.item?.media_url.replace('/file2', ''))

      if (typeof values?.file !== 'string') {
        let responseFile = await upload('25', values?.file)
        values.file = responseFile.url
      }

      const data = {
        type: initialValues?.item?.type,
        purchase_order_id: id,
        description: `Replace ${initialValues?.item?.type}`,
        media_url: values?.file,
        is_replace: true
      }

      const response = await axios.put(`${ApiUrl}/api/tender/po-attachment/${initialValues?.item?.uuid}`, data)

      toastSuccess(response?.data?.meta?.message)
      setSelectedJenisDocument(0)
      setDocumentBase64('')
      handleClose()
      loadItem()
      setKey(key + 1)
    } catch (error: any) {
      toastError(error?.response?.data?.meta?.message || 'Terjadi kesalahan')
    }
    setLoadingSubmit(false)
  }

  const handleClose = () => {
    setInitialValues(defaultValue)
    setKey(key + 1)
    setShowModalReplace(false)
  }

  return (
    <>
      <Formik
        key={key}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={replaceFile}
      >
        {({ errors, touched, values, setFieldValue }) => (
          <>
            <Modal show={showModalReplace} onHide={handleClose} centered>
              <Form>
                <Modal.Body>
                  <div className='mb-10'>
                    <FormikUploadFile
                      label='Lampiran'
                      name='file'
                      accept='.docx'
                    />
                  </div>
                  <div
                    className='d-flex justify-content-end mb-3 gap-3'
                    data-kt-materialrequest-table-toolbar='base'
                  >
                    <button
                      onClick={handleClose}
                      className='btn btn-sm btn-danger'
                      type='button'
                      disabled={loadingSubmit}
                    >
                      <i className="fas fa-times" />
                      Cancel
                    </button>
                    <button
                      className='btn btn-sm btn-primary'
                      type='submit'
                      disabled={loadingSubmit}
                    >
                      {loadingSubmit ? <span className='spinner-border spinner-border-sm me-1' /> : <i className="fas fa-save" />}
                      Simpan
                    </button>
                  </div>
                </Modal.Body>
              </Form>
            </Modal>
          </>
        )}
      </Formik>

      <div className='card mb-5'>
        <div className='card-body'>
          <Row>
            <Col lg={4}>
              {isShowForm &&
                <>
                  <Row className='mb-5'>
                    <Col lg={9}>
                      <SelectXTemplateDocument
                        data={po}
                        key={key}
                        loadingDocument={loadingDocument}
                        loadingSubmit={loadingSubmit}
                        setJenisDocumentGenerate={setJenisDocumentGenerate}
                      />
                    </Col>
                    <Col lg={3}>
                      <OverlayTrigger
                        placement='right'
                        overlay={<Tooltip id='button-tooltip-2'>Generate</Tooltip>}
                      >
                        <button
                          type='submit'
                          className='btn btn-primary mt-8'
                          onClick={() =>
                            submitFormGenerate(jenisDocumentGenerate)
                          }
                          disabled={loadingDocument || loadingSubmit}
                        >
                          {loadingDocument || loadingSubmit ? (
                            <span className='spinner-border spinner-border-sm' />
                          ) : (
                            <span className='fa-solid fa-gears' />
                          )}
                        </button>
                      </OverlayTrigger>
                    </Col>
                  </Row>
                  <Row className='mb-5'>
                    <Col>
                      <hr />
                    </Col>
                  </Row>
                </>
              }
              <Row>
                {items && items?.map((item: any, index: any) => (
                  <>
                    <Col lg={6}>
                      <button
                        key={index}
                        className={"btn btn-sm w-100 mb-3"}
                        style={{
                          backgroundColor: `${selectedJenisDocument === (index + 1) ? 'var(--bs-primary)' : 'var(--bs-secondary)'}`,
                          color: `${selectedJenisDocument === (index + 1) ? 'white' : 'black'}`,
                          padding: '11px',
                        }}
                        disabled={loadingDocument || loadingSubmit}
                        onClick={async () => {
                          setSelectedJenisDocument((index + 1))
                          await loadDocument(item?.media_url?.replace('/file2', '/convert'))
                        }}
                      >
                        {item?.type}
                      </button>
                    </Col>
                    <Col>
                      <ButtonGroup size={'sm'} aria-label='Action' className='d-flex justify-content-end'>
                        {(po?.status === 'Draft' ||
                          po?.status === 'PO Rejected' ||
                          po?.status === 'Vendor Approval Rejection' ||
                          po?.status === 'Submit for Approval Release') &&
                          <>
                            <OverlayTrigger
                              placement='right'
                              overlay={<Tooltip id='button-tooltip-2'>Delete</Tooltip>}
                            >
                              <button
                                type='button'
                                className='btn btn-sm btn-danger'
                                disabled={loadingDocument || loadingSubmit}
                                onClick={() => {
                                  setContinuedAction(1, item)
                                }}
                              >
                                <i className={'fa fa-trash'} />
                              </button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              placement='right'
                              overlay={<Tooltip id='button-tooltip-2'>Replace</Tooltip>}
                            >
                              <button
                                type='button'
                                className='btn btn-sm btn-warning'
                                disabled={loadingDocument || loadingSubmit}
                                onClick={() => {
                                  setContinuedAction(3, item)
                                }}
                              >
                                <i className="fa-solid fa-file-pen" />
                              </button>
                            </OverlayTrigger>
                          </>
                        }
                        <OverlayTrigger
                          placement='right'
                          overlay={<Tooltip id='button-tooltip-2'>Download</Tooltip>}
                        >
                          <button
                            type='button'
                            className='btn btn-sm btn-info'
                            disabled={loadingDocument || loadingSubmit}
                            onClick={() => {
                              setContinuedAction(2, item)
                            }}
                          >
                            <i className={'fa fa-download'} />
                          </button>
                        </OverlayTrigger>
                      </ButtonGroup>
                    </Col>
                  </>
                ))}
              </Row>
            </Col>
            <Col lg={8}>
              <object width="100%" height="700" data={documentBase64} type="application/pdf"></object>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default Memo
