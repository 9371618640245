import axios from 'axios'
import React, {useRef, useState} from 'react'
import * as Yup from 'yup'
import {useMutation, useQuery} from 'react-query'
import {Form, Formik} from 'formik'
import {Col, Row, Spinner} from 'react-bootstrap'
import {toast} from 'react-toastify'
import {getEnv} from '../../../../../helpers/procurex'
import Ckeditor from '../../../../components/shared/form/Ckeditor'
import DokumenKlarifikasiComponent from './DokumenKlarifikasiComponent'
import {useIntl} from 'react-intl'

const fetchSummaryAanwijzing = (aanwijzingId: any) => {
  return axios.get(`${getEnv('tender')}/aanwijzing-summary/${aanwijzingId}`)
}

const fetchCreateSummaryAanwijzing = (payloadData) => {
  return axios.post(`${getEnv('tender')}/aanwijzing-summary`,payloadData)
}

const SummaryTab = ({aanwijzing}:any) => {
  const [initialValues, setInitialValues] = useState({
    summary: ""
  } as any)
  const validationSchema = Yup.object().shape({
    summary: Yup.string().required("Summary harus diisi")
  })
  const formik = useRef({} as any)
  const intl = useIntl()
  const [key,setKey] = useState(0)

  //region create summary
  const onSuccessCreateSummary = (data:any) => {
    toast.success("Successfull save summary")
  }
  const onErrorCreateSummary = (error:any) => {}
  const {mutate:mutateCreateSummary, isLoading:isLoadingCreateSummary} = useMutation("create-summary-aanwijzing",(payloadData)=>fetchCreateSummaryAanwijzing(payloadData),{
    onSuccess: onSuccessCreateSummary,
    onError: onErrorCreateSummary
  })
  //endregion


  //region get summary
  const onSuccessGetSummary = (data:any) => {
    formik.current.setFieldValue("summary",data.data.data.summary)
    setKey(key + 1)
  }
  const onErrorGetSummary = (error:any) => {}
  const {data, isLoading, refetch} = useQuery("get-summary-aanwijzing",
    ()=>fetchSummaryAanwijzing(aanwijzing?.summary?.uuid),{
      // enabled: aanwijzing?.summary?.uuid !== undefined,
      onSuccess: onSuccessGetSummary,
      onError: onErrorGetSummary
    }
  )
  //endregion

  const handleOnSubmit = async (values:any) => {
    // @ts-ignore
    mutateCreateSummary({
      aanwijzing_id: aanwijzing.uuid,
      summary: values.summary
    })
  }
  
  const isFormEnabled = () => {
    const allowedStatus = [
        'LIVE',
        'DONE',
    ];
    return allowedStatus?.includes(aanwijzing?.status)
  }


  return (
    <div className={"card"}>
      <div className={"card-body p-4"}>
        <Formik
          initialValues={initialValues}
          onSubmit={handleOnSubmit}
          validationSchema={validationSchema}
          innerRef={formik}
          enableReinitialize
        >
          {({
              errors,
              touched,
              values,
              setFieldValue,
              isValid,
              setTouched,
              isSubmitting,
              dirty,
            }) => (
            <Form>
              <Row className={"mb-4 px-4"}>
                <Col>
                  <div className='mb-10'>
                    <div className={"d-flex justify-content-between mb-10"}>
                      <div>
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="required form-label"
                        >
                          <span>Summary</span>
                        </label>
                      </div>
                      <div>
                        <div className={'form-group text-end'}>
                          <button
                            type="submit"
                            className="btn btn-info btn-sm"
                            disabled={isLoadingCreateSummary || !isFormEnabled()}
                          >
                            {(isLoadingCreateSummary) ? (
                              <Spinner animation="border" size="sm" className={'me-2'} />
                            ) : (
                              <i className="fa fa-save"></i>
                            )}
                            Save Summary
                          </button>
                        </div>
                      </div>
                    </div>

                    {/*<FormikEditor label={"Summary"} name={"summary"} />*/}
                    <Ckeditor
                      key={key}
                      defaultValue={values.summary}
                      value={values.summary}
                      onChange={(value: string) => {
                        setFieldValue('summary', value)
                        setTouched({summary: true})
                      }}
                      disabled={!isFormEnabled()}
                    />
                    {/*<SyncfusionPremium />*/}
                    {/*<ReactQuill*/}
                    {/*  value={values.summary}*/}
                    {/*  onChange={(value) => {*/}
                    {/*    setFieldValue('summary', value)*/}
                    {/*    setTouched({summary: true})*/}
                    {/*  }}*/}
                    {/*  readOnly={!isFormEnabled()}*/}
                    {/*  theme='snow'*/}
                    {/*  style={{height: '450px'}}*/}
                    {/*  modules={{*/}
                    {/*    toolbar: [*/}
                    {/*      [{header: [1, 2, false]}],*/}
                    {/*      ['bold', 'italic', 'underline', 'strike', 'blockquote','table'],*/}
                    {/*      [*/}
                    {/*        {list: 'ordered'},*/}
                    {/*        {list: 'bullet'},*/}
                    {/*        {indent: '-1'},*/}
                    {/*        {indent: '+1'},*/}
                    {/*      ],*/}
                    {/*      ['link', 'image'],*/}
                    {/*      ['clean'],*/}
                    {/*    ],*/}
                    {/*  }}*/}
                    {/*/>*/}
                    {touched.summary &&
                      errors.summary && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            {/* @ts-ignore*/}
                            <span role='alert'>{errors.summary}</span>
                          </div>
                        </div>
                      )}
                  </div>
                </Col>
              </Row>

            </Form>
          )}
        </Formik>

        <div className={"form-group p-2 border-top"}>

          <DokumenKlarifikasiComponent
            aanwijzing={aanwijzing}
            // proposalTender={proposalTender}
          />
        </div>

      </div>
    </div>
  )
}


export default SummaryTab;