import { Field, Form, Formik } from "formik";
import { Accordion } from "react-bootstrap";
import * as Yup from "yup";
import React, { useEffect, useRef, useState } from "react";
import {formatNumberToDecimal, getEnv, parseStringToBool, formatNumberX} from '../../../../../../helpers/procurex'
import FormikSelect2 from "../../../../../components/shared/form/FormikSelect2";
import FormAttachment from "../../../../../components/shared/form/FormAttachment";
import { useAxiosGet, useAxiosPost } from "../../../../../../hooks/useAxios";
import { toast } from "react-toastify";
import PreviewAttachment from "../../../../../components/PreviewAttachment";
import CurrencyInput from "react-currency-input-field";
import DatepickerX2 from "../../../../../components/shared/form/DatepickerX2";
import { useNavigate, useParams } from "react-router-dom";
import QuotationItemFormTable from "./QuotationItemFormTable";
import BottomToolbarWrapper from "../../../../../components/shared/BottomToolbarWrapper";
import { Placeholder } from "rsuite";
import { useIntl } from "react-intl";
import {formatCurrency, toastError} from '../../../../../../_metronic/helpers'
import { getQuotationStatus } from "./SideLeftLayout";
import Swal from 'sweetalert2'
import {useMutation} from 'react-query'
import axios from 'axios'
import {useProposalTenderVendor} from '../core/_proposalTenderVendorProvider'


const HeaderPenawaran = ({ proposalTender, values, setFieldValue, touched, errors, isDisabledForm, quotationStep }: any) => {
  const handleScroll = (e: any) => {
    e.preventDefault();
  };
  const intl = useIntl();
  const isNomorQuotationCommercialEnabled = () => {
    const allowedStatus = [
      "Penawaran Komersil"
    ];
    return allowedStatus.includes(proposalTender?.status) &&
      quotationStep === 2;
  };

  const isNomorQuotationCommercialShow = () => {
    return quotationStep === 2 && proposalTender?.metode_penyampaian === "2 Tahap";
  };

  return (
    <>
      <div className="row">
        <div className={"col-md-6 col-sm-12"}>
          <div className={"form-group mb-4"}>
            <label className="required form-label">
              {intl.formatMessage({ id: "Nomor Quotation" })}
            </label>
            <Field
              name="quotation_no"
              type="text"
              className="form-control"
              disabled={isDisabledForm}
            />
            {(errors.quotation_no && touched.quotation_no) && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{errors.quotation_no}</div>
              </div>
            )}
          </div>
          {isNomorQuotationCommercialShow() && (
            <div className={"form-group mb-4"}>
              <label className="required form-label">
                {intl.formatMessage({ id: "Nomor Quotation Komersial" })}
              </label>
              <Field
                name="quotation_commercial_number"
                type="text"
                className="form-control"
                disabled={!isNomorQuotationCommercialEnabled()}
              />
              {(errors.quotation_commercial_number && touched.quotation_commercial_number) && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{errors.quotation_commercial_number}</div>
                </div>
              )}
            </div>
          )}
          <div className={"form-group mb-4"}>
            <label className="required form-label">
              {intl.formatMessage({ id: "Tanggal Penawaran" })}
            </label>
            <DatepickerX2
              onChange={(date: any) => setFieldValue("date", date)}
              size={"lg"}
              readonly={isDisabledForm}
              value={values.date}
              format={"dd-MM-yyyy"}
            />
            {(errors.date && touched.date) && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{errors.date}</div>
              </div>
            )}
          </div>
          <div className={"form-group mb-4"}>
            <label className="required form-label">
              {intl.formatMessage({ id: "Masa Berlaku Penawaran" })}
            </label>
            <div className={"input-group"} onWheel={handleScroll}>
              <Field
                name="validity"
                type="number"
                min={0}
                className="form-control"
                disabled={isDisabledForm}
              />
              <div className="input-group-append">
                <span className="input-group-text">Hari</span>
              </div>
            </div>
            {(errors.validity && touched.validity) && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{errors.validity}</div>
              </div>
            )}
          </div>
        </div>
        <div className={"col-md-6 col-sm-12"}>
          <div className={"form-group mb-4"}>
            <label className="required form-label">
              {intl.formatMessage({ id: "Incoterm" })}
            </label>
            <FormikSelect2
              name="incoterm_id"
              optionsUrl={`${getEnv("masterdata")}/incoterm`}
              unique_property={"id"}
              search_property={"code_name"}
              getOptionValue={(opt: any) => opt}
              getOptionLabel={(opt: any) =>
                `${opt.code}`
              }
              isDisabled={isDisabledForm}
            />
          </div>
          <div className={"form-group mb-4"}>
            <label className={"mb-2 required"} style={{
              fontWeight: "450"
            }}>
              {intl.formatMessage({ id: "Lokasi Pengiriman / Pekerjaan" })}
            </label>
            <Field
              name="location"
              type="text"
              className="form-control"
              disabled={isDisabledForm}
              label={"location"} />
            {
              (errors.location && touched.location) && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{errors.location}</div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </>
  );
};

const JaminanPenawaran = ({ values, quotation, setFieldValue, touched, errors, isDisabledForm, isRequired, setBidBondValue, bidBondValue }: any) => {
  const {proposalTender} = useProposalTenderVendor();
  const intl = useIntl();
  return (
    <>
      {!quotation.id && (
        <div className={'alert alert-info'}>
          <i className={'fa fa-info-circle me-2'}></i>
          {intl.formatMessage({id: 'Load Quotation terlebih dahulu untuk meload jaminan penawaran'})}
        </div>
      )}
      {quotation.id && (
        <div className="row" >
          <div className={"col-md-6 col-sm-12"}>
            <div className={'form-group mb-4'}>
              <label className={`form-label ${isRequired ? 'required' : ''}`}>
                {intl.formatMessage({id: 'Nilai Jaminan Penawaran (Bid Bond)'})}
              </label>
              <div className={'input-group'}>
                <CurrencyInput
                  className="form-control"
                  groupSeparator="."
                  decimalSeparator=","
                  decimalsLimit={3}
                  defaultValue={formatNumberX(values.bid_bond_value, 2)}
                  value={formatNumberX(values.bid_bond_value,2)}
                  step={1}
                  onValueChange={(value) => {
                    const bid_bod_value= formatNumberX(value,2)
                    // setFieldValue(`harga_satuan`, value)
                    setFieldValue(`bid_bond_value`, bid_bod_value)
                    setBidBondValue({
                      ...bidBondValue,
                      total: bid_bod_value
                    })
                  }}
                  name='bid_bond_value'
                  disabled={
                    isDisabledForm
                  }
                />
                <div className="input-group-append">
                  <button
                    type={'button'}
                    className={
                      'btn btn-default border border-secondary rounded-0 rounded-end input-group-text'
                    }
                  >
                    {proposalTender?.items[0]?.currency}
                  </button>
                </div>
              </div>
              {(errors.bid_bond_value && touched.bid_bond_value) && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{errors.bid_bond_value}</div>
                </div>
              )}
              <div className={'text-muted'}>
                <i><small>*Min Bidbond is {proposalTender?.bidbond_threshold?.percentage_minimum} % from your total bid price.</small></i>
              </div>
            </div>
            <div className={'form-group mb-4'}>
              <FormAttachment
                media_type_filter={'TENDER_ATTACHMENT'}
                onlyFile={true}
                afterSubmit={(e: any) => {
                  setFieldValue('bid_bond_attachment', e)
                  setBidBondValue({
                    ...bidBondValue,
                    attachment: e
                  })
                }}
                defaultValue={values.bid_bond_attachment}
                isRequired={isRequired}
                isDisabled={isDisabledForm}
                overWriteErrMessage={true}
              />
              {(errors.bid_bond_attachment && touched.bid_bond_attachment) && (
                <div className="fv-plugins-message-container">
                <div className="fv-help-block">{errors.bid_bond_attachment}</div>
              </div>
              )}
            </div>
          </div>
          <div className={'col-md-6 col-sm-12'}>
            <div className={'form-group mb-4'}>
              <label className={`form-label ${isRequired ? "required" : ""}`}>
                {intl.formatMessage({ id: "Masa Berlaku Jaminan Penawaran (Bid Bond)" })}
              </label>
              <DatepickerX2
                onChange={(date: any) => {
                  setFieldValue("bid_bond_validity", date)
                  setBidBondValue({
                    ...bidBondValue,
                    validity: date
                  })
                }}
                size={"lg"}
                readonly={isDisabledForm}
                value={values.bid_bond_validity}
              />
              {(errors.bid_bond_validity && touched.bid_bond_validity) && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{errors.bid_bond_validity}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const PersyaratanDokumenPenawaran = ({ proposalTender, quotationStep, onChange }: any) => {
  const [previewAttachment, setPreviewAttachment] = React.useState("");
  const [renderKey,setRenderKey] = useState(1)
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosPost(
    getEnv("tender") + `/vendor/quotation-registration/requirement/${proposalTender?.uuid}`
  );
  const [syarats, setSyarats] = React.useState<any>([]);
  const [selectedIndexRemove,setSelectedIndexRemove] = useState(0)

  const intl = useIntl();
  const handleAfterSubmit = (e: any, item: any, index: number) => {
    const payload = {
      proposal_tender_syarat_id: item.uuid,
      media_url: e.url,
      media_type: {
        ...e.type,
        notes: e.notes
      }
    };
    setSyarats((prevState: any) => {
          prevState[index].media_url = e.url;
          prevState[index].media_notes = e.notes;
          prevState[index].store_data = payload;
          return prevState;
    });
    setRenderKey(renderKey+1)
    // doSubmit(payload, null, toast).then((res: any) => {
    //   setSyarats((prevState: any) => {
    //     prevState[index].media_url = e.url;
    //     prevState[index].media_notes = e.notes;
    //     return prevState;
    //   });
    // });
  };

  const isFormEnabled = () => {
    return proposalTender?.published_action?.quotation && proposalTender?.vendor_status === "REGISTRATION-QUOTATION";
    // return proposalTender?.status_detail === "Quotation" && proposalTender?.status === "Penawaran";
  };

  const handlePreviewAttachment = (e: any, url: any) => {
    e.preventDefault();
    setPreviewAttachment(url);
  };

  const generateSyarat1Sampul = () => {
    return proposalTender?.syarats?.filter((item: any) => item.type !== "configuration");
  };

  const generateSyarat = () => {
    const arr = generateSyarat1Sampul();
    if (proposalTender?.metode_penyampaian === "2 Tahap" ||
      proposalTender?.metode_penyampaian === "2 Sampul"
    )
    {
      if (quotationStep === 1) {
        return arr.filter((item: any) => item.type === "administration" || item.type === "technical");
      } else {
        return arr.filter((item: any) => item.type === "commercial");
      }
    }
    console.log(arr);
    return arr;
  };

  const {mutate} = useMutation({
    // @ts-ignore
    mutationFn: (payload:any) => axios.post(getEnv('tender')+`/vendor/quotation-registration/requirement/${proposalTender?.uuid}`,{
      ...payload,
      _method: "DELETE"
    }),
    enabled: false,
    onSuccess: (res:any) => {
      setSyarats((prevState: any) => {
        prevState[selectedIndexRemove].media_url = null;
        prevState[selectedIndexRemove].media_notes = null;
        return prevState;
      });
      toast.success(res?.data?.meta?.message)
    }
  });

  const handleOnDeleteSyarat = (item:any, index: number) => {
    // setSelectedIndexRemove(index)
    // Swal.fire({
    //   title: intl.formatMessage({id:'Apakah Anda yakin'})+"?",
    //   text: intl.formatMessage({id: 'Anda akan menghapus attachment ini'}),
    //   icon: 'question',
    //   showCancelButton: true,
    //   confirmButtonText: intl.formatMessage({id:'Ya, Submit'}),
    //   cancelButtonText: intl.formatMessage({id:'Tidak'}),
    // }).then((result) => {
    //   /* Read more about isConfirmed, isDenied below */
    //   console.log(item)
    //   if (result.isConfirmed) {
    //     mutate({
    //       proposal_tender_syarat_id: item?.uuid
    //     })
    //   }
    // })

    setSyarats((prevState: any) => {
      prevState[index].media_url = null;
      prevState[index].media_notes = null;
      prevState[index].store_data = null;
      prevState[index].delete_data = {
        _method: "DELETE",
        proposal_tender_syarat_id: item?.uuid
      };

      return prevState;
    });
    setRenderKey(renderKey+1)
  }

  useEffect(() => {
    if (proposalTender?.syarats) {
      setSyarats(generateSyarat());
    }

  }, [proposalTender]);

  const isCanDeleteAttachment = () => {
    const allowedStatus = [
      "Penawaran",
      "Penawaran Teknikal",
      "Penawaran Komersil"
    ]
    return allowedStatus.includes(proposalTender?.status)
  }

  useEffect(() => {
    onChange && onChange(syarats)
  }, [renderKey])

  useEffect(() => {
    onChange(syarats)
  }, [syarats])

  const [maxSizeUpload, setMaxSizeUpload] = useState("")
  const [{ doSubmit: doSubmitGetDetailMediaType}] = useAxiosGet(
    getEnv('media') + '/media-type?filter[name]=FREE_ATTACHMENT'
  )

  useEffect(() => {
    doSubmitGetDetailMediaType({}, undefined, undefined).then((res: any) => {
      if (res?.data?.length > 0) {
        setMaxSizeUpload(`${res?.data[0]?.max_size_in_bytes / 1024} MB`)
      } else {
        setMaxSizeUpload(`15 MB`)
      }
    });
  }, [])


  return (
    <>
      {!isFormEnabled() && (
        <div className={"alert alert-warning text-dark gap-2"}>
          <i className={"fa fa-exclamation-triangle me-2"}></i>
          {intl.formatMessage({ id: 'Hanya dapat diisi ketika sudah masuk waktu quotation'})}
        </div>
      )}
      {isFormEnabled() && (
        <div className={"alert alert-warning text-dark gap-2"}>
          <i className={"fa fa-exclamation-triangle me-2"}></i>
          {intl.formatMessage({ id: 'Maksimal file yang bisa diupload berukuran'})} {maxSizeUpload}
        </div>
      )}
      <table className={"table table-bordered"}>
        <thead>
        <tr>
          <th className={"text-center border"}><strong>{intl.formatMessage({id:'No'})}</strong></th>
          <th className={"text-center border"}><strong>{intl.formatMessage({id:'Jenis Dokumen'})}</strong></th>
          <th className={"text-center border"}><strong>{intl.formatMessage({id:'Deskripsi'})}</strong></th>
          <th className={"text-center border"}><strong>{intl.formatMessage({id:'Wajib Lampiran'})}</strong></th>
          <th className={"text-center border"}><strong>{intl.formatMessage({id:"Lampiran"})}</strong></th>
        </tr>
        </thead>
        <tbody key={renderKey}>
        {syarats.map((item: any, index: number) => {
            return (
              <tr key={index}>
                <td className={"text-center border"}>{index + 1}</td>
                <td className={"text-center border"}>
                  {item?.type}
                </td>
                <td className={"text-center border"}>{item?.description}</td>
                <td className={"text-center border"}>{item?.required_attachment ? "Ya" : "Tidak"}</td>
                <td className={'text-start border'}>

                  {/*{item?.media_url && (*/}
                  {/*  <div className={'text-end mb-2 pe-2'}>*/}
                  {/*    <a href={'#'}*/}
                  {/*       onClick={(e: any) => handlePreviewAttachment(e, item.media_url)}*/}
                  {/*       className={'text-primary mb-2'}*/}
                  {/*    >*/}
                  {/*      <small>{intl.formatMessage({id: 'Preview'})}</small>*/}
                  {/*    </a>*/}
                  {/*  </div>*/}
                  {/*)}*/}
                  {/*{item?.}*/}
                  <FormAttachment
                    isSmall={true}
                    isHideLabel={true}
                    media_type_filter={'pq_vendor_attachment'}
                    onlyFile={true}
                    isLoading={isLoading}
                    afterSubmit={(e: any) => handleAfterSubmit(e, item, index)}
                    isDisabled={!isFormEnabled()}
                  />
                  {(item?.media_url) && (
                    <div className={'w-100 px-4 py-2 d-flex justify-content-between align-items-center'} style={{
                      backgroundColor: '#f1faff',
                    }}>
                      <div>
                        <a href={'javascript:;'}
                           onClick={(e: any) => handlePreviewAttachment(e, item.media_url)}
                           className={'text-info mt-4'}
                           style={{
                             color: 'blue',
                             textDecoration: 'underline',
                           }}>
                          <strong>{item?.media_notes || (
                            <>
                              <i className={'fa fa-eye me-2 text-info'}></i>
                              Preview
                            </>
                          )}</strong>
                        </a>
                      </div>
                      {!item?.required_attachment && (
                        <>
                          {isCanDeleteAttachment() && (
                            <div>
                              <a
                                href={'javascript:;'}
                                title={'Remove'}
                                className={''}
                                onClick={() => handleOnDeleteSyarat(item, index)}
                              >
                                <i className={'fa fa-times text-danger'}></i>
                              </a>
                            </div>
                          )}
                        </>
                      )}


                    </div>
                  )}

                </td>
              </tr>
            );
          }
        )}
        </tbody>
      </table>
      <PreviewAttachment previewExt={previewAttachment} onHide={() => setPreviewAttachment('')} />
    </>
  );
};

const QuotationVendorTab = ({proposalTender, quotationStep, onRefresh}: any) => {
  const [initialValues, setInitialValues] = useState({
    proposal_tender_id: proposalTender?.uuid,
    quotation_no: "",
    quotation_commercial_number: "",
    date: "",
    validity: "",
    incoterm: "",
    incoterm_id: "",
    location: "",
    bid_bond: true,
    bid_bond_value: "",
    bid_bond_validity: "",
    bid_bond_attachment: {},
    tkdn: proposalTender?.tkdn ? true : false,
    tkdn_no: "",
    tkdn_value: "",
    tkdn_validity: "",
    tkdn_attachment: {},
    note: "",
    items: proposalTender?.items,
    syarats: []
  });
  const intl = useIntl();
  const [formSchemaValidation, setFormSchemaValidation] = useState(Yup.object().shape({}));
  const formik = useRef({} as any);
  const [isRequiredBidBond, setIsRequiredBidBond] = useState(false)
  const [isExpandAll, setIsExpandAll] = useState(true);
  const [formIndex, setFormIndex] = useState(0);
  const [activeCollapse, setActiveCollapse] = useState(["0", "1", "2", "3", "4", "5"]);
  const navigate = useNavigate();
  const errorRef = useRef();
  const [errorMessages, setErrorMessages] = useState("");
  const [quotationStatus, setQuotationStatus] = useState({
    status: true,
    message: ""
  });
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosPost(
    getEnv("tender") + `/vendor/quotation`
  );
  const [currentQuotation, setCurrentQuotation] = useState<any>({});
  const [{ doSubmit: doGetDetail, isLoading: isLoadingDetail, data: dataDetail }] = useAxiosGet(
    getEnv("tender") + `/vendor/quotation?filter[proposal_tender_id]=${proposalTender?.id}&perPage=1000`
  );
  const [quotationItems, setQuotationItems] = useState<any>([]);
  const [bidBondValue, setBidBondValue] = useState({
    total: null,
    validity: null,
    attachment: null,
  });
  const quotationItemRef = useRef<any>([]);
  const [sectionValidation, setSectionValidation] = useState<any>({
    header: true,
    bid_bond: true,
    tkdn: true,
    items: true,
    persyaratan: true
  });
  const [{ doSubmit: doSubmitGetDetail}] = useAxiosGet(
    getEnv('tender')+'vendor/published'
  )
  const {uuid} = useParams();

  const handleOnSubmitQuotation = async (values: any, { setStatus, setSubmitting }: any) => {
    if (!proposalTender?.published_action?.quotation) {
      Swal.fire({
        title: intl.formatMessage({id:'Tidak dalam masa Input Penawaran'}),
        icon: "error",
      }).then((result) => {
      });
    } else {
      //region validate syarat
      let state = true;
      values?.syarats?.map((e:any)=>{
        if(e?.required_attachment){
          if(!e?.media_url){
            toast.error(intl.formatMessage({id:"Please fill required document first"}))
            state = false;
          }
        }
      })

      // region validate validity in quotation not be lower than validity in proposal tender
      if (parseInt(values?.validity) < parseInt(proposalTender?.masa_berlaku)) {
        toast.error("Validity in Quotation cannot be lower than the validity in Proposal Tender");
        state = false;
      }
      // endregion

      console.log(values?.syarats)
      if(!state) return ;
      //endregion

      //region submit syarats
      setErrorMessages("");
      values?.syarats?.map(async (e)=> {
        if(e?.store_data) await doSubmit(e?.store_data,getEnv("tender") + `/vendor/quotation-registration/requirement/${proposalTender?.uuid}`)
        if(e?.delete_data) await doSubmit(e?.delete_data,getEnv("tender") + `/vendor/quotation-registration/requirement/${proposalTender?.uuid}`)
      })

      await submitQuotation(values, setStatus, setSubmitting);
      //endregion
    }
  };

  const submitQuotation = async (values: any, setStatus: any, setSubmitting: any) => {
    let newUrl = getEnv("tender") + `/vendor/quotation`;
    let method = "POST";
    if (currentQuotation?.uuid) {
      newUrl = newUrl + `/${currentQuotation?.uuid}`;
      method = "PUT";
    }
    const payload = {
      _method: method,
      proposal_tender_id: values.proposal_tender_id,
      quotation_number: values.quotation_no,
      quotation_commercial_number: values.quotation_commercial_number,
      date: values.date,
      validity: values.validity,
      incoterm_id: values.incoterm_id?.id,
      location: values.location,
      bid_bond_value: formatNumberX(values.bid_bond_value),
      bid_bond_media_type: values.bid_bond_attachment?.type,
      bid_bond_media_url: values.bid_bond_attachment?.url,
      bid_bond_masa_berlaku: values.bid_bond_validity,
      tkdn_no: values.tkdn_no,
      tkdn: values.tkdn_value,
      tkdn_masa_berlaku: values.tkdn_validity,
      note: values.note
    };
    // console.log(generateProposalTenderItemAsCreateQuotationItemPayload())

    // store quotation header
    await doSubmit(payload, newUrl, undefined).catch(e => {
      Swal.fire({
        title: intl.formatMessage({id: e?.response?.data?.meta?.message}),
        icon: "error",
      })
    });
    await Promise.all(
      quotationItems?.map(async (itm: any) => {
        const itemUrl = getEnv("tender") + `/vendor/quotation-item/${itm?.uuid}`;
        const itmPayload = {
          ...itm,
          quotation_id: currentQuotation?.uuid,
          _method: "PUT",
          price: formatNumberToDecimal(itm?.price ?? itm?.valuation_price, 3),
          // @ts-ignore
          additional_cost: formatNumberToDecimal(itm?.additional_cost || 0, 3),
        };

        // return;
        await doSubmit(itmPayload, itemUrl, undefined);
        await Promise.all(
          // simpan level service line
          itm?.serviceLines?.map(async (itmService: any) => {
            const serviceUrl = getEnv("tender") + `/vendor/quotation-item-service/${itmService?.uuid}`;
            const servicePayload = {
              ...itmService,
              quotation_item_id: itm?.uuid,
              _method: "PUT",
              price: formatNumberToDecimal(itmService?.price ?? itmService?.net_price, 3)
            };
            await doSubmit(servicePayload, serviceUrl, undefined);
          })
        )
      })
    )
    // simpan level item
    toast.success("Success saving data");
    getQuotation()
    doSubmitGetDetail(
      {},
      getEnv('tender')+"/vendor/published/"+uuid
    ).then(r => {
      // console.log("R",r)
      // @ts-ignore
      setQuotationStatus(getQuotationStatus(r?.data,intl,quotationStep))
      onRefresh && onRefresh(false);
    });
  }

  const checkCompleteness = () => {
    setErrorMessages("")
    //region validation
    if(currentQuotation?.uuid) {
      // doGetDetail({}, getEnv('tender') + `/vendor/quotation/status/${currentQuotation?.uuid}`, undefined).then((res: any) => {

      // }).catch((err: any) => {
      //   if(err?.response?.data){
      //     setErrorMessages(err?.response?.data?.meta?.message);
      //     // @ts-ignore
      //     if (errorRef?.current) {
      //       // @ts-ignore
      //       const errorOffsetTop = errorRef?.current?.offsetTop;
      //       const scrollToPosition = errorOffsetTop - 30; // Mengurangkan 30px dari posisi offset elemen

      //       window.scrollTo({
      //         top: scrollToPosition,
      //         behavior: 'smooth' // Efek smooth untuk perpindahan scroll
      //       });
      //     }
      //   }
      // });
    } else {

    }
    //endregion
  }

  const handleOnSubmitQuotationItem = (payload: any) => {
    doSubmit(payload, getEnv("tender") + `/vendor/quotation-item`, undefined).then((res: any) => {
      getQuotation();
    })
  };

  const checkIsBidBond = () => {
    return false;
  };

  const checkIsTKDN = () => {
    return proposalTender?.tkdn;
  };

  const expandAll = () => {
    setActiveCollapse(["0", "1", "2", "3", "4", "5"]);
    setIsExpandAll(true);
  };

  const hideAll = () => {
    setActiveCollapse([]);
    setIsExpandAll(false);
  };

  const showSidebar = () => {
    // hide sidebar
    const elementToClick = document.getElementById("kt_aside_toggle");

    if (elementToClick && elementToClick.classList.contains("active")) {
      // Memicu klik pada elemen
      elementToClick.click();
    }
  };

  const goBack = async () => {
    showSidebar();
    navigate(-2);
  };

  const getQuotation = () => {
    doGetDetail(null, "", null).then((res: any) => {
      if (res.data && res.data.length) {
        setCurrentQuotation(res.data[0]);

        setInitialValues({
          ...initialValues,
          quotation_no: res.data[0].quotation_number,
          quotation_commercial_number: res.data[0].quotation_commercial_number,
          date: res.data[0].date,
          validity: res.data[0].validity,
          location: res.data[0].location,
          bid_bond_value: res.data[0].bid_bond_value,
          bid_bond_validity: res.data[0].bid_bond_masa_berlaku,
          bid_bond_attachment: {
            type: res.data[0].bid_bond_media_type,
            url: res.data[0].bid_bond_media_url
          },
          tkdn_value: res.data[0].tkdn,
          tkdn_validity: res.data[0].tkdn_masa_berlaku,
          note: res.data[0].note,
          incoterm_id: res.data[0].incoterm
        });
      }

      setBidBondValue({
        total: res.data[0].bid_bond_value === "0.000" ? null : res.data[0].validity,
        validity: res.data[0].validity,
        attachment: res.data[0].bid_bond_media_type ? {
          type: res.data[0].bid_bond_media_type,
          url: res.data[0].bid_bond_media_url
        } : null,
      });

      setFormIndex(formIndex + 1);
      checkCompleteness()
    });
  };

  const checkIsFormEnabled = () => {
    if (proposalTender?.metode_penyampaian === "2 Sampul") {
      if (quotationStep === 2) return false;
    }

    const proposalTenderStatusAllowed = [
      "Penawaran",
      "Penawaran Teknikal"
    ];
    const proposalTenderStatusDetailAllowed = [
      "Quotation"
    ];
    const proposalTenderVendorStatusAllowed = [
      "REGISTRATION-QUOTATION"
    ];
    // return proposalTenderStatusAllowed.includes(proposalTender?.status) &&
    //   proposalTenderStatusDetailAllowed.includes(proposalTender?.status_detail) &&
    //   proposalTenderVendorStatusAllowed.includes(proposalTender?.vendor_status);
    return proposalTenderVendorStatusAllowed.includes(proposalTender?.vendor_status)
      && proposalTenderStatusAllowed.includes(proposalTender?.status)
      && proposalTender?.published_action?.quotation;
  };

  const checkIsQuotationItemEnabled = () => {
    if (proposalTender?.metode_penyampaian === "2 Sampul") {
      if (quotationStep === 2) return true;
    }

    const proposalTenderStatusAllowed = [
      "Penawaran",
      "Penawaran Teknikal",
      "Penawaran Komersil"
    ];
    const proposalTenderStatusDetailAllowed = [
      "Quotation"
    ];
    const proposalTenderVendorStatusAllowed = [
      "REGISTRATION-QUOTATION"
    ];

    // return proposalTenderStatusAllowed.includes(proposalTender?.status) &&
    //   proposalTenderStatusDetailAllowed.includes(proposalTender?.status_detail) &&
    //   proposalTenderVendorStatusAllowed.includes(proposalTender?.vendor_status);
    return proposalTenderVendorStatusAllowed.includes(proposalTender?.vendor_status)
      && proposalTenderStatusAllowed.includes(proposalTender?.status)
      && proposalTender?.published_action?.quotation;
  };

  const checkIsButtonSubmitEnabled = () => {
    let proposalTenderStatusAllowed = [
      "Penawaran",
      "Penawaran Teknikal"
    ];
    let proposalTenderStatusDetailAllowed = [
      "Quotation"
    ];
    let proposalTenderVendorStatusAllowed = [
      "REGISTRATION-QUOTATION"
    ];

    if (quotationStep === 1) {
      return proposalTenderVendorStatusAllowed.includes(proposalTender?.vendor_status)
        && proposalTenderStatusAllowed.includes(proposalTender?.status)
        // && proposalTender?.published_action?.quotation;
    } else {
      proposalTenderStatusAllowed.push("Penawaran Komersil");
      return proposalTenderVendorStatusAllowed.includes(proposalTender?.vendor_status)
        && proposalTenderStatusAllowed.includes(proposalTender?.status)
        // && proposalTender?.published_action?.quotation;

    }

  };

  const isBidbondEnabled = () => {
    if (proposalTender?.metode_penyampaian === "1 Sampul") {
      return true;
    }

    if ((proposalTender?.metode_penyampaian === "2 Sampul" || proposalTender?.metode_penyampaian === "2 Tahap") && quotationStep === 2) {
      return true;
    }

    if(proposalTender?.metode_penyampaian === '2 Tahap' && quotationStep === 2) {
      return true;
    }

    return false;
  };

  const renderValidationSchema = () => {
    const validation = {
      proposal_tender_id: Yup.string().required("The field is required"),
      quotation_no: Yup.string().required("The field is required"),
      date: Yup.string().required("The field is required"),
      validity: Yup.string().required("The field is required"),
      location: Yup.string().required("The field is required"),
      note: Yup.string().nullable()
    }

    const bidBond = {
      bid_bond: Yup.boolean().nullable(),
      bid_bond_value: Yup.string().when("bid_bond", {
        is: true,
        then: Yup.string().required("The field is required"),
        otherwise: Yup.string().nullable()
      }),
      bid_bond_validity: Yup.string().when("bid_bond", {
        is: true,
        then: Yup.string().required("The field is required"),
        otherwise: Yup.string().nullable()
      }),
      // bid_bond_attachment: Yup.string().when("bid_bond", {
      //   is: true,
      //   then: Yup.string().nullable().required('The field is required'),
      //   otherwise: Yup.string().nullable()
      // })
    }

    if (proposalTender?.metode_penyampaian === "1 Sampul") {
      if (currentQuotation?.id && isRequiredBidBond) {
        Object.assign(validation, bidBond);
      }
      setFormSchemaValidation(Yup.object().shape(validation));
    } else {
      setFormSchemaValidation(Yup.object().shape(validation));
    }
  };

  const isBidBondShow = () => {
    if (proposalTender?.metode_penyampaian === "1 Sampul") {
      return true;
    }

    if ((proposalTender?.metode_penyampaian === "2 Sampul" || proposalTender?.metode_penyampaian === "2 Tahap") && quotationStep === 2) {
      return true;
    }

    if (proposalTender?.metode_penyampaian === "2 Tahap" && quotationStep === 2) {
      return true;
    }


    return false;
  };

  const isPersyaratanKomersiShow = () => {
    return proposalTender?.metode_penyampaian === "1 Sampul" ||
      (proposalTender?.metode_penyampaian === "2 Sampul" && quotationStep === 2);
  };

  const handleOnChangeItems = async (items: any) => {
    setQuotationItems(items);
  };

  const validateItems = () => {
    let isItemsValid = true;
    quotationItems.forEach((item:any) => {
      if(item?.serviceLines && item?.serviceLines.length > 0) {
        // region check jasa
        item?.serviceLines?.forEach((srvLine: any) => {
          const _priceJasa = parseInt(srvLine?.price);
          if (_priceJasa === 0) {
            isItemsValid = false;
          }
        });
        // endregion
      }
      else {
        // region check barang dan/atau jasa level item
        if(item?.price === "0.000" || item?.delivery_time === "" || item?.delivery_time === "0" || item?.delivery_time === 0) {
          isItemsValid = false;
        }
        // endregion
      }
    })
    return isItemsValid;
  }

  const validateBidBond = () => {
    if (!currentQuotation?.id) return true

    let totalAmountForBidBond = 0
    quotationItems.forEach((item:any, indexItem:any) => {
      if(item?.serviceLines?.length === 0) {
        const price = parseFloat(item?.price || item?.valuation_price);
        const quantity = parseFloat(item.quantity);
        totalAmountForBidBond += (price * quantity);
      } else {
        totalAmountForBidBond += item.serviceLines.reduce((sum, line) => {
          const price = parseFloat(line.price);
          const quantity = parseFloat(line.quantity);
          return sum + (price * quantity);
        }, 0);
      }
    })

    //requirement 22 Okt 2024, validation bid_bond sesuai dengan parameter
    // const isBidbondRequired = (totalAmountForBidBond > parseFloat(proposalTender?.bidbond_threshold?.bidbond_threshold)) ? true : false
    let isBidbondRequired = false;
    if(proposalTender?.bid_bond){
      isBidbondRequired = (totalAmountForBidBond > parseFloat(proposalTender?.bidbond_threshold?.bidbond_threshold));
    }
    setIsRequiredBidBond(isBidbondRequired)
    
    const validateWhenRequired = isBidbondRequired && bidBondValue?.total && bidBondValue?.validity && bidBondValue?.attachment
    console.log("validateWhenRequired",validateWhenRequired)
    if (!isBidbondRequired || validateWhenRequired) return true

    return false
  }

  const validationSection = () => {
    let isHeaderValid = true;
    let isBidBondValid = true;
    let isTKDNValid = true;
    let isItemsValid = true;
    let isPersyaratanValid = true;


    if(
      (proposalTender?.metode_penyampaian === "2 Sampul" && quotationStep === 1) ||
      (proposalTender?.metode_penyampaian === "2 Tahap" && quotationStep === 1)
    ) {
      //region 2 Sampul & 2 Tahap step 1


      //endregion
    } else if(
      (proposalTender?.metode_penyampaian === "2 Sampul" && quotationStep === 2) ||
      (proposalTender?.metode_penyampaian === "2 Tahap" && quotationStep === 2)
    ) {
      // region untuk 2 Sampul / 2 Tahap step 2
      isItemsValid = validateItems();
      // endregion
    } else if(proposalTender?.metode_penyampaian === "1 Sampul") {
      isItemsValid = validateItems();
      // endregion
    }

    isBidBondValid = validateBidBond()

    setSectionValidation({
      header: isHeaderValid,
      bid_bond: isBidBondValid,
      tkdn: isTKDNValid,
      items: isItemsValid,
      persyaratan: isPersyaratanValid
    });
  }

  useEffect(() => {
    getQuotation();
    validationSection()
  }, []);

  useEffect(() => {
    validationSection()
  }, [bidBondValue]);

  useEffect(() => {
    renderValidationSchema();
    validationSection();
  }, [currentQuotation]);

  useEffect(() => {
    setInitialValues({
      ...initialValues,
      bid_bond: isRequiredBidBond
    })
  }, [isRequiredBidBond]);

  useEffect(() => {
    validationSection()
  }, [quotationItems]);

  useEffect(() => {
    setQuotationStatus(getQuotationStatus(proposalTender,intl,quotationStep))
  },[proposalTender])

  const checkAllowedSubmit = (values) => {
    // check required attachment
    let countValidate = 0
    proposalTender?.syarats.forEach((item:any) => {
      if (proposalTender?.metode_penyampaian === "1 Sampul") {
        if (item.required_attachment === true && item?.media_url === "") {
          countValidate++
          toastError(intl.formatMessage({id:'Lampiran wajib diisi!'}))
        }
      } else {
        if (quotationStep === 1) {
          if (item.required_attachment === true && item?.media_url === "" && item?.type === 'administration') {
            countValidate++
            toastError(intl.formatMessage({id:'Lampiran wajib diisi!'}))
          }
        } else if (quotationStep === 2) {
          if (item.required_attachment === true && item?.media_url === "" && item?.type === 'commercial') {
            countValidate++
            toastError(intl.formatMessage({id:'Lampiran wajib diisi!'}))
          }
        }
      }
    })

    // check if itemize, minimum 1 item should be deviate / comply
    if(proposalTender?.itemize === true) {
      let countExceptNoQuote  = 0;
      countExceptNoQuote      = quotationItems.filter((item:any) => item.quote !== "NO_QUOTE").length;
      if(countExceptNoQuote === 0) {
        countValidate++
        toastError(intl.formatMessage({id:'Itemize, minimal 1 item harus comply / deviate!'}))
      }
    }

    // check item
    let totalAmountForBidBond = 0;
    quotationItems.forEach((item:any, indexItem:any) => {
      const proposalTenderOri = proposalTender?.items[indexItem]

      if ((item?.quote === "DEVIATE") && (item?.short_text === proposalTenderOri?.short_text)) {
        countValidate++
        toastError(intl.formatMessage({id:'Short Text per item tidak boleh sama dengan sebelumnya!'}))
      }

      if (quotationItems[indexItem]?.serviceLines && quotationItems[indexItem]?.serviceLines.length > 0) {
        item?.serviceLines.forEach((srvLine:any, indexSrvLine:any) => {
          const proposalTenderOriSrvLine = proposalTender?.items[indexItem]?.service_line[indexSrvLine]
          if ((item?.quote === "DEVIATE") && (srvLine?.short_text === proposalTenderOriSrvLine?.short_text)) {
            countValidate++
            toastError(intl.formatMessage({id:'Short Text per service line tidak boleh sama dengan sebelumnya!'}))
          }

          if ( (item?.quote === "DEVIATE" || item?.quote === "COMPLY") &&
            ((proposalTender?.metode_penyampaian === "1 Sampul") || ((proposalTender?.metode_penyampaian !== "1 Sampul") && (quotationStep === 2)))
          ) {
            if (parseInt(srvLine?.price) === 0) {
              countValidate++
              toastError(intl.formatMessage({id:'Harga satuan harus lebih dari 0!'}))
            }
          }
        })
      } else {
        if ((item?.quote === "DEVIATE" || item?.quote === "COMPLY") &&
          ((proposalTender?.metode_penyampaian === "1 Sampul") || ((proposalTender?.metode_penyampaian !== "1 Sampul") && (quotationStep === 2)))
        ) {
          if (parseInt(item?.price) === 0) {
            countValidate++
            toastError(intl.formatMessage({id:'Harga satuan harus lebih dari 0!'}))
          }
        }
      }

      const handleSpecificationOri = proposalTenderOri?.specification === "/n"
        ? proposalTenderOri?.specification.trim()
        : proposalTenderOri?.specification

      const handleSpecificationQuote = item?.specification === "/n"
        ? item?.specification.trim()
        : item?.specification

      if ((item?.quote === "DEVIATE") && (handleSpecificationOri === handleSpecificationQuote)) {
        countValidate++
        toastError(intl.formatMessage({id:'Specification tidak boleh sama dengan sebelumnya!'}))
      }

      if ((item?.quote === "DEVIATE" || item?.quote === "COMPLY") && (item.delivery_time === 0 || item.delivery_time === "0")) {
        countValidate++
        toastError(intl.formatMessage({id:'Delivery Time wajib lebih dari 0!'}))
      }

      if(item?.serviceLines?.length === 0) {
        const price = parseFloat(item?.price || item?.valuation_price);
        const quantity = parseFloat(item.quantity);
        totalAmountForBidBond += (price * quantity);
      } else {
        totalAmountForBidBond += item.serviceLines.reduce((sum, line) => {
          const price = parseFloat(line.price);
          const quantity = parseFloat(line.quantity);
          return sum + (price * quantity);
        }, 0);
      }
    })
    
    if (isRequiredBidBond) {
      const percentBidbond = proposalTender?.bidbond_threshold?.percentage_minimum;
      const minimumBidbond = totalAmountForBidBond * percentBidbond / 100;
      const currentBidbond = isNaN(parseFloat(values?.bid_bond_value)) ? 0 : parseFloat(values?.bid_bond_value)

      if(percentBidbond > 0 && (currentBidbond < minimumBidbond)) {
        const threshold = proposalTender?.bidbond_threshold?.bidbond_threshold;
        
        if(totalAmountForBidBond >= threshold){
          toast.error(
            "Bid Bond minimum is not reached. Minimum Bid Bond: " +
            `${formatCurrency(minimumBidbond)} ${proposalTender?.items[0]?.currency}`
          )
          document.getElementById('bidbond_section')?.scrollIntoView({ behavior: 'smooth' });
          setTimeout(() => {
            window.scrollBy(0, -80);
          }, 500);
        }
  
        return ;
      }
    }

    if (isRequiredBidBond && !bidBondValue?.validity) {
      toast.error("Validity Bid Bond wajib diisi!")
      document.getElementById('bidbond_section')?.scrollIntoView({ behavior: 'smooth' });
      setTimeout(() => {
        window.scrollBy(0, -80);
      }, 500);
      return false
    }

    if (isRequiredBidBond && !bidBondValue?.attachment) {
      toast.error("File Bid Bond wajib diisi!")
      document.getElementById('bidbond_section')?.scrollIntoView({ behavior: 'smooth' });
      setTimeout(() => {
        window.scrollBy(0, -80);
      }, 500);
      return false
    }

    // check mandatory tkdn
    if(proposalTender?.tkdn === true) {
      quotationItems.forEach((item:any) => {
        if ( (item?.quote === "DEVIATE" || item?.quote === "COMPLY") && (item?.tkdn === 0 || item?.tkdn_certification_number === "" || item?.tkdn_certification_url === '')) {
          countValidate++
          toastError(intl.formatMessage({id:'TKDN wajib diisi lengkap!'}))
        }
      })
    }

    //region check if masa berlaku penawaran not more than proposal tender masa berlaku tender
    if(values.validity > proposalTender?.validity) {
      countValidate++
      toastError(intl.formatMessage({id:'Masa berlaku penawaran tidak boleh kurang dari konfigurasi masa berlaku penawaran di tender'}))
    }
    //end region

    return (countValidate === 0 ? true : false)
  }

  useEffect(() => {
    const allowedModal = [
      "Penawaran",
      "Penawaran Teknikal",
      "Penawaran Komersil"
    ]
    if(quotationStatus?.status && quotationStatus?.message && allowedModal.includes(proposalTender?.status)) {
      Swal.fire({
        title: intl.formatMessage({id:"Congratulation"}),
        text:  intl.formatMessage({id:quotationStatus?.message})  || "",
        icon: "success"
      });
    }
  }, [quotationStatus?.status, quotationStatus?.message])

  return (
    <>
      {isLoading && (
        <Placeholder.Graph active height={1024} />
      )}
      {!isLoading && (
        <div className={`alert alert-${quotationStatus?.status ? "success" : "danger"} text-dark`} ref={errorRef}>
          {quotationStatus?.status && (
            <i className={"fa fa-check-circle me-2 text-success"}></i>
          )}
          {!quotationStatus?.status && (
            <i className={"fa fa-exclamation-triangle me-2"}></i>
          )}
          {quotationStatus?.message}
        </div>
      )}

      {errorMessages && (
        <div className={"alert alert-danger"} ref={errorRef}>
          <i className={"fa fa-exclamation-triangle me-2"}></i>
          {errorMessages}
        </div>
      )}

      {!isLoading && (
        <Formik
          innerRef={formik}
          initialValues={initialValues}
          onSubmit={handleOnSubmitQuotation}
          validationSchema={formSchemaValidation}
          enableReinitialize
          validateOnChange={true}
        >
          {({ errors, touched, values, setFieldValue, resetForm, submitForm }: any) => (
            <Form id="form-quotation">
              <>
                <div className={"text-end mb-2"}>
                  <a href={"#"} onClick={(e: any) => {
                    if (isExpandAll) hideAll();
                    else expandAll();
                  }}>
                    {isExpandAll && (<i className={"fa fa-chevron-up me-2"}></i>)}
                    {!isExpandAll && (<i className={"fa fa-chevron-down me-2"}></i>)}
                    {isExpandAll ? intl.formatMessage({id:'Sembunyikan Semua'}): intl.formatMessage({id:'Tampilkan Semua'})}
                  </a>
                </div>
                <Accordion
                  activeKey={activeCollapse}
                  defaultActiveKey={isExpandAll ? [] : ["0", "1", "2", "3", "4", "5"]}
                  alwaysOpen={true}
                  onSelect={(e: any) => {
                    setActiveCollapse(e);
                  }}
                >
                  <Accordion.Item
                    eventKey="0"
                    className={"mb-4"}
                    style={{
                      border: sectionValidation?.header ? "" : "5px solid #f1416c"
                    }}
                  >
                    <Accordion.Header
                      bsPrefix={sectionValidation?.header ? "alert-success-quotation-vendor" : "alert-danger-quotation-vendor"}
                    >
                      <span className={sectionValidation?.header ? "text-dark" : "text-white"}>
                        {intl.formatMessage({ id: "Penawaran Header" })}*
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <HeaderPenawaran
                        proposalTender={proposalTender}
                        setFieldValue={setFieldValue}
                        touched={touched}
                        values={values}
                        errors={errors}
                        isDisabledForm={!checkIsFormEnabled()}
                        quotationStep={quotationStep}
                      />
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item
                    eventKey="3"
                    className={"mb-4"}
                    ref={quotationItemRef}
                    style={{
                      border: sectionValidation?.items ? "" : "5px solid #f1416c"
                    }}
                  >
                    <Accordion.Header
                      bsPrefix={sectionValidation?.items ? "alert-success-quotation-vendor" : "alert-danger-quotation-vendor"}
                    >
                      <span className={sectionValidation?.items ? "text-dark" : "text-white"}>
                        {intl.formatMessage({id:'Item Penawaran'})}
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <QuotationItemFormTable
                        quotation={currentQuotation}
                        proposalTender={proposalTender}
                        setFieldValue={setFieldValue}
                        touched={touched}
                        errors={errors}
                        isDisabledForm={!checkIsQuotationItemEnabled()}
                        onChangeItems={handleOnChangeItems}
                        quotationStep={quotationStep}
                      />
                    </Accordion.Body>
                  </Accordion.Item>

                  {isBidBondShow() && (
                    <Accordion.Item
                      eventKey="3"
                      className={"mb-4"}
                      style={{
                        border: sectionValidation?.bid_bond ? "" : "5px solid #f1416c"
                      }}
                      id="bidbond_section"
                    >
                      <Accordion.Header
                        bsPrefix={sectionValidation?.bid_bond ? "alert-success-quotation-vendor" : "alert-danger-quotation-vendor"}
                      >
                        <span className={sectionValidation?.bid_bond ? "text-dark" : "text-white"}>
                          {intl.formatMessage({ id: "Jaminan Penawaran / Bid Bond" })} {isRequiredBidBond ? "(*)" : ""}
                        </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        <JaminanPenawaran
                          proposalTender={proposalTender}
                          quotation={currentQuotation}
                          setFieldValue={setFieldValue}
                          values={values}
                          touched={touched}
                          errors={errors}
                          isDisabledForm={!isBidbondEnabled()}
                          isRequired={isRequiredBidBond}
                          setBidBondValue={setBidBondValue}
                          bidBondValue={bidBondValue}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                  )}

                  {/* {isPersyaratanKomersiShow() && ( */}
                  <Accordion.Item
                    eventKey="4"
                    className={"mb-4"}
                    style={{
                      border: sectionValidation?.persyaratan ? "" : "5px solid #f1416c"
                    }}
                  >
                    <Accordion.Header
                      bsPrefix={sectionValidation?.persyaratan ? "alert-success-quotation-vendor" : "alert-danger-quotation-vendor"}
                    >
                        <span className={sectionValidation?.persyaratan ? "text-dark" : "text-white"}>
                        {proposalTender?.metode_penyampaian === "1 Sampul"
                          ? intl.formatMessage({ id: "Persyaratan Dokumen Penawaran Administrasi, Teknis, Komersil" })
                          : quotationStep === 1
                            ? intl.formatMessage({ id: "Persyaratan Dokumen Penawaran Administrasi, Teknis" })
                            : intl.formatMessage({ id: "Persyaratan Dokumen Penawaran Komersil" })
                        }
                        </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <PersyaratanDokumenPenawaran
                        proposalTender={proposalTender}
                        setFieldValue={setFieldValue}
                        touched={touched}
                        errors={errors}
                        isDisabledForm={false}
                        quotationStep={quotationStep}
                        onChange={(syarats:any)=> {
                          setFieldValue("syarats",syarats)
                        }}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                  {/* )} */}

                </Accordion>
                <BottomToolbarWrapper
                  onGoBack={() => goBack()} children={
                  <>
                    <button
                      className={"btn btn-primary"}
                      type={"button"}
                      onClick={() => {
                        (checkAllowedSubmit(values) === true) && submitForm()
                      }}
                      disabled={isLoading || isLoadingDetail || !checkIsButtonSubmitEnabled()}
                    >
                      {(isLoading || isLoadingDetail) && (<i className={"fa fa-spinner fa-spin me-2"}></i>)}
                      {(!isLoading && !isLoadingDetail) && (<i className={"fa fa-save me-2"}></i>)}

                      {!currentQuotation?.id && (
                        intl.formatMessage({ id: "Load Quotation" })
                      )}

                      {currentQuotation?.id && (
                        intl.formatMessage({ id: "Submit" })
                      )}
                    </button>

                  </>
                } />
              </>
            </Form>
          )}
        </Formik>
      )}

    </>
  );
};

export default QuotationVendorTab;