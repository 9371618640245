import { useState } from 'react'
import ImportExcel from './components/ImportExcel'
import { Steps, ButtonGroup, Button as RsButton } from 'rsuite';
import ShowData from './components/ShowData';
const Snop = () => {

    const [step, setStep] = useState(0);

    const onChangeStep = nextStep => {
        setStep(nextStep < 0 ? 0 : nextStep > 1 ? 1 : nextStep);
    };

    const onNext = () => onChangeStep(step + 1);
    const onPrevious = () => onChangeStep(step - 1);
    return (
        <>
            <div className={'bg-white p-10 mt-5'}>
                <h4 className='mb-10'>Run Simulation</h4>
                <div>
                    <Steps current={step} small>
                        <Steps.Item title="Import Data" />
                        <Steps.Item title="Pilih Tanggal" />
                    </Steps>
                    <div className='mt-5'>
                        {step == 0 && <ImportExcel handleSuccess={() => onNext()} />}
                        {step == 1 && <ShowData />}
                    </div>
                    <hr />
                    <ButtonGroup>
                        <RsButton onClick={onPrevious} disabled={step === 0}>
                            Previous
                        </RsButton>
                    </ButtonGroup>
                </div>
            </div>
        </>
    );
}

export default Snop;