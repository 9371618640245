import React, {FC, useEffect, useState} from 'react'
import InputFormX from "./InputFormX";
import {Button, Form, Spinner} from 'react-bootstrap';
import Select2FormX from "./Select2FormX";
import Select2MultipleFormX from "./Select2MultipleFormX";
import ServiceRouteMenu from './ServiceRouteMenu';
import InputTagCommaFormX from "./InputTagCommaFormX";
import ReactQuill from "react-quill";
import Ckeditor from "./Ckeditor";

type Props = {
    title: string,
    fields: any,
    colMd?: number,
    colSm?: number,
    colXl?: number,
    onChange?: any,
    onSubmit?: any,
    formError?: any,
    showSubmitButton?: boolean,
    disableSubmitButton?:boolean,
    isLoadingSubmitButton?:boolean
}
interface IFormData {
    Header: string,
    accessor: string,
    value: any,
    focus?: boolean,
    placeholder?: string,

}
const FormX: FC<Props> = (props) => {
    const {
        title,
        fields,
        onChange:onChangeProps,
        colSm,
        colMd,
        colXl,
        formError,
        showSubmitButton,
        disableSubmitButton,
        onSubmit,
        isLoadingSubmitButton
    } = props;
    const [formData,setFormData] = useState<IFormData[]>([])
    const [formPayload,setFormPayload] = useState({})
    const formSize = "sm";
    const colSizeSm = 12;
    const colSizeMd = 6;
    const colSizeLg = 6;

    const [code, setCode] = useState('');

    useEffect(()=>{
        // @ts-ignore
        let datas = [];
        fields.map((v:any)=>{
            let record = v;
            let fieldRow = formData.filter((x:any)=>x.accessor === v.accessor);
            if(fieldRow.length > 0) {
                record.value = fieldRow[0].value;
            }
            // @ts-ignore
            datas.push(record)
        })
        // @ts-ignore
        onChangeProps(datas)
    },[formData])

    const onChange = (field:string,value:any) => {
        let _formData = formData.filter((v)=>v.accessor !== field);
        let _currentField = fields.filter((v:any)=>v.accessor === field);
        let row = {
            Header: _currentField[0].Header,
            accessor: field,
            value: value
        };
        _formData.push(row);
        setFormData(_formData)

        let _formPayload = formPayload;
        //@ts-ignore
        _formPayload[field] = value;
        setFormPayload(_formPayload)
    }

    const handleSubmitForm = (e:any) => {
        e.preventDefault()
        onSubmit(formPayload)
    }

    const onChangeQuillEditor = (content, delta, source, editor) => {
        setCode(content);
    };

    return <Form className={"row"} onSubmit={handleSubmitForm}>
        {(formError && formError.response && formError.response.data) && (
            <div className={"col-12 p-3"}>
                <div className={"alert alert-danger"}>
                    <i className={"fa fa-exclamation-triangle"}></i> {formError.response.status}: {formError.response.data.message}
                </div>
            </div>
        )}

        {fields.map((v:any,index:number) => {
            return <Form.Group key={`${v.ancessor}-${index}`} className={`mb-3 col-sm-${colSm??colSizeSm} col-md-${colMd??colSizeMd} col-lg-${colXl??colSizeLg}`} controlId="formBasicEmail">
                    <Form.Label className={(formError && formError.response && formError.response.data && formError.response.data.errors && formError.response.data.errors[v.accessor]) ? 'text-danger' : ''}>
                        <strong>{v.Header}</strong>
                        {(formError && formError.response && formError.response.data && formError.response.data.errors && formError.response.data.errors[v.accessor]) && (<i className={"fa fa-exclamation-triangle ms-2 text-danger"}></i>)}
                    </Form.Label>
                    {(!v.type || v.type === "text" || v.type === "number" || v.type === "email" || v.type === "text_array") && (
                        <InputFormX
                            focus={v.focus??false}
                            type={v.type ? v.type : 'text'}
                            size={formSize}
                            placeholder={`${v.placeholder??''}`}
                            model={v.accessor}
                            onChange={onChange}
                            value={v.value}
                            readonly={v.readonly || false}
                        />
                    //     End InputFormX
                    )}
                {(v.type === 'status') && (
                    <div className="form-check form-switch form-check-custom form-check-solid">
                        <input className="form-check-input" type="checkbox" value="ACTIVE" checked={v.value?true:false} onChange={(e)=>onChange(v.accessor,e.target.checked)} id="flexSwitchDefault"/>
                        <label className="form-check-label" htmlFor="flexSwitchDefault">
                            {v.value ? 'Active' : 'Inactive'}
                        </label>
                    </div>
                    // End toggle status
                )}
                {(v.type === 'boolean') && (
                    <div className="form-check form-switch form-check-custom form-check-solid">
                        <input className="form-check-input" type="checkbox" value="1" checked={v.value===true || v.value === 1} onChange={(e)=>onChange(v.accessor,e.target.checked)} id="flexSwitchDefault"/>
                        <label className="form-check-label" htmlFor="flexSwitchDefault">
                            {v.value ? 'True' : 'False'}
                        </label>
                    </div>
                    // End toggle status
                )}
                {/* {JSON.stringify(v)} */}
                {(v.type === 'select2') && (
                    <div className="form-check form-switch form-check-custom form-check-solid">
                        <Select2FormX
                            onChange={(e:any)=>onChange(v.accessor,e)}
                            model={v.accessor}
                            name={v.Header}
                            options={v.options}
                            value={v.value}
                            defaultValue={v.value}
                        />
                    </div>
                    // End toggle status
                )}
                {(v.type === 'select2_multiple') && (
                    <div className="form-check form-switch form-check-custom form-check-solid">
                        <Select2MultipleFormX
                            onChange={(e:any)=>onChange(v.accessor,e)}
                            model={v.accessor}
                            name={v.Header}
                            options={v.options}
                            defaultValue={v.value}
                        />
                    </div>
                    // End toggle status
                )}
                {(v.type === 'array_comma') && (
                    <div className="form-check form-switch form-check-custom form-check-solid">
                        <InputTagCommaFormX
                            focus={v.focus??false}
                            type={v.type ? v.type : 'text'}
                            size={formSize}
                            placeholder={`${v.placeholder??''}`}
                            model={v.accessor}
                            onChange={onChange}
                            value={v.value}
                            readonly={v.readonly || false}
                        />
                    </div>
                    // End toggle status
                )}
                {(v.type === 'editor') && (
                  <div className="form-check form-switch form-check-custom form-check-solid">
                      <ReactQuill
                        theme='snow'
                        modules={{
                            toolbar: [
                                [{header: [1, 2, false]}],
                                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                [{list: 'ordered'}, {list: 'bullet'}, {indent: '-1'}, {indent: '+1'}],
                                ['link', 'image'],
                                ['clean'],
                            ],
                        }}
                        style={{
                            width:'100%',
                        }}
                        onChange={(e:any)=>onChange(v.accessor,e)}
                        defaultValue={v.value}
                      />
                  </div>
                  // End toggle status
                )}
                {(v.type === "editorX") && (
                  <div className="form-check form-switch form-check-custom form-check-solid">
                    <ReactQuill
                        theme='snow'
                        modules={{
                            toolbar: [
                                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                ["bold", "italic", "underline", "strike", "blockquote"],
                                [{ size: [] }],
                                [{ font: [] }],
                                [{ align: ["right", "center", "justify"] }],
                                [{ list: "ordered" }, { list: "bullet" }],
                                ["link", "image"],
                                [{ color: ["red", "#785412"] }],
                                [{ background: ["red", "#785412"] }]
                            ],
                        }}
                        formats={[
                            "header","bold","italic","underline","strike",
                            "blockquote","list","bullet","link","color",
                            "image","background","align","size","font"
                        ]}
                        style={{
                            width:'100%',
                        }}
                        onChange={(e:any)=>onChange(v.accessor,e)}
                        defaultValue ={v.value}
                    />
                </div>
                // End toggle status
                )}
                {(v.type === "service_route_menu") && (
                    <>
                        <ServiceRouteMenu
                            focus={v.focus??false}
                            type={v.type ? v.type : 'text'}
                            size={formSize}
                            placeholder={`${v.placeholder??''}`}
                            model={v.accessor}
                            onChange={onChange}
                            value={v.row}
                            readonly={v.readonly || false}
                        />
                    </>
                )}

                {/* {JSON.stringify(v)} */}
                    {v.help && (
                        <Form.Text className="text-muted">
                            {v.help}
                        </Form.Text>
                    )}
                    
                {(formError && formError.response && formError.response.data && formError.response.data.errors && formError.response.data.errors[v.accessor]) && (
                    formError.response.data.errors[v.accessor].map((val:any,indexVal:number)=>{
                        return (
                            <i className={"text-danger"} key={`err-${v.accessor}-${indexVal}`}><small>*{val}</small></i>
                        )
                    })
                )}
                </Form.Group>
        })}

        {showSubmitButton && (
            <div className={"d-flex flex-row-reverse my-5"}>
                <Button variant={"primary"} size={"sm"} type={"submit"} disabled={disableSubmitButton||isLoadingSubmitButton}>

                    {isLoadingSubmitButton && (
                        <Spinner animation={"border"} as="span"
                                 className={"me-2"}
                                 size="sm"
                                 role="status"
                                 aria-hidden="true"  />
                    )}
                    Submit
                </Button>
            </div>
        )}
    </Form>
}

export default FormX;