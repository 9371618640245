import { useCallback, useEffect, useState } from "react";
import { useAxiosGet, useAxiosPost } from "../../../../../hooks/useAxios";
import { formatNumber, formatNumberToDecimal, formatNumberToDecimalAuction, getEnv, multiplyNumber, parseNumberToCurrency, parseNumberToFloat } from "../../../../../helpers/procurex";
import { Placeholder } from "rsuite";
import CurrencyInput from "react-currency-input-field";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";

const RowTabulasi = ({ auction, item, isValidPrice, index, tabulasi, isLoading, isDisabled }: any) => {

  const [fieldIndex, setFieldIndex] = useState(0)
  const [renderIndex, setRenderIndex] = useState(0)
  const [unitPrice, setUnitPrice] = useState(item?.current_bid?.unit_price || 0);
  tabulasi[index].current_bid.unit_price_change = unitPrice;

  const onIncrementDecrement = () => {
    var valueIncrement = parseFloat(formatNumberToDecimalAuction(auction?.increment, 2));
    var changeValue = auction?.tipe_auction?.id == 366 ? unitPrice - valueIncrement : unitPrice + valueIncrement;
    if (changeValue > 0) {
      setUnitPrice(changeValue);
    }
  }

  useEffect(() => {
    setRenderIndex(renderIndex + 1);
    tabulasi[index].current_bid.unit_price_change = unitPrice;
  }, [unitPrice]);
  useEffect(() => {
    if (!isValidPrice) {
      setUnitPrice(item?.current_bid?.unit_price || 0);
    }
  }, [isValidPrice]);
  return (
    <tr key={"row-tabulasi-itemize-" + index}>
      <td className={"border text-center px-2"}>{index + 1}</td>
      <td className={"border text-start px-2"}>{item?.nama}</td>
      <td className={"border text-end px-2"}>{item?.quantity}</td>
      <td className={"border text-center px-2"}>{item?.uom}</td>
      <td className={"border text-end px-2"}>
        <input
          type={"text"}
          className={"form-control form-control-sm text-end"}
          value={parseNumberToCurrency(item?.last_price, item?.currency)}
          disabled={true} />
      </td>
      <td className={"border text-end px-2"}>
        <div className={"input-group input-group-sm text-nowrap"} key={fieldIndex}>
          <div className={"input-group-text"}>
            {isLoading && <Spinner animation={"border"} size={"sm"}></Spinner>}
            {!isLoading && (
              <>
                {isValidPrice && <i className={"fa fa-check text-success"}></i>}
                {!isValidPrice && <i className={"fa fa-exclamation-triangle text-danger"}></i>}
              </>
            )}

          </div>
          <CurrencyInput
            className={"form-control form-control-sm text-end " + (isValidPrice ? "border-success" : "border-danger")}
            groupSeparator="."
            decimalSeparator=","
            decimalsLimit={2}
            value={unitPrice}
            onValueChange={(value: any) => {
              setUnitPrice(value);
            }}
            style={{
            }}
            disabled={isLoading || isDisabled}
          />


        </div>
      </td>
      <td className={"border text-center px-2"}>
        <button className={"btn btn-sm btn-primary"} onClick={onIncrementDecrement} disabled={isLoading || isDisabled}>
          {parseNumberToCurrency(auction?.increment, item?.currency)}
        </button>
      </td>
      <td className={"border text-end px-2"}>{parseNumberToCurrency(item?.current_bid?.total_price, item?.currency)}</td>
      <td className={"border text-center px-2"}>{auction?.is_package == false && item?.current_bid?.ranking} {(auction?.is_package == false && item?.current_bid?.ranking === 1) && <i className={"fa fa-trophy fs-3"} style={{ color: "gold" }}></i>}</td>
    </tr>
  )
}

const RowTabulasiMvbVariable = ({ auction, bid_header, indexBidHeader, item, index, tabulasi, isLoading, isDisabled }: any) => {
  const [unitPrice, setUnitPrice] = useState(bid_header?.current_bid?.unit_price || 0);
  tabulasi[index].bid_headers[indexBidHeader].current_bid.unit_price_change = unitPrice;
  const onIncrementDecrement = () => {
    var valueIncrement = parseFloat(formatNumberToDecimalAuction(auction?.increment, 2));
    var unitPriceChangeValue = parseFloat(unitPrice);
    var changeValue = auction?.tipe_auction?.id == 366 ? unitPriceChangeValue - valueIncrement : unitPriceChangeValue + valueIncrement;
    if (changeValue > 0) {
      setUnitPrice(changeValue);
    }
  }
  useEffect(() => {
    tabulasi[index].bid_headers[indexBidHeader].current_bid.unit_price_change = unitPrice;
  }, [unitPrice]);
  return (
    <tr key={"row-tabulasi-itemize-" + index + indexBidHeader}>
      {indexBidHeader == 0 &&
        <>
          <td className={"border text-center align-middle px-2"} rowSpan={item?.bid_headers?.length}>{index + 1}</td>
          <td className={"border text-center align-middle px-2"} rowSpan={item?.bid_headers?.length}>{item?.nama}</td>
          <td className={"border text-center align-middle px-2"} rowSpan={item?.bid_headers?.length}>{item?.quantity}</td>
        </>
      }
      <td className={"border text-center px-2"}>{bid_header?.param_setting_mvb_variable?.detail_template?.variable_name}</td>
      <td className={"border text-end px-2"}>
        <CurrencyInput
          className={"form-control form-control-sm text-end"}
          groupSeparator="."
          decimalSeparator=","
          decimalsLimit={2}
          value={unitPrice}
          onValueChange={(value: any) => {
            setUnitPrice(value);
          }}
          style={{
          }}
          disabled={isLoading || isDisabled || bid_header?.disabled}
        />
      </td>
      <td className={"border text-center px-2"}>
        <button className={"btn btn-sm btn-primary"} onClick={onIncrementDecrement} disabled={isLoading || isDisabled || bid_header?.disabled}>
          {auction?.increment}
        </button>
      </td>
      {indexBidHeader == 0 &&
        <>
          <td className={"border text-center align-middle px-2"} rowSpan={item?.bid_headers?.length}>{item?.ranking} {(item?.ranking === 1) && <i className={"fa fa-trophy fs-3"} style={{ color: "gold" }}></i>}</td>
        </>
      }
    </tr>
  )
}


const RowTabulasiMvb = ({ auction, item, index, tabulasi, isLoading, isDisabled }: any) => {
  return item?.bid_headers.map((bid_header: any, indexBidHeader: number) => {
    return <RowTabulasiMvbVariable
      key={'row-tabulasi-mvb-variable' + index + indexBidHeader}
      item={item}
      index={index}
      indexBidHeader={indexBidHeader}
      bid_header={bid_header}
      auction={auction}
      tabulasi={tabulasi}
      isLoading={isLoading}
      isDisabled={isDisabled}
    />
  })
}

const Tabulasi = ({ auction, tabulasi }: any) => {
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosPost(
    getEnv("auction") + `/as-vendor/bid`
  );
  const [isValidPrice, setIsValidPrice] = useState(true)


  const handleOnSave = async () => {
    const items: any[] = [];
    tabulasi.tabulasi.map((itemAuction: any) => {
      if ((auction?.metode_evaluasi.id == 368) || (auction?.metode_evaluasi.id == 367 && itemAuction?.current_bid?.unit_price_change != itemAuction?.current_bid?.unit_price)) {
        items.push({
          item_id: itemAuction?.item_id,
          unit_price: formatNumberToDecimalAuction(itemAuction?.current_bid?.unit_price_change, 2)
        });
      }
    });
    if (items.length == 0) {
      toast.error('Harap Submit Dengan Nilai Yang Berbeda');
      return;
    }
    const payload = {
      auction_id: auction?.uuid,
      items: items
    }
    doSubmit(payload, undefined,).then((res: any) => {
      setIsValidPrice(true);
      toast.success('Success bid')
    }).catch((error) => {
      setIsValidPrice(false);
      setTimeout(() => {
        setIsValidPrice(true);
      });
    });
  }


  return (
    <>
      <br />
      <h3>Bidding Tab</h3>
      <div className={"table-responsive"}>
        <table className={"table table-striped table-header-gray"}>
          <thead>
            <tr>
              <th className={"border text-center px-2"} style={{ minWidth: '50px', width: '50px' }}>No</th>
              <th className={"border text-start px-2"} style={{ minWidth: '300px', width: '300px' }}>Item Name</th>
              <th className={"border text-end px-2"} style={{ minWidth: '150px', width: '150px' }}>Quantity</th>
              <th className={"border text-center px-2"} style={{ minWidth: '100px', width: '100px' }}>UOM</th>
              <th className={"border text-end px-2"} style={{ minWidth: '250px', width: '250px' }}>Last Price</th>
              <th className={"border text-end px-2 text-nowrap"} style={{ minWidth: '250px', width: '250px' }}>Unit Price</th>
              <th className={"border text-center px-2"} style={{ minWidth: '200px', width: '200px' }}>{tabulasi?.increment_decrement_label}</th>
              <th className={"border text-end px-2"} style={{ minWidth: '200px', width: '200px' }}>Total</th>
              <th className={"border text-center px-2"} style={{ minWidth: '200px', width: '200px' }}>Ranking</th>
            </tr>
          </thead>
          <tbody>
            {tabulasi?.tabulasi?.length === 0 && (
              <tr>
                <td className={"border text-center px-2"} colSpan={10}>Tidak ada data</td>
              </tr>
            )}
            {tabulasi?.tabulasi?.map((item: any, index: number) => {
              return (
                <RowTabulasi
                  key={"tabulasi-row" + index}
                  item={item}
                  index={index}
                  auction={auction}
                  tabulasi={tabulasi?.tabulasi}
                  isLoading={isLoading}
                  isDisabled={tabulasi?.is_disabled}
                  isValidPrice={isValidPrice}
                />
              )
            })}
            {auction?.is_package == true && tabulasi?.tabulasi?.length > 0 && (
              <tr>
                <td className={"border text-end px-2"} colSpan={7}></td>
                <td className={"border text-end px-2"}>{parseNumberToCurrency(tabulasi?.ranking_package?.total_price, tabulasi?.tabulasi[0].currency)}</td>
                <td className={"border text-center px-2"}>{tabulasi?.ranking_package?.ranking} {(tabulasi?.ranking_package?.ranking === 1) && <i className={"fa fa-trophy fs-3"} style={{ color: "gold" }}></i>}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className={'row'}>
        <div className={'col-md-12 text-end'}>
          <button onClick={handleOnSave} className={'btn btn-primary btn-sm'} disabled={isLoading || tabulasi?.is_disabled}>
            {isLoading && (
              <Spinner animation={'border'} size={'sm'} className={'me-2'} />
            )}
            {!isLoading && <i className={'fa fa-save me-2'}></i>}
            Submit
          </button>
        </div>
      </div>
    </>
  )
}

const TabulasiMvb = ({ auction, tabulasi }: any) => {
  const [listInformation, setListInformation] = useState([])

  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosPost(
    getEnv("auction") + `/as-vendor/bid`
  );


  const handleOnSave = async () => {
    const bidHeaders: any[] = [];
    tabulasi.tabulasi.map((itemAuction: any) => {
      itemAuction?.bid_headers.map((bid_header: any) => {
        if (auction?.metode_evaluasi.id == 367 && bid_header?.current_bid?.unit_price_change != bid_header?.current_bid?.unit_price) {
          bidHeaders.push({
            bid_header_id: bid_header.bid_header_id,
            unit_price: formatNumberToDecimalAuction(bid_header?.current_bid?.unit_price_change, 2)
          });
        }
      })

    });
    if (bidHeaders.length == 0) {
      toast.error('Harap Submit Dengan Nilai Yang Berbeda');
      return;
    }
    const payload = {
      auction_id: auction?.uuid,
      bid_headers: bidHeaders
    }
    doSubmit(payload, undefined,).then((res: any) => {
      toast.success('Success bid')
    });
  }

  useEffect(() => {
    let informations = [];
    tabulasi?.tabulasi?.map((item) => {
      item?.bid_headers_information?.map((i) => informations.push(i));
    })
    setListInformation(informations);
  }, [tabulasi]);


  return (
    <>
      <br />
      <h3>Bidding Tab</h3>
      <div className={"table-responsive"}>
        <table className={"table table-striped table-header-gray"}>
          <thead>
            <tr>
              <th className={"border text-center px-2"} style={{ minWidth: '50px', width: '50px' }}>No</th>
              <th className={"border text-start px-2"} style={{ minWidth: '150px', width: '300px' }}>Item Name</th>
              <th className={"border text-center px-2"} style={{ minWidth: '50px', width: '150px' }}>Qty</th>
              <th className={"border text-center px-2"} style={{ minWidth: '150px', width: '250px' }}>Category</th>
              <th className={"border text-center px-2 text-nowrap"} style={{ minWidth: '150px', width: '250px' }}>Value</th>
              <th className={"border text-center px-2"} style={{ minWidth: '100px', width: '200px' }}>{tabulasi?.increment_decrement_label}</th>
              <th className={"border text-center px-2"} style={{ minWidth: '100px', width: '200px' }}>Ranking</th>
            </tr>
          </thead>
          <tbody>
            {tabulasi?.tabulasi?.length === 0 && (
              <tr>
                <td className={"border text-center px-2"} colSpan={11}>Tidak ada data</td>
              </tr>
            )}
            {tabulasi?.tabulasi?.map((item: any, index: number) => {
              return (
                <RowTabulasiMvb
                  key={'table-row-tabulasi-mvb-' + index}
                  item={item}
                  index={index}
                  auction={auction}
                  tabulasi={tabulasi?.tabulasi}
                  isDisabled={tabulasi?.is_disabled}
                  isLoading={isLoading}
                />
              )
            })}
          </tbody>
        </table>
      </div>
      <div className={'row'}>
        <div className={'col-md-12 text-end'}>
          <button onClick={handleOnSave} className={'btn btn-primary btn-sm'} disabled={isLoading || tabulasi?.is_disabled}>
            {isLoading && (
              <Spinner animation={'border'} size={'sm'} className={'me-2'} />
            )}
            {!isLoading && <i className={'fa fa-save me-2'}></i>}
            Submit
          </button>
        </div>
      </div>
      <br />
      <h3>Information</h3>
      <div className={"table-responsive"}>
        <table className={"table table-striped table-header-gray"}>
          <thead>
            <tr>
              <th className={"border text-center px-2"} style={{ minWidth: '50px', width: '50px' }}>No</th>
              <th className={"border text-start px-2"} style={{ minWidth: '300px', width: '300px' }}>Item Name</th>
              <th className={"border text-center px-2"} style={{ minWidth: '250px', width: '250px' }}>Category</th>
              <th className={"border text-center px-2 text-nowrap"} style={{ minWidth: '250px', width: '250px' }}>Value</th>
            </tr>
          </thead>
          <tbody>
            {listInformation.length === 0 && (
              <tr>
                <td className={"border text-center px-2"} colSpan={4}>Tidak ada data</td>
              </tr>
            )}
            {tabulasi?.tabulasi?.map((item: any, index: number) => {
              return item?.bid_headers_information.map((bid_header: any, indexBidHeader: number) => {
                return (
                  <tr key={"row-tabulasi-information-" + index + indexBidHeader}>
                    {indexBidHeader == 0 &&
                      <>
                        <td className={"border text-center align-middle px-2"} rowSpan={item?.bid_headers_information?.length}>{index + 1}</td>
                        <td className={"border text-center align-middle px-2"} rowSpan={item?.bid_headers_information?.length}>{item?.nama}</td>
                      </>
                    }
                    <td className={"border text-center px-2"}>{bid_header?.param_setting_mvb_variable?.detail_template?.variable_name}</td>
                    <td className={"border text-end px-2"}>
                      {parseNumberToFloat(bid_header?.current_bid?.unit_price, 3)}
                    </td>
                  </tr>
                )
              })
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}

const PeringkatTerbaikPackage = ({ auction, tabulasi }: any) => {
  return (
    <>
      <br />
      <h3>Peringkat Terbaik</h3>
      <div className={"table-responsive"}>
        <table className={"table table-header-gray"}>
          <thead>
            <tr>
              <th className={"border text-center px-2"}>Nama Vendor</th>
              <th className={"border text-center px-2"}>Nama Item</th>
            </tr>
          </thead>
          {tabulasi?.data_peringkat_terbaik?.map((vendor: any, index: number) => {
            return (
              <tbody key={"row-peringkat-terbaik-package-" + index}>
                {vendor?.items?.map((itemAuction: any, index2: number) => {
                  return (
                    <tr key={"row-item-peringkat-terbaik-package-" + index2}>
                      {
                        index2 == 0 &&
                        <td className={"border text-center text-nowrap align-middle px-2"} rowSpan={vendor?.items?.length}>{vendor.kode_vendor} - {vendor.nama_vendor}</td>
                      }
                      <td className={"border text-start px-2"}>{itemAuction}</td>
                    </tr>
                  );
                })}
              </tbody>
            );
          })}
        </table>
      </div>
    </>
  )
}

const PeringkatTerbaikItem = ({ auction, tabulasi }: any) => {
  return (
    <>
      <br />
      <h3>Peringkat Terbaik</h3>
      <div className={"table-responsive"}>
        <table className={"table table-header-gray"}>
          <thead>
            <tr>
              <th className={"border text-center px-2"}>Nama Item</th>
              <th className={"border text-center px-2"}>Nama Vendor</th>
            </tr>
          </thead>
          <tbody>
            {tabulasi?.data_peringkat_terbaik?.map((item: any, index: number) => {
              return (
                <tr key={"row-peringkat-terbaik-" + index}>
                  <td className={"border text-start px-2"}>{item.nama_item}</td>
                  <td className={"border text-start text-nowrap align-middle px-2"}>{item.kode_vendor} - {item.nama_vendor}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}

const HargaTerbaik = ({ auction, tabulasi }: any) => {
  return (
    <>
      <br />
      <h3>Harga Terbaik</h3>
      <div className={"table-responsive"}>
        <table className={"table table-header-gray"}>
          <thead>
            <tr>
              <th className={"border text-center px-2"}>Nama Item</th>
              {tabulasi?.is_mvb && <th className={"border text-center px-2"}>Nama Variable</th>}
              <th className={"border text-center px-2"}>{tabulasi?.is_mvb ? 'Nilai Variable' : 'Harga Satuan'}</th>
            </tr>
          </thead>
          <tbody>
            {tabulasi?.data_harga_terbaik?.map((item: any, index: number) => {
              return (
                <tr key={"row-harga-terbaik-" + index}>
                  <td className={"border text-start px-2"}>{item.nama_item}</td>
                  {tabulasi?.is_mvb && <td className={"border text-start px-2"}>{item.nama_variable}</td>}
                  <td className={"border text-center text-nowrap align-middle px-2"}>{tabulasi?.is_mvb ? parseNumberToFloat(item.unit_price,2) :parseNumberToCurrency(item.unit_price, item.currency)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}

const TabulasiAuctionVendorTab = ({ auction, tabulasi, getTabulasi, isLoadingRefreshTabulasi }: any) => {
  return (
    <>
      <button
        type={"button"}
        onClick={() => {
          getTabulasi();
        }}
        className='btn btn-sm btn-light-info mx-1 fw-bold'>
        {isLoadingRefreshTabulasi && <Spinner animation={"border"} size={"sm"} className={"me-2"}></Spinner>}
        {!isLoadingRefreshTabulasi && <i className={"fa fa-refresh"}></i>}
        Refresh</button>
      {(tabulasi?.data_peringkat_terbaik != null || tabulasi?.data_harga_terbaik != null) && (
        <div className="row">
          <div className={tabulasi?.data_peringkat_terbaik != null && tabulasi?.data_harga_terbaik != null ? 'col-sm-12 col-md-6' : 'col-sm-12 col-md-12'}>
            {tabulasi?.is_package == true && tabulasi?.data_peringkat_terbaik != null && (
              <PeringkatTerbaikPackage auction={auction} tabulasi={tabulasi} />
            )}
            {tabulasi?.is_package == false && tabulasi?.data_peringkat_terbaik != null && (
              <PeringkatTerbaikItem auction={auction} tabulasi={tabulasi} />
            )}
          </div>
          <div className={tabulasi?.data_peringkat_terbaik != null && tabulasi?.data_harga_terbaik != null ? 'col-sm-12 col-md-6' : 'col-sm-12 col-md-12'}>
            {tabulasi?.data_harga_terbaik != null && (
              <HargaTerbaik auction={auction} tabulasi={tabulasi} />
            )}
          </div>
        </div>
      )}
      {(auction && !tabulasi.is_mvb) && (
        <Tabulasi auction={auction} tabulasi={tabulasi} />
      )}
      {(auction && tabulasi.is_mvb) && (
        <TabulasiMvb auction={auction} tabulasi={tabulasi} />
      )}
    </>
  )
}

export default TabulasiAuctionVendorTab