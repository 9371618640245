import { Field, Form, Formik } from 'formik'
import React, { useState, useEffect } from 'react'
import { Modal, Button } from "react-bootstrap";
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { Link, useNavigate, useParams } from 'react-router-dom'
import SelectX from '../../../../../components/shared/form/SelectX'
import { ApiUrl } from '../../../../../config/Api'
import { getEnv } from "../../../../../../helpers/procurex";
import { InputNumber } from "rsuite";
import axios from 'axios'
import { useAuth } from "../../../../../../app/modules/auth";
import DatepickerX2 from "../../../../../components/shared/form/DatepickerX2";
import { useIntl } from "react-intl";
import Swal from "sweetalert2";

interface CreateProps {
    backUrl?: string
}
const CreateArrival = ({ backUrl }: CreateProps) => {
    const { currentUser } = useAuth();

    const initialValues = {
        reference: '',
        moda_transportasi: '',
        nama_transportasi: '',
        transportation_id: '',
        material_number: '',
        plant: '',
        sloc: '',
        pr: '',
        qty: '',
        eta: '',
        actual_gr_date: '',
        keterangan: '',
        po: '',
        contract: ''
    }

    const intl = useIntl();
    const refFormik = React.useRef<any>();
    const [itemsMasterMaterial, setItemsMasterMaterial] = useState<any>([])
    const [itemsMasterCompany, setItemsCompanyStock] = useState<any>([])
    const [ItemsTransportasi, setItemsTransportasi] = useState<any>([])
    const [ItemsPlant, setItemsPlant] = useState<any>([])
    const [ItemsSloc, setItemsSloc] = useState<any>([])
    const [itemLoading, setItemLoading] = useState(false)

    const loadItem = () => {
        axios
            .get(getEnv("stockview") + `/master-material`)
            .then((res) => {
                setItemsMasterMaterial(res.data.data)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setItemLoading(false)
            })
        axios
            .get(getEnv("stockview") + `/company-stock?perPage=50&page=1`)
            .then((res) => {
                setItemsCompanyStock(res.data.data)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setItemLoading(false)
            })
        axios
            .get(getEnv("stockview") + `/transportation-all`)
            .then((res) => {
                setItemsTransportasi(res.data.data)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setItemLoading(false)
            })
        axios
            .get(getEnv("stockview") + `/material-plant-all?filter[active_app]=X&company_code=${company_code_user_first}`)
            .then((res) => {
                setItemsPlant(res.data.data)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setItemLoading(false)
            })
        axios
            .get(getEnv("stockview") + `/sloc?company_code=${company_code_user_first}&perPage=2090&page=1`)
            .then((res) => {
                const uniqueItems = res.data.data.filter((item, index, self) =>
                    index === self.findIndex((t) => t.kode_sloc === item.kode_sloc)
                );
                setItemsSloc(uniqueItems);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setItemLoading(false);
            });

    }

    useEffect(() => {
        loadItem();
    }, [])

    const formSchemaValidation = Yup.object().shape({
        material_number: Yup.string().required('Material Number is required'),
        plant: Yup.string().required('Plant is required'),
        qty: Yup.number().required('Quantity is required').positive('Quantity must be positive'),
        eta: Yup.date().required('ETA is required'),
    });

    const [formData, setFormData] = React.useState([]);

    // const [initialValues, setInitialvalues] = useState<any>(defaultValue)
    const [loading, setLoading] = useState(true)
    const [isLoading, setIsLoading] = useState(false);
    const [key, setKey] = useState(0)

    const [totalPR, setTotalPR] = useState(0);
    const [totalQTY, setTotalQTY] = useState(0);
    const [totalContract, setTotalContract] = useState(0);

    const handleOnTotalPRChange = (score: number) => {
        setTotalPR(score)
    }
    const handleOnTotalContractChange = (score: number) => {
        setTotalContract(score)
    }
    const handleOnTotalQTYChange = (score: number) => {
        setTotalQTY(score)
    }

    const [selectedModaTransportasi, setSelectedModaTransportasi] = useState(null);
    const [selectedNamaTransportasi, setSelectedNamaTransportasi] = useState(null);
    const [selectedMaterial, setSelectedMaterial] = useState(null);
    const [selectedPlant, setSelectedPlant] = useState(null);
    const [selectedSloc, setSelectedSloc] = useState(null);

    const handleModaTransportasiSelect = (selectedValue: any) => {
        setSelectedModaTransportasi(selectedValue);
    };

    const handleNamaTransportasiSelect = (selectedValue: any) => {
        setSelectedNamaTransportasi(selectedValue);
    };

    const handleMaterialSelect = (selectedValue: any) => {
        setSelectedMaterial(selectedValue);
    };

    const handlePlantSelect = (selectedValue: any) => {
        setSelectedPlant(selectedValue);
    };

    const handleSlocSelect = (selectedValue: any) => {
        setSelectedSloc(selectedValue);
    };

    const TableRow = ({ no, reference,
        moda_transportasi,
        nama_transportasi,
        material_number,
        plant,
        sloc,
        pr,
        qty,
        eta,
        actual_gr_date,
        keterangan,
        po,
        po_item,
        contract, onDelete, ...props }: any) => {

        const [showModal, setShowModal] = useState(false);

        const handleModalOpen = () => setShowModal(true);
        const handleModalClose = () => setShowModal(false);

        return (
            <>
                <tr>
                    <td className={"border text-center"}>{no}</td>
                    <td className={"border"}>{reference}</td>
                    <td className={"border"}>{moda_transportasi}</td>
                    <td className={"border"}>{nama_transportasi}</td>
                    <td className={"border"}>{material_number}</td>
                    <td className={"border"}>{plant}</td>
                    <td className={"border"}>{sloc}</td>
                    <td className={"border"}>{pr}</td>
                    <td className={"border"}>{qty}</td>
                    <td className={"border"}>{eta}</td>
                    <td className={"border"}>{actual_gr_date}</td>
                    <td className={"border"}>{keterangan}</td>
                    <td className={"border"}>{po}</td>
                    <td className={"border"}>
                        <button type="button" className='btn btn-info' onClick={handleModalOpen}>
                            <i className='bi bi-eye'></i>
                        </button>
                    </td>
                    <td className={"border"}>{contract}</td>
                    <td className={"border text-center"}>
                        <button className={"btn btn-sm btn-secondary"} onClick={() => onDelete()}>
                            <i className={"fa fa-trash text-danger"}></i>
                        </button>
                    </td>
                </tr>

                <Modal size="xl" show={showModal} onHide={handleModalClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>PO Item Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {po_item ? (
                            (() => {
                                let poItemData;
                                try {
                                    poItemData = JSON.parse(po_item);
                                } catch (error) {
                                    return <div className="alert alert-danger">Invalid JSON data</div>;
                                }

                                const allKeys = Array.isArray(poItemData) ? poItemData.reduce((keys, item) => {
                                    Object.keys(item).forEach(key => {
                                        if (typeof item[key] === 'object' && item[key] !== null) {
                                            Object.keys(item[key]).forEach(nestedKey => {
                                                const combinedKey = `${key}.${nestedKey}`;
                                                if (!keys.includes(combinedKey)) keys.push(combinedKey);
                                            });
                                        } else {
                                            if (!keys.includes(key)) keys.push(key);
                                        }
                                    });
                                    return keys;
                                }, []) : [];

                                return (
                                    <div className="table-responsive mt-3">
                                        <table className='table table-striped table-bordered'>
                                            <thead>
                                                <tr>
                                                    {allKeys.map((key, index) => (
                                                        <th key={index}>{key}</th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Array.isArray(poItemData) && poItemData.length > 0 ? (
                                                    poItemData.map((item, index) => (
                                                        <tr key={index}>
                                                            {allKeys.map((key) => {
                                                                const keys = key.split('.');
                                                                let value = item;

                                                                keys.forEach(k => {
                                                                    value = value ? value[k] : null;
                                                                });

                                                                return (
                                                                    <td key={key}>
                                                                        {value !== undefined && value !== null
                                                                            ? String(value)
                                                                            : 'N/A'}
                                                                    </td>
                                                                );
                                                            })}
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan={allKeys.length}>No PO Item data available.</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                );
                            })()
                        ) : (
                            <div>No PO Item data available.</div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleModalClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    };

    const [formindex, setFormIndex] = useState(0)

    const navigate = useNavigate();

    const handleBack = async () => {
        Swal.fire({
            title: `Data Belum Tersimpan`,
            html: `Apakah Anda Yakin Ingin Keluar ?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: `Ya, Keluar!`,
            confirmButtonColor: "#999999",
            cancelButtonColor: "#258141",
            cancelButtonText: `Cancel`,
        }).then((result) => {
            if (result.isConfirmed) {
                navigate('/stockview/transaction/arrival')
            }
        })
    }

    const handleBackClick = () => {
        if (formData.length !== 0) {
            handleBack();
        } else {
            navigate('/stockview/transaction/arrival');
        }
    };

    const handleAddRow = async (values: any) => {
        let idFromTransportation = values.nama_transportasi.split(" ")[0];
        let nameFromTransportation = values.nama_transportasi.substring(values.nama_transportasi.indexOf(' ') + 1);

        const newData = {
            reference: values.reference,
            moda_transportasi: values.moda_transportasi,
            nama_transportasi: nameFromTransportation,
            transportation_id: idFromTransportation,
            material_number: values.material_number,
            plant: values.plant,
            sloc: values.sloc,
            pr: values.pr,
            qty: values.qty,
            contract: values.contract,
            keterangan: values.keterangan,
            po: values.po,
            po_item: Array.isArray(selectedItemsPO) && selectedItemsPO.length > 0 ? selectedItemsPO : '',
            eta: values.eta,
            actual_gr_date: values.actual_gr_date
        };
        setFormData([...formData, newData]);

        setFormIndex(formindex + 1)

        // @ts-ignore

    };

    const handleDeleteRow = async (index: number) => {
        const newData = formData.filter((item: any, i: number) => i !== index);
        // @ts-ignore
        setFormData(newData);
    };

    const handleSubmit = async () => {
        setLoading(true)
        try {
            const data = {
                ...formData
            };

            const response = await axios.post(getEnv("stockview") + `/kedatangan-transaction`, data)

            if (response.status === 200 || response.status === 201) {
                toast.success(response?.status + ': ' + response?.data.meta.message, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                })
            }
            setKey(key + 1)
        } catch (error: any) {
            console.error(error)
            toast.error(error?.data?.meta?.message || 'Terjadi kesalahan', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            })
        } finally {
            setLoading(false)
        }
    }

    const [itemsPO, setItemsPO] = useState([]);
    const [selectedItemsPO, setSelectedItemsPO] = useState([]);

    const syncPO = async (values: any) => {
        setLoading(true);

        const newData = {
            po: values.po
        };

        const idTranportation = {
            id: values.nama_transportasi.split(" ")[0]
        }
        const data = { ...newData };
        const dataId = { ...idTranportation };

        const response = await axios.post(getEnv("stockview") + `/kedatangan-transaction/sync-po`, data)
        await axios.post(getEnv("stockview") + `/vessel-route-history/AddDataHistoryLog`, dataId)

        if (response.status === 200 || response.status === 201) {
            toast.success(response?.status + ': Successfully get data PO SAP', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            })
        }

        const itemDataITEM = response?.data?.data?.data?.ITEM?.item || [];
        const itemDataITEM_SCHEDULE = response?.data?.data?.data?.ITEM_SCHEDULE?.item || [];
        const combinedData = itemDataITEM.map((item) => {
            const matchingScheduleItem = itemDataITEM_SCHEDULE.find(
                (scheduleItem) => scheduleItem.PO_ITEM === item.PO_ITEM
            );

            return {
                ITEM: {
                    ...item
                },
                ITEM_SCHEDULE: {
                    ...(matchingScheduleItem ? matchingScheduleItem : {})
                },
            };
        });

        setItemsPO(combinedData);
    }

    const handleItemSelection = (e, item) => {
        console.log('item', item);

        const { checked } = e.target;

        setSelectedItemsPO((prevSelectedItems) => {
            if (checked) {
                return [...prevSelectedItems, item];
            } else {
                return prevSelectedItems.filter((selectedItem) => selectedItem.ITEM.PCKG_NO !== item.ITEM.PCKG_NO);
            }
        });
    };

    let company_code_user_first = currentUser.company_code[0];
    const formatCapacity = (capacity: number) => {
        return new Intl.NumberFormat('id-ID', { minimumFractionDigits: 3 }).format(capacity);
    };

    return (
        <Formik
            key={formindex}
            innerRef={refFormik}
            initialValues={initialValues}
            onSubmit={handleAddRow}
            validationSchema={formSchemaValidation}
            enableReinitialize
            validateOnChange={true}
        >
            {({ errors, touched, values, setFieldValue }: any) => (
                <Form>
                    <div className='d-flex justify-content-between mb-6'>
                        <div>
                            <button className='btn btn-secondary btn-sm fw-bold' onClick={handleBackClick}>
                                Back To list
                            </button>
                        </div>
                        <div className='d-flex gap-4'>
                            <button className={"btn btn-primary btn-sm"} disabled={isLoading}
                                onClick={() => handleSubmit()}>
                                {isLoading && <i className={"fa fa-spinner fa-spin"}></i>}
                                {!isLoading && <i className={"fa fa-save"}></i>}
                                {intl.formatMessage({ id: "Simpan" })}
                            </button>
                        </div>
                    </div>

                    <div className='card my-5'>
                        <div className='card-header d-flex align-items-center'>
                            <b>FORM ADD KEDATANGAN</b>
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <div className='mb-6'>
                                        <label htmlFor='' className='form-label'>
                                            Reference
                                        </label>
                                        <Field className='form-control' name="reference" placeholder="input reference.." />
                                    </div>
                                    <div className='mb-6'>
                                        <label htmlFor='' className='form-label'>
                                            PO
                                        </label>
                                        <div className='sync mb-3' style={{ 'textAlign': 'end' }}>
                                            <button type='button' className='btn btn-success' onClick={() => syncPO(values)}>Sync</button>
                                        </div>
                                        <Field className='form-control' name="po" placeholder="input nomor po.." />
                                        {itemsPO.length > 0 && (
                                            <div className="dropdown mt-2">
                                                <button
                                                    className="btn btn-secondary dropdown-toggle"
                                                    type="button"
                                                    id="dropdownMenuButton"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    Select Items PO
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    {itemsPO.map((item, index) => (
                                                        <li key={index} className="dropdown-item">
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    value={item.ITEM.PO_ITEM}
                                                                    id={`item-${index}`}
                                                                    onChange={(e) => handleItemSelection(e, item)}
                                                                    checked={selectedItemsPO.some((selected) => selected.ITEM.PO_ITEM === item.ITEM.PO_ITEM)}
                                                                />
                                                                <label className="form-check-label" htmlFor={`item-${index}`}>
                                                                    {item.ITEM.PO_ITEM}
                                                                </label>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                                {/* <div className="mt-3">
                                                    <h6>Selected Items (as Array):</h6>
                                                    <pre>{JSON.stringify(selectedItemsPO, null, 2)}</pre>
                                                </div> */}
                                            </div>
                                        )}
                                    </div>
                                    <div className='mb-6'>
                                        <label htmlFor='' className='form-label'>
                                            Moda Transportasi
                                        </label>
                                        <SelectX
                                            onChange={(e: any) => setFieldValue('moda_transportasi', e)}
                                            options={[
                                                { label: 'Shipping', value: 'Shipping' },
                                                { label: 'Truck', value: 'Truck' },
                                            ]}
                                        />
                                    </div>
                                    <div className='mb-6'>
                                        <label htmlFor='' className='form-label'>
                                            Nama Transportasi
                                        </label>
                                        <SelectX
                                            onChange={(e: any) => setFieldValue('nama_transportasi', e)}
                                            options={ItemsTransportasi.map((item: any) => ({
                                                label: `${item.name} - ${parseFloat(item.capacity).toLocaleString('id-ID', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`,
                                                value: `${item.id} ${item.name} - ${parseFloat(item.capacity).toLocaleString('id-ID', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`
                                            }))}
                                        />
                                    </div>
                                    <div className='mb-6'>
                                        <label htmlFor='' className='form-label'>
                                            Sloc
                                        </label>
                                        <SelectX
                                            onChange={(e: any) => setFieldValue('sloc', e)}
                                            options={ItemsSloc.map((item: any) => ({
                                                label: `${item.kode_sloc} - ${item.desc_sloc}`,
                                                value: item.kode_sloc
                                            }))}
                                        />
                                    </div>
                                    <div className='mb-6'>
                                        <label htmlFor='' className='form-label'>
                                            PR
                                        </label>
                                        <InputNumber
                                            defaultValue={totalPR}
                                            min={0}
                                            name="pr"
                                            className={"w-100 text-end"}
                                            onChange={(e: any) => setFieldValue('pr', e)}
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='mb-6'>
                                        <label htmlFor='' className='required form-label'>
                                            Quantity
                                        </label>
                                        <InputNumber
                                            defaultValue={totalQTY}
                                            min={0}
                                            name="qty"
                                            className={"w-100 text-end"}
                                            onChange={(e: any) => setFieldValue('qty', e)}
                                        />
                                        {(errors.qty && touched.qty) && (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{errors.qty}</div>
                                            </div>
                                        )}
                                    </div>
                                    <div className='mb-6'>
                                        <label htmlFor='' className='required form-label'>
                                            Eta
                                        </label>
                                        <DatepickerX2
                                            onChange={(date: any) => setFieldValue("eta", date)}
                                            size={"lg"}
                                            value={values.eta}
                                            format={"dd-MM-yyyy"}
                                        />
                                        {(errors.eta && touched.eta) && (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{errors.eta}</div>
                                            </div>
                                        )}
                                    </div>
                                    <div className='mb-6'>
                                        <label htmlFor='' className='required form-label'>
                                            Material Number
                                        </label>
                                        <SelectX
                                            onChange={(e: any) => setFieldValue('material_number', e)}
                                            options={itemsMasterMaterial.map((item: any) => ({
                                                label: `${item.material_number} - ${item.material_desc}`,
                                                value: item.material_number
                                            }))}
                                        />
                                        {(errors.material_number && touched.material_number) && (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{errors.material_number}</div>
                                            </div>
                                        )}
                                    </div>
                                    <div className='mb-6'>
                                        <label htmlFor='' className='required form-label'>
                                            Plant
                                        </label>
                                        <SelectX
                                            onChange={(e: any) => setFieldValue('plant', e)}
                                            options={ItemsPlant.map((item: any) => ({
                                                label: `${item.kode_plant} - ${item.nama_plant}`,
                                                value: item.kode_plant
                                            }))}
                                        />
                                        {(errors.plant && touched.plant) && (
                                            <div className="fv-plugins-message-container">
                                                <div className="fv-help-block">{errors.plant}</div>
                                            </div>
                                        )}
                                    </div>
                                    <div className='mb-6'>
                                        <label htmlFor='' className='form-label'>
                                            Actual GR Date
                                        </label>
                                        <DatepickerX2
                                            onChange={(date: any) => setFieldValue("actual_gr_date", date)}
                                            size={"lg"}
                                            value={values.actual_gr_date}
                                            format={"dd-MM-yyyy"}
                                        />
                                    </div>
                                    <div className='mb-6'>
                                        <label htmlFor='' className='form-label'>
                                            Keterangan
                                        </label>
                                        <Field className='form-control' name="keterangan" placeholder="input keterangan.." />
                                    </div>
                                    <div className='mb-6'>
                                        <label htmlFor='' className='form-label'>
                                            Contract
                                        </label>
                                        <InputNumber
                                            defaultValue={totalContract}
                                            min={0}
                                            name="contract"
                                            className={"w-100 text-end"}
                                            onChange={(e: any) => setFieldValue('contract', e)}
                                        />
                                    </div>
                                </div>
                                <div className='d-flex justify-content-end gap-4'>
                                    <button type={"submit"} className={"btn btn-primary btn-sm fw-bold"}>
                                        {isLoading && <i className={"fa fa-spinner fa-spin"}></i>}
                                        {!isLoading && <i className={"fa fa-plus"}></i>}
                                        {intl.formatMessage({ id: "Tambah" })}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"col-md-12 mt-5"}>
                        <table className={"table table-bordered"}>
                            <thead>
                                <tr>
                                    <th className={"border text-center"}><strong>No</strong></th>
                                    <th className={"border text-center"}><strong>Reference</strong></th>
                                    <th className={"border text-center"}><strong>Moda Transportasi</strong></th>
                                    <th className={"border text-center"}><strong>Nama Transportasi</strong></th>
                                    <th className={"border text-center"}><strong>Material Number</strong></th>
                                    <th className={"border text-center"}><strong>Plant</strong></th>
                                    <th className={"border text-center"}><strong>Sloc</strong></th>
                                    <th className={"border text-center"}><strong>PR</strong></th>
                                    <th className={"border text-center"}><strong>Quantity</strong></th>
                                    <th className={"border text-center"}><strong>Eta</strong></th>
                                    <th className={"border text-center"}><strong>Actual GR Date</strong></th>
                                    <th className={"border text-center"}><strong>Keterangan</strong></th>
                                    <th className={"border text-center"}><strong>PO</strong></th>
                                    <th className={"border text-center"}><strong>PO ITEM</strong></th>
                                    <th className={"border text-center"}><strong>Contract</strong></th>
                                    <th className={"border text-center"}><strong>Aksi</strong></th>
                                </tr>
                            </thead>
                            <tbody>
                                {formData.length === 0 && (
                                    <tr>
                                        <td colSpan={17} className={"text-center"}>
                                            <i>Tidak ada data</i>
                                        </td>
                                    </tr>
                                )}
                                {formData.map((item: any, index: number) => {
                                    return (
                                        <TableRow
                                            key={"adm-" + index}
                                            no={index + 1}
                                            reference={item.reference}
                                            moda_transportasi={item.moda_transportasi}
                                            nama_transportasi={item.nama_transportasi}
                                            material_number={item.material_number}
                                            plant={item.plant}
                                            sloc={item.sloc}
                                            pr={item.pr}
                                            qty={item.qty}
                                            eta={item.eta}
                                            actual_gr_date={item.actual_gr_date}
                                            keterangan={item.keterangan}
                                            po={item.po}
                                            po_item={JSON.stringify(item.po_item, null, 2) || ''}
                                            contract={item.contract}
                                            onDelete={() => handleDeleteRow(index)}
                                        />
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export { CreateArrival }