import 'devextreme/dist/css/dx.light.css'
import 'devexpress-richedit/dist/dx.richedit.css'

import {AxiosResponse} from 'axios'
import {
  create,
  createOptions,
  DocumentFormat,
  EventHandlers,
  FileTabItemId,
  HomeTabItemId,
  RibbonTabType,
  RichEdit,
  RichEditUnit,
  SavingEventArgs,
  ViewType,
} from 'devexpress-richedit'
import {BookmarkClientApi} from 'devexpress-richedit/lib/client/api/bookmark'
import {Component} from 'react'
import {toast} from 'react-toastify'

export interface DevExpressRichEditProps {
  base64Document: string
  documentName?: string
  documentFormat?: DocumentFormat

  enableFileTab?: boolean
  disableProtection?: boolean
  width?: string
  height?: string
  readOnly?: boolean

  onEvents?: EventHandlers
  onSaving?: (blob: Blob, richEdit: RichEdit, args: SavingEventArgs) => Promise<AxiosResponse>
  onAfterSaving?: (
    response: AxiosResponse,
    blob: Blob,
    richEdit: RichEdit,
    args: SavingEventArgs
  ) => void
}

export class DevExpressRichEdit extends Component<DevExpressRichEditProps> {
  rich: RichEdit | null = null

  componentDidMount() {
    if (this.rich || !this.props.base64Document) return

    this.rich = create(document.getElementById('richEdit'), this.getOptions())

    this.rich.openDocument(
      this.props.base64Document,
      this.props.documentName ?? 'DocumentName',
      this.props.documentFormat ?? DocumentFormat.OpenXml
    )
  }

  getOptions() {
    // the createOptions() method creates an object that contains RichEdit options initialized with default values
    const options = createOptions()

    options.confirmOnLosingChanges.enabled = true
    options.confirmOnLosingChanges.message =
      'Are you sure you want to perform the action? All unsaved document data will be lost.'

    options.unit = RichEditUnit.Centimeter

    options.view.viewType = ViewType.PrintLayout
    options.view.simpleViewSettings.paddings = {
      left: 15,
      top: 15,
      right: 15,
      bottom: 15,
    }

    options.readOnly = this.props.readOnly ?? false
    options.width = this.props.width
    options.height = this.props.height ?? '800px'

    options.rangePermissions.showBrackets = false
    options.rangePermissions.bracketsColor = undefined
    options.rangePermissions.highlightRanges = false
    options.rangePermissions.highlightColor = undefined

    //region Events
    options.events = this.props.onEvents ?? {}
    options.events.documentLoaded = this.executeOnDocumentLoaded()

    if (!options.events?.saving && !!this.props.onSaving) {
      options.events.saving = this.executeOnSaving()
    }
    //endregion

    //region Ribbon
    const homeTab = options.ribbon.getTab(RibbonTabType.Home)

    const ribbonItemUndo = homeTab.getItem(HomeTabItemId.Undo)

    ribbonItemUndo.beginGroup = true

    const ribbonItemSave = options.ribbon
      .getTab(RibbonTabType.File)
      .getItem(FileTabItemId.ExportDocument)

    homeTab.insertItem(ribbonItemSave, 0)

    if (!this.props.enableFileTab) {
      options.ribbon.removeTab(RibbonTabType.File)
    }
    //endregion

    return options
  }

  executeOnDocumentLoaded() {
    return (richEdit: RichEdit) => {
      this.protectDocument(richEdit)
    }
  }

  private protectDocument(richEdit: RichEdit) {
    if (this.props.disableProtection) {
      return
    }

    const document = richEdit.document
    const bookmarks = document.bookmarks
    let bookmark: BookmarkClientApi | null = null
    const protectedIntervals = []

    for (let i = 0; i < bookmarks.count; i++) {
      bookmark = bookmarks.getByIndex(i)

      if (bookmark) {
        protectedIntervals.push(bookmark.interval)
      }
    }

    document.rangePermissions.protectRange(protectedIntervals)

    richEdit.document.protect(process.env.DEVEXPRESS_PROTECT_PASSWORD)
    richEdit.hasUnsavedChanges = false
  }

  private unprotectDocument(richEdit: RichEdit) {
    const count = richEdit.document.rangePermissions.count

    for (let i = 0; i < count; i++) {
      richEdit.document.rangePermissions.getByIndex(0).delete()
    }

    richEdit.document.unprotect()
  }

  executeOnSaving() {
    return (richEdit: RichEdit, event: SavingEventArgs) => {
      richEdit.readOnly = true

      this.unprotectDocument(richEdit)

      richEdit.exportToBlob(async (blob: Blob) => {
        const savingToast = toast.info('Saving...', {
          position: 'top-right',
          hideProgressBar: true,
          closeOnClick: false,
          closeButton: false,
        })

        try {
          const response = await this.props.onSaving(blob, richEdit, event)

          toast.dismiss(savingToast)

          richEdit.hasUnsavedChanges = false

          if (this.props.onAfterSaving) {
            this.props.onAfterSaving(response, blob, richEdit, event)
          }
        } catch (error) {
          toast.dismiss(savingToast)
          return error
        } finally {
          richEdit.readOnly = false

          this.protectDocument(richEdit)
        }
      })
    }
  }

  render() {
    return <div id='richEdit'></div>
  }
}
