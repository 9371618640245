import { ID, KTCard, KTCardBody } from '../../../../../../_metronic/helpers'
import React, { useState, useEffect } from 'react'
import TableXWrpper from "../../../../../components/shared/TableXWrapper";
import { ApiUrl } from '../../../../../config/Api'
import { getEnv } from "../../../../../../helpers/procurex";
import { useIntl } from 'react-intl'
import axios from 'axios'
import { Button, ButtonGroup, Col, Modal, Row } from "react-bootstrap";
import { useAuth } from "../../../../../../app/modules/auth";

const VesselRouteHistoryList = () => {
    const intl = useIntl()
    const { currentUser } = useAuth();
    const tablecolumns = [
        {
            Header: "IMO",
            accessor: "imo",
            width: 100,
        },
        {
            Header: "SHIP NAME",
            accessor: "shipname",
            width: 140,
        },
        {
            Header: "DISTANCE",
            accessor: "distance",
            width: 120,
        },
        {
            Header: "PANAMA",
            accessor: "panama",
            width: 120,
        },
        {
            Header: "SUEZ",
            accessor: "suez",
            width: 120,
        },
        {
            Header: "FINAL PATH",
            accessor: "final_path",
            width: 400,
            Cell: ({ value }) => {
                if (typeof value !== 'string') {
                    return <span>-</span>;
                }

                const maxLength = 5;
                const coordinates = value.match(/\([\d\.\s,-]+\)/g)?.[0] || "";

                if (!coordinates) {
                    return <span>No Coordinates</span>;
                }
                const truncatedCoordinates = coordinates.split(",").slice(0, maxLength).join(", ") + " ...";
                return <span>{`LINESTRING ${truncatedCoordinates}`}</span>;
            }
        },
        {
            Header: "Action",
            accessor: "uuid",
            width: 180,
            className: "text-center fixed-column",
            Cell: (props: any) => {
                const row = props.row.original;
                return (
                    <ButtonGroup size='sm' aria-label='Action'>
                        <button
                            type='button'
                            className='btn btn-sm btn-success'
                            onClick={(e: any) => {
                                e.preventDefault();
                                setId(row.id);
                                openModalWithItem(row);
                            }}
                        >
                            <i className='fa fa-eye' aria-hidden="true"></i> Detail
                        </button>
                    </ButtonGroup>
                );
            }
        }
    ];

    const [formindex, setFormIndex] = useState(0);

    const [isShowModal, setIsShowModal] = useState(false)
    const [isShowDetail, setIsShowDetail] = useState(false)
    let [itemId, setItemId] = useState(0)
    const [id, setId] = useState('')
    const [item, setItem] = useState<any>(null)

    const openModalWithItem = async (item: any) => {
        setItem(item);
        setIsShowModal(true);
    };

    let company_code_user = currentUser.company_code;
    let role = currentUser.has_roles;

    return (
        <>
            <Modal size="xl" show={isShowModal} onHide={() => setIsShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Detail Route History <strong>{item?.shipname} - {item?.imo}</strong></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='detail'>
                        <ul className='list-group'>
                            <li className='list-group-item'><p style={{ fontSize: '16px' }}><strong>Distance:</strong> {item?.distance}</p></li>
                            <li className='list-group-item'><p style={{ fontSize: '16px' }}><strong>Panama:</strong> {item?.panama}</p></li>
                            <li className='list-group-item'><p style={{ fontSize: '16px' }}><strong>Suez:</strong> {item?.suez}</p></li>
                            <li className='list-group-item'><p style={{ fontSize: '16px' }}><strong>Final Path:</strong> {item?.final_path}</p></li>
                            <li className='list-group-item'><p style={{ fontSize: '16px' }}><strong>Updated Time:</strong> {new Date(item?.updated_at).toLocaleString('en-US', {
                                timeZone: 'Asia/Jakarta'
                            })}</p></li>
                        </ul>
                    </div>
                </Modal.Body>
            </Modal>

            <KTCard className='card border mt-5'>
                <KTCardBody >
                    <TableXWrpper
                        title={"Vessel Route History"}
                        key={formindex}
                        readonly
                        urlIndex={getEnv("stockview") + `/vessel-route-history`}
                        columns={tablecolumns}
                    />
                </KTCardBody>
            </KTCard>
        </>
    )
}

export { VesselRouteHistoryList }
