import React from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
import {PageLink} from '../../../../_metronic/layout/core'
import {PoArchiveDetailPage} from './PoArchiveDetailPage'
import {PoArchiveListPage} from './PoArchiveListPage'
import {PoArchiveUploadPage} from './PoArchiveUploadPage'

export const PoArchivePage = ({breadcrumbs}: {breadcrumbs?: Array<PageLink>}) => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route index element={<PoArchiveListPage breadcrumbs={breadcrumbs} />} />
        <Route path='/upload' element={<PoArchiveUploadPage breadcrumbs={breadcrumbs} />} />
        <Route path='/:uuid/*' element={<PoArchiveDetailPage breadcrumbs={breadcrumbs} />} />
      </Route>
    </Routes>
  )
}
