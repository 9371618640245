import React, {CSSProperties, useEffect, useState} from 'react'
import {createPortal} from 'react-dom'
import {SignCoordinatesProvider} from '../components/shared/SignCoordinatesProvider'

const DEFAULT_URL =
  'https://procurement-dev.pupuk-indonesia.com/api/media/media/9d5a1c03-2f98-4fab-82d8-18531a03a647/file2'

export const DevExpressRichCoordinateTestPage = () => {
  const [documentUrl, setDocumentUrl] = useState(DEFAULT_URL)
  const [bookmarkName, setBookmarkName] = useState('sign')


  return (
    <SignCoordinatesProvider
      documents={{
        test: {
          docxDocumentUrl: documentUrl,
          bookmarkName: bookmarkName,
        },
      }}
      isEditorVisible={true}
    >
      {({results}) => {
        const {coordinateInPixels, coordinateInPoints, error} = results.test ?? {}
        const page = coordinateInPixels
          ? document.querySelector(`.dxrePage:nth-child(${coordinateInPixels.page})`)
          : null
        const pageRect = page?.getBoundingClientRect()
        const marginTop = 15
        const baseStyle = {
          position: 'absolute',
          backgroundColor: '#333',
          color: 'white',
          zIndex: 9999999,
        } as CSSProperties
        // @ts-ignore
        console.log("=>(DevExpressRichCoordinateTestPage.tsx:26) coordinateInPixels\n", coordinateInPixels);

        return (
          <div className='card'>
            {coordinateInPixels &&
              createPortal(
                <>
                  <div
                    id='coordinate-page'
                    style={{
                      ...baseStyle,
                      top: pageRect.height - marginTop + 'px',
                      left: 0 + 'px',
                    }}
                  >
                    Page
                  </div>
                  <div
                    id='coordinate-lower-left'
                    style={{
                      ...baseStyle,
                      top: pageRect.height - coordinateInPixels.lowerLeftY + 'px',
                      left: coordinateInPixels.lowerLeftX + 'px',
                    }}
                  >
                    lowerLeft {coordinateInPoints.lowerLeftX}pt x {coordinateInPoints.lowerLeftY}pt
                  </div>
                  <div
                    id='coordinate-upper-right'
                    style={{
                      ...baseStyle,
                      top: pageRect.height - coordinateInPixels.upperRightY + 'px',
                      left: coordinateInPixels.upperRightX + 'px',
                    }}
                  >
                    upperRight {coordinateInPoints.upperRightX}pt x {coordinateInPoints.upperRightY}pt
                  </div>
                </>,
                page
              )}
            <div className='card-body'>
              <form
                onSubmit={(e) => {
                  e.preventDefault()
                  setDocumentUrl(e.target['document_url'].value)
                  setBookmarkName(e.target['bookmark_name'].value)
                }}
              >
                <div className='row'>
                  <div className='col'>
                    <label htmlFor='document_url' className='form-label'>
                      Document URL
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      id='document_url'
                      name='document_url'
                      defaultValue={documentUrl}
                    />
                  </div>

                  {/*<div className='col'>*/}
                  {/*  <label htmlFor='bookmark_name' className='form-label'>*/}
                  {/*    Bookmark Name*/}
                  {/*  </label>*/}
                  {/*  <input*/}
                  {/*    type='text'*/}
                  {/*    className='form-control'*/}
                  {/*    id='bookmark_name'*/}
                  {/*    name='bookmark_name'*/}
                  {/*    defaultValue={bookmarkName}*/}
                  {/*  />*/}
                  {/*</div>*/}

                  <div className='col'>
                    <label htmlFor='bookmark_name' className='form-label'>
                      Bookmark Name
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      id='bookmark_name'
                      name='bookmark_name'
                      defaultValue={bookmarkName}
                    />
                  </div>

                  <div className='col-auto'>
                    <div>
                      <label className='form-label'>&nbsp;</label>
                    </div>
                    <button type='submit' className='btn btn-primary'>
                      Get Sign Coordinate
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className='card-body'>
              {error && (
                <div className={'alert alert-danger'}>
                  <i className={'fa fa-exclamation-triangle'}></i>
                  {error}
                </div>
              )}

              <div className='table-responsive'>
                <table className='table table-rounded table-striped border gy-2 gs-7'>
                  <tbody>
                    <tr>
                      <th>lowerLeftX</th>
                      <td className='text-end'>{coordinateInPixels?.lowerLeftX ?? '-'} px</td>
                      <td className='text-end'>{coordinateInPoints?.lowerLeftX ?? '-'} pt</td>
                    </tr>
                    <tr>
                      <th>lowerLeftY</th>
                      <td className='text-end'>{coordinateInPixels?.lowerLeftY ?? '-'} px</td>
                      <td className='text-end'>{coordinateInPoints?.lowerLeftY ?? '-'} pt</td>
                    </tr>
                    <tr>
                      <th>upperRightX</th>
                      <td className='text-end'>{coordinateInPixels?.upperRightX ?? '-'} px</td>
                      <td className='text-end'>{coordinateInPoints?.upperRightX ?? '-'} pt</td>
                    </tr>
                    <tr>
                      <th>upperRightY</th>
                      <td className='text-end'>{coordinateInPixels?.upperRightY ?? '-'} px</td>
                      <td className='text-end'>{coordinateInPoints?.upperRightY ?? '-'} pt</td>
                    </tr>
                    <tr>
                      <th>page</th>
                      <td className='text-end'>{coordinateInPixels?.page ?? '-'}</td>
                      <td className='text-end'>{coordinateInPoints?.page ?? '-'}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )
      }}
    </SignCoordinatesProvider>
  )
}
