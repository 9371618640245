import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useAxiosGet, useAxiosPost } from "../../../../../../hooks/useAxios";
import {getEnv, parseNumberToCurrency, parseNumberToFloat} from '../../../../../../helpers/procurex'
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import axios from "axios";
import { Badge, InputNumber, Panel, Stack } from "rsuite";
import NoteAttachmentEvaluation from "./NoteAttachmentEvaluation";
import { Spinner } from "react-bootstrap";
import CurrencyInput from "react-currency-input-field";
import PreviewAttachment from "../../../../../components/PreviewAttachment";
import {EvaluasiKlarifikasiTeknisList} from './EvaluasiKlarifikasiAdministrasiTeknisList'

const EvaluasiTeknis = ({ proposalTender, quotation, onChange, onChangeEvaluationData, isOwner }: any) => {
  const [dataItems, setDataItems] = useState<any>([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isUncheckAll, setIsUncheckAll] = useState(false);
  const [note, setNote] = useState("");
  const [attachment, setAttachment] = useState([]);
  const [deletedAttachment, setDeletedAttachment] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [scorePaketSistemNilai, setScorePaketSistemNilai] = useState(0);
  const [statusEvaluationTechnical, setStatusEvaluationTechnical] = useState("NOT-EVALUATE");
  const [statusEvaluationTechnicalItem, setStatusEvaluationTechnicalItem] = useState("NOT-EVALUATE");
  const [debounceTimer, setDebounceTimer] = useState<any>(null);
  const [durVendor, setDurVendor] = useState(null as any);
  const [isMountedSistemNilai, setIsMountedSistemNilai] = useState(false);
  const [dataEvaluation, setDataEvaluation] = useState<any>([]);
  const [previewAttachment, setPreviewAttachment] = useState(null);
  const [totalValueVendor, setTotalValueVendor] = useState<any>(false);
  const [isExpand, setIsExpand] = useState(false)
  const [renderKey,setRenderKey] = useState(0)

  const intl = useIntl();
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosGet(
    getEnv("tender") + `/evaluation/item/${proposalTender?.uuid}/${quotation?.vendor?.uuid}`
  );

  const [{ doSubmit: doSubmitSave, isLoading: isLoadingSave }] = useAxiosPost(
    getEnv("tender") + `/evaluation/administration/syarat`
  );

  const debounce = (func: any, delay: number) => {
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }
    const timer = setTimeout(func, delay);
    setDebounceTimer(timer);
  };

  const handleOnCheck = (index: any, state: boolean) => {
    let tmpEval = [];
    const newItems = dataItems.map((item: any, i: number) => {
      if (isItemizeSistemGugur() && item.quote == "NO-QUOTE") return item;
      if (i === index) {
        item.technical_status = state;
        if (isPaketSistemGugur()) submitIfPaketSistemGugur(state);
        if (isItemizeSistemGugur()) submitIfItemizeSistemGugur(item, state, "-", index);
      }
      if (index === "all") {
        item.technical_status = state;
        if (isItemizeSistemGugur()) {
          const submit = submitIfItemizeSistemGugur(item, state, "-", index);
          tmpEval.push(submit)
        }
      }
      return item;
    });
    // @ts-ignore
    setDataItems(newItems);
    if (index === "all") setDataEvaluation(tmpEval);
  };

  const handleOnScoreChange = (score: number) => {
    setScorePaketSistemNilai(score);
  };

  const isFormEnabled = () => {
    const allowedStatus = [
      "Bid Opening",
      "Evaluasi Administrasi",
      "Evaluasi Teknikal"
    ];
    return allowedStatus.includes(proposalTender?.status)
            && !isLoadingSave ;
  };

  const getDurVendor = async () => {
    const url = getEnv("tender") + `/dur-vendor?filter[proposal_tender_id]=${proposalTender?.id}&filter[vendor_id]=${quotation?.vendor_id}`;
    doSubmit({}, url, undefined, () => {}).then((res: any) => {
      setDurVendor(res.data[0]);
    }).catch((err: any) => {});
  }

  const submitIfPaketSistemGugur = (state: boolean) => {
    setStatusEvaluationTechnical(state ? "PASSED" : "FAILED");
    const payload = {
      proposal_tender_vendor_id: quotation?.vendor?.uuid,
      evaluation: state
    }
    let tmp = dataEvaluation
    tmp = tmp.filter((item: any) => item.proposal_tender_vendor_id !== payload.proposal_tender_vendor_id)
    tmp.push(payload)
    setDataEvaluation(tmp)

    // doSubmitSave({
    //
    // }, getEnv("tender") + `/evaluation/technical/item/${proposalTender?.uuid}`, toast)
    //   .then((res: any) => {
    //     onChange && onChange();
    //   });
  };

  const submitIfItemizeSistemGugur = (item: any, state: boolean, notes: string, index: any) => {
    const payload = {
      proposal_tender_vendor_id: quotation?.vendor?.uuid,
      quotation_item_id: item?.uuid,
      evaluation: state,
      note: notes
    };
    let tmp = dataEvaluation;
    tmp = tmp.filter((item: any) => item.quotation_item_id !== payload.quotation_item_id)
    tmp.push(payload)
    if (index === "all") {
      return payload
    } else {
      setDataEvaluation(tmp)
    }

    // doSubmitSave(payload, getEnv("tender") + `/evaluation/technical/item/${proposalTender?.uuid}`,
    //   toast, () => {
    //   }).then((res: any) => {
    //   dataItems.forEach((item2: any) => {
    //     if (item2?.uuid === item?.uuid) {
    //       item.technical_status = state ? "PASSED" : "FAILED";
    //     }
    //   });
    //   onChange && onChange();
    // });
  };

  const submitIfPaketSistemNilai = () => {
    const payload = {
      proposal_tender_vendor_id: quotation?.vendor?.uuid,
      score: (scorePaketSistemNilai)
    }
    let tmp = dataEvaluation
    tmp = tmp.filter((item: any) => item.proposal_tender_vendor_id !== payload.proposal_tender_vendor_id)
    tmp.push(payload)
    setDataEvaluation(tmp)

    // doSubmitSave(payload, getEnv("tender") + `/evaluation/technical/item/${proposalTender?.uuid}`,
    //   toast, () => {
    //   }).then((res: any) => {
    //   onChange && onChange();
    // });
  };

  const handleOnChangeScoreItemizeSistemNilai = (item: any, score: any) => {
    const payload = {
      proposal_tender_vendor_id: quotation?.vendor?.uuid,
      quotation_item_id: item?.uuid,
      score: score,
      note: "-"
    };

    let tmp = dataEvaluation
    tmp = tmp.filter((item: any) => item.quotation_item_id !== payload.quotation_item_id)
    tmp.push(payload)
    setDataEvaluation(tmp)

    // doSubmitSave(payload, getEnv("tender") + `/evaluation/technical/item/${proposalTender?.uuid}`, toast, () => {
    // }).then((res: any) => {
    //   // dataItems.forEach((item2:any)=>{
    //   //   if(item2?.uuid === item?.uuid){
    //   //     item.score_technical = score;
    //   //   }
    //   // })
    //   onChange && onChange();
    // });
  };

  const handleOnSubmitEvaluasiTeknis = async() => {

    Swal.fire({
      title: `${intl.formatMessage({id:"Apakah Anda yakin"})}?`,
      html: `${intl.formatMessage({id:"Anda akan mensubmit Evaluasi Teknis"})}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Ya, Submit`,
      cancelButtonText: intl.formatMessage({id:"Cancel"}),
    }).then((result) => {
      if (result.isConfirmed) {
        //region save evaluation
        dataEvaluation.forEach((item: any,index:number) => {
          doSubmitSave(item, getEnv("tender") + `/evaluation/technical/item/${proposalTender?.uuid}`, undefined).then((res:any)=>{
            if(index === dataEvaluation.length-1){
              const evaluateVendorURL = getEnv("tender") + `/evaluation/vendor/v2/${proposalTender?.uuid}/${quotation?.vendor?.uuid}`;
              doSubmitSave({}, evaluateVendorURL, toast, () => {}).then((res: any) => {
                onChange && onChange();
                getDurVendor();
                getItems();
                setIsExpand(!isExpand)
                setRenderKey(renderKey+1)
              }).catch((err: any) => {});
            }
          });
        });
        //end region


        // region save note
        if (note) {
          doSubmitSave({
            note: note || "-",
            type: "Evaluasi Teknikal"
          }, getEnv("tender") + `/evaluation/notes/${quotation?.vendor?.uuid}`, undefined);

        }
        // end region

        // region save attachment
        // @ts-ignore
        if (attachment && attachment.length > 0) {
          const payloadAttachment = {
            // @ts-ignore
            description: attachment[0]?.media_type?.file_name,
            type: "Evaluasi Teknikal",
            // @ts-ignore
            media_type: attachment[0]?.media_type,
            // @ts-ignore
            media_url: attachment[0]?.media_url
          }
          doSubmitSave(payloadAttachment, getEnv("tender") + `/evaluation/attachment/${quotation?.vendor?.uuid}`, undefined);
        }

        //delete attachment
        // @ts-ignore
        if (deletedAttachment && deletedAttachment.length > 0) {
          deletedAttachment.forEach((item: any) => {
            doSubmitSave({
              _method: "DELETE"
            }, getEnv("tender") + `/evaluation/attachment/${quotation?.vendor?.uuid}/${item}`, undefined);
          })
        }
        //end region
      }
    })
  }

  const isPaketSistemGugur = () => {
    return proposalTender?.order_placement === "Paket" &&
      proposalTender?.metode_evaluasi === "Sistem Gugur";
  };

  const isPaketSistemNilai = () => {
    return proposalTender?.order_placement === "Paket" &&
      proposalTender?.metode_evaluasi === "Sistem Nilai";
  };

  const isItemizeSistemGugur = () => {
    return proposalTender?.order_placement === "Itemize" &&
      proposalTender?.metode_evaluasi === "Sistem Gugur";
  };

  const isItemizeSistemNilai = () => {
    return proposalTender?.order_placement === "Itemize" &&
      proposalTender?.metode_evaluasi === "Sistem Nilai";
  };

  // Get selected vendor status based on quotation and proposal tender
  const getSelectedVendor = () => {
    axios
      .get(getEnv("tender") + `/dur-vendor?filter[proposal_tender_id]=${proposalTender?.id}&perPage=1000`)
      .then((res) => {
        const _selectedVendor = res.data.data?.find((vendor: any) => {
          return (vendor?.vendor_id).toString() === (quotation?.vendor_id).toString();
        });

        let technical_status;
        if (proposalTender?.order_placement === "Paket" && proposalTender?.metode_evaluasi === "Sistem Gugur") {
          technical_status = _selectedVendor?.status_item_evaluation_technical;
        } else {
          technical_status = _selectedVendor?.status_evaluation_technical;
        }

        // Set states
        setSelectedVendor(_selectedVendor);
        setScorePaketSistemNilai(_selectedVendor?.score);
        setStatusEvaluationTechnical(technical_status);
      })
      .catch((error) => {
        console.error("Error fetching selected vendor:", error);
      });

  };

  const getItems = () => {
    doSubmit({}, undefined, undefined, () => {
    }).then((res: any) => {
      console.log(res);
      const _items = res.data;
      // jika paket sistem gugur, maka default technical_status ambil dari status vendor di proposal tender
      if (isPaketSistemGugur()) {
        _items.forEach((item: any) => {
          // @ts-ignore
          item.technical_status = selectedVendor?.status_evaluation_technical === "PASSED";
        });
      }
      setDataItems(_items);
      setRenderKey(renderKey+1)
    }).catch((err: any) => {
      toast.error("Get Evaluasi Teknis: " + err?.response?.data?.meta?.message);
    });
  };

  const getDataEvaluationItem = (index: number) => {
    return dataEvaluation
      ?.find((item: any) => item?.quotation_item_id === dataItems?.[index]?.uuid)
      ?.evaluation;
  }

  const getTotalValueFromVendorQuotation = () => {
    let total = '0';
    let _total = 0;

    // backlog: currency not found
    let curr = dataItems[0]?.currency?.code ?? 'IDR';

    dataItems.forEach((item: any) => {
      _total += parseFloat(item.total_value);
    });

    total = _total.toLocaleString('id-ID', {
              style: 'currency',
              currency: curr
            });
    // setTotalValueVendor(total);

    return total;
  }

  useEffect(() => {
    getDurVendor();
    getItems();
    getSelectedVendor();
  }, []);

  useEffect(() => {
    if (isPaketSistemNilai() && isMountedSistemNilai) {
      debounce(() => {
        submitIfPaketSistemNilai();
      }, 500);
    }
    setTimeout(() => {
      setIsMountedSistemNilai(true);
    }, 500);
  }, [scorePaketSistemNilai]);

  useEffect(() => {
    //region expanded condition
    if((durVendor?.status_evaluation_technical === "PASSED" || durVendor?.status_evaluation_technical === "FAILED")){
      setIsExpand(false)
    } else {
      setIsExpand(true)
    }
    setRenderKey(renderKey+1)
    //endregion
  }, [durVendor])

  const TableItemServiceLines = ({proposalTender, service, quotation, item, index,}: any) => {
    const [shortTextPR, setShortTextPR] = useState<any>(service?.short_text_pr);
    const [shortText, setShortText] = useState<any>(service?.short_text);
    const [remark, setRemark] = useState<any>(service?.remark);
    const [quantity, setQuantity] = useState<any>(service?.quantity);
    const [valuationPrice, setValuationPrice] = useState<any>(service?.net_price);
    const [totalPenawaran, setTotalPenawaran] = useState<any>(service?.gross_price);
    const [renderIndex, setRenderIndex] = useState<any>(0);
    const [debounceTimer, setDebounceTimer] = useState<any>(null);


    return (
      <>
        <tr key={index + "-" + service?.id} style={{
          position: "relative",
          height: "auto"
        }}>
          <td className={"text-center border align-middle"}
            style={{verticalAlign: "middle", position: 'sticky', left: 0, zIndex: 1, backgroundColor: "#fff"}}>
            {isLoading && (
              <div className={""} style={{
                position: "absolute",
                width: "100%",
                textAlign: "center",
                backgroundColor: "rgba(255,255,255,0.5)",
                zIndex: 9999,
                justifyContent: "center",
                alignItems: "center",
                height: "100%"
              }}>
                <i className={"fa fa-spinner fa-spin fa-2x"}></i>
              </div>
            )}
          </td>

          {/* Start Short Text */}
          <td className={"text-start border align-start bg-white"} style={{
            verticalAlign: "middle", position: 'sticky', left: 30, zIndex: 1
          }}>
            <textarea
              className={"form-control form-control-sm"}
              defaultValue={shortTextPR}
              rows={1}
              disabled={true}
              maxLength={50}>
            </textarea>
          </td>
          {/* End Short Text */}

          {/* Start Qty */}
          <td className={"text-center border align-start"}>
            <CurrencyInput
              className="form-control form-control-sm text-end"
              groupSeparator="."
              decimalSeparator=","
              decimalsLimit={2}
              value={quantity}
              onValueChange={(value, name) => {
                setQuantity(value);
              }}
              style={{
                minWidth: "100px"
              }}
              disabled={true}
            />
          </td>
          {/* End Qty */}

          {/* Start UoM */}
          <td className={"text-center border align-start"}>
            <input type={"text"}
                  className={"form-control form-control-sm"}
                  disabled={true}
                  value={service?.uom_id}
                  style={{
                    minWidth: "50px"
                  }}
            />
          </td>
          {/* End UoM */}

          {/* Start Currency */}
          <td className={"text-center border align-start"}>
            <input type={"text"}
              className={"form-control form-control-sm"}
              disabled={true}
              value={service?.currency_id}
              style={{
                minWidth: "50px"
              }}
            />
          </td>
          {/* End Currency */}

          {/* Start Short Text */}
          <td className={"text-start border align-start bg-white"} style={{
            verticalAlign: "middle", position: 'sticky', left: 30, zIndex: 1
          }}>
            <textarea
              className={"form-control form-control-sm"}
              defaultValue={shortText}
              rows={1}
              disabled={true}
              maxLength={50}>
            </textarea>
          </td>
          {/* End Short Text */}
          {/* Start Qty */}
          <td className={"text-center border align-start"}>
            <CurrencyInput
              className="form-control form-control-sm text-end"
              groupSeparator="."
              decimalSeparator=","
              decimalsLimit={2}
              value={quantity}
              onValueChange={(value, name) => {
                setQuantity(value);
              }}
              style={{
                maxWidth: "150px"
              }}
              disabled={true}
            />
          </td>
          {/* End Qty */}

          {/* Start UoM */}
          <td className={"text-center border align-start"}>
            <input type={"text"}
                  className={"form-control form-control-sm"}
                  disabled={true}
                  value={service?.uom_id}
                  style={{
                    minWidth: "50px"
                  }}
            />
          </td>
          {/* End UoM */}

          {/* Start Currency */}
          <td className={"text-center border align-start px-2"}>
            <input type={"text"}
              className={"form-control form-control-sm"}
              disabled={true}
              value={service?.currency_id}
              style={{
                minWidth: "50px"
              }}
            />
          </td>
          {/* End Currency */}
        </tr>
      </>
    )
  }

  const TableItemService = ({proposalTender, service, quotation, item, index,}: any) => {
    let serviceLinesCount         = (item?.service_lines_quotation?.length === 0) ? 0 : item?.service_lines_quotation?.length;
    let totalRowSpanPerItem       = serviceLinesCount + 1;

    let totalServiceLinesAllItem  = dataItems.reduce((total: any, item: { service_lines_quotation: string | any[]; }) => {
      return total + ((item?.service_lines_quotation?.length === 0) ? 0 : item?.service_lines_quotation?.length);
    }, 0);

    let totalRowSpan              = totalServiceLinesAllItem + dataItems.length;


    return (
      <>
        {/* Start Short Text */}
        <td className={"border"}
            style={{ position: "sticky",left: 30,zIndex: 1,backgroundColor: "#fff" }}>
              <div className={"mb-2"}><small className={"text-muted"}>PR Short Text </small><br />{item?.buyer_short_text}</div>
              
              <div className={"mb-2"}><small className={"text-muted"}>No PR / Line No: </small><br />{item?.noPr} / {item?.itemPr}</div>
        </td>
        {/* End Short Text */}

        {/* Start Buyer Specification */}
        <td className={"border"} style={{ maxHeight: `${40 * item.serviceLinesCount}px`}} rowSpan={totalRowSpanPerItem}>
          <div style={{
            maxHeight: `${40 * item.serviceLinesCount}px`,
            width: "250px",
            overflow: "auto"
          }}>
            <textarea
              className={"form-control form-control-sm"}
              defaultValue={item?.buyer_spesification}
              rows={(3 * serviceLinesCount) + 1}
              disabled={true}
              maxLength={250}>
            </textarea>
          </div>
        </td>
        {/* End Buyer Specification */}

        {/* Start Quantity */}
        <td className={"border text-end"}>{parseNumberToFloat(item?.quantity)}</td>
        {/* End Quantity */}

        {/* Start UoM */}
        <td className={"border text-center text-nowrap"}>{item?.uom?.uom}</td>
        {/* End UoM */}

        {/* Start Currency */}
        <td className={"border text-center text-nowrap"}>{item?.currency?.code}</td>
        {/* End Currency */}

        {/* Start Comply or Deviate */}
        <td className={"border text-center text-nowrap"} rowSpan={totalRowSpanPerItem ?? 0} style={{
          verticalAlign: "middle",
        }}>
          <strong>{item?.quote}</strong>
        </td>
        {/* End Comply or Deviate */}

        {/* Start Short Text or Deskripsi */}
        <td className={"border"}>{item?.short_text}</td>
        {/* End Short Text or Deskripsi */}

        {/* Start Qty */}
        <td className={"border text-end"}>
          <div style={{
            maxHeight: "100px",
            width: "75px",
            overflow: "auto"
          }}>
            {parseNumberToFloat(item?.quantity)}
          </div>
        </td>
        {/* End Qty */}

        {/* Start Spesifikasi */}
        <td className={"border"} style={{ maxHeight: `${40 * item.serviceLinesCount}px`}} rowSpan={totalRowSpanPerItem}>
          <div style={{
            maxHeight: `${40 * item.serviceLinesCount}px`,
            width: "250px",
            overflow: "auto"
          }}>
            <textarea
              className={"form-control form-control-sm"}
              defaultValue={item?.buyer_spesification}
              rows={(3 * serviceLinesCount) + 1}
              disabled={true}
              maxLength={250}>
            </textarea>
          </div>
        </td>
        {/* End Spesifikasi */}

        {/* Start UoM */}
        <td className={"border text-center text-nowrap"}>{item?.uom?.uom}</td>
        {/* End UoM */}

        {/* Start Currency */}
        <td className={"border text-nowrap"}>{item?.currency?.code}</td>
        {/* End Currency */}

        {/* Start Nilai TKDN */}
        <td className={"text-center border align-start"} style={{ maxHeight: `${40 * item.serviceLinesCount}px`, verticalAlign: "middle"}} rowSpan={totalRowSpanPerItem}>
          <input type={"text"}
                className={"form-control form-control-sm text-end"}
                disabled={true}
                value={(item?.tkdn+" %")}
                style={{ minWidth: "75px" }}
          />
        </td>
        {/* End Nilai TKDN */}

        {/* Start Nomor TKDN */}
        <td className={"text-center border align-start"} style={{ maxHeight: `${40 * item.serviceLinesCount}px`, verticalAlign: "middle", minWidth: "100px"}} rowSpan={totalRowSpanPerItem}>
          <div  className={"input-group input-group-sm d-flex align-items-center"}
                style={{minWidth: "250px"}}>
            <input  type={"text"}
                    className={"form-control form-control-sm"}
                    value={item?.tkdn_certification_number}
                    disabled={true}
                    style={{ minWidth: "100px" }}
            />
            <button type={"button"}
                    className={"btn btn-primary btn-sm ml-2"}
                    disabled={isLoading}
                    onClick={() => setPreviewAttachment(
                      item?.tkdn_certification_url
                    )}>
              <i className={"fa fa-eye"}></i>
            </button>
          </div>
        </td>
        {/* End Nomor TKDN */}

        {/* Start Nilai TKDN */}
        <td className={"text-center border align-start"} style={{ maxHeight: `${40 * item.serviceLinesCount}px`, verticalAlign: "middle"}} rowSpan={totalRowSpanPerItem}>
          <input type={"text"}
                className={"form-control form-control-sm text-end"}
                disabled={true}
                value={(item?.delivery_time)}
                style={{ minWidth: "50px" }}
          />
        </td>
        {/* End Nilai TKDN */}

        {/* Start Note */}
        <td className={"text-center border align-start"} style={{ maxHeight: `${40 * item.serviceLinesCount}px`, verticalAlign: "middle"}} rowSpan={totalRowSpanPerItem}>
          <div style={{minWidth: "125px"}}>
            <textarea
              className={"form-control form-control-sm"}
              defaultValue={item?.remark}
              rows={(2 * serviceLinesCount)}
              disabled={true}
              maxLength={500}
            />
          </div>
        </td>
        {/* End Note */}
      </>
    )
  }

  const TableItemMaterial = ({proposalTender, service, quotation, item, index,}: any) => {
    return (
      <>
        {/* Start Short Text */}
        <td className={"border"}
            style={{ position: "sticky",left: 30,zIndex: 1,backgroundColor: "#fff" }}>
              <div className={"mb-2"}><small className={"text-muted"}>PR Short Text </small><br />{item?.buyer_short_text}</div>
              <div className={"mb-2"}><small className={"text-muted"}>No PR / Line No: </small><br />{item?.noPr} / {item?.itemPr}</div>
        </td>
        {/* End Short Text */}

        {/* Start Buyer Specification */}
        <td className={"border"} style={{ maxHeight: `${40 * item.serviceLinesCount}px`}}>
          <div style={{
            maxHeight: `${40 * item.serviceLinesCount}px`,
            width: "250px",
            overflow: "auto"
          }}>
            <textarea
              className={"form-control form-control-sm"}
              defaultValue={item?.buyer_spesification}
              rows={1}
              disabled={true}
              maxLength={250}>
            </textarea>
          </div>
        </td>
        {/* End Buyer Specification */}

        {/* Start Quantity */}
        <td className={"border text-end align-middle"}>{parseNumberToFloat(item?.quantity)}</td>
        {/* End Quantity */}

        {/* Start UoM */}
        <td className={"border text-center text-nowrap align-middle"}>{item?.uom?.uom}</td>
        {/* End UoM */}

        {/* Start Currency */}
        <td className={"border text-center text-nowrap align-middle"}>{item?.currency?.code}</td>
        {/* End Currency */}

        {/* Start Comply or Deviate */}
        <td className={"border text-center text-nowrap align-middle"} style={{
          verticalAlign: "middle",
        }}>
          <strong>{item?.quote}</strong>
        </td>
        {/* End Comply or Deviate */}

        {/* Start Short Text or Deskripsi */}
        <td className={"border align-middle"}>{item?.short_text}</td>
        {/* End Short Text or Deskripsi */}

        {/* Start Qty */}
        <td className={"border text-end align-middle"}>
          <div style={{
            maxHeight: "100px",
            width: "75px",
            overflow: "auto"
          }}>
            {parseNumberToFloat(item?.quantity)}
          </div>
        </td>
        {/* End Qty */}

        {/* Start Spesifikasi */}
        <td className={"border"} style={{ maxHeight: `${40 * item.serviceLinesCount}px`}}>
          <div style={{
            maxHeight: `${40 * item.serviceLinesCount}px`,
            width: "250px",
            overflow: "auto"
          }}>
            <textarea
              className={"form-control form-control-sm"}
              defaultValue={item?.specification}
              rows={1}
              disabled={true}
              maxLength={250}>
            </textarea>
          </div>
        </td>
        {/* End Spesifikasi */}

        {/* Start UoM */}
        <td className={"border text-center text-nowrap align-middle"}>{item?.uom?.uom}</td>
        {/* End UoM */}

        {/* Start Currency */}
        <td className={"border text-nowrap align-middle"}>{item?.currency?.code}</td>
        {/* End Currency */}

        {/* Start Nilai TKDN */}
        <td className={"text-center border align-start"} style={{ maxHeight: `${40 * item.serviceLinesCount}px`, verticalAlign: "middle"}}>
          <input type={"text"}
                className={"form-control form-control-sm text-end"}
                disabled={true}
                value={(item?.tkdn+" %")}
                style={{ minWidth: "75px" }}
          />
        </td>
        {/* End Nilai TKDN */}

        {/* Start Nomor TKDN */}
        <td className={"text-center border align-middle"} style={{ maxHeight: `${40 * item.serviceLinesCount}px`, verticalAlign: "middle", minWidth: "100px"}}>
          <div  className={"input-group input-group-sm d-flex align-items-center"}
                style={{minWidth: "250px"}}>
            <input  type={"text"}
                    className={"form-control form-control-sm"}
                    value={item?.tkdn_certification_number}
                    disabled={true}
                    style={{ minWidth: "100px" }}
            />
            <button type={"button"}
                    className={"btn btn-primary btn-sm ml-2"}
                    disabled={isLoading}
                    onClick={() => setPreviewAttachment(
                      item?.tkdn_certification_url
                    )}>
              <i className={"fa fa-eye"}></i>
            </button>
          </div>
        </td>
        {/* End Nomor TKDN */}

        {/* Start Delivery Time */}
        <td className={"border text-nowrap align-middle"}>{item?.delivery_time}</td>
        {/* End Delivery Time */}

        {/* Start Note */}
        <td className={"text-center border align-start"} style={{ maxHeight: `${40 * item.serviceLinesCount}px`, verticalAlign: "middle"}}>
          <div style={{minWidth: "125px"}}>
            <textarea
              className={"form-control form-control-sm"}
              defaultValue={item?.remark}
              disabled={true}
              maxLength={500}
            />
          </div>
        </td>
        {/* End Note */}
      </>
    )
  }

  const handleCheckAll = (e: any) => {
    if (e.target.checked) {
      setIsCheckAll(true);
      setIsUncheckAll(false);
      handleOnCheck("all", true);
    } else {
      setIsCheckAll(false);
      setIsUncheckAll(false);
      setDataEvaluation([])
    }
  };

  const handleUncheckAll = (e: any) => {
    if (e.target.checked) {
      setIsCheckAll(false);
      setIsUncheckAll(true);
      handleOnCheck("all", false);
    } else {
      setIsCheckAll(false);
      setIsUncheckAll(false);
      setDataEvaluation([])
    }
  };

  useEffect(() => {
    let check = 0
    let unCheck = 0

    dataEvaluation.map((item: any, index: number) => {
      if (item?.evaluation) check++
      if (!item?.evaluation) unCheck++
    })

    if (check > 0 && unCheck > 0) {
      setIsCheckAll(false);
      setIsUncheckAll(false);
    }
  }, [dataEvaluation])

  return (
    <>
      <hr></hr>
      <Panel
        key={renderKey}
        header={
          <Stack justifyContent={"space-between"}>
            <h3>{intl.formatMessage({ id: "Proses Evaluasi Teknis" })}</h3>
            {(durVendor?.status_evaluation_technical === "NO-DATA" || durVendor?.status_evaluation_technical === "NOT-EVALUATE") && (
              <div>
                <Badge className={"px-2 py-1"} content={
                  <>
                    <i className={"fa fa-times-circle text-white me-2"}></i>
                    {intl.formatMessage({ id: "Belum Dievaluasi" })}
                  </>
                } color="red" />
              </div>
            )}
            {(durVendor?.status_evaluation_technical === "PASSED" || durVendor?.status_evaluation_technical === "FAILED") && (
              <div>
                <Badge className={"px-2 py-1"} content={
                  <>
                    <i className={"fa fa-check-circle text-white me-2"}></i>
                    {intl.formatMessage({ id: "Telah Dievaluasi" })}
                  </>
                } color="blue" />
              </div>
            )}
          </Stack>
        }
        collapsible
        bordered
        defaultExpanded={isExpand}
      >
        <div className={"mb-10"}>
          <div className={"table-responsive"}>
            <table className={"table table-bordered table-striped table-header-gray"}>
              <thead>
              <tr>
                <th
                  className={"text-center border px-2"}
                  style={{ verticalAlign: "middle", position: "sticky", left: 0, zIndex: 1 }}
                  rowSpan={2}
                >
                  <strong>&nbsp;{intl.formatMessage({ id: "No" })}</strong>
                </th>
                <th className={"border align-items-start"}
                    style={{ verticalAlign: "middle", minWidth: "250px", position: "sticky", left: 30, zIndex: 1 }}
                    rowSpan={2}>
                  <strong>{intl.formatMessage({ id: "Deskripsi Item PR" })}</strong></th>
                <th className={"border text-center"} colSpan={4}>
                  <strong>{intl.formatMessage({ id: "Permintaan Buyer" })}</strong></th>
                <th className={"border text-center"} colSpan={10}>
                  <strong>{intl.formatMessage({ id: "Penawaran Vendor" })}</strong></th>
                <th
                  className={"border text-center text-nowrap px-2"}
                  style={{ verticalAlign: "middle", position: "sticky", right: 0, zIndex: 1 }}
                  colSpan={(isPaketSistemGugur() || isItemizeSistemGugur()) ? 2 : 1}
                  rowSpan={isPaketSistemNilai() || isItemizeSistemNilai() ? 2 : 1}
                >
                  <strong>
                    {proposalTender?.metode_evaluasi === "Sistem Nilai" ? intl.formatMessage({ id: "Nilai Evaluasi" }) : intl.formatMessage({ id: "Hasil Evaluasi" })}
                  </strong>
                </th>
              </tr>
              <tr>
                <th className={"border px-2 text-center"}>{intl.formatMessage({ id: "Spesifikasi" })}</th>
                <th className={"border px-2 text-center"}>{intl.formatMessage({ id: "Qty" })}</th>
                <th className={"border px-2 text-center"}>{intl.formatMessage({ id: "UoM" })}</th>
                <th className={"border px-2 text-center text-nowrap"}>{intl.formatMessage({ id: "Curr" })}</th>
                <th className={"border px-2 text-center text-nowrap"}>{intl.formatMessage({ id: "Comply/Deviate" })}</th>
                <th className={"border px-2 text-center"} style={{
                  minWidth: "250px"
                }}>{intl.formatMessage({ id: "Deskripsi" })}</th>
                <th className={"border px-2 text-center"}>{intl.formatMessage({ id: "Qty" })}</th>
                <th className={"border px-2 text-center"}>{intl.formatMessage({ id: "Spesifikasi" })}</th>
                <th className={"border px-2 text-center"}>{intl.formatMessage({ id: "UoM" })}</th>
                <th className={"border text-nowrap px-2 text-center"}>{intl.formatMessage({ id: "Curr" })}</th>
                <th className={"border text-nowrap px-2 text-center"}>{intl.formatMessage({ id: "Nilai TKDN" })}</th>
                <th className={"border text-nowrap px-2 text-center"}>{intl.formatMessage({ id: "Nomor TKDN" })}</th>
                <th className={"border text-nowrap px-2 text-center"}>{intl.formatMessage({ id: "Delivery Time" })}</th>
                <th className={"border text-nowrap px-2 text-center"}>{intl.formatMessage({ id: "Catatan" })}</th>
                {(isPaketSistemGugur() || isItemizeSistemGugur()) && (
                  <>
                    <th
                      className={"text-center text-nowrap border"}
                      style={{ verticalAlign: "middle", position: "sticky", right: 85, zIndex: 1 }}
                    >
                      {isItemizeSistemGugur() &&
                        <>
                          <input type={"checkbox"} className={"form-check-input"} checked={isCheckAll} disabled={!isFormEnabled()}
                          onChange={handleCheckAll} /> {intl.formatMessage({ id: "Setujui Semua" })}
                        </>
                      }
                    </th>
                    <th
                      className={"text-center text-nowrap border"}
                      style={{ verticalAlign: "middle", position: "sticky", right: 0, zIndex: 1 }}
                    >
                      {isItemizeSistemGugur() &&
                        <>
                          <input type={"checkbox"} className={"form-check-input"} checked={isUncheckAll}
                          disabled={!isFormEnabled()} onChange={handleUncheckAll} /> {intl.formatMessage({ id: "Tolak Semua" })}
                        </>
                      }
                    </th>
                  </>
                )}

              </tr>
              </thead>
              <tbody>

              {dataItems.map((item: any, index: number) => {

                let isBarang    = (item?.service_lines_quotation?.length === 0)
                let isJasa      = (item?.service_lines_quotation?.length > 0);

                let serviceLinesCount         = (item?.service_lines_quotation?.length === 0) ? 0 : item?.service_lines_quotation?.length;
                let totalRowSpanPerItem       = serviceLinesCount + 1;

                let totalServiceLinesAllItem  = dataItems.reduce((total: any, item: { service_lines_quotation: string | any[]; }) => {
                  return total + ((item?.service_lines_quotation?.length === 0) ? 0 : item?.service_lines_quotation?.length);
                }, 0);

                let totalRowSpan              = totalServiceLinesAllItem + dataItems.length;

                return (
                  <>
                    <tr key={"items-" + item?.uuid}>
                      <td className={"text-center border"}
                          style={{ position: "sticky", left: 0, zIndex: 1, backgroundColor: "#fff" }}>{index + 1}
                      </td>

                      {/* Start Barang/Jasa Evaluasi */}
                      {(isBarang) && (
                        <TableItemMaterial proposalTender={proposalTender} service={item} quotation={quotation} item={item} index={index} />
                      )}
                      {(isJasa) && (
                        <TableItemService proposalTender={proposalTender} service={item} quotation={quotation} item={item} index={index} />

                      )}
                      {/* End Barang/Jasa Evaluasi */}

                      {/*Start Nilai Evaluasi / Lolos,TidakLolos */}
                      {(isPaketSistemGugur() && index === 0) && (
                        <>
                          <td
                            className={"border text-center text-nowrap px-2"}
                            rowSpan={isJasa
                              ? totalRowSpan
                              : dataItems.length + 1
                            }
                            style={{
                              verticalAlign: "middle",
                              position: "sticky",
                              right: 85,
                              zIndex: 1,
                              backgroundColor: "#f8fafb"
                            }}
                          >
                            {isLoadingSave && (
                              <i className={"fa fa-spinner fa-spin"}></i>
                            )}
                            <input type={"checkbox"} className={"form-check-input"}
                                  checked={statusEvaluationTechnical === "PASSED"} disabled={!isFormEnabled()}
                                  onChange={(e: any) => {
                                    handleOnCheck(index, true);
                                  }} /> {intl.formatMessage({ id: "Diterima" })}
                          </td>
                          <td
                            className={"border text-center text-nowrap px-2"}
                            rowSpan={isJasa
                              ? totalRowSpan
                              : dataItems.length + 1
                            }
                            style={{
                              verticalAlign: "middle",
                              position: "sticky",
                              right: 0,
                              zIndex: 1,
                              backgroundColor: "#f8fafb"
                            }}
                          >
                            {isLoadingSave && (
                              <i className={"fa fa-spinner fa-spin"}></i>
                            )}
                            <input type={"checkbox"} className={"form-check-input"}
                                  checked={statusEvaluationTechnical === "FAILED"} disabled={!isFormEnabled()}
                                  onChange={(e: any) => {
                                    handleOnCheck(index, false);
                                  }} /> {intl.formatMessage({ id: "Ditolak" })}
                          </td>
                        </>
                      )}
                      {(isPaketSistemNilai() && index === 0) && (
                        <td className={"border text-center align-start text-nowrap px-2"}
                              rowSpan={isJasa
                                ? totalRowSpan
                                : dataItems.length + 1
                              }                            style   ={{
                              verticalAlign: "middle",
                              position: "sticky",
                              right: 0,
                              zIndex: 1
                            }}>
                          {isLoadingSave && (
                            <i className={"fa fa-spinner fa-spin"}></i>
                          )}
                          {/*<input*/}
                          {/*  type={"number"}*/}
                          {/*  defaultValue={scorePaketSistemNilai}*/}
                          {/*  min={0} max={100}*/}
                          {/*  className={"w-100 text-end"}*/}
                          {/*  placeholder={"score"}*/}
                          {/*  disabled={isLoadingSave}*/}
                          {/*  onChange={(e:any)=>{*/}
                          {/*    handleOnScoreChange(e.target.value)*/}
                          {/*  }}*/}
                          {/*/>*/}
                          <InputNumber
                            defaultValue={dataItems[index]?.score_technical_evaluation}
                            min={0} max={100} step={0.01}
                            className={"w-100 text-end"}
                            disabled={!isFormEnabled()}
                            onChange={(value: any) => {
                              handleOnScoreChange(value);
                            }}
                          />
                        </td>
                      )}
                      {isItemizeSistemGugur() && (
                        <>
                          <td
                            className={"border text-center text-nowrap px-2"}
                            rowSpan={isBarang ? 1 : totalRowSpanPerItem}
                            style={{
                              verticalAlign: "middle",
                              position: "sticky",
                              right: 90,
                              zIndex: 1,
                              backgroundColor: "#f8fafb"
                            }}
                          >
                            {isLoadingSave && (
                              <i className={"fa fa-spinner fa-spin"}></i>
                            )}
                            <input type={"checkbox"} className={"form-check-input"}
                                  checked={item?.technical_status === "PASSED" || getDataEvaluationItem(index) === true}
                                  disabled={!isFormEnabled()}
                                  onChange={(e: any) => {
                                    handleOnCheck(index, true);
                                  }} /> Diterima
                          </td>
                          <td
                            className={"border text-center text-nowrap px-2"}
                            rowSpan={isBarang ? 1 : totalRowSpanPerItem}
                            style={{
                              verticalAlign: "middle",
                              position: "sticky",
                              right: 0,
                              zIndex: 1,
                              backgroundColor: "#f8fafb"
                            }}
                          >
                            {isLoadingSave && (
                              <i className={"fa fa-spinner fa-spin"}></i>
                            )}
                            <input type={"checkbox"} className={"form-check-input"}
                                  checked={item?.technical_status === "FAILED" || getDataEvaluationItem(index) === false}
                                  disabled={!isFormEnabled()}
                                  onChange={(e: any) => {
                                    handleOnCheck(index, false);
                                  }} /> Tidak Diterima
                          </td>
                        </>
                      )}
                      {(isItemizeSistemNilai()) && (
                        <td className={"border text-center align-start text-nowrap px-2"}
                            style={{ verticalAlign: "middle", position: "sticky", right: 0, zIndex: 1 }}
                            rowSpan={isBarang ? 1 : totalRowSpanPerItem}
                            key={"render-"+dataItems[index]+'-'+renderKey}
                        >
                          {isLoadingSave && (
                            <i className={"fa fa-spinner fa-spin"}></i>
                          )}
                          {isFormEnabled() && (
                            <InputNumber
                              defaultValue={dataItems[index]?.score_technical_evaluation}
                              min={0} max={100} step={0.01}
                              className={"w-100 text-end"}
                              onChange={(value: any) => {
                                debounce(() => {
                                  handleOnChangeScoreItemizeSistemNilai(item, value);
                                }, 500);
                              }}
                            />
                          )}
                          {!isFormEnabled() && (
                            <div className={"bg-white"}>
                              {dataItems[index]?.score_technical_evaluation}
                            </div>
                          )}

                        </td>
                      )}
                      {/*End Nilai Evaluasi / Lolos,TidakLolos */}

                    </tr>
                    {(item?.service_lines_quotation && item?.service_lines_quotation?.length > 0)  && item?.service_lines_quotation?.map((service: any, index2: number) => {
                        return (
                          <TableItemServiceLines
                            service={service}
                            index={index2}
                            indexItem={index}
                            key={index2 + "-" + service?.id}
                            quotation={quotation}
                            item={item}
                            proposalTender={proposalTender}
                          />
                        );
                      })}
                  </>
                );
                })}
                </tbody>
                {/* <tfoot>
                  <td className={"text-center border align-start"} style={{backgroundColor: '#0e365b', fontWeight: 'bold', fontSize: '20px', color: "white"}} colSpan={17}> Total Penawaran Vendor</td>
                  <td className={"text-center border align-start"} style={{backgroundColor: '#0e365b', fontWeight: 'bold', fontSize: '20px', color: 'white'}} colSpan={6}> <strong></strong>{getTotalValueFromVendorQuotation()}</td>
                </tfoot> */}
                </table>
                </div>

              <NoteAttachmentEvaluation
                proposalTender={proposalTender}
                quotation={quotation}
                onChange={(type:string,value:any)=>{
              setNote(value?.note);
              setAttachment(value?.attachments);
            }}
            onDeleted={(deletedAttachment: any) => {
              setDeletedAttachment(deletedAttachment)
            }}
            fieldKey={"evaluation_technical"}
            disabled={!isFormEnabled()}
          />

          <EvaluasiKlarifikasiTeknisList
            vendor_id={quotation?.vendor_id}
          />

          <PreviewAttachment previewExt={previewAttachment} onHide={setPreviewAttachment} />
          {isOwner &&
            <div className={"mt-4 d-flex justify-content-end"}>
              <button
                className={"btn btn-primary"}
                type={"button"}
                onClick={handleOnSubmitEvaluasiTeknis}
                disabled={isLoadingSave || !isFormEnabled()}
              >
                {isLoadingSave && (
                  // @ts-ignore
                  <Spinner
                    animation="border"
                    role="status"
                    size={"sm"}
                    className={"me-2"}
                  />
                )}
                {!isLoadingSave && <i className={"fa fa-save"}></i>}
                {intl.formatMessage({ id: "Simpan Evaluasi Teknis" })}
              </button>
            </div>
          }
        </div>
      </Panel>
    </>
  );
};


export default EvaluasiTeknis;