import {Col, Modal, Row} from 'react-bootstrap'

interface Props {
    show: boolean
    handleClose: () => void
    disabled: boolean
}

const ModalContact = ({
    show,
    handleClose,
    disabled
}: Props) => {
    return (
        <>
            <Modal size='lg' show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                  <Modal.Title><h1>Contact Us</h1></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='card mt-3 border'>
                        <div className='card-body'>
                            <Row>
                                <Col lg="6">
                                    <h1 style={{marginLeft: '10%', marginTop: '30%'}}>Form Feedback ProcureX</h1>
                                    <a href="https://cares.pupuk-indonesia.com" target='_blank' style={{color: 'blue', marginLeft: '10%', marginTop: 'auto', fontSize: '17px'}}>cares.pupuk-indonesia.com</a>
                                </Col>
                                <Col lg="6" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <img src='/qr_feedback1.jpg' alt='QR Code Feedback ProcureX' style={{ maxWidth: '70%' }}/>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className='card mt-3 border'>
                        <div className='card-body'>
                            <h1>Pertanyaan & Saran Terkait Aplikasi Dapat Menghubungi :</h1><br />

                            <h2>Hubungi Arya Razaan Muaafii</h2>
                            <p>Dept. Perencanaan Strategic & Evaluasi<br /><span style={{color: 'blue'}}><a href="whatsapp://send?text=Hello&phone=+62895600042222">+62 8956 0004 2222</a></span></p>
                        </div>
                    </div>
                    {/* <div className='card mt-3 border'>
                        <div className='card-body'>
                            <h1>Info Terkait Perencanaan Pengadaan : </h1><br />

                            {/* <h3>Dept. Perencanaan Daan Bahan Baku</h3> */}
                            {/* <ul> */}
                                {/* <li><b>Moch. Chafid</b><br /><span style={{color: 'blue'}}><a href="whatsapp://send?text=Hello&phone=+6281298414932">+62 812 9841 4932</a></span></li> */}
                                {/* <li><b>Puspita Firsty Lestari</b><br /><span style={{color: 'blue'}}><a href="whatsapp://send?text=Hello&phone=+6285711826867">+62 857 1182 6867</a></span></li> */}
                                {/* <li><b>Amelia Apriani</b><br /><span style={{color: 'blue'}}><a href="whatsapp://send?text=Hello&phone=+6285880980076">+62 858 8098 0076</a></span></li> */}
                            {/* </ul><br /> */}

                            {/* <h3>Dept. Perencanaan Daan Barang</h3> */}
                            {/* <ul> */}
                                {/* <li><b>Wanda Hafiz Nurzaman</b><br /><span style={{color: 'blue'}}><a href="whatsapp://send?text=Hello&phone=+6285780503780">+62 857 8050 3780</a></span></li> */}
                                {/* <li><b>Yonnas Sage</b><br /><span style={{color: 'blue'}}><a href="whatsapp://send?text=Hello&phone=+6282186008677">+62 821 8600 8677</a></span></li> */}
                                {/* <li><b>Abdul Fattah</b><br /><span style={{color: 'blue'}}><a href="whatsapp://send?text=Hello&phone=+6281283236624">+62 812 8323 6624</a></span></li> */}
                            {/* </ul><br /> */}

                            {/* <h3>Dept. Perencanaan Daan Jasa</h3> */}
                            {/* <ul> */}
                                {/* <li><b>Iwan</b><br /><span style={{color: 'blue'}}><a href="whatsapp://send?text=Hello&phone=+6281321041289">+62 813 2104 1289</a></span></li> */}
                                {/* <li><b>Mercyano Febrianda</b><br /><span style={{color: 'blue'}}><a href="whatsapp://send?text=Hello&phone=+6281239007826">+62 812 3900 7826</a></span></li> */}
                                {/* <li><b>Rani Widya Putri</b><br /><span style={{color: 'blue'}}><a href="whatsapp://send?text=Hello&phone=+6281252729836">+62 812 5272 9836</a></span></li> */}
                            {/* </ul> */}
                        {/* </div> */}
                    {/* </div> */}
                </Modal.Body>
                <Modal.Footer>
                <button onClick={handleClose} className='btn btn-secondary' type='button'>
                    Close
                </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ModalContact
