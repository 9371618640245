import React from 'react'
import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import Dashboard from './dashboard/Dashboard'
import {PoArchivePage} from './po-archive/PoArchivePage'
import { List } from './po/list/List'
import Create from './po/create/Create'
import MonitoringPOPage from './monitoring/MonitoringPOPage'
import ListPoPerItem from './po/list-per-item/ListPo'
import ListAgreementPerItem from './po/list-per-item/ListAgreement'
import AgreementList from './agreement/admin/AgreementList'
import AgreementDetail from './agreement/admin/AgreementDetail'

const listBreadcrumbs: Array<PageLink> = [
  {
    title: 'Contract Management',
    path: '/contract-management',
    isSeparator: false,
    isActive: false,
  },
]

const ContractPage = () => {
  return (
    <Routes>
      <Route index element={<Navigate to='/contract-management/dashboard' />} />
      <Route element={<Outlet />}>
        <Route
          path='/dashboard'
          element={
            <>
              <PageTitle breadcrumbs={listBreadcrumbs}>Dashboard</PageTitle>
              <Dashboard />
            </>
          }
        />
      </Route>

      <Route element={<Outlet />}>
        <Route
          path='/po'
          element={
            <>
              <PageTitle breadcrumbs={listBreadcrumbs}>Purchase Order List</PageTitle>
              <List />
            </>
          }
        />
      </Route>
      <Route element={<Outlet />}>
        <Route
          path='/po/create'
          element={
            <>
              <PageTitle breadcrumbs={listBreadcrumbs}>Purchase Order Create</PageTitle>
              <Create />
            </>
          }
        />
      </Route>
      <Route element={<Outlet />}>
        <Route
          path='/po/:id'
          element={
            <>
              <PageTitle breadcrumbs={listBreadcrumbs}>Purchase Order</PageTitle>
              <Create />
            </>
          }
        />
      </Route>

      <Route element={<Outlet />}>
        <Route
          path='/agreement'
          element={
            <>
              <PageTitle breadcrumbs={listBreadcrumbs}>Agreement List</PageTitle>
              <AgreementList />
            </>
          }
        />
      </Route>
      <Route element={<Outlet />}>
        <Route
          path='/agreement/create'
          element={
            <>
              <PageTitle breadcrumbs={listBreadcrumbs}>Agreement Detail</PageTitle>
              <AgreementDetail />
            </>
          }
        />
      </Route>
      <Route element={<Outlet />}>
        <Route
          path='/agreement/:id'
          element={
            <>
              <PageTitle breadcrumbs={listBreadcrumbs}>Agreement Detail</PageTitle>
              <AgreementDetail />
            </>
          }
        />
      </Route>

      <Route path='/po-archive/*' element={<PoArchivePage breadcrumbs={listBreadcrumbs} />}></Route>

      <Route element={<Outlet />}>
        <Route
          path='/po/po-per-item'
          element={
            <>
              <PageTitle breadcrumbs={listBreadcrumbs}>Purchase Order Per Item List</PageTitle>
              <ListPoPerItem />
            </>
          }
        />
      </Route>
      <Route element={<Outlet />}>
        <Route
          path='/po/agreement-per-item'
          element={
            <>
              <PageTitle breadcrumbs={listBreadcrumbs}>Agreement Per Item List</PageTitle>
              <ListAgreementPerItem />
            </>
          }
        />
      </Route>
      <Route element={<Outlet />}>
        <Route
          path='/monitoring'
          element={
            <>
              <PageTitle breadcrumbs={listBreadcrumbs}>Monitoring PO</PageTitle>
              <MonitoringPOPage />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default ContractPage
