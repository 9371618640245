import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import moment from "moment";
import { DatePicker } from "rsuite";
import { useAxiosPost } from "../../../../../../hooks/useAxios";
import { getEnv } from "../../../../../../helpers/procurex";

const JadwalAuction = ({auction, onSave}: any) => {
    const [initialValues, setInitialValues] = useState<any>({});
    const [defaultCatatan, setDefaultCatatan] = useState<any>('HARAP JARINGAN SAUDARA DALAM KEADAAN BAIK DAN STABIL. APABILA TERJADI KESALAHAN JARINGAN, MAKA BUKAN TANGGUNG JAWAB KAMI. KEPUTUSAN BERSIFAT MUTLAK DAN TIDAK DAPAT DIGANGGU GUGAT.');
    const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosPost(
      getEnv('auction') + `/auction/jadwal/${auction?.uuid}`
    );
    const headerFormSchema = Yup.object().shape({
        batas_awal: Yup.string().nullable().required('Batas Awal is required'),
        batas_akhir: Yup.string().nullable().required('Batas Akhir is required'),
        catatan: Yup.string().nullable().required('Catatan is required'),
    });
    const onSubmit = async (values: any) => {
        const payload = {
            _method: 'PUT',
            batas_awal: moment(values?.batas_awal).format('yyyy-MM-DD HH:mm:ss'),
            batas_akhir: moment(values?.batas_akhir).format('yyyy-MM-DD HH:mm:ss'),
            catatan: values?.catatan
        }
        doSubmit(payload,undefined,undefined).then((res: any) => {
            onSave && onSave();
            toast.success(res.meta.message);
        });
    }

    const isFormEnabled = () => {
        return auction?.status === 'draft';
    }

    const initiateData = () => {
        let details = {
            batas_awal: auction?.batas_awal ? new Date(Date.parse(moment(auction?.batas_awal).format('yyyy-MM-DD HH:mm:ss'))) : null,
            batas_akhir: auction?.batas_akhir ? new Date(Date.parse(moment(auction?.batas_akhir).format('yyyy-MM-DD HH:mm:ss'))) : null,
            catatan: auction?.catatan || defaultCatatan,
            status: auction?.status
        }
        setInitialValues(details);
    }

    useEffect(() => {
        initiateData();
    },[])

    return (
      <>
          <Formik
            enableReinitialize
            validationSchema={headerFormSchema}
            initialValues={initialValues}
            onSubmit={onSubmit}
          >
              {({errors, touched, values, setFieldValue}: any) => (
                <Form>
                    <div className={'row'}>
                        <div className={'col-md-6 col-sm-12 col-lg-6'}>
                            <div className='form-group mb-10'>
                                <label className='mb-3'>Batas Awal Auction Submission*</label><br/>
                                <DatePicker
                                  className={"w-100"}
                                  value={values?.batas_awal ?? null}
                                  onChange={(val: any) =>
                                    setFieldValue('batas_awal', val)
                                  }
                                  format='dd-MM-yyyy HH:mm:ss'
                                  readOnly={!isFormEnabled()}
                                />
                                {(errors.batas_awal && touched.batas_awal) && (
                                  <div className="fv-plugins-message-container">
                                      <div className="fv-help-block">{errors.batas_awal}</div>
                                  </div>
                                )}

                            </div>
                            <div className='form-group mb-10'>
                                <label className='form-label mb-3'>Batas Akhir Auction Submission*</label><br/>
                                <DatePicker
                                  className={"w-100"}
                                  value={values?.batas_akhir ?? null}
                                  onChange={(val: any) =>
                                    setFieldValue('batas_akhir',val)
                                  }
                                  format='dd-MM-yyyy HH:mm:ss'
                                  readOnly={!isFormEnabled()}
                                />
                                {(errors.batas_akhir && touched.batas_akhir) && (
                                  <div className="fv-plugins-message-container">
                                      <div className="fv-help-block">{errors.batas_akhir}</div>
                                  </div>
                                )}
                            </div>
                            <div className='mb-10'>
                                <label className='form-label mb-3'>Catatan</label>
                                <Field
                                  required
                                  as={'textarea'}
                                  rows={5}
                                  className='form-control'
                                  name='catatan'
                                  disabled={!isFormEnabled()}
                                />
                            </div>
                            <div className='text-end'>
                                <button
                                  type='submit'
                                  className='btn btn-primary btn-sm'
                                  disabled={isLoading || !isFormEnabled()}
                                >
                                    {isLoading && (
                                      <span className='spinner-border spinner-border-sm me-1'></span>
                                    )}
                                    {!isLoading && <i className='fa fa-save me-2'></i>}
                                    Simpan
                                </button>
                            </div>
                        </div>
                    </div>

                </Form>
              )}
          </Formik>
      </>
    )
};

export { JadwalAuction };
