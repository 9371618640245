import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { KTCard, KTCardBody } from '../../../../../_metronic/helpers'
import TableXWrapper from '../../../../components/shared/TableXWrapper'
import { PageLink, PageTitle } from '../../../../../_metronic/layout/core'
import { darkModeClass } from '../../../../../helpers/utils'
import TableTabs from '../components/TableTabs'
import { useState } from 'react'

const AgreementList = () => {
    const [key, setKey] = useState(0);
    const [activeTab, setActiveTab] = useState(0);
    const [getDraft, setGetDraft] = useState(1)
    const [getActive, setGetActive] = useState(0)

    const tabs = [
        {
            value: 0,
            text: 'Draft',
            total: getDraft
        },
        {
            value: 1,
            text: 'Active',
            total: getActive,
        }
    ]

    const tableColumns = [
        {
            Header: 'Action ',
            accessor: 'id',
            width: 150,
            Cell: (props: any) =>
                <div className='d-flex w-100 justify-content-center'>
                    <OverlayTrigger
                        placement='right'
                        overlay={<Tooltip id='button-tooltip-2'>Detail PO</Tooltip>}
                    >
                        <NavLink to={'/contract-management/agreement/' + props?.cell?.row?.original?.uuid} className='p-3'>
                            <i className={'fa fs-3 fa-pencil text-dark'} />
                        </NavLink>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement='right'
                        overlay={<Tooltip id='button-tooltip-2'>Notes</Tooltip>}
                    >
                        <a href={"javascript:;"} aria-disabled onClick={(e: any) => {
                            // setDetailPO(props?.cell?.row?.original)
                            // setShowModal(true)
                            // e.preventDefault()
                        }} className={`p-3`}>
                            <i className={`fa fs-3 fa-note-sticky ${darkModeClass()}`}></i>
                        </a>
                    </OverlayTrigger>
                </div>
        },
        { Header: 'Catatan', accessor: 'a', width: 170 },
        { Header: 'Status OA', accessor: 'b', width: 170 },
        { Header: 'No OA ProcureX', accessor: 'c', width: 170 },
        { Header: 'No PO SAP', accessor: 'd', width: 150 },
        { Header: 'No tender', accessor: 'e', width: 180 },
        { Header: 'Vendor', accessor: 'f', width: 150 },
        { Header: 'Target Value', accessor: 'g', width: 150 },
        { Header: 'Currency', accessor: 'h', width: 150 },
        { Header: 'Agreement Date', accessor: 'i', width: 150 },
        { Header: 'Validity Start', accessor: 'j', width: 150 },
        { Header: 'Validity End', accessor: 'k', width: 150 },
        { Header: 'Purch. Org', accessor: 'l', width: 150 },
        { Header: 'Purch. Group', accessor: 'm', width: 150 },
    ]

    return (
        <KTCard>
            <TableTabs
                tabs={tabs}
                currentTab={activeTab}
                setCurrentTab={(tabNumber: any) => {
                setActiveTab(tabNumber);
                setKey(key + 1);
                }}
            />

            <KTCardBody className='py-4'>
                <TableXWrapper
                    readonly
                    urlIndex={`https://procurement-dev.pupuk-indonesia.com/mock-api/listPo.json`}
                    columns={tableColumns}
                    disableUrlChanged={true}
                />
            </KTCardBody>
        </KTCard>
    )
}

export default AgreementList;