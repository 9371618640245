import { ThemeModeComponent } from "../_metronic/assets/ts/layout";

export const formatNumber = (v) => {
  if (v) {
    return v.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  } else
    return v;
};

export const darkModeClass = () => {
  return ThemeModeComponent.getMode() === "dark" ? "text-white" : "text-dark";
};

export const listParamsTransformer = (params, isSortFilterValue = false) => {
  if (typeof params !== 'object') {
    return params;
  }

  const {filters,...newParams} = params;

  if (newParams.include) {
    newParams.include = isSortFilterValue
      ? newParams.include.sort().join(',')
      : newParams.include.join(',');
  }

  if (filters === undefined) {
    return newParams
  }

  for (const key in filters) {
    if (filters[key] !== undefined) {
      let value = filters[key];

      if (Array.isArray(value)) {
        value = isSortFilterValue ? value.sort() : value;
        value = value.join(',');
      }

      newParams['filter[' + key + ']'] = value;
    }
  }

  return newParams;
};

export const listParamsTransformerSorted = (params) => listParamsTransformer(params, true);

export const blobToBase64 = blob => {
  const reader = new FileReader();
  return new Promise((resolve, _) => {
    reader.onloadend = (event) => {
      const result = event.target.result.toString()

      resolve(result.replace(/^data:.+;base64,/, ''))
    }
    reader.readAsDataURL(blob);
  });
}
