import axios from 'axios'
import {Field, Form, Formik} from 'formik'
import React, {useState} from 'react'
import {Button, Modal, Spinner} from 'react-bootstrap'
import {useMutation, useQuery} from 'react-query'
import {useParams} from 'react-router-dom'
import {Placeholder} from 'rsuite'
import * as Yup from 'yup'
import {
  MetronicErrorMessage,
  toastError,
  toastSuccess,
} from '../../../../../../../_metronic/helpers'
import {getEnv} from '../../../../../../../helpers/procurex'
import {blobToBase64} from '../../../../../../../helpers/utils'
import useRemoteFileAsObjectUrl from '../../../../../../../hooks/useRemoteFileAsObjectUrl'
import {
  DevExpressRichEdit,
  DevExpressRichEditProps,
} from '../../../../../../components/shared/DevExpressRichEdit'
import SwalX from '../../../../../../components/shared/SwalX'
import {useAuth} from '../../../../../auth'
import {extractUUID} from '../../../../../vendor-management/core/utils'

const TENDER_BASE_URL = getEnv('tender')

export const PengumumanPemenangQueryKey = (proposalTenderUuid: string) => [
  'proposal-tender',
  'detail',
  'berita-acara',
  proposalTenderUuid,
  'pengumuman-pemenang',
]

const Editor = ({
  urlDocx,
  onAfterSaving,
}: {
  urlDocx: string
  onAfterSaving?: DevExpressRichEditProps['onAfterSaving']
}) => {
  const {uuid: proposalTenderUuid} = useParams()
  const base64DocumentQuery = useQuery({
    queryKey: [...PengumumanPemenangQueryKey(proposalTenderUuid), 'base64'],
    queryFn: async () =>
      axios
        .get(urlDocx, {responseType: 'blob'})
        .then(async (response) => blobToBase64(response.data)),
    enabled: !!urlDocx,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })

  const base64Document = base64DocumentQuery.data
  const isReady =
    !base64DocumentQuery.isLoading && !base64DocumentQuery.isRefetching && !!base64Document

  if (!isReady) {
    return <Placeholder.Graph height={800} />
  }

  return (
    <DevExpressRichEdit
      base64Document={base64Document}
      onAfterSaving={onAfterSaving}
      onSaving={async (blob: Blob) => {
        const formData = new FormData()

        formData.append('document', blob)

        const response = await axios.post(
          `${TENDER_BASE_URL}/awarding-announcement/generate-document/v2/${proposalTenderUuid}`,
          formData
        )

        toastSuccess(response.data.meta.message)

        return response
      }}
    />
  )
}

const SignModal = ({
  show,
  onHide,
  mediaUuid,
  otpSentTo,
  onSuccess,
}: {
  show: boolean
  onHide: () => void
  mediaUuid: string
  otpSentTo: string
  onSuccess?: () => void
}) => {
  const {mutate: signMutate, isLoading: isLoadingSign} = useMutation({
    mutationFn: (payload: {otp_code: string}) =>
      axios.post(getEnv('media') + `/media/${mediaUuid}/signing/sign`, payload),
  })

  const {mutate: resendOtp, isLoading: isLoadingResendOtp} = useMutation({
    mutationFn: () => axios.post(getEnv('media') + `/media/${mediaUuid}/signing/request-otp`),
  })

  const isLoading = isLoadingResendOtp || isLoadingSign

  return (
    <Modal show={show} onHide={onHide} centered>
      <Formik
        initialValues={{otp_code: ''}}
        validationSchema={Yup.object().shape({
          otp_code: Yup.string().required().label('OTP code'),
        })}
        onSubmit={(values, formikHelpers) => {
          signMutate(values, {
            onSuccess: (response) => {
              toastSuccess(response.data.meta.message)
              onSuccess && onSuccess()
            },
            onError: (error: any) => {
              if (axios.isAxiosError(error)) {
                if (error.response.status === 422 && error.response.data?.errors) {
                  formikHelpers.setErrors(error.response.data.errors)
                } else {
                  toastError(error.response.data?.meta?.message ?? 'Terjadi kesalahan')
                }
              }
            },
          })
        }}
      >
        {({setErrors}) => (
          <Form>
            <Modal.Body>
              <Modal.Title>Sign Document</Modal.Title>

              <p>Please enter the OTP sent to your phone ({otpSentTo}).</p>

              <Field
                name='otp_code'
                placeholder='OTP code'
                className='form-control'
                autoComplete='off'
              />

              <MetronicErrorMessage name='otp_code' />

              <div className='d-flex justify-content-between mt-4'>
                <Button
                  type='button'
                  variant='secondary'
                  disabled={isLoading}
                  onClick={() => {
                    resendOtp(undefined, {
                      onSuccess: (response) => {
                        toastSuccess(response.data.meta.message)
                      },
                      onError: (error) => {
                        if (axios.isAxiosError(error)) {
                          if (error.response.status === 422 && error.response.data?.errors) {
                            setErrors(error.response.data.errors)
                          } else {
                            toastError(error.response.data?.meta?.message ?? 'Terjadi kesalahan')
                          }
                        }
                      },
                    })
                  }}
                >
                  {isLoadingResendOtp && (
                    <Spinner animation={'border'} size={'sm'} className={'me-2'} />
                  )}
                  Resend OTP
                </Button>

                <Button type='submit' disabled={isLoading}>
                  {isLoadingSign && <Spinner animation={'border'} size={'sm'} className={'me-2'} />}
                  Sign
                </Button>
              </div>
            </Modal.Body>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export const BeritaAcaraPengumumanPemenangTabV2 = ({proposalTender, isOwner}: any) => {
  const {currentUser} = useAuth()
  const {uuid: proposalTenderUuid} = useParams()
  const [isConfirmResetOpen, setIsConfirmResetOpen] = useState(false)
  const [isLoadingReset, setIsLoadingReset] = useState(false)
  const [selectedItemIndex, setSelectedItemIndex] = useState<number | null>(0)
  const [showEditor, setShowEditor] = useState(false)
  const [showSignModal, setShowSignModal] = useState(false)

  const urls = [`${TENDER_BASE_URL}/awarding-announcement/generate-document/${proposalTenderUuid}`]

  const {
    objectUrl,
    sourceUrl,
    isLoading: isLoadingObjectUrl,
    setSourceUrl,
    resetObjectUrl,
  } = useRemoteFileAsObjectUrl(undefined)

  const {
    data: beritaAcara,
    isLoading: isLoadingBeritaAcara,
    refetch: refetchBeritaAcara,
  } = useQuery({
    queryKey: PengumumanPemenangQueryKey(proposalTenderUuid),
    queryFn: async () => axios.get(selectedItem?.url).then((response) => response.data.data),
    enabled: !!urls[selectedItemIndex],
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onSuccess: (data) => {
      setSourceUrl(data.url_pdf)
    },
  })

  const uuid = sourceUrl ? extractUUID(sourceUrl) : undefined
  const {
    data: media,
    isLoading: isLoadingMedia,
    refetch: refetchMedia,
  } = useQuery({
    queryKey: ['media', 'file', uuid],
    queryFn: async () => {
      return axios.get(getEnv('media') + `/media/${uuid}`).then((response) => response.data.data)
    },
    enabled: !!uuid,
    staleTime: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })

  const items = [
    {
      title: 'Pengumuman Pemenang',
      subTitle: '',
      url: urls[0],
      url_submit_ba_note: `${TENDER_BASE_URL}/awarding-announcement/generate-document/${proposalTenderUuid}`,
      disabled: false,
      editor_disabled:
        !isOwner ||
        !['Draft', 'Rejected'].includes(proposalTender?.status_detail) ||
        beritaAcara?.has_been_edited,
      can_be_reset: isOwner && proposalTender?.status_detail in ['Draft', 'Rejected'],
      can_be_signed:
        currentUser?.id === media?.signed_by_user_id && media?.sign_status === 'PERURI_SUBMITTED',
      isShowPIC: false,
    },
  ]
  const selectedItem = selectedItemIndex !== null ? items[selectedItemIndex] : null

  if (showEditor) {
    return (
      <>
        <div className='row mb-3'>
          <div className='col-12'>
            <button
              className={'btn btn-danger btn-sm'}
              type={'button'}
              onClick={() => {
                setShowEditor(false)
              }}
            >
              <i className={'fa fa-arrow-left'}></i> Kembali
            </button>
          </div>
        </div>

        <div className='row'>
          <div className='col-12'>
            <Editor
              urlDocx={beritaAcara?.url_docx}
              onAfterSaving={async () => {
                setShowEditor(false)
                resetObjectUrl()
                await refetchBeritaAcara()
              }}
            />
          </div>
        </div>
      </>
    )
  }
  return (
    <div className={'row'}>
      <div className={'col-md-3'}>
        {items &&
          items?.map((item: any, index: number) => (
            <div className={'mb-4 btn-group-vertical w-100'}>
              <div className='btn-group mb-2' role='group' aria-label='Basic example'>
                <button
                  key={index}
                  className={'btn btn-sm w-100'}
                  style={{
                    backgroundColor: `${
                      selectedItemIndex === index ? 'var(--bs-primary)' : 'var(--bs-secondary)'
                    }`,
                    color: `${selectedItemIndex === index ? 'white' : 'black'}`,
                    padding: '11px',
                    border: '#333',
                  }}
                  disabled={
                    item.url === null ||
                    isLoadingObjectUrl ||
                    isLoadingBeritaAcara ||
                    item?.disabled
                  }
                  onClick={() => setSelectedItemIndex(index)}
                >
                  <div>{item?.title}</div>
                  <div>{item?.subTitle}</div>
                </button>
              </div>

              <div className='btn-group' role='group' aria-label='Basic example'>
                <button
                  className={'btn btn-primary btn-sm'}
                  style={{
                    backgroundColor: `${
                      selectedItemIndex === index ? 'var(--bs-primary)' : 'var(--bs-secondary)'
                    }`,
                    color: `${selectedItemIndex === index ? 'white' : 'black'}`,
                    padding: '11px',
                    border: '#333',
                  }}
                  onClick={() => {
                    setSelectedItemIndex(index)
                    setShowEditor(true)
                  }}
                  disabled={
                    item.url === null ||
                    isLoadingObjectUrl ||
                    isLoadingBeritaAcara ||
                    !isOwner ||
                    item?.disabled ||
                    item?.editor_disabled
                  }
                >
                  <i
                    className={'fa fa-file-edit'}
                    style={{
                      color: `${selectedItemIndex === index ? 'white' : 'black'}`,
                    }}
                  ></i>
                </button>

                <button
                  className={'btn btn-danger btn-sm'}
                  style={{
                    backgroundColor: `${
                      selectedItemIndex === index ? 'var(--bs-danger)' : 'var(--bs-secondary)'
                    }`,
                    color: `${selectedItemIndex === index ? 'white' : 'black'}`,
                    padding: '11px',
                    border: '#333',
                  }}
                  onClick={() => {
                    setSelectedItemIndex(index)
                    setIsConfirmResetOpen(true)
                  }}
                  disabled={
                    item.url === null ||
                    isLoadingObjectUrl ||
                    isLoadingBeritaAcara ||
                    !isOwner ||
                    item?.disabled ||
                    item?.can_be_reset
                  }
                >
                  <i
                    className={'fa fa-undo'}
                    style={{
                      color: `${selectedItemIndex === index ? 'white' : 'black'}`,
                    }}
                  ></i>
                </button>

                <button
                  className={'btn btn-primary btn-sm'}
                  style={{
                    backgroundColor: `${
                      selectedItemIndex === index ? 'var(--bs-primary)' : 'var(--bs-secondary)'
                    }`,
                    color: `${selectedItemIndex === index ? 'white' : 'black'}`,
                    padding: '11px',
                    border: '#333',
                  }}
                  onClick={() => {
                    setSelectedItemIndex(index)
                    setShowSignModal(true)
                  }}
                  disabled={
                    item.url === null ||
                    isLoadingObjectUrl ||
                    isLoadingBeritaAcara ||
                    isLoadingMedia ||
                    item?.disabled ||
                    !item?.can_be_signed
                  }
                >
                  <i
                    className={'fa fa-signature'}
                    style={{
                      color: `${selectedItemIndex === index ? 'white' : 'black'}`,
                    }}
                  ></i>
                </button>
              </div>
            </div>
          ))}
      </div>
      <div className={'col-md-9'}>
        {isLoadingObjectUrl || isLoadingBeritaAcara ? (
          <Placeholder.Graph height={700} active={isLoadingObjectUrl || isLoadingBeritaAcara} />
        ) : selectedItem && objectUrl ? (
          <>
            <object
              width='100%'
              style={{
                height: '700px',
              }}
              data={objectUrl}
              type='application/pdf'
            >
              {' '}
            </object>
          </>
        ) : (
          <div className={'bg-secondary w-100'} style={{height: 700, opacity: '0.6'}}></div>
        )}
      </div>

      <SwalX
        title='Konfirmasi Reset'
        message='Tindakan ini akan menghilangkan perubahan dokumen!'
        btnConfirmLabel='Ya, Reset'
        btnConfirmIcon='fa fa-undo'
        show={isConfirmResetOpen}
        loading={isLoadingReset}
        onHide={() => setIsConfirmResetOpen(false)}
        onSubmit={async () => {
          setIsLoadingReset(true)
          await axios.post(
            `${TENDER_BASE_URL}/awarding-announcement/generate-document/reset/${proposalTenderUuid}`
          )
          setIsLoadingReset(false)
          setIsConfirmResetOpen(false)
          resetObjectUrl()
          await refetchBeritaAcara()
        }}
      />

      <SignModal
        show={showSignModal}
        onHide={() => setShowSignModal(false)}
        mediaUuid={media?.uuid}
        otpSentTo={media?.otp_sent_to}
        onSuccess={async () => {
          setShowSignModal(false)
          resetObjectUrl()
          await refetchMedia()
        }}
      />
    </div>
  )
}
