import axios, {AxiosResponse} from 'axios'
import * as React from 'react'
import {createContext, Fragment, useCallback, useContext, useState} from 'react'
import {useIntl} from 'react-intl'
import {Link, NavLink, NavLinkProps, Outlet, Route, Routes, useParams} from 'react-router-dom'
import Select from 'react-select'
import {toastError} from '../../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {formatDateTimeToDBFormat, getEnv} from '../../../../helpers/procurex'
import PreviewAttachment from '../../../components/PreviewAttachment'
import {getSelectStylesControl} from '../../../components/shared/form/SelectX'
import SwalX from '../../../components/shared/SwalX'
import TableXWrapper from '../../../components/shared/TableXWrapper'
import {PurchaseOrderModel} from '../core/_models'
import {PO_ARCHIVE_SOURCE, usePurchaseOrderQuery} from '../core/_queries'

//region Context & Provider
type PoArchiveDetailContextValue = {
  purchaseOrder: PurchaseOrderModel
}

const PoArchiveDetailContext = createContext<PoArchiveDetailContextValue>({
  purchaseOrder: undefined,
})

const PoArchiveDetailProvider = ({
  children,
}: {
  children: React.ReactNode | ((value: PoArchiveDetailContextValue) => React.ReactNode)
}) => {
  const {uuid} = useParams()
  const {data: purchaseOrder} = usePurchaseOrderQuery(uuid)

  return (
    <PoArchiveDetailContext.Provider value={{purchaseOrder}}>
      {typeof children === 'function' ? children({purchaseOrder}) : children}
    </PoArchiveDetailContext.Provider>
  )
}
//endregion

const HistoryTable = () => {
  const {purchaseOrder} = useContext(PoArchiveDetailContext)

  if (!purchaseOrder?.id) {
    return <></>
  }

  return (
    <div className='card'>
      <TableXWrapper
        title='List Files'
        readonly
        hideSelection
        urlIndex={`${getEnv('media')}/archive/log`}
        defaultFilter={`sort=-created_at&filter[source]=${PO_ARCHIVE_SOURCE}&filter[object_id]=${purchaseOrder.id}`}
        columns={[
          {
            Header: 'Employee No',
            accessor: 'causer.emp_no',
            searchable: false,
            orderable: false,
            disableSortBy: true,
            width: 150,
          },
          {
            Header: 'Name',
            accessor: 'causer.name',
            searchable: false,
            orderable: false,
            disableSortBy: true,
            width: 150,
          },
          {
            Header: 'Jabatan',
            accessor: 'causer.emp_grade_title',
            searchable: false,
            orderable: false,
            disableSortBy: true,
            width: 150,
          },
          {
            Header: 'Status',
            accessor: 'description',
            orderable: false,
            disableSortBy: true,
            width: 450,
          },
          {
            Header: 'Tanggal',
            accessor: 'created_at',
            searchable: false,
            width: 150,
            Cell: ({value}) => (value ? formatDateTimeToDBFormat(value) : ''),
          },
        ]}
      />
    </div>
  )
}

const DetailTable = () => {
  const intl = useIntl()
  const {purchaseOrder} = useContext(PoArchiveDetailContext)
  const [tableKey, setTableKey] = useState(0)
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false)
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)
  const [deletedItem, setDeletedItem] = useState(undefined)
  const [previewAttachment, setPreviewAttachment] = useState(null)

  const onDeleteItem = useCallback(async () => {
    setIsLoadingDelete(true)
    await axios.delete(`${getEnv('media')}/archive/${deletedItem.uuid}`)
    setIsLoadingDelete(false)
    setIsConfirmDeleteOpen(false)
    setTableKey((key) => key + 1)
  }, [deletedItem])

  if (!purchaseOrder?.id) {
    return <></>
  }

  return (
    <div className='card'>
      <TableXWrapper
        key={tableKey}
        title='List Files'
        readonly
        hideSelection
        urlIndex={`${getEnv('media')}/archive`}
        defaultFilter={`filter[source]=${PO_ARCHIVE_SOURCE}&filter[object_id]=${purchaseOrder?.id}`}
        columns={[
          {
            Header: intl.formatMessage({id: 'Aksi'}),
            accessor: 'uuid',
            searchable: false,
            orderable: false,
            disableSortBy: true,
            width: 100,
            Cell: ({row}) => {
              // noinspection RequiredAttributes
              return (
                <div className='d-flex w-100 justify-content-center gap-2'>
                  <button
                    type='button'
                    className='btn btn-flush btn-active-icon-primary btn-active-text-primary'
                    onClick={() => {
                      setPreviewAttachment(row.original.media.url)
                    }}
                  >
                    <i className='fa fs-3 fa-eye'></i>
                  </button>

                  <button
                    type='button'
                    className='btn btn-flush btn-active-icon-danger btn-active-text-danger'
                    onClick={() => {
                      setDeletedItem(row.original)
                      setIsConfirmDeleteOpen(true)
                    }}
                  >
                    <i className='fa fs-3 fa-trash'></i>
                  </button>
                </div>
              )
            },
          },
          {
            Header: '#',
            accessor: 'id',
            searchable: false,
            orderable: false,
            disableSortBy: true,
            width: 50,
            Cell: ({row}) => row.index + 1,
          },
          {
            Header: 'PO',
            accessor: 'media.properties.po_sap_no',
            searchable: false,
            width: 250,
          },
          {
            Header: 'Category',
            accessor: 'media.properties.category',
            width: 250,
          },
          {
            Header: 'Description',
            accessor: 'media.properties.description',
            width: 500,
          },
        ]}
      />

      <SwalX
        title={intl.formatMessage({id: 'DATATABLE.CONFIRM_DELETE'})}
        message={intl.formatMessage({id: 'DATATABLE.MESSAGE_DELETE'})}
        show={isConfirmDeleteOpen}
        loading={isLoadingDelete}
        onHide={() => setIsConfirmDeleteOpen(false)}
        onSubmit={onDeleteItem}
      />

      <PreviewAttachment previewExt={previewAttachment} onHide={setPreviewAttachment} />
    </div>
  )
}

const getFilenameFromResponse = (response: AxiosResponse) => {
  let headerLine = response.headers['content-disposition']

  if (!headerLine) {
    return null
  }

  let startFileNameIndex = headerLine?.indexOf('"') + 1
  let endFileNameIndex = headerLine?.lastIndexOf('"')

  return headerLine?.substring(startFileNameIndex, endFileNameIndex)
}

const MergedArchiveDownloader = () => {
  const {purchaseOrder} = useContext(PoArchiveDetailContext)
  const [category, setCategory] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const onClick = useCallback(async () => {
    try {
      setIsLoading(true)

      if (!purchaseOrder?.id) {
        return
      }

      const url = `${getEnv('media')}/archive/merged-file/${PO_ARCHIVE_SOURCE}/${purchaseOrder.id}`
      const response = await axios.get(url, {
        responseType: 'blob',
        params: {
          'filter[media.properties.category]': category,
        },
      })

      const blobUrl = window.URL.createObjectURL(
        new Blob([response.data], {type: 'application/pdf'})
      )
      const link = document.createElement('a')

      link.download = getFilenameFromResponse(response) ?? `Arsip PO - ${category ?? 'ALL'}.pdf`
      link.href = blobUrl
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (error) {
      const data = error.response.data
      if (data instanceof Blob && data.type === 'application/json') {
        data.text().then((text) => {
          const errorData = JSON.parse(text)
          toastError(errorData.meta?.message || 'Terjadi kesalahan')
        })
      } else {
        toastError(error.message)
      }
    } finally {
      setIsLoading(false)
    }
  }, [category, purchaseOrder?.id])

  // noinspection JSFileReferences
  return (
    <div className='card'>
      <div className='card-body'>
        <label htmlFor='' className='form-label'>
          Category Document
        </label>
        <div className='d-flex gap-3'>
          <div className='flex-grow-1'>
            <Select
              styles={{
                control: (baseStyles, props) => getSelectStylesControl(baseStyles, props),
                menu: (baseStyles) => ({...baseStyles, zIndex: 3}),
              }}
              options={[
                {value: '', label: 'All'},
                {value: 'PO', label: 'PO'},
                {value: 'TENDER', label: 'TENDER'},
                {value: 'LAINNYA', label: 'LAINNYA'},
              ]}
              defaultValue={{value: null, label: 'All'}}
              onChange={(option) => setCategory(option.value)}
              isDisabled={isLoading}
            />
          </div>

          <button
            type='button'
            className='btn btn-primary shadow-sm'
            disabled={isLoading}
            onClick={onClick}
          >
            Download Merged
          </button>
        </div>

        {/*{!isLoading && objectUrl !== '' && (*/}
        {/*  <PreviewAttachment previewExt={objectUrl} onHide={() => setSourceUrl('')} />*/}
        {/*)}*/}
      </div>
    </div>
  )
}

interface Tab {
  text: string
  url: string
  icon?: string
  disabled?: boolean
  nav_link_props?: Omit<NavLinkProps & React.RefAttributes<HTMLAnchorElement>, 'to'>
}

const Tabs = ({tabs}: {tabs: Tab[]}) => {
  const defaultClassName = 'p-5 text-center d-flex align-items-center flex-column gap-2 flex-fill'
  return (
    <div className='card my-3' style={{boxShadow: '0px 0px 30px rgba(56, 71, 109, 0.22)'}}>
      <div className='card-body p-0'>
        <div className='d-flex justify-content-between w-100 overflow-auto align-items-center rounded-lg'>
          {tabs.map((tab, index) => (
            <Fragment key={index}>
              {tab.disabled ? (
                <div className={`${defaultClassName} bg-secondary`}>
                  <i className={`fs-2 ${tab.icon}`}></i> {tab.text}
                </div>
              ) : (
                <NavLink
                  to={tab.url}
                  className={({isActive}) =>
                    `${defaultClassName} ${
                      isActive ? 'fw-bold text-info border-info border-3 border-bottom' : ''
                    }`
                  }
                  {...(tab.nav_link_props ?? {})}
                >
                  {({isActive}) => (
                    <>
                      <i className={`fs-2 ${tab.icon} ${isActive ? 'text-info' : ''}`}></i>{' '}
                      {tab.text}
                    </>
                  )}
                </NavLink>
              )}
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  )
}

export const PoArchiveDetailPage = ({breadcrumbs}: {breadcrumbs?: Array<PageLink>}) => {
  const intl = useIntl()
  const {uuid} = useParams()
  const tabs: Tab[] = [
    {
      icon: 'fa-solid fa-box-archive',
      url: `../${uuid}`,
      text: 'Purchase Order',
      nav_link_props: {end: true},
    },
    {
      icon: 'fa-solid fa-clock-rotate-left fa-fw',
      url: `../${uuid}/history`,
      text: 'History',
    },
  ]

  breadcrumbs = breadcrumbs.concat([
    {
      title: 'Arsip PO',
      path: '/contract-management/po-archive',
      isSeparator: false,
      isActive: false,
    },
  ])

  return (
    <PoArchiveDetailProvider>
      {({purchaseOrder}) => (
        <>
          <PageTitle breadcrumbs={breadcrumbs}>
            {purchaseOrder ? `${purchaseOrder.no}` : 'Arsip PO'}
          </PageTitle>

          <div className={'mb-4 d-flex'}>
            <div>
              <Link to={'../'} className={'btn btn-danger btn-sm'}>
                <i className={'fa fa-arrow-left'}></i>
                {intl.formatMessage({id: 'Kembali ke list'})}
              </Link>
            </div>
          </div>

          <Tabs tabs={tabs} />

          <Routes>
            <Route element={<Outlet />}>
              <Route
                index
                element={
                  <div className='d-flex flex-column gap-3'>
                    <MergedArchiveDownloader />
                    <DetailTable />
                  </div>
                }
              />
              <Route path='/history' element={<HistoryTable />} />
            </Route>
          </Routes>
        </>
      )}
    </PoArchiveDetailProvider>
  )
}
