import {KTCard} from '../../../../../../_metronic/helpers'
import {useAuth} from '../../../../auth'
import TableTabs from '../../../../mr-sr/request/list/components/header/TableTabs'
import React, {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useClarificationTenderItemCountsQuery} from '../../evaluation/core/_queries'
import {ToolbarInterface, useProposalTender} from '../core/_proposalTenderProvider'

export const useEvaluasiUserNeedBuyerAction = (proposalTenderUuid: string) => {
  const {currentUser} = useAuth()
  const query = useClarificationTenderItemCountsQuery(
    currentUser.username,
    {
      groups: {
        on_process: {
          current_user: currentUser?.username,
          proposal_tender_uuid: proposalTenderUuid,
          status: ['Submit Approval', 'Receive'],
        },
      }
    },
    {enabled: !!currentUser?.username && !!proposalTenderUuid},
    'evaluasi-user-need-buyer-action',
  )

  return {isEvaluasiBuyerNeedAction: query.data?.on_process > 0, query}
}



const ToolbarEvaluasi = () => {
  const intl = useIntl();
  const {proposalTender, toolbarEvaluasi, setToolbarEvaluasi} = useProposalTender()
  const {isEvaluasiBuyerNeedAction} = useEvaluasiUserNeedBuyerAction(proposalTender?.uuid)
  const allowedStatus = [
    'Evaluasi Administrasi',
    'Negotiation',
    'Laporan Pengadaan',
    'Penawaran Komersil',
    'Evaluasi Komersil',
    'Pengumuman Pemenang',
    'Penunjukan Pemenang',
    'Penawaran Teknikal',
    'Purchase Order',
  ]
  const isEvaluasiEnabled = allowedStatus.includes(proposalTender?.status)
  const tabs = [
    {
      value: 0,
      text: intl?.formatMessage({id: 'Evaluasi Penawaran'}),
      total: 0,
    },
    {
      value: 1,
      text: intl?.formatMessage({id: 'Evaluasi User'}),
      total: 0,
      needAction: isEvaluasiEnabled && isEvaluasiBuyerNeedAction,
      disabled: !isEvaluasiEnabled,
    },
    {
      value: 2,
      text: intl?.formatMessage({id: 'Klarifikasi Vendor'}),
      total: 0,
      disabled: !isEvaluasiEnabled,
    },
  ]

  return (
    <KTCard className={'mt-0 mb-1'}>
      <TableTabs
        tabs={tabs}
        currentTab={toolbarEvaluasi?.currentTab}
        setCurrentTab={(tabNumber: any) => {
          // handleTabChange(tabNumber)
          setToolbarEvaluasi((prevValue: ToolbarInterface) => {
            return {
              ...prevValue,
              currentTab: tabNumber,
            }
          })
        }}
        hideBadge={true}
      />
    </KTCard>
  )
}

const ToolbarRightLayout = () => {
  const {toolbarEvaluasi} = useProposalTender();
  const isShowEvaluasi = process.env.REACT_APP_IS_EVALUATION_USER === 'true';

  useEffect(() => {
    console.log('ToolbarEvaluasi:', toolbarEvaluasi);
  }, [toolbarEvaluasi])

  return (
    <>
      {(isShowEvaluasi && toolbarEvaluasi.show) && (
        <ToolbarEvaluasi />
      )}
    </>
  )
}

export default ToolbarRightLayout;