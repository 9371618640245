import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import MasterDataRoutes from './MasterDataRoutes'
import ProcurexRoutes from './ProcurexRoutes'
import TenderingRoutes from './TenderingRoutes'
import PadiRoutes from "./PadiRoutes";
import AuctionRoutes from "./AuctionRoutes";
import ContractPage from '../modules/contract-management/ContractPage'
import StockviewRoutes from './StockviewRoutes'
import AuctionV2Routes from "./AuctionV2Routes";
import PenerimaanRoutes from "./PenerimaanRoutes";
import { VersioningPage } from "../pages/dashboard/VersioningPage";
import InvoiceRoutes from './InvoiceRoutes'

const PrivateRoutes = () => {
  const MrSrPage = lazy(() => import('../modules/mr-sr/MrSrPage'))
  const VendorPage = lazy(() => import('../modules/vendor-management/VendorPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='release-notes' element={<VersioningPage />} />
        {/*<Route path='master-data/*' element={<MasterDataRoutes />} />*/}
        <Route path='procure-x/*' element={<ProcurexRoutes />} />
        <Route path='tender/*' element={<TenderingRoutes />} />
        <Route path='padi/*' element={<PadiRoutes />} />
        <Route path='auction/*' element={<AuctionRoutes />} />
        <Route path='auction-v2/*' element={<AuctionV2Routes />} />
        <Route path='penerimaan/*' element={<PenerimaanRoutes />} />
        <Route path='invoice/*' element={<InvoiceRoutes />} />
        <Route
          path='builder'
          element={
            <SuspensedView>
              <BuilderPageWrapper />
            </SuspensedView>
          }
        />
        {/* Lazy Modules */}
        {/*<Route path='apps/user-management/*' element={<SuspensedView><UsersPage /></SuspensedView>}/>*/}
        <Route
          path='mr-sr/*'
          element={
            <SuspensedView>
              <MrSrPage />
            </SuspensedView>
          }
        />
        <Route
          path='vendor/*'
          element={
            <SuspensedView>
              <VendorPage />
            </SuspensedView>
          }
        />
        <Route
          path='contract-management/*'
          element={
            <SuspensedView>
              <ContractPage />
            </SuspensedView>
          }
        />
        <Route
          path='stockview/*'
          element={
            <SuspensedView>
              <StockviewRoutes />
            </SuspensedView>
          }
        />
        <Route
          path='account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route path='master-data/*' element={<MasterDataRoutes />} />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
