import React, {useEffect, useState} from 'react'
import {useMutation, useQuery} from 'react-query'
import axios from 'axios'
import {formatDateTimeToDBFormat, getEnv} from '../../../../../../helpers/procurex'
import {useProposalTender} from '../core/_proposalTenderProvider'
import {Nav, Placeholder} from 'rsuite'
import {FetchListParams} from '../../../../../../_models'
import {listParamsTransformerSorted} from '../../../../../../helpers/utils'
import {
  ClarificationTenderItemFilters,
  useClarificationTenderItemsQuery,
} from '../../evaluation/core/_queries'

const API_URL = getEnv('tender')
// const API_URL = "http://procurex-tender.test/api"

//region vendor
const EvaluasiKlarifikasiVendor = ({vendor_id, clarificationType}) => {
  const {proposalTenderUuid} = useProposalTender()
  const [clarificationList,setClarificationList] = useState([] as any)
  const {mutate, isLoading} = useMutation({
    mutationKey: "getClarificationPerVendor",
    mutationFn: ({uuid}:any) => axios.get(
      `${API_URL}/clarification/vendor/latest-all-vendors/${uuid}?include=items.syaratEvaluation.syarat,items.quotationItem.serviceLines,items.quotationItem.quotation,items.attachment,latestClarification.items.attachment&filter[vendor_id]=${vendor_id}`
    ),
    onSuccess:(res)=>{
      setClarificationList(res?.data?.data[0]?.latest_clarification?.items?.filter(v =>
        v?.syarat_evaluation?.syarat?.type === clarificationType ||
        (clarificationType === 'administration' && v?.syarat_evaluation?.syarat?.type === 'technical' && v?.quotation_item === null)
      ))
    }
  })

  useEffect(() => {
    mutate({
      uuid: proposalTenderUuid
    })
  }, [proposalTenderUuid])

  if(isLoading) {
    return <Placeholder.Grid columns={4} rows={5} active={true} />
  }

  return (
    <>
      <table className={"table table-bordered table-striped table-header-gray"}>
        <thead>
        <tr>
          <th className={'border px-2'}
            //@ts-ignore
              width={10}
          >
            <strong>No</strong>
          </th>
          <th className={'border px-2'}
            //@ts-ignore
              width={200}
          >
            <strong>Deskripsi</strong>
          </th>
          <th className={'border px-2'}>
            <strong>Buyer</strong>
          </th>
          <th className={'border px-2'}>
            <strong>Vendor</strong>
          </th>
        </tr>
        </thead>
        <tbody>

        {clarificationList?.length === 0 && (
          <tr>
            <td colSpan={4} className={"text-center"}>
              Tidak ada data tersedia
            </td>
          </tr>
        )}

        {clarificationList?.map((clarificationItem: any, clarificationIndex: number) => {
          return (
            <tr key={`clarificationRow-${clarificationType}-${clarificationIndex}`}>
              <td className={'border px-2'}>{clarificationIndex + 1}</td>
              <td className={'border px-2'}>
                {clarificationItem?.syarat_evaluation?.syarat?.description}
              </td>
              <td className={'border px-2'}>
                <div className={'border-bottom'}>
                  {clarificationItem?.buyer_note}
                </div>
                <div className={'mt-2'}>
                  <a href={'javascript:;'}>
                    <strong>
                      <small>
                        <i className={'fa fa-chevron-right me-2'}></i> Lihat File
                      </small>
                    </strong>
                  </a>
                  <div className={'text-start'}>
                    <small className={'text-muted'}>
                      {formatDateTimeToDBFormat(clarificationItem?.created_at)}
                    </small>
                  </div>
                </div>
              </td>
              <td className={'border px-2'}>
                <div className={'border-bottom'}>Notes 1</div>
                <div className={'mt-2'}>
                  <a href={'javascript:;'}>
                    <strong>
                      <small>
                        <i className={'fa fa-chevron-right me-2'}></i> Lihat File
                      </small>
                    </strong>
                  </a>
                  <div className={'text-start'}>
                    <small className={'text-muted'}>
                      {formatDateTimeToDBFormat(clarificationItem?.created_at)}
                    </small>
                  </div>
                </div>
              </td>

            </tr>
          )
        })}

        </tbody>
      </table>
    </>
  )
}

const EvaluasiKlarifikasiAdministrasiTeknisList = ({vendor_id, clarificationType}: any) => {

  const [selectedTab, setSelectedTab] = useState("0")
  // @ts-ignore
  return (
    <div className={"border-top my-4"}>
      <div>
        <div className={'mt-4 mb-2'}>
          <strong>Clarification</strong>
        </div>
        <Nav
          defaultActiveKey="Home"
          appearance={'tabs'}
          onSelect={(e)=>setSelectedTab(e)}
          activeKey={selectedTab}
        >
          <Nav.Item eventKey="0">Evaluator / End User</Nav.Item>
          <Nav.Item eventKey="1">Vendor</Nav.Item>
        </Nav>

        <div className={"py-2"}>
          {selectedTab === "0" && (
            <EvaluasiKlarifikasiEvaluatorEndUser vendor_id={vendor_id} clarificationType={clarificationType} />
          )}
          {selectedTab === "1" && (
            <EvaluasiKlarifikasiVendor vendor_id={vendor_id} clarificationType={clarificationType} />
          )}
        </div>


      </div>
    </div>
  )
}

const EvaluasiKlarifikasiTeknisVendor = ({vendor_id}) => {
  const {proposalTenderUuid} = useProposalTender()
  const [clarificationList,setClarificationList] = useState([] as any)
  const {mutate, isLoading} = useMutation({
    mutationKey: "getClarificationPerVendor",
    mutationFn: ({uuid}:any) => axios.get(
      `${API_URL}/clarification/vendor/latest-all-vendors/${uuid}?include=items.syaratEvaluation.syarat,items.quotationItem.serviceLines,items.quotationItem.quotation,items.attachment,latestClarification.items.attachment&filter[vendor_id]=${vendor_id}`
    ),
    onSuccess:(res)=>{
      setClarificationList(res?.data?.data[0]?.latest_clarification?.items?.filter((v)=>v?.quotation_item !== null))
      console.log("asdf",res?.data)
    }
  })

  useEffect(() => {
    mutate({
      uuid: proposalTenderUuid
    })
  }, [proposalTenderUuid])

  if(isLoading) {
    return <Placeholder.Grid columns={4} rows={5} active={true} />
  }

  return (
    <>
      <table className={"table table-bordered table-striped table-header-gray"}>
        <thead>
        <tr>
          <th className={'border px-2'}
            //@ts-ignore
              width={10}
          >
            <strong>No</strong>
          </th>
          <th className={'border px-2'}
            //@ts-ignore
              width={200}
          >
            <strong>Deskripsi</strong>
          </th>
          <th className={'border px-2'}>
            <strong>Buyer</strong>
          </th>
          <th className={'border px-2'}>
            <strong>Vendor</strong>
          </th>
        </tr>
        </thead>
        <tbody>

        {clarificationList?.length === 0 && (
          <tr>
            <td colSpan={4} className={"text-center"}>
              Tidak ada data tersedia
            </td>
          </tr>
        )}

        {clarificationList?.map((clarificationItem: any, clarificationIndex: number) => {
          return (
            <tr key={`clarificationRow-teknis-${clarificationIndex}`}>
              <td className={'border px-2'}>{clarificationIndex + 1}</td>
              <td className={'border px-2'}>
                {clarificationItem?.quotation_item?.short_text}
              </td>
              <td className={'border px-2'}>
                <div className={'border-bottom'}>
                  {clarificationItem?.buyer_note}
                </div>
                <div className={'mt-2'}>
                  <a href={'javascript:;'}>
                    <strong>
                      <small>
                        <i className={'fa fa-chevron-right me-2'}></i> Lihat File
                      </small>
                    </strong>
                  </a>
                  <div className={'text-start'}>
                    <small className={'text-muted'}>
                      {formatDateTimeToDBFormat(clarificationItem?.created_at)}
                    </small>
                  </div>
                </div>
              </td>
              <td className={'border px-2'}>
                <div className={'border-bottom'}>Notes 1</div>
                <div className={'mt-2'}>
                  <a href={'javascript:;'}>
                    <strong>
                      <small>
                        <i className={'fa fa-chevron-right me-2'}></i> Lihat File
                      </small>
                    </strong>
                  </a>
                  <div className={'text-start'}>
                    <small className={'text-muted'}>
                      {formatDateTimeToDBFormat(clarificationItem?.created_at)}
                    </small>
                  </div>
                </div>
              </td>

            </tr>
          )
        })}

        </tbody>
      </table>
    </>
  )
}
//endregion

//region Evaluator / End user
const EvaluasiKlarifikasiEvaluatorEndUserFinalResult = ({
  proposalTenderUuid,
  vendorId,
  clarificationType,
}: {
  proposalTenderUuid: string,
  vendorId: string
  clarificationType: string
}) => {
  const fetchParams = listParamsTransformerSorted({
    perPage: 1,
    sort: '-tender_item_updated_at',
    filters: {
      proposal_tender_uuid: proposalTenderUuid,
      tender_item_status: 'Complete',
      vendor_id: vendorId,
      is_category_administration: clarificationType === 'administration' ? true : undefined,
      is_category_technical: clarificationType === 'technical' ? true : undefined,
      is_category_commercial: clarificationType === 'commercial' ? true : undefined,
    },
  })

  const {data: finalResult} = useQuery(
    ['clarification-evaluator-results', fetchParams],
    () =>
      axios
        .get(`${API_URL}/clarification/evaluator/result`, {
          params: fetchParams,
        })
        .then((response) => response.data),
    {
      retry: false,
      select: (response) => (Array.isArray(response.data) ? response.data[0] : null),
    }
  )

  return (
    <table className={'table table-header-gray mt-4'}>
      <tbody>
        <tr>
          <td className={'px-2 border'} width={175}>
            <strong>Evaluator</strong>
          </td>
          <td width={5} className={'border'}>
            :
          </td>
          <td className={'px-2 border'} width={300}>
            {finalResult?.updated_by
              ? `${finalResult.updated_by.name} (${finalResult.updated_by.username})`
              : '-'}
          </td>
          <td className={'px-2 border'}>
            <strong>Waktu</strong>
          </td>
          <td className={'border'} width={5}>
            :
          </td>
          <td className={'px-2 border'}>
            {finalResult?.updated_by ? formatDateTimeToDBFormat(finalResult.updated_at) : '-'}
          </td>
        </tr>
        <tr>
          <td className={'px-2 border'} width={100}>
            <strong>Hasil Evaluasi Final</strong>
          </td>
          <td width={5} className={'border'}>
            :
          </td>
          <td className={'px-2 border'} colSpan={4}>
            {finalResult?.status ? (finalResult.status) : '-'}
          </td>
        </tr>
        <tr>
          <td className={'px-2 border'} width={100}>
            <strong>Catatan Hasil Evaluasi</strong>
          </td>
          <td width={5} className={'border'}>
            :
          </td>
          <td className={'px-2 border'} colSpan={4}>
            {finalResult?.note ? (finalResult.note) : '-'}
          </td>
        </tr>
      </tbody>
    </table>
  )
}

const EvaluasiKlarifikasiEvaluatorEndUserParamResults = ({
  proposalTenderUuid,
  vendor_id,
  clarificationType,
}: {
  proposalTenderUuid: string
  vendor_id: string
  clarificationType: string
}) => {
  const fetchParams: FetchListParams<ClarificationTenderItemFilters> = {
    perPage: 100,
    sort: 'created_at',
    filters: {
      status: ['Complete'],
      proposal_tender_uuid: proposalTenderUuid,
      with_vendor_id: vendor_id,
      is_category_administration: clarificationType === 'administration' ? true : undefined,
      is_category_technical: clarificationType === 'technical' ? true : undefined,
      is_category_commercial: clarificationType === 'commercial' ? true : undefined,
    },
  }

  const {data: tenderItems} = useClarificationTenderItemsQuery(
    {
      retry: false,
    },
    fetchParams
  )

  let counter = 1;

  return (
    <table className={'table table-bordered table-striped table-header-gray mt-4'}>
      <thead>
      <tr>
        <td className={'px-2 border text-center'} width={10}>
          <strong>No</strong>
        </td>
        <td className={'px-2 border text-center'} width={170}>
          <strong>Item PR</strong>
        </td>
        <td className={'px-2 border text-start'} width={200}>
          <strong>Parameter & Hasil</strong>
        </td>
        <td className={'px-2 border text-start'}>
          <strong>Catatan</strong>
        </td>
      </tr>
      </thead>
      <tbody>
      {tenderItems?.map((tenderItem) =>
        tenderItem.evaluator_results.map((evaluatorResult) =>
          evaluatorResult
            .params
            .filter(param => param.category === null || param.category === clarificationType)
            .map((param, paramIndex) => (
              <tr key={param.id}>
                <td className={'px-2 border text-center'}>{counter++}</td>
                <td className={'px-2 border text-start'}>
                  {paramIndex === 0 ? `${tenderItem.no_pr} - ${tenderItem.line_no}` : ''}
                </td>
                <td className={'px-2 border text-start'}>
                  <div className={'pb-2 border-bottom'}>
                    <small>
                      <strong>Parameter</strong>
                    </small>
                    <div>{param.name}</div>
                  </div>
                  <div className={'pt-2'}>
                    <small>
                      <strong>Hasil Evaluasi</strong>
                    </small>
                    <div>{{
                      'COMPLY': 'Sesuai',
                      'NOT-COMPLY': 'Tidak Sesuai',
                      'NOT-EVALUATE': 'Tidak Dievaluasi',
                    }[param.result] ?? param.result}</div>
                  </div>
                </td>
                <td className={'px-2 border text-start'}>
                  <div className={'d-flex flex-row justify-content-between'}>
                    <div>
                      <strong>
                        {param.updated_by
                          ? `${param.updated_by?.username} - ${param.updated_by.name}`
                          : ''}
                      </strong>
                    </div>
                    <div>
                      <i>
                        <small>{formatDateTimeToDBFormat(param.updated_at)}</small>
                      </i>
                    </div>
                  </div>
                  {param.comment}
                </td>
              </tr>
            ))
        )
      )}
      </tbody>
    </table>
  )
}

const EvaluasiKlarifikasiEvaluatorEndUser = ({
  vendor_id,
  clarificationType,
}: {
  vendor_id: string
  clarificationType?: string
}) => {
  clarificationType = clarificationType || 'technical'

  const {proposalTenderUuid} = useProposalTender()

  return (
    <>
      <EvaluasiKlarifikasiEvaluatorEndUserFinalResult
        proposalTenderUuid={proposalTenderUuid}
        vendorId={vendor_id}
        clarificationType={clarificationType}
      />

      <h5>Parameter Evaluasi</h5>
      <div
        style={{
          overflow: 'auto',
          maxHeight: 500,
        }}
        className={'border-bottom pb-2'}
      >
        <EvaluasiKlarifikasiEvaluatorEndUserParamResults
          proposalTenderUuid={proposalTenderUuid}
          vendor_id={vendor_id}
          clarificationType={clarificationType}
        />
      </div>
    </>
  )
}
//endregion

const EvaluasiKlarifikasiTeknisList = ({vendor_id}: any) => {

  const [selectedTab, setSelectedTab] = useState('0')
  // @ts-ignore
  return (
    <div className={'border-top my-4'}>
      <div>
        <div className={'mt-4 mb-2'}>
          <strong>Clarification</strong>
        </div>
        <Nav
          defaultActiveKey="Home"
          appearance={'tabs'}
          onSelect={(e) => setSelectedTab(e)}
          activeKey={selectedTab}
        >
          <Nav.Item eventKey="0">Evaluator / End User</Nav.Item>
          <Nav.Item eventKey="1">Vendor</Nav.Item>
        </Nav>

        <div className={'py-2'}>
          {selectedTab === '0' && (
            <EvaluasiKlarifikasiEvaluatorEndUser vendor_id={vendor_id} />
          )}
          {selectedTab === '1' && (
            <EvaluasiKlarifikasiTeknisVendor vendor_id={vendor_id} />
          )}
        </div>


      </div>
    </div>
  )
}


export {
  EvaluasiKlarifikasiAdministrasiTeknisList,
  EvaluasiKlarifikasiTeknisList,
}