import React, { useEffect, useRef, useState } from "react";
import { Nav, Placeholder } from "rsuite";
import { useNavigate, useParams } from "react-router-dom";
import { useAxiosGet, useAxiosPost } from "../../../../hooks/useAxios";
import { capitalizeFirstChar, getEnv } from "../../../../helpers/procurex";
import { KTCard, KTCardBody } from "../../../../_metronic/helpers";
import TabulasiAuctionVendorTab from "./components/TabulasiAuctionVendorTab";
import Countdown, { zeroPad } from "react-countdown";
import { Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import moment from "moment";
import { RiwayatAuctionVendorTab } from "./components/RiwayatAuctionVendorTab";
import axios from "axios";
import { FinalisasiAuctionVendorTab } from "./components/FinalisasiAuctionVendorTab";
import { BeritaAcaraAuctionVendorTab } from "./components/BeritaAcaraAuctionVendorTab";
import { PaktaIntegritasAuctionVendorTab } from "./components/PaktaIntegritasAuctionVendorTab";

const HeaderCountdown = ({ time, status, type }: any) => {
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosPost(
    getEnv("auction") + `/as-vendor/auction/run-cronjob/`
  );

  const PusherJS = require('pusher-js');
  const [currentTimeStamp, setCurrentTimestamp] = useState(0)
  const [socketCurrentTimeStamp, setSocketTimestamp] = useState(0)
  const initSocket = () => {
    let client = new PusherJS(process.env.REACT_APP_WEBSOCKET_APP_KEY, {
      wsHost: process.env.REACT_APP_WEBSOCKET_URL,
      wsPort: process.env.REACT_APP_WEBSOCKET_PORT,
      cluster: process.env.REACT_APP_WEBSOCKET_CLUSTER,
      forceTLS: process.env.REACT_APP_WEBSOCKET_FORCETLS,
      encrypted: process.env.REACT_APP_WEBSOCKET_ENCRYPTED,
      disableStats: true,
      enabledTransports: ["ws", "wss"],
      logToConsole: true
    });
    const channel = client.subscribe("server-time");
    channel.bind("App\\Events\\ServerTimeEvent", function (data: any) {
      setSocketTimestamp(data.message)
    });
  }

  const onCompleteCountdown = () => {
    if (time > 0 && type) {
      if (type == 'auction-akan-dimulai-dalam') {
        const payload = {
          _method: "PUT"
        };
        const newUrl = getEnv("auction") + `/as-vendor/auction/run-cronjob/ongoing`;
        doSubmit(payload, newUrl).then((res: any) => {
        });
      } else if (type == 'waktu-sisa-auction') {
        const payload = {
          _method: "PUT"
        };
        const newUrl = getEnv("auction") + `/as-vendor/auction/run-cronjob/done`;
        doSubmit(payload, newUrl).then((res: any) => {
        });
      }
      else if (type == 'waktu-sisa-freeze') {
        const payload = {
          _method: "PUT"
        };
        const newUrl = getEnv("auction") + `/as-vendor/auction/run-cronjob/freeze-peserta`;
        doSubmit(payload, newUrl).then((res: any) => {
        });
      }
    }
  }


  useEffect(() => {
    if (status == 'paused' && currentTimeStamp == 0) {
      setCurrentTimestamp(socketCurrentTimeStamp);
    } else if (status != 'paused') {
      setCurrentTimestamp(socketCurrentTimeStamp);
    }
  }, [socketCurrentTimeStamp])

  useEffect(() => {
    initSocket()
  }, []);
  return (
    <div className={"d-flex"}>
      {time === 0 || currentTimeStamp == 0 && (
        '00:00:00:00'
      )}
      {time >= 0 && currentTimeStamp != 0 && (
        <>
          <Countdown date={time} now={() => currentTimeStamp} onComplete={() => onCompleteCountdown()} />
        </>
      )}
    </div>
  )
}


const Header = ({ auction, onRefreshAuction, tabulasi }: any) => {
  const navigate = useNavigate();

  const isShowCountdown = () => {
    const allowedStatus = ["published", "ongoing", "paused"];
    return allowedStatus.includes(tabulasi?.status);
  };

  return (
    <>
      <div className={"row mb-2"}>
        <div className={"col-md-5"}>
          <div className={"row"}>
            <div className={"col-md-12 d-flex justify-content-between"}>
              <div>
                <h2>[{auction?.jenis_simulasi?.value}] {auction?.nama}</h2>
              </div>

            </div>
            <div className={"col-md-12 d-flex gap-3 mb-1"}>
              <div>
                No: <strong>{auction?.no_auction}</strong>
              </div>
              <div>
                | <strong>{auction?.purchasing_org?.comp_code}</strong> ({auction?.purchasing_org?.comp_code_name})
              </div>

            </div>
            <div className={"col-md-12 d-flex gap-3 mb-2"}>
              <div className={"d-flex gap-2"}>
                <span className={"badge badge-primary"}>#{auction?.tipe_auction?.value}</span>
                <span className={"badge badge-primary"}>#{auction?.metode_evaluasi?.value}</span>
                <span className={"badge badge-primary"}>#{auction?.metode_peringkat?.value}</span>
              </div>

            </div>
            <div className={"col-md-12 d-flex gap-3"}>
              <button className={"btn btn-danger btn-sm"} type={"button"} onClick={() => {
                navigate("/auction-v2/auction");
              }}>
                <i className={"fa fa-arrow-left"}></i> Kembali Ke List
              </button>
            </div>
          </div>
        </div>
        <div className={"col-md-4 text-center"}>
          {isShowCountdown() && (
            <table className={"table table-borderless"}>
              <tbody>
                <tr>
                  <td className={"bg-white border"}>
                    <strong>Waktu Sisa Auction</strong>
                  </td>
                  <td className={"bg-white border text-start px-3"} style={{
                    width: "200px"
                  }}>
                    <h2>
                      <HeaderCountdown type={'waktu-sisa-auction'} status={tabulasi?.status} time={tabulasi?.end_countdown} key={tabulasi?.end_countdown} />
                    </h2>
                  </td>
                </tr>
                {tabulasi?.freeze_time_in_minutes > 0 && (
                  <tr>
                    <td className={"bg-white border"}>
                      <strong>Waktu Sisa Freeze</strong>
                    </td>
                    <td className={"bg-white border text-start px-3"}>
                      <h2>
                        <HeaderCountdown type={'waktu-sisa-freeze'} status={tabulasi?.status} time={tabulasi?.freeze_countdown} key={tabulasi?.freeze_countdown} />
                      </h2>
                    </td>
                  </tr>
                )}
                {tabulasi?.status === "published" && (
                  <tr>
                    <td className={"bg-white border"}>
                      Akan dimulai dalam:
                    </td>
                    <td className={"bg-white border text-start px-3"}>
                      <h4>
                        <HeaderCountdown type={'auction-akan-dimulai-dalam'} status={tabulasi?.status} time={tabulasi?.start_countdown} />
                      </h4>
                    </td>
                  </tr>
                )}

              </tbody>
            </table>
          )}

        </div>
        <div className={"col-md-3 text-end d-flex align-items-end justify-content-between flex-column"}>
          <div>
            <h2><strong>{capitalizeFirstChar(tabulasi?.status)}</strong></h2>
            {tabulasi?.batas_awal != null &&
              <strong>{tabulasi?.batas_awal_format} WIB - {tabulasi?.batas_akhir_format} WIB</strong>
            }
          </div>
          {tabulasi?.is_banned && (
            <div>
              <button className={"btn btn-danger btn-sm d-flex align-items-center"} type={"button"}>
                <i className={"fa fa-exclamation-triangle me-2"}></i>
                BANNED
              </button>
            </div>
          )}

          {(tabulasi?.status == 'ongoing' && tabulasi?.is_freeze && tabulasi?.is_banned == false) && (
            <div>
              <button className={"btn btn-danger btn-sm d-flex align-items-center"} type={"button"}>
                <i className={"fa fa-exclamation-triangle me-2"}></i>
                FREEZE
              </button>
            </div>
          )}
        </div>
      </div>

    </>
  );
};

const FormPaktaIntegritas = ({ auction, onRefreshAuction }: any) => {
  const navigate = useNavigate();
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosPost(
    getEnv("auction") + `/as-vendor/approve`
  );

  const handleOnSubmit = async (state: boolean) => {
    Swal.fire({
      title: "Apakah Anda Yakin?",
      text: state ? "Anda akan menyetujui pakta integritas ini" : "Anda tidak menyetujui pakta integritas ini",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Submit",
      cancelButtonText: "Cancel"
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const newUrl = getEnv("auction") + `/as-vendor/approve/${auction?.uuid}/${state ? '1' : '0'}`;
        doSubmit({ _method: 'PUT' }, newUrl, toast).then((res: any) => {
          onRefreshAuction();
          if (!state) navigate("/auction-v2/auction");
        }).catch((err: any) => {
          console.log(err);
        })
      }
    });
  }

  return (
    <>
      <Modal show={auction?.peserta_status === "inactive" || auction?.peserta_status == "rejected"} onHide={() => { }} size={"xl"}>
        <Modal.Header closeButton>
          <Modal.Title>Pakta Integritas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <p className='py-3' dangerouslySetInnerHTML={{ __html: auction?.petunjuk }}></p>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button disabled={isLoading} className={"btn btn-primary"} type={"button"} onClick={() => { handleOnSubmit(true) }}>
            {isLoading && <Spinner animation={"border"} size={"sm"} className={"me-2"}></Spinner>}
            {!isLoading && <i className={"fa fa-check me-2"}></i>}
            Setuju
          </button>
          <button disabled={isLoading} className={"btn btn-danger"} type={"button"} onClick={() => { handleOnSubmit(false) }}>
            {isLoading && <Spinner animation={"border"} size={"sm"} className={"me-2"}></Spinner>}
            {!isLoading && <i className={"fa fa-times me-2"}></i>}
            Tidak Setuju
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const AuctionVendorDetailForm = () => {
  const [selectedTab, setSelectedTab] = useState("parameter-auction");
  const [auction, setAuction] = useState<any>({});
  const [tabulasi, setTabulasi] = useState<any>({});
  const { uuid } = useParams();
  const eventSocket = "App\\Events\\AuctionVendorEvent";
  const [subscribedSocket, setSubscribedSocket] = useState("");
  const PusherJS = require("pusher-js");
  

  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosGet(
    getEnv("auction") + "/as-vendor/auction/" + uuid
  );

  const [{ doSubmit: doSubmitRefreshTabulasi, isLoading: isLoadingRefreshTabulasi }] = useAxiosGet(
    getEnv("auction") + "/as-vendor/auction/" + uuid
  );
  const [menuTab, setMenuTab] = useState([] as any);

  const initiateTab = () => {
    const tmp = [
      { label: "Tabulasi", eventKey: "tabulasi", disabled: false },
      { label: "Riwayat", eventKey: "riwayat" },
    ]
    if (tabulasi?.status_peserta != 'rejected' && tabulasi?.status_peserta != 'inactive') {
      tmp.push({ label: "Pakta Integritas", eventKey: "pakta-integritas" });
    }
    if (tabulasi?.status == 'done' || tabulasi?.status == 'closed') {
      tmp.push({ label: "Berita Acara", eventKey: "berita-acara" });
    }
    if (!tabulasi?.is_service_line_empty) {
      tmp.push({ label: "Finalisasi", eventKey: "finalisasi" });
    }
    setMenuTab(tmp);
  }

  const getDetail = async () => {
    doSubmit(undefined, undefined, undefined).then((res: any) => {
      setAuction(res.data);
      setSubscribedSocket(`auction.vendor.${res.data?.uuid}.${res.data?.peserta_id}`)
      setSelectedTab("tabulasi");

    });
  };

  const getTabulasi = async () => {
    const newUrl = getEnv("auction") + `/as-vendor/tabulasi/${uuid}`;
    doSubmit(undefined, newUrl, undefined).then((res: any) => {
      setTabulasi(res.data);
      initiateSocket();
    });
  };

  const getRefreshTabulasi = async () => {
    const newUrl = getEnv("auction") + `/as-vendor/tabulasi/${uuid}`;
    doSubmitRefreshTabulasi(undefined, newUrl, undefined).then((res: any) => {
      setTabulasi(res.data);
      initiateSocket();
    });
  };


  const checkIsTabEnabled = (eventKey: string) => {
    if (eventKey === 'pemenang-lelang') {
      return auction?.status === 'done';
    } else if (eventKey === 'penandatanganan') {
      return auction?.status === 'done';
    }
    return true;
  };

  const initiateSocket = () => {

    // @ts-ignore
    let client = new PusherJS(process.env.REACT_APP_WEBSOCKET_APP_KEY, {
      wsHost: process.env.REACT_APP_WEBSOCKET_URL,
      wsPort: process.env.REACT_APP_WEBSOCKET_PORT,
      cluster: process.env.REACT_APP_WEBSOCKET_CLUSTER,
      forceTLS: process.env.REACT_APP_WEBSOCKET_FORCETLS,
      encrypted: process.env.REACT_APP_WEBSOCKET_ENCRYPTED,
      disableStats: true,
      enabledTransports: ["ws", "wss"],
      logToConsole: true
    });

    const channel = client.subscribe(subscribedSocket);
    channel.bind(eventSocket, function (data: any) {
      setTabulasi(data.data);

    });
  };

  const showTab = () => {
    switch (selectedTab) {
      case "riwayat":
        return (
          <RiwayatAuctionVendorTab title={"Riwayat"} tabulasi={tabulasi} />
        );
      case "pakta-integritas":
        return (
          <PaktaIntegritasAuctionVendorTab auction={auction} />
        );
      case "berita-acara":
        return (
          <BeritaAcaraAuctionVendorTab auction={auction} tabulasi={tabulasi} />
        );
      case "finalisasi":
        return (
          <FinalisasiAuctionVendorTab auction={auction} tabulasi={tabulasi} />
        );
      default:
        return (
          <TabulasiAuctionVendorTab auction={auction} tabulasi={tabulasi} getTabulasi={() => getRefreshTabulasi()} isLoadingRefreshTabulasi={isLoadingRefreshTabulasi} />
        )
    }
  };

  useEffect(() => {
    getDetail();
  }, []);

  useEffect(() => {
    getTabulasi()
  }, [subscribedSocket]);

  useEffect(() => {
    initiateTab();
    if (auction && auction.status) {
      var dataAuction = { ...auction };
      dataAuction.status = tabulasi.status;
      setAuction(dataAuction);
    }
    if (tabulasi?.status == 'done' && !tabulasi?.is_disabled_finalisasi && !tabulasi?.is_service_line_empty) {
      setSelectedTab("finalisasi");
    }
  }, [tabulasi]);

  return (
    <>


      {isLoading && (
        <Placeholder.Grid rows={3} active columns={2} style={{ marginTop: 20 }} />
      )}
      {!isLoading && (
        <Header
          auction={auction}
          onRefreshAuction={getDetail}
          tabulasi={tabulasi}
        />
      )}
      {tabulasi.status === "ongoing" && (
        <div className={"bg-warning mb-4 p-2"}>
          <div className="marquee-container">
            <div className="marquee-content text-dark">
              <strong>{auction?.catatan}</strong>
            </div>
          </div>
        </div>
      )}

      <KTCard>
        <KTCardBody>
          {isLoading && (
            <Placeholder.Graph height={1000} active={true} />
          )}
          {!isLoading && (
            <div className={"table-responsive"} style={{
              overflowX: "hidden",
              width: "100%"
            }}>
              <Nav appearance={"subtle"} justified={true} activeKey={selectedTab} onSelect={(e: any) => {
                setSelectedTab(e);
              }} style={{ marginBottom: 20 }}>
                {menuTab.map((item: any, index: number) => {
                  return (
                    <Nav.Item className={item.label == 'Finalisasi' && !tabulasi?.is_disabled_finalisasi ? "text-center red-border" : "text-center"} eventKey={item.eventKey} key={index} disabled={!checkIsTabEnabled(item.eventKey)}>
                      <div className={"text-center w-100"}>
                        {item.label}
                      </div>
                      {item.label == 'Finalisasi' && !tabulasi?.is_disabled_finalisasi &&
                        <small className="error-counter">
                          <i className={"fa fa-exclamation-circle text-danger text-sm blink_me"}></i>
                        </small>
                      }
                    </Nav.Item>
                  );
                })}
              </Nav>
            </div>
          )}
          {auction?.id && showTab()}
          <FormPaktaIntegritas
            auction={auction}
            onRefreshAuction={() => { getDetail() }}
          />
        </KTCardBody>
      </KTCard>
    </>
  );
};

export default AuctionVendorDetailForm;