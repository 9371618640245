import { NavLink, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { ApprovalX, ButtonProsesApproval } from '../../../../components/shared/ApprovalX'
import { KTSVG, toastError, toastSuccess } from '../../../../../_metronic/helpers'
import { useState, useRef, useEffect } from 'react'
import { useAuth } from '../../../auth'
import { ApiUrl } from '../../../../config/Api'
import { Modal } from 'react-bootstrap'

import BottomToolbarWrapper from '../../../../components/shared/BottomToolbarWrapper'
import ApproveRejection from '../../../../components/shared/ApproveRejection'
import ConfirmationPO from './components/ConfirmationPO'
import HeaderForm from './components/form/HeaderForm'
import CreateTabs from './components/CreateTabs'
import History from './components/History'
import Memo from './components/Memo'
import axios from 'axios'
import Swal from 'sweetalert2'
import { NoteConfirmationModal } from './NoteConfirmationModal'

const Create = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const location = useLocation()
    const {currentUser} = useAuth()
    const [tabs, setTabs] = useState<any>([])
    const [loading, setLoading] = useState(false)
    const [currentTab, setCurrentTab] = useState(0)
    const [headerForm, setHeaderForm] = useState<any>(null)
    const [approveOrReject, setApproveOrReject] = useState("")
    const [searchParams, setSearchParams] = useSearchParams()
    const [modalCancelPO, setModalCancelPO] = useState(false)
    const [loadingApproval, setLoadingApproval] = useState(false)
    const [approvalSchema, setApprovalSchema] = useState<any>(null)
    const [isShowApprovalSchema, setIsShowApprovalSchema] = useState(false)
    const [showModalNoteConfrimation, setShowModalNoteConfrimation] = useState(false)


    const canSubmitPo = headerForm?.status === 'Draft' || headerForm?.status === 'PO Rejected' || headerForm?.status === 'Vendor Approval Rejection'
    let tabsOpt: any
    if (!currentUser?.has_roles?.includes('vendor')) {
        tabsOpt = [
            {
                value: 0,
                icon: 'fa-solid fa-sliders fa-fw',
                text: 'Purchase Order',
                disabled: false,
            },
            {
                value: 1,
                icon: 'fa-solid fa-file-invoice fa-fw',
                text: 'Memo Persetujuan PO',
                disabled: location.pathname === '/contract-management/po/create',
            },
            {
                value: 2,
                icon: 'fa-solid fa-file-circle-plus fa-fw',
                text: 'Document Konfirmasi',
                disabled: location.pathname === '/contract-management/po/create'
                    || canSubmitPo
                    || headerForm?.status === 'Submit for Approval Release',
            },
            {
                value: 3,
                icon: 'fa-solid fa-clock-rotate-left fa-fw',
                text: 'History',
                disabled: location.pathname === '/contract-management/po/create',
            },
        ]
    } else {
        tabsOpt = [
            {
                value: 0,
                icon: 'fa-solid fa-sliders fa-fw',
                text: 'Purchase Order',
                disabled: false,
            },
            {
                value: 1,
                icon: 'fa-solid fa-file-invoice fa-fw',
                text: 'Memo Persetujuan PO',
                disabled: location.pathname === '/contract-management/po/create',
            },
            {
                value: 2,
                icon: 'fa-solid fa-file-circle-plus fa-fw',
                text: 'Document Konfirmasi',
                disabled: location.pathname === '/contract-management/po/create'
                    || canSubmitPo
                    || headerForm?.status === 'Submit for Approval Release',
            },
        ]
    }

    const hideSidebar = () => {
        // hide sidebar
        const elementToClick = document.getElementById('kt_aside_toggle');

        if (elementToClick && !elementToClick.classList.contains('active')) {
            // Memicu klik pada elemen
            elementToClick.click();
        }
    }

    const showSidebar = () => {
        // hide sidebar
        const elementToClick = document.getElementById('kt_aside_toggle');

        if (elementToClick && elementToClick.classList.contains('active')) {
            // Memicu klik pada elemen
            elementToClick.click();
        }
    }

    useEffect(() => {
        setTabs(tabsOpt)
        axios
            .get(`${ApiUrl}/api/tender/po/${id}`)
            .then((res) => {
                if (res.data.data?.status === 'PO Approved' ||
                    res.data.data?.status === 'Release' ||
                    res.data.data?.status === 'Rejected Confirmation' ||
                    res.data.data?.status === 'Final Check'
                ) setCurrentTab(2)
                if (res.data.data?.status === 'Submit for Approval Release') setCurrentTab(1)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [location])

    useEffect(() => {
        if (id) {
            loadSingle(id)

        }
    }, [id])

    useEffect(() => {
        setTabs(tabsOpt)
        loadApprovalSchema()
        hideSidebar()
    }, [headerForm])

    const beforeClick = async () => {
        if (currentTab === 0 && location.pathname !== '/contract-management/po/create') {
            await refHeaderForm.current.submitHeaderForm()
        }
    }

    const afterClick = () => {
        loadSingle(id)
        setTimeout(() => {
            window.scrollTo(0, 0)
        }, 500)
    }

    const loadSingle = (id: any) => {
        setLoading(true)
        axios
            .get(`${ApiUrl}/api/tender/po/${id}`)
            .then((res) => {
                setHeaderForm(res.data.data)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const validateIsSubmission = () => {
        setLoading(true)
        axios
            .get(`${ApiUrl}/api/tender/po/validateIsSubmission/${id}`)
            .then((res) => {
                setIsShowApprovalSchema(true)
            }).catch((error) => {
                console.error(error)
                toastError(error?.response?.data?.meta?.message || 'Terjadi kesalahan')
                setIsShowApprovalSchema(false)
            }).finally(() => {
                setLoading(false)
            })
    }

    const loadApprovalSchema = () => {
        setLoading(true)
        if (headerForm === null) {
            return
        }

        if (canSubmitPo) {

            axios
                .get(`${ApiUrl}/api/tender/po/routing-approval-code/${id}`)
                .then((res) => {
                    setApprovalSchema(res.data.data)
                })
                .catch((error) => {
                    console.error(error)
                })
                .finally(() => {
                    setLoading(false)
                })
        } else {
            axios
                .get(`${ApiUrl}/api/approval/routing-approval/detail?company_id=${headerForm?.company?.id}&object_id=${headerForm?.id}&source=TENDER&code=${headerForm?.routing_approval_config_code}`)
                .then((res) => {
                    setApprovalSchema(res.data.data[0])
                })
                .catch((error) => {
                    console.error(error)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    const refHeaderForm = useRef<any>()

    const handleOnSubmitApproval = async (values: any) => {
        const payload = {
            approvers: values.approval.map((item: any) => {
                return {
                    step: item.step,
                    jabatan: item.jabatan,
                    type: "APPROVAL",
                    user_id: item.extra_info.user.user_id,
                    extra_info: item.extra_info
                }
            }),
        }


        try {
            setLoadingApproval(true)
            const response = await axios.post(`${ApiUrl}/api/tender/po/submit/${headerForm?.uuid}`, payload)

            toastSuccess(response?.data?.meta?.message)
            navigate('/contract-management/po')
        } catch (error: any) {
            if(error?.response?.data?.data?.show_modal) {
                setIsShowApprovalSchema(false)
            } else {
                toastError(error?.response?.data?.meta?.message || 'Terjadi kesalahan')
            }

        } finally {
            setLoadingApproval(false)
        }
    }

    const onSubmitReConfirmation = () => {
        Swal.fire({
            title: "Apakah Anda Yakin?",
            text: "Anda akan release po ini?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Ya, Release",
            cancelButtonText: "Tidak"
        }).then(async (result) => {
            if (!result.isConfirmed) {
                return;
            }

            try {
                const response = await axios.post(`${ApiUrl}/api/tender/po/submit/${id}`, {})

                toastSuccess(response?.data?.meta?.message)
                navigate('/contract-management/po')
            } catch (error: any) {
                toastError(error?.response?.data?.meta?.message || 'Terjadi kesalahan')
            }
        })
    }

    const onApproveConfirmation = () => {
        Swal.fire({
            title: "Apakah Anda Yakin?",
            text: "Anda setuju dengan PO ini?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Ya, Setuju",
            cancelButtonText: "Tidak"
        }).then(async (result) => {
            if (!result.isConfirmed) return;
            try {
                let response: any
                if (headerForm?.status === 'Release') response = await axios.post(`${ApiUrl}/api/tender/vendor/po/${id}`, {confirmation: true})
                if (headerForm?.status === 'Final Check') response = await axios.post(`${ApiUrl}/api/tender/po/submit/${id}`, {confirmation: true})
                toastSuccess(response?.data?.meta?.message)
                navigate('/contract-management/po')
            } catch (error: any) {
                toastError(error?.response?.data?.meta?.message || 'Terjadi kesalahan')
            }
        })
    }

    const onRejectConfirmation = () => {
        Swal.fire({
            title: "Apakah Anda Yakin?",
            text: "Anda tolak dengan po ini?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Ya, Tolak",
            cancelButtonText: "Tidak"
        }).then(async (result) => {
            if (!result.isConfirmed) return;
            try {
                let response: any
                if (headerForm?.status === 'Release') response = await axios.post(`${ApiUrl}/api/tender/vendor/po/${id}`, {confirmation: false})
                if (headerForm?.status === 'Final Check') response = await axios.post(`${ApiUrl}/api/tender/po/submit/${id}`, {confirmation: false})

                toastSuccess(response?.data?.meta?.message)
                navigate('/contract-management/po')
            } catch (error: any) {
                toastError(error?.response?.data?.meta?.message || 'Terjadi kesalahan')
            }
        })
    }

    // @ts-ignore
    const handleOnGoBack = () => {
        showSidebar()
        navigate(searchParams.get('back') || '/contract-management/po')
    }

    return (
        <>
            {approvalSchema !== null &&
                <ApprovalX
                    show={isShowApprovalSchema}
                    schema={approvalSchema?.details}
                    onHide={() => {
                        setIsShowApprovalSchema(false)
                    }}
                    onSubmit={(data: any) => {
                        handleOnSubmitApproval(data)
                    }}
                    isLoading={loadingApproval}
                    source={"TENDER"}
                    object_id={headerForm?.id}
                    company_id={headerForm?.company?.id}
                    code={headerForm?.routing_approval_config_code}
                    readOnly={headerForm?.status === "Vendor Approval Rejection" ? false : headerForm?.is_on_approval}
                />
            }

            {modalCancelPO &&
                <Modal
                    show={modalCancelPO}
                    onHide={() => {
                        setModalCancelPO(false)
                    }}
                    size={'lg'}
                >
                    <Modal.Header closeButton={true}>
                        <h4>Batalkan PO</h4>
                    </Modal.Header>
                    <Modal.Body className='overflow-visible'>
                        <label htmlFor="">
                            Catatan Batal PO
                        </label>
                        <textarea className='form-control' id='note' />
                    </Modal.Body>
                </Modal>
            }

            <NoteConfirmationModal
                action={approveOrReject}
                po={headerForm}
                show={showModalNoteConfrimation}
                onHide={setShowModalNoteConfrimation}
            />

            <div className='d-flex justify-content-end gap-4 mb-8'>
                <div className='me-auto'>
                    <button type={"button"} onClick={handleOnGoBack} className='btn btn-danger btn-sm'>
                        <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon-muted' />
                        Kembali ke List
                    </button>
                </div>

                <div className={"d-flex justify-content-end me-3"}>
                    <div className={"badge badge-warning text-dark"}>
                    <i className={"fa fa-tag me-3 text-dark"}/>
                    {headerForm && (
                        <>{headerForm?.status}</>
                    )}
                    </div>
                </div>
                {(approvalSchema !== null && !canSubmitPo && headerForm?.status === "Submit for Approval Release") &&
                   <>
                       <ButtonProsesApproval
                         onShowDetailApproval={() => setIsShowApprovalSchema(true)}
                         isLoading={loadingApproval}
                         source={"TENDER"}
                         object_id={headerForm?.id}
                         company_id={headerForm?.company?.id}
                         code={headerForm?.routing_approval_config_code}
                         readOnly={headerForm?.is_on_approval}
                         onSubmit={() => {
                             navigate('/contract-management/po')
                         }}
                       />
                   </>
                }

                {headerForm &&
                    headerForm?.status === 'Submit for Approval Release' &&
                    location.pathname !== '/contract-management/po/create' && (
                       <>
                           {/*<ApproveRejection*/}
                           {/*  object_id={headerForm?.id}*/}
                           {/*  source='TENDER'*/}
                           {/*  company_id={headerForm?.company?.id}*/}
                           {/*  code={headerForm?.routing_approval_config_code}*/}
                           {/*  routingApprovalDetail={headerForm?.current_approval_user}*/}
                           {/*  onSuccess={() => navigate('/contract-management/po')}*/}
                           {/*/>*/}
                       </>
                    )
                }
            </div>

            <CreateTabs
                afterClick={afterClick}
                beforeClick={beforeClick}
                tabs={tabs}
                setCurrentTab={setCurrentTab}
                currentTab={currentTab}
            />

            {currentTab === 0 && <HeaderForm data={headerForm} ref={refHeaderForm} headerLoading={loading} />}
            {currentTab === 1 && <Memo data={headerForm}/>}
            {currentTab === 2 && <ConfirmationPO data={headerForm}/>}
            {!currentUser?.has_roles?.includes('vendor') &&
                <>
                    {currentTab === 3 && <History id={headerForm?.id} />}
                </>
            }

            <BottomToolbarWrapper children={
                    <>
                        {currentUser?.has_roles?.toString().includes('buyer') && !(headerForm?.status === 'Done') &&
                            <button
                                onClick={() => {
                                    // setModalCancelPO(true)
                                    Swal.fire({
                                        title: "On Progress",
                                        text: "",
                                        icon: "warning",
                                    })
                                }}
                                className='btn btn-redblood btn-lg me-3'>
                                <i className="fa fa-times" aria-hidden="true" />
                                Batalkan PO
                            </button>
                        }

                        {canSubmitPo &&
                            <>
                                <button
                                    onClick={() => {
                                        if (currentTab === 0) {
                                            refHeaderForm.current.submitHeaderForm(true)
                                        } else {
                                            toastSuccess('200: Successfully update data.')
                                        }
                                    }}
                                    className='btn btn-info btn-lg me-3'
                                >
                                    <KTSVG path='/media/icons/duotune/files/fil008.svg' className='svg-icon-muted' />
                                    Save Draft
                                </button>
                                <button onClick={() => validateIsSubmission()} className='btn btn-primary btn-lg me-3'>
                                    <i className="fa-regular fa-thumbs-up"/>
                                    Submit PO
                                </button>
                            </>
                        }

                        {((headerForm?.status === 'PO Approved' || headerForm?.status === 'Rejected Confirmation') 
                        && currentUser?.has_roles?.toString().includes('buyer')) &&
                            <button onClick={() => onSubmitReConfirmation()} className='btn btn-warning btn-lg me-3'>
                                <i className={"fa fa-reply"}></i>
                                Release PO
                            </button>
                        }

                        {(((headerForm?.status === 'Release' || headerForm?.status === "Waiting Vendor Approval") && 
                        !currentUser?.has_roles?.toString().includes('buyer'))
                        || (headerForm?.status === 'Final Check' && currentUser?.has_roles?.toString().includes('buyer'))) &&
                            <>
                                <button onClick={() => {
                                    setApproveOrReject("Approve")
                                    setShowModalNoteConfrimation(true)
                                    }} className="btn btn-lg btn-success me-3">
                                    <i className={"fa fa-check"}></i>
                                    Approve
                                </button>
                                <button onClick={() => {
                                    setApproveOrReject("Reject")
                                    setShowModalNoteConfrimation(true)
                                    }} className='btn btn-lg btn-redblood me-3'>
                                    <i className="fa fa-times" aria-hidden="true" />
                                    Reject
                                </button>
                            </>
                        }

                        <button
                            type='button'
                            className='btn btn-secondary btn-lg me-3'
                            disabled={currentTab === 0}
                            onClick={() => {
                                if (currentTab === 3) {
                                    if (canSubmitPo) {
                                        setCurrentTab(currentTab - 2)
                                    } else {
                                        setCurrentTab(currentTab - 1)
                                    }
                                } else {
                                    setCurrentTab(currentTab - 1)
                                }
                            }}
                        >
                            <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon-muted' />
                            Previous
                        </button>

                        <button
                            type='button'
                            className='btn btn-primary btn-lg me-3'
                            disabled={!currentUser?.has_roles?.includes('vendor') ? currentTab === 3 : currentTab === 2}
                            onClick={() => {
                                if (currentTab === 0 && 
                                    (headerForm?.status === 'Draft' || 
                                    headerForm?.status === 'Vendor Approval Rejection' ||
                                    headerForm?.status === 'PO Rejected'
                                    )) {
                                    if (refHeaderForm.current) {
                                        refHeaderForm.current.submitHeaderForm(true)
                                    } else {
                                        refHeaderForm.current.submitHeaderForm(false)
                                    }
                                }
                                if (location.pathname !== '/contract-management/po/create') {
                                    if (currentTab === 1) {
                                        if (canSubmitPo) {
                                            setCurrentTab(currentTab + 2)
                                        } else {
                                            setCurrentTab(currentTab + 1)
                                        }
                                    } else {
                                        setCurrentTab(currentTab + 1)
                                    }
                                }
                            }}
                        >
                            <KTSVG path='/media/icons/duotune/arrows/arr001.svg' className='svg-icon-muted' />
                            Next
                        </button>
                    </>
                }
            />
        </>
    )
}

export default Create
