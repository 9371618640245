import { Navigate, Route, Routes } from "react-router-dom";
import { FC, lazy, Suspense } from "react";
import { WithChildren } from "../../_metronic/helpers";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import TopBarProgress from "react-topbar-progress-indicator";
import { useAuth } from "../modules/auth";

const InvoiceRoutes = () => {
  const DashboardPage = lazy(() => import("../modules/invoice/admin/InvoiceAdminDashboard"));
  const GoodReceiptPage = lazy(() => import("../modules/invoice/admin/good-receipts/GoodReceiptPage"));
  const InvoicePage = lazy(() => import("../modules/invoice/admin/invoices/InvoicePage"));
  const VendorDashboardPage = lazy(() => import("../modules/invoice/vendor/InvoiceVendorDashboard"));
  const VendorGoodReceiptPage = lazy(() => import("../modules/invoice/vendor/good-receipts/GoodReceiptPage"));
  const VendorInvoicePage = lazy(() => import("../modules/invoice/vendor/invoices/InvoicePage"));
  const { currentUser } = useAuth();
  return (
    <>
      {!currentUser?.has_roles?.includes("vendor") && (
        <Routes>
          <Route index element={<Navigate to="/invoice/admin/dashboard" />} />
          <Route path="/admin/dashboard" element={<SuspensedView><DashboardPage /></SuspensedView>} />
          <Route path="/admin/good-receipts/*" element={<SuspensedView><GoodReceiptPage /></SuspensedView>} />
          <Route path="/admin/invoices/*" element={<SuspensedView><InvoicePage /></SuspensedView>} />
        </Routes>
      )}
      {currentUser?.has_roles?.includes("vendor") && (
        <Routes>
          <Route index element={<Navigate to="/invoice/vendor/dashboard" />} />
          <Route path="/vendor/dashboard" element={<SuspensedView><VendorDashboardPage /></SuspensedView>} />
          <Route path="/vendor/good-receipts/*" element={<SuspensedView><VendorGoodReceiptPage /></SuspensedView>} />
          <Route path="/vendor/invoices/*" element={<SuspensedView><VendorInvoicePage /></SuspensedView>} />
        </Routes>
      )}

    </>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor
    },
    barThickness: 1,
    shadowBlur: 5
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export default InvoiceRoutes;
