import React, {useEffect, useState} from 'react'
import {KTCard, KTCardBody} from '../../../../../_metronic/helpers'
import {PageAlertX} from '../../../../components/shared/PageAlertX'
import SideLeftLayout from './components/SideLeftLayout'
import {Outlet, Route, Routes, useNavigate, useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import ButtonToolbar from './components/ButtonToolbar'
import ParameterPengadaanPage from './components/tahapan_proposal/ParameterPengadaanPage'
import {Placeholder} from 'rsuite'
import {useProposalTender} from './core/_proposalTenderProvider'
import ToolbarRightLayout from './components/ToolbarRightLayout'
import ItemPRPage from './components/tahapan_proposal/ItemPRPage'
import DokumenPengadaanPage from './components/tahapan_proposal/DokumenPengadaanPage'
import JadwalTenderPage from './components/tahapan_proposal/JadwalTenderPage'
import PerysaratanTenderPageV2 from './components/tahapan_proposal/PersyaratanTenderPageV2'
import PanitiaTenderForm from './components/tahapan_proposal/PanitiaTenderForm'
import DURPage from './components/tahapan_proposal/DURPage'
import PraQualificationEvaluatePage from './components/tahapan_proses/PraQualificationEvaluatePage'
import AanwijzingDetail from '../aanwijzing/AanwijzingDetail'
import NegosiasiPage from './components/tahapan_proses/negosiasi/NegosiasiPage'
import PesertaTenderPage from './components/tahapan_proses/PesertaTenderPage'
import EvaluasiTeknisSatuSampulPage from './components/tahapan_proses/evaluasi_satu_sampul/EvaluasiTeknisSatuSampulPage'
import AwardingPage from './components/tahapan_awarding/AwardingPage'
import PengumumanPemenangPage from './components/tahapan_awarding/PengumumanPemenangPage'
import PenunjukkanPemenangPage from './components/tahapan_awarding/PenunjukkanPemenangPage'
import EvaluasiTeknisDuaSampulPage from './components/tahapan_proses/evaluasi_dua_sampul/EvaluasiTeknisDuaSampulPage'
import {useMutation} from 'react-query'
import {getEnv} from '../../../../../helpers/procurex'
import axios from 'axios'
import EvaluasiTeknisDuaTahapPage from './components/tahapan_proses/evaluasi_dua_tahap/EvaluasiTeknisDuaTahapPage'
import EvaluasiKomersilDuaTahapPage from './components/tahapan_proses/evaluasi_dua_tahap/EvaluasiKomersilDuaTahapPage'
import { useAuth } from '../../../auth'

const GenerateAanwijzing = ({isOwner}:any) => {
  const {proposalTender, refetchProposalTender} = useProposalTender();
  const {mutate, isLoading } = useMutation({
    mutationKey: ["proposal-tender","generate-aanwijzing"],
    mutationFn: (uuid:string) => axios.post(getEnv('tender') + `/tender/generate-aanwijzing/${uuid}`, {}),
    onSuccess: (res:any) => {
      toast.success(res?.data?.meta?.message)
      refetchProposalTender()
    },
    onError: (err:any) => {
      // console.error("error_",)
      toast.error(err?.response?.data?.meta?.message)
    }

  })
  const handleGenerateAanwijzing = () => {
    mutate(proposalTender?.uuid)
  }
  return (
    <div>
      <div className={'alert alert-info d-flex flex-row gap-1'}>
        <i className={'fa fa-info-circle me-2'} />
        <div>There is no Aanwijzing found for this proposal. Please Generate an Aanwijzing first.</div>
      </div>
      <button
        className={'btn btn-sm btn-primary'}
        onClick={handleGenerateAanwijzing}
        disabled={!isOwner || isLoading}
      >
        {isLoading && <i className={'fa fa-gear fa-spin me-2'} /> }
        {!isLoading && <i className={'fa fa-gear me-2'} /> }
         Generate Aanwijzing
      </button>
    </div>
  )
}
const ProposalTenderForm = () => {
  const [selectedMenu, setSelectedMenu] = React.useState({
    index: 0,
    headerIndex: 1,
    component: null
  });
  // const [{ doGetDetail, data: proposalTender, isLoading }] = useGetDetailProposalTender();
  const navigate = useNavigate();
  const { uuid } = useParams();
  const { currentUser } = useAuth();
  const refParameterPengadaan = React.useRef(null);
  const [isOwner, setIsOwner] = useState(false);
  const [alertMessage, setAlertMessage] = React.useState<any>(null);
  const [quotation, setQuotation] = React.useState<any>(null);
  const [isShowRetender,setIsShowRetender] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = React.useState(false);
  const [configNego, setConfigNego] = React.useState<any>(null);
  const [defaultMenuBeforeRefresh, setDefaultMenuBeforeRefresh] = useState(null);
  const [renderKey, setRenderKey] = useState(0);
  const {proposalTender, isLoadingProposalTender:isLoading, refetchProposalTender} = useProposalTender()

  const showSidebar = () => {
    // hide sidebar
    const elementToClick = document.getElementById('kt_aside_toggle');

    if (elementToClick && elementToClick.classList.contains('active')) {
      // Memicu klik pada elemen
      elementToClick.click();
    }
  }
  const goBack = () => {
    showSidebar();
    const path = localStorage.getItem("backTo");
    // alert(path)
    navigate(path ? path : "/tender/admin/proposal-tender/draft");
    // navigate('/tender/admin/proposal-tender')
  };

  useEffect(() => {
    if(proposalTender){
      // @ts-ignore
      if(proposalTender?.status === "Draft" && proposalTender?.status_detail === "Draft" && proposalTender?.praqualification){
        setAlertMessage("Silahkan tekan tombol Lanjut PQ untuk dapat melakukan proses Pra Qualification.");
      }
      // @ts-ignore
      if(proposalTender?.status === "Pra Qualification" && proposalTender?.status_detail === "Quotation"){
        setAlertMessage("Proposal Tender ini dalam proses Pra Qualification, silahkan Tutup PQ jika ingin menutup PQ lebih cepat dari jadwal, tindakan ini tidak dapat dikembalikan.");
      }
      // @ts-ignore
      if(proposalTender?.status === "Purchase Order"){
        setAlertMessage("Proposal Tender ini telah selesai, tidak dapat dilakukan perubahan.")
      }

      setIsOwner(currentUser?.username === proposalTender?.created_by?.username)
    }
  }, [proposalTender]);


  useEffect(() => {
    setDefaultMenuBeforeRefresh(selectedMenu?.index)
  }, [selectedMenu])

  const getDetail = () =>{
    refetchProposalTender();
  }

  if(!proposalTender || isLoading){
    return <div>
      <div>
        <Placeholder.Paragraph active style={{ marginTop: 30 }} />
        <Placeholder.Paragraph active />
        <Placeholder.Paragraph active />
        <Placeholder.Paragraph active />
        <Placeholder.Paragraph active />
        <Placeholder.Paragraph active />
        <Placeholder.Paragraph active />
        <Placeholder.Paragraph active />
      </div>
    </div>
  }


  return (
    <>
      {alertMessage && (
        <PageAlertX
          title={"Warning"}
          variant={"warning"}
          message={alertMessage}
        />
      )}

      <div className={"row mb-3"}>
        <div className={"col-md-3 col-sm-12 col-lg-3"}>
          <div className={"d-flex align-items-end justify-content-start"}>
            <div className={"mb-5"}>
              <button className={"btn btn-sm btn-danger me-3"} onClick={() => goBack()}>
                <i className={"fa fa-arrow-left"}></i>
                Kembali ke list
              </button>
            </div>
          </div>
          <div style={{ position: 'sticky', top: '80px' }}>
            <KTCard>
              <KTCardBody className="p-3 mb-3">
                <SideLeftLayout
                  proposalTender={proposalTender}
                  defaultMenu={defaultMenuBeforeRefresh}
                  onIndexChange={(e: any) => {
                    setSelectedMenu(e);
                  }}
                  onQuotationChanged={(e: any) => {
                    setQuotation(e)
                    getDetail()
                  }}
                  onSetKonfigurasiNego={(e: any) => {
                    setConfigNego(e)
                  }}
                  onRefresh={() => {
                    getDetail();
                  }}
                  handleOnBatalTender={() => {
                    // @ts-ignore
                    refParameterPengadaan.current?.refBatalTender().then(() => {
                      setIsLoadingButton(false);
                    }).catch(() => {
                      setIsLoadingButton(false);
                    });
                  }}
                  handleOnRetender={() => {
                    // @ts-ignore
                    refParameterPengadaan.current?.refReTender()?.then(() => {
                      setIsLoadingButton(false);
                    }).catch(() => {
                      setIsLoadingButton(false);
                    });
                  }}
                  handleOnAlihkan={() => {
                    // @ts-ignore
                    refParameterPengadaan.current?.refAlihkan()?.then(() => {
                      setIsLoadingButton(false);
                    }).catch(() => {
                      setIsLoadingButton(false);
                    });
                  }}
                  isOwner={isOwner}
                />
              </KTCardBody>
            </KTCard>
          </div>
        </div>
        <div className={"col-md-9 col-sm-12 col-lg-9"}>

          <div className={"d-flex align-items-end justify-content-end"}>
            <div className={"mb-5"}>
              {proposalTender && (
                <ButtonToolbar
                  proposalTender={proposalTender}
                  isLoading={isLoadingButton}
                  quotation={quotation}
                  isOwner={isOwner}
                  onSubmit={() => {
                    // @ts-ignore
                    refParameterPengadaan.current?.refSubmit().then(() => {
                      setIsLoadingButton(false);
                    }).catch(() => {
                      setIsLoadingButton(false);
                    });
                  }}
                  onDraft={() => {
                    setIsLoadingButton(true);
                    // @ts-ignore
                    refParameterPengadaan.current?.refSaveDraft().then(() => {
                      setIsLoadingButton(false);
                    }).catch(() => {
                      setIsLoadingButton(false);
                    });
                  }}
                  onCancel={(title: string, text: string, action: any) => {
                    setIsLoadingButton(true);
                    // @ts-ignore
                    refParameterPengadaan.current?.refCancel(title, text, action).then(() => {
                      setIsLoadingButton(false);
                    }).catch(() => {
                      setIsLoadingButton(false);
                    });
                  }}
                  onCancelPQ={(title: string, text: string, action: any) => {
                    setIsLoadingButton(true);
                    // @ts-ignore
                    refParameterPengadaan.current?.refCancelPQ(title, text, action).then(() => {
                      setIsLoadingButton(false);
                    }).catch(() => {
                      setIsLoadingButton(false);
                    });
                  }}
                  onPublishPQ={(title: string, text: string, action: any) => {
                    // @ts-ignore
                    refParameterPengadaan.current?.refPublishPQ(title, text, action).then(() => {
                      setIsLoadingButton(false);
                    }).catch(() => {
                      setIsLoadingButton(false);
                    });
                  }}
                  onLanjutPQ={(title: string, text: string, action: any) => {
                    setIsLoadingButton(true);
                    // @ts-ignore
                    refParameterPengadaan.current?.refContinuePQ(title, text, action).then(() => {

                    }).catch(() => {
                      setIsLoadingButton(false);
                    });
                  }}
                  onBidOpening={(title: string, text: string, action: any) => {
                    setIsLoadingButton(true);
                    // @ts-ignore
                    refParameterPengadaan.current?.refSubmitBidOpening(title, text, action).then(() => {

                    }).catch(() => {
                      setIsLoadingButton(false);
                    });
                  }}
                  onSubmitEvaluasi={(title: string, text: string, action: any) => {
                    setIsLoadingButton(true);
                    // @ts-ignore
                    refParameterPengadaan.current?.refSubmitEvaluasi(title, text, action).then(() => {
                      setIsLoadingButton(false);
                    }).catch(() => {
                      setIsLoadingButton(false);
                    });
                  }}
                  onSaveKonfigurasiNego={(title: string, text: string, type: string, action: any) => {
                    setIsLoadingButton(true);
                    // @ts-ignore
                    refParameterPengadaan.current?.refSaveKonfigurasiNego(title, text, configNego, type, action).then(() => {

                    }).catch((e: any) => {
                      toast.error(e)
                    }).finally(() => {
                      setIsLoadingButton(false);
                    });
                  }}
                  onApprove={() => {
                    getDetail();
                  }}
                  onCloseNegosiasi={(title: string, text: string, action: any) => {
                    setIsLoadingButton(true);
                    // @ts-ignore
                    refParameterPengadaan.current?.refSubmitCloseNegosiasi(title, text, configNego, action).then(() => {
                      setIsLoadingButton(false);
                    }).catch(() => {
                      setIsLoadingButton(false);
                    });
                  }}
                  onSubmitToAwarding={(title: string, text: string, action: any) => {
                    setIsLoadingButton(true);
                    // @ts-ignore
                    refParameterPengadaan.current?.refSubmitToAwarding(title, text, configNego, action).then(() => {
                      setIsLoadingButton(false);
                    }).catch(() => {
                      setIsLoadingButton(false);
                    });
                  }}
                  onSubmitReportAwarding={(title: string, text: string, action: any) => {
                    setIsLoadingButton(true);
                    // @ts-ignore
                    refParameterPengadaan.current?.refSubmitReportAwarding(title, text, action).then(() => {
                      setIsLoadingButton(false);
                    }).catch(() => {
                      setIsLoadingButton(false);
                    });
                  }}
                  onSubmitPengumumanPemenang={(title: string, text: string, action: any) => {
                    setIsLoadingButton(true);
                    // @ts-ignore
                    refParameterPengadaan.current?.refSubmitPengumumanPemenang(title, text, action).then(() => {
                      setIsLoadingButton(false);
                    }).catch(() => {
                      setIsLoadingButton(false);
                    });
                  }}
                  onSubmitPenunjukkanPemenang={(title:string, text:string, action:any)=>{
                    setIsLoadingButton(true);
                    // @ts-ignore
                    refParameterPengadaan.current?.refSubmitPenunjukkanPemenang(title, text, action).then(() => {
                      setIsLoadingButton(false);
                    }).catch(() => {
                      setIsLoadingButton(false);
                    });
                  }}
                />
              )}
            </div>
          </div>
          {/*
						Karena button parameter pengadaan form selalu diatas, maka kita bisa menggunakan ref untuk mengakses methodnya
					*/}
          <ToolbarRightLayout />
          <div className={"card"}>
            <div className={"card-body"}>
              {isLoading && (
                <div>
                  <Placeholder.Paragraph rows={30} active />
                </div>
              )}
              {(
                (proposalTender?.status_detail === "Submit for Approval Cancel" || proposalTender?.status === "Cancel") &&
                proposalTender?.cancel_reason
              )  && (
                <div className={"alert alert-warning mb-5 text-dark "}>
                  <i className={"fa fa-exclamation-triangle"}></i> {proposalTender?.cancel_reason}
                </div>
              )}
              <Routes>
                <Route path="" element={<Outlet />}>
                  <Route
                    index
                    element={
                      <ParameterPengadaanPage
                        show={selectedMenu.index === 0}
                        ref={refParameterPengadaan}
                        proposalTender={proposalTender}
                        quotation={quotation}
                        key={renderKey}
                        afterSaveDraft={() => getDetail()}
                        onUpdateLoading={(state: boolean) => setIsLoadingButton(state)}
                        onGoBack={() => goBack()}
                        isOwner={isOwner}
                        parent={this}
                      />
                    }
                  />
                  <Route path='item' element={<ItemPRPage />} />
                  <Route path='dokumen' element={<DokumenPengadaanPage isOwner={isOwner}/>} />
                  <Route 
                    path='jadwal' 
                    element={
                      <JadwalTenderPage 
                        proposalTender={proposalTender} 
                        onRefresh={()=>getDetail()} 
                        isOwner={isOwner}
                      />
                    } 
                  />
                  {/*TODO-MENU: 1. tambahkan bintang di akhir*/}
                  <Route 
                    path='persyaratan/*' 
                    element={
                      <PerysaratanTenderPageV2 
                        proposalTender={proposalTender} 
                        onRefresh={()=>getDetail()} 
                        onUpdateLoading={(state: boolean) => setIsLoadingButton(state)}
                        isOwner={isOwner}
                      />
                    } 
                  />
                  <Route path='panitia' element={<PanitiaTenderForm proposalTender={proposalTender} isOwner={isOwner}/>} />
                  <Route 
                    path='dur' 
                    element={
                      <DURPage 
                        afterSubmit={()=>getDetail()} 
                        onUpdateLoading={(state: boolean) => setIsLoadingButton(state)} 
                        afterSaveDraft={() => getDetail()} 
                        isOwner={isOwner}
                      />
                    } 
                  />
                  <Route 
                    path='pq' 
                    element={
                      <PraQualificationEvaluatePage 
                        proposalTender={proposalTender} 
                        onRefresh={()=>getDetail()} 
                        isOwner={isOwner} 
                      />
                    } 
                  />
                  <Route
                    path='aanwijzing'
                    element={
                      <>
                        {!proposalTender?.aanwijzing_uuid &&(
                          <GenerateAanwijzing isOwner={isOwner}/>
                        )}
                        {proposalTender?.aanwijzing_uuid && (
                          <AanwijzingDetail
                            key={proposalTender?.aanwijzing_uuid}
                            aanwijzingUuid={proposalTender?.aanwijzing_uuid}
                            isShowBackButton={false}
                          />
                        )}
                      </>
                    }
                  />
                  <Route
                    path='negosiasi'
                    element={
                      <NegosiasiPage onSetKonfigurasiNego={()=>getDetail()} isOwner={isOwner}/>
                    }
                  />

                  <Route
                    path='proses-tender/peserta'
                    element={
                      <PesertaTenderPage
                        proposalTender={proposalTender}
                        onQuotationChanged={(e)=>{
                          setQuotation(e)
                          getDetail()
                        }} 
                        isOwner={isOwner}
                      />
                    }
                  />

                  {proposalTender?.metode_penyampaian === "1 Sampul" && (
                    <Route
                      path='proses-tender/evaluasi'
                      element={
                        <EvaluasiTeknisSatuSampulPage
                          proposalTender={proposalTender}
                          onQuotationChanged={()=>getDetail()}
                          isOwner={isOwner}
                        />
                      }
                    />
                  )}
                  {proposalTender?.metode_penyampaian === "2 Sampul" && (
                    <Route
                      path='proses-tender/evaluasi'
                      element={
                        <EvaluasiTeknisDuaSampulPage
                          proposalTender={proposalTender}
                          onQuotationChanged={()=>{getDetail()}}
                          isOwner={isOwner}
                        />
                      }
                    />
                  )}
                  {proposalTender?.metode_penyampaian === "2 Tahap" && (
                    <>
                      <Route
                        path='proses-tender/evaluasi'
                        element={
                          <EvaluasiTeknisDuaTahapPage
                            proposalTender={proposalTender}
                            onQuotationChanged={()=>getDetail()}
                            isOwner={isOwner}
                          />
                        }
                      />
                      <Route
                        path='proses-tender/evaluasi-komersil'
                        element={
                          <EvaluasiKomersilDuaTahapPage
                            proposalTender={proposalTender}
                            onQuotationChanged={()=>getDetail()}
                            isOwner={isOwner}
                          />
                        }
                      />
                    </>
                  )}

                  <Route
                    path='awarding'
                    element={
                      <AwardingPage
                        proposalTender={proposalTender}
                        afterSaveDraft={()=>getDetail()}
                        onRefresh={()=>getDetail()}
                        isOwner={isOwner}
                      />
                    }
                  />
                  <Route
                    path='pengumuman-pemenang'
                    element={
                      <PengumumanPemenangPage
                        proposalTender={proposalTender}
                        onRefresh={()=>getDetail()}
                        isOwner={isOwner}
                      />
                    }
                  />
                  <Route
                    path='penunjukkan-pemenang'
                    element={
                      <PenunjukkanPemenangPage
                        proposalTender={proposalTender}
                        onRefresh={()=>getDetail()}
                        isOwner={isOwner}
                      />
                    }
                  />
                </Route>
              </Routes>
              {/*{(!isLoading && proposalTender) && (*/}
              {/*  <>*/}
              {/*    <ParameterPengadaanPage*/}
              {/*      show={selectedMenu.index === 0}*/}
              {/*      ref={refParameterPengadaan}*/}
              {/*      proposalTender={proposalTender}*/}
              {/*      quotation={quotation}*/}
              {/*      key={renderKey}*/}
              {/*      afterSaveDraft={() => getDetail()}*/}
              {/*      onUpdateLoading={(state: boolean) => setIsLoadingButton(state)}*/}
              {/*      onGoBack={() => goBack()}*/}
              {/*      parent={this} />*/}
              {/*    <>*/}
              {/*      {selectedMenu?.component && (*/}
              {/*        selectedMenu?.component*/}
              {/*      )}*/}
              {/*    </>*/}
              {/*  </>*/}
              {/*)}*/}
            </div>

          </div>

          {/*{proposalTender && (*/}
          {/*  <ActivityLog proposalTender={proposalTender} />*/}
          {/*)}*/}


        </div>
      </div>
    </>
  )
};

export { ProposalTenderForm };
