import { MetronicErrorMessage, toastError, toastSuccess } from '../../../../../../_metronic/helpers'
import { Field, Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { ApiUrl } from '../../../../../config/Api'

import FormikSelect2 from '../../../../../components/shared/form/FormikSelect2'
import CurrencyInput from 'react-currency-input-field'
import * as Yup from 'yup'
import axios from 'axios'
import { Placeholder } from 'rsuite'

const validationSchema = Yup.object({
  delivery_date: Yup.date().required('This field is required'),
  tax_code: Yup.object().required('This field is required').nullable(),
  // note: Yup.string().required('This field is required'),
  item_text: Yup.string().required('This field is required'),
  specification: Yup.string().required('This field is required'),
})

interface Props {
  disabled: any
  header: any
  data: any
  handleClose: () => void
}

const DetailItemPO = ({ disabled, header, data, handleClose }: Props) => {
  const [loading, setLoading] = useState(false)

  const [initialValues, setInitialValues] = useState({
    specification: '',
    uom: '',
    currency: '',
    delivery_date: '',
    tax_code: '',
    item_text: '',
    note: '',
    serviceLines: [],
  })

  const loadItem = (id: any) => {
    axios
        .get(`${ApiUrl}/api/tender/po-item/${id}`)
        .then((res) => {
          setInitialValues(res.data.data)
        })
        .catch((error) => {
            console.error(error)
        })
        .finally(() => {
          setLoading(false)
        })
  }

  useEffect(() => {
    setLoading(true)
    loadItem(data?.uuid)
  }, [data])

  const onSubmit = async (values: any) => {
    try {
      setLoading(true)
      const value = {
        purchase_order_id: header.uuid,
        delivery_date: values?.delivery_date,
        tax_code_id: values?.tax_code?.code,
        item_text: values?.item_text,
        specification: values?.specification,
        note: values?.note,
      }

      const response = await axios.put(`${ApiUrl}/api/tender/po-item/${data?.uuid}`, value)

      toastSuccess(response?.data?.meta?.message)
      loadItem(data?.uuid)
      handleClose()
    } catch (error: any) {
      toastError(error?.response?.data?.meta?.message || 'Terjadi kesalahan')
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <div className='card border mt-5'>
        <div className='card-body'>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {({ values, setFieldValue }) => (
              <>
                <Form id='form-additionalCostPO'>
                  <Row>
                    <Col>
                      <div className="mb-7">
                        <label htmlFor="comment mb-3 form-label">Spesifikasi Barang / Material Text <span className='text-danger'>*</span></label>
                        {loading ? (
                          <Placeholder.Paragraph/>
                        ) : (
                          <>
                            <Field
                              as="textarea"
                              name="specification"
                              id="specification"
                              className="form-control"
                              disabled={
                                header?.status !== 'Draft' && 
                                header?.status !== 'Vendor Approval Rejection' && 
                                header?.status !== 'PO Rejected'
                              }
                            />
                            <MetronicErrorMessage name='specification'/>
                          </>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <div className='mb-7'>
                        <label htmlFor='exampleFormControlInput1' className='form-label'>
                          <span>Quantity</span>
                        </label>
                        {loading ? (
                          <Placeholder.Paragraph/>
                        ) : (
                          <Field
                            disabled
                            name='quantity'
                            value={data?.quantity}
                            type='text'
                            className='form-control'
                            placeholder=''
                          />
                        )}
                      </div>
                      <div className='mb-7'>
                        <label
                          htmlFor='exampleFormControlInput1'
                          className='form-label'
                        >
                          Unit Price
                        </label>
                        {loading ? (
                          <Placeholder.Paragraph/>
                        ) : (
                          <CurrencyInput
                            className='form-control'
                            groupSeparator='.'
                            decimalSeparator=','
                            decimalsLimit={2}
                            step={1}
                            value={data?.valuation_price || 0}
                            disabled
                          />
                        )}
                      </div>
                      <div className='mb-7'>
                        <label
                          htmlFor='exampleFormControlInput1'
                          className='form-label'
                        >
                          Additional Cost (dari penawaran)
                        </label>
                        {loading ? (
                          <Placeholder.Paragraph/>
                        ) : (
                          <CurrencyInput
                            className='form-control'
                            groupSeparator='.'
                            decimalSeparator=','
                            decimalsLimit={2}
                            step={1}
                            value={data?.additional_cost || 0}
                            disabled
                          />
                        )}
                      </div>
                      <div className='mb-7'>
                        <label
                          htmlFor='exampleFormControlInput1'
                          className='form-label'
                        >
                          Total Price
                        </label>
                        {loading ? (
                          <Placeholder.Paragraph/>
                        ) : (
                          <CurrencyInput
                            className='form-control'
                            groupSeparator='.'
                            decimalSeparator=','
                            decimalsLimit={2}
                            step={1}
                            value={data?.total_value || 0}
                            disabled
                          />
                        )}
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className='mb-7'>
                        <label htmlFor='' className='form-label'>
                          Satuan
                        </label>
                        {loading ? (
                          <Placeholder.Paragraph/>
                        ) : (
                          <FormikSelect2
                            name='uom'
                            optionsUrl={`${ApiUrl}/api/masterdata/satuan`}
                            getOptionValue={(opt: any) => opt}
                            getOptionLabel={(opt: any) => `${opt.uom} - ${opt.uom_name}`}
                            unique_property={'id'}
                            search_property='uom_uom_name'
                            isDisabled={true}
                          />
                        )}
                      </div>
                      <div className='mb-7'>
                        <label htmlFor='' className='form-label'>
                          Currency
                        </label>
                        {loading ? (
                          <Placeholder.Paragraph/>
                        ) : (
                          <FormikSelect2
                            name="currency"
                            optionsUrl={`${ApiUrl}/api/masterdata/currency`}
                            unique_property={"id"}
                            getOptionValue={(opt: any) => opt}
                            getOptionLabel={(opt: any) => opt.code + " - " + opt.long_text}
                            search_property={"code"}
                            isDisabled={true}
                          />
                        )}
                      </div>
                      <div className='mb-7'>
                        <label
                          htmlFor=''
                          className='form-label required'
                        >
                          Delivery date
                        </label>
                        {loading ? (
                          <Placeholder.Paragraph/>
                        ) : (
                          <>
                            <Field
                              className='form-control'
                              name='delivery_date'
                              type='date'
                              disabled={
                                header?.status !== 'Draft' && 
                                header?.status !== 'Vendor Approval Rejection' && 
                                header?.status !== 'PO Rejected'
                              }
                            />
                            <MetronicErrorMessage name='delivery_date'/>
                          </>
                        )}
                      </div>
                      <div className='mb-7'>
                        <label htmlFor='' className='form-label required'>
                          Tax Code
                        </label>
                        {loading ? (
                          <Placeholder.Paragraph/>
                        ) : (
                          <FormikSelect2
                            name="tax_code"
                            optionsUrl={`${ApiUrl}/api/masterdata/tax`}
                            unique_property={"id"}
                            getOptionValue={(opt: any) => opt}
                            getOptionLabel={(opt: any) => opt.code + " - " + opt.description}
                            search_property={"code_description"}
                            isDisabled={
                              header?.status !== 'Draft' && 
                              header?.status !== 'Vendor Approval Rejection' && 
                              header?.status !== 'PO Rejected'
                            }
                            required
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="mb-7">
                        <label htmlFor="comment mb-3 form-label">Item Text <span className='text-danger'>*</span></label>
                        {loading ? (
                          <Placeholder.Paragraph/>
                        ) : (
                          <>
                            <Field
                              as="textarea"
                              name="item_text"
                              id="item_text"
                              className="form-control"
                              disabled={
                                header?.status !== 'Draft' && 
                                header?.status !== 'Vendor Approval Rejection' && 
                                header?.status !== 'PO Rejected'
                              }
                            />
                            <MetronicErrorMessage name='item_text'/>
                          </>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="mb-7">
                        <label htmlFor="comment mb-3 form-label">Note </label>
                        {loading ? (
                          <Placeholder.Paragraph/>
                        ) : (
                          <>
                            <Field
                              as="textarea"
                              name="note"
                              id="note"
                              className="form-control"
                              disabled={
                                header?.status !== 'Draft' && 
                                header?.status !== 'Vendor Approval Rejection' && 
                                header?.status !== 'PO Rejected'
                              }
                            />
                            <MetronicErrorMessage name='note'/>
                          </>
                        )}
                      </div>
                    </Col>
                  </Row>

                  {initialValues?.serviceLines?.length > 0 &&
                      <div className="mt-5 table-responsive" style={{ height: "150px", minWidth: "100%" }}>
                        <table className={"table table-bordered table-minify"}>
                          <thead>
                            <tr className="border-bottom border-gray-200">
                              <th>#</th>
                              <th>Line</th>
                              <th>Service No.</th>
                              <th>Short Text</th>
                              <th>Quantity</th>
                              <th style={{ minWidth: "150px" }}>UoM</th>
                              <th>Gross Price</th>
                              <th>Currency</th>
                              <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {initialValues?.serviceLines && initialValues?.serviceLines?.map((item: any, index: any) => (
                              <tr className="border-bottom border-gray-200 text-grey">
                                <td></td>
                                <td>
                                  <Field
                                    name={`line_no`}
                                    value={item?.line_no}
                                    type="text"
                                    className="form-control form-control-sm"
                                  />
                                </td>
                                <td>
                                  <Field
                                    name={`service_no`}
                                    value={item?.service_no}
                                    type="text"
                                    className="form-control form-control-sm"
                                  />
                                </td>
                                <td>
                                  <Field
                                    name={`short_text`}
                                    value={item?.short_text}
                                    type="text"
                                    className="form-control form-control-sm"
                                  />
                                </td>
                                <td>
                                  <CurrencyInput
                                    style={{ width: "100px" }}
                                    className="form-control form-control-sm"
                                    value={item?.quantity}
                                    decimalSeparator=","
                                    groupSeparator="."
                                    decimalsLimit={0}
                                  />
                                </td>
                                <td>
                                  <Field
                                    name={`uom`}
                                    value={item?.uom?.uom}
                                    type="text"
                                    className="form-control form-control-sm"
                                  />
                                </td>
                                <td>
                                  <CurrencyInput
                                    style={{ width: "100px" }}
                                    className="form-control form-control-sm"
                                    value={item?.net_price}
                                    decimalSeparator=","
                                    groupSeparator="."
                                    decimalsLimit={0}
                                  />
                                </td>
                                <td>
                                  <Field
                                    name={`short_text`}
                                    value={item?.currency?.code}
                                    type="text"
                                    className="form-control form-control-sm"
                                  />
                                </td>
                                <td>
                                  <CurrencyInput
                                    style={{ width: "100px" }}
                                    className="form-control form-control-sm"
                                    value={item?.gross_price}
                                    decimalSeparator=","
                                    groupSeparator="."
                                    decimalsLimit={0}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                  }

                  <div className='d-flex justify-content-end mt-5'>
                    <button 
                      disabled={
                        loading || 
                        (header?.status !== "Draft" && 
                        header?.status !== 'Vendor Approval Rejection' && 
                        header?.status !== "PO Rejected")
                      } 
                      type='submit' className='btn btn-primary'
                    >
                      {loading ? <span className='spinner-border spinner-border-sm me-1'/> : <i className="fas fa-save"></i>}
                      Save
                    </button>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}

export default DetailItemPO
