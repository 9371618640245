import { FC, lazy, Suspense } from 'react'
import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../_metronic/layout/core'
import SnopPage from '../modules/stockview/snop/SnopPage'
import DashboardStockviewPage from '../modules/stockview/dashboard/DashboardStockviewPage'
import DashboardOpsStockviewPage from '../modules/stockview/dashboard-ops/DashboardOpsStockviewPage'
import DashboardStockviewResume from '../modules/stockview/dashboard-resume/DashboardStockviewResume'
import GoodMovementStockview from '../modules/stockview/transaction/good-movement/GoodMovementStockview'
import ConsumptionStockview from '../modules/stockview/transaction/consumption/ConsumptionStockview'
import VesselStockviewPage from '../modules/stockview/vessel-management/VesselStockviewPage'
import { Create } from '../modules/stockview/transaction/good-movement/create/Create'
import { CreateConsumption } from '../modules/stockview/transaction/consumption/create/Create'
import MaterialMasterDataStockView from '../modules/stockview/masterdata/material/MaterialMasterDataStockView'
import PlantMasterDataStockview from '../modules/stockview/masterdata/plant/PlantMasterDataStockview'
import SlocMasterDataStockview from '../modules/stockview/masterdata/sloc/SlocMasterDataStockview'
import CompanyMasterDataStockview from '../modules/stockview/masterdata/company/CompanyMasterDataStockview'
import TransportationMasterDataStockview from '../modules/stockview/masterdata/transportation/TransportationMasterDataStockview'
import VendorMasterDataStockview from '../modules/stockview/masterdata/vendorstock/VendorMasterDataStockview'
import ProductionStockview from '../modules/stockview/transaction/production/ProductionStockview'
import { CreateProduction } from '../modules/stockview/transaction/production/create/Create'
import { ArrivalStockviewList } from '../modules/stockview/transaction/arrival/components/ArrivalStockviewList'
import { CreateArrival } from '../modules/stockview/transaction/arrival/create/Create'
import SafetyStockStockview from '../modules/stockview/transaction/safety-stock/SafetyStockStockview'
import { CreateSafetyStock } from '../modules/stockview/transaction/safety-stock/create/Create'
import MaxStockStockview from '../modules/stockview/transaction/max-stock/MaxStockStockview'
import { CreateMaxStock } from '../modules/stockview/transaction/max-stock/create/Create'
import { CreateTransportation } from '../modules/stockview/masterdata/transportation/create/Create'
import GoodMovementSAPStockview from '../modules/stockview/transaction/good-movement-sap/GoodMovementSAPStockview'
import FleetRegistrationStockview from '../modules/stockview/vessel-management/fleet-registration/FleetRegistrationStockview'
import SAPDailyStockviewView from '../modules/stockview/transaction/sap-daily-stock/SAPDailyStockviewView'
import UserGuideStockview from '../modules/stockview/user-guide/UserGuideStockview'
import PPICMasterDataStockview from '../modules/stockview/masterdata/ppic/PPICMasterDataStockview'
import PPICTransactionStockview from '../modules/stockview/transaction/ppic-transaction/PPICTransactionStockview'
import { BuletinPriceUpdateStockviewList } from '../modules/stockview/buletin-price/buletin-price-update/components/BuletinPriceUpdateStockviewList'
import { CreateBuletinPrice } from '../modules/stockview/buletin-price/buletin-price-update/create/Create'
import { JenisPublikasiStockViewList } from '../modules/stockview/masterdata/jenis-publikasi/components/JenisPublikasiStockViewList'
import { CreateJenisPublikasi } from '../modules/stockview/masterdata/jenis-publikasi/create/CreateJenisPublikasi'
import { BuletinDashboardStockview } from '../modules/stockview/buletin-price/dashboard/components/BuletinDashboardStockview'
import DetailBuletinStockview from '../modules/stockview/buletin-price/dashboard/components/DetailBuletinStockview'
import MonitoringAkurasiDataStockview from '../modules/stockview/transaction/monitoring-akurasi-data/MonitoringAkurasiDataStockview'
import VesselListStockview from '../modules/stockview/vessel-management/vessel-list/VesselListStockview'
import VesselRouteHistory from '../modules/stockview/vessel-management/vessel-history/VesselRouteHistory'
import TransportationVesselHistoryLog from '../modules/stockview/vessel-management/vessel-history-log/TransportationVesselHistoryLog'
// import AnnouncementCategoryPage from '../modules/master-data/announcement-category/AnnouncementCategoryPage'

const listBreadcrumbs: Array<PageLink> = [
  {
    title: 'Stockview',
    path: '/stockview',
    isSeparator: false,
    isActive: false,
  },
]

const StockviewRoutes = () => {
  return (
    <>
      <Routes>
        <Route index element={<Navigate to='/stockview/dashboard' />} />
        <Route element={<Outlet />}>
          <Route
            path='/dashboard'
            element={
              <>
                <PageTitle breadcrumbs={listBreadcrumbs}>Dashboard</PageTitle>
                <DashboardStockviewPage />
              </>
            }
          />
        </Route>
      </Routes>
      <Routes>
        <Route element={<Navigate to='/stockview/dashboard-ops' />} />
        <Route element={<Outlet />}>
          <Route
            path='/dashboard-ops'
            element={
              <>
                <PageTitle breadcrumbs={listBreadcrumbs}>Dashboard Operasional</PageTitle>
                <DashboardOpsStockviewPage />
              </>
            }
          />
        </Route>
      </Routes>
      <Routes>
        <Route element={<Navigate to='/stockview/dashboard-resume' />} />
        <Route element={<Outlet />}>
          <Route
            path='/dashboard-resume'
            element={
              <>
                <PageTitle breadcrumbs={listBreadcrumbs}>Dashboard Resume</PageTitle>
                <DashboardStockviewResume />
              </>
            }
          />
        </Route>
      </Routes>
      <Routes>
        <Route element={<Navigate to='/stockview/transaction/goods-movement' />} />
        <Route element={<Outlet />}>
          <Route
            path='/transaction/goods-movement'
            element={
              <>
                <PageTitle breadcrumbs={listBreadcrumbs}>IN OUT / GOOD MOVEMENT</PageTitle>
                <GoodMovementStockview />
              </>
            }
          />
        </Route>
      </Routes>
      <Routes>
        <Route element={<Navigate to='/stockview/transaction/goods-movement-sap' />} />
        <Route element={<Outlet />}>
          <Route
            path='/transaction/goods-movement-sap'
            element={
              <>
                <PageTitle breadcrumbs={listBreadcrumbs}>Good Movement SAP</PageTitle>
                <GoodMovementSAPStockview />
              </>
            }
          />
        </Route>
      </Routes>
      <Routes>
        <Route element={<Outlet />}>
          <Route
            path='/transaction/goods-movement/create'
            element={
              <>
                <PageTitle breadcrumbs={listBreadcrumbs}>Goods Movement Create</PageTitle>
                <Create />
              </>
            }
          />
        </Route>
      </Routes>
      <Routes>
        <Route element={<Navigate to='/stockview/transaction/ppic-transaction' />} />
        <Route element={<Outlet />}>
          <Route
            path='/transaction/ppic-transaction'
            element={
              <>
                <PageTitle breadcrumbs={listBreadcrumbs}>PPIC Transaction</PageTitle>
                <PPICTransactionStockview />
              </>
            }
          />
        </Route>
      </Routes>
      <Routes>
        <Route element={<Navigate to='/stockview/transaction/consumption' />} />
        <Route element={<Outlet />}>
          <Route
            path='/transaction/consumption/*'
            element={
              <>
                <PageTitle breadcrumbs={listBreadcrumbs}>CONSUMPTION</PageTitle>
                <ConsumptionStockview />
              </>
            }
          />
        </Route>
      </Routes>
      <Routes>
        <Route element={<Outlet />}>
          <Route
            path='/transaction/consumption/create'
            element={
              <>
                <PageTitle breadcrumbs={listBreadcrumbs}>Consumption Create</PageTitle>
                <CreateConsumption />
              </>
            }
          />
        </Route>
      </Routes>
      <Routes>
        <Route element={<Navigate to='/stockview/transaction/production' />} />
        <Route element={<Outlet />}>
          <Route
            path='/transaction/production'
            element={
              <>
                <PageTitle breadcrumbs={listBreadcrumbs}>Production</PageTitle>
                <ProductionStockview />
              </>
            }
          />
        </Route>
      </Routes>
      <Routes>
        <Route element={<Outlet />}>
          <Route
            path='/transaction/production/create'
            element={
              <>
                <PageTitle breadcrumbs={listBreadcrumbs}>Production Create</PageTitle>
                <CreateProduction />
              </>
            }
          />
        </Route>
      </Routes>
      <Routes>
        <Route element={<Navigate to='/stockview/transaction/arrival' />} />
        <Route element={<Outlet />}>
          <Route
            path='/transaction/arrival'
            element={
              <>
                <PageTitle breadcrumbs={listBreadcrumbs}>Kedatangan</PageTitle>
                <ArrivalStockviewList />
              </>
            }
          />
        </Route>
      </Routes>
      <Routes>
        <Route element={<Outlet />}>
          <Route
            path='/transaction/arrival/create'
            element={
              <>
                <PageTitle breadcrumbs={listBreadcrumbs}>Kedatangan Create</PageTitle>
                <CreateArrival />
              </>
            }
          />
        </Route>
      </Routes>
      <Routes>
        <Route element={<Navigate to='/stockview/transaction/safety-stock' />} />
        <Route element={<Outlet />}>
          <Route
            path='/transaction/safety-stock'
            element={
              <>
                <PageTitle breadcrumbs={listBreadcrumbs}>Safety Stock</PageTitle>
                <SafetyStockStockview />
              </>
            }
          />
        </Route>
      </Routes>
      <Routes>
        <Route element={<Outlet />}>
          <Route
            path='/transaction/safety-stock/create'
            element={
              <>
                <PageTitle breadcrumbs={listBreadcrumbs}>Safety Stock Create</PageTitle>
                <CreateSafetyStock />
              </>
            }
          />
        </Route>
      </Routes>
      <Routes>
        <Route element={<Navigate to='/stockview/transaction/max-stock' />} />
        <Route element={<Outlet />}>
          <Route
            path='/transaction/max-stock'
            element={
              <>
                <PageTitle breadcrumbs={listBreadcrumbs}>Max Stock</PageTitle>
                <MaxStockStockview />
              </>
            }
          />
        </Route>
      </Routes>
      <Routes>
        <Route element={<Outlet />}>
          <Route
            path='/transaction/max-stock/create'
            element={
              <>
                <PageTitle breadcrumbs={listBreadcrumbs}>Max Stock Create</PageTitle>
                <CreateMaxStock />
              </>
            }
          />
        </Route>
      </Routes>
      <Routes>
        <Route element={<Navigate to='/stockview/transaction/sap-daily' />} />
        <Route element={<Outlet />}>
          <Route
            path='/transaction/sap-daily'
            element={
              <>
                <PageTitle breadcrumbs={listBreadcrumbs}>SAP Daily Stock Plant</PageTitle>
                <SAPDailyStockviewView />
              </>
            }
          />
        </Route>
      </Routes>
      <Routes>
        <Route element={<Navigate to='/stockview/masterdata/material' />} />
        <Route element={<Outlet />}>
          <Route
            path='/masterdata/material'
            element={
              <>
                <PageTitle breadcrumbs={listBreadcrumbs}>Master Data Material</PageTitle>
                <MaterialMasterDataStockView />
              </>
            }
          />
        </Route>
      </Routes>
      <Routes>
        <Route element={<Navigate to='/stockview/masterdata/plant' />} />
        <Route element={<Outlet />}>
          <Route
            path='/masterdata/plant'
            element={
              <>
                <PageTitle breadcrumbs={listBreadcrumbs}>Master Data Plant</PageTitle>
                <PlantMasterDataStockview />
              </>
            }
          />
        </Route>
      </Routes>
      <Routes>
        <Route element={<Navigate to='/stockview/masterdata/sloc' />} />
        <Route element={<Outlet />}>
          <Route
            path='/masterdata/sloc'
            element={
              <>
                <PageTitle breadcrumbs={listBreadcrumbs}>Master Data Sloc</PageTitle>
                <SlocMasterDataStockview />
              </>
            }
          />
        </Route>
      </Routes>
      <Routes>
        <Route element={<Navigate to='/stockview/masterdata/company' />} />
        <Route element={<Outlet />}>
          <Route
            path='/masterdata/company'
            element={
              <>
                <PageTitle breadcrumbs={listBreadcrumbs}>Master Data Company</PageTitle>
                <CompanyMasterDataStockview />
              </>
            }
          />
        </Route>
      </Routes>
      <Routes>
        <Route element={<Navigate to='/stockview/masterdata/vendor' />} />
        <Route element={<Outlet />}>
          <Route
            path='/masterdata/vendor'
            element={
              <>
                <PageTitle breadcrumbs={listBreadcrumbs}>Master Data Vendor</PageTitle>
                <VendorMasterDataStockview />
              </>
            }
          />
        </Route>
      </Routes>
      <Routes>
        <Route element={<Navigate to='/stockview/masterdata/transportation' />} />
        <Route element={<Outlet />}>
          <Route
            path='/masterdata/transportation'
            element={
              <>
                <PageTitle breadcrumbs={listBreadcrumbs}>Master Data Transportation</PageTitle>
                <TransportationMasterDataStockview />
              </>
            }
          />
        </Route>
      </Routes>
      <Routes>
        <Route element={<Outlet />}>
          <Route
            path='/masterdata/transportation/create'
            element={
              <>
                <PageTitle breadcrumbs={listBreadcrumbs}>Transportation Create</PageTitle>
                <CreateTransportation />
              </>
            }
          />
        </Route>
      </Routes>
      <Routes>
        <Route element={<Navigate to='/stockview/vessel-management/Dashboard-vessel' />} />
        <Route element={<Outlet />}>
          <Route
            path='/vessel-management/Dashboard-vessel'
            element={
              <>
                <PageTitle breadcrumbs={listBreadcrumbs}>Vessel Management</PageTitle>
                <VesselStockviewPage />
              </>
            }
          />
        </Route>
      </Routes>
      <Routes>
        <Route element={<Navigate to='/stockview/vessel-management/vessel-list' />} />
        <Route element={<Outlet />}>
          <Route
            path='/vessel-management/vessel-list'
            element={
              <>
                <PageTitle breadcrumbs={listBreadcrumbs}>Vessel List</PageTitle>
                <VesselListStockview />
              </>
            }
          />
        </Route>
      </Routes>
      <Routes>
        <Route element={<Navigate to='/stockview/vessel-management/vessel-route-history' />} />
        <Route element={<Outlet />}>
          <Route
            path='/vessel-management/vessel-route-history'
            element={
              <>
                <PageTitle breadcrumbs={listBreadcrumbs}>Vessel History</PageTitle>
                <VesselRouteHistory />
              </>
            }
          />
        </Route>
      </Routes>
      <Routes>
        <Route element={<Navigate to='/stockview/vessel-management/vessel-history-log' />} />
        <Route element={<Outlet />}>
          <Route
            path='/vessel-management/vessel-history-log'
            element={
              <>
                <PageTitle breadcrumbs={listBreadcrumbs}>Transportation Vessel History Log</PageTitle>
                <TransportationVesselHistoryLog />
              </>
            }
          />
        </Route>
      </Routes>
      <Routes>
        <Route element={<Navigate to='/stockview/vessel-management/fleet-registration' />} />
        <Route element={<Outlet />}>
          <Route
            path='/vessel-management/fleet-registration'
            element={
              <>
                <PageTitle breadcrumbs={listBreadcrumbs}>Fleet Registration</PageTitle>
                <FleetRegistrationStockview />
              </>
            }
          />
        </Route>
      </Routes>
      <Routes>
        <Route element={<Navigate to='/stockview/vessel-management/fleet-registration' />} />
        <Route element={<Outlet />}>
          <Route
            path='/vessel-management/fleet-registration'
            element={
              <>
                <PageTitle breadcrumbs={listBreadcrumbs}>Fleet Registration</PageTitle>
                <FleetRegistrationStockview />
              </>
            }
          />
        </Route>
      </Routes>
      <Routes>
        <Route element={<Navigate to='/stockview/User-Guide' />} />
        <Route element={<Outlet />}>
          <Route
            path='/User-Guide'
            element={
              <>
                <PageTitle breadcrumbs={listBreadcrumbs}>User Guide</PageTitle>
                <UserGuideStockview />
              </>
            }
          />
        </Route>
      </Routes>
      <Routes>
        <Route element={<Navigate to='/stockview/masterdata/ppic' />} />
        <Route element={<Outlet />}>
          <Route
            path='/masterdata/ppic'
            element={
              <>
                <PageTitle breadcrumbs={listBreadcrumbs}>PPIC</PageTitle>
                <PPICMasterDataStockview />
              </>
            }
          />
        </Route>
      </Routes>
      <Routes>
        <Route element={<Navigate to='/stockview/masterdata/jenis-publikasi' />} />
        <Route element={<Outlet />}>
          <Route
            path='/masterdata/jenis-publikasi'
            element={
              <>
                <PageTitle breadcrumbs={listBreadcrumbs}>Master Data Jenis Publikasi</PageTitle>
                <JenisPublikasiStockViewList />
              </>
            }
          />
        </Route>
      </Routes>
      <Routes>
        <Route element={<Outlet />}>
          <Route
            path='/masterdata/jenis-publikasi/create'
            element={
              <>
                <PageTitle breadcrumbs={listBreadcrumbs}>Data Jenis Publikasi Create</PageTitle>
                <CreateJenisPublikasi />
              </>
            }
          />
        </Route>
      </Routes>
      <Routes>
        <Route element={<Navigate to='/stockview/price-bulletin/table-price-bulletin' />} />
        <Route element={<Outlet />}>
          <Route
            path='/price-bulletin/table-price-bulletin'
            element={
              <>
                <PageTitle breadcrumbs={listBreadcrumbs}>Bulletin Database Data</PageTitle>
                <BuletinPriceUpdateStockviewList />
              </>
            }
          />
        </Route>
      </Routes>
      <Routes>
        <Route element={<Outlet />}>
          <Route
            path='/price-bulletin/table-price-bulletin/create'
            element={
              <>
                <PageTitle breadcrumbs={listBreadcrumbs}>Bulletin Create</PageTitle>
                <CreateBuletinPrice />
              </>
            }
          />
        </Route>
      </Routes>
      <Routes>
        <Route element={<Navigate to='/stockview/price-bulletin/dashboard-price-bulletin' />} />
        <Route element={<Outlet />}>
          <Route
            path='/price-bulletin/dashboard-price-bulletin'
            element={
              <>
                <PageTitle breadcrumbs={listBreadcrumbs}>Bulletin Dashboard</PageTitle>
                <BuletinDashboardStockview />
              </>
            }
          />
        </Route>
      </Routes>
      <Routes>
        <Route element={<Outlet />}>
          <Route
            path='/buletin-price/dashboard/components/DetailBuletinStockview'
            element={
              <>
                <PageTitle breadcrumbs={listBreadcrumbs}>Detail Bulletin</PageTitle>
                <DetailBuletinStockview />
              </>
            }
          />
        </Route>
      </Routes>
      <Routes>
        <Route element={<Navigate to='/stockview/transaction/monitoring-akurasi-data' />} />
        <Route element={<Outlet />}>
          <Route
            path='/transaction/monitoring-akurasi-data'
            element={
              <>
                <PageTitle breadcrumbs={listBreadcrumbs}>Monitoring Akurasi Data</PageTitle>
                <MonitoringAkurasiDataStockview />
              </>
            }
          />
        </Route>
      </Routes>
      <Routes>
        <Route element={<Navigate to='/stockview/snop' />} />
        <Route element={<Outlet />}>
          <Route
            path='/snop'
            element={
              <>
                <PageTitle breadcrumbs={listBreadcrumbs}>SNOP</PageTitle>
                <SnopPage />
              </>
            }
          />
        </Route>
      </Routes>
    </>
  )
}

export default StockviewRoutes
