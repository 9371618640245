import {FC, useState} from 'react'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import {useIntl} from 'react-intl'
import {NavLink, useLocation} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useLang, setLanguage} from '../../../../_metronic/i18n/Metronici18n'
import {Dropdown1} from '../../../../_metronic/partials'
import {Languages} from '../../../../_metronic/partials/layout/header-menus/Languages'

const NavigationBar: FC = () => {
  const intl = useIntl()
  const lang = useLang()
  const location = useLocation()
  const languages = [
    {
      lang: 'en',
      name: 'English',
      flag: toAbsoluteUrl('/media/flags/united-states.svg'),
    },
    {
      lang: 'id',
      name: 'Indonesia',
      flag: toAbsoluteUrl('/media/flags/indonesia.svg'),
    },
  ]
  const currentLanguage = languages.find((x) => x.lang === lang)

  return (
    <Navbar expand='lg'>
      <Container>
        <Navbar.Brand href='/'>
          <img
            src='https://www.pupuk-indonesia.com/assets/img/logo.png'
            alt='logo pupuk indonesia'
            height='40px'
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='me-auto'>
            <Nav.Link>
              <NavLink to='/' className='text-gray-900 fw-bold fs-4 text-hover-primary'>
                Home
              </NavLink>
            </Nav.Link>
            <Nav.Link>
              <NavLink to='/contact' className='text-gray-900 fw-bold fs-4 text-hover-primary'>
                {intl.formatMessage({id: 'HOMEPAGE.CONTACT'})}
              </NavLink>
            </Nav.Link>
          </Nav>
          <div className='my-0'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              <img
                className='w-15px h-15px rounded-1 ms-2'
                src={currentLanguage?.flag}
                alt='metronic'
              />
            </button>
            <Dropdown1 />
          </div>
          <div className='d-flex gap-2'>
            {location.pathname === '/vendor-register' ? (
              <>
                <NavLink to='/' className='btn btn-primary mx-2'>
                  <i className='fas fa-fw fa-chevron-left'></i>
                  {intl.formatMessage({id: 'HOMEPAGE.BACK_TO_HOME'})}
                </NavLink>
              </>
            ):(
              <>
                <NavLink to='/auth' className='btn btn-primary'>
                  {intl.formatMessage({id: 'HOMEPAGE.LOGIN'})}
                </NavLink>
                {/*<NavLink to='/vendor-register' className='btn btn-primary'>*/}
                {/*  Sign Up*/}
                {/*</NavLink>*/}
              </>
            )}
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default NavigationBar