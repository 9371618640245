import { Outlet, Route, Routes } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import DatepickerX2 from '../../../components/shared/form/DatepickerX2'
import { getEnv } from '../../../../helpers/procurex'
import { useAuth } from '../../auth'
import { Accordion } from 'react-bootstrap'
import { Field, Form, Formik } from 'formik'
import { toPng } from 'html-to-image'
import axios from 'axios'

const masterData = {
  title: 'Dashboard',
  path: 'stockview/dashboard',
  isSeparator: false,
  isActive: false,
}
const separator = {
  title: '',
  path: '',
  isSeparator: true,
  isActive: false,
}
const breadcrumbs: Array<PageLink> = [masterData, separator]
const childBreadcrumbs: Array<PageLink> = [
  masterData,
  separator,
  {
    title: 'Stockview Dashboard',
    path: 'stockview/dashboard',
    isSeparator: false,
    isActive: false,
  },
  separator,
]

const Circle = ({ bgColor }: { bgColor: string }) => {
  const circleStyle = {
    padding: 10,
    margin: 20,
    display: "inline-block",
    backgroundColor: bgColor,
    borderRadius: "50%",
    width: 25,
    height: 25,
  };

  return (
    <div style={circleStyle}>
    </div>
  );
};

const colors1 = ["#07D537"];
const colors2 = ["#F7FF03"];

const renderData1 = colors1?.map((color, index) => <Circle key={index + color} bgColor={color} />);
const renderData2 = colors2?.map((color, index) => <Circle key={index + color} bgColor={color} />);


const DashboardStockviewResume = () => {
  const { currentUser } = useAuth();
  const [itemLoading, setItemLoading] = useState(false)

  // @ts-ignore
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const [id, setId] = useState('')
  const [items, setItems] = useState<any>([])
  const [keterangan, setKeterangan] = useState<any>([])
  const [key, setKey] = useState(0)
  const [isConfirmDialog, setIsConfirmDialog] = useState(false)

  const handleDateChange = (newDate: Date | null) => {

    setSelectedDate(newDate);

    if (newDate) {
      // axios.get(getEnv("stockview") + `/resume-main?filter[posting_date]=${newDate}&perPage=25&page=1`)
      axios.get(getEnv("stockview") + `/resume-main?filter[posting_date]=${newDate}&company_code=${company_code}&perPage=25&page=1`)
        .then((res) => {
          setItems(res.data.data)
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          setItemLoading(false)
        })
      axios
        .get(getEnv("stockview") + `/resume-keterangan?filter[tanggal_keterangan]=${newDate}&perPage=25&page=1`)
        .then((res) => {
          setKeterangan(res.data.data)
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          setItemLoading(false)
        })
      setFormIndex(formIndex + 1);
      setItemLoading(true);
    }
  };

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;

  useEffect(() => {
    loadItem();
  }, [])

  const loadItem = () => {
    setItemLoading(true);

    Promise.all([
      axios.get(getEnv("stockview") + `/resume-main?filter[posting_date]=${formattedDate}&company_code=${company_code}&perPage=25&page=1`),
      axios.get(getEnv("stockview") + `/resume-keterangan?filter[tanggal_keterangan]=${formattedDate}&perPage=25&page=1`)
    ])
      .then(([mainRes, keteranganRes]) => {
        setItems(mainRes.data.data);
        setKeterangan(keteranganRes.data.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setItemLoading(false);
      });
  }

  const groupedItems = items.reduce((acc: any[], item: any) => {
    const existingItem = acc.find((group: any) => group.material_group === item.material_group && group.company_code === item.company_code);

    if (existingItem) {
      existingItem.rowspan += 1;
    } else {
      acc.push({ ...item, rowspan: 1 });
    }
    return acc;
  }, []);


  const calculateRowspan = (groupedItems: any[], currentIndex: number) => {
    let rowspan = 1;
    for (let i = currentIndex + 1; i < groupedItems.length; i++) {
      if (groupedItems[i].material_group === groupedItems[currentIndex].material_group) {
        rowspan++;
      } else {
        break;
      }
    }
    return rowspan;
  };

  let dateTime = new Date()
  const options: any = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    // timeZoneName: 'short',
    timeZone: 'Asia/Jakarta',
  }

  function formatNumber(number: any) {
    return new Intl.NumberFormat('id-ID', { minimumFractionDigits: 0, maximumFractionDigits: 3 }).format(number);
  }

  const [initialValues, setInitialValues] = useState({
    keterangan: "",
    company: "",
    material_group: "",
  });

  const [item, setItem] = useState<any>(null)
  const [keteranganInput, setKeteranganInput] = useState(0);

  const KeteranganInput = (value: any) => {
    setKeteranganInput(value)
  }

  const containerRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const captureScreen = useCallback(() => {
    if (containerRef.current === null) {
      return;
    }

    if (buttonRef.current) {
      buttonRef.current.style.display = 'none';
    }

    toPng(containerRef.current, { cacheBust: true })
      .then((dataUrl) => {
        if (buttonRef.current) {
          buttonRef.current.style.display = '';
        }

        const link = document.createElement('a');
        link.download = 'screenshot-resume.png';
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);

        if (buttonRef.current) {
          buttonRef.current.style.display = '';
        }
      });
  }, [containerRef]);

  const [formIndex, setFormIndex] = React.useState(0);
  const [dataKeterangan, setDataKeterangan] = useState([]);
  const formik = useRef({} as any);

  const generatePayload = (values: any) => {
    return {
      "keterangan": values.keterangan,
      "material_group": values.material_group,
      "company": values.company,
      "tanggal_keterangan": selectedDate ? selectedDate : (values.tanggal_keterangan || formattedDate)
    }
  }

  const handleSaveDraft = async (values: any, errors: any, source = "button") => {
    const payload = generatePayload(values);
  };


  // submit autosave keterangan
  const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    }
  }

  const debounceAutoSave = debounce(async (values: any) => {

    const payload = {
      ...generatePayload(formik.current.values),
    }

    let foundValue = keterangan.map(async (item: any) => {
      if ((values.material_group !== item.material_group && values.company !== item.company) || values.length == 0) {
        return true;
      } else {
        if ((values.material_group == item.material_group && values.company == item.company) || values.length != 0) {
          if (item.keterangan == null) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }
    });

    // axios.put(getEnv('tender') + "/tender/" + props?.proposalTender?.uuid, payload)
    let foundValues = await Promise.all(foundValue);

    let matchingItems = keterangan.filter((item: any) =>
      values.material_group === item.material_group && values.company === item.company
    );

    let matchingIds = matchingItems.map((item: any) => item.id);

    let idToUse = matchingIds.length > 0 ? matchingIds[0] : null;

    if (foundValues.includes(true)) {
      await axios.post(getEnv("stockview") + `/resume-main`, payload);
    } else {
      if (idToUse == null) {
        await axios.post(getEnv("stockview") + `/resume-main`, payload);
      } else {
        await axios.put(getEnv("stockview") + `/resume-keterangan/${idToUse}`, payload);
      }
    }
  }, 1000)
  const handleAutoSave = (values: any) => {
    // @ts-ignore
    debounceAutoSave(values)
  };

  let formattedDateTime = dateTime.toLocaleDateString('en-GB', options);

  formattedDateTime = formattedDateTime.toUpperCase()

  let keteranganCheck = groupedItems?.map((group: any) => {
    return keterangan.map((keteranganItem: any) =>
      keteranganItem.company === group.company && keteranganItem.material_group === group.material_group
    );
  }).filter(item => item !== undefined);

  const mergeKeteranganWithGroupedItems = (groupedItems, keterangan) => {
    const keteranganMap = new Map();
    keterangan.forEach(item => {
      keteranganMap.set(`${item.company}-${item.material_group}`, item.keterangan);
    });

    return groupedItems.map(item => {
      const key = `${item.company_code}-${item.material_group}`;
      const matchedKeterangan = keteranganMap.get(key);
      return { ...item, keterangan: matchedKeterangan || '' };
    });
  };

  const mergedArray = mergeKeteranganWithGroupedItems(groupedItems, keterangan);

  const excludedRoles = ['admin_anper', 'DaanExp_Anper', 'Gudang_Bahan_Baku_Anper', 'Perencanaan_Pengadaan_Anper', 'Managerial_PI'];
  let role = currentUser.has_roles;
  let company_code = currentUser.company_code;

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          index
          element={
            <>
              <PageTitle breadcrumbs={breadcrumbs}>Dashboard Stockview Resume</PageTitle>
              <div className={'bg-white p-3 mt-5'}>
                <p className={'text-center'}>STOCK PER TANGGAL {formattedDateTime}</p>
                <h1 className={'font-bold text-center mt-5 mb-5'}>RESUME STOCK LEVEL</h1>
                <div ref={containerRef}>
                  <div className='card border mt-5'>
                    <Accordion defaultActiveKey={['0']} className='mb-5'>
                      <Accordion.Item eventKey='0'>
                        <Accordion.Body>
                          <div className='card'>
                            <div className='card-body'>
                              <div className='mt-5 table-responsive overflow-hidden' style={{ minWidth: '100%' }}>
                                <div className={'row'}>
                                  <div className={'col-4 mb-2'}>
                                    <DatepickerX2
                                      onChange={handleDateChange}
                                      value={selectedDate}
                                      size={"sm"}
                                      format={"dd MMMM yyyy"}
                                    />
                                  </div>
                                  <div className={'col-8 mb-2 d-flex justify-content-end'}>
                                    <button className="btn btn-primary" onClick={captureScreen} ref={buttonRef} title="Screenshot Layar"><i className="fa fa-camera" aria-hidden="true"></i></button>
                                  </div>
                                </div>
                                <table
                                  className={
                                    'table table-bordered table-rounded border border-dark gs-5'
                                  } style={{ lineHeight: '1' }}
                                >
                                  <thead className={
                                    'thead text-bg-primary'
                                  }>
                                    <tr>
                                      <th
                                        className={'border border-dark fw-bold'}
                                        style={{ fontSize: '15px', minWidth: '150px' }}
                                      >
                                        KOMODITI
                                      </th>
                                      <th
                                        className={'border border-dark fw-bold'}
                                        style={{ fontSize: '15px', minWidth: '100px' }}
                                      >
                                        ANPER
                                      </th>
                                      <th
                                        className={'border border-dark fw-bold'}
                                        style={{ fontSize: '15px', minWidth: '100px' }}
                                      >
                                        SAFETY STOCK (TON)
                                      </th>
                                      <th
                                        className={'border border-dark fw-bold'}
                                        style={{ fontSize: '15px', minWidth: '100px' }}
                                      >
                                        STOCK HARI INI (TON)
                                      </th>
                                      <th
                                        className={'border border-dark fw-bold'}
                                        style={{ fontSize: '15px', minWidth: '100px' }}
                                      >
                                        STATUS
                                      </th>
                                      <th
                                        className={'border border-dark fw-bold'}
                                        style={{ fontSize: '15px', minWidth: '100px' }}
                                      >
                                        KETERANGAN
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {mergedArray?.map((group: any, index: any) => (
                                      <>
                                        {itemLoading ? (
                                          <div className="text-start">
                                            <p>Loading... <span className='spinner-border h-15px w-15px align-middle text-gray-400' /></p>
                                          </div>
                                        ) : (
                                          <tr key={index}>
                                            {index === 0 || mergedArray[index].material_group !== mergedArray[index - 1].material_group ? (
                                              <td
                                                className={'border border-dark'}
                                                rowSpan={calculateRowspan(mergedArray, index)}
                                                align={`center`}
                                                style={{ fontSize: '25px', fontWeight: 'bold', verticalAlign: 'middle' }}
                                              >
                                                {group.material_group}
                                              </td>
                                            ) : null}
                                            <td className={'border text-center border-dark'} style={{ fontSize: '20px', fontWeight: 'bold', verticalAlign: 'middle' }}>{group.company}</td>
                                            <td className={'border text-center border-dark'} style={{ fontSize: '20px', fontWeight: 'bold', verticalAlign: 'middle' }}>{formatNumber(group.safety_stock)}</td>
                                            <td className={'border text-center border-dark'} style={{ fontSize: '20px', fontWeight: 'bold', verticalAlign: 'middle' }}>{formatNumber(group.quantity)}</td>
                                            <td className={'border text-center border-dark'} style={{ verticalAlign: 'middle' }} title={'Jika Stock Kurang Dari Safety Stock Warna Lingkaran Kuning, Begitupun Sebaliknya'}>
                                              {group.quantity > group.safety_stock ? (
                                                renderData1
                                              ) : group.quantity < group.safety_stock ? (
                                                renderData2
                                              ) : (
                                                <p>Invalid status data</p>
                                              )}
                                            </td>
                                            <td className={'border border-dark'} style={{ minWidth: '100%', alignContent: 'center' }}>
                                              <Formik
                                                initialValues={{ material_group: group.material_group, company: group.company_code, keterangan: group.keterangan }}
                                                innerRef={formik}
                                                key={`formik-resume-stockview-` + formIndex}
                                                onSubmit={handleSaveDraft}
                                                enableReinitialize
                                                validateOnChange={true}>
                                                {({ values, setFieldValue }) => {
                                                  const isDisabled = excludedRoles.some(r => role.includes(r));
                                                  return (
                                                    <Form>
                                                      <Field
                                                        as='textarea'
                                                        cols={30}
                                                        rows={3}
                                                        style={{ width: '100%', fontSize: '14px' }}
                                                        name='keterangan'
                                                        // value={matchingKeterangan ? matchingKeterangan.keterangan : ''}
                                                        value={values.keterangan ? values.keterangan : ''}
                                                        onChange={(e: any) => {
                                                          setFieldValue('keterangan', e.target.value)
                                                          handleAutoSave(values)
                                                        }}
                                                        disabled={isDisabled}
                                                      ></Field>
                                                      <Field type="hidden" name="material_group" className="form-control" value={values.material_group} />
                                                      <Field type="hidden" name="company" className="form-control" value={values.company} />
                                                      <Field
                                                        className='form-control'
                                                        name='tanggal_keterangan'
                                                        type='hidden'
                                                        value={selectedDate ? values.tanggal_keterangan || new Date().toISOString() : values.tanggal_keterangan || ''}
                                                      />
                                                    </Form>
                                                  );
                                                }}
                                              </Formik>
                                            </td>
                                          </tr>
                                        )}
                                      </>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
              </div>
            </>
          }
        ></Route>
      </Route>
    </Routes>
  )
}

export default DashboardStockviewResume
