import React, { useState } from "react";
import TableXWrpper from "../../../components/shared/TableXWrapper";
import { KTCard, KTCardBody } from "../../../../_metronic/helpers";
import { NavLink } from "react-router-dom";
import { getEnv, parseNumberToCurrency } from "../../../../helpers/procurex";

const AuctionVendorList = () => {
  const urlIndex = getEnv('auction')+'/as-vendor/auction';

  const [key, setKey] = useState(0)

  const StatusBadge = ({status}:any) => {
    switch (status) {
      case 'done':
        return <span className={`badge badge-primary fw-bolder`}>Done</span>
      case 'draft':
        return <span className={`badge badge-info fw-bolder`}>Draft</span>
      case 'ongoing':
        return <span className={`badge badge-success fw-bolder`}>Ongoing</span>
      default:
        return <span className={`badge badge-light-warning fw-bolder`}>{status}</span>
    }
  }

  const tableColumns = [
    {
      Header: 'Action',
      width: 100,
      Cell: (props: any) => (
        <>
          <div className='d-flex w-100 justify-content-start'>
            <NavLink to={'/auction-v2/auction/' + props?.cell?.row?.original?.uuid} className='p-3'>
              <i className={'fa fa-eye fs-3'} />
            </NavLink>
          </div>
        </>
      ),
    },
    {
      Header: 'Status',
      accessor: 'status',
      width: 100,
      className: "text-center",
      Cell: (props: any) => {
        const data = props.cell.row.original
        return (
          <>
            <StatusBadge status={data?.status} />
          </>
        )
      }
    },
    { Header: "No Auction", accessor: "no_auction", width: 220, className: "text-center text-nowrap" },
    { Header: "No Referensi Proposal Tender", accessor: "no_tender", width: 220, className: "text-center text-nowrap" },
    { Header: "Title", accessor: "nama", width: 250, className: "text-start" },
    // { Header: 'Quantity', accessor: 'quantity', width: 100, className: "text-end" },
    // {
    //   Header: 'Total Price',
    //   accessor: 'total_price',
    //   width: 150,
    //   className: "text-end",
    //   Cell: (props: any) => {
    //     return (
    //       <>
    //         {parseNumberToCurrency(props?.cell?.row?.original?.total_price,props?.cell?.row?.original?.mata_uang)}
    //       </>
    //     )
    //   }
    // },
  ];

  return (
    <>

      {/*<ListToolbar buttonText={'Buat Auction Baru'} setDefaultData={setDefaultData} setShowModalCreate={setIsShowModalCreate} />*/}
      <KTCard>
        <KTCardBody className="py-4">
          <TableXWrpper
            key={key}
            readonly
            urlIndex={urlIndex}
            columns={tableColumns}
            disablePaddingCard={true}
            defaultFilter={"sort=-created_at"}
          />
        </KTCardBody>
      </KTCard>
    </>
  );
};

export { AuctionVendorList };
