import {
  formatNumberToDecimal,
  getEnv,
  parseNumberToCurrency,
  parseNumberToFloat,
  formatNumberX
} from '../../../../../../helpers/procurex'
import React, {useEffect, useRef, useState} from 'react'
import {useAxiosGet, useAxiosPost} from '../../../../../../hooks/useAxios'
import {toast} from 'react-toastify'
import {Field} from 'formik'
import CurrencyInput from 'react-currency-input-field'
import {QuotationItemInterface} from '../../interfaces/QuotationInterface'
import {Button, Popover, Whisper} from 'rsuite'
import clsx from 'clsx'
import FormAttachment from '../../../../../components/shared/form/FormAttachment'
import {useIntl} from 'react-intl'
/**
 * komponen ini digunakan untuk menampilkan item barang
 * @param item
 * @param index
 * @param isDisabledForm
 * @constructor
 */
const TdItemBarang = ({
  proposalTender,
  quotation,
  quotationStep,
  item,
  index,
  isDisabledForm,
  onChange,
}: any) => {
  const [selectedComply, setSelectedComply] = useState<any>(item.quote)
  const [shortText, setShortText] = useState<any>(item?.short_text)
  const [spesifikasi, setSpesifikasi] = useState<any>(item?.specification)
  const [remark, setRemark] = useState<any>(item?.remark)
  const [quantity, setQuantity] = useState<any>(item?.quantity)
  const [valuationPrice, setValuationPrice] = useState<any>(item?.valuation_price)
  const [totalPenawaran, setTotalPenawaran] = useState<any>(item?.total_value)
  const [deliveryTime, setDeliveryTime] = useState<any>(item.delivery_time)

  const [addCost, setAddCost] = useState<any>(item?.additional_cost)
  const [addCostDescription, setAddCostDescription] = useState<any>(
    item?.additional_cost_description
  )

  const [tkdn, setTkdn] = useState<any>(item?.tkdn)
  const [tkdnCertification, setTkdnCertification] = useState<any>(item?.tkdn_certification_number)
  const [tkdnDocument, setTkdnDocument] = useState<any>(item?.tkdn_certification_url)

  const [renderIndex, setRenderIndex] = useState<any>(0)
  const [debounceTimer, setDebounceTimer] = useState<any>(null)
  const [{doSubmit, isLoading, isError, data, isSuccess}] = useAxiosPost(
    getEnv('tender') + `/vendor/quotation-item/${quotation?.uuid}`
  )
  const isNoQuoteShow = () => {
    return proposalTender.order_placement !== 'Paket'
  }
  const isSelected = (value: any) => {
    return selectedComply === value
  }

  const handleOnRowChange = () => {
    const payload = {
      ...item,
      quotation_id: quotation?.uuid,
      quote: selectedComply,
      short_text: shortText,
      specification: spesifikasi,
      remark: remark,
      delivery_time: deliveryTime,
      quantity: formatNumberToDecimal(quantity, 3),
      price: formatNumberToDecimal(valuationPrice, 3),
      tkdn: tkdn,
      additional_cost: formatNumberToDecimal(addCost, 3),
      additional_cost_description: addCostDescription,
      tkdn_certification_number: tkdnCertification,
      tkdn_certification_url: tkdnDocument,
    }
    onChange && onChange(payload)
    // doSubmit(payload, getEnv("tender") + `/vendor/quotation-item/${item?.uuid}`, toast).then((res: any) => {
    //   setTotalPenawaran(res?.data?.total_value);
    // });
  }

  const debounce = (func: any, delay: number) => {
    if (debounceTimer) {
      clearTimeout(debounceTimer)
    }
    const timer = setTimeout(func, delay)
    setDebounceTimer(timer)
  }

  const isShortTextEnabled = () => {
    const _selectedComply = ['DEVIATE']

    if (proposalTender?.metode_penyampaian === '2 Sampul') {
      return quotationStep === 1 && _selectedComply.includes(selectedComply)
    }

    if (proposalTender?.metode_penyampaian === '2 Tahap') {
      if (quotationStep === 1) {
        const allowedStatus = ['Penawaran Teknikal']
        // _selectedComply.push("COMPLY");
        return (
          _selectedComply.includes(selectedComply) && allowedStatus.includes(proposalTender?.status)
        )
      } else if (quotationStep === 2) {
        return false
      }
    }

    return _selectedComply.includes(selectedComply) && !isDisabledForm
  }

  const isSpesifikasiEnabled = () => {
    const _selectedComply = ['DEVIATE']

    if (proposalTender?.metode_penyampaian === '2 Sampul') {
      return quotationStep === 1 && _selectedComply.includes(selectedComply)
    }

    if (proposalTender?.metode_penyampaian === '2 Tahap') {
      if (quotationStep === 1) {
        const allowedStatus = ['Penawaran Teknikal']
        // _selectedComply.push("COMPLY");
        return (
          _selectedComply.includes(selectedComply) && allowedStatus.includes(proposalTender?.status)
        )
      } else if (quotationStep === 2) {
        return false
      }
    }

    return _selectedComply.includes(selectedComply) && !isDisabledForm
  }

  // Disabled, Quantity Hanya Bisa di Adjust Pada Saat Awarding
  const isQuantityEnabled = () => {
    let _selectedComply = ['a']

    if (proposalTender?.metode_penyampaian === '2 Sampul') {
      return quotationStep === 2 && _selectedComply.includes(selectedComply)
    }

    if (proposalTender?.metode_penyampaian === '2 Tahap') {
      if (quotationStep === 1) {
        const allowedStatus = ['Penawaran Teknikal']
        return (
          _selectedComply.includes(selectedComply) && allowedStatus.includes(proposalTender?.status)
        )
      } else if (quotationStep === 2) {
        return false
      }
    }
    return _selectedComply.includes(selectedComply) && !isDisabledForm
  }

  const isValuationPriceEnabled = () => {
    let _selectedComply = ['DEVIATE', 'COMPLY']

    if (proposalTender?.metode_penyampaian === '2 Sampul') {
      _selectedComply.push('COMPLY')
      return quotationStep === 2 && _selectedComply.includes(selectedComply)
    }

    if (proposalTender?.metode_penyampaian === '2 Tahap') {
      const allowedStatus = ['Penawaran Komersil']
      return (
        quotationStep === 2 &&
        _selectedComply.includes(selectedComply) &&
        allowedStatus.includes(proposalTender?.status)
      )
    }
    // _selectedComply.push("COMPLY");
    return _selectedComply.includes(selectedComply) && !isDisabledForm
  }

  const isRemarkEnabled = () => {
    const _selectedComply = ['DEVIATE', 'COMPLY']
    return _selectedComply.includes(selectedComply) && !isDisabledForm
  }

  const isAddCostEnabled = () => {
    // const _selectedComply = ['DEVIATE']

    // if (proposalTender?.metode_penyampaian === '2 Sampul') {
    //   _selectedComply.push('COMPLY')
    //   return quotationStep === 2 && _selectedComply.includes(selectedComply)
    // }

    // if (proposalTender?.metode_penyampaian === '2 Tahap') {
    //   const allowedStatus = ['Penawaran Komersil']
    //   return (
    //     quotationStep === 2 &&
    //     _selectedComply.includes(selectedComply) &&
    //     allowedStatus.includes(proposalTender?.status)
    //   )
    // }
    // _selectedComply.push('COMPLY')
    // return _selectedComply.includes(selectedComply) && !isDisabledForm
    return false
  }

  const isTkdnEnabled = () => {
    const _selectedComply = ['DEVIATE', 'COMPLY']

    if (proposalTender?.metode_penyampaian === '2 Sampul') {
      return quotationStep === 1 && _selectedComply.includes(selectedComply)
    }

    if (proposalTender?.metode_penyampaian === '2 Tahap') {
      if (quotationStep === 1) {
        const allowedStatus = ['Penawaran Teknikal']
        // _selectedComply.push("COMPLY");
        return (
          _selectedComply.includes(selectedComply) && allowedStatus.includes(proposalTender?.status)
        )
      } else if (quotationStep === 2) {
        return false
      }
    }
    _selectedComply.push('COMPLY')
    return _selectedComply.includes(selectedComply) && !isDisabledForm
  }

  const isDeliveryTimeEnabled = () => {
    const _selectedComply = ['DEVIATE', 'COMPLY']
    return _selectedComply.includes(selectedComply) && !isDisabledForm
  }

  const isComplyDeviateEnabled = () => {
    if (proposalTender?.metode_penyampaian === '2 Sampul') {
      return quotationStep === 1
    }
    if (proposalTender?.metode_penyampaian === '2 Tahap') {
      return proposalTender?.status === 'Penawaran Teknikal'
    }
    return !isDisabledForm
  }

  useEffect(() => {
    // if (renderIndex > 0) debounce(handleOnRowChange, 500);
    // setRenderIndex(renderIndex + 1);
    handleOnRowChange()
  }, [
    selectedComply,
    shortText,
    spesifikasi,
    remark,
    quantity,
    valuationPrice,
    deliveryTime,
    addCost,
    addCostDescription,
    tkdn,
    tkdnCertification,
    tkdnDocument,
  ])

  const speaker = (requirement: any) => {
    return (
      <Popover title='Original Requirement'>
        <p style={{whiteSpace: 'pre-wrap'}}>{requirement}</p>
      </Popover>
    )
  }

  return (
    <tr
      style={{
        position: 'relative',
        height: 'auto',
      }}
    >
      <td className={"text-center px-2 border"}>{index+1}</td>
      <td className={'text-center border align-start'}>
        {isLoading && (
          <div
            className={''}
            style={{
              position: 'absolute',
              width: '100%',
              textAlign: 'center',
              backgroundColor: 'rgba(255,255,255,0.5)',
              zIndex: 9999,
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <i className={'fa fa-spinner fa-spin fa-2x'}></i>
          </div>
        )}
        <div className={'d-flex justify-content-center btn-group'}>
          <button
            type={'button'}
            className={`d-flex justify-content-center align-items-center gap-2 btn btn-sm ${
              isSelected('COMPLY') ? 'btn-primary' : 'btn-default'
            }`}
            disabled={!isComplyDeviateEnabled()}
            onClick={() => {
              if (!isDisabledForm) {
                setSelectedComply('COMPLY')
              }
            }}
          >
            <Field
              name={`items.${index}.comply`}
              type='checkbox'
              disabled={!isComplyDeviateEnabled()}
              value={'COMPLY'}
              checked={selectedComply === 'COMPLY'}
              onChange={(e: any) => {
                if (e.target.checked && !isDisabledForm) {
                  setSelectedComply(e.target.value)
                }
              }}
            />{' '}
            Comply
          </button>
          <button
            type={'button'}
            className={`d-flex justify-content-center align-items-center gap-2  btn btn-sm  ${
              isSelected('DEVIATE') ? 'btn-primary' : 'btn-default'
            }`}
            disabled={!isComplyDeviateEnabled()}
            onClick={() => {
              if (!isDisabledForm) {
                setSelectedComply('DEVIATE')
              }
            }}
          >
            <Field
              name={`items.${index}.comply`}
              type='checkbox'
              disabled={!isComplyDeviateEnabled()}
              value={'DEVIATE'}
              checked={selectedComply === 'DEVIATE'}
              onChange={(e: any) => {
                if (e.target.checked && !isDisabledForm) {
                  setSelectedComply(e.target.value)
                }
              }}
            />{' '}
            Deviate
          </button>
          {isNoQuoteShow() && (
            <button
              type={'button'}
              className={`d-flex justify-content-center align-items-center text-nowrap gap-2  btn btn-sm  ${
                isSelected('NO-QUOTE') ? 'btn-primary' : 'btn-default'
              }`}
              disabled={!isComplyDeviateEnabled()}
              onClick={() => {
                if (!isDisabledForm) {
                  setSelectedComply('NO-QUOTE')
                }
              }}
            >
              <Field
                name={`items.${index}.comply`}
                type='checkbox'
                disabled={!isComplyDeviateEnabled()}
                value={'NO-QUOTE'}
                checked={selectedComply === 'NO-QUOTE'}
                onChange={(e: any) => {
                  if (e.target.checked && !isDisabledForm) {
                    setSelectedComply(e.target.value)
                  }
                }}
              />{' '}
              No Quote
            </button>
          )}
        </div>
      </td>
      <td className={'text-center border align-middle'}>{item?.material}</td>
      <td
        className={'text-start border align-start bg-white'}
        style={{
          verticalAlign: 'middle',
          position: 'sticky',
          left: 0,
          zIndex: 100,
          backgroundColor: 'white',
        }}
      >
        <textarea
          className={'form-control form-control-sm'}
          defaultValue={shortText}
          rows={1}
          style={{
            minWidth: '150px',
          }}
          disabled={!isShortTextEnabled()}
          onChange={(e: any) => setShortText(e.target.value)}
        ></textarea>
        {selectedComply === 'DEVIATE' && (
          <Whisper
            placement='top'
            trigger='hover'
            controlId='control-id-hover-enterable'
            speaker={speaker(proposalTender?.items[index]?.short_text)}
            enterable
          >
            <Button className={'p-0 mt-2'}>
              <span className={'badge badge-info'}>
                <i className={'fa fa-info-circle text-white me-2'}></i> Requirement
              </span>
            </Button>
          </Whisper>
        )}
      </td>
      <td className={'text-start border align-start'}>
        <textarea
          className={'form-control form-control-sm'}
          defaultValue={spesifikasi}
          rows={1}
          style={{
            minWidth: '150px',
          }}
          disabled={!isSpesifikasiEnabled()}
          onChange={(e: any) => setSpesifikasi(e.target.value)}
        ></textarea>
        {selectedComply === 'DEVIATE' && (
          <Whisper
            placement='top'
            trigger='hover'
            controlId='control-id-hover-enterable'
            speaker={speaker(proposalTender?.items[index]?.specification)}
            enterable
          >
            <Button className={'p-0 mt-2'}>
              <span className={'badge badge-info'}>
                <i className={'fa fa-info-circle text-white me-2'}></i> Requirement
              </span>
            </Button>
          </Whisper>
        )}
      </td>
      <td className={'text-center border align-start'}>
        <CurrencyInput
          className='form-control form-control-sm text-end'
          groupSeparator='.'
          decimalSeparator=','
          decimalsLimit={2}
          value={parseNumberToFloat(quantity)}
          onValueChange={(value, name) => {
            setQuantity(value)
          }}
          style={{
            minWidth: '100px',
          }}
          disabled={!isQuantityEnabled()}
        />
      </td>
      <td className={'text-center border align-start'}>
        <input
          type={'text'}
          className={'form-control form-control-sm'}
          disabled={true}
          value={item?.uom_id}
          style={{
            minWidth: '50px',
          }}
        />
      </td>

      <td className={'text-center border align-start'}>
        <CurrencyInput
          className='form-control form-control-sm text-end'
          groupSeparator='.'
          decimalSeparator=','
          decimalsLimit={2}
          value={valuationPrice}
          onValueChange={(value: any, name) => {
            setValuationPrice(value)
            setTotalPenawaran(value?.replace(/,/g, '.')*quantity)
          }}
          style={{
            minWidth: '150px',
          }}
          disabled={!isValuationPriceEnabled()}
        />
      </td>

      <td className={'text-center border align-start'}>
        <CurrencyInput
          className='form-control form-control-sm text-end'
          groupSeparator='.'
          decimalSeparator=','
          decimalsLimit={2}
          value={addCost}
          onValueChange={(value, name) => {
            setAddCost(value)
          }}
          style={{
            minWidth: '100px',
          }}
          disabled={!isAddCostEnabled()}
        />
      </td>
      <td className={'text-center border align-start'}>
        <textarea
          className={'form-control form-control-sm'}
          defaultValue={addCostDescription}
          rows={1}
          style={{
            minWidth: '150px',
          }}
          disabled={!isAddCostEnabled()}
          onChange={(e: any) => setAddCostDescription(e.target.value)}
        ></textarea>
      </td>
      <td className={'text-center border align-start'}>
        <input
          type={'text'}
          className={'form-control form-control-sm text-end'}
          disabled={true}
          value={parseNumberToCurrency(totalPenawaran, item.currency_id)}
          style={{minWidth: '200px'}}
        />
      </td>
      <td className={'text-center border align-start'}>
        <input
          type={'text'}
          className={'form-control form-control-sm'}
          disabled={true}
          value={item?.currency_id}
          style={{
            minWidth: '50px',
          }}
        />
      </td>
      <td className={'border align-start text-nowrap'} style={{minWidth: '150px'}}>
        <div className={'input-group input-group-sm'}>
          <input
            type={'number'}
            className={'form-control form-control-sm text-end'}
            disabled={!isTkdnEnabled()}
            value={tkdn}
            min={0}
            step={1}
            max={100}
            onChange={(e: any) => {
              setTkdn(e.target.value)
            }}
            style={{
              minWidth: '50px',
              overflow: 'hidden',
            }}
          />
          <div className='input-group-append'>
            <button
              type={'button'}
              className={
                'btn btn-default btn-sm border border-secondary rounded-0 rounded-end input-group-text'
              }
            >
              <i className={'fa fa-percent'}></i>
            </button>
          </div>
        </div>
      </td>

      <td className={'text-center border align-start'}>
        <input
          type={'text'}
          className={'form-control form-control-sm'}
          disabled={!isTkdnEnabled()}
          defaultValue={tkdnCertification}
          maxLength={50}
          onChange={(e: any) => {
            setTkdnCertification(e.target.value)
          }}
          style={{minWidth: '150px'}}
        />
      </td>
      <td className={'text-start border align-start'} style={{minWidth: '300px'}}>
        <FormAttachment
          media_type_filter={'TENDER_ATTACHMENT'}
          isHideLabel={true}
          isDisabled={!isTkdnEnabled()}
          onlyFile={true}
          isSmall={true}
          afterSubmit={(e: any) => {
            setTkdnDocument(e?.url)
          }}
          previewUrl={tkdnDocument}
          withPreviewButton={true}
        />
      </td>
      <td className={'text-center border align-start'}>
        <textarea
          className={'form-control form-control-sm'}
          defaultValue={remark}
          rows={1}
          style={{
            minWidth: '150px',
          }}
          disabled={!isRemarkEnabled()}
          onChange={(e: any) => setRemark(e.target.value)}
        ></textarea>
      </td>
      <td className={'border align-start text-nowrap px-2'} style={{minWidth: '150px'}}>
        <div className={'input-group input-group-sm'}>
          <input
            type={'number'}
            className={'form-control form-control-sm text-end'}
            disabled={!isDeliveryTimeEnabled()}
            defaultValue={deliveryTime}
            min={0}
            step={1}
            onChange={(e: any) => {
              setDeliveryTime(e.target.value)
            }}
          />
          <div className='input-group-append'>
            <button
              type={'button'}
              className={
                'btn btn-default btn-sm border border-secondary rounded-0 rounded-end input-group-text'
              }
            >
              Hari
            </button>
          </div>
        </div>
      </td>
    </tr>
  )
}

/**
 * Komponen ini digunakan untuk row baris item service
 * @param service
 * @param index
 * @param selectedComply
 * @param onChange
 * @param isDisabledForm
 * @constructor
 */
const TdItemJasaServiceRow = ({
  proposalTender,
  service,
  quotation,
  item,
  index,
  indexItem,
  selectedComply,
  onChange,
  isDisabledForm,
  quotationStep,
  setKeyChangeTotalSrvLine,
  keyChangeTotalSrvLine,
  serviceLineRefs
}: any) => {
  const [shortText, setShortText] = useState<any>(service?.short_text)
  const [remark, setRemark] = useState<any>(service?.remark)
  const [quantity, setQuantity] = useState<any>(service?.quantity)
  const [valuationPrice, setValuationPrice] = useState<any>(service?.net_price)
  const [totalPenawaranSrvLine, setTotalPenawaranSrvLine] = useState<any>(service?.gross_price)
  const [renderIndex, setRenderIndex] = useState<any>(0)
  const [debounceTimer, setDebounceTimer] = useState<any>(null)
  const [{doSubmit, isLoading, isError, data, isSuccess}] = useAxiosPost(
    getEnv('tender') + `/vendor/quotation-item/${quotation?.uuid}`
  )

  const handleOnRowChange = () => {
    const payload = {
      ...service,
      quotation_item_id: item?.uuid,
      short_text: shortText,
      specification: service.specification,
      remark: remark,
      delivery_time: service.delivery_time,
      quantity: formatNumberX(quantity, 3),
      price: formatNumberX(valuationPrice, 3),
    }
    onChange(item, payload)
  }

  const debounce = (func: any, delay: number) => {
    if (debounceTimer) {
      clearTimeout(debounceTimer)
    }
    const timer = setTimeout(func, delay)
    setDebounceTimer(timer)
  }

  const isShortTextEnabled = () => {
    const _selectedComply = ['DEVIATE']

    if (proposalTender?.metode_penyampaian === '2 Sampul') {
      return quotationStep === 1 && _selectedComply.includes(selectedComply)
    }

    if (proposalTender?.metode_penyampaian === '2 Tahap') {
      if (quotationStep === 1) {
        const allowedStatus = ['Penawaran Teknikal']
        return (
          _selectedComply.includes(selectedComply) && allowedStatus.includes(proposalTender?.status)
        )
      } else if (quotationStep === 2) {
        return false
      }
    }

    return _selectedComply.includes(selectedComply) && !isDisabledForm
  }

  const isQuantityEnabled = () => {
    return false
    // const _selectedComply = ["DEVIATE"];
    //
    // if (proposalTender?.metode_penyampaian === "2 Sampul") {
    //   return quotationStep === 1 && _selectedComply.includes(selectedComply);
    // }
    //
    // if (proposalTender?.metode_penyampaian === "2 Tahap") {
    //   if (quotationStep === 1) {
    //     const allowedStatus = ["Penawaran"];
    //     return _selectedComply.includes(selectedComply) &&
    //       allowedStatus.includes(proposalTender?.status);
    //   } else if (quotationStep === 2) {
    //     return false;
    //   }
    // }

    // return _selectedComply.includes(selectedComply) && !isDisabledForm;
  }

  const isValuationPriceEnabled = () => {
    let _selectedComply = ['DEVIATE']

    if (proposalTender?.metode_penyampaian === '2 Sampul') {
      _selectedComply.push('COMPLY')
      return quotationStep === 2 && _selectedComply.includes(selectedComply)
    }

    if (proposalTender?.metode_penyampaian === '2 Tahap') {
      const allowedStatus = ['Penawaran Komersil']
      return (
        quotationStep === 2 &&
        // _selectedComply.includes(selectedComply) &&
        allowedStatus.includes(proposalTender?.status)
      )
    }

    _selectedComply.push('COMPLY')
    return _selectedComply.includes(selectedComply) && !isDisabledForm
  }
  const speaker = (requirement: any) => {
    return (
      <Popover title='Original Requirement'>
        <p style={{whiteSpace: 'pre-wrap'}}>{requirement}</p>
      </Popover>
    )
  }

  useEffect(() => {
    handleOnRowChange()
  }, [selectedComply, shortText, remark, quantity, valuationPrice])

  useEffect(() => {
    setKeyChangeTotalSrvLine(keyChangeTotalSrvLine + 1)
  }, [totalPenawaranSrvLine])

  return (
    <tr
      key={index + '-' + service?.id}
      style={{
        position: 'relative',
        height: 'auto',
      }}
    >
      <td className={'text-center border align-middle'}>
        {isLoading && (
          <div
            className={''}
            style={{
              position: 'absolute',
              width: '100%',
              textAlign: 'center',
              backgroundColor: 'rgba(255,255,255,0.5)',
              zIndex: 9999,
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <i className={'fa fa-spinner fa-spin fa-2x'}></i>
          </div>
        )}
      </td>
      <td className={'text-center border align-start'}></td>
      <td className={'text-center border align-start'}>{service?.id}</td>
      <td
        className={'text-start border align-start bg-white'}
        style={{
          verticalAlign: 'middle',
          position: 'sticky',
          left: 0,
          zIndex: 1,
        }}
      >
        <textarea
          className={'form-control form-control-sm'}
          defaultValue={shortText}
          rows={1}
          onChange={(e: any) => setShortText(e.target.value)}
          disabled={!isShortTextEnabled()}
          maxLength={50}
        ></textarea>
        {selectedComply === 'DEVIATE' && (
          <Whisper
            placement='top'
            trigger='hover'
            controlId='control-id-hover-enterable'
            speaker={speaker(proposalTender?.items[indexItem]?.service_line[index]?.short_text)}
            enterable
          >
            <Button className={'p-0 mt-2'}>
              <span className={'badge badge-info'}>
                <i className={'fa fa-info-circle text-white me-2'}></i> Requirement
              </span>
            </Button>
          </Whisper>
        )}
      </td>
      <td className={'text-center border align-start'}>
        <CurrencyInput
          className='form-control form-control-sm text-end'
          groupSeparator='.'
          decimalSeparator=','
          decimalsLimit={2}
          value={quantity}
          onValueChange={(value, name) => {
            setQuantity(value)
          }}
          style={{
            minWidth: '100px',
          }}
          disabled={!isQuantityEnabled()}
        />
      </td>
      <td className={'text-center border align-start'}>
        <input
          type={'text'}
          className={'form-control form-control-sm'}
          disabled={true}
          value={service?.uom_id}
          style={{
            minWidth: '50px',
          }}
        />
      </td>
      <td className={'text-center border align-start'} id={'totalSrvLine'+index} ref={el => {serviceLineRefs.current[index] = el}}>
        <CurrencyInput
          className='form-control form-control-sm text-end'
          groupSeparator='.'
          decimalSeparator=','
          decimalsLimit={2}
          value={valuationPrice}
          onValueChange={(value: any, name) => {
            setValuationPrice(value)
            const input = (value === undefined ? 0 : value.replace(/,/g, '.'))
            setTotalPenawaranSrvLine(input*quantity)
          }}
          style={{
            minWidth: '150px',
          }}
          disabled={!isValuationPriceEnabled()}
        />
      </td>
      <td className={'text-center border align-start'}></td>
      <td className={'text-center border align-start'}></td>
      <td className={'text-center border align-start'}>
        <input
          type={'text'}
          className={'form-control form-control-sm text-end'}
          disabled={true}
          value={parseNumberToCurrency(totalPenawaranSrvLine, service.currency_id)}
          style={{minWidth: '200px'}}
        />
      </td>

      <td className={'text-center border align-start'}>
        <input
          type={'text'}
          className={'form-control form-control-sm'}
          disabled={true}
          value={service?.currency_id}
          style={{
            minWidth: '50px',
          }}
        />
      </td>
      <td className={'text-center border align-start'}></td>
      <td className={'text-center border align-start'}></td>
      <td className={'text-center border align-start'}></td>
      <td className={'text-center border align-start'}></td>
      <td className={'text-center border align-middle'}></td>
    </tr>
  )
}

/**
 * Komponen ini hanya untuk row header item jasa
 * @param proposalTender
 * @param item
 * @param index
 * @param isDisabledForm
 * @param onChange
 * @constructor
 */
const TdItemJasa = ({
  proposalTender,
  quotation,
  quotationStep,
  item,
  index,
  isDisabledForm,
  onChange,
}: any) => {
  const [selectedComply, setSelectedComply] = useState<any>(item.quote)
  const [shortText, setShortText] = useState<any>(item?.short_text)
  const [deliveryTime, setDeliveryTime] = useState<any>(item.delivery_time)
  const [spesifikasi, setSpesifikasi] = useState<any>(item?.specification)
  const [serviceLine, setServiceLine] = useState<any>(item.serviceLines)
  // prevent autosave ketika baru pertama kali di load
  const [renderIndex, setRenderIndex] = useState<any>(0)
  const [debounceTimer, setDebounceTimer] = useState<any>(null)
  const [totalPenawaran, setTotalPenawaran] = useState<any>(item?.total_value || 0)
  const [keyChangeTotalSrvLine, setKeyChangeTotalSrvLine] = useState(0)
  const [addCost, setAddCost] = useState<any>(item?.additional_cost)
  const [addCostDescription, setAddCostDescription] = useState<any>(
    item?.additional_cost_description
  )
  const [remark, setRemark] = useState<any>(item?.remark)
  const [tkdn, setTkdn] = useState<any>(item?.tkdn)
  const [tkdnCertification, setTkdnCertification] = useState<any>(item?.tkdn_certification_number)
  const [tkdnDocument, setTkdnDocument] = useState<any>(item?.tkdn_certification_url)

  const [{doSubmit, isLoading, isError, data, isSuccess}] = useAxiosPost(
    getEnv('tender') + `/vendor/quotation-item/${quotation?.uuid}`
  )
  const isSelected = (value: any) => {
    return selectedComply === value
  }

  const isNoQuoteShow = () => {
    return proposalTender.order_placement !== 'Paket'
  }

  const handleOnRowChange = () => {
    const payload = {
      ...item,
      quotation_id: quotation?.uuid,
      quote: selectedComply,
      short_text: shortText,
      specification: spesifikasi,
      remark: remark,
      delivery_time: deliveryTime,
      quantity: item.quantity,
      price: formatNumberToDecimal(item.total_value || 0, 3),
      additional_cost: formatNumberToDecimal(addCost, 3),
      additional_cost_description: addCostDescription,
      tkdn: tkdn,
      tkdn_certification_number: tkdnCertification,
      tkdn_certification_url: tkdnDocument,
      serviceLines: serviceLine,
    }
    onChange && onChange(payload)
    // onChange(payload)
    // doSubmit(payload, getEnv("tender") + `/vendor/quotation-item/${item?.uuid}`, toast).then((res: any) => {
    //   setTotalPenawaran(res?.data?.total_value);
    // });
  }
  const isRemarkEnabled = () => {
    const _selectedComply = ['DEVIATE']

    if (proposalTender?.metode_penyampaian === '2 Sampul') {
      _selectedComply.push('COMPLY')
      return (
        (quotationStep === 2 || quotationStep === 1) && _selectedComply.includes(selectedComply)
      )
    }

    if (proposalTender?.metode_penyampaian === '2 Tahap') {
      if (quotationStep === 1) {
        const allowedStatus = ['Penawaran Teknikal']
        _selectedComply.push('COMPLY')
        return (
          _selectedComply.includes(selectedComply) && allowedStatus.includes(proposalTender?.status)
        )
      } else if (quotationStep === 2) {
        const allowedStatus = ['Penawaran Komersil']
        _selectedComply.push('COMPLY')
        return (
          _selectedComply.includes(selectedComply) && allowedStatus.includes(proposalTender?.status)
        )
      }
      return false
    }
    _selectedComply.push('COMPLY')
    return _selectedComply.includes(selectedComply) && !isDisabledForm
  }

  const handleOnServiceLineChange = async (serviceLine: any) => {
    setTotalPenawaran(serviceLine?.quotationItem?.total_value)
  }

  const debounce = (func: any, delay: number) => {
    if (debounceTimer) {
      clearTimeout(debounceTimer)
    }
    const timer = setTimeout(func, delay)
    setDebounceTimer(timer)
  }

  const isShortTextEnabled = () => {
    const _selectedComply = ['DEVIATE']

    if (proposalTender?.metode_penyampaian === '2 Sampul') {
      return quotationStep === 1 && _selectedComply.includes(selectedComply)
    }

    if (proposalTender?.metode_penyampaian === '2 Tahap') {
      if (quotationStep === 1) {
        const allowedStatus = ['Penawaran Teknikal']
        return (
          _selectedComply.includes(selectedComply) && allowedStatus.includes(proposalTender?.status)
        )
      } else if (quotationStep === 2) {
        return false
      }
    }

    return _selectedComply.includes(selectedComply) && !isDisabledForm
  }

  const isDeliveryTimeEnabled = () => {
    if (proposalTender?.metode_penyampaian === '2 Tahap') {
      return (
        proposalTender?.status === 'Penawaran Teknikal' ||
        proposalTender?.status === 'Penawaran Komersil'
      )
    }
    const _selectedComply = ['DEVIATE', 'COMPLY']
    return _selectedComply.includes(selectedComply) && !isDisabledForm
  }

  const isAddCostEnabled = () => {
    // let _selectedComply = ['DEVIATE']

    // if (proposalTender?.metode_penyampaian === '2 Sampul') {
    //   _selectedComply.push('COMPLY')
    //   return quotationStep === 2 && _selectedComply.includes(selectedComply)
    // }

    // if (proposalTender?.metode_penyampaian === "2 Tahap") {
    //   const allowedStatus = ["Penawaran Komersil"];
    //   return quotationStep === 2 &&
    //     // _selectedComply.includes(selectedComply) &&
    //     allowedStatus.includes(proposalTender?.status);
    // }
    // _selectedComply.push("COMPLY");
    return false
  }

  const isTkdnEnabled = () => {
    let _selectedComply = ['DEVIATE']

    if (proposalTender?.metode_penyampaian === '2 Sampul') {
      _selectedComply.push('COMPLY')
      return quotationStep === 1 && _selectedComply.includes(selectedComply)
    }

    if (proposalTender?.metode_penyampaian === '2 Tahap') {
      const allowedStatus = ['Penawaran Teknikal']
      _selectedComply.push('COMPLY')
      return (
        quotationStep === 1 &&
        _selectedComply.includes(selectedComply) &&
        allowedStatus.includes(proposalTender?.status)
      )
    }
    _selectedComply.push('COMPLY')
    return _selectedComply.includes(selectedComply) && !isDisabledForm
  }

  const isComplyDeviateEnabled = () => {
    if (proposalTender?.metode_penyampaian === '2 Sampul') {
      return quotationStep === 1
    }
    if (proposalTender?.metode_penyampaian === '2 Tahap') {
      return proposalTender?.status === 'Penawaran Teknikal'
    }
    return !isDisabledForm
  }

  const speaker = (requirement: any) => {
    return (
      <Popover title='Original Requirement'>
        <p style={{whiteSpace: 'pre-wrap'}}>{requirement}</p>
      </Popover>
    )
  }

  const updateServiceLine = async (item: any, service: any) => {
    const _serviceLine = serviceLine
    const _index = _serviceLine.findIndex((el: any) => el.id === service.id)
    _serviceLine[_index] = service
    let _item = item
    _item.serviceLines = _serviceLine
    setServiceLine(_serviceLine)
    onChange(_item)
    // const _currentServiceLine =  _serviceLine.findIndex((i:any)=> i.id === service?.id);
    // console.log('currentServiceLine',_currentServiceLine)
    // const payload = {
    //   ...serviceLine,
    //   quotation_item_id: item?.uuid,
    //   quotation_id: quotation?.uuid,
    //   quote: selectedComply,
    //   short_text: item?.short_text,
    //   specification: spesifikasi,
    //   remark: remark,
    //   delivery_time: deliveryTime,
    //   quantity: item?.quantity,
    //   price: formatNumberToDecimal(item?.valuation_price, 3),
    //   additional_cost: formatNumberToDecimal(addCost, 3),
    //   additional_cost_description: addCostDescription,
    //   tkdn: tkdn,
    //   tkdn_certification_number: tkdnCertification,
    //   tkdn_certification_url: tkdnDocument
    // };
    // console.log("payload", payload);
    // await update && update(payload);
    // doSubmit(payload, getEnv("tender") + `/vendor/quotation-item-service/${serviceLine?.uuid}`).then((res: any) => {
    //   setTotalPenawaran(res?.data?.gross_price);
    // });
  }

  useEffect(() => {
    handleOnRowChange()
  }, [
    selectedComply,
    remark,
    shortText,
    spesifikasi,
    deliveryTime,
    addCost,
    addCostDescription,
    tkdn,
    tkdnCertification,
    tkdnDocument,
  ])

  useEffect(() => {
    // handleOnRowChange()
  }, [])

  const serviceLineRefs = useRef([]);

  useEffect(() => {
    const total = serviceLineRefs.current.reduce((acc, ref, refIndex) => {
      const input = ref.querySelector('input');
      const value = input ? input.value.replace(/\./g, '').replace(/,/g, '.') : 0;
      const qty = serviceLine[refIndex]?.quantity || 0
      const tempTotal = value * qty
      return acc + tempTotal;
    }, 0);

    setTotalPenawaran(total);
  }, [keyChangeTotalSrvLine])

  return (
    <>
      <tr
        style={{
          position: 'relative',
          height: 'auto',
        }}
      >
        <td className={"text-center border px-2"}>{index+1}</td>
        <td className={'text-center border align-middle'}>
          {isLoading && (
            <div
              className={''}
              style={{
                position: 'absolute',
                width: '100%',
                textAlign: 'center',
                backgroundColor: 'rgba(255,255,255,0.5)',
                zIndex: 9999,
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <i className={'fa fa-spinner fa-spin fa-2x'}></i>
            </div>
          )}
          <div className={'d-flex justify-content-center btn-group'}>
            <button
              type={'button'}
              className={`d-flex justify-content-center align-items-center gap-2 btn btn-sm ${
                isSelected('COMPLY') ? 'btn-primary' : 'btn-default'
              }`}
              disabled={!isComplyDeviateEnabled()}
              onClick={() => {
                if (!isDisabledForm) {
                  setSelectedComply('COMPLY')
                }
              }}
            >
              <Field
                name={`items.${index}.comply`}
                type='checkbox'
                disabled={!isComplyDeviateEnabled()}
                value={'COMPLY'}
                checked={selectedComply === 'COMPLY'}
                onChange={(e: any) => {
                  if (e.target.checked && !isDisabledForm) {
                    setSelectedComply(e.target.value)
                  }
                }}
              />{' '}
              Comply
            </button>
            <button
              type={'button'}
              className={`d-flex justify-content-center align-items-center gap-2  btn btn-sm  ${
                isSelected('DEVIATE') ? 'btn-primary' : 'btn-default'
              }`}
              disabled={!isComplyDeviateEnabled()}
              onClick={() => {
                if (!isDisabledForm) {
                  setSelectedComply('DEVIATE')
                }
              }}
            >
              <Field
                name={`items.${index}.comply`}
                type='checkbox'
                disabled={!isComplyDeviateEnabled()}
                value={'DEVIATE'}
                checked={selectedComply === 'DEVIATE'}
                onChange={(e: any) => {
                  if (e.target.checked && !isDisabledForm) {
                    setSelectedComply(e.target.value)
                  }
                }}
              />{' '}
              Deviate
            </button>

            {isNoQuoteShow() && (
              <button
                type={'button'}
                className={`d-flex justify-content-center align-items-center text-nowrap gap-2  btn btn-sm  ${
                  isSelected('NO-QUOTE') ? 'btn-primary' : 'btn-default'
                }`}
                disabled={!isComplyDeviateEnabled()}
                onClick={() => {
                  if (!isDisabledForm) {
                    setSelectedComply('NO-QUOTE')
                  }
                }}
              >
                <Field
                  name={`items.${index}.comply`}
                  type='checkbox'
                  disabled={!isComplyDeviateEnabled()}
                  value={'NO-QUOTE'}
                  checked={selectedComply === 'NO-QUOTE'}
                  onChange={(e: any) => {
                    if (e.target.checked && !isDisabledForm) {
                      setSelectedComply(e.target.value)
                    }
                  }}
                />{' '}
                No Quote
              </button>
            )}
          </div>
        </td>
        <td></td>
        <td
          className={'border align-start bg-white'}
          style={{
            verticalAlign: 'middle',
            position: 'sticky',
            left: 0,
            zIndex: 100,
          }}
        >
          <textarea
            className={'form-control form-control-sm'}
            defaultValue={shortText}
            rows={1}
            style={{
              minWidth: '150px',
            }}
            disabled={!isShortTextEnabled()}
            onChange={(e: any) => setShortText(e.target.value)}
          ></textarea>
          {selectedComply === 'DEVIATE' && (
            <Whisper
              placement='top'
              trigger='hover'
              controlId='control-id-hover-enterable'
              speaker={speaker(proposalTender?.items[index]?.short_text)}
              enterable
            >
              <Button className={'p-0 mt-2'}>
                <span className={'badge badge-info'}>
                  <i className={'fa fa-info-circle text-white me-2'}></i> Requirement
                </span>
              </Button>
            </Whisper>
          )}
        </td>
        <td className={'border align-start'} rowSpan={serviceLine?.length + 1}>
          <textarea
            className={'form-control form-control-sm'}
            defaultValue={spesifikasi}
            rows={(serviceLine?.length + 1) * 3}
            style={{
              minWidth: '150px',
            }}
            disabled={!isShortTextEnabled()}
            onChange={(e: any) => setSpesifikasi(e.target.value)}
          ></textarea>
          {selectedComply === 'DEVIATE' && (
            <Whisper
              placement='top'
              trigger='hover'
              controlId='control-id-hover-enterable'
              speaker={speaker(proposalTender?.items[index]?.specification)}
              enterable
            >
              <Button className={'p-0 mt-2'}>
                <span className={'badge badge-info'}>
                  <i className={'fa fa-info-circle text-white me-2'}></i> Requirement
                </span>
              </Button>
            </Whisper>
          )}
        </td>
        <td className={'text-center border align-start'}></td>
        <td className={'text-center border align-start'}></td>
        <td className={'text-center border align-start'}></td>
        <td className={'text-center border align-start'}>
          <CurrencyInput
            className='form-control form-control-sm text-end'
            groupSeparator='.'
            decimalSeparator=','
            decimalsLimit={2}
            value={addCost}
            onValueChange={(value, name) => {
              setAddCost(value)
            }}
            style={{
              minWidth: '100px',
            }}
            disabled={!isAddCostEnabled()}
          />
        </td>
        <td className={'text-center border align-start'}>
          <textarea
            className={'form-control form-control-sm'}
            defaultValue={addCostDescription}
            rows={1}
            style={{
              minWidth: '150px',
            }}
            disabled={!isAddCostEnabled()}
            onChange={(e: any) => setAddCostDescription(e.target.value)}
          ></textarea>
        </td>
        <td className={'text-center border align-start'}>
          <input
            type={'text'}
            className={'form-control form-control-sm text-end'}
            disabled={true}
            value={parseNumberToCurrency(totalPenawaran, item.currency_id)}
            style={{minWidth: '200px'}}
          />
        </td>
        <td></td>
        <td className={'border align-start text-nowrap'} style={{minWidth: '150px'}}>
          <div className={'input-group input-group-sm'}>
            <input
              type={'number'}
              className={'form-control form-control-sm text-end'}
              disabled={!isTkdnEnabled()}
              value={tkdn}
              min={0}
              step={1}
              max={100}
              onChange={(e: any) => {
                setTkdn(e.target.value)
              }}
              style={{
                minWidth: '50px',
                overflow: 'hidden',
              }}
            />
            <div className='input-group-append'>
              <button
                type={'button'}
                className={
                  'btn btn-default btn-sm border border-secondary rounded-0 rounded-end input-group-text'
                }
              >
                <i className={'fa fa-percent'}></i>
              </button>
            </div>
          </div>
        </td>

        <td className={'text-center border align-start'}>
          <input
            type={'text'}
            className={'form-control form-control-sm'}
            disabled={!isTkdnEnabled()}
            defaultValue={tkdnCertification}
            maxLength={50}
            onChange={(e: any) => {
              setTkdnCertification(e.target.value)
            }}
            style={{minWidth: '150px'}}
          />
        </td>
        <td className={'text-start border align-start'} style={{minWidth: '300px'}}>
          <FormAttachment
            media_type_filter={'TENDER_ATTACHMENT'}
            isHideLabel={true}
            isDisabled={!isTkdnEnabled()}
            onlyFile={true}
            isSmall={true}
            afterSubmit={(e: any) => {
              setTkdnDocument(e?.url)
            }}
            previewUrl={tkdnDocument}
            withPreviewButton={true}
          />
        </td>
        <td className={'border align-start text-nowrap px-2'}>
          <textarea
            className={'form-control form-control-sm'}
            defaultValue={remark}
            rows={1}
            style={{
              minWidth: '150px',
            }}
            disabled={!isRemarkEnabled()}
            onChange={(e: any) => setRemark(e.target.value)}
          ></textarea>
        </td>
        <td className={'border align-start text-nowrap px-2'} style={{minWidth: '150px'}}>
          <div className={'input-group input-group-sm'}>
            <input
              type={'number'}
              className={'form-control form-control-sm text-end'}
              disabled={!isDeliveryTimeEnabled()}
              defaultValue={deliveryTime}
              min={0}
              step={1}
              onChange={(e: any) => {
                setDeliveryTime(e.target.value)
              }}
            />
            <div className='input-group-append'>
              <button
                type={'button'}
                className={
                  'btn btn-default btn-sm border border-secondary rounded-0 rounded-end input-group-text'
                }
              >
                Hari
              </button>
            </div>
          </div>
        </td>
      </tr>
      {serviceLine?.map((service: any, index2: number) => {
        return (
          <TdItemJasaServiceRow
            service={service}
            index={index2}
            indexItem={index}
            selectedComply={selectedComply}
            isDisabledForm={isDisabledForm}
            key={index2 + '-' + service?.id}
            quotation={quotation}
            item={item}
            onChange={updateServiceLine}
            quotationStep={quotationStep}
            proposalTender={proposalTender}
            setKeyChangeTotalSrvLine={setKeyChangeTotalSrvLine}
            keyChangeTotalSrvLine={keyChangeTotalSrvLine}
            serviceLineRefs={serviceLineRefs}
          />
        )
      })}
    </>
  )
}

interface ItemPenawaranTRInterface {
  quotation: any
  proposalTender: any
  item: QuotationItemInterface
  index: number
  isDisabledForm: boolean
  onChange: Function
  quotationStep: number
}

const ItemPenawaranTRconst = ({
  quotation,
  quotationStep,
  proposalTender,
  item: itemRow,
  index,
  isDisabledForm,
  onChange,
}: ItemPenawaranTRInterface) => {
  const [quotationItems, setQuotationItems] = useState<any>(itemRow)

  return (
    <>
      {quotationItems?.serviceLines && quotationItems?.serviceLines.length > 0 && (
        <TdItemJasa
          key={index}
          item={quotationItems}
          index={index}
          isDisabledForm={isDisabledForm}
          proposalTender={proposalTender}
          quotation={quotation}
          onChange={onChange}
          quotationStep={quotationStep}
        />
      )}
      {quotationItems.serviceLines?.length === 0 && (
        <TdItemBarang
          key={index}
          item={quotationItems}
          index={index}
          isDisabledForm={isDisabledForm}
          proposalTender={proposalTender}
          quotation={quotation}
          onChange={onChange}
          quotationStep={quotationStep}
        />
      )}
    </>
  )
}

const QuotationItemFormTable = ({
  quotation,
  proposalTender,
  quotationStep,
  touched,
  errors,
  isDisabledForm,
  onChangeItems,
}: any) => {
  const [quotationItems, setQuotationItems] = useState<any>([])
  const [{doSubmit, isLoading, isError, data, isSuccess}] = useAxiosGet(
    getEnv('tender') + '/vendor/quotation-item?perPage=1000&filter[quotation_id]=' + quotation?.id
  )
  const intl = useIntl()
  const getQuotationItem = async () => {
    doSubmit(
      null,
      getEnv('tender') + '/vendor/quotation-item?perPage=1000&filter[quotation_id]=' + quotation?.id
    ).then((res: any) => {
      setQuotationItems(res.data)
    })
  }

  const handleChange = (item: any) => {
    const tmpQuotationItems = [...quotationItems]
    const index = tmpQuotationItems.findIndex((el: any) => el.id === item.id)
    tmpQuotationItems[index] = item
    setQuotationItems(tmpQuotationItems)
  }

  useEffect(() => {
    onChangeItems(quotationItems)
  }, [quotationItems])

  useEffect(() => {
    if (quotation?.id) {
      getQuotationItem()
    }
  }, [quotation])

  return (
    <>
      {!quotation.id && (
        <div className={'alert alert-info'}>
          <i className={'fa fa-info-circle me-2'}></i>
          {intl.formatMessage({id: 'Load Quotation terlebih dahulu untuk meload item penawaran'})}
        </div>
      )}
      {quotation.id && (
        <div className={'alert alert-warning text-dark'}>
          <i className={'fa fa-exclamation-triangle me-2'}></i>
          {intl.formatMessage({
            id: '[Sebagai Informasi] Pastikan untuk mengisi detail harga penawaran secara lengkap dan benar.',
          })}
        </div>
      )}
      {isLoading && (
        <div className={'text-center'}>
          <i className={'fa fa-spinner fa-spin'}></i> Loading...
        </div>
      )}
      <div className={'table-responsive mb-4'}>
        {quotation.id && (
          <table className={'table table-header-gray'}>
            <thead>
            <tr>
              <th className={'text-center text-nowrap px-2'}>
                <strong>{intl.formatMessage({id: 'No'})}</strong>
              </th>
              <th className={'text-center text-nowrap'}>
                <strong>{intl.formatMessage({id: 'Comply/Deviate'})}</strong>
              </th>
              <th className={'text-center text-nowrap'}>
                <strong>{intl.formatMessage({id: 'Material No'})}</strong>
              </th>
              <th
                className={'text-center text-nowrap'}
                style={{
                  minWidth: '350px',
                  verticalAlign: 'middle',
                  position: 'sticky',
                  left: 0,
                  zIndex: 1,
                }}
              >
                <strong>{intl.formatMessage({id: 'Short Text'})}</strong>
              </th>
              <th className={'text-center text-nowrap'}>
                <strong>{intl.formatMessage({id: 'Spesifikasi'})}</strong>
              </th>
              <th className={'text-center text-nowrap'}>
                <strong>{intl.formatMessage({id: 'Qty'})}</strong>
              </th>
              <th className={'text-center text-nowrap'}>
                <strong>{intl.formatMessage({id: 'UOM'})}</strong>
              </th>
              <th className={'text-center text-nowrap'}>
                <strong>{intl.formatMessage({id: 'Harga Satuan'})}</strong>
              </th>
              <th className={'text-center text-nowrap'}>
                <strong>{intl.formatMessage({id: 'Add Cost'})}</strong>
              </th>
              <th className={'text-center text-nowrap'}>
                <strong>{intl.formatMessage({id: 'Keterangan Add Cost'})}</strong>
              </th>
              <th className={'text-center text-nowrap'}>
                <strong>{intl.formatMessage({id: 'Total Penawaran'})}</strong>
              </th>
              <th className={'text-center text-nowrap'}>
                <strong>{intl.formatMessage({id: 'Currency'})}</strong>
              </th>
              <th className={'text-center text-nowrap'}>
                <strong>{intl.formatMessage({id: 'TKDN'})}</strong>
              </th>
              <th className={'text-center text-nowrap'}>
                <strong>{intl.formatMessage({id: 'Nomor TKDN'})}</strong>
              </th>
              <th className={'text-center text-nowrap'}>
                <strong>{intl.formatMessage({id: 'Attachment TKDN'})}</strong>
              </th>
              <th className={'text-center text-nowrap'}>
                <strong>{intl.formatMessage({id: 'Remark'})}</strong>
              </th>
              <th className={'text-center text-nowrap'}>
                <strong>{intl.formatMessage({id: 'Delivery Time'})}</strong>
              </th>
            </tr>
            </thead>
            <tbody>
            {quotationItems?.map((item: any, index: number) => {
              return (
                <ItemPenawaranTRconst
                    proposalTender={proposalTender}
                    quotation={quotation}
                    key={'item-penawaran' + item.id}
                    item={item}
                    index={index}
                    isDisabledForm={isDisabledForm}
                    onChange={handleChange}
                    quotationStep={quotationStep}
                  />
                )
              })}
            </tbody>
          </table>
        )}
      </div>

      <div className={'form-group'}>
        <label className='form-label'>{intl.formatMessage({id: 'Catatan'})}</label>
        <Field
          name='note'
          type='text'
          as={'textarea'}
          className='form-control'
          disabled={isDisabledForm}
        />
        {errors.note && touched.note && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>{errors.note}</div>
          </div>
        )}
      </div>
    </>
  )
}

export default QuotationItemFormTable
