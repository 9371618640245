import React, { useEffect, useState } from "react";
import { Nav, Placeholder } from "rsuite";
import { KTCard, KTCardBody } from "../../../../../_metronic/helpers";
import { useAxiosGet, useAxiosPost } from "../../../../../hooks/useAxios";
import { capitalizeFirstChar, getEnv } from "../../../../../helpers/procurex";
import { useNavigate, useParams } from "react-router-dom";
import { ParameterAuctionTab } from "./components/ParameterAuctionTab";
import { ItemAuctionTab } from "./components/ItemAuctionTab";
import { PesertaAuctionTab } from "./components/PesertaAuctionTab";
import { JadwalAuction } from "./components/JadwalAuction";
import { PetunjukPeraturanAuctionTab } from "./components/PetunjukPeraturanAuctionTab";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { TabulasiAuctionPage } from "./components/TabulasiAuctionPage";
import { RiwayatAuctionTab } from "./components/RiwayatAuctionTab";
import { ParamSettingAuctionTab } from "./components/ParamSettingAuctionTab";
import { Spinner } from "react-bootstrap";
import Countdown from "react-countdown";
import axios from "axios";
import { FinalisasiAuctionTab } from "./components/FinalisasiAuctionTab";
import { BeritaAcaraAuctionTab } from "./components/BeritaAcaraAuctionTab";
import { PaktaIntegritasAuctionTab } from "./components/PaktaIntegritasAuctionTab";
import { PanitiaPengadaanTab } from "./components/PanitiaPengadaanTab";


const HeaderCountdown = ({ status, time, type }: any) => {
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosPost(
    getEnv("auction") + `/auction/run-cronjob/`
  );

  const PusherJS = require('pusher-js');
  const [currentTimeStamp, setCurrentTimestamp] = useState(0)
  const [socketCurrentTimeStamp, setSocketTimestamp] = useState(0)
  const initSocket = () => {
    let client = new PusherJS(process.env.REACT_APP_WEBSOCKET_APP_KEY, {
      wsHost: process.env.REACT_APP_WEBSOCKET_URL,
      wsPort: process.env.REACT_APP_WEBSOCKET_PORT,
      cluster: process.env.REACT_APP_WEBSOCKET_CLUSTER,
      forceTLS: process.env.REACT_APP_WEBSOCKET_FORCETLS,
      encrypted: process.env.REACT_APP_WEBSOCKET_ENCRYPTED,
      disableStats: true,
      enabledTransports: ["ws", "wss"],
      logToConsole: true
    });
    const channel = client.subscribe("server-time");
    channel.bind("App\\Events\\ServerTimeEvent", function (data: any) {
      setSocketTimestamp(data.message)
    });
  }

  useEffect(() => {
    if (status == 'paused' && currentTimeStamp == 0) {
      setCurrentTimestamp(socketCurrentTimeStamp);
    } else if (status != 'paused') {
      setCurrentTimestamp(socketCurrentTimeStamp);
    }
  }, [socketCurrentTimeStamp])

  const onCompleteCountdown = () => {
    if (time > 0 && type) {
      if (type == 'auction-akan-dimulai-dalam') {
        const payload = {
          _method: "PUT"
        };
        const newUrl = getEnv("auction") + `/auction/run-cronjob/ongoing`;
        doSubmit(payload, newUrl).then((res: any) => {
        });
      } else if (type == 'waktu-sisa-auction') {
        const payload = {
          _method: "PUT"
        };
        const newUrl = getEnv("auction") + `/auction/run-cronjob/done`;
        doSubmit(payload, newUrl).then((res: any) => {
        });
      }
    }
  }

  useEffect(() => {
    initSocket()
  }, []);
  return (
    <div className={"d-flex"}>
      {time === 0 || currentTimeStamp == 0 && (
        '00:00:00:00'
      )}
      {time >= 0 && currentTimeStamp != 0 && (
        <>
          <Countdown date={time} now={() => currentTimeStamp} onComplete={() => onCompleteCountdown()} />
        </>
      )}
    </div>
  )
}


const Header = ({ auction, tabulasi, onRefreshAuction }: any) => {
  const navigate = useNavigate();

  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosPost(
    getEnv("auction") + `/auction/publish/${auction?.uuid}/1`
  );

  const isPublishEnabled = () => {
    // return true;
    return auction?.status === "draft" && auction.ready_publish;
  };


  const isShowCountdown = () => {
    const allowedStatus = ["published", "ongoing", "paused"];
    return allowedStatus.includes(auction?.status);
  };

  const handlePublish = () => {
    Swal.fire({
      title: "Apakah Anda Yakin?",
      text: "Anda akan mempublish auction ini!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, publish",
      cancelButtonText: "Tidak"
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const payload = {
          _method: "PUT"
        };
        doSubmit(payload, undefined).then((res: any) => {
          onRefreshAuction();
        });
      }
    });
  };

  const handleStop = () => {
    Swal.fire({
      title: "Apakah Anda Yakin?",
      text: "Anda akan stop auction ini!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, stop",
      cancelButtonText: "Tidak"
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const payload = {
          _method: "PUT"
        };
        const newUrl = getEnv("auction") + `/auction/closed/${auction?.uuid}`;
        doSubmit(payload, newUrl, toast).then((res: any) => {
          onRefreshAuction();
        });
      }
    });
  };

  const handleFinish = () => {
    Swal.fire({
      title: "Apakah Anda Yakin?",
      text: "Anda akan selesaikan auction ini!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, selesaikan",
      cancelButtonText: "Tidak"
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const payload = {
          _method: "PUT"
        };
        const newUrl = getEnv("auction") + `/auction/finished/${auction?.uuid}`;
        doSubmit(payload, newUrl, toast).then((res: any) => {
          onRefreshAuction();
        });
      }
    });
  };


  const handlePause = () => {
    Swal.fire({
      title: "Apakah Anda Yakin?",
      text: "Anda akan menghentikan sementaran auction ini!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, hentikan sementara",
      cancelButtonText: "Tidak"
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const payload = {
          _method: "PUT"
        };
        const newUrl = getEnv("auction") + `/auction/paused/${auction?.uuid}/1`;
        doSubmit(payload, newUrl, toast).then((res: any) => {
          onRefreshAuction();
        });
      }
    });
  };

  const handleUnPause = () => {
    Swal.fire({
      title: "Apakah Anda Yakin?",
      text: "Anda akan menjalankan kembali auction ini!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, jalankan kembali",
      cancelButtonText: "Tidak"
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const payload = {
          _method: "PUT"
        };
        const newUrl = getEnv("auction") + `/auction/paused/${auction?.uuid}/0`;
        doSubmit(payload, newUrl, toast).then((res: any) => {
          onRefreshAuction();
        });
      }
    });
  };

  return (
    <>
      <div className={"row mb-2"}>
        <div className={"col-md-5"}>
          <div className={"row"}>
            <div className={"col-md-12 d-flex justify-content-between"}>
              <div>
                <h2>[{auction?.jenis_simulasi?.value}] {auction?.nama}</h2>
              </div>

            </div>
            <div className={"col-md-12 d-flex gap-3 mb-1"}>
              <div>
                No: <strong>{auction?.no_auction}</strong>
              </div>
              <div>
                | <strong>{auction?.purchasing_org?.comp_code}</strong> ({auction?.purchasing_org?.comp_code_name})
              </div>

            </div>
            <div className={"col-md-12 d-flex gap-3 mb-2"}>
              <div className={"d-flex gap-2"}>
                <span className={"badge badge-primary"}>#{auction?.tipe_auction?.value}</span>
                <span className={"badge badge-primary"}>#{auction?.metode_evaluasi?.value}</span>
                <span className={"badge badge-primary"}>#{auction?.metode_peringkat?.value}</span>
              </div>

            </div>
            <div className={"col-md-12 d-flex gap-3"}>
              <button className={"btn btn-danger btn-sm"} type={"button"} onClick={() => {
                navigate("/auction-v2/auction");
              }}>
                <i className={"fa fa-arrow-left"}></i> Kembali Ke List
              </button>
            </div>
          </div>
        </div>
        <div className={"col-md-4 text-center"}>
          {isShowCountdown() && (
            <table className={"table table-borderless"}>
              <tbody>
                <tr>
                  <td className={"bg-white border"}>
                    <strong>Waktu Sisa Auction</strong>
                  </td>
                  <td className={"bg-white border text-start px-3"} style={{
                    width: "200px"
                  }}>
                    <h2>
                      <HeaderCountdown type={'waktu-sisa-auction'} status={tabulasi?.status} time={tabulasi?.end_countdown} key={tabulasi?.end_countdown} />
                    </h2>
                  </td>
                </tr>
                {tabulasi?.status === "published" && (
                  <tr>
                    <td className={"bg-white border"}>
                      Akan dimulai dalam:
                    </td>
                    <td className={"bg-white border text-start px-3"}>
                      <h2>
                        <HeaderCountdown type={'auction-akan-dimulai-dalam'} status={tabulasi?.status} time={tabulasi?.start_countdown} />
                      </h2>
                    </td>
                  </tr>
                )}

              </tbody>
            </table>
          )}

        </div>
        <div className={"col-md-3 text-end d-flex align-items-end justify-content-between flex-column"}>
          <div>
            <h2><strong>{capitalizeFirstChar(tabulasi?.status)}</strong></h2>
            {auction?.batas_awal != null &&
              <strong>{auction?.batas_awal_format} WIB - {auction?.batas_akhir_format} WIB</strong>
            }
          </div>
          <div className="btn-group">
            {tabulasi?.status == 'draft' &&
              <button
                className={"btn btn-primary btn-sm"}
                type={"button"}
                disabled={!isPublishEnabled() || isLoading}
                onClick={() => {
                  handlePublish();
                }}
              >
                {isLoading && <Spinner animation={"border"} size={"sm"} className={"me-2"}></Spinner>}
                {!isLoading && <i className={"fa fa-paper-plane"}></i>}
                Publish
              </button>
            }

            {tabulasi?.status == 'ongoing' &&
              (
                <>
                  <button
                    className={"btn btn-primary btn-sm"}
                    type={"button"}
                    disabled={isLoading}
                    onClick={() => {
                      handleStop();
                    }}
                  >
                    {isLoading && <Spinner animation={"border"} size={"sm"} className={"me-2"}></Spinner>}
                    {!isLoading && <i className={"fa fa-stop"}></i>}
                    Stop
                  </button>
                  <button
                    className={"btn btn-primary btn-sm"}
                    type={"button"}
                    disabled={isLoading}
                    onClick={() => {
                      handlePause();
                    }}
                  >
                    {isLoading && <Spinner animation={"border"} size={"sm"} className={"me-2"}></Spinner>}
                    {!isLoading && <i className={"fa fa-pause"}></i>}
                    Pause
                  </button>
                </>
              )
            }

            {(tabulasi?.status == 'done') &&
              <>
                <button
                  className={"btn btn-primary btn-sm"}
                  type={"button"}
                  disabled={isLoading}
                  onClick={() => {
                    handleFinish();
                  }}
                >
                  {isLoading && <Spinner animation={"border"} size={"sm"} className={"me-2"}></Spinner>}
                  {!isLoading && <i className={"fa fa-stop"}></i>}
                  Close
                </button>
              </>
            }

            {tabulasi?.status == 'paused' &&
              <button
                className={"btn btn-primary btn-sm"}
                type={"button"}
                disabled={isLoading}
                onClick={() => {
                  handleUnPause();
                }}
              >
                {isLoading && <Spinner animation={"border"} size={"sm"} className={"me-2"}></Spinner>}
                {!isLoading && <i className={"fa fa-play"}></i>}
                Unpause
              </button>
            }
          </div>
        </div>

      </div>

    </>
  );
};

const Footer = ({ isLoading, handlePrevious, handleNext }: any) => {
  return (
    <div className={"fixed-bottom row"}>
      <div className={"col-md-12 bg-white px-20 py-3 gap-3"}>
        <div className={"text-end"}>
          <button
            className={"btn btn-danger btn-sm me-3"}
            type={"button"}
            onClick={() => {
              handlePrevious();
            }}
            disabled={isLoading}
          >
            {isLoading && (<i className={"fa fa-spinner fa-spin me-2"}></i>)}
            {!isLoading && (<i className={"fa fa-chevron-left me-2"}></i>)}
            Sebelumnya
          </button>
          <button
            className={"btn btn-success btn-sm me-3"}
            type={"button"}
            onClick={() => {
              handleNext();
            }}
            disabled={isLoading}
          >
            Selanjutnya
            {isLoading && (<i className={"fa fa-spinner fa-spin ms-2"}></i>)}
            {!isLoading && (<i className={"fa fa-chevron-right ms-2"}></i>)}
          </button>

        </div>
      </div>
    </div>
  );
};

const AuctionDetailForm = () => {
  const [selectedTab, setSelectedTab] = useState("parameter-auction");
  const [auction, setAuction] = useState<any>({});
  const [tabulasi, setTabulasi] = useState<any>(null);
  const [isIntialLoad, setIsInitialLoad] = useState(true);
  const { uuid } = useParams();
  const eventSocket = "App\\Events\\AuctionBuyerEvent";
  const subscribedSocket = `auction.buyer.`;
  const [tabulasiIndex, setTabulasiIndex] = useState(0);

  const PusherJS = require("pusher-js");

  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosGet(
    getEnv("auction") + "/auction/" + uuid
  );
  const [menuTab, setMenuTab] = useState([] as any);

  const initiateTab = () => {
    const tmp = [
      { label: "Parameter Auction", eventKey: "parameter-auction", disabled: false },
      { label: "Item Auction", eventKey: "item-auction", disabled: false },
      { label: "Peserta", eventKey: "peserta", disabled: false },
      { label: "Param Setting", eventKey: "param-setting", disabled: false },
      { label: "Jadwal", eventKey: "jadwal", disabled: false },
      { label: "Petunjuk Peraturan", eventKey: "petunjuk", disabled: false },
      { label: "Tabulasi", eventKey: "tabulasi", disabled: false },
      { label: "Riwayat", eventKey: "riwayat" },
    ]
    if (tabulasi?.status != 'draft') {
      tmp.push({ label: "Pakta Integritas", eventKey: "pakta-integritas" });
    }
    if (tabulasi?.status == 'done' || tabulasi?.status == 'closed') {
      tmp.push({ label: "Panitia Pengadaan", eventKey: "panitia-pengadaan" });
      tmp.push({ label: "Berita Acara", eventKey: "berita-acara" });
    }
    if (!tabulasi?.is_service_line_empty) {
      tmp.push({ label: "Finalisasi", eventKey: "finalisasi" });
    }
    setMenuTab(tmp);
  }


  const handlePrevious = () => {
    let index = menuTab.findIndex((item: any) => item.eventKey === selectedTab);
    if (index > 0) {
      setSelectedTab(menuTab[index - 1].eventKey);
    }
  };

  const handleNext = () => {
    let index = menuTab.findIndex((item: any) => item.eventKey === selectedTab);
    if (index < menuTab.length - 1) {
      //check is tab enabled
      if (checkIsTabEnabled(menuTab[index + 1].eventKey)) {
        setSelectedTab(menuTab[index + 1].eventKey);
      }
    }
  };

  const handleOnSave = () => {
    getDetail(true);
  };

  const getDetail = async (isUpdateData = false) => {
    doSubmit(undefined, undefined, undefined).then((res: any) => {
      setAuction(res.data);

      if (!isUpdateData) {
        if (res.data.status === "draft") {
          setSelectedTab("parameter-auction");
        } else {
          setSelectedTab("tabulasi");
        }
      }
      setIsInitialLoad(false);
    });
  };

  const getTabulasi = async () => {
    const newUrl = getEnv("auction") + `/auction/tabulasi/${uuid}`;
    doSubmit(undefined, newUrl, undefined).then((res: any) => {
      setTabulasi(res.data);
      initiateSocket(res.data.auction_uuid);
    });
  };

  const checkIsTabEnabled = (eventKey: string) => {
    if (auction.status === "draft") {
      // ketika draft, hanya Parameter Auction, Item Auction, Peserta, Param Setting, Jadwal yang bisa diakses
      const allowedTab = ["parameter-auction", "item-auction", "peserta", "param-setting", "jadwal", "petunjuk"];
      return allowedTab.includes(eventKey);
    } else {
      return true;
    }
  };

  const showTab = () => {
    switch (selectedTab) {
      case "parameter-auction":
        return (
          <ParameterAuctionTab auction={auction} onSave={() => handleOnSave()} />
        );
      case "item-auction":
        return (
          <ItemAuctionTab auction={auction} onSave={() => handleOnSave()} />
        );
      case "peserta":
        return (
          <PesertaAuctionTab auction={auction} onSave={() => handleOnSave()} />
        );
      case "jadwal":
        return (
          <JadwalAuction auction={auction} onSave={() => handleOnSave()} />
        );
      case "petunjuk":
        return (
          <PetunjukPeraturanAuctionTab auction={auction} onSave={() => handleOnSave()} />
        );
      case "tabulasi":
        return (
          <TabulasiAuctionPage auction={auction} getTabulasi={() => getTabulasi()} tabulasi={tabulasi} isLoading={isLoading} />
        );
      case "riwayat":
        return (
          <RiwayatAuctionTab title={"Riwayat"} tabulasi={tabulasi} />
        );
      case "param-setting":
        return (
          <ParamSettingAuctionTab auction={auction} onSave={() => handleOnSave()} />
        );
      case "pakta-integritas":
        return (
          <PaktaIntegritasAuctionTab auction={auction} tabulasi={tabulasi} />
        );
      case "panitia-pengadaan":
        return (
          <PanitiaPengadaanTab auction={auction} tabulasi={tabulasi} onSave={() => handleOnSave()} />
        );
      case "berita-acara":
        return (
          <BeritaAcaraAuctionTab auction={auction} tabulasi={tabulasi} />
        );
      case "finalisasi":
        return (
          <FinalisasiAuctionTab tabulasi={tabulasi} />
        );
    }
  };

  const initiateSocket = (auction_uuid: string) => {
    let client = new PusherJS(process.env.REACT_APP_WEBSOCKET_APP_KEY, {
      wsHost: process.env.REACT_APP_WEBSOCKET_URL,
      wsPort: process.env.REACT_APP_WEBSOCKET_PORT,
      cluster: process.env.REACT_APP_WEBSOCKET_CLUSTER,
      forceTLS: process.env.REACT_APP_WEBSOCKET_FORCETLS,
      encrypted: process.env.REACT_APP_WEBSOCKET_ENCRYPTED,
      disableStats: true,
      enabledTransports: ["ws", "wss"],
      logToConsole: true
    });
    const channel = client.subscribe(subscribedSocket + auction_uuid);
    channel.bind(eventSocket, function (data: any) {
      setTabulasi(data.data);
      const changedRow = document.getElementById(`table-tabulasi`);
      if (changedRow) {
        changedRow.classList.add('blink_me');

        // Hapus kelas "blink" setelah animasi selesai
        setTimeout(() => {
          changedRow.classList.remove('blink_me');
        }, 1000);
      }
    });
  };

  const getDataAfterPublish = () => {
    getDetail();
    getTabulasi();
  }


  useEffect(() => {
    getDetail();
    getTabulasi();
  }, []);

  useEffect(() => {
    initiateTab();
    if (auction && auction.status) {
      var dataAuction = { ...auction };
      dataAuction.status = tabulasi.status;
      setAuction(dataAuction);
    }
  }, [tabulasi]);

  return (
    <>


      {(isLoading && isIntialLoad) && (
        <Placeholder.Grid rows={3} active columns={2} style={{ marginTop: 20 }} />
      )}
      {(!isIntialLoad) && (
        <Header
          auction={auction}
          tabulasi={tabulasi}
          onRefreshAuction={getDataAfterPublish}
        />
      )}
      {tabulasi?.status === "ongoing" && (
        <div className={"bg-warning mb-4 p-2"}>
          <div className="marquee-container">
            <div className="marquee-content text-dark">
              <strong>{auction?.catatan}</strong>
            </div>
          </div>
        </div>
      )}

      <KTCard key={tabulasiIndex}>
        <KTCardBody className="">
          {(isLoading && isIntialLoad) && (
            <Placeholder.Graph height={1000} active={true} />
          )}
          {!isIntialLoad && (
            <div className={"table-responsive"} style={{
              // overflowX: "hidden",
              width: "100%"
            }}>
              <Nav appearance={"subtle"} activeKey={selectedTab} onSelect={(e: any) => {
                setSelectedTab(e);
              }} style={{ marginBottom: 20 }}>
                {menuTab.map((item: any, index: number) => {
                  return (
                    <Nav.Item className={item.label == 'Finalisasi' && tabulasi?.status == 'done' ? "red-border" : ""} eventKey={item.eventKey} key={index} disabled={!checkIsTabEnabled(item.eventKey)}>
                      {item.label}
                      {item.label == 'Finalisasi' && tabulasi?.status == 'done' &&
                        <small className="error-counter">
                          <i className={"fa fa-exclamation-circle text-danger text-sm blink_me"}></i>
                        </small>
                      }
                    </Nav.Item>
                  );
                })}
              </Nav>
            </div>
          )}

          {showTab()}


        </KTCardBody>
      </KTCard>
      <Footer isLoading={isLoading} handlePrevious={handlePrevious} handleNext={handleNext} />
    </>
  );
};

export default AuctionDetailForm;