import { Outlet, Route, Routes } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import React from 'react'

import Snop from './Snop'

const masterData = {
  title: 'SNOP',
  path: 'stockview/snop',
  isSeparator: false,
  isActive: false,
}
const separator = {
  title: '',
  path: '',
  isSeparator: true,
  isActive: false,
}
const breadcrumbs: Array<PageLink> = [masterData, separator]

const SnopPage: React.FC = () => {
  
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          index
          element={
            <>
              <PageTitle breadcrumbs={breadcrumbs}>Stockview</PageTitle>
              <Snop/>
            </>
          }
        ></Route>
      </Route>
    </Routes>
  )
}

export default SnopPage
