import {formatCurrency, KTCard, KTCardBody} from '../../../../../_metronic/helpers'
import React, {useEffect, useState} from 'react'
import TableXWrpper from '../../../../components/shared/TableXWrapper'
import {NavLink, useLocation, useNavigate} from 'react-router-dom'
import {getEnv} from '../../../../../helpers/procurex'
import {StatusBadgeMonitoringTender} from '../monitoring/components/MonitoringTenderList'
import {ButtonDetailApprovalOnTable} from '../../../../components/shared/ApprovalX'
import {useAuth} from '../../../auth'
import {useIntl} from 'react-intl'
import {darkModeClass} from '../../../../../helpers/utils'
import {Modal} from 'react-bootstrap'
import {useAxiosGet, useAxiosPost} from '../../../../../hooks/useAxios'
import {toast} from 'react-toastify'
import {Placeholder} from 'rsuite'
import { AlihkanModal } from './components/AlihkanModal'

const ModalNotes = ({proposalTender, isShowModal, onCloseModal}: any) => {
  const intl = useIntl();
  const  [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosPost(
    getEnv("tender") + "/tender/notes"
  )
  const  [{ doSubmit: doSubmitGet, isLoading: isLoadingGet }] = useAxiosGet(
    getEnv("tender") + "/tender/tender"
  )
  const [notes, setNotes] = useState(proposalTender?.notes)
  const [showModal, setShowModal] = useState(isShowModal);
  const [currentProposalTender, setCurrentProposalTender] = useState(null);

  useEffect(() => {
    setShowModal(isShowModal);
  }, [isShowModal]);

  const handleClose = async() => {
    setShowModal(false)
    onCloseModal && onCloseModal();
  }

  const getProposalTender = async() => {
    doSubmitGet({}, getEnv("tender") + `/tender/${proposalTender.uuid}`, undefined)
      .then((res: any) => {
        setNotes(res?.data?.notes)
      })
  }

  const handleSave = async() => {
    const payload = {
      _method: "PUT",
      notes: notes
    }
    doSubmit(payload, getEnv("tender") + `/tender/notes/${proposalTender.uuid}`,toast)
      .then((res: any) => {
        handleClose()
      })
  }

  useEffect(() => {
    getProposalTender()
  }, [proposalTender?.uuid]);

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {intl.formatMessage({id:"Catatan"})}
            </Modal.Title>
          </Modal.Header>
        <Modal.Body>
          {isLoadingGet && (
            <>
              <Placeholder.Grid rows={4} columns={2} active />
              <Placeholder.Graph active />
            </>
          )}
          {!isLoadingGet && (
            <>
              <div className="form-group">
                <table className="table table-borderless">
                  <tr className={"mb-3"}>
                    <th><strong>No</strong></th>
                    <td className={"text-end"}>
                      {proposalTender?.no}
                    </td>
                  </tr>
                  <tr className={"pt-3"}>
                    <th><strong>No PR</strong></th>
                    <td className={"text-end"}>
                      {proposalTender?.pr_header_numbers}
                    </td>
                  </tr>
                  <tr className={"pt-3"}>
                    <th><strong>Judul</strong></th>
                    <td className={"text-end"}>
                      {proposalTender?.title}
                    </td>
                  </tr>
                  <tr className={"pt-3"}>
                    <th><strong>Nilai Tender</strong></th>
                    <td className={"text-end"}>
                      {/*{(proposalTender?.items?.length > 0 ? proposalTender?.items[0]?.currency_id : "")}*/}
                      {proposalTender?.currency_id}
                      {formatCurrency(proposalTender?.total_nilai_tender)}
                    </td>
                  </tr>
                </table>
              </div>
              <hr />
              <div className="form-group">
                <label className="form-label">{intl.formatMessage({ id: "Catatan" })}</label>
                <textarea
                  className="form-control"
                  rows={3}
                  onChange={(e: any) => {
                    setNotes(e.target.value);
                  }}
                >{notes}</textarea>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-light btn-sm" onClick={handleClose} disabled={isLoading || isLoadingGet}>
            <i className="fa fa-times"></i>
            {intl.formatMessage({ id: "Tutup" })}
          </button>
          <button type="button" className="btn btn-sm btn-primary" onClick={handleSave} disabled={isLoading || isLoadingGet}>
            {isLoading && <i className="fa fa-spinner fa-spin me-2"></i>}
            {!isLoading && <i className="fa fa-save me-2"></i>}
            {intl.formatMessage({ id: "Simpan" })}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export const getUrlDetailTender = (proposalTender: any) => {

  const status = proposalTender?.status
  const statusDetail = proposalTender?.status_detail
  const baseUrl = `/tender/admin/proposal-tender/detail/${proposalTender?.uuid}`

  const allowedStatusDraft = ['Draft']
  const allowedStatusPQ = ['Pra Qualification']
  const allowedStatusDUR = ['DUR']
  const allowedStatusPenawaran = ['Published', 'Aanwijzing', 'Registrasi', 'Penawaran', 'Penawaran Teknikal', 'Penawaran Komersil']
  const allowedStatusBid = ['Bid Opening', 'Evaluasi Administrasi', 'Evaluasi Teknikal', 'Evaluasi Komersil']
  const allowedStatusNego = ['Negotiation']
  const allowedStatusAwarding = ['Awarding', 'Laporan Pengadaan', 'Pengumuman Pemenang', 'Penunjukan Pemenang']

  const allowedDetailStatusRetender = ['Submit for Approval Retender', 'Retender']

  if (allowedDetailStatusRetender.includes(statusDetail)) {
    return baseUrl+"/item";
  }

  if (allowedStatusDUR.includes(status)) {
    if (statusDetail === 'Submit for Approval DUR') {
      return baseUrl+"/proses-tender/peserta"
    } else {
      return baseUrl+"/dur"
    }
  } else if (allowedStatusPQ.includes(status)) {
    if (statusDetail !== 'Draft') {
      return (baseUrl+"/pq")
    }
  } else if (allowedStatusBid.includes(status)) {
    if (statusDetail === 'Submit for Approval Change Schedule') {
      return (baseUrl+"/proses-tender/jadwal")
    }
    if (proposalTender?.metode_penyampaian_step === 2 && proposalTender?.metode_penyampaian === '2 Tahap') {
      return (baseUrl+"/proses-tender/evaluasi-komersil")
    } else {
      return (baseUrl+"/proses-tender/evaluasi")
    }
  } else if (allowedStatusNego.includes(proposalTender?.status)) {
    return (baseUrl+"/negosiasi")
  } else if (allowedStatusAwarding.includes(proposalTender?.status)) {
    if(proposalTender?.status === "Penunjukan Pemenang"){
      return baseUrl+"/penunjukkan-pemenang"
    }
    if(proposalTender?.status === "Pengumuman Pemenang"){
      return baseUrl+"/pengumuman-pemenang"
    }
    return (baseUrl+"/awarding")
  } else if (allowedStatusPenawaran.includes(proposalTender?.status)) {
    return baseUrl+"/proses-tender/peserta"
  }

  return baseUrl;
}

const ProposalTenderList = ({ status,statusDetail, backTo }: any) => {
  const urlIndex = getEnv("tender") + `/tender`;
  const { currentUser } = useAuth();
  const [tableIndex, setTableIndex] = React.useState(0);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showModalAlihkan, setShowModalAlihkan] = useState(false);
  const [currentProposalTender, setCurrentProposalTender] = useState({} as any);
  const [statusFilter, setStatusFilter] = React.useState("");
  const [statusDetailFilter, setStatusDetailFilter] = React.useState("");
  const [filterOnlyMe, setFilterOnlyMe] = useState(false);
  const [roleFilterOnlyMe, setRoleFilterOnlyMe] = useState([
    "spv_buyer_pi",
    "spv_buyer_anper",
    "vp_buyer_pi",
    "vp_buyer_anper",
    "avp_buyer_anper",
    "staff_buyer_pi",
    "staff_buyer_anper"
  ]);
  const intl = useIntl();
  const tableColumns = [
    {
      Header: intl.formatMessage({ id: "Aksi" }),
      accessor: "action",
      width: 120,
      className: "text-start fixed-column",
      Cell: (props: any) => {
        const row = props.row.original;
        return (
          <>
            <NavLink to={getUrlDetailTender(row)} title={"Edit"}className={`me-2 ${darkModeClass()}`}>
              <i className={`fa fs-3 fa-pencil ${darkModeClass()}`}></i>
            </NavLink>
            <a href={"javascript:;"} title={"notes"} onClick={(e:any) => {
              setCurrentProposalTender(row);
              setShowModal(true);
              e.preventDefault();
            }} className={`me-2 ${darkModeClass()}`}>
              <i className={`fa fs-3 fa-note-sticky ${darkModeClass()}`}></i>
            </a>
            {(!status?.includes("Cancel") && currentUser?.has_roles?.includes("superadmin")) && (
              <a href={"javascript:;"} title={"Alihkan"} onClick={(e:any) => {
                setCurrentProposalTender(row);
                setShowModalAlihkan(true);
                e.preventDefault();
              }} className={`${darkModeClass()}`}>
                <i className={`fas fs-3 fa-hand-holding-hand ${darkModeClass()}`}></i>
              </a>
            )}
            {(row?.status_detail).includes("Submit for Approval") && (
              <ButtonDetailApprovalOnTable
                title={`(${row?.status})` + " " + row?.status_detail}
                source={"TENDER"}
                company_id={row.company.id}
                object_id={row.id}
                code={row.routing_approval_config_code}
              />
            )}
          </>
        );
      }
    },
    {
      Header: intl.formatMessage({ id: "Status" }),
      accessor: "status_monitoring",
      className: "text-start text-nowrap",
      width: 300,
      Cell: (props: any) => {
        const status = (props?.row?.original?.metode_penyampaian === "1 Sampul")
          ? props?.row?.original?.status
          : (props?.row?.original?.status === "Bid Opening")
            ? props?.row?.original?.metode_penyampaian_step === 0
              ? "Bid Opening Teknis"
              : props?.row?.original?.metode_penyampaian_step === 1
                && "Bid Opening Komersil"
            : props?.row?.original?.status
        const statusDetail = props.row.original.status_detail === "Submit for Approval Retender"
          ? props.row.original.status_detail + ' ' + props.row.original.order_placement
          : props.row.original.status_detail

        return (
          <>
            <StatusBadgeMonitoringTender
              status={`${status} - ${statusDetail}`} />
          </>
        );
      }
    },
    {
      Header: intl.formatMessage({id:"Notes"}),
      accesor: 'notes',
      className: "text-start text-nowrap",
      width: 200,
      Cell: (props: any) => {
        return (
          <>
            {props?.row?.original?.notes?.split("\n")?.map((item: any, index: number) => {
              return (
                <div key={index}>{item}</div>
              );
            })}
          </>
        )
      }
    },
    {
      Header: intl.formatMessage({id:"No"}),
      accessor: "no",
      width: 180,
      disableCreate: true,
      disableEdit: true,
      className: "text-center fixed-column"
    },
    {
      Header: intl.formatMessage({id:"No PR"}),
      accessor: "pr_header_numbers",
      width: 120,
      disableCreate: true,
      disableEdit: true,
      className: "text-center",
      Cell: (props: any) => {
        return (
          <>
            {props?.row?.original?.pr_header_numbers?.split(",")?.map((item: any, index: number) => {
              return (
                <div key={index}>{item}</div>
              );
            })}
          </>
        );
      }
    },
    { Header: intl.formatMessage({id:"Judul"}),
      accessor: "title",
      width: 200,
    },
    {
      Header: intl.formatMessage({id:"Nilai Tender"}),
      accessor: 'total_nilai_tender', className: 'text-end', width: 150, Cell: (props: any) => {
      const data = props.cell.row.original
        return formatCurrency(data.total_nilai_tender)
      }
    },
    {
      Header: intl.formatMessage({id:"Mata Uang"}),
      accessor: 'currency_id', className: 'text-center', width: 90,
      // Cell: (props: any) => {
      // const data = props.cell.row.original
      //   return data?.items[0]?.currency_id
      // }
    },
    {Header: intl.formatMessage({id:"Tanggal Berakhir Penawaran"}), accessor: "closing_date_penawaran", width: 200, className: "text-center"},
    {
      Header: intl.formatMessage({id:"Tanggal Pengumuman"}),
      accessor: "start_at_pengumuman",
      width: 200,
      className: "text-center",
      Cell: (props: any) => {
        const data = props.cell.row.original
        const value = data?.
                      jadwals?.filter((item: any) => item.description === "Pengumuman")
                      .map((item: any) => item.start_at)
        return value;
      }
    },
    { Header: intl.formatMessage({id:"Referensi"}), accessor: "reference", width: 150, className: "text-center" },
    { Header: intl.formatMessage({id:"Purchasing Group"}), accessor: "purchasing_group_id", width: 125, className: "text-center" },
    {
      Header: intl.formatMessage({id:"Buyer"}),
      accessor: "created_by.name",
      width: 200,
      disableCreate: true,
      disableEdit: true,
      className: "text-center",
      Cell: (props: any) => {
        return (
          <>
            {props.row.original.created_by.username} - {props.row.original.created_by.name}
          </>
        );
      }
    },
    { Header: intl.formatMessage({id:"Dibuat Pada"}), accessor: "created_at", width: 200, className: "text-center" }
  ];
  const location = useLocation();
  const renderStatusFilter = () => {

    roleFilterOnlyMe.map((v:any)=>{
      if(currentUser?.has_roles?.includes(v))
        setFilterOnlyMe(true);
    });

    let _statusFilter = "";
    status?.map((item: any, index: number) => {
      _statusFilter += `${item},`;
    });
    setStatusFilter(_statusFilter);

    let _statusDetailFilter = "";
    statusDetail?.map((item: any, index: number) => {
      _statusDetailFilter += `${item},`;
    });
    setStatusDetailFilter(_statusDetailFilter);
  }

  useEffect(() => {
    renderStatusFilter();
    localStorage.setItem("backTo", location.pathname);
    setTableIndex(tableIndex + 1);
  }, [status]);
  return (
    <>
      <KTCard>
        <KTCardBody className="py-4">
          <TableXWrpper
            key={tableIndex}
            readonly={true}
            title={"Proposal Tender"}
            urlIndex={urlIndex}
            columns={tableColumns}
            isForceCreateButton={true}
            defaultFilter={
              `sort=-updated_at&filter[status]=${statusFilter}&only_me=${filterOnlyMe}&filter[status_detail]=${statusDetailFilter}`
            }
            hasAnySearch={true}
          />
        </KTCardBody>
      </KTCard>
      {currentProposalTender?.uuid && (
        <ModalNotes
          proposalTender={currentProposalTender}
          isShowModal={showModal}
          onCloseModal={() => setShowModal(false)}
        />
      )}
      <AlihkanModal
        proposalTender={currentProposalTender}
        show={showModalAlihkan}
        onHide={() => setShowModalAlihkan(false)}
      />

    </>
  );
};

export { ProposalTenderList };
