import { getEnv } from '../../../../../helpers/procurex'
import { Col, Row } from 'react-bootstrap'
import { useState } from 'react'

import TableXWrapper from '../../../../components/shared/TableXWrapper'

const ListAgreementPerItem = () => {
  const [key, setKey] = useState(0)

  const tableColumns = [
    { Header: 'No Agreement', accessor: 'no_agreement', width: 150, className: 'text-center' },
    { Header: 'Line No Agreement', accessor: 'line_no_agreement', width: 100, className: 'text-center' },
    { Header: 'Vendor', accessor: 'vendor', width: 200, className: 'text-center' },
    { Header: 'Currency', accessor: 'currency', width: 100, className: 'text-center' },
    { Header: 'Net Price', accessor: 'net_price', width: 150, className: 'text-center' },
    { Header: 'Target Qty', accessor: 'target_qty', width: 150, className: 'text-center' },
    { Header: 'Validity Start Date', accessor: 'validity_start_date', width: 150, className: 'text-center' },
    { Header: 'Validity End Date', accessor: 'validity_end_date', width: 150, className: 'text-center' },
    { Header: 'Material', accessor: 'material', width: 150, className: 'text-center' },
    { Header: 'Short Text', accessor: 'short_text', width: 200, className: 'text-center' },
  ]

  return (
    <div className='card mt-3'>
      <div className='card-body'>
        <Row>
          <Col>
            <TableXWrapper
              readonly
              key={key}
              urlIndex={`${getEnv("masterdata")}/agreement`}
              columns={tableColumns}
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default ListAgreementPerItem