import { ID, KTCard, KTCardBody } from '../../../../../../_metronic/helpers'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import TableXWrpper from "../../../../../components/shared/TableXWrapper";
import SelectX from '../../../../../components/shared/form/SelectX'
import { ApiUrl } from '../../../../../config/Api'
import { getEnv } from "../../../../../../helpers/procurex";
import SwalX from '../../../../../components/shared/SwalX'
import { InputNumber } from "rsuite";
import { Field, Form, Formik } from 'formik'
import { useIntl } from 'react-intl'
import { MetronicErrorMessage, toastError } from '../../../../../../_metronic/helpers'
import { toast } from 'react-toastify'
import axios from 'axios'
import { Button, ButtonGroup, Col, Modal, Row } from "react-bootstrap";
import { useAuth } from "../../../../../../app/modules/auth";
import DatepickerX2 from "../../../../../components/shared/form/DatepickerX2";
import moment from 'moment/moment'
import { ImportExcelModal } from '../../../../../components/shared/import-excel/ImportExcelModal';

const ConsumptionStockviewList = () => {
    const intl = useIntl()
    const { currentUser } = useAuth();
    const tablecolumns = [
        {
            Header: "TANGGAL",
            accessor: "start_validity_date",
            width: 250,
            Cell: ({ value }) => {
                if (!value) return ''; // Handle null or undefined values
                const date = new Date(value);
                const day = date.getDate().toString().padStart(2, '0'); // Get day and pad with leading zero if necessary
                const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get month (+1 because month index starts from 0) and pad with leading zero if necessary
                const year = date.getFullYear(); // Get full year
                return `${day}-${month}-${year}`;
            }
        },
        {
            Header: "MATERIAL NUMBER",
            accessor: "material_number_desc",
            width: 300,
        },
        {
            Header: "COMPANY CODE",
            accessor: "company_code_short",
            width: 200,
        },
        {
            Header: "TOTAL CONSUMPTION",
            accessor: "total_consumption",
            width: 150,
        },
        {
            Header: "UOM",
            accessor: "uom",
            width: 100,
        },
        {
            Header: "Action",
            accessor: "uuid",
            width: 180,
            className: "text-center fixed-column",
            Cell: (props: any) => {
                const row = props.row.original;

                return (
                    // <ButtonGroup size='sm' aria-label='Action'>
                    //     <button
                    //         type='button'
                    //         className='btn btn-sm btn-success'
                    //         onClick={(e: any) => {
                    //             e.preventDefault();
                    //             setId(row.id);
                    //             openModalWithItem(row); // Pass the row item to the function
                    //         }}
                    //     >
                    //         Edit
                    //     </button>
                    //     <button
                    //         type='button'
                    //         className='btn btn-sm btn-danger'
                    //         onClick={() => {
                    //             setItemId(props.row.original.id)
                    //             setIsConfirmDeleteOpen(true)
                    //         }}
                    //     >
                    //         <i className={'fa fa-trash'} />
                    //     </button>
                    // </ButtonGroup>
                    <ButtonGroup size='sm' aria-label='Action'>
                        {!excludedRoles.some(r => role.includes(r)) && (
                            <>
                                <button
                                    type='button'
                                    className='btn btn-sm btn-success'
                                    onClick={(e: any) => {
                                        e.preventDefault();
                                        setId(row.id);
                                        openModalWithItem(row); // Pass the row item to the function
                                    }}
                                >
                                    Edit
                                </button>
                                <button
                                    type='button'
                                    className='btn btn-sm btn-danger'
                                    onClick={() => {
                                        setItemId(row.id);
                                        setIsConfirmDeleteOpen(true);
                                    }}
                                >
                                    <i className='fa fa-trash' />
                                </button>
                            </>
                        )}
                    </ButtonGroup>
                );
            },
        },
    ];

    const excludedRoles = ['DaanExp_Anper', 'Gudang_Bahan_Baku_Anper', 'Perencanaan_Pengadaan_Anper', 'Managerial_PI'];
    const [formindex, setFormIndex] = useState(0);

    const [isShowModal, setIsShowModal] = useState(false)
    const [isShowDetail, setIsShowDetail] = useState(false)
    let [itemId, setItemId] = useState(0)
    let [isLoadingDelete, setIsLoadingDelete] = useState(false)
    let [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false)
    const [key, setKey] = useState(0)
    const [id, setId] = useState('')
    const [item, setItem] = useState<any>(null)
    const [isConfirmDialog, setIsConfirmDialog] = useState(false)
    const [itemsMasterMaterial, setItemsMasterMaterial] = useState<any>([])
    const [itemsMasterCompany, setItemsCompanyStock] = useState<any>([])
    const [itemLoading, setItemLoading] = useState(false)
    const [isShowImportModal, setIsShowImportModal] = useState(false)

    const loadItem = () => {
        axios
            .get(getEnv("stockview") + `/master-material`)
            .then((res) => {
                setItemsMasterMaterial(res.data.data)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setItemLoading(false)
            })
        axios
            .get(getEnv("stockview") + `/company-stock?perPage=50&page=1`)
            .then((res) => {
                setItemsCompanyStock(res.data.data)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setItemLoading(false)
            })
    }

    useEffect(() => {
        loadItem();
    }, [])

    const [totalConsumption, setTotalConsumption] = useState(0);

    const handleOnTotalConsumptionChange = (score: number) => {
        setTotalConsumption(score)
    }

    const [selectedMaterial, setSelectedMaterial] = useState(null);
    const [selectedCompanyCode, setSelectedCompanyCode] = useState(null);

    const handleCompanySelect = (selectedValue: any) => {
        setSelectedCompanyCode(selectedValue);
    };

    const handleMaterialSelect = (selectedValue: any) => {
        setSelectedMaterial(selectedValue);
    };

    const submit = async (values: any) => {
        const { materialnumberLabel } = await processItem(item);

        try {
            const newData = {
                master: {
                    code: "STOCKVIEW_CONSUMPTION"
                },
                details: [
                    {
                        data: {
                            material_number: selectedMaterial ? selectedMaterial : materialnumberLabel,
                            company_code: selectedCompanyCode ? selectedCompanyCode : item?.company_code,
                            total_consumption: item?.total_consumption || '',
                            uom: item?.uom ? item?.uom : selectedMaterial ? 'TON' : '',
                            start_date: values.start_validity_date || item?.start_validity_date,
                            end_date: values.end_validity_date || item?.end_validity_date,
                        }
                    }
                ]
            };

            const response = await axios.put(getEnv("stockview") + `/consumption-list/${id}`, newData)

            if (response.status === 200 || response.status === 201) {
                toast.success(response.data.meta.message)
                setId('')
                setIsShowModal(false)
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.meta?.message || 'Terjadi kesalahan')
        } finally {
            setIsConfirmDialog(false)
            setIsShowDetail(false)
        }
    }

    const deleteItem = async () => {
        try {
            setIsLoadingDelete(true)
            const response: any = await axios.delete(getEnv("stockview") + `/consumption-list/${itemId}`)

            if (response.status === 200) {
                // setFormIndex(formindex + 1)
                setIsConfirmDeleteOpen(false)
                toast.success(response?.data?.meta?.message, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                })
                loadItem()
            }
        } catch (error: any) {
            console.error(error)
            toastError(error.response?.data?.meta?.message || 'Terjadi kesalahan')
        } finally {
            setIsLoadingDelete(false)
        }
    }

    const defaultInitialValues = {
        material_number: '',
        company_code: '',
        total_consumption: '',
        uom: '',
        start_validity_date: '',
        end_validity_date: '',
    };

    const [initialValues, setInitialValues] = useState<any>(defaultInitialValues);
    const processItem = async (item: any) => {
        const materialnumber = item?.material_number || '';
        const materialnumberParts = materialnumber.split(' - ');
        const materialnumberLabel = materialnumberParts[0];

        return { materialnumberLabel };
    };

    const openModalWithItem = async (item: any) => {
        const { materialnumberLabel } = await processItem(item);

        setInitialValues({
            material_number: materialnumberLabel,
            company_code: item?.company_code || '',
            total_consumption: item?.total_consumption || '0',
            uom: item?.uom || '',
            start_validity_date: item?.start_validity_date || '',
            end_validity_date: item?.end_validity_date || '',
        });
        setItem(item);
        setIsShowModal(true);
    };

    let company_code_user = currentUser.company_code;
    let jenis_api = "table";
    let role = currentUser.has_roles;

    return (
        <>
            <SwalX
                title={intl.formatMessage({ id: 'DATATABLE.CONFIRM_DELETE' })}
                message={intl.formatMessage({ id: 'DATATABLE.MESSAGE_DELETE' })}
                show={isConfirmDeleteOpen}
                loading={isLoadingDelete}
                onHide={() => setIsConfirmDeleteOpen(false)}
                onSubmit={() => deleteItem()}
            />

            <Modal size="xl" show={isShowModal} onHide={() => setIsShowModal(false)}>
                <Modal.Header>Edit Consumption Stockview</Modal.Header>
                <Modal.Body>
                    <Formik initialValues={initialValues} onSubmit={submit} enableReinitialize>
                        {({ values, setFieldValue }) => (
                            <Form>
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label'>
                                                Material Number
                                            </label>
                                            <SelectX
                                                defaultValue={initialValues.material_number.trim()}
                                                onChange={handleMaterialSelect}
                                                options={itemsMasterMaterial.map((item: any) => ({
                                                    label: `${item.material_number} - ${item.material_desc}`,
                                                    value: item.material_number.trim()
                                                }))}
                                            />
                                            {/* <MetronicErrorMessage name='sap_no' /> */}
                                        </div>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label'>
                                                Company Code
                                            </label>
                                            <SelectX
                                                defaultValue={initialValues.company_code}
                                                onChange={handleCompanySelect}
                                                options={itemsMasterCompany
                                                    .filter((item: any) => {
                                                        if (company_code_user === "A000") {
                                                            return item.active_app === "X" && item.company_code !== "A000";
                                                        } else {
                                                            return item.active_app === "X" && item.company_code !== "A000" && item.company_code === company_code_user;
                                                        }
                                                    })
                                                    .map((item: any) => ({
                                                        label: item.company_code,
                                                        value: item.company_code
                                                    }))
                                                }
                                            />
                                            {/* <MetronicErrorMessage name='npwp_no' /> */}
                                        </div>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label'>
                                                Total Consumption
                                            </label>
                                            <InputNumber
                                                defaultValue={initialValues.total_consumption}
                                                min={0}
                                                name="total_consumption"
                                                className={"w-100 text-end"}
                                                onChange={(value: any) => {
                                                    handleOnTotalConsumptionChange(value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label'>
                                                Uom
                                            </label>
                                            <Field className='form-control' name="uom" value={initialValues.uom ? initialValues.uom : selectedMaterial ? 'TON' : ''} disabled />
                                            {/* <MetronicErrorMessage name='sap_no' /> */}
                                        </div>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label'>
                                                Start Validity Date
                                            </label>
                                            <DatepickerX2
                                                onChange={(date: any) => setFieldValue("start_validity_date", date)}
                                                size={"lg"}
                                                value={values.start_validity_date}
                                                format={"dd-MM-yyyy"}
                                            />
                                            {/* <Field
                                                className='form-control'
                                                name='start_validity_date'
                                                type='date'
                                            /> */}
                                        </div>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label'>
                                                End Validity Date
                                            </label>
                                            <DatepickerX2
                                                onChange={(date: any) => setFieldValue("end_validity_date", date)}
                                                size={"lg"}
                                                value={values.end_validity_date}
                                                format={"dd-MM-yyyy"}
                                            />
                                            {/* <Field
                                                className='form-control'
                                                name='end_validity_date'
                                                type='date'
                                            /> */}
                                        </div>
                                    </div>
                                </div>

                                <div className='d-flex gap-4 mt-5'>
                                    <button type='submit' className='btn btn-primary'>
                                        Save
                                    </button>
                                    <button
                                        type='button'
                                        className='btn btn-secondary'
                                        onClick={() => {
                                            setId('')
                                            setIsShowModal(false)
                                        }}
                                    >
                                        Batal
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>

            <ImportExcelModal
                // masterCode="STOCKVIEW_CONSUMPTION_2"
                masterCode="STOCKVIEW_CONSUMPTION_test"
                show={isShowImportModal}
                onHide={() => setIsShowImportModal(false)}
                invalidTableXDataColumns={[
                    {
                        Header: 'Material Number',
                        accessor: 'data.material_number',
                        width: 150,
                    },
                    {
                        Header: 'Company Code',
                        accessor: 'data.company_code',
                        width: 150,
                    },
                    {
                        Header: 'Total Consumption',
                        accessor: 'data.total_consumption',
                        width: 200,
                    },
                    {
                        Header: 'Start Date',
                        accessor: 'data.start_date',
                        width: 150,
                    },
                    {
                        Header: "End Date",
                        accessor: "data.end_date",
                        width: 150,
                    },
                ]}
            />

            <KTCard className='card border mt-5'>
                <KTCardBody >
                    <TableXWrpper
                        title={"MR/SR Unit Kerja"}
                        key={formindex}
                        readonly
                        urlIndex={getEnv("stockview") + `/consumption-list?company_code=${company_code_user}&jenis_api=${jenis_api}`}
                        columns={tablecolumns}
                        headerButtons={
                            !excludedRoles.some(r => role.includes(r)) && (
                                <>
                                    <Link
                                        to="/stockview/transaction/consumption/create"
                                        className="btn btn-primary"
                                        title="Add Data"
                                    >
                                        Add Data
                                    </Link>
                                    <Button className="ms-2" onClick={() => setIsShowImportModal(prevState => !prevState)}>Import Data</Button>
                                </>
                            )
                        }
                    />
                </KTCardBody>
            </KTCard>
        </>
    )
}

export { ConsumptionStockviewList }
