import { ID, KTCard, KTCardBody } from '../../../../../../_metronic/helpers'
import React, { useState, useEffect } from 'react'
import TableXWrpper from "../../../../../components/shared/TableXWrapper";
import { ApiUrl } from '../../../../../config/Api'
import { getEnv } from "../../../../../../helpers/procurex";
import { useIntl } from 'react-intl'
import axios from 'axios'
import { Button, ButtonGroup, Col, Modal, Row } from "react-bootstrap";
import { useAuth } from "../../../../../../app/modules/auth";

const TransportationVesselHistoryLogList = () => {
    const intl = useIntl()
    const { currentUser } = useAuth();

    function formatNumber(value) {
        if (!value) return '';

        return Math.floor(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    const tablecolumns = [
        {
            Header: "NOMOR IMO",
            accessor: "imo",
            width: 100,
        },
        {
            Header: "Name",
            accessor: "name",
            width: 140,
        },
        {
            Header: "Capacity",
            accessor: "capacity",
            width: 100,
            Cell: ({ value }) => {
                return formatNumber(value);
            }
        },
        {
            Header: "UOM",
            accessor: "uom",
            width: 100,
        },
        {
            Header: "ETA Estimasi",
            accessor: "eta_estimasi",
            width: 120,
            Cell: ({ value }) => {
                if (!value) return '';
                const date = new Date(value);
                if (isNaN(date.getTime())) return '';

                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const year = date.getFullYear();

                return `${day}/${month}/${year}`;
            },
        },
        {
            Header: "PORT DESTINATION",
            accessor: "port_destination",
            width: 120,
        },
        {
            Header: "Komoditas",
            accessor: "komoditas",
            width: 120,
        },
        {
            Header: "Category",
            accessor: "category",
            width: 120,
        },
        {
            Header: "Created Time",
            accessor: "created_at",
            width: 120,
            Cell: ({ value }) => {
                if (!value) return 'N/A';
                const date = new Date(value);
                if (isNaN(date.getTime())) return 'N/A';

                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const year = date.getFullYear();
                const hours = String(date.getHours()).padStart(2, '0');
                const minutes = String(date.getMinutes()).padStart(2, '0');
                const seconds = String(date.getSeconds()).padStart(2, '0');

                return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
            },
        },
        {
            Header: "Action",
            accessor: "uuid",
            width: 150,
            className: "text-center fixed-column",
            Cell: (props: any) => {
                const row = props.row.original;
                return (
                    <ButtonGroup size='sm' aria-label='Action'>
                        <button
                            type='button'
                            className='btn btn-sm btn-success'
                            onClick={(e: any) => {
                                e.preventDefault();
                                setId(row.id);
                                openModalWithItemDetail(row);
                            }}
                        >
                            <i className='fa fa-eye' aria-hidden="true"></i> Detail
                        </button>
                    </ButtonGroup>
                );
            }
        }
    ];

    const [formindex, setFormIndex] = useState(0);

    const [isShowModalDetail, setIsShowModalDetail] = useState(false)
    let [itemId, setItemId] = useState(0)
    const [id, setId] = useState('')
    const [item, setItem] = useState<any>(null)

    const openModalWithItemDetail = (item: any) => {
        setItem(item);
        setIsShowModalDetail(true);
    };

    //laycan date format
    const formatDateLaycan = (dateString) => {
        const [year, month, day] = dateString.split('-');
        return `${day}/${month}/${year}`;
    };

    const laycanDates = item?.laycan_date ? JSON.parse(item.laycan_date) : [];
    const formattedStartDate = laycanDates[0] ? formatDateLaycan(laycanDates[0]) : '';
    const formattedEndDate = laycanDates[1] ? formatDateLaycan(laycanDates[1]) : '';
    //end laycan date format

    let company_code_user = currentUser.company_code;
    let role = currentUser.has_roles;

    return (
        <>
            <Modal size="xl" show={isShowModalDetail} onHide={() => setIsShowModalDetail(false)}>
                <Modal.Header>Detail Transportation Vessel History Log<strong>{item?.name}</strong></Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className='col-6'>
                            <ul className='list-group'>
                                <li className='list-group-item'><p><strong>Name:</strong> {item?.name}</p></li>
                                <li className='list-group-item'><p><strong>Capacity:</strong> {formatNumber(item?.capacity)}</p></li>
                                <li className='list-group-item'><p><strong>IMO:</strong> {item?.imo}</p></li>
                                <li className='list-group-item'><p><strong>Vendor:</strong> {item?.vendor}</p></li>
                                <li className='list-group-item'><p><strong>Category:</strong> {item?.category}</p></li>
                                <li className='list-group-item'><p><strong>Harga Perolehan:</strong> {formatNumber(item?.harga_perolehan)} {item?.currency}</p></li>
                                <li className='list-group-item'><p><strong>Komoditas:</strong> {item?.komoditas}</p></li>
                            </ul>
                        </div>
                        <div className='col-6'>
                            <ul className='list-group'>
                                <li className='list-group-item'><p><strong>Awarding:</strong> {item?.awarding}</p></li>
                                <li className='list-group-item'><p><strong>Uom:</strong> {item?.uom}</p></li>
                                <li className='list-group-item'>
                                    <p>
                                        <strong>ETA Estimasi: </strong>
                                        {(() => {
                                            const dateString = item?.eta_estimasi;
                                            if (!dateString) return 'N/A';
                                            const date = new Date(dateString);
                                            if (isNaN(date.getTime())) return 'N/A';
                                            const day = String(date.getDate()).padStart(2, '0');
                                            const month = String(date.getMonth() + 1).padStart(2, '0');
                                            const year = date.getFullYear();

                                            return `${day}/${month}/${year}`;
                                        })()}
                                    </p>
                                </li>
                                <li className='list-group-item'><p><strong>Laycan Estimasi:</strong> {formattedStartDate} - {formattedEndDate}</p></li>
                                <li className='list-group-item'><p><strong>Port Load:</strong> {item?.port_load}</p></li>
                                <li className='list-group-item'><p><strong>Port Destination:</strong> {item?.port_destination}</p></li>
                            </ul>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <KTCard className='card border mt-5'>
                <KTCardBody >
                    <TableXWrpper
                        title={"Transportation Vessel History Log"}
                        key={formindex}
                        readonly
                        urlIndex={getEnv("stockview") + `/vessel-history-log`}
                        columns={tablecolumns}
                    />
                </KTCardBody>
            </KTCard>
        </>
    )
}

export { TransportationVesselHistoryLogList }
